import {
  isClaimCommentCreatedCustomerEvent,
  isClaimCreatedCustomerEvent,
  isClaimStatusUpdatedCustomerEvent,
} from "@redotech/redo-model/customer-activity/customer-activity-definition";
import { IClaimCustomerEvent } from "@redotech/redo-model/customer-event/customer-event-definition";
import AlertHexagonIcon from "@redotech/redo-web/arbiter-icon/alert-hexagon.svg";
import { Flex } from "@redotech/redo-web/flex";
import { memo, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import * as customerActivityCardCss from "../../../../common/activity-card.module.css";
import { BaseActivityCard } from "../../../../common/base-activity-card";
import { MessageBubble } from "./message-bubble";
import { ReturnClaimStatusBadge } from "./return-claim-status-badge";

export const ClaimCustomerActivityCard = memo(
  function ClaimCustomerActivityCard({
    customerEvent,
  }: {
    customerEvent: IClaimCustomerEvent;
  }) {
    const navigate = useNavigate();

    const iconElement = (
      <Flex
        align="center"
        className={
          customerActivityCardCss.customerActivityDataClaimIconContainer
        }
        justify="center"
      >
        <AlertHexagonIcon
          className={customerActivityCardCss.customerActivityDataClaimIcon}
        />
      </Flex>
    );

    const getTitle = (): string => {
      if (isClaimCreatedCustomerEvent(customerEvent)) {
        return "New claim created";
      } else if (isClaimStatusUpdatedCustomerEvent(customerEvent)) {
        return "Claim status updated";
      } else if (isClaimCommentCreatedCustomerEvent(customerEvent)) {
        return "New claim comment";
      } else {
        return "";
      }
    };

    const getExtraDetailsElement = (): ReactNode | undefined => {
      if (isClaimStatusUpdatedCustomerEvent(customerEvent)) {
        return (
          <Flex mt="lg">
            <ReturnClaimStatusBadge
              returnClaimStatus={customerEvent.newStatus}
            />
          </Flex>
        );
      } else if (isClaimCommentCreatedCustomerEvent(customerEvent)) {
        return (
          <Flex flex={1} mt="lg">
            <MessageBubble text={customerEvent.messageId} />
          </Flex>
        );
      } else {
        return undefined;
      }
    };

    const onCardClick = () => {
      navigate(
        `/stores/${customerEvent.team.toString()}/order-management/claims/${customerEvent.claimId}`,
      );
    };

    return (
      <BaseActivityCard
        extraDetailsElement={getExtraDetailsElement()}
        onClick={onCardClick}
        timestamp={customerEvent.timestamp}
        title={getTitle()}
        topLeftElement={iconElement}
      />
    );
  },
);
