// extracted by mini-css-extract-plugin
export var actionButton = "_6a-3e";
export var actionButtonText = "_6a-3d";
export var addIcon = "_6a-13";
export var addItem = "_6a-1f";
export var addNewItemButton = "_6a-8";
export var addNewItemButtonText = "_6a-9";
export var addNewItemIcon = "_6a-a";
export var addressSpace = "_6a-2s";
export var adjustmentBonus = "_6a-1i";
export var adjustmentFee = "_6a-1j";
export var buttonBar = "_6a-2v";
export var buttonContentNew = "_6a-2t";
export var buttonDropdown = "_6a-2x";
export var buttonDropdown2 = "_6a-2y";
export var buttonDropdownContainer = "_6a-2w";
export var card = "_6a-0";
export var changeAddress = "_6a-39";
export var chevron = "_6a-i";
export var clickAwayListener = "_6a-1u";
export var clickable = "_6a-g";
export var container = "_6a-2";
export var coverage = "_6a-25";
export var createLabelsImage = "_6a-2j";
export var deleteIcon = "_6a-2p";
export var deleteIconContainer = "_6a-2o";
export var dropdownContainer = "_6a-3n";
export var dropdownIcon = "_6a-3p";
export var dropdownIconOpen = "_6a-3q";
export var dropdownItem = "_6a-3r";
export var dropdownOption = "_6a-2z";
export var dropdownOptionText = "_6a-2u";
export var dropdownOverlay = "_6a-3t";
export var dropdownText = "_6a-3s";
export var dropdownTrigger = "_6a-3o";
export var editIcon = "_6a-f";
export var editNewItemsLink = "_6a-12";
export var emptyExchangeOrder = "_6a-1m";
export var error = "_6a-h";
export var expandButton = "_6a-3g";
export var expandButtonContainer = "_6a-3h";
export var expanded = "_6a-3i";
export var external = "_6a-24";
export var fraudRiskContainer = "_6a-2f";
export var fullHeight = "_6a-2i";
export var fullWidth = "_6a-2h";
export var greenReturnAdjustment = "_6a-1n";
export var greenReturnImage = "_6a-1r";
export var greenReturnSelectContainer = "_6a-1o";
export var greenReturnSelectTitle = "_6a-1p";
export var greenReturnText = "_6a-1q";
export var headerLayout = "_6a-30";
export var headerSubtitleSmall = "_6a-38";
export var hiddenValues = "_6a-3j";
export var hiddenValuesClosing = "_6a-3l";
export var icon = "_6a-3";
export var item = "_6a-k";
export var itemCard = "_6a-2k";
export var itemCommentImage = "_6a-6";
export var itemCommentImages = "_6a-7";
export var itemComments = "_6a-5";
export var itemHeader = "_6a-q";
export var itemImage = "_6a-l";
export var itemLink = "_6a-21";
export var itemMain = "_6a-m";
export var itemOption = "_6a-v";
export var itemOptions = "_6a-s";
export var itemOptionsText = "_6a-t";
export var itemPrice = "_6a-y";
export var itemPriceContainer = "_6a-16";
export var itemPriceEdit = "_6a-x";
export var itemPriceText = "_6a-17";
export var itemProperties = "_6a-n";
export var itemProperty = "_6a-o";
export var itemPropertyName = "_6a-p";
export var itemSearch = "_6a-1g";
export var itemSubtitle = "_6a-z";
export var itemSubtitleText = "_6a-10";
export var itemSummary = "_6a-18";
export var itemSummaryLine = "_6a-19";
export var itemSummaryValue = "_6a-1a";
export var itemTitle = "_6a-r";
export var itemTitleIcon = "_6a-u";
export var itemType = "_6a-w";
export var items = "_6a-4";
export var linkColor = "_6a-3a";
export var menuTitle = "_6a-1c";
export var modalButton = "_6a-1s";
export var modalContent = "_6a-26";
export var modalFooterRight = "_6a-1t";
export var newAddress = "_6a-j";
export var newItemButtonText = "_6a-e";
export var newItemHeader = "_6a-1k";
export var newItemHeaderTabs = "_6a-1l";
export var newItemShipping = "_6a-b";
export var newItems = "_6a-2r";
export var newItemsSection = "_6a-2q";
export var noTextWrap = "_6a-2l";
export var nonPriceContainer = "_6a-11";
export var optionContent = "_6a-1d";
export var optionTitle = "_6a-1b";
export var orderAndTags = "_6a-3v";
export var orderLink = "_6a-20";
export var orderNames = "_6a-3w";
export var orderNumber = "_6a-3f";
export var orderNumbers = "_6a-32";
export var originalPrice = "_6a-14";
export var paddedModalContent = "_6a-27";
export var priceSummaryTitle = "_6a-1e";
export var processButtons = "_6a-28";
export var product = "_6a-22";
export var productTitle = "_6a-3u";
export var question = "_6a-3c";
export var questions = "_6a-3b";
export var returnTypeModal = "_6a-1v";
export var returnTypeModalButton = "_6a-1y";
export var returnTypeModalHeader = "_6a-1x";
export var right = "_6a-15";
export var rightAligned = "_6a-1w";
export var riskReportIframe = "_6a-2g";
export var shippingText = "_6a-c";
export var shippingTextHeader = "_6a-d";
export var slideDown = "_6a-3k";
export var slideUp = "_6a-3m";
export var status = "_6a-23";
export var subsection = "_6a-2d";
export var subtitle = "_6a-36";
export var subtitleText = "_6a-37";
export var summaryElement = "_6a-1";
export var table = "_6a-1z";
export var tableCell = "_6a-2c";
export var tableDescription = "_6a-2b";
export var tableHeader = "_6a-2a";
export var tableHeaderGroup = "_6a-29";
export var tags = "_6a-34";
export var titleText = "_6a-35";
export var tooltipLink = "_6a-2n";
export var top = "_6a-33";
export var topRow = "_6a-31";
export var trackingNumbers = "_6a-2e";
export var trash = "_6a-2m";
export var voided = "_6a-1h";