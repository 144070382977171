import { UserCallAvailability } from "@redotech/redo-model/support/voice/voice-types";
import { memo } from "react";
import { RedoListItem } from "../arbiter-components/list/redo-list";
import { StatusDotColor } from "../arbiter-components/list/redo-list-item-lead";
import { Flex } from "../flex";
import { Text } from "../text";
import { StatusDot } from "./status-dot";

export const StatusText: Record<UserCallAvailability, string> = {
  [UserCallAvailability.AVAILABLE]: "Available",
  [UserCallAvailability.IN_CALL]: "In Call",
  [UserCallAvailability.UNAVAILABLE]: "Unavailable",
};

export const UserCallAvailabilityToStatusColor: Record<
  UserCallAvailability,
  StatusDotColor
> = {
  [UserCallAvailability.AVAILABLE]: "green",
  [UserCallAvailability.IN_CALL]: "yellow",
  [UserCallAvailability.UNAVAILABLE]: "red",
};

export function statusToListItem(
  status: UserCallAvailability,
): RedoListItem<UserCallAvailability> {
  return {
    id: status,
    text: StatusText[status],
    value: status,
    type: "text",
    leadingItem: {
      type: "status",
      color: UserCallAvailabilityToStatusColor[status],
    },
  };
}

export const PhoneWidgetStatus = memo(function PhoneWidgetStatus({
  status,
}: {
  status: UserCallAvailability;
}) {
  return (
    <Flex align="center">
      <StatusDot color={UserCallAvailabilityToStatusColor[status]} />
      <Text fontSize="xs">{StatusText[status]}</Text>
    </Flex>
  );
});
