// extracted by mini-css-extract-plugin
export var bubbleContent = "_bc-0";
export var dark = "_bc-2";
export var dot = "_bc-7";
export var firstDot = "_bc-9";
export var left = "_bc-3";
export var light = "_bc-1";
export var loadingBubble = "_bc-5";
export var loadingDot = "_bc-c";
export var loadingDotContainer = "_bc-6";
export var moveUpDown = "_bc-8";
export var right = "_bc-4";
export var secondDot = "_bc-a";
export var thirdDot = "_bc-b";