import { useRequiredContext } from "@redotech/react-util/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { LinkPrimaryTab } from "@redotech/redo-web/arbiter-components/navigation/primary/redo-primary-nav";
import { PageNavigationEntry } from "@redotech/redo-web/arbiter-components/navigation/redo-side-navigation";
import { SecondaryNavigationData } from "@redotech/redo-web/arbiter-components/navigation/secondary/redo-secondary-nav";
import ShoppingCartIcon from "@redotech/redo-web/arbiter-icon/shopping-cart-03.svg";
import ShoppingCartFilledIcon from "@redotech/redo-web/arbiter-icon/shopping-cart-03_filled.svg";
import { memo } from "react";
import { AbTestItems } from "./ab-tests";
import { AnalyticsItems } from "./analytics";
import { CheckoutItems } from "./checkouts";

export function useConversionNavigation(): PageNavigationEntry | null {
  const team = useRequiredContext(TeamContext);

  const checkoutOptimizationEnabled =
    team?.settings.checkoutOptimization?.enabled;

  if (!checkoutOptimizationEnabled) {
    return null;
  }

  const pathRoot = `/stores/${team._id}/conversion`;

  const primaryTab: LinkPrimaryTab = {
    id: "conversion",
    hasNotification: false,
    UnfilledIcon: ShoppingCartIcon,
    FilledIcon: ShoppingCartFilledIcon,
    type: "link",
    href: `${pathRoot}/ab-tests`,
    pathRoot: pathRoot,
    friendlyName: "Conversion",
  };

  const secondaryNavigationData: SecondaryNavigationData = {
    id: "conversion",
    name: "Conversion",
    NavContent: SecondaryConversionSection,
  };

  return { primaryTab, secondaryNavigationData };
}

const SecondaryConversionSection = memo(function SecondaryConversionSection({
  pathRoot,
}: {
  pathRoot: string;
}) {
  return (
    <>
      <AbTestItems conversionRoot={pathRoot} />
      <CheckoutItems conversionRoot={pathRoot} />
      <AnalyticsItems conversionRoot={pathRoot} />
    </>
  );
});
