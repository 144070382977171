import MuiModal from "@mui/material/Modal";
import { DynamicVariable } from "@redotech/redo-model/advanced-flow/schemas/schemas";
import { SchemaField } from "@redotech/redo-model/advanced-flow/type-system/schema";
import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoFeaturedIcon } from "@redotech/redo-web/arbiter-components/featured-icon/redo-featured-icon";
import { RedoTextInput } from "@redotech/redo-web/arbiter-components/input/redo-text-input";
import * as redoModalCss from "@redotech/redo-web/arbiter-components/modal/redo-modal.module.css";
import {
  ButtonSize,
  RedoButtonCloseX,
} from "@redotech/redo-web/arbiter-components/redo-button-close-x";
import { RedoSingleSelectDropdownInput } from "@redotech/redo-web/arbiter-components/select-dropdown/redo-single-select-dropdown-input";
import VariableIcon from "@redotech/redo-web/arbiter-icon/brackets-ellipses_filled.svg";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { camelToTitleCase } from "@redotech/string/transform";
import * as classNames from "classnames";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { RedoListItem } from "./arbiter-components/list/redo-list";
import * as selectionModalCss from "./dynamic-variable-selection-modal.module.css";
export type DynamicVariableSelectionModalCloseCause =
  | "clickOutside"
  | "clickX"
  | "clickCancel"
  | "select"
  | "escape";

export const DynamicVariableSelectionModal = memo(
  function DynamicVariableSelectionModal({
    dynamicVariables,
    handleDynamicVariableSelected,
    onClose,
    open,
    closeOnSelect = true,
  }: {
    dynamicVariables: readonly SchemaField[];
    handleDynamicVariableSelected: (variable: DynamicVariable) => void;
    onClose: (cause: DynamicVariableSelectionModalCloseCause) => void;
    open: boolean;
    closeOnSelect?: boolean;
  }) {
    const [selectedVariable, setSelectedVariable] = useState<SchemaField>();
    const [defaultForVariable, setDefaultForVariable] = useState<string>();
    const [linkText, setLinkText] = useState<string>();

    const submitEnabled = useMemo(() => {
      if (!selectedVariable) return false;
      if (selectedVariable.dataType.includes("Url")) {
        return !!linkText;
      }
      return true;
    }, [selectedVariable, linkText]);

    const onSubmit = useCallback(() => {
      if (!submitEnabled || !selectedVariable) return;
      if (selectedVariable.dataType.includes("Url")) {
        handleDynamicVariableSelected({
          key: selectedVariable.key,
          type: "url",
          text: linkText!, // Safe because of submitEnabled check
        });
      } else {
        handleDynamicVariableSelected({
          key: selectedVariable.key,
          type: "text",
          default: defaultForVariable,
        });
      }

      if (closeOnSelect) {
        onClose("select");
      }
    }, [
      submitEnabled,
      selectedVariable,
      defaultForVariable,
      linkText,
      closeOnSelect,
      onClose,
      handleDynamicVariableSelected,
    ]);

    // Attach escape key listener
    useEffect(() => {
      if (!open) return;
      const onKeyDown = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
          onClose("escape");
        }
      };
      window.addEventListener("keydown", onKeyDown);
      return () => {
        window.removeEventListener("keydown", onKeyDown);
      };
    }, [open, onClose]);

    const options: RedoListItem<SchemaField>[] = useMemo(
      () =>
        dynamicVariables.map((variable) => {
          const titleCase = camelToTitleCase(variable.key);
          const optionName = titleCase[0] + titleCase.slice(1).toLowerCase();
          return {
            id: variable.key,
            type: "text",
            text: optionName,
            value: variable,
          };
        }),
      [dynamicVariables],
    );

    const muiModalCloseCallback = useCallback(
      (event: {}, muiReason?: "backdropClick" | "escapeKeyDown") => {
        let reason: DynamicVariableSelectionModalCloseCause;
        if (muiReason === "backdropClick") {
          reason = "clickOutside";
        } else if (muiReason === "escapeKeyDown") {
          reason = "escape";
        } else {
          reason = "clickX";
        }
        onClose(reason);
      },
      [onClose],
    );

    return (
      <MuiModal onClose={muiModalCloseCallback} open={open}>
        <article
          className={classNames(
            redoModalCss.modal,
            selectionModalCss.selectorModal,
          )}
        >
          <Flex dir="column" p="3xl">
            {/* Header */}
            <Flex dir="column" gap="xl">
              <RedoFeaturedIcon Icon={VariableIcon} size="lg" />
              <Flex dir="column" gap="xs">
                <Text fontSize="lg" fontWeight="semibold">
                  Insert a variable
                </Text>
                <Text fontSize="sm" textColor="tertiary">
                  This will be dynamic per subscriber.
                </Text>
              </Flex>

              <RedoSingleSelectDropdownInput
                label="Variable"
                options={options}
                optionSelected={({ item }) => setSelectedVariable(item.value)}
                placeholder="Select variable"
                selectedItem={options.find(
                  (item) => item.id === selectedVariable?.key,
                )}
                size="xs"
              />
              {selectedVariable?.dataType.includes("Url") && (
                <RedoTextInput
                  label="Link text"
                  placeholder="Set link text"
                  setValue={setLinkText}
                  value={linkText ?? ""}
                />
              )}
              {!selectedVariable?.dataType.includes("Url") && (
                <RedoTextInput
                  infoTooltip="This text will appear anytime we don't have the variable information for a subscriber"
                  label="Default value"
                  placeholder="Set a default"
                  setValue={setDefaultForVariable}
                  value={defaultForVariable ?? ""}
                />
              )}
            </Flex>
            {/* Header */}

            {/* Footer */}
            <Flex alignSelf="flex-end" pt="3xl">
              <RedoButton
                hierarchy="secondary"
                onClick={() => onClose("clickCancel")}
                size="md"
                text="Cancel"
              />
              <RedoButton
                disabled={!submitEnabled}
                hierarchy="primary"
                onClick={onSubmit}
                size="md"
                text="Insert"
              />
            </Flex>
            {/* Footer */}

            {/* X button */}
            <Flex className={selectionModalCss.xButton}>
              <RedoButtonCloseX
                onclick={() => onClose("clickX")}
                onDarkBackground={false}
                size={ButtonSize.LARGE}
              />
            </Flex>
            {/* X button */}
          </Flex>
        </article>
      </MuiModal>
    );
  },
);
