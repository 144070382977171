export type E164PhoneNumber = `+${number}`;

export function convertArbitraryPhoneNumberToE164(
  phoneNumber: string,
  defaultCountryCode: string = "+1",
): E164PhoneNumber | null {
  const sanitizedPhoneNumber = phoneNumber.replace(/[^\d+]/g, "");
  if (sanitizedPhoneNumber.startsWith("+")) {
    return sanitizedPhoneNumber as E164PhoneNumber;
  } else if (
    sanitizedPhoneNumber.length === 11 &&
    sanitizedPhoneNumber.startsWith("1")
  ) {
    return `+${sanitizedPhoneNumber}` as E164PhoneNumber;
  } else {
    const e164Number = `${defaultCountryCode}${sanitizedPhoneNumber}`;
    const digitsOnly = e164Number.replace(/\D/g, "");
    if (digitsOnly.length >= 10 && digitsOnly.length <= 15) {
      return e164Number as E164PhoneNumber;
    } else {
      return null;
    }
  }
}

export function isValidE164PhoneNumber(phoneNumber: string): boolean {
  return /^\+[1-9]\d{7,14}$/.test(phoneNumber);
}
