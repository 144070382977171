import { Redirect } from "@redotech/react-router-util/redirect";
import { matchPath, Navigate, RouteObject } from "react-router-dom";
import {
  automationRoutes,
  backwardsCompatibleEmailSmsRoutes,
  emailSmsRoutes,
} from "../../../marketing/route";
import { reviewsRoutes } from "../../../reviews/route";
import { aboutOneClickUpsellRoutes } from "../../../setting/about-one-click-upsell/route";
import { trackingRoutes } from "../../../setting/about-tracking/route";

const HIDE_PADDING_ROUTES: string[] = [
  "/reviews/all-reviews",
  "/reviews/products",
  "/one-click-upsell/upsell-page/*",
  "/email-sms/audience",
  "/automations/*",
  "/email-sms/campaigns/*",
  "/email-sms/segments/*",
  "/email-sms/templates/email/*",
  "/email-sms/templates/sms/*",
  "/email-sms/campaigns/:campaignId/details",
  "/email-sms/signups/*",
  "/tracking/tracking-page",
];

function hidePadding(url: string): boolean {
  return HIDE_PADDING_ROUTES.some(
    (route) => !!matchPath(`/stores/:storeId/marketing${route}`, url),
  );
}

const FULLSCREEN_ROUTES: string[] = [
  "/upsell-page/:id",
  "/email-sms/signups/builder/:id",
  "/email-sms/templates/email/:id",
  "/email-sms/templates/sms/:id",
  "/email-sms/segments/:segmentId",
  "/email-sms/campaigns/:campaignId/edit/*",
  "/automations/:automationId",
  "/one-click-upsell/upsell-page/:upsellId/:stepId",
  "/tracking/tracking-page",
];

const HIDE_HEADER_ROUTES: string[] = [
  "/tracking/about",
  "/one-click-upsell/about",
  ...FULLSCREEN_ROUTES,
];
function hideHeader(url: string): boolean {
  return HIDE_HEADER_ROUTES.some(
    (route) => !!matchPath(`/stores/:storeId/marketing${route}`, url),
  );
}

const HIDE_NAV_ROUTES: string[] = [...FULLSCREEN_ROUTES];

function hideNav(url: string): boolean {
  return HIDE_NAV_ROUTES.some(
    (route) => !!matchPath(`/stores/:storeId/marketing${route}`, url),
  );
}

export const marketingRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Marketing" },
    async lazy() {
      const { Page } = await import("../../page");
      return {
        element: (
          <Page
            hideHeader={hideHeader}
            hideNavbar={hideNav}
            hidePadding={hidePadding}
          />
        ),
      };
    },
    children: [
      { index: true, element: <Navigate to="email-sms" /> },
      { path: "reviews", children: reviewsRoutes },
      { path: "tracking", children: trackingRoutes },
      { path: "one-click-upsell", children: aboutOneClickUpsellRoutes },
      { path: "email-sms", children: emailSmsRoutes },
      { path: "automations", children: automationRoutes },
      ...backwardsCompatibleEmailSmsRoutes,
    ],
  },
];

export const backwardsCompatibleMarketingRoutes: RouteObject[] = [
  {
    path: "reviews",
    children: [
      {
        children: [
          {
            index: true,
            element: (
              <Redirect match={/reviews/} replaceValue="marketing/reviews" />
            ),
          },
          {
            path: "*",
            element: (
              <Redirect match={/reviews/} replaceValue="marketing/reviews" />
            ),
          },
        ],
      },
    ],
  },
  {
    path: "tracking",
    children: [
      {
        children: [
          {
            index: true,
            element: (
              <Redirect match={/tracking/} replaceValue="marketing/tracking" />
            ),
          },
          {
            path: "*",
            element: (
              <Redirect match={/tracking/} replaceValue="marketing/tracking" />
            ),
          },
        ],
      },
    ],
  },
  {
    path: "one-click-upsell",
    children: [
      {
        children: [
          {
            index: true,
            element: (
              <Redirect
                match={/one-click-upsell/}
                replaceValue="marketing/one-click-upsell"
              />
            ),
          },
          {
            path: "*",
            element: (
              <Redirect
                match={/one-click-upsell/}
                replaceValue="marketing/one-click-upsell"
              />
            ),
          },
        ],
      },
    ],
  },
];
