import { ReviewResult } from "@redotech/redo-model/agents/review-result";
import { Return } from "@redotech/redo-model/return";
import {
  RedoBadge,
  RedoBadgeProps,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import StarsIcon from "@redotech/redo-web/arbiter-icon/stars-01-gradient.svg";
import { Card } from "@redotech/redo-web/card";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { assertNever } from "@redotech/util/type";
import { memo } from "react";

export const AiReview = memo(function AiReview({
  return_,
}: {
  return_: Return;
}) {
  if (!return_.agentReview) return null;
  const review = return_.agentReview;
  return (
    <Card
      customBackgroundColor="var(--redo-colors-background-bg-tertiary)"
      gap="md"
      p="xl"
      radius="md"
      title={
        <Flex align="center" dir="row">
          <StarsIcon height={24} />
          <Text fontSize="sm" fontWeight="semibold">
            AI Review
          </Text>
          <RedoBadge {...getRiskBadgeProps(review.risk)} />
        </Flex>
      }
    >
      <Text fontSize="sm">{review.explanation}</Text>
    </Card>
  );
});

function getRiskBadgeProps(risk: ReviewResult["risk"]): RedoBadgeProps {
  switch (risk) {
    case "low":
      return { color: "success", text: "Low risk" };
    case "medium":
      return { color: "warning", text: "Medium risk" };
    case "high":
      return { color: "error", text: "High risk" };
    default:
      assertNever(risk);
  }
}
