import { genericMemo } from "@redotech/react-util/component";
import { useHandler } from "@redotech/react-util/hook";
import { RedoFilterDropdownAnchor } from "@redotech/redo-web/arbiter-components/filter-group/redo-filter-group";
import { useEffect, useMemo, useState } from "react";
import { RedoListItem } from "../arbiter-components/list/redo-list";
import { RedoListDropdown } from "../arbiter-components/list/redo-list-dropdown";

export const SimpleFilterDropdown = genericMemo(function SimleFilterDropdown<
  T extends string,
>({
  value,
  setValue,
  options,
  optionToFriendlyName,
  openOnRender,
  filterStyle,
}: {
  value: T | null | undefined;
  setValue(query: T): void;
  options: T[];
  optionToFriendlyName: (option: T) => string;
  openOnRender?: boolean;
  filterStyle: "query" | "value";
}) {
  const optionItems: RedoListItem<T>[] = useMemo(() => {
    return options.map((option) => ({
      id: `${option}`,
      value: option,
      type: "text",
      text: optionToFriendlyName(option),
    }));
  }, [options, optionToFriendlyName]);

  const [dropdownButtonRef, setDropdownButtonRef] =
    useState<HTMLButtonElement | null>(null);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    if (openOnRender && dropdownButtonRef) {
      dropdownButtonRef.click();
    }
  }, [openOnRender, dropdownButtonRef]);

  const selectOption = useHandler(({ item }: { item: RedoListItem<T> }) =>
    setValue(item.value),
  );

  return (
    <>
      <RedoFilterDropdownAnchor
        color={filterStyle === "query" ? "secondary" : "primary"}
        onClick={() => setDropdownOpen(!dropdownOpen)}
        ref={setDropdownButtonRef}
        text={value ? optionToFriendlyName(value) : "..."}
        weight={filterStyle === "query" ? "regular" : "semibold"}
      />
      <RedoListDropdown<T>
        dropdownAnchor={dropdownButtonRef}
        dropdownOpen={dropdownOpen}
        fitToAnchor={false}
        items={optionItems}
        itemSelected={selectOption}
        selectedItems={value || undefined}
        setDropdownOpen={setDropdownOpen}
        size="xs"
      />
    </>
  );
});
