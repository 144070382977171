import { memo } from "react";
import { Flex } from "../flex";
import * as dotsLoadingCss from "./dots-loading.module.css";

export const DotsLoading = memo(function DotsLoading() {
  return (
    <Flex
      className={dotsLoadingCss.container}
      dir="column"
      gap="xs"
      justify="center"
    >
      <div className={dotsLoadingCss.dotsLoading} />
    </Flex>
  );
});
