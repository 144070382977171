import { useRequiredContext } from "@redotech/react-util/context";
import { v3FiltersFormat } from "@redotech/redo-model/conversation-filters/conversation-filter-formats";
import { ConversationFiltersV3 } from "@redotech/redo-model/conversation-filters/conversation-filters";
import { SystemView } from "@redotech/redo-model/conversation-filters/system-view";
import { CreateViewBody } from "@redotech/redo-model/create-view-body";
import { RedoCheckbox } from "@redotech/redo-web/arbiter-components/checkbox/redo-checkbox";
import { RedoTextInput } from "@redotech/redo-web/arbiter-components/input/redo-text-input";
import {
  RedoModal,
  RedoModalButtonProps,
} from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo, useState } from "react";
import { ViewsContext } from "../../app/views";

export const SaveViewModal = memo(function SaveViewModal({
  cancel,
  save,
  activeFilters,
}: {
  activeFilters: ConversationFiltersV3;
  save(view: CreateViewBody): Promise<void>;
  cancel(): void;
}) {
  const teamAndUserViews = useRequiredContext(ViewsContext);
  const [makeViewPrivate, setMakeViewPrivate] = useState(false);
  const [viewName, setViewName] = useState("");

  const userDefinedViewNames = [...teamAndUserViews].map((view) => view.name);
  const systemViewNames = Object.values(SystemView);
  const reservedViewNames = new Set(
    [...userDefinedViewNames, ...systemViewNames].map((name) =>
      name.toLowerCase(),
    ),
  );

  const sumitDisabledBecauseOfEmptyName =
    viewName.length === 0 ? "You must name this view" : false;
  const submitDisabledBecauseOfDuplicateName = reservedViewNames.has(
    viewName.toLowerCase(),
  )
    ? "A view with this name already exists"
    : false;

  const submitDisabled =
    sumitDisabledBecauseOfEmptyName || submitDisabledBecauseOfDuplicateName;

  const primaryButton: RedoModalButtonProps = {
    text: "Confirm",
    onClick: async () => {
      const view: CreateViewBody = {
        filters: v3FiltersFormat.write(activeFilters),
        name: viewName,
        isPrivate: makeViewPrivate,
      };
      await save(view);
    },
    disabled: submitDisabled,
  };

  const secondaryButton: RedoModalButtonProps = {
    text: "Cancel",
    onClick: cancel,
  };

  return (
    <RedoModal
      buttonPlacement="full"
      onModalCloseRequested={cancel}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      title="Save as view"
    >
      <Flex dir="column" gap="xl">
        <Flex dir="column">
          <Text>Name</Text>
          <RedoTextInput setValue={setViewName} size="md" value={viewName} />
        </Flex>
        <RedoCheckbox
          label="Make view private"
          setValue={(value) => {
            setMakeViewPrivate(!!value);
          }}
          value={makeViewPrivate}
        />
        <div />
      </Flex>
    </RedoModal>
  );
});
