import { GoogleAdsConnectionSchema } from "@redotech/redo-model/integration/google-ads/google-ads-definition";
import { z } from "zod";

export const getGoogleAdsIntegration = {
  input: z.null(),
  output: z.object({
    exists: z.boolean(),
    customerId: z.string().optional(),
    connections: z.array(GoogleAdsConnectionSchema),
  }),
};
