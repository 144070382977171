import { PrintableDocument } from "@redotech/redo-model/fulfillments/fullfilment-print-status";
import { PrinterOptionsSchema } from "@redotech/redo-model/printnode/printer";
import { z } from "zod";

export const DocumentTypeSchema = z.nativeEnum(PrintableDocument);

export const printDocument = {
  input: z.intersection(
    z.union([
      z.object({ fulfillmentGroupId: z.string() }),
      z.object({ batchId: z.string() }),
    ]),
    z.object({
      documentType: DocumentTypeSchema,
      templateId: z.string().optional(),
      printerId: z.number().optional(),
      printerOptions: PrinterOptionsSchema.optional(),
    }),
  ),
  output: z.object({ url: z.string() }),
};

export type PrintDocumentInput = z.infer<typeof printDocument.input>;
