// extracted by mini-css-extract-plugin
export var activityItem = "_6c-0";
export var avatarContainer = "_6c-5";
export var commentImage = "_6c-9";
export var deleteButton = "_6c-f";
export var dotContainer = "_6c-b";
export var externalLink = "_6c-3";
export var icon = "_6c-c";
export var input = "_6c-7";
export var inputContainer = "_6c-6";
export var leftContent = "_6c-d";
export var messageBox = "_6c-8";
export var messageInputWrapper = "_6c-a";
export var rightContent = "_6c-e";
export var timelineContainer = "_6c-1";
export var timelineItem = "_6c-2";
export var timelineLine = "_6c-4";