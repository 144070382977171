// extracted by mini-css-extract-plugin
export var actionButton = "_c9-0";
export var bubble = "_c9-2";
export var bubbleContext = "_c9-6";
export var bubbleItems = "_c9-7";
export var customer = "_c9-3";
export var divider = "_c9-8";
export var fileList = "_c9-9";
export var infoAbove = "_c9-a";
export var internal = "_c9-4";
export var lightActionButton = "_c9-1";
export var link = "_c9-b";
export var merchant = "_c9-5";
export var message = "_c9-c";
export var profilePicture = "_c9-e";
export var quillToolbarContainer = "_c9-f";
export var senderDate = "_c9-g";
export var senderDateCustomer = "_c9-h";
export var senderName = "_c9-d";
export var time = "_c9-i";
export var uploadedImage = "_c9-j";
export var username = "_c9-k";
export var xsmall = "_c9-l";