import { ShopifyFulfillmentHoldReason } from "@redotech/redo-model/fulfillments/shopify-fulfillment-schemas";
import { z } from "zod";

export const placeHolds = {
  input: z.object({
    fulfillmentGroupIds: z.array(z.string()),
    holdUntil: z.coerce.date().nullish(),
    reason: z.string().nullish(),
    reasonOption: z.nativeEnum(ShopifyFulfillmentHoldReason),
  }),
  output: z.object({ failedGroupIds: z.array(z.string()) }),
};
