// extracted by mini-css-extract-plugin
export var createdDate = "_ap-1";
export var divider = "_ap-2";
export var headerDivider = "_ap-3";
export var messagesCard = "_ap-0";
export var messagesContainer = "_ap-7";
export var messagesContainerWrapper = "_ap-4";
export var pointerCapturingButtonBoxes = "_ap-a";
export var showLeftPanelButton = "_ap-9";
export var showMoreButton = "_ap-c";
export var showMoreButtonContainer = "_ap-b";
export var summaryTitle = "_ap-6";
export var systemMessageCardWrapper = "_ap-d";
export var topButtons = "_ap-8";
export var withPadding = "_ap-5";