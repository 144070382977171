import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo } from "react";
import * as customerActivityCardCss from "../../../../common/activity-card.module.css";

export const MessageBubble = memo(function MessageBubble({
  text,
}: {
  text: string;
}) {
  return (
    <Flex className={customerActivityCardCss.messageBubble} flex={1} p="lg">
      <Text
        className={customerActivityCardCss.messageBubbleText}
        fontSize="xs"
        textColor="secondary"
      >
        {text}
      </Text>
    </Flex>
  );
});
