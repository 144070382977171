import { z } from "zod";
import { zExt } from "../common/zod-util";
import { PresetSchema } from "./presets";

export enum LearnedPresetKind {
  CARRIER_SERVICE_LEVEL = "carrier-service-level",
  PARCEL = "parcel",
  WEIGHT = "weight",
}

const LearnedPresetOutputSchema = PresetSchema.omit({
  _id: true,
  name: true,
  hotkey: true,
});
export type LearnedPresetOutput = z.infer<typeof LearnedPresetOutputSchema>;

export const LearnedPresetSchema = z.object({
  kind: z.nativeEnum(LearnedPresetKind),
  preset: LearnedPresetOutputSchema,
  key: z.string(),
  learnedFrom: zExt.objectId(),
});
export type LearnedPreset = z.infer<typeof LearnedPresetSchema>;
