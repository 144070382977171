import { genericMemo } from "@redotech/react-util/component";
import { Primitive } from "@redotech/util/type";
import { Flex } from "../../flex";
import { DescriptionText, LabelText } from "../common/descriptors";
import {
  RedoRadioButton,
  RedoRadioButtonSize,
  sizeToDescriptionTextSize,
  sizeToLabelTextSize,
} from "./redo-radio-button";

export interface RadioGroupOption<T extends Primitive> {
  id: T;
  label?: string;
  description?: string;
  disabled?: boolean;
}

export interface RedoRadioGroupProps<T extends Primitive> {
  options: RadioGroupOption<T>[];
  value: T;
  setValue(value: T): void;
  disabled?: boolean;
  size?: RedoRadioButtonSize;
  label?: string;
  description?: string;
  /**
   * @deprecated This prop's functionality will be replaced with an updated `RedoCheckboxCard` component.
   */
  bordered?: boolean;
}

export const RedoRadioGroup = genericMemo(function RedoRadioGroup<
  T extends Primitive,
>({
  options,
  value,
  setValue,
  disabled,
  size = "xs",
  description,
  label,
  bordered,
}: RedoRadioGroupProps<T>) {
  return (
    <Flex dir="column">
      <LabelText label={label} size={sizeToLabelTextSize[size]} />
      {options.map((option) => {
        return (
          <RedoRadioButton
            bordered={bordered}
            description={option.description}
            disabled={disabled || option.disabled}
            key={`${option.id}`}
            label={option.label}
            onSelect={(selected) => {
              if (selected) {
                setValue(option.id);
              }
            }}
            selected={value === option.id}
            size={size}
          />
        );
      })}
      <DescriptionText
        description={description}
        size={sizeToDescriptionTextSize[size]}
      />
    </Flex>
  );
});
