import PlusIcon from "@redotech/redo-web/arbiter-icon/plus.svg";
import { createContext, memo, useContext, useMemo, useState } from "react";
import { Tooltip } from "../../../tooltip/tooltip";
import { AvatarStatus, RedoAvatar } from "../../avatar/redo-avatar";
import { RedoListItem } from "../../list/redo-list";
import { RedoListDropdown } from "../../list/redo-list-dropdown";
import * as navigationCss from "../redo-navigation.module.css";

export interface ConnectedTeamData {
  teamId: string;
  name: string;
  logoUrl?: string;
  ticketCount: number;
}

export interface CurrentTeamData {
  teamId: string;
  name: string;
  logoUrl?: string;
}

export interface TeamSwitcherData {
  connectedStoresHaveTickets: boolean;
  connectedStores: ConnectedTeamData[];
  setCurrentTeam: (teamId: string) => void;
  currentTeam: CurrentTeamData;
  addStore: () => void;
}

export const TeamDataContext = createContext<TeamSwitcherData | undefined>(
  undefined,
);

export const RedoNavigationTeamItem = memo(function RedoNavigationTeamItem() {
  const teamData = useContext(TeamDataContext);

  const status: AvatarStatus | undefined = teamData?.connectedStoresHaveTickets
    ? { type: "presence", color: "red" }
    : undefined;

  const [dropdownAnchor, setDropdownAnchor] = useState<HTMLElement | null>(
    null,
  );

  const [switcherDropdownOpen, setSwitcherDropdownOpen] = useState(false);

  const teamSwitcherList: RedoListItem<string>[] = useMemo(() => {
    if (!teamData) {
      return [];
    }

    const { connectedStores, currentTeam } = teamData;

    const currentTeamItem: RedoListItem<string> = {
      id: currentTeam.teamId,
      value: currentTeam.teamId,
      type: "text",
      text: currentTeam.name,
      leadingItem: {
        type: "avatar",
        imageUrl: currentTeam.logoUrl,
        alt: currentTeam.name,
        name: currentTeam.name,
      },
    };

    const connectedTeams: RedoListItem<string>[] = connectedStores.map(
      (connectedStore) => {
        return {
          id: connectedStore.teamId,
          value: connectedStore.teamId,
          type: "text",
          text: connectedStore.name,
          infoBadge: {
            type: "alternate",
            text: connectedStore.ticketCount,
            color: connectedStore.ticketCount > 0 ? "error" : "black",
          },
          leadingItem: {
            type: "avatar",
            imageUrl: connectedStore.logoUrl,
            alt: connectedStore.name,
            name: connectedStore.name,
          },
        };
      },
    );

    const addTeam: RedoListItem<string> = {
      id: "add-team",
      type: "text",
      text: "Add another store",
      leadingItem: { type: "icon", Icon: PlusIcon },
      value: "add-team",
    };

    return [currentTeamItem, ...connectedTeams, addTeam];
  }, [teamData]);

  if (!teamData) {
    return null;
  }

  const { currentTeam, setCurrentTeam } = teamData;

  return (
    <>
      <Tooltip darkMode placement="right" title={currentTeam.name}>
        <button
          className={navigationCss.teamItem}
          onClick={() => setSwitcherDropdownOpen((old) => !old)}
          ref={setDropdownAnchor}
        >
          <RedoAvatar
            content={{
              type: "image",
              imageUrl: currentTeam.logoUrl,
              altText: currentTeam.name,
              backup: { type: "text", text: currentTeam.name },
            }}
            size="sm"
            status={status}
          />
        </button>
      </Tooltip>
      <RedoListDropdown
        darkMode
        dropdownAnchor={dropdownAnchor}
        dropdownOpen={switcherDropdownOpen}
        items={teamSwitcherList}
        itemSelected={({ item }) => {
          if (item.id === "add-team") {
            teamData.addStore();
          } else {
            setCurrentTeam(item.value);
          }
        }}
        selectedItems={currentTeam.teamId}
        setDropdownOpen={setSwitcherDropdownOpen}
        size="sm"
      />
    </>
  );
});
