import type { RedoConciergeRpcClient } from "@redotech/merchant-sdk/concierge-rpc/client";
import { createRedoConciergeRpcClient } from "@redotech/merchant-sdk/concierge-rpc/client";
import { Context, createContext, ReactNode, useMemo } from "react";

export const RedoConciergeRpcClientContext: Context<
  RedoConciergeRpcClient | undefined
> = createContext<RedoConciergeRpcClient | undefined>(undefined);
RedoConciergeRpcClientContext.displayName = "RedoConciergeRpcClientContext";

export function RedoConciergeRpcClientProvider({
  children,
  baseUrl,
  authToken,
}: {
  children: ReactNode | ReactNode[];
  baseUrl: string;
  authToken?: string;
}) {
  const redoConciergeRpcClient = useMemo(() => {
    if (!authToken) {
      return undefined;
    }
    return createRedoConciergeRpcClient({
      baseURL: new URL(`${baseUrl}/concierge-rpc`),
      headers: { Authorization: authToken },
    });
  }, [baseUrl, authToken]);
  return (
    <RedoConciergeRpcClientContext.Provider value={redoConciergeRpcClient}>
      {children}
    </RedoConciergeRpcClientContext.Provider>
  );
}
