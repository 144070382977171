import {
  DiscountCodeGenerationStrategy,
  RedoDiscount,
  RedoDiscountConfiguration,
} from "@redotech/merchant-sdk/marketing-rpc/schema/discounts/discount-schemas";
import {
  createLazyContext,
  useLazyContext,
} from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { useSearch } from "@redotech/react-util/search";
import {
  DiscountGroupType,
  DiscountProvider,
  DiscountValueType,
  ExpirationType,
} from "@redotech/redo-model/discount";
import { DiscountSettingsType } from "@redotech/redo-model/discount/discount-db-parser";
import { BadgeRedoListItem } from "@redotech/redo-web/arbiter-components/list/redo-list";
import EditPencilIcon from "@redotech/redo-web/arbiter-icon/edit-02.svg";
import TrashIcon from "@redotech/redo-web/arbiter-icon/trash-03.svg";
import {
  dateToCurrentPlainDate,
  plainDateToCurrentDate,
} from "@redotech/util/temporal";
import { assertNever } from "@redotech/util/type";
import Fuse from "fuse.js";
import * as isInteger from "lodash/isInteger";
import {
  createContext,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { RedoButton } from "../arbiter-components/buttons/redo-button";
import { RedoCheckbox } from "../arbiter-components/checkbox/redo-checkbox";
import { RedoSingleDatePicker } from "../arbiter-components/date-picker/redo-single-date-picker";
import { RedoTextInput } from "../arbiter-components/input/redo-text-input";
import { RedoListItem } from "../arbiter-components/list/redo-list";
import { RedoModal } from "../arbiter-components/modal/redo-modal";
import { RedoRadioGroup } from "../arbiter-components/radio/redo-radio-group";
import { RedoSingleSelectDropdownInput } from "../arbiter-components/select-dropdown/redo-single-select-dropdown-input";
import { RedoTagsDropdownInput } from "../arbiter-components/select-dropdown/redo-tags-dropdown-input";
import DiscountIcon from "../arbiter-icon/tag-01.svg";
import { Flex } from "../flex";
import { LabeledInput, LabelSize } from "../labeled-input";
import { Text } from "../text";

export interface DiscountMethodsContextI {
  deleteDiscount: (id: string) => Promise<void>;
  createDiscount: (args: {
    discountConfiguration: RedoDiscountConfiguration;
  }) => Promise<string>;
  updateDiscount: (args: {
    discountId: string;
    discountConfiguration: RedoDiscountConfiguration;
  }) => Promise<void>;
}

const EmptyDiscountMethodsContext: DiscountMethodsContextI = {
  deleteDiscount: async () => {},
  createDiscount: async () => "",
  updateDiscount: async () => {},
};

export const CollectionsContext =
  createLazyContext<{ id: string; name: string }[]>();
export const DiscountsContext = createLazyContext<RedoDiscount[]>();

export const DiscountMethodsContext = createContext<DiscountMethodsContextI>(
  EmptyDiscountMethodsContext,
);

export const DiscountSelector = memo(function DiscountSelector({
  discountId,
  setDiscountId,
}: {
  discountId: string | undefined;
  setDiscountId: (id: string | undefined) => void;
}) {
  const { deleteDiscount } = useContext(DiscountMethodsContext);

  const [discounts] = useLazyContext(DiscountsContext);

  const [discountModalOpen, setDiscountModalOpen] = useState(false);

  const [discountBeingUpdated, setDiscountBeingUpdated] = useState<
    RedoDiscount | undefined
  >();

  const options: RedoListItem<string>[] = useMemo(() => {
    if (!discounts.value) {
      return [];
    }
    return discounts.value.map((discount) => ({
      id: discount._id,
      type: "text",
      text: discount.name,
      value: discount._id,
      menu: [
        {
          onClick: async () => {
            setDiscountBeingUpdated(discount);
            setDiscountModalOpen(true);
          },
          text: "Edit",
          Icon: EditPencilIcon,
        },
        {
          onClick: async () => {
            await deleteDiscount(discount._id);
            setDiscountId(undefined);
            setDiscountId(undefined);
          },
          text: "Delete",
          Icon: TrashIcon,
          theme: "destructive",
        },
      ],
    }));
  }, [discounts.value, setDiscountId, deleteDiscount]);

  const selectedDiscount = options.find((option) => option.id === discountId);

  return (
    <Flex dir="column" gap="sm">
      <RedoSingleSelectDropdownInput
        disabled={discounts.value?.length === 0}
        itemsLoading={discounts.pending}
        options={options}
        optionSelected={({ item }) => {
          setDiscountId(item.value);
        }}
        placeholder="Select a discount"
        selectedItem={selectedDiscount}
        size="xs"
      />
      <RedoButton
        hierarchy="secondary"
        onClick={() => {
          setDiscountModalOpen(true);
        }}
        size="sm"
        text="Create discount +"
      />
      <DiscountModal
        existingDiscount={discountBeingUpdated}
        isOpen={discountModalOpen}
        key={`${discountModalOpen}`}
        onModalCloseRequested={() => {
          setDiscountBeingUpdated(undefined);
          setDiscountModalOpen(false);
        }}
        value={discountId}
        valueChange={(value) => {
          setDiscountId(value);
        }}
      />
    </Flex>
  );
});

const DiscountModal = memo(function DiscountModal({
  value,
  valueChange,
  isOpen,
  onModalCloseRequested,
  existingDiscount,
}: {
  value: string | undefined;
  valueChange: (value: string) => void;
  isOpen: boolean;
  onModalCloseRequested: () => void;
  existingDiscount: RedoDiscount | undefined;
}) {
  const { updateDiscount, createDiscount } = useContext(DiscountMethodsContext);

  const [discounts] = useLazyContext(DiscountsContext);

  const [discountName, setDiscountName] = useState(
    existingDiscount?.name || "",
  );

  const [codeGenerationStrategy, setCodeGenerationStrategy] =
    useState<DiscountGroupType>(
      existingDiscount?.codeGenerationStrategy.strategy ||
        DiscountGroupType.STATIC,
    );

  const [codeStrategyName, setCodeStrategyName] = useState(() => {
    if (!existingDiscount) {
      return "DISCOUNT";
    }
    return existingDiscount.codeGenerationStrategy.code;
  });

  const [discountAmount, setDiscountAmount] = useState<number | undefined>(
    existingDiscount?.discountSettings.discountValueAmount || undefined,
  );
  const [discountValueType, setDiscountValueType] = useState<DiscountValueType>(
    existingDiscount?.discountSettings.discountValueType ||
      DiscountValueType.PERCENTAGE,
  );
  const [combineDiscountWith, setCombineDiscountWith] = useState<{
    orderDiscounts: boolean;
    productDiscounts: boolean;
    shippingDiscount: boolean;
  }>(() => {
    if (!existingDiscount) {
      return {
        orderDiscounts: false,
        productDiscounts: false,
        shippingDiscount: false,
      };
    } else {
      const existingCombinesWith =
        existingDiscount.discountSettings.combinesWith;
      return {
        orderDiscounts: existingCombinesWith.orderDiscounts || false,
        productDiscounts: existingCombinesWith.productDiscounts || false,
        shippingDiscount: existingCombinesWith.shippingDiscount || false,
      };
    }
  });

  const [excludeOnSale, setExcludeOnSale] = useState(
    existingDiscount?.discountSettings.excludeItemsOnSale || false,
  );
  const [specifyCollections, setSpecifyCollections] = useState(() => {
    if (!existingDiscount) {
      return false;
    }
    return (
      !!existingDiscount.discountSettings.appliesToCollections &&
      !!existingDiscount.discountSettings.appliesToCollections.length
    );
  });

  const [collectionIds, setCollectionIds] = useState<string[]>(
    existingDiscount?.discountSettings.appliesToCollections || [],
  );

  const [expiration, setExpiration] = useState<RedoDiscount["expiration"]>(
    existingDiscount?.expiration || { expirationType: ExpirationType.NEVER },
  );
  useEffect(() => {
    if (
      codeGenerationStrategy === DiscountGroupType.STATIC &&
      [ExpirationType.EXPIRATION_DAYS, ExpirationType.DATE].includes(
        expiration.expirationType,
      )
    ) {
      setExpiration({ expirationType: ExpirationType.NEVER });
    }
  }, [codeGenerationStrategy, expiration.expirationType]);

  const handleSetExcludeOnSale = useHandler((checked: boolean) => {
    setExcludeOnSale(checked);
    if (checked) {
      setSpecifyCollections(false);
    }
  });

  const handleSetSpecifyCollections = useHandler((checked: boolean) => {
    setSpecifyCollections(checked);
    if (checked) {
      setExcludeOnSale(false);
    }
  });

  const discountValidationResult: RedoDiscountConfiguration | string =
    useMemo(() => {
      if (excludeOnSale && specifyCollections) {
        return "Invalid state";
      }

      if (existingDiscount) {
        if (
          codeGenerationStrategy !==
          existingDiscount.codeGenerationStrategy.strategy
        ) {
          return "You cannot change the type of a discount once it is created";
        }
        if (
          codeStrategyName.toLowerCase() !==
          existingDiscount.codeGenerationStrategy.code.toLowerCase()
        ) {
          return "You cannot change the code of a discount once it is created";
        }
      }

      if (codeGenerationStrategy === DiscountGroupType.STATIC) {
        const hasStaticNamingConflict = discounts.value?.some(
          (loadedDiscount) => {
            return (
              loadedDiscount.codeGenerationStrategy.strategy ===
                DiscountGroupType.STATIC &&
              loadedDiscount.codeGenerationStrategy.code.toLowerCase() ===
                codeStrategyName.toLowerCase() &&
              loadedDiscount._id !== existingDiscount?._id
            );
          },
        );
        if (hasStaticNamingConflict) {
          return "Static code names must be unique";
        }
      }

      if (!discountName) {
        return "The discount must have a name";
      }

      if (!discountAmount || discountAmount < 0) {
        return "The discount amount must be greater than 0";
      }

      if (
        discountValueType === DiscountValueType.PERCENTAGE &&
        discountAmount > 100
      ) {
        return "A percentage must be between 1 and 100";
      }

      if (
        discountValueType === DiscountValueType.PERCENTAGE &&
        !isInteger(discountAmount)
      ) {
        return "A percentage must be a whole number";
      }

      if (
        discountValueType === DiscountValueType.AMOUNT &&
        discountAmount > 1000
      ) {
        return "The discount amount must be less than $1000";
      }

      if (!codeStrategyName) {
        return "The discount code is required";
      }

      if (
        expiration.expirationType === ExpirationType.EXPIRATION_DAYS &&
        (!expiration.days || expiration.days <= 0)
      ) {
        return "Number of days must be greater than 0";
      }

      const codeStrategy: DiscountCodeGenerationStrategy = (() => {
        switch (codeGenerationStrategy) {
          case DiscountGroupType.STATIC:
            return {
              strategy: DiscountGroupType.STATIC,
              code: codeStrategyName,
            };
          case DiscountGroupType.DYNAMIC:
            return {
              strategy: DiscountGroupType.DYNAMIC,
              code: codeStrategyName,
            };
          default:
            assertNever(codeGenerationStrategy);
        }
      })();

      const discountSettings: RedoDiscountConfiguration = {
        ...existingDiscount,
        name: discountName,
        provider: DiscountProvider.SHOPIFY,
        codeGenerationStrategy: codeStrategy,
        expiration,
        discountSettings: {
          settingsType: DiscountSettingsType.SHOPIFY_BASIC,
          discountValueType,
          discountValueAmount: discountAmount,
          combinesWith: combineDiscountWith,
          excludeItemsOnSale: excludeOnSale,
          appliesToCollections: specifyCollections ? collectionIds : undefined,
          endsAt:
            expiration.expirationType === ExpirationType.DATE
              ? expiration.date
              : undefined,
        },
      };
      return discountSettings;
    }, [
      excludeOnSale,
      specifyCollections,
      discountAmount,
      codeStrategyName,
      discountName,
      combineDiscountWith,
      collectionIds,
      codeGenerationStrategy,
      discountValueType,
      expiration,
      existingDiscount,
      discounts,
    ]);

  const handleCreateOrUpdateDiscount = useHandler(async () => {
    if (typeof discountValidationResult === "string") {
      return;
    }

    if (existingDiscount) {
      await updateDiscount({
        discountId: existingDiscount._id,
        discountConfiguration: discountValidationResult,
      });
    } else {
      const discountId = await createDiscount({
        discountConfiguration: discountValidationResult,
      });
      valueChange(discountId);
    }
    onModalCloseRequested();
  });

  return (
    <RedoModal
      footerBorder
      headerBorder
      headerIconAlignment="inline"
      isOpen={isOpen}
      modalSize="md"
      onModalCloseRequested={onModalCloseRequested}
      primaryButton={{
        text: existingDiscount ? "Update discount" : "Create discount",
        disabled:
          typeof discountValidationResult === "string"
            ? discountValidationResult
            : false,
        onClick: handleCreateOrUpdateDiscount,
      }}
      secondaryButton={{
        text: "Cancel",
        onClick: () => {
          onModalCloseRequested();
        },
      }}
      subtitle={
        existingDiscount
          ? "Updating your discount may be disruptive for audience members who have already received it"
          : undefined
      }
      theme={existingDiscount ? "warn" : undefined}
      title={existingDiscount ? "Update discount" : "Create discount"}
      TitleIcon={DiscountIcon}
    >
      <Flex dir="column" gap="xl">
        <RedoTextInput
          label="Name"
          placeholder="Add a name"
          setValue={setDiscountName}
          value={discountName}
        />
        <Flex>
          <Flex style={{ flex: "1 1 0" }}>
            <RedoSingleSelectDropdownInput<DiscountGroupType>
              description={getDiscountCodeStrategyDescription({
                prefix: codeStrategyName,
                type: codeGenerationStrategy,
              })}
              label="Code generation strategy"
              options={discountGroupTypeOptions}
              optionSelected={({ item }) => {
                setCodeGenerationStrategy(item.value);
              }}
              placeholder="Select a discount type"
              selectedItem={discountGroupTypeOptions.find(
                (o) => o.id === codeGenerationStrategy,
              )}
              size="xs"
            />
          </Flex>
          <Flex style={{ flex: "1 1 0" }}>
            <RedoTextInput
              fullWidth
              label={discountCodeNameTitle[codeGenerationStrategy]}
              placeholder="Name your code"
              setValue={setCodeStrategyName}
              value={codeStrategyName}
            />
          </Flex>
        </Flex>
        <RedoTextInput
          inputTypeHint="number"
          label="Discount amount"
          placeholder="Add a discount amount"
          setValue={(value) => {
            setDiscountAmount(Number(value));
          }}
          trailingActions={
            <Flex>
              <RedoSingleSelectDropdownInput<DiscountValueType>
                fitToAnchor={false}
                hideBorder
                options={discountValueTypeItems}
                optionSelected={({ item }) => {
                  setDiscountValueType(item.value);
                }}
                selectedItem={discountValueTypeItems.find(
                  (o) => o.value === discountValueType,
                )}
                size="xs"
              />
            </Flex>
          }
          value={discountAmount?.toString() ?? ""}
        />
        <LabeledInput
          label={
            <Text fontWeight="medium" textColor="secondary">
              Combine discount with:
            </Text>
          }
          size={LabelSize.EXTRA_SMALL}
        >
          <RedoCheckbox
            label="Product discounts"
            setValue={() => {
              setCombineDiscountWith({
                ...combineDiscountWith,
                productDiscounts: !combineDiscountWith.productDiscounts,
              });
            }}
            size="xs"
            value={combineDiscountWith.productDiscounts}
          />
          <RedoCheckbox
            label="Order discounts"
            setValue={() => {
              setCombineDiscountWith({
                ...combineDiscountWith,
                orderDiscounts: !combineDiscountWith.orderDiscounts,
              });
            }}
            size="xs"
            value={combineDiscountWith.orderDiscounts}
          />
          <RedoCheckbox
            label="Shipping discounts"
            setValue={() => {
              setCombineDiscountWith({
                ...combineDiscountWith,
                shippingDiscount: !combineDiscountWith.shippingDiscount,
              });
            }}
            size="xs"
            value={combineDiscountWith.shippingDiscount}
          />
        </LabeledInput>
        <LabeledInput
          label={
            <Text fontSize="xs" fontWeight="medium" textColor="secondary">
              Item restrictions:
            </Text>
          }
          size={LabelSize.EXTRA_SMALL}
        >
          {!specifyCollections && (
            <RedoCheckbox
              description="This will create a collection in Shopify to keep track of which items are not on sale."
              label="Exclude on-sale items"
              setValue={(checked) => {
                handleSetExcludeOnSale(checked === true);
              }}
              size="xs"
              value={excludeOnSale}
            />
          )}
          {!excludeOnSale && (
            <RedoCheckbox
              description="If enabled, the discount will only be applied to items in the specified collections."
              label="Limit to collections"
              setValue={(checked) => {
                handleSetSpecifyCollections(checked === true);
              }}
              size="xs"
              value={specifyCollections}
            />
          )}
          {specifyCollections && (
            <CollectionsPicker
              collections={collectionIds}
              setCollections={setCollectionIds}
            />
          )}
        </LabeledInput>

        <LabeledInput
          label={
            <Text fontSize="xs" fontWeight="medium" textColor="secondary">
              Expiration:
            </Text>
          }
          size={LabelSize.EXTRA_SMALL}
        >
          <RedoRadioGroup<ExpirationType>
            options={[
              { id: ExpirationType.NEVER, label: "Never" },
              ...(codeGenerationStrategy === DiscountGroupType.DYNAMIC
                ? [{ id: ExpirationType.EXPIRATION_DAYS, label: "Days" }]
                : []),
              { id: ExpirationType.DATE, label: "Date" },
            ]}
            setValue={(value) => {
              setExpiration(getDefaultExpiration(value));
            }}
            size="xs"
            value={expiration.expirationType}
          />
        </LabeledInput>

        {expiration.expirationType === ExpirationType.EXPIRATION_DAYS && (
          <Flex>
            <RedoTextInput
              inputTypeHint="number"
              label="Number of days"
              min={1}
              placeholder="Enter days"
              setValue={(value) =>
                setExpiration({ ...expiration, days: Number(value) })
              }
              step={1}
              value={expiration.days?.toString() || ""}
            />
          </Flex>
        )}

        {expiration.expirationType === ExpirationType.DATE && (
          <Flex>
            <RedoSingleDatePicker
              buttonSize="sm"
              date={dateToCurrentPlainDate(expiration.date)}
              setDate={(date) =>
                setExpiration({
                  ...expiration,
                  date: plainDateToCurrentDate(date),
                })
              }
            />
          </Flex>
        )}
      </Flex>
    </RedoModal>
  );
});

const discountValueTypeItems: RedoListItem<DiscountValueType>[] = [
  {
    id: DiscountValueType.PERCENTAGE,
    type: "text",
    text: "%",
    value: DiscountValueType.PERCENTAGE,
  },
  {
    id: DiscountValueType.AMOUNT,
    type: "text",
    text: "$",
    value: DiscountValueType.AMOUNT,
  },
];

const prettyDiscountType = (type: DiscountGroupType) => {
  switch (type) {
    case DiscountGroupType.DYNAMIC:
      return "Dynamic";
    case DiscountGroupType.STATIC:
      return "Static";
    default:
      assertNever(type);
  }
};

const discountGroupTypeOptions: RedoListItem<DiscountGroupType>[] =
  Object.values(DiscountGroupType).map((type) => ({
    id: type,
    type: "text",
    value: type,
    text: prettyDiscountType(type),
  }));

const searcher = new Fuse<BadgeRedoListItem<{ id: string; name: string }>>([], {
  keys: ["value.name"],
});

const CollectionsPicker = memo(function CollectionsPicker({
  collections,
  setCollections,
}: {
  collections: string[];
  setCollections: (c: string[]) => void;
}) {
  const [collectionsLoad] = useLazyContext(CollectionsContext);

  const [collectionsSearchString, setCollectionsSearchString] =
    useState<string>("");

  const allCollectionOptions: BadgeRedoListItem<{
    id: string;
    name: string;
  }>[] = useMemo(() => {
    const loadedCollections = collectionsLoad.value || [];
    return loadedCollections.map((collection) => ({
      id: collection.id,
      value: collection,
      type: "badge",
      badge: { text: collection.name },
    }));
  }, [collectionsLoad]);

  const dropdownOptions = useSearch(
    searcher,
    allCollectionOptions,
    collectionsSearchString,
  );

  const selectedOptions: BadgeRedoListItem<{ id: string; name: string }>[] =
    useMemo(() => {
      return allCollectionOptions.filter((collection) =>
        collections.includes(collection.value.id),
      );
    }, [allCollectionOptions, collections]);

  return (
    <RedoTagsDropdownInput
      options={dropdownOptions}
      placeholder="Select collections"
      searchPlaceholder="Search collections"
      searchString={collectionsSearchString}
      selectedOptions={selectedOptions}
      setSearchString={setCollectionsSearchString}
      setSelectedOptions={(items) => {
        setCollections(items.map((item) => item.value.id));
      }}
      size="sm"
    />
  );
});

const discountCodeNameTitle: Record<DiscountGroupType, string> = {
  [DiscountGroupType.STATIC]: "Discount code",
  [DiscountGroupType.DYNAMIC]: "Discount code prefix",
};

function getDiscountCodeStrategyDescription({
  prefix,
  type,
}: {
  prefix: string;
  type: DiscountGroupType;
}) {
  switch (type) {
    case DiscountGroupType.STATIC:
      return `The code ${prefix.toUpperCase()} will be sent to all customers.`;
    case DiscountGroupType.DYNAMIC:
      return `A unique discount code with this prefix will be generated for each customer. Eg '${prefix.toUpperCase()}-12345'`;
    default:
      assertNever(type);
  }
}

function getDefaultExpiration(expirationType: ExpirationType) {
  switch (expirationType) {
    case ExpirationType.NEVER:
    case ExpirationType.DELIVERY:
      return { expirationType };
    case ExpirationType.EXPIRATION_DAYS:
      return { expirationType, days: 7 };
    case ExpirationType.DATE:
      return { expirationType, date: new Date() };
    default:
      assertNever(expirationType);
  }
}
