// extracted by mini-css-extract-plugin
export var actionButton = "_9u-t";
export var buttonContainer = "_9u-0";
export var buttonContainer2 = "_9u-1";
export var buttonSection = "_9u-2";
export var details = "_9u-3";
export var dropdownItem = "_9u-5";
export var dropdownItemsContainer = "_9u-4";
export var fileInput = "_9u-r";
export var headerButton = "_9u-6";
export var icon = "_9u-7";
export var input = "_9u-8";
export var macroListItem = "_9u-9";
export var mainContent = "_9u-a";
export var microText = "_9u-b";
export var modal = "_9u-w";
export var optionsButton = "_9u-c";
export var paperclip = "_9u-s";
export var pillContent = "_9u-d";
export var preview = "_9u-e";
export var preview2 = "_9u-f";
export var quillEditor = "_9u-q";
export var selectedMacro = "_9u-g";
export var sidebar = "_9u-h";
export var sidebarHeader = "_9u-j";
export var sidebarList = "_9u-i";
export var sidebarListItem = "_9u-k";
export var sidebarSearch = "_9u-l";
export var snoozeDurationWrapper = "_9u-v";
export var statusPillContainer = "_9u-m";
export var tagsContainer = "_9u-n";
export var unselectedMacro = "_9u-o";
export var variable = "_9u-u";
export var wrapper = "_9u-p";