import { z } from "zod";

const MetaLeadAdsPage = z.object({ id: z.string(), name: z.string() });

const MetaLeadAdsFormMapping = z.object({
  formId: z.string(),
  formName: z.string(),
  segmentId: z.string(),
  segmentName: z.string(),
});

export const getMetaLeadAdsIntegration = {
  input: z.null(),
  output: z.object({
    exists: z.boolean(),
    pageId: z.string().optional(),
    pages: z.array(MetaLeadAdsPage).optional(),
    formMappings: z.array(MetaLeadAdsFormMapping).optional(),
  }),
};
