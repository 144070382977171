import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { updateUserViewOrdering } from "@redotech/redo-merchant-app-common/client/user";
import {
  ReloadUserContext,
  UserContext,
} from "@redotech/redo-merchant-app-common/user";
import { FolderData } from "@redotech/redo-model/support/folder-types";
import { toast } from "@redotech/redo-web/alert";
import { filterTruthy } from "@redotech/util/array";
import { groupMap } from "@redotech/util/map";
import * as isEqual from "lodash/isEqual";
import { createContext, memo, useMemo, useState } from "react";
import { DefaultViews, ViewInfo, ViewsContext } from "../../app/views";
import {
  createUserSupportFolder,
  deleteUserSupportFolder,
  editUserSupportFolder,
} from "../../client/view-folders";
import { CreateFolderModal } from "../../support/create-folder-modal";
import { EditFolderModal } from "../../support/edit-folder-modal";
import { viewUrlNameToDisplayName } from "../../support/view-name-utils";

export interface ViewOrderingData {
  defaultViewOrdering: ViewInfo[];
  folderlessViewOrdering: ViewInfo[];
  setFolderBeingEdited: (folder: FolderData) => void;
  folders: FolderData[];
  updateViewOrdering: (newOrder: ViewInfo[]) => void;
  openCreateFolderModal: () => void;
}

export const SupportViewOrderingContext = createContext<
  ViewOrderingData | undefined
>(undefined);

export const SupportViewOrderingService = memo(
  function SupportViewOrderingService({
    children,
  }: {
    children: React.ReactNode;
  }) {
    const user = useRequiredContext(UserContext);
    const reloadUser = useRequiredContext(ReloadUserContext);
    const client = useRequiredContext(RedoMerchantClientContext);
    const customViews = useRequiredContext(ViewsContext);

    const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
    const [folderBeingEdited, setFolderBeingEdited] =
      useState<FolderData | null>(null);

    const [folders, setFolders] = useState<FolderData[]>(
      user?.viewFolders ?? [],
    );

    const handleCreateFolder = async (
      name: string,
      viewIds: string[],
      defaultViews: string[],
    ) => {
      const newFolder = await createUserSupportFolder(client, {
        userId: user._id,
        name: name,
        viewIds: viewIds,
        defaultViews: defaultViews,
      });
      if (newFolder) {
        setFolders((prev) => [...prev, newFolder]);
        setShowCreateFolderModal(false);
      } else {
        console.error("Failed to create folder");
        toast("Failed to create folder", { variant: "error" });
      }
    };

    const handleEditFolder = async (
      folderId: string,
      newFolder: FolderData,
    ) => {
      try {
        setFolders((prev) =>
          prev.map((folder) => (folder._id === folderId ? newFolder : folder)),
        );
        await editUserSupportFolder(client, {
          userId: user._id,
          folderId: folderId,
          newFolder: newFolder,
        });
        setFolderBeingEdited(null);
      } catch (error) {
        console.error(`Failed to edit folder ${newFolder.name}`);
        toast(`Failed to edit folder ${newFolder.name}`, { variant: "error" });
      }
    };

    const handleDeleteFolder = async (folderId: string) => {
      try {
        setFolders((cur) => cur.filter((folder) => folder._id !== folderId));
        setFolderBeingEdited(null);
        await deleteUserSupportFolder(client, {
          userId: user._id,
          folderId: folderId,
        });
      } catch (error) {
        console.error(`Failed to delete folder ${folderBeingEdited?.name}`);
        toast(`Failed to delete folder ${folderBeingEdited?.name}`, {
          variant: "error",
        });
      }
    };

    const viewOrderingDefault: ViewInfo[] = useMemo(() => {
      return [
        ...Object.values(DefaultViews).map((view) => ({
          identifier: { name: view },
          title: viewUrlNameToDisplayName(view),
          isPrivate: false,
        })),
        ...customViews.map((view) => ({
          identifier: { _id: view._id },
          title: view.name,
          isPrivate: !!view.user,
        })),
      ];
    }, [customViews]);

    const [optimisticUserOrdering, setOptimisticUserOrdering] = useState<
      ViewInfo[] | undefined
    >();

    const currentUserOrdering: ViewInfo[] = useMemo(() => {
      const viewNameToView: Map<string, ViewInfo> = groupMap(
        viewOrderingDefault,
        (view) =>
          view.identifier.name ? [view.identifier.name, view] : undefined,
      );

      const viewIdToView: Map<string, ViewInfo> = groupMap(
        viewOrderingDefault,
        (view) =>
          view.identifier._id ? [view.identifier._id, view] : undefined,
      );

      const customViewOrdering = user?.customViewOrdering ?? [];

      const viewsToUse = filterTruthy(
        customViewOrdering.map((customView) => {
          if (customView.view) {
            const matchingView = viewIdToView.get(customView.view);
            if (matchingView) {
              return matchingView;
            }
          }

          if (customView.viewName) {
            const matchingView = viewNameToView.get(customView.viewName);
            if (matchingView) {
              return matchingView;
            }
          }
          return undefined;
        }),
      );

      return viewsToUse;
    }, [viewOrderingDefault, user]);

    const userOrdering = optimisticUserOrdering ?? currentUserOrdering;

    const additionalViews = useMemo(() => {
      const viewNameToView: Map<string, ViewInfo> = groupMap(
        userOrdering,
        (view) =>
          view.identifier.name ? [view.identifier.name, view] : undefined,
      );

      const viewIdToView: Map<string, ViewInfo> = groupMap(
        userOrdering,
        (view) =>
          view.identifier._id ? [view.identifier._id, view] : undefined,
      );

      return viewOrderingDefault.filter((view) => {
        const accountedForByName =
          !!view.identifier.name && viewNameToView.has(view.identifier.name);
        const accountedForById =
          !!view.identifier._id && viewIdToView.has(view.identifier._id);

        return !accountedForByName && !accountedForById;
      });
    }, [userOrdering, viewOrderingDefault]);

    const viewOrdering: ViewInfo[] = useMemo(() => {
      return [...userOrdering, ...additionalViews];
    }, [userOrdering, additionalViews]);

    const viewsNotInFolders = useMemo(() => {
      const allFolderViewIds = folders.flatMap((folder) => folder.viewIds);
      const allFolderDefaultViews = folders.flatMap(
        (folder) => folder.defaultViews,
      );

      return viewOrdering.filter((view) => {
        if (view.identifier._id) {
          return !allFolderViewIds.includes(view.identifier._id);
        } else {
          return !allFolderDefaultViews.includes(view.identifier.name || "");
        }
      });
    }, [folders, viewOrdering]);

    const updateViewOrdering = useHandler(async (newOrder: ViewInfo[]) => {
      const isTheOrderTheSame = viewOrdersEqual(userOrdering, newOrder);

      if (isTheOrderTheSame) {
        return;
      }

      try {
        setOptimisticUserOrdering(newOrder);
        await updateUserViewOrdering(client, {
          userId: user._id,
          customViewOrdering: newOrder.map((view) => {
            if (view.identifier._id) {
              return { view: view.identifier._id };
            } else {
              return { viewName: view.identifier.name };
            }
          }),
        });
      } finally {
        setOptimisticUserOrdering(undefined);
      }

      reloadUser();
    });

    return (
      <SupportViewOrderingContext.Provider
        value={{
          defaultViewOrdering: viewOrderingDefault,
          folderlessViewOrdering: viewsNotInFolders,
          setFolderBeingEdited,
          folders,
          updateViewOrdering,
          openCreateFolderModal: () => setShowCreateFolderModal(true),
        }}
      >
        {children}
        {folderBeingEdited && (
          <EditFolderModal
            deleteFolder={handleDeleteFolder}
            folder={folderBeingEdited}
            isOpen={!!folderBeingEdited}
            onClose={() => setFolderBeingEdited(null)}
            onSubmit={(newFolder: FolderData) =>
              handleEditFolder(folderBeingEdited._id, newFolder)
            }
          />
        )}

        <CreateFolderModal
          isOpen={showCreateFolderModal}
          onClose={() => setShowCreateFolderModal(false)}
          onSubmit={handleCreateFolder}
        />
      </SupportViewOrderingContext.Provider>
    );
  },
);

function viewOrdersEqual(a: ViewInfo[], b: ViewInfo[]): boolean {
  if (a.length !== b.length) {
    return false;
  }

  for (let i = 0; i < a.length; i++) {
    if (!isEqual(a[i], b[i])) {
      return false;
    }
  }

  return true;
}
