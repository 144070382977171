import {
  FeaturedIconColor,
  RedoFeaturedIcon,
} from "@redotech/redo-web/arbiter-components/featured-icon/redo-featured-icon";
import AlertCircle from "@redotech/redo-web/arbiter-icon/alert-circle.svg";
import { Flex } from "@redotech/redo-web/flex";
import { memo } from "react";

type NotificationType = "error" | "warning" | "info" | "success";

export const Notification = memo(function Notification({
  type,
  children,
}: {
  type: NotificationType;
  children: React.ReactNode;
}) {
  return (
    <Flex
      bgColor="primary"
      borderBottomWidth="1px"
      borderColor="primary"
      borderStyle="solid"
      px="3xl"
      py="lg"
      w="full"
    >
      <RedoFeaturedIcon
        color={notificationTypeToFeaturedIconColor[type]}
        Icon={AlertCircle}
      />
      <Flex align="center" dir="row" justify="space-between" w="full">
        {children}
      </Flex>
    </Flex>
  );
});

const notificationTypeToFeaturedIconColor: Record<
  NotificationType,
  FeaturedIconColor
> = { error: "error", warning: "warning", info: "gray", success: "success" };
