import { z } from "zod";
import { zExt } from "../common/zod-util";

export enum UpsellAnalyticsRecordBillingStatus {
  FREE = "FREE",
  BILLED = "BILLED",
  BILLING_FAILED = "BILLING_FAILED",
}

export const upsellPageAnalyticsRecordSchema = z.object({
  teamId: zExt.objectId(),
  referenceId: z.string(),
  totalUpsellRevenue: z.number(),
  productId: z.string().optional(),
  createdAt: z.date(),
  orderNumber: z.number().optional(),
  billingStatus: z.optional(
    z.nullable(z.nativeEnum(UpsellAnalyticsRecordBillingStatus)),
  ),
  billingAmountInDollars: z.number().optional(),
});

export type UpsellPageAnalyticsRecord = z.infer<
  typeof upsellPageAnalyticsRecordSchema
>;
