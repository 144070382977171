import {
  ensureProtocolPresent,
  isValidLink,
  sanitizeUrl,
} from "@redotech/util/link";
import Link from "quill/formats/link";

export class QuillLink extends Link {
  public static override create(value: string) {
    const sanitizedValue = sanitizeUrl(value);

    const link = isValidLink(sanitizedValue)
      ? ensureProtocolPresent(sanitizedValue)
      : sanitizedValue;
    const node = super.create(link);
    node.setAttribute("href", link);

    return node;
  }
}
