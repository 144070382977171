// extracted by mini-css-extract-plugin
export var centered = "_28-6";
export var flexible = "_28-5";
export var footer = "_28-k";
export var footerBorder = "_28-l";
export var header = "_28-c";
export var headerBorder = "_28-d";
export var headerDefault = "_28-g";
export var headerMedium = "_28-h";
export var headerPadding = "_28-e";
export var iconAndTitle = "_28-f";
export var large = "_28-4";
export var largePadding = "_28-a";
export var medium = "_28-3";
export var mediumPadding = "_28-9";
export var modal = "_28-0";
export var modalContainer = "_28-b";
export var noPadding = "_28-7";
export var skinny = "_28-1";
export var small = "_28-2";
export var smallPadding = "_28-8";
export var subtitle = "_28-j";
export var title = "_28-i";