import { CheckoutAbTestMetricToOptimize } from "@redotech/redo-model/checkouts/checkout-ab-test-builder";
import { CheckoutExperienceZodArraySchema } from "@redotech/redo-model/integration/redo-in-shipping/ris";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

const treatmentSchema = z.object({
  id: zExt.objectId(),
  name: z.string(),
  isSpecialExistingExperienceTreatment: z.boolean(),
  allCheckoutExperiences: CheckoutExperienceZodArraySchema,
});
export type GetCheckoutSplitDetailsTreatment = z.infer<typeof treatmentSchema>;

export const getCheckoutSplitDetails = {
  input: z.object({ splitId: zExt.objectId() }),
  output: z.object({
    name: z.string(),
    description: z.string(),
    metricToOptimize: z.nativeEnum(CheckoutAbTestMetricToOptimize),
    status: z.enum(["in_progress", "concluded"]),
    controlTreatment: treatmentSchema,
    variantTreatments: z.array(treatmentSchema),
  }),
};
