// extracted by mini-css-extract-plugin
export var bodySections = "_5m-8";
export var gray = "_5m-7";
export var green = "_5m-4";
export var iconContainer = "_5m-9";
export var iconWidgetContainer = "_5m-1";
export var phoneIcon = "_5m-2";
export var red = "_5m-5";
export var statusDot = "_5m-3";
export var widgetContainer = "_5m-0";
export var yellow = "_5m-6";