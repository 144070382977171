import { z } from "zod";
import { zExt } from "../common/zod-util";
import {
  CarrierSchema,
  ServiceSchema,
} from "../fulfillments/fulfillment-carriers-and-services";
import { ParcelSchema } from "./parcel";

export enum Hotkey {
  CMD_0 = "⌘0",
  CMD_1 = "⌘1",
  CMD_2 = "⌘2",
  CMD_3 = "⌘3",
  CMD_4 = "⌘4",
  CMD_5 = "⌘5",
  CMD_6 = "⌘6",
  CMD_7 = "⌘7",
  CMD_8 = "⌘8",
  CMD_9 = "⌘9",
}

export const getHotkeyDisplayName = (hotkey: Hotkey) => {
  const isMac = navigator.platform.toLowerCase().includes("mac");
  if (!isMac) {
    return hotkey.replace("⌘", "Ctrl ");
  }
  return hotkey.replace("⌘", "⌘ ");
};

export const HotkeySchema = z.nativeEnum(Hotkey);

export const PresetSchema = z.object({
  _id: zExt.objectId(),
  name: z.string(),
  hotkey: HotkeySchema,
  carrier: CarrierSchema.nullish(),
  service: ServiceSchema.nullish(),
  parcel: ParcelSchema.nullish(),
  totalWeightInOunces: z.number().nullish(),
});
export type Preset = z.infer<typeof PresetSchema>;
