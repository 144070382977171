import { ClickAwayListener } from "@mui/material";
import DotsHorizontal from "@redotech/redo-web/arbiter-icon/dots-horizontal.svg";
import PauseCircle from "@redotech/redo-web/arbiter-icon/pause-circle.svg";
import PlayCircle from "@redotech/redo-web/arbiter-icon/play-circle.svg";
import RefreshCCW01 from "@redotech/redo-web/arbiter-icon/refresh-ccw-01_filled.svg";
import RefreshCW01 from "@redotech/redo-web/arbiter-icon/refresh-cw-01_filled.svg";
import VolumeMin from "@redotech/redo-web/arbiter-icon/volume-min.svg";
import VolumeX from "@redotech/redo-web/arbiter-icon/volume-x.svg";
import { memo, ReactNode } from "react";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import * as darkModeCss from "../../theme/component-colors-dark.module.css";
import { RedoButton } from "../buttons/redo-button";
import * as redoAudioPlayerCss from "./redo-audio-player.module.css";

export const RedoAudioPlayer = memo(function RedoAudioPlayer({
  audioSrc,
  showCustomActions = false,
  customActionList,
  customMenuOpen,
  setCustomMenuOpen,
  setDropdownAnchor,
}: {
  audioSrc: string;
  showCustomActions: boolean;
  customActionList?: ReactNode;
  customMenuOpen?: boolean;
  setCustomMenuOpen?: (open: boolean) => void;
  setDropdownAnchor?: (anchor: HTMLElement | null) => void;
}) {
  const hasCustomMenu =
    showCustomActions &&
    customActionList &&
    setCustomMenuOpen &&
    customMenuOpen !== undefined;
  return (
    <div className={redoAudioPlayerCss.audioPlayer}>
      <AudioPlayer
        customAdditionalControls={[RHAP_UI.VOLUME]}
        customIcons={{
          play: <RedoButton hierarchy="primary" IconLeading={PlayCircle} />,
          pause: <RedoButton hierarchy="primary" IconLeading={PauseCircle} />,
          rewind: (
            <RedoButton
              className={darkModeCss.darkMode}
              hierarchy="tertiary"
              IconLeading={RefreshCCW01}
            />
          ),
          forward: (
            <RedoButton
              className={darkModeCss.darkMode}
              hierarchy="tertiary"
              IconLeading={RefreshCW01}
            />
          ),
          volume: <RedoButton hierarchy="primary" IconLeading={VolumeMin} />,
          volumeMute: <RedoButton hierarchy="primary" IconLeading={VolumeX} />,
        }}
        customVolumeControls={
          hasCustomMenu
            ? [
                <ClickAwayListener
                  key="clickAwayListener"
                  onClickAway={() => {
                    if (customMenuOpen) {
                      setCustomMenuOpen(false);
                    }
                  }}
                >
                  <RedoButton
                    className={darkModeCss.darkMode}
                    hierarchy="tertiary"
                    IconLeading={DotsHorizontal}
                    onClick={(e) => {
                      e.preventDefault();
                      setCustomMenuOpen(!customMenuOpen);
                    }}
                    ref={setDropdownAnchor}
                  />
                </ClickAwayListener>,
              ]
            : []
        }
        showFilledVolume={false}
        src={audioSrc}
      />
      {hasCustomMenu && <>{customActionList}</>}
    </div>
  );
});
