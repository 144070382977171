import { ClickAwayListener } from "@mui/material";
import XCloseIcon from "@redotech/redo-web/arbiter-icon/x-close.svg";
import { memo, useState } from "react";
import * as expandableImageCss from "./expandable-image.module.css";

export const ExpandableImage = memo(function ExpandableImage({
  src,
  alt,
  className,
}: {
  src: string;
  alt?: string;
  className?: string;
}) {
  const [expanded, setExpanded] = useState(false);
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const isVideo = /\.(mp4|mov|avi|mkv)$/i.test(src);

  const handleImageClick = (e: React.MouseEvent<HTMLImageElement>) => {
    if (scale === 1) {
      const rect = e.currentTarget.getBoundingClientRect();
      const x = ((e.clientX - rect.left) / rect.width) * 100;
      const y = ((e.clientY - rect.top) / rect.height) * 100;
      setPosition({ x: 50 - x, y: 50 - y });
      setScale(3);
    } else {
      setScale(1);
      setPosition({ x: 0, y: 0 });
    }
  };

  return (
    <>
      {isVideo ? (
        <video
          className={className}
          onClick={() => setExpanded(true)}
          src={src}
          style={{ cursor: "pointer" }}
        />
      ) : (
        <img
          alt={alt}
          className={className}
          onClick={() => setExpanded(true)}
          src={src}
          style={{ cursor: "pointer" }}
        />
      )}

      {expanded && (
        <div className={expandableImageCss.overlay}>
          <XCloseIcon
            className={expandableImageCss.closeButton}
            onClick={() => {
              setExpanded(false);
              setScale(1);
              setPosition({ x: 0, y: 0 });
            }}
          />
          <ClickAwayListener
            onClickAway={() => {
              setExpanded(false);
              setScale(1);
              setPosition({ x: 0, y: 0 });
            }}
          >
            {isVideo ? (
              <video
                className={expandableImageCss.expandedImage}
                controls
                src={src}
              />
            ) : (
              <img
                alt={alt}
                className={expandableImageCss.expandedImage}
                onClick={handleImageClick}
                src={src}
                style={{
                  transform: `scale(${scale}) translate(${position.x}%, ${position.y}%)`,
                  transition: "transform 0.2s ease-out",
                  cursor: scale === 1 ? "zoom-in" : "zoom-out",
                }}
              />
            )}
          </ClickAwayListener>
        </div>
      )}
    </>
  );
});
