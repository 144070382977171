import { useEffect, useState } from "react";
import { Text } from "../text";
function formatTimeUnit(unit: number) {
  return unit.toString().padStart(2, "0");
}

function getTimeElapsed(startTime: Date) {
  const now = new Date();
  const elapsedMs = now.getTime() - startTime.getTime();
  const elapsedSeconds = Math.floor(elapsedMs / 1000);
  const hours = Math.floor(elapsedSeconds / 3600);
  const minutes = Math.floor((elapsedSeconds % 3600) / 60);
  const seconds = elapsedSeconds % 60;

  return { hours, minutes, seconds };
}

function getTimeElapsedString({
  hours,
  minutes,
  seconds,
}: {
  hours: number;
  minutes: number;
  seconds: number;
}) {
  const timeArray = [hours, minutes, seconds];
  if (hours === 0) {
    timeArray.shift();
  }
  return timeArray.map(formatTimeUnit).join(":");
}

export const TimeElapsed = ({ startTime }: { startTime: Date }) => {
  const [timeElapsed, setTimeElapsed] = useState(getTimeElapsed(startTime));

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeElapsed(getTimeElapsed(startTime));
    }, 1000);

    return () => clearInterval(interval);
  }, [startTime]);

  return (
    <Text fontSize="xxs" textColor="primary">
      {getTimeElapsedString(timeElapsed)}
    </Text>
  );
};
