// import { searchProducts } from "@redotech/redo-return-app/api";
import { useRequiredContext } from "@redotech/react-util/context";
import { useLoad } from "@redotech/react-util/load";
import { RedoMerchantRpcClientContext } from "@redotech/redo-merchant-app-common/rpc-client";
import { Provider } from "@redotech/redo-model/order";
import {
  InventoryPolicy,
  ProductInfo,
  VariantInfo,
} from "@redotech/redo-model/product";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { Checkbox } from "@redotech/redo-web/checkbox";
import { Divider } from "@redotech/redo-web/divider";
import { Flex } from "@redotech/redo-web/flex";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import SearchIcon from "@redotech/redo-web/icon-old/search.svg";
import { LoadingRedoAnimation } from "@redotech/redo-web/loading-redo-animation";
import { Modal } from "@redotech/redo-web/modal";
import { TextInput } from "@redotech/redo-web/text-input";
import * as classNames from "classnames";
import { memo, useEffect, useState } from "react";
import { useDebounce } from "usehooks-ts";
import * as productSelectModalCss from "./product-select-modal.module.css";

export const ProductSelectModal = memo(function ProductSelectModal({
  open,
  setOpen,
  selectedVariants,
  setSelectedVariants,
  provider,
  allowEmptySubmission = false,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedVariants: VariantInfo[];
  setSelectedVariants(products: VariantInfo[]): void;
  provider: Provider;
  allowEmptySubmission?: boolean;
}) {
  const rpcClient = useRequiredContext(RedoMerchantRpcClientContext);
  const [searchString, setSearchString] = useState<string>("");
  const debouncedSearch = useDebounce(searchString, 500);
  const [currentlySelectedVariants, setCurrentlySelectedVariants] = useState<
    VariantInfo[]
  >(selectedVariants || []);

  useEffect(() => {
    setCurrentlySelectedVariants(selectedVariants);
  }, [selectedVariants]);

  const productsLoad = useLoad<ProductInfo[]>(
    async function (signal) {
      if (!open || debouncedSearch === "") {
        return [];
      }
      const results = await rpcClient.searchProducts(
        { searchString: debouncedSearch, provider },
        { signal },
      );
      const products = results?.products;
      products && addProductImageToVariantsWithoutImages(products);
      return products;
    },
    [debouncedSearch, open],
  );

  const closeModal = () => {
    setCurrentlySelectedVariants([]);
    setOpen(false);
  };

  function addProductImageToVariantsWithoutImages(
    products: ProductInfo[],
  ): void {
    for (const product of products) {
      for (const variant of product.variants) {
        variant.image = variant.image.url ? variant.image : product.image;
      }
    }
  }

  function getTotalVariantQuantity(product: ProductInfo): number {
    return product.variants.reduce(
      (sum, variant) =>
        // TODO: is this the best way to handle this when inventory isn't tracked?
        sum + (variant.inventoryTracked ? variant.inventoryQuantity : 1),
      0,
    );
  }

  const modalFooter = (
    <div className={productSelectModalCss.actionButtons}>
      <Button onClick={closeModal} theme={ButtonTheme.OUTLINED}>
        Cancel
      </Button>
      <Button
        disabled={!allowEmptySubmission && !currentlySelectedVariants.length}
        onClick={() => {
          // Don't wait for the callback to complete before closing the modal
          setSelectedVariants(currentlySelectedVariants);
          closeModal();
        }}
        theme={ButtonTheme.PRIMARY}
      >
        Add
      </Button>
    </div>
  );

  // TODO(devin): Might be duplicated?
  const isVariantInStock = (variant: VariantInfo): boolean => {
    return (
      !variant.inventoryTracked ||
      variant.inventoryQuantity > 0 ||
      variant.inventoryPolicy === InventoryPolicy.CONTINUE
    );
  };

  return (
    <Modal
      footer={modalFooter}
      onClose={closeModal}
      open={open}
      title="Select Products"
    >
      <div className={productSelectModalCss.resultsContainer}>
        <div className={productSelectModalCss.searchInput}>
          <TextInput
            autoFocus
            icon={<SearchIcon />}
            onChange={setSearchString}
            placeholder="Search products"
            value={searchString}
          />
        </div>
        {productsLoad.pending ? (
          <LoadingRedoAnimation />
        ) : productsLoad.error ? (
          <Flex justify="center" pt="lg">
            Could not fetch products
          </Flex>
        ) : !productsLoad.value?.length ? (
          <Flex justify="center" pt="lg">
            No products found
          </Flex>
        ) : (
          productsLoad.value.map((product) => (
            <div key={product.id}>
              <div
                className={classNames(
                  gridCss.grid,
                  productSelectModalCss.productContainer,
                )}
              >
                <div
                  className={
                    product.variants.length === 1
                      ? gridCss.span7
                      : gridCss.span12
                  }
                >
                  <div className={productSelectModalCss.productLeft}>
                    <Checkbox
                      disabled={
                        !product.variants.some((v) => isVariantInStock(v))
                      }
                      onChange={(checked) => {
                        if (checked) {
                          // add all variants to products
                          const newVariants: VariantInfo[] =
                            product.variants.filter((v) => isVariantInStock(v));
                          setCurrentlySelectedVariants([
                            ...currentlySelectedVariants,
                            ...newVariants,
                          ]);
                        } else {
                          setCurrentlySelectedVariants(
                            currentlySelectedVariants.filter(
                              (p) =>
                                !product.variants
                                  .map((pv: VariantInfo) => pv.id)
                                  .includes(p.id),
                            ),
                          );
                        }
                      }}
                      value={
                        !!product.variants.find((v) =>
                          currentlySelectedVariants
                            .map((s) => s.id)
                            .includes(v.id),
                        )
                      }
                    />
                    <img
                      alt={product.image.alt || ""}
                      className={productSelectModalCss.image}
                      src={product.image.url || ""}
                    />
                    <div>{product.name}</div>
                  </div>
                </div>

                {product.variants.length === 1 && (
                  <>
                    <div className={gridCss.span3}>
                      {getTotalVariantQuantity(product)} available
                    </div>
                    <div className={gridCss.span2}>
                      {product.variants[0].price}
                    </div>
                  </>
                )}
              </div>
              {product.variants.length > 1 &&
                product.variants.map((variant) => (
                  <div key={variant.id}>
                    <Divider />
                    <div
                      className={classNames(
                        gridCss.grid,
                        productSelectModalCss.variantContainer,
                      )}
                    >
                      <div className={gridCss.span7}>
                        <div className={productSelectModalCss.productLeft}>
                          <Checkbox
                            disabled={!isVariantInStock(variant)}
                            onChange={(checked) => {
                              if (checked) {
                                setCurrentlySelectedVariants([
                                  ...currentlySelectedVariants,
                                  variant,
                                ]);
                              } else {
                                setCurrentlySelectedVariants(
                                  currentlySelectedVariants.filter(
                                    (p) => p.id !== variant.id,
                                  ),
                                );
                              }
                            }}
                            value={
                              !!currentlySelectedVariants.find(
                                (p) => variant.id === p.id,
                              )
                            }
                          />
                          <img
                            alt={variant.image.alt || ""}
                            className={productSelectModalCss.image}
                            src={variant.image.url || ""}
                          />
                          <div>{variant.name}</div>
                        </div>
                      </div>
                      <div className={gridCss.span3}>
                        {variant.inventoryQuantity} available
                      </div>
                      <div className={gridCss.span2}>{variant.price}</div>
                    </div>
                  </div>
                ))}
              <Divider />
            </div>
          ))
        )}
      </div>
    </Modal>
  );
});
