import { z } from "zod";

export const getSupportAiPricing = {
  input: z.object({}),
  output: z.object({
    monthlyOrders: z.number(),
    platformPrice: z.number(),
    aiPrice: z.number(),
    aiResolutionPriceInCents: z.number(),
  }),
};
