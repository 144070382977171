export function earliestDate(dates: Date[]): Date {
  return new Date(Math.min(...dates.map((date) => date.getTime())));
}

export function latestDate(dates: Date[]): Date {
  return new Date(Math.max(...dates.map((date) => date.getTime())));
}

export function sortDatesOldestToNewest(dates: Date[]): Date[] {
  return dates.sort((a, b) => b.getTime() - a.getTime());
}

export function sortDatesNewestToOldest(dates: Date[]): Date[] {
  return dates.sort((a, b) => a.getTime() - b.getTime());
}

export function isValidDate(date: Date): boolean {
  return !isNaN(date.getTime());
}

// Returns the date in YYYY-MM-DD format
export function getDateString(date: Date): string {
  return date.toISOString().split("T")[0];
}

export function getDateStartOfDay(date: Date): Date {
  const startOfDay = new Date(date);
  startOfDay.setHours(0, 0, 0, 0);
  return startOfDay;
}

export function getDateStartOfDayUtc(date: Date): Date {
  const startOfDay = new Date(date);
  startOfDay.setUTCHours(0, 0, 0, 0);
  return startOfDay;
}

export function getDateEndOfDay(date: Date): Date {
  const endOfDay = new Date(date);
  endOfDay.setHours(23, 59, 59, 999);
  return endOfDay;
}

export function getStartOfWeek(date: Date): Date {
  const startOfWeek = new Date(date);
  const dayOfWeek = startOfWeek.getDay();
  const diff = startOfWeek.getDate() - dayOfWeek; // adjust when day is Sunday
  startOfWeek.setDate(diff);
  startOfWeek.setHours(0, 0, 0, 0);
  return startOfWeek;
}

export function getEndOfWeek(date: Date): Date {
  const endOfWeek = new Date(date);
  const dayOfWeek = endOfWeek.getDay();
  const diff = endOfWeek.getDate() + (6 - dayOfWeek); // adjust when day is Sunday
  endOfWeek.setDate(diff);
  endOfWeek.setHours(23, 59, 59, 999);
  return endOfWeek;
}

export function getStartOfMonth(date: Date): Date {
  const startOfMonth = new Date(date);
  startOfMonth.setDate(1);
  startOfMonth.setHours(0, 0, 0, 0);
  return startOfMonth;
}

export function getEndOfMonth(date: Date): Date {
  const endOfMonth = new Date(date);
  const lastDay = new Date(
    endOfMonth.getFullYear(),
    endOfMonth.getMonth() + 1,
    0,
  ).getDate();
  endOfMonth.setDate(lastDay);
  endOfMonth.setHours(23, 59, 59, 999);
  return endOfMonth;
}

export function getStartOfYear(date: Date): Date {
  const startOfYear = new Date(date);
  startOfYear.setMonth(0); // January
  startOfYear.setDate(1);
  startOfYear.setHours(0, 0, 0, 0);
  return startOfYear;
}

export function getEndOfYear(date: Date): Date {
  const endOfYear = new Date(date);
  endOfYear.setMonth(11); // December
  const lastDay = new Date(
    endOfYear.getFullYear(),
    endOfYear.getMonth() + 1,
    0,
  ).getDate();
  endOfYear.setDate(lastDay);
  endOfYear.setHours(23, 59, 59, 999);
  return endOfYear;
}

export function isBeforeNow(date: Date): boolean {
  return date.getTime() < new Date().getTime();
}

export function isAfterNow(date: Date): boolean {
  return date.getTime() > new Date().getTime();
}

export function getPlainDateFromDateUtc(date: Date): Temporal.PlainDate {
  return Temporal.PlainDate.from({
    year: date.getUTCFullYear(),
    month: date.getUTCMonth() + 1,
    day: date.getUTCDate(),
  });
}

export function getStartOfDayWithTimezone(
  plainDate: Temporal.PlainDate,
  tz: string,
): Date {
  return new Date(
    plainDate
      .toPlainDateTime({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toZonedDateTime(tz).epochMilliseconds,
  );
}

export function getEndOfDayWithTimezone(
  plainDate: Temporal.PlainDate,
  tz: string,
): Date {
  return new Date(
    plainDate
      .toPlainDateTime({ hour: 23, minute: 59, second: 59, millisecond: 999 })
      .toZonedDateTime(tz).epochMilliseconds,
  );
}

export function getStartOfWeekWithTimezone(
  plainDate: Temporal.PlainDate,
  tz: string,
): Date {
  const daysFromMonday = plainDate.dayOfWeek - 1 || 0;
  const startOfWeek = plainDate.subtract({ days: daysFromMonday });

  return new Date(
    startOfWeek
      .toPlainDateTime({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toZonedDateTime(tz).epochMilliseconds,
  );
}

export function getEndOfWeekWithTimezone(
  plainDate: Temporal.PlainDate,
  tz: string,
): Date {
  const daysFromMonday = plainDate.dayOfWeek - 1 || 0;
  const startOfWeek = plainDate.subtract({ days: daysFromMonday });
  const endOfWeek = startOfWeek.add({ days: 6 });

  return new Date(
    endOfWeek
      .toPlainDateTime({ hour: 23, minute: 59, second: 59, millisecond: 999 })
      .toZonedDateTime(tz).epochMilliseconds,
  );
}

export function getStartOfMonthWithTimezone(
  plainDate: Temporal.PlainDate,
  tz: string,
): Date {
  const startOfMonth = plainDate.with({ day: 1 });

  return new Date(
    startOfMonth
      .toPlainDateTime({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toZonedDateTime(tz).epochMilliseconds,
  );
}

export function getEndOfMonthWithTimezone(
  plainDate: Temporal.PlainDate,
  tz: string,
): Date {
  const endOfMonth = plainDate.with({ day: plainDate.daysInMonth });

  return new Date(
    endOfMonth
      .toPlainDateTime({ hour: 23, minute: 59, second: 59, millisecond: 999 })
      .toZonedDateTime(tz).epochMilliseconds,
  );
}

export function getStartOfYearWithTimezone(
  plainDate: Temporal.PlainDate,
  tz: string,
): Date {
  const startOfYear = plainDate.with({ month: 1, day: 1 });

  return new Date(
    startOfYear
      .toPlainDateTime({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toZonedDateTime(tz).epochMilliseconds,
  );
}

export function getEndOfYearWithTimezone(
  plainDate: Temporal.PlainDate,
  tz: string,
): Date {
  const endOfYear = plainDate.with({ month: 12, day: 31 });

  return new Date(
    endOfYear
      .toPlainDateTime({ hour: 23, minute: 59, second: 59, millisecond: 999 })
      .toZonedDateTime(tz).epochMilliseconds,
  );
}
