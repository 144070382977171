import { useRequiredContext } from "@redotech/react-util/context";
import { useLoad } from "@redotech/react-util/load";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { getCustomerTags } from "@redotech/redo-merchant-app-common/client/shopify";
import {
  CustomerTagsFilterType,
  FilterGroupFilterOption,
  CustomerTagsFilter as ModelCustomerTagsFilter,
  PendingFilter,
} from "@redotech/redo-model/conversation-filters/conversation-filters";
import { RedoFilterGroup } from "@redotech/redo-web/arbiter-components/filter-group/redo-filter-group";
import {
  ConversationFilterOptionToIcon,
  ConversationFilterOptionToName,
} from "@redotech/redo-web/conversation-filters/conversation-filter-icons";
import { SimpleFilterDropdown } from "@redotech/redo-web/filters/simple-filter-dropdown";
import { identity } from "@redotech/util/function";
import { memo, useMemo } from "react";
import { SimpleMultiSelectFilterDropdown } from "../../../../web/src/filters/simple-multiselect-filter-dropdown";

export const CustomerTagsFilter = memo(function CustomerTagsFilter({
  filter,
  removeFilter,
  setFilter,
  openOnRender,
}: {
  filter: ModelCustomerTagsFilter | PendingFilter<ModelCustomerTagsFilter>;
  removeFilter(): void;
  setFilter(
    filter: ModelCustomerTagsFilter | PendingFilter<ModelCustomerTagsFilter>,
  ): void;
  openOnRender: boolean;
}) {
  const client = useRequiredContext(RedoMerchantClientContext);

  const customerTagFilterType = filter.query;
  const selectedTags = filter.value;

  const availableCustomerTagsLoad = useLoad(
    (signal) => getCustomerTags(client, { signal }),
    [client],
  );

  const allTagItems = useMemo<string[]>(
    () => (availableCustomerTagsLoad.value || []).map((tag) => tag.name),
    [availableCustomerTagsLoad.value],
  );

  return (
    <RedoFilterGroup
      Icon={
        ConversationFilterOptionToIcon[FilterGroupFilterOption.CUSTOMER_TAGS]
      }
      propertyName={
        ConversationFilterOptionToName[FilterGroupFilterOption.CUSTOMER_TAGS]
      }
      query={
        <SimpleFilterDropdown
          filterStyle="query"
          options={Object.values(CustomerTagsFilterType)}
          optionToFriendlyName={(option) => tagFilterTypeToText[option]}
          setValue={(query) => setFilter({ ...filter, query })}
          value={customerTagFilterType}
        />
      }
      removeFilter={removeFilter}
      value={
        <SimpleMultiSelectFilterDropdown
          openOnRender={openOnRender}
          options={allTagItems}
          optionToFriendlyName={identity}
          selectedOptionsToDisplay={(selectedOptions) =>
            selectedOptions
              ? selectedOptions.length === 1
                ? "1 Tag"
                : `${selectedOptions.length} Tags`
              : "..."
          }
          setValues={(tags) => {
            if (tags.length === 0) {
              setFilter({ ...filter, value: null });
            } else {
              setFilter({ ...filter, value: tags });
            }
          }}
          values={selectedTags || undefined}
        />
      }
    />
  );
});

const tagFilterTypeToText: Record<CustomerTagsFilterType, string> = {
  [CustomerTagsFilterType.ALL_OF]: "have all of",
  [CustomerTagsFilterType.ANY_OF]: "include any of",
  [CustomerTagsFilterType.NONE_OF]: "include none of",
};
