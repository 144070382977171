import { productToProcessSchema } from "@redotech/redo-model/returns/product-to-process-schema";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const processReturn = {
  input: z.object({
    returnId: zExt.objectId(),
    products: z.array(productToProcessSchema),
    customerRecoveryAmount: z.number(),
    options: z.object({
      sendNotifications: z.boolean(),
      refundInstantExchangeRecoveryFee: z.boolean(),
      waiveDraftFee: z.boolean().optional(),
    }),
    customerNotes: z.string().optional(),
    userId: zExt.objectId(),
    orderTags: z.array(z.string()).optional(),
  }),
  output: z.null(),
};
