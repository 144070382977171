import { z } from "zod";
import { zExt } from "../common/zod-util";
import { OMSUserSchema } from "./oms-user";

export enum FulfillmentBatchStatus {
  PENDING_LABEL_PURCHASE = "pending",
  LABELS_CREATED = "labels_created",
  LABELS_PRINTED = "labels_printed",
  PENDING_MANUAL_FULFILLMENT = "pending_manual_fulfillment",
  MANUALLY_FULFILLED = "manually_fulfilled",
  FAILED = "failed",
  PICKED_UP = "picked_up",
  READY_FOR_PICKUP = "ready_for_pickup",
  PARTIALLY_PICKED_UP = "partially_picked_up",
}

export enum BatchHistoryTableColumnKeys {
  SELECTED = "selected",
  BATCH_ID = "batchId",
  BATCH_DATE = "batchDate",
  ORDERS = "orders",
  SHIPMENTS = "shipments",
  BATCH_STATUS = "batchStatus",
  ERRORS = "errors",
  CREATED_BY = "createdBy",
  NUMBER_OF_FULFILLMENTS = "numberOfFulfillments",
}

export const FulfillmentBatchErrorSchema = z.object({
  fulfillmentGroupId: zExt.objectId(),
  message: z.string(),
});

export type FulfillmentBatchError = z.infer<typeof FulfillmentBatchErrorSchema>;

export const FulfillmentBatchSchema = z.object({
  _id: zExt.objectId(),
  id: z.string(),
  team: zExt.objectId(),
  batchDate: z.date(),
  orderNumbers: z.array(z.string()),
  fulfillmentGroupIds: z.array(zExt.objectId()),
  failedFulfillmentGroupIds: z.array(zExt.objectId()).nullish(),
  errors: z.array(FulfillmentBatchErrorSchema).nullish(),
  selectedRates: z.record(zExt.objectId()).nullish(),
  shipmentIds: z.array(z.string()),
  batchStatus: z.nativeEnum(FulfillmentBatchStatus),
  documents: z
    .object({
      shippingLabel: z.string().nullish(),
      packingSlip: z.string().nullish(),
      shippingLabel_packingSlip: z.string().nullish(),
      pickList: z.string().nullish(),
      commercialInvoice: z.string().nullish(),
    })
    .nullish(),
  createdBy: OMSUserSchema.nullish(),
  containsCommercialInvoice: z.boolean().nullish(),
  notifyMarketplace: z.boolean().nullish(),
  fulfillmentDate: z.date().nullish(),
  insured: z.boolean().nullish(),
  updatedAt: z.date().nullish(),
  local: z.boolean().nullish(),
});
export type FulfillmentBatch = z.infer<typeof FulfillmentBatchSchema>;
export type FulfillmentBatchDocumentKind = keyof NonNullable<
  FulfillmentBatch["documents"]
>;
