import { z } from "zod";
import { AdvancedFilterDataSchema } from "../views/views";

export const OmsTableInputSchema = z.object({
  // put shared search, sort, filters here
  advancedFiltersData: z.array(AdvancedFilterDataSchema),
  sort: z
    .object({ key: z.string(), direction: z.enum(["asc", "desc"]) })
    .nullish(),
  search: z.string().nullish(),
  merchantTimezone: z.string().optional(),
});
export type OmsTableInput = z.infer<typeof OmsTableInputSchema>;
