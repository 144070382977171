import { AnimateLayoutChanges, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import * as classNames from "classnames";
import { CSSProperties, memo, useEffect, useRef, useState } from "react";
import { Flex } from "../../../../flex";
import { TreeItemComponent, TreeItemProps } from "./tree-item";
import * as navigationCss from "./tree-item.module.css";

const animateLayoutChanges: AnimateLayoutChanges = ({
  isSorting,
  wasDragging,
}) => (isSorting || wasDragging ? false : true);

export const SortableTreeItem = memo(function SortableTreeItem({
  depth,
  value,
  ...props
}: TreeItemProps) {
  const {
    attributes,
    listeners,
    setDraggableNodeRef,
    setDroppableNodeRef,
    transform,
    transition,
    isDragging,
    isSorting,
  } = useSortable({ id: value.id, animateLayoutChanges });

  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  // This whole thing is a bit of a hack to create a smooth transition for a folders expansion
  // We want the height of elements that are created in an open folder to be animated when the folder is opened
  const firstRenderRef = useRef(true);
  const expandable =
    !!value.parentId && !props.clone && !props.ghost && !isSorting;
  const initallyExpanded = !value.parentId || props.clone || props.ghost;
  const [expanded, setExpanded] = useState(initallyExpanded);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      setExpanded(true);
    }
  }, [setExpanded]);

  return (
    <Flex
      className={classNames(
        expandable && navigationCss.expandableItem,
        expanded && navigationCss.expanded,
      )}
      m="xxs"
      overflow="visible"
    >
      <TreeItemComponent
        depth={depth}
        ghost={isDragging}
        handleProps={{ ...attributes, ...listeners }}
        ref={setDraggableNodeRef}
        style={style}
        value={value}
        wrapperRef={setDroppableNodeRef}
        {...props}
      />
    </Flex>
  );
});
