import { RedoMerchantClient } from "@redotech/redo-merchant-app-common/client";
import { REDO_MERCHANT_SERVER_URL } from "@redotech/redo-merchant-app-common/config";
import { ExpandedConversation } from "@redotech/redo-model/conversation";

export function getMergeGroupForConversation({
  client,
  conversationId,
  signal,
}: {
  client: RedoMerchantClient;
  conversationId: string;
  signal: AbortSignal;
}): Promise<{
  data: { dismissed: boolean; conversations: ExpandedConversation[] };
}> {
  const url = `${REDO_MERCHANT_SERVER_URL}/team/conversations/${conversationId}/merge-group`;
  return client.client.get(url, { signal, headers: client.authorization() });
}

export function dismissMergeSuggestionForConversation({
  client,
  conversationId,
  signal,
}: {
  client: RedoMerchantClient;
  conversationId: string;
  signal: AbortSignal;
}): Promise<void> {
  const url = `${REDO_MERCHANT_SERVER_URL}/team/conversations/${conversationId}/dismiss-suggested-merge`;
  return client.client.post(
    url,
    {},
    { signal, headers: client.authorization() },
  );
}
