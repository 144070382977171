import { useRequiredContext } from "@redotech/react-util/context";
import { useLoad } from "@redotech/react-util/load";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { ExpandedConversation } from "@redotech/redo-model/conversation";
import {
  isConversationCreatedCustomerEvent,
  isConversationMessageCreatedCustomerEvent,
  isConversationStatusUpdatedCustomerEvent,
} from "@redotech/redo-model/customer-activity/customer-activity-definition";
import { IConversationCustomerEvent } from "@redotech/redo-model/customer-event/customer-event-definition";
import MessageTextSquareIcon from "@redotech/redo-web/arbiter-icon/message-text-square-02.svg";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { getConversation } from "../../../../client/conversations";
import * as customerActivityCardCss from "../../../../common/activity-card.module.css";
import { BaseActivityCard } from "../../../../common/base-activity-card";
import { ConversationStatusPill } from "../../../../common/conversation-status-pill";
import { MessageBubble } from "./message-bubble";

export const ConversationCustomerActivityCard = memo(
  function ConversationCustomerActivityCard({
    customerEvent,
  }: {
    customerEvent: IConversationCustomerEvent;
  }) {
    const navigate = useNavigate();

    const client = useRequiredContext(RedoMerchantClientContext);

    const conversationLoad = useLoad<ExpandedConversation | null>(async () => {
      if (!customerEvent.conversationId) {
        console.error("No conversation ID provided");
        return null;
      }
      return await getConversation(client, {
        conversationId: customerEvent.conversationId,
      });
    }, [customerEvent.conversationId]);

    const iconElement = (
      <Flex
        align="center"
        className={
          customerActivityCardCss.customerActivityDataConversationIconContainer
        }
        justify="center"
      >
        <MessageTextSquareIcon
          className={
            customerActivityCardCss.customerActivityDataConversationIcon
          }
        />
      </Flex>
    );

    const getTitle = (): string => {
      if (isConversationMessageCreatedCustomerEvent(customerEvent)) {
        return "New support reply";
      } else if (isConversationCreatedCustomerEvent(customerEvent)) {
        return "New support ticket created";
      } else if (isConversationStatusUpdatedCustomerEvent(customerEvent)) {
        return "Support ticket status updated";
      } else {
        return "";
      }
    };

    const getExtraDetailsElement = (): ReactNode | undefined => {
      if (isConversationStatusUpdatedCustomerEvent(customerEvent)) {
        return (
          <Flex mt="lg">
            <ConversationStatusPill
              conversationStatus={customerEvent.newStatus}
            />
          </Flex>
        );
      } else if (isConversationMessageCreatedCustomerEvent(customerEvent)) {
        return (
          <Flex flex={1} mt="lg">
            <MessageBubble text={customerEvent.messageId} />
          </Flex>
        );
      } else if (conversationLoad.value) {
        return (
          <Text
            className={customerActivityCardCss.messageBubbleText}
            fontSize="xs"
            textColor="tertiary"
            textOverflow="wrap"
          >
            {conversationLoad.value?.messages?.length > 0 &&
              conversationLoad.value?.messages[
                conversationLoad.value?.messages?.length - 1
              ].content}
          </Text>
        );
      } else {
        return undefined;
      }
    };

    const onCardClick = () => {
      navigate(
        `/stores/${customerEvent.team.toString()}/support/table/all?activeConversationId=${customerEvent.conversationId}`,
      );
    };

    return (
      <BaseActivityCard
        extraDetailsElement={getExtraDetailsElement()}
        onClick={onCardClick}
        timestamp={customerEvent.timestamp}
        title={getTitle()}
        topLeftElement={iconElement}
      />
    );
  },
);
