import { useRequiredContext } from "@redotech/react-util/context";
import { useTriggerLoad } from "@redotech/react-util/load";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import type { Return } from "@redotech/redo-model/return";
import { ReturnStatus } from "@redotech/redo-model/return-status";
import { alertOnFailure } from "@redotech/redo-web/alert";
import { RedoModal } from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import AlertTriangle from "@redotech/redo-web/arbiter-icon/alert-triangle.svg";
import { Checkbox } from "@redotech/redo-web/checkbox";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo, useMemo, useState } from "react";
import { reopenReturn } from "../../client/return";
import { returnTypeName } from "../util";

export const DO_NOT_SHOW_REOPEN_PROCESSED_MODAL_KEY =
  "redo.doNotShowReopenProcessedModal";

export const ReopenModal = memo(function ReopenModal({
  open,
  return: return_,
  onClose,
  reload,
}: {
  open: boolean;
  onClose(): void;
  return: Return;
  reload(): void;
}) {
  const client = useRequiredContext(RedoMerchantClientContext);

  const [doNotShowAgainChecked, setDoNotShowAgainChecked] = useState(false);

  const [reopenLoad, doReopen] = useTriggerLoad((signal) =>
    alertOnFailure("Reopening return failed")(async () => {
      if (doNotShowAgainChecked) {
        localStorage.setItem(DO_NOT_SHOW_REOPEN_PROCESSED_MODAL_KEY, "true");
      }

      await reopenReturn(client, { returnId: return_.id, signal });

      reload();
      onClose();
      location.reload();
      return true;
    }),
  );

  const isProcessed = return_.status === ReturnStatus.COMPLETE;

  const message = useMemo(() => {
    if (isProcessed) {
      return `You are about to re-open this processed ${returnTypeName(return_.type)}. This cannot undo the actions performed when the ${returnTypeName(return_.type)} was processed.`;
    } else {
      return `Are you sure you want to re-open this ${returnTypeName(return_.type)}?`;
    }
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [return_.status]);

  return (
    <RedoModal
      hideCloseButton={reopenLoad.pending}
      isOpen={open}
      modalSize="sm"
      onModalCloseRequested={() => {
        if (!reopenLoad.pending) {
          onClose();
        }
      }}
      primaryButton={{
        text: "Re-open",
        onClick: doReopen,
        disabled: reopenLoad.pending,
      }}
      secondaryButton={{
        text: "Cancel",
        onClick: onClose,
        disabled: reopenLoad.pending,
      }}
      theme="warn"
      title="Re-open return"
      TitleIcon={AlertTriangle}
    >
      <Flex align="flex-start" dir="column" gap="lg">
        <Text textColor="tertiary">{message}</Text>
        {isProcessed && (
          <Checkbox
            onChange={setDoNotShowAgainChecked}
            value={doNotShowAgainChecked}
          >
            Don't show again
          </Checkbox>
        )}
      </Flex>
    </RedoModal>
  );
});
