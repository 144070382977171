import { useRequiredContext } from "@redotech/react-util/context";
import { useLoad } from "@redotech/react-util/load";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { getUserById } from "@redotech/redo-merchant-app-common/client/user";
import { TeamUser } from "@redotech/redo-model/team";
import { GetUser as User } from "@redotech/redo-model/user";
import { SelectDropdown } from "@redotech/redo-web/select-dropdown";
import { memo } from "react";

export const AssigneePicker = memo(function AssigneePicker({
  assignee,
  users,
  onChange,
  showChatbot = false,
}: {
  assignee: User | null | undefined;
  users: TeamUser[];
  onChange(user: User): void;
  showChatbot?: boolean;
}) {
  const client = useRequiredContext(RedoMerchantClientContext);
  const chatBotUserLoad = useLoad(async (signal) => {
    const chatBotUser = await getUserById(client, {
      id: process.env.CHATBOT_USER_ID!,
      signal,
    });
    return chatBotUser;
  }, []);
  const options = [null, ...users.map((user) => user.user as User)];

  return (
    <SelectDropdown
      allowNull
      options={
        !!showChatbot && chatBotUserLoad.value
          ? [...options, chatBotUserLoad.value]
          : options
      }
      placeholder="Select user"
      value={assignee}
      valueChange={onChange}
    >
      {(option) => {
        if (option?._id === process.env.CHATBOT_USER_ID) {
          return "Chatbot";
        } else if (option) {
          return (option as User)?.name;
        } else {
          return "Unassigned";
        }
      }}
    </SelectDropdown>
  );
});
