import { checkoutWidgetsSchema } from "@redotech/redo-model/checkouts/checkout-widgets-builder";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const updateCheckoutExperienceWidgets = {
  input: z.object({
    checkoutExperienceId: zExt.objectId(),
    checkoutWidgets: checkoutWidgetsSchema,
  }),
  output: z.null(),
};
