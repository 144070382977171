import { useRequiredContext } from "@redotech/react-util/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import {
  AssigneesFilterType,
  FilterGroupFilterOption,
  AssigneesFilter as ModelAssigneesFilter,
  PendingFilter,
} from "@redotech/redo-model/conversation-filters/conversation-filters";
import { GetUser } from "@redotech/redo-model/user";
import {
  RedoFilterDropdownAnchor,
  RedoFilterGroup,
} from "@redotech/redo-web/arbiter-components/filter-group/redo-filter-group";
import { RedoListItem } from "@redotech/redo-web/arbiter-components/list/redo-list";
import { RedoMultiselectDropdown } from "@redotech/redo-web/arbiter-components/list/redo-multiselect-dropdown";
import {
  ConversationFilterOptionToIcon,
  ConversationFilterOptionToName,
} from "@redotech/redo-web/conversation-filters/conversation-filter-icons";
import { SimpleFilterDropdown } from "@redotech/redo-web/filters/simple-filter-dropdown";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo, useEffect, useMemo, useState } from "react";

type Assignee = { id: string | null; name: string };

export const AssigneesFilter = memo(function AssigneesFilter({
  filter,
  setFilter,
  removeFilter,
  openOnRender,
}: {
  filter: ModelAssigneesFilter | PendingFilter<ModelAssigneesFilter>;
  setFilter(
    filter: ModelAssigneesFilter | PendingFilter<ModelAssigneesFilter>,
  ): void;
  removeFilter(): void;
  openOnRender: boolean;
}) {
  const [valueRef, setValueRef] = useState<HTMLButtonElement | null>(null);

  const team = useRequiredContext(TeamContext);

  const { value: assignees, query: assigneeFilterType } = filter;

  const [valueDropdownOpen, setValueDropdownOpen] = useState(false);

  const teamUsers = team.users.map((user) => user.user as GetUser);

  const assigneesOptions: Assignee[] = useMemo(
    () => [
      { id: null, name: "Unassigned" },
      ...teamUsers
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((user) => ({ id: user._id, name: user.name })),
      { id: process.env.CHATBOT_USER_ID || "", name: "Chatbot" },
    ],
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [team],
  );

  useEffect(() => {
    if (openOnRender && valueRef) {
      valueRef.click();
    }
  }, [openOnRender, valueRef]);

  const valueText = assignees
    ? assignees.length === 1
      ? "1 Assignee"
      : ` ${assignees.length} Assignees`
    : "...";

  const value = (
    <RedoFilterDropdownAnchor
      color="primary"
      onClick={() => setValueDropdownOpen(!valueDropdownOpen)}
      ref={setValueRef}
      text={valueText}
      tooltip={
        assignees && (
          <Flex dir="column">
            {assignees.map((assignee) => (
              <Text key={assignee}>
                {assigneeToFriendlyAssignee(assignee, teamUsers)}
              </Text>
            ))}
          </Flex>
        )
      }
      weight="medium"
    />
  );

  const assigneeListOptions: RedoListItem<Assignee>[] = useMemo(
    () =>
      assigneesOptions.map<RedoListItem<Assignee>>((assignee) => {
        return {
          id: assignee.id || "null",
          type: "text",
          text: assignee.name,
          value: assignee,
        };
      }),
    [assigneesOptions],
  );

  const selectedAssignees: RedoListItem<Assignee>[] = useMemo(() => {
    if (!assignees) {
      return [];
    }
    return assigneeListOptions.filter((assignee) => {
      return assignees.some(
        (selectedAssignee) => selectedAssignee === assignee.value.id,
      );
    });
  }, [assignees, assigneeListOptions]);

  const valueDropdown = (
    <RedoMultiselectDropdown
      dropdownAnchor={valueRef}
      dropdownOpen={valueDropdownOpen}
      fitToAnchor={false}
      items={assigneeListOptions}
      selectedItems={selectedAssignees}
      setDropdownOpen={setValueDropdownOpen}
      setSelectedItems={(assignees) => {
        if (assignees.length === 0) {
          setFilter({ ...filter, value: null });
        } else {
          const ids = assignees.map((item) => item.value.id);
          setFilter({ ...filter, value: ids });
        }
      }}
      size="xs"
    />
  );

  return (
    <>
      {valueDropdown}
      <RedoFilterGroup
        Icon={ConversationFilterOptionToIcon[FilterGroupFilterOption.ASSIGNEES]}
        propertyName={
          ConversationFilterOptionToName[FilterGroupFilterOption.ASSIGNEES]
        }
        query={
          <SimpleFilterDropdown
            filterStyle="query"
            options={Object.values(AssigneesFilterType)}
            optionToFriendlyName={(option) => assigneeFilterTypeToText[option]}
            setValue={(value) => setFilter({ ...filter, query: value })}
            value={assigneeFilterType}
          />
        }
        removeFilter={removeFilter}
        value={value}
      />
    </>
  );
});

const assigneeFilterTypeToText: Record<AssigneesFilterType, string> = {
  [AssigneesFilterType.INCLUDES]: "include",
  [AssigneesFilterType.EXCLUDES]: "do not include",
};

function assigneeToFriendlyAssignee(
  assignee: string | null,
  teamUsers: GetUser[],
): string {
  if (assignee === null) {
    return "Unassigned";
  } else if (assignee === process.env.CHATBOT_USER_ID) {
    return "Chatbot";
  }
  return (
    teamUsers.find((user) => user._id === assignee)?.name || "Unknown user"
  );
}
