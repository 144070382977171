// extracted by mini-css-extract-plugin
export var box = "_3o-1";
export var checked = "_3o-5";
export var descriptionSpacer = "_3o-9";
export var disabled = "_3o-4";
export var icon = "_3o-6";
export var input = "_3o-0";
export var labelSpacer = "_3o-8";
export var medium = "_3o-3";
export var small = "_3o-2";
export var text = "_3o-7";