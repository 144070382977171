import { useRequiredContext } from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { useHandler } from "@redotech/react-util/hook";
import { useTriggerLoad } from "@redotech/react-util/load";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { Order } from "@redotech/redo-model/order";
import {
  MerchantAppReturn,
  ReturnAddress,
  ReturnTypeEnum,
} from "@redotech/redo-model/return";
import {
  kilogramsToGrams,
  ouncesToGrams,
  poundsToGrams,
  WeightUnit,
} from "@redotech/redo-model/weight-conversion";
import { alertOnFailure } from "@redotech/redo-web/alert";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  IconButton,
} from "@redotech/redo-web/button";
import { FormCheckbox } from "@redotech/redo-web/checkbox";
import { ChipInput } from "@redotech/redo-web/chip-input";
import { Flex } from "@redotech/redo-web/flex";
import HelpCircleIcon from "@redotech/redo-web/icon-old/help-circle.svg";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { Modal, ModalSize } from "@redotech/redo-web/modal";
import {
  FormSelectDropdown,
  SelectDropdown,
} from "@redotech/redo-web/select-dropdown";
import { Text } from "@redotech/redo-web/text";
import {
  FormTextInput,
  InputLines,
  TextInput,
} from "@redotech/redo-web/text-input";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import {
  groupInput,
  input,
  InputProvider,
  listInput,
  numberValidator,
} from "@redotech/ui/form";
import { memo, useState } from "react";
import { approveReturn, reapproveReturn } from "../../client/return";
import * as returnCss from "../return.module.css";
import { returnTypeName } from "../util";
import { AllLabelsValue, CreateLabelsModal } from "./create-labels-modal";

const MAX_WEIGHT = 45359; // 100 lbs

export type ApprovalProductInfo = {
  _id: string;
  weight?: number;
  weightUnit?: WeightUnit;
  shipBack?: boolean;
};

const productInput = groupInput({
  _id: input<string>(),
  weight: input<string>({ validator: numberValidator({ min: 0 }) }),
  weightUnit: input<string>(),
  shipBack: input<boolean>(),
});

type productValue = InputProvider.Value<typeof productInput>;

const productDefault = (_id: string): productValue => ({
  _id,
  weight: "0",
  weightUnit: WeightUnit.GRAMS,
  shipBack: true,
});

const approveInput = listInput(
  () => productInput,
  productDefault,
  (product: productValue) => product._id,
);

export const ApproveModal = memo(function ApproveModal({
  return: return_,
  open,
  reapprove,
  onClose,
  reload,
  orders = [],
}: {
  open: boolean;
  onClose(): void;
  reapprove: boolean;
  return: MerchantAppReturn;
  reload(): void;
  orders?: Order[];
}) {
  const client = useRequiredContext(RedoMerchantClientContext);
  const team = useRequiredContext(TeamContext);
  const [showShippingScreen, setShowShippingScreen] = useState<boolean>(false);
  const [multipleLabels, setMultipleLabels] = useState<
    AllLabelsValue | undefined
  >(undefined);
  const [note, setNote] = useState("");
  const [orderTags, setOrderTags] = useState<readonly string[]>([]);

  const hasMultipleLabelsEnabled = team.settings.returns?.multipleLabelsEnabled;

  const hasSingleShipmentGroup = return_.shipmentGroups?.length === 1;
  const hasMultipleLocations = team.settings.locations?.length > 1;
  const canEditAddressOrMultipleLabels =
    team.settings.canEditReturnAddress || hasMultipleLabelsEnabled;

  const showAddressSelection = !!(
    return_.merchant_address &&
    hasSingleShipmentGroup &&
    hasMultipleLocations &&
    canEditAddressOrMultipleLabels
  );

  const [address, setAddress] = useState<ReturnAddress | undefined>(
    showAddressSelection ? return_.merchant_address : undefined,
  );

  const input = useInput(
    approveInput,
    return_.products.map((product) => ({
      _id: product._id,
      weight: String(product.grams) || "0",
      weightUnit: WeightUnit.GRAMS,
      shipBack: !product.green_return,
    })),
  );

  const getGrams = (weight: number, weightUnit: WeightUnit) => {
    switch (weightUnit) {
      case WeightUnit.GRAMS:
        return weight;
      case WeightUnit.OUNCES:
        return ouncesToGrams(weight);
      case WeightUnit.POUNDS:
        return poundsToGrams(weight);
      case WeightUnit.KILOGRAMS:
        return kilogramsToGrams(weight);
      default:
        // Shouldn't happen
        console.error("Invalid weight unit", weightUnit);
        return weight;
    }
  };

  const getApprovalProductsInfo = (): ApprovalProductInfo[] =>
    input.value.map((product) => ({
      _id: product._id,
      grams: getGrams(Number(product.weight), product.weightUnit as WeightUnit),
      shipBack: product.shipBack,
    }));

  const [approveLoad, doApprove] = useTriggerLoad((signal) =>
    alertOnFailure("Approving return failed")(async () => {
      const approvalProducts = getApprovalProductsInfo();

      if (reapprove) {
        await reapproveReturn(client, {
          returnId: return_.id,
          customerNotes: note,
          products: approvalProducts,
          selectedAddress: address,
          orderTags,
          multipleLabels,
          signal,
        });
      } else {
        await approveReturn(client, {
          customerNotes: note,
          returnId: return_.id,
          products: approvalProducts,
          selectedAddress: address,
          orderTags,
          multipleLabels,
          signal,
        });
      }
      reload();
      return true;
    }),
  );
  const handleSubmit = useHandler(() => doApprove());

  const addressOptions: ReturnAddress[] = [];
  if (showAddressSelection) {
    for (const location of team.settings.locations) {
      if (location.address && location.address.street1) {
        addressOptions.push({
          ...location.address,
          address1: location.address.street1,
          address2: location.address.street2,
          phone: location.address.phone || "",
          longitude: undefined,
          latitude: undefined,
        });
      }
    }
  }

  const footer = approveLoad.value ? (
    <Button
      className={returnCss.modalButton}
      onClick={onClose}
      theme={ButtonTheme.OUTLINED}
    >
      Ok
    </Button>
  ) : (
    <>
      <Button
        className={returnCss.modalButton}
        onClick={onClose}
        theme={ButtonTheme.OUTLINED}
      >
        No, go back
      </Button>

      {team.settings.returns?.multipleLabelsEnabled &&
      input.value.some((product) => product.shipBack) ? (
        <Button
          className={returnCss.modalButton}
          disabled={approveLoad.value || !!input.allErrors.length}
          onClick={() => {
            setShowShippingScreen(true);
            setMultipleLabels(undefined);
          }}
          pending={approveLoad.pending}
          theme={ButtonTheme.PRIMARY}
        >
          Next
        </Button>
      ) : (
        <Button
          className={returnCss.modalButton}
          disabled={approveLoad.value || !!input.allErrors.length}
          onClick={handleSubmit}
          pending={approveLoad.pending}
          theme={ButtonTheme.PRIMARY}
        >
          Yes, approve
        </Button>
      )}
    </>
  );

  if (showShippingScreen && approveLoad.value !== true) {
    return (
      <CreateLabelsModal
        address={address}
        addressOptions={addressOptions}
        approveInfo={{
          customerNotes: note,
          products: getApprovalProductsInfo().filter(
            (product) => product.shipBack,
          ),
          orderTags,
        }}
        onBack={() => {
          setMultipleLabels(undefined);
          setShowShippingScreen(false);
        }}
        onModalClose={() => {
          setMultipleLabels(undefined);
          onClose();
        }}
        onSubmit={(multipleLabels) => {
          setMultipleLabels(multipleLabels);
          doApprove();
        }}
        return_={return_}
        setAddress={setAddress}
        showAddressSelector={showAddressSelection}
        team={team}
      />
    );
  }

  const getInput = (_id: string) =>
    input.inputs.find((inputProduct) => inputProduct.value._id === _id);

  const isPreTransit = orders?.some((order) => {
    return order?.trackers.some((tracker) => {
      return tracker._tracker.status === "pre_transit";
    });
  });

  const showPreTransitWarning =
    isPreTransit &&
    team.settings?.packageProtection?.packageProtectionPlusEnabled &&
    return_.type === ReturnTypeEnum.CLAIM;

  return (
    <Modal
      footer={footer}
      onClose={onClose}
      open={open}
      size={ModalSize.SMALL}
      title={`Approve ${returnTypeName(return_.type)}`}
    >
      <div className={returnCss.modalContent}>
        {approveLoad.value ? (
          <p>The {returnTypeName(return_.type)} has been approved.</p>
        ) : (
          <>
            <div className={returnCss.greenReturnSelectTitle}>
              Items customer must send back before processing:
            </div>
            {return_.products.map((product) => (
              <Flex dir="column" gap="lg" key={product._id}>
                <div className={returnCss.greenReturnSelectContainer}>
                  <FormCheckbox
                    input={getInput(product._id)!.inputs.shipBack}
                  />
                  <img
                    className={returnCss.greenReturnImage}
                    src={product.images[0]}
                  />
                  <div className={returnCss.greenReturnText}>
                    {product.product_title}{" "}
                    {product.variant_title !== "Default Title" &&
                      product.variant_title}
                  </div>
                </div>
                {product.grams >= MAX_WEIGHT &&
                  getInput(product._id)!.value.shipBack && (
                    <Flex align="flex-end">
                      <FormTextInput
                        input={getInput(product._id)!.inputs.weight}
                        label={
                          <Flex align="center">
                            Product weight{" "}
                            <Tooltip
                              arrow
                              placement="top"
                              title={
                                <span>
                                  Please ensure your product weight is correct.
                                  We may not be able to generate a label if the
                                  weight is too high
                                </span>
                              }
                            >
                              <IconButton size={ButtonSize.SMALL}>
                                <HelpCircleIcon height="18px" />
                              </IconButton>
                            </Tooltip>
                          </Flex>
                        }
                        min={0}
                        type="number"
                      />
                      <FormSelectDropdown
                        input={getInput(product._id)!.inputs.weightUnit}
                        label=""
                        options={[...Object.values(WeightUnit)]}
                      >
                        {(option) => <>{option}</>}
                      </FormSelectDropdown>
                    </Flex>
                  )}
              </Flex>
            ))}
            {showAddressSelection && !hasMultipleLabelsEnabled && (
              <div>
                <div className={returnCss.greenReturnSelectTitle}>
                  Return Location:
                </div>
                <SelectDropdown
                  className=""
                  options={addressOptions}
                  value={address}
                  valueChange={(value) => {
                    setAddress(value);
                  }}
                >
                  {(option) => (
                    <div className="analyticsCss.dropdownOption">
                      {option.name}
                    </div>
                  )}
                </SelectDropdown>
              </div>
            )}
            <TextInput
              lines={InputLines.MULTI}
              onChange={setNote}
              placeholder="Note to customer"
              value={note}
            />
            {return_.type === ReturnTypeEnum.CLAIM && (
              <LabeledInput
                description="Add tags to the original order"
                label="Order Tags"
              >
                <ChipInput value={orderTags} valueChange={setOrderTags} />
              </LabeledInput>
            )}
            {showPreTransitWarning && (
              <Text fontSize="sm">
                NOTE: This order is pre-transit. Please ensure the package is
                shipped before approving claim.
              </Text>
            )}
          </>
        )}
      </div>
    </Modal>
  );
});
