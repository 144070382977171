import { useHover } from "@redotech/react-util/hover";
import { formatTimeAgo } from "@redotech/redo-web/date-utils";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo, ReactNode, useState } from "react";
import * as customerActivityCardCss from "./activity-card.module.css";

export const BaseActivityCard = memo(function BaseActivityCard({
  topLeftElement,
  title,
  timestamp,
  extraDetailsElement,
  onClick,
}: {
  topLeftElement: ReactNode;
  title?: string;
  timestamp: Date;
  extraDetailsElement?: ReactNode | ReactNode[] | undefined;
  onClick: (() => void) | undefined;
}) {
  const [elementRef, setElementRef] = useState<HTMLElement | null>(null);
  const isHovered = useHover(elementRef);

  return (
    <Flex
      className={`${customerActivityCardCss.baseCustomerActivityCardContainer} ${
        isHovered
          ? customerActivityCardCss.baseCustomerActivityCardContainerHovered
          : undefined
      }`}
      dir="column"
      gap="none"
      justify="flex-start"
      onClick={onClick}
      p="sm"
      ref={setElementRef}
    >
      <Flex align="flex-start" justify="flex-start" pb="md">
        <Flex>{topLeftElement}</Flex>
        <Flex
          align="flex-start"
          dir="column"
          justify="space-between"
          overflow="hidden"
        >
          {title && (
            <Text fontSize="xs" textColor="primary" textOverflow="clip">
              {title}
            </Text>
          )}
          {extraDetailsElement}
        </Flex>
      </Flex>
      <Text fontSize="xs" mt="sm" textColor="tertiary">
        {formatTimeAgo(timestamp.toISOString())}
      </Text>
    </Flex>
  );
});
