// extracted by mini-css-extract-plugin
export var bubble = "_44-0";
export var container = "_44-3";
export var contrastBorder = "_44-4";
export var greyOut = "_44-2";
export var initialsWrapper = "_44-1";
export var large = "_44-9";
export var medium = "_44-8";
export var mediumSmall = "_44-7";
export var micro = "_44-5";
export var small = "_44-6";
export var tiny = "_44-b";
export var xTiny = "_44-a";