import { memo } from "react";
import { CalendarView } from "./components/calendar-view";

export type DatePickerVariant = "single" | "range";

interface BaseDatePickerProps {
  variant?: DatePickerVariant;
  minDate?: Temporal.PlainDate;
  maxDate?: Temporal.PlainDate;
  onCancel?: () => void;
  onApply?: () => void;
  allowNoDateOption?: boolean;
}

interface SingleDatePickerProps extends BaseDatePickerProps {
  selectedDate: Temporal.PlainDate | null;
  onDateChange: (date: Temporal.PlainDate | null) => void;
  // Range props should be undefined
  startDate?: undefined;
  endDate?: undefined;
  onRangeChange?: undefined;
}

interface RangeDatePickerProps extends BaseDatePickerProps {
  startDate: Temporal.PlainDate | null;
  endDate: Temporal.PlainDate | null;
  onRangeChange: (
    startDate: Temporal.PlainDate | null,
    endDate: Temporal.PlainDate | null,
  ) => void;
  // Single props should be undefined
  selectedDate?: undefined;
  onDateChange?: undefined;
}

// Union type for the component props
export type CustomDatePickerProps =
  | SingleDatePickerProps
  | RangeDatePickerProps;

export const DateRangePicker = memo(function DateRangePicker(
  props: RangeDatePickerProps,
) {
  return (
    <CalendarView
      allowNoDateOption={props.allowNoDateOption}
      endDate={props.endDate}
      maxDate={props.maxDate}
      minDate={props.minDate}
      onApply={props.onApply}
      onCancel={props.onCancel}
      onRangeChange={props.onRangeChange}
      startDate={props.startDate}
      variant="range"
    />
  );
});

export const DatePicker = memo(function DatePicker(
  props: SingleDatePickerProps,
) {
  return (
    <CalendarView
      allowNoDateOption={props.allowNoDateOption}
      maxDate={props.maxDate}
      minDate={props.minDate}
      onApply={props.onApply}
      onCancel={props.onCancel}
      onDateSelect={props.onDateChange}
      selectedDate={props.selectedDate}
      variant="single"
    />
  );
});
