import { useRequiredContext } from "@redotech/react-util/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { RedoSecondaryNavItem } from "@redotech/redo-web/arbiter-components/navigation/secondary/redo-secondary-nav-item";
import AnnouncementIcon3 from "@redotech/redo-web/arbiter-icon/announcement-03.svg";
import AnnouncementIcon3Filled from "@redotech/redo-web/arbiter-icon/announcement-03_filled.svg";
import { memo } from "react";
import { useMatch } from "react-router-dom";

export const EmailSMSItems = memo(function MarketingItems({
  marketingRoot,
}: {
  marketingRoot: string;
}) {
  const team = useRequiredContext(TeamContext);
  const settings = team.settings;

  const emailSmsRoot = `${marketingRoot}/email-sms`;

  const marketingEnabled = settings.marketing?.enabled;

  const showCampaigns =
    marketingEnabled && settings?.marketing?.campaigns?.enabled;

  const anyChildSelected = useMatch(`${emailSmsRoot}/*`);

  return (
    <RedoSecondaryNavItem
      action={{ type: "link", href: `${emailSmsRoot}/audience` }}
      anyChildSelected={!!anyChildSelected}
      FilledIcon={AnnouncementIcon3Filled}
      friendlyName="Email & SMS"
      id="email-sms"
      UnfilledIcon={AnnouncementIcon3}
    >
      <RedoSecondaryNavItem
        action={{ type: "link", href: `${emailSmsRoot}/audience` }}
        friendlyName="Audience"
        id="audience"
      />
      {showCampaigns && (
        <>
          <RedoSecondaryNavItem
            action={{ type: "link", href: `${emailSmsRoot}/campaigns` }}
            friendlyName="Campaigns"
            id="campaigns"
          />
          <RedoSecondaryNavItem
            action={{ type: "link", href: `${emailSmsRoot}/segments` }}
            friendlyName="Segments"
            id="segments"
          />
        </>
      )}
      <RedoSecondaryNavItem
        action={{ type: "link", href: `${emailSmsRoot}/templates/email` }}
        friendlyName="Email templates"
        id="email-templates"
      />
      <RedoSecondaryNavItem
        action={{ type: "link", href: `${emailSmsRoot}/templates/sms` }}
        friendlyName="SMS templates"
        id="sms-templates"
      />
      {marketingEnabled && (
        <RedoSecondaryNavItem
          action={{ type: "link", href: `${emailSmsRoot}/signups` }}
          friendlyName="Sign-ups"
          id="signups"
        />
      )}
    </RedoSecondaryNavItem>
  );
});
