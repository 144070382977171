import {
  ArrayStringFilterData,
  ArrayStringFilterOperator,
} from "@redotech/redo-model/views/advanced-filters/array-string-filter";
import { BooleanFilterOperator } from "@redotech/redo-model/views/advanced-filters/boolean-filter";
import {
  DateFilterData,
  DateFilterOperator,
  KnownDateFilterTimeFrame,
} from "@redotech/redo-model/views/advanced-filters/date-filter";
import { ExistsFilterOperator } from "@redotech/redo-model/views/advanced-filters/exists-filter";
import {
  AdvancedFilterType,
  GenericAdvancedFilterData,
} from "@redotech/redo-model/views/advanced-filters/generic-advanced-filter-data";
import {
  NumberFilterData,
  NumberFilterOperator,
  NumberValue,
} from "@redotech/redo-model/views/advanced-filters/number-filter";
import { StringFilterOperator } from "@redotech/redo-model/views/advanced-filters/string-filter";
import { getFilterBuilder } from "@redotech/redo-model/views/utils/util";
import { deepCopyObject } from "@redotech/util/object";
import { JSXElementConstructor } from "react";
import { RedoListItem } from "../arbiter-components/list/redo-list";

export interface GenericAdvancedTableFilter<VALUE, OPERATOR extends string> {
  type: AdvancedFilterType;
  data: GenericAdvancedFilterData<VALUE, OPERATOR>;
  operators?: OPERATOR[];
  Icon: JSXElementConstructor<any>;
  openOnRender?: boolean;
  displayName: string;
}

export interface ArrayStringTableFilter<T = string>
  extends GenericAdvancedTableFilter<string[], ArrayStringFilterOperator> {
  data: ArrayStringFilterData;
  type: AdvancedFilterType.ARRAY_TO_STRING;
  itemLabel: string;
  itemLabelPlural?: string;
  valueFetcher?: (
    {
      filters,
      filterSearch,
      applyTableState,
    }: {
      filters: AdvancedTableFilter[];
      filterSearch?: string;
      applyTableState?: boolean;
    },
    signal: AbortSignal,
  ) => Promise<T[]>;
  itemGenerator?: (item: T) => RedoListItem<T>;
  stringify?: (item: T) => string;
  display?: (item: T) => string;
  values?: T[];
  dbSearch?: boolean;
  applyTableState?: boolean;
}

export interface DateTableFilter
  extends GenericAdvancedTableFilter<
    KnownDateFilterTimeFrame,
    DateFilterOperator
  > {
  type: AdvancedFilterType.DATE;
  data: DateFilterData;
  values?: KnownDateFilterTimeFrame[];
}

export interface NumberTableFilter
  extends GenericAdvancedTableFilter<NumberValue, NumberFilterOperator> {
  type: AdvancedFilterType.NUMBER;
  data: NumberFilterData;
  min?: number;
  max?: number;
  step?: number;
  precision?: number;
  prefix?: string;
  suffix?: string;
  modifier?: number;
  secondField?: {
    scalar?: number;
    min?: number;
    max?: number;
    step?: number;
    prefix?: string;
    suffix?: string;
  };
  values?: number[];
}

/**
 * @deprecated Use ArrayStringTableFilter instead.
 * This interface is maintained only for database migration purposes.
 * All new filter implementations should use ArrayStringTableFilter.
 */
export interface StringTableFilter<T = string>
  extends GenericAdvancedTableFilter<string, StringFilterOperator> {
  type: AdvancedFilterType.STRING;
  data: GenericAdvancedFilterData<string, StringFilterOperator>;
  valueFetcher?: (
    { filters }: { filters: AdvancedTableFilter[] },
    signal: AbortSignal,
  ) => Promise<T[]>;
  itemGenerator?: (item: T) => RedoListItem<T>;
  stringify?: (item: T) => string; // value string
  display?: (value: T) => string; // display string
  values?: T[];
}

export interface ExistsTableFilter<T = boolean>
  extends GenericAdvancedTableFilter<boolean, ExistsFilterOperator> {
  type: AdvancedFilterType.EXISTS;
  data: GenericAdvancedFilterData<boolean, ExistsFilterOperator>;
  boolDisplay?: { falseDisplay: string; trueDisplay: string };
  label?: string;
}

export interface BooleanTableFilter
  extends GenericAdvancedTableFilter<boolean, BooleanFilterOperator> {
  type: AdvancedFilterType.BOOLEAN;
  data: GenericAdvancedFilterData<boolean, BooleanFilterOperator>;
  boolDisplay?: { falseDisplay: string; trueDisplay: string };
  label?: string;
}

export type AdvancedTableFilter =
  | ArrayStringTableFilter<any>
  | DateTableFilter
  | NumberTableFilter
  | StringTableFilter<any>
  | ExistsTableFilter
  | BooleanTableFilter;

export function encodeAdvancedFilters(filters: AdvancedTableFilter[]): string {
  let query = "";
  let first = true;
  for (const filter of filters) {
    const filterBuilder = getFilterBuilder(filter.data);
    if (!first) {
      query += ";;";
    }
    query += filter.data.name;
    query += "@@";
    query += filterBuilder.writeToString(filter.data);
    first = false;
  }
  return query;
}

export function decodeAdvancedFilters(
  encoded: string,
  advancedFilters: AdvancedTableFilter[],
): AdvancedTableFilter[] {
  if (!encoded) {
    return [];
  }
  const filtersStrings = encoded.split(";;");
  return filtersStrings
    .map((filterString) => {
      const [name, dataString] = filterString.split("@@");
      const filter = advancedFilters.find((f) => f.data.name === name);
      if (!filter) {
        return null;
      }
      const deepClone = deepCopyObject(filter);
      const filterBuilder = getFilterBuilder(deepClone.data);
      return {
        ...deepClone,
        data: filterBuilder.readFromString(name, dataString, deepClone.data),
      };
    })
    .filter((f) => f !== null) as AdvancedTableFilter[];
}
