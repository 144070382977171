import * as amplitude from "@amplitude/analytics-browser";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { ThemeProvider } from "@mui/material";
import { composeWrappers } from "@redotech/react-util/component";
import { HidProvider } from "@redotech/react-util/hid";
import { RedoClient } from "@redotech/redo-api-client";
import {
  AuthContext,
  AuthProvider,
} from "@redotech/redo-merchant-app-common/auth";
import { RedoMerchantClientProvider } from "@redotech/redo-merchant-app-common/client/context";
import {
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  REDO_API_URL,
  REDO_MERCHANT_APP_VERSION,
  REDO_MERCHANT_SERVER_URL,
} from "@redotech/redo-merchant-app-common/config";
import { ConfirmationProvider } from "@redotech/redo-merchant-app-common/confirmation-context";
import { MerchantAppEventServerProvider } from "@redotech/redo-merchant-app-common/events/merchant-app-event-server-provider";
import { RedoConversionRpcClientProvider } from "@redotech/redo-merchant-app-common/redo-conversion-rpc-client-provider";
import { RedoMerchantRpcClientProvider } from "@redotech/redo-merchant-app-common/rpc-client";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { UserContext } from "@redotech/redo-merchant-app-common/user";
import { RedoOutboundLabelsRpcClientProvider } from "@redotech/redo-merchant-app-fulfillment/outbound-labels-rpc-client";
import { OutboundViewsProvider } from "@redotech/redo-merchant-app-fulfillment/outbound-views";
import { RedoReviewsRpcClientProvider } from "@redotech/redo-merchant-app-review/rpc-client";
import { SettlementContextProvider } from "@redotech/redo-return-app/contexts/SettlementContext";
import { RedoClientContext } from "@redotech/redo-web/client";
import { MUI_THEME } from "@redotech/redo-web/mui-theme";
import { CustomSnackbarProvider } from "@redotech/redo-web/snackbar";
import { ThemeProvider as RedoThemeProvider } from "@redotech/redo-web/theme-provider";
import { StripeProvider } from "@redotech/stripe-react";
import { createRoot } from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";
import { NotificationProvider } from "../../web/src/notification-provider";
import { AlertContextProvider } from "./app/alert";
import { RedoConciergeRpcClientProvider } from "./app/redo-concierge-rpc-client-provider";
import { RedoMarketingRpcClientProvider } from "./app/redo-marketing-rpc-client-provider";
import { TeamProvider } from "./app/team";
import { ConciergeOnboardingAnalyticsProvider } from "./concierge/context/concierge-onboarding-analytics-provider";
import { routes } from "./route";
import "./styles.css";

// Initialize exchange rates for currency conversion - necessary to use Money objects
// TODO @JstnMcBrd Change the passed-in method to only call getExchangeRates() if it's been over a certain amount of time,
// and otherwise pull from localStorage. Also need to find a way to save the exchange rates to localStorage.
// TODO @JstnMcBrd Re-enable once Money objects are ready
// import { ExchangeRatesManager } from "@redotech/money/exchange-rates";
// import { getExchangeRates } from "./api";
// void ExchangeRatesManager.initialize(getExchangeRates);

if (process.env.NODE_ENV !== "development") {
  if (DATADOG_CLIENT_TOKEN && DATADOG_APPLICATION_ID) {
    datadogRum.init({
      applicationId: DATADOG_APPLICATION_ID,
      clientToken: DATADOG_CLIENT_TOKEN,
      site: "datadoghq.com",
      service: "merchant-app",
      env: process.env.NODE_ENV || "production",
      version: REDO_MERCHANT_APP_VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 1,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
    });
    datadogLogs.init({
      clientToken: DATADOG_CLIENT_TOKEN,
      site: "datadoghq.com",
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      service: "merchant-app",
      version: REDO_MERCHANT_APP_VERSION,
    });
  }
  amplitude.init(process.env.AMPLITUDE_API_KEY!, { defaultTracking: true });
}

const router = createBrowserRouter(routes);

const MerchantApp = () => {
  return composeWrappers(
    (inner) => <HidProvider>{inner}</HidProvider>,
    (inner) => <ThemeProvider theme={MUI_THEME}>{inner}</ThemeProvider>,
    (inner) => (
      <StripeProvider publicKey={process.env.STRIPE_PUBLIC_KEY!}>
        {inner}
      </StripeProvider>
    ),
    (inner) => (
      <IntercomProvider
        appId={process.env.INTERCOM_APP_ID || ""}
        autoBoot
        shouldInitialize={!!process.env.INTERCOM_APP_ID}
      >
        {inner}
      </IntercomProvider>
    ),
    (inner) => <NotificationProvider>{inner}</NotificationProvider>,
    (inner) => <CustomSnackbarProvider>{inner}</CustomSnackbarProvider>,
    (inner) => <AuthProvider>{inner}</AuthProvider>,
    (inner) => (
      <AuthContext.Consumer>
        {(auth) => (
          <RedoClientContext.Provider
            value={new RedoClient(REDO_API_URL, auth?.token)}
          >
            {inner}
          </RedoClientContext.Provider>
        )}
      </AuthContext.Consumer>
    ),
    (inner) => (
      <AuthContext.Consumer>
        {(auth) => (
          <RedoMerchantClientProvider
            token={auth?.token}
            url={REDO_MERCHANT_SERVER_URL}
          >
            {inner}
          </RedoMerchantClientProvider>
        )}
      </AuthContext.Consumer>
    ),
    (inner) => (
      <AuthContext.Consumer>
        {(auth) => (
          <RedoConciergeRpcClientProvider
            authToken={auth?.token}
            baseUrl={REDO_MERCHANT_SERVER_URL}
          >
            {inner}
          </RedoConciergeRpcClientProvider>
        )}
      </AuthContext.Consumer>
    ),
    (inner) => (
      <AuthContext.Consumer>
        {(auth) => (
          <RedoConversionRpcClientProvider
            authToken={auth?.token}
            baseUrl={REDO_MERCHANT_SERVER_URL}
          >
            {inner}
          </RedoConversionRpcClientProvider>
        )}
      </AuthContext.Consumer>
    ),
    (inner) => (
      <AuthContext.Consumer>
        {(auth) => (
          <RedoMarketingRpcClientProvider
            authToken={auth?.token}
            baseUrl={REDO_MERCHANT_SERVER_URL}
          >
            {inner}
          </RedoMarketingRpcClientProvider>
        )}
      </AuthContext.Consumer>
    ),
    (inner) => (
      <AuthContext.Consumer>
        {(auth) => (
          <RedoOutboundLabelsRpcClientProvider
            authToken={auth?.token}
            baseUrl={REDO_MERCHANT_SERVER_URL}
          >
            {inner}
          </RedoOutboundLabelsRpcClientProvider>
        )}
      </AuthContext.Consumer>
    ),
    (inner) => (
      <AuthContext.Consumer>
        {(auth) => (
          <RedoReviewsRpcClientProvider
            authToken={auth?.token}
            baseUrl={REDO_MERCHANT_SERVER_URL}
          >
            {inner}
          </RedoReviewsRpcClientProvider>
        )}
      </AuthContext.Consumer>
    ),
    (inner) => (
      <AuthContext.Consumer>
        {(auth) => (
          <RedoMerchantRpcClientProvider
            authToken={auth?.token}
            baseUrl={REDO_MERCHANT_SERVER_URL}
          >
            {inner}
          </RedoMerchantRpcClientProvider>
        )}
      </AuthContext.Consumer>
    ),
    (inner) => <TeamProvider>{inner}</TeamProvider>,
    (inner) => <OutboundViewsProvider>{inner}</OutboundViewsProvider>,
    (inner) => (
      <MerchantAppEventServerProvider>{inner}</MerchantAppEventServerProvider>
    ),
    (inner) => <SettlementContextProvider>{inner}</SettlementContextProvider>,
    (inner) => <RedoThemeProvider>{inner}</RedoThemeProvider>,
    (inner) => <AlertContextProvider>{inner}</AlertContextProvider>,
    (inner) => <ConfirmationProvider>{inner}</ConfirmationProvider>,
    (inner) => (
      <TeamContext.Consumer>
        {(team) => (
          <UserContext.Consumer>
            {(user) => (
              <ConciergeOnboardingAnalyticsProvider team={team} user={user}>
                {inner}
              </ConciergeOnboardingAnalyticsProvider>
            )}
          </UserContext.Consumer>
        )}
      </TeamContext.Consumer>
    ),
  )(<RouterProvider router={router} />);
};

const root = createRoot(document.getElementById("root")!);
root.render(<MerchantApp />);
