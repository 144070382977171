import { NonIndexRouteObject } from "react-router-dom";

export const checkoutOptimizationRoutes: NonIndexRouteObject = {
  handle: { breadcrumb: "Checkout optimization", breadcrumbNavigate: false },
  path: "checkout-optimization",
  children: [
    {
      path: "delivery-estimate",
      handle: { breadcrumb: "Delivery estimate" },
      lazy: async () => {
        const { DeliveryEstimateSettingsPage } = await import(
          "./delivery-estimate"
        );
        return { Component: DeliveryEstimateSettingsPage };
      },
    },
  ],
};
