import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { useTriggerLoad } from "@redotech/react-util/load";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { getUserAvatarUrl } from "@redotech/redo-merchant-app-common/get-avatar-url";
import { RedoMerchantRpcClientContext } from "@redotech/redo-merchant-app-common/rpc-client";
import { PaymentIntentItem } from "@redotech/redo-model/payment-intent";
import { MerchantAppReturn } from "@redotech/redo-model/return";
import { TimelineEvent } from "@redotech/redo-model/timeline";
import { Trackable } from "@redotech/redo-model/trackable";
import { GetUser } from "@redotech/redo-model/user";
import { alertOnFailure, toast } from "@redotech/redo-web/alert";
import { Avatar } from "@redotech/redo-web/arbiter-components/avatars/avatar";
import CurrencyIcon from "@redotech/redo-web/arbiter-icon/currency-dollar-circle.svg";
import EmailIcon from "@redotech/redo-web/arbiter-icon/mail-01.svg";
import TrashIcon from "@redotech/redo-web/arbiter-icon/trash-03.svg";
import { CURRENCY_FORMAT, CurrencyContext } from "@redotech/redo-web/currency";
import { getDateTimeString } from "@redotech/redo-web/date-utils";
import { Flex } from "@redotech/redo-web/flex";
import { ExternalLink } from "@redotech/redo-web/link";
import { Text } from "@redotech/redo-web/text";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import { UserImageSize } from "@redotech/redo-web/user-image";
import { memo, useContext, useMemo, useState } from "react";
import { returnCommentDelete } from "../../client/comment";
import { orderStatusName } from "../util";

import * as redoBlack from "./redo-black.png?url";

import { RedoMerchantClient } from "@redotech/redo-merchant-app-common/client";
import { RedoBadge } from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoCommandMenu } from "@redotech/redo-web/arbiter-components/command-menu/redo-command-menu";
import { RedoModal } from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import HorizontalDots from "@redotech/redo-web/arbiter-icon/dots-horizontal_filled.svg";
import InfoCircleIcon from "@redotech/redo-web/arbiter-icon/info-circle.svg";
import { ExpandableImage } from "@redotech/redo-web/expandable-image";
import { useParams } from "react-router-dom";
import { getReturn } from "../../client/return";
import { EmailEventActionDropdown } from "../../order/activity-timeline";
import { ActivityCardInput } from "./activity-card-input";
import * as activityCss from "./activity-card.module.css";

const IconContainerSize = ["xs", "sm", "md", "lg", "xl"] as const;
export type IconContainerSize = (typeof IconContainerSize)[number];

const IconContainer = memo(function IconContainer({
  IconSvg,
  size = "md",
  color = "gray",
}: {
  IconSvg: React.JSXElementConstructor<any>;
  size: IconContainerSize;
  color: string;
}) {
  return (
    <Flex
      style={{
        width: sizeToPx[size],
        height: sizeToPx[size],
        color,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ width: "100%", height: "100%", position: "relative" }}>
        <IconSvg
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            height: "100%",
          }}
        />
      </div>
    </Flex>
  );
});
const sizeToPx: Record<IconContainerSize, number> = {
  xs: 12,
  sm: 16,
  md: 20,
  lg: 24,
  xl: 28,
};

type ActivityItemProps = {
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
};

export const ActivityItem = ({
  leftContent,
  rightContent,
}: ActivityItemProps) => (
  <Flex dir="row" gap="lg">
    <Flex className={activityCss.leftContent} dir="column" gap="xs">
      {leftContent}
      <div className={activityCss.timelineLine} />
    </Flex>
    <Flex className={activityCss.rightContent} dir="column" gap="lg">
      {rightContent}
    </Flex>
  </Flex>
);

export type ActivityItem = {
  type: "comment" | "transaction" | "email" | "oms-event";
  time: Date;
  content: React.ReactNode;
};

export const ActivityCard = memo(function ActivityCard({
  initialReturn,
  trackable,
  emailTemplateIdToNameMap,
}: {
  initialReturn: MerchantAppReturn;
  trackable: Trackable;
  emailTemplateIdToNameMap?: Map<string, string>;
}) {
  const params = useParams();
  const client = useRequiredContext(RedoMerchantClientContext);
  const { formatCurrency } = useContext(CurrencyContext);

  const [return_, setReturn] = useState<MerchantAppReturn | null>(
    initialReturn,
  );

  async function reloadReturn() {
    if (!params.returnId) return;
    setReturn(await getReturn(client, { id: params.returnId }));
  }

  const activities = useMemo(
    () =>
      getReturnActivityItems({
        return_,
        client,
        reloadReturn,
        formatCurrency,
        trackable,
        emailTemplateIdToNameMap,
      }),
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [return_, trackable, emailTemplateIdToNameMap],
  );

  return (
    <Flex dir="column" gap="xl">
      <Text fontSize="md" fontWeight="semibold">
        Activity
      </Text>
      <ActivityCardInput
        client={client}
        params={params}
        reloadReturn={reloadReturn}
      />
      <div className={activityCss.timelineContainer}>
        {activities.map((activity, index) => (
          <Flex
            className={activityCss.timelineItem}
            flexDirection="column"
            gap="lg"
            key={index}
          >
            {activity.content}
          </Flex>
        ))}
      </div>
    </Flex>
  );
});

export const getReturnActivityItems = ({
  return_,
  client,
  reloadReturn,
  formatCurrency,
  trackable,
  emailTemplateIdToNameMap,
  extraLabel,
}: {
  return_: MerchantAppReturn | null;
  client: RedoMerchantClient;
  reloadReturn: () => Promise<void>;
  formatCurrency: (amount: number) => string;
  trackable: Trackable;
  emailTemplateIdToNameMap?: Map<string, string>;
  extraLabel?: string;
}) => {
  const items: ActivityItem[] = [];

  // Add comments
  return_?.timeline
    .filter((event) => !event.isShipment && !event.customer)
    .forEach((event) => {
      items.push({
        type: "comment",
        time: new Date(event.updatedAt),
        content: (
          <CommentItem
            client={client as RedoMerchantClient}
            event={event}
            extraLabel={extraLabel}
            imageUrls={event.imageUrls}
            reload={reloadReturn}
            return_={return_}
          />
        ),
      });
    });

  // Add refund transactions
  return_?.refunds?.forEach((refund) => {
    refund.refund?.transactions?.forEach((transaction: any, j: number) => {
      items.push({
        type: "transaction",
        time: new Date(transaction.created_at),
        content: (
          <OtherTransactionItem
            extraLabel={extraLabel}
            paymentIntent={{
              type: "refund",
              created_at: transaction.created_at,
              amount: CURRENCY_FORMAT(transaction.currency).format(
                transaction.amount,
              ),
            }}
          />
        ),
      });
    });
  });

  // Add stored credit transactions
  return_?.storedCredits?.forEach((discountCode) => {
    items.push({
      type: "transaction",
      time: new Date(discountCode.createdAt),
      content: (
        <OtherTransactionItem
          extraLabel={extraLabel}
          paymentIntent={{
            type: "storedCredit",
            created_at: discountCode.createdAt,
            amount: formatCurrency(discountCode.value ?? 0),
            code: discountCode.code,
          }}
        />
      ),
    });
  });

  // Add gift card transactions
  return_?.giftCards?.forEach((giftCard) => {
    items.push({
      type: "transaction",
      time: new Date(giftCard.createdAt),
      content: (
        <OtherTransactionItem
          extraLabel={extraLabel}
          paymentIntent={{
            type: "giftCard",
            created_at: giftCard.createdAt,
            amount: formatCurrency(giftCard.value || 0),
            code: giftCard.code,
            giftCardId: giftCard.giftCardId,
            url:
              giftCard.giftCardId !== undefined
                ? `https://${return_.team.storeUrl}/admin/gift_cards/${giftCard.giftCardId}`
                : undefined,
          }}
        />
      ),
    });
  });

  // Add payment intent transactions
  (return_?.paymentIntents || [])
    .filter((pi) => pi.payment_intent)
    .forEach((paymentIntent) => {
      items.push({
        type: "transaction",
        time: new Date(paymentIntent.payment_intent.created * 1000),
        content: (
          <TransactionItem
            extraLabel={extraLabel}
            formatCurrency={formatCurrency}
            paymentIntent={paymentIntent}
          />
        ),
      });
    });

  // Add email events
  trackable.trackingEmailsSent?.forEach((emailInfo) => {
    items.push({
      type: "email",
      time: new Date(emailInfo.sentAt),
      content: (
        <EmailItem
          extraLabel={extraLabel}
          orderId={trackable.id}
          previewURL={emailInfo.s3URL}
          templateId={emailInfo.emailId}
          templateName={emailTemplateIdToNameMap?.get(emailInfo.emailId)}
          time={new Date(emailInfo.sentAt)}
        />
      ),
    });
  });

  // Add exchange order transactions
  return_?.exchanges?.forEach((order) => {
    items.push({
      type: "transaction",
      time: new Date(order.created_at),
      content: (
        <OtherTransactionItem
          extraLabel={extraLabel}
          paymentIntent={{
            type: "exchange",
            created_at: order.created_at,
            amount: "",
            code: order.name,
            status: order.fulfillment_status,
            url: `https://${return_.team.storeUrl}/admin/orders/${order.id || ""}`,
          }}
        />
      ),
    });
  });

  // Add draft order transactions
  if (return_?.draftOrder) {
    // Add draft order creation
    items.push({
      type: "transaction",
      time: new Date(return_.draftOrder.created_at),
      content: (
        <OtherTransactionItem
          extraLabel={extraLabel}
          paymentIntent={{
            type: "draftOrder",
            created_at: return_.draftOrder.created_at,
            amount: "",
            code: return_.draftOrder.name,
            url: `https://${return_.team.storeUrl}/admin/draft_orders/${return_.draftOrder.id || ""}`,
          }}
        />
      ),
    });

    // Add invoice sent event if it exists
    if (return_.draftOrder.invoice_sent_at) {
      items.push({
        type: "transaction",
        time: new Date(return_.draftOrder.invoice_sent_at),
        content: (
          <OtherTransactionItem
            extraLabel={extraLabel}
            paymentIntent={{
              type: "draftOrderInvoice",
              created_at: return_.draftOrder.invoice_sent_at,
              amount: "",
            }}
          />
        ),
      });
    }
  }

  // Add deposit refund transaction if applicable
  if (return_?.totals.depositRefunded) {
    // Place it right after the exchange order creation time
    const exchangeTime = return_?.exchanges?.[0]?.created_at;
    items.push({
      type: "transaction",
      // Use exchange time + 1 second, or current time if no exchange time exists
      time: exchangeTime
        ? new Date(new Date(exchangeTime).getTime() + 1000)
        : new Date(),
      content: (
        <OtherTransactionItem
          extraLabel={extraLabel}
          paymentIntent={{
            type: "depositRefund",
            created_at: exchangeTime || new Date().toISOString(),
            amount: "",
          }}
        />
      ),
    });
  }

  const sortedItems = items.sort((a, b) => b.time.getTime() - a.time.getTime());

  return sortedItems;
};

export const CommentItem = memo(function CommentItem({
  event,
  reload,
  return_,
  client,
  extraLabel,
  imageUrls,
}: {
  event: TimelineEvent;
  reload?: () => Promise<void>;
  return_?: MerchantAppReturn;
  client?: RedoMerchantClient;
  extraLabel?: string;
  imageUrls?: string[];
}) {
  const user = event?.user as GetUser | null;
  const isSystem = event.isSystem;

  const [_deleteLoad, doDelete] = useTriggerLoad(async (signal) => {
    if (!return_ || !client || !reload) {
      return;
    }
    await alertOnFailure("Could not delete comment")(() =>
      returnCommentDelete(client, {
        commentId: event._id!,
        returnId: return_._id,
        signal,
      }),
    );
    await reload();
  });

  if (isSystem) {
    return (
      <ActivityItem
        leftContent={<Avatar alt="Redo" imageUrl={redoBlack.default} />}
        rightContent={
          <Flex dir="column" gap="xs">
            <Text fontSize="xs" fontWeight="thin">
              {extraLabel ? `${extraLabel}: ` : ""}
              {event.message || event.link?.message}
            </Text>
            <Text fontSize="xxs" textColor="secondary">
              {getDateTimeString(new Date(event.updatedAt))}
            </Text>
          </Flex>
        }
      />
    );
  }

  return (
    <ActivityItem
      leftContent={
        <Avatar
          alt="profile picture"
          imageSize={UserImageSize.SMALL}
          imageUrl={getUserAvatarUrl({
            email: user?.email || "",
            size: 32,
            userId: user?._id,
          })}
          name={user?.name ?? ""}
        />
      }
      rightContent={
        <Flex dir="column" gap="xs">
          <Flex dir="row" gap="none">
            <Text as="span" fontSize="xs" fontWeight="regular">
              {extraLabel ? `${extraLabel}: ` : ""}
              {user?.name ?? ""}
            </Text>
            <Text as="span" fontSize="xs" fontWeight="thin">
              {" sent a message"}
            </Text>
          </Flex>
          <Text fontSize="xxs" textColor="secondary">
            {getDateTimeString(new Date(event.updatedAt))}
          </Text>
          <Flex className={activityCss.messageBox} justify="space-between">
            <Flex dir="column" gap="sm">
              <Flex dir="row" gap="sm" wrap="wrap">
                {imageUrls?.map((imageUrl) => (
                  <div className={activityCss.commentImage} key={imageUrl}>
                    <ExpandableImage src={imageUrl} />
                  </div>
                ))}
              </Flex>
              {event.message && event.message !== "" && (
                <Text fontSize="xs" fontWeight="thin">
                  {event.message}
                </Text>
              )}
            </Flex>
            {!event.isSystem &&
              !event.customer &&
              !!return_ &&
              !!client &&
              !!reload && (
                <div className={activityCss.deleteButton}>
                  <RedoButton
                    IconLeading={TrashIcon}
                    onClick={() => doDelete()}
                    pending={_deleteLoad.pending}
                    size="sm"
                    theme="destructive"
                  />
                </div>
              )}
          </Flex>
        </Flex>
      }
    />
  );
});

type OtherTransactionData = {
  type:
    | "refund"
    | "storedCredit"
    | "giftCard"
    | "exchange"
    | "draftOrder"
    | "draftOrderInvoice"
    | "depositRefund";
  created_at: string;
  amount: string;
  code?: string;
  status?: string;
  url?: string;
  giftCardId?: string;
};

const OtherTransactionItem = memo(function TransactionItem({
  paymentIntent,
  extraLabel,
}: {
  paymentIntent: OtherTransactionData;
  extraLabel?: string;
}) {
  const params = useParams();
  const returnId = params.returnId || "";

  const getTransactionText = () => {
    switch (paymentIntent.type) {
      case "refund":
        return " to the original payment method";
      case "storedCredit":
        return ` store credit issued${paymentIntent.code ? ` (${paymentIntent.code})` : ""}`;
      case "giftCard":
        return (
          <>
            {" gift card issued"}
            {paymentIntent.code && paymentIntent.url ? (
              <>
                {" "}
                (
                <ExternalLink
                  className={activityCss.externalLink}
                  showIcon={false}
                  url={paymentIntent.url}
                >
                  {paymentIntent.code}
                </ExternalLink>
                )
              </>
            ) : paymentIntent.code ? (
              ` (${paymentIntent.code})`
            ) : (
              ""
            )}
          </>
        );
      case "exchange":
        return (
          <Flex align="center" dir="row" gap="xs">
            Exchange order{" "}
            <ExternalLink
              className={activityCss.externalLink}
              showIcon={false}
              url={paymentIntent.url || ""}
            >
              {paymentIntent.code}
            </ExternalLink>
            {paymentIntent.status && (
              <RedoBadge
                size="xs"
                text={`${orderStatusName(paymentIntent.status)}`}
              />
            )}
          </Flex>
        );
      case "draftOrder":
        return (
          <>
            Draft order{" "}
            <ExternalLink
              className={activityCss.externalLink}
              showIcon={false}
              url={paymentIntent.url || ""}
            >
              {paymentIntent.code}
            </ExternalLink>
          </>
        );
      case "draftOrderInvoice":
        return "Invoice sent to customer";
      case "depositRefund":
        return "Instant exchange deposit refunded";
      default:
        return "Transaction";
    }
  };

  const showActionDropdown =
    paymentIntent.type === "giftCard" && !!paymentIntent.giftCardId;

  return (
    <ActivityItem
      leftContent={
        <div className={activityCss.dotContainer}>
          <CurrencyIcon className={activityCss.icon} />
        </div>
      }
      rightContent={
        <Flex justify="space-between" w="full">
          <Flex dir="column" gap="xs">
            <Flex align="flex-start" dir="row" gap="xs" wrap="nowrap">
              <Text fontSize="xs" fontWeight="thin">
                {extraLabel ? `${extraLabel}: ` : ""}
                {paymentIntent.amount}
                {getTransactionText()}
              </Text>
              {showActionDropdown && (
                <Tooltip title="For security reasons, Redo does not store gift card codes. You can re-send with the dot menu on the right, or click the code link to manage the gift card in Shopify.">
                  <span>
                    <IconContainer
                      color="gray"
                      IconSvg={InfoCircleIcon}
                      size="sm"
                    />
                  </span>
                </Tooltip>
              )}
            </Flex>
            <Text fontSize="xxs" textColor="secondary">
              {getDateTimeString(new Date(paymentIntent.created_at))}
            </Text>
          </Flex>
          {showActionDropdown && (
            <Flex>
              <GiftCardActionDropdown
                giftCardId={paymentIntent.giftCardId || ""}
                giftCardUrl={paymentIntent.url}
                returnId={returnId}
              />
            </Flex>
          )}
        </Flex>
      }
    />
  );
});

const TransactionItem = memo(function TransactionItem({
  extraLabel,
  paymentIntent,
  formatCurrency,
}: {
  extraLabel?: string;
  paymentIntent: PaymentIntentItem;
  formatCurrency: (amount: number) => string;
}) {
  const getTransactionText = () => {
    switch (paymentIntent.type) {
      case "label":
        return "Return shipping label charge";
      case "recovery":
        return "Recovery fee charge";
      case "hold":
        return "Instant Exchange hold successful";
      default:
        return "Transaction";
    }
  };

  return (
    <ActivityItem
      leftContent={
        <div className={activityCss.dotContainer}>
          <CurrencyIcon className={activityCss.icon} />
        </div>
      }
      rightContent={
        <Flex dir="column" gap="xs">
          <Text fontSize="xs" fontWeight="thin">
            {extraLabel ? `${extraLabel}: ` : ""}
            {getTransactionText()}
            {paymentIntent.payment_intent.amount &&
              paymentIntent.type !== "hold" &&
              `: ${formatCurrency(paymentIntent.payment_intent.amount / 100)}`}
          </Text>
          <Text fontSize="xxs" textColor="secondary">
            {getDateTimeString(
              new Date(paymentIntent.payment_intent.created * 1000),
            )}
          </Text>
        </Flex>
      }
    />
  );
});

export const EmailItem = memo(function EmailItem({
  templateName,
  time,
  extraLabel,
  previewURL,
  templateId,
  orderId,
}: {
  templateName?: string;
  time: Date;
  extraLabel?: string;
  previewURL?: string;
  templateId: string;
  orderId: string;
}) {
  return (
    <ActivityItem
      leftContent={
        <div className={activityCss.dotContainer}>
          <EmailIcon className={activityCss.icon} />
        </div>
      }
      rightContent={
        <Flex justify="space-between" w="full">
          <Flex dir="column" gap="xs">
            <Text fontSize="xs" fontWeight="thin">
              {extraLabel ? `${extraLabel}: ` : ""}
              Email Sent{templateName ? `: ${templateName}` : ""}
            </Text>
            <Text fontSize="xxs" textColor="secondary">
              {getDateTimeString(time)}
            </Text>
          </Flex>
          <Flex>
            <EmailEventActionDropdown
              orderId={orderId}
              previewURL={previewURL}
              templateId={templateId}
            />
          </Flex>
        </Flex>
      }
    />
  );
});

const GiftCardActionDropdown = memo(function GiftCardActionDropdown({
  returnId,
  giftCardId,
  giftCardUrl,
}: {
  returnId: string;
  giftCardId: string;
  giftCardUrl?: string;
}) {
  const rpcClient = useRequiredContext(RedoMerchantRpcClientContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [anchorRef, setAnchorRef] = useState<HTMLElement | null>(null);

  const [resendGiftCardState, doResendGiftCard] = useTriggerLoad(
    async (signal) => {
      const result = await rpcClient.resendGiftCard({ returnId, giftCardId });

      if (!result.success) {
        throw new Error(result.error || "Failed to resend gift card");
      }
      closeModal();
      toast("Gift card notification sent successfully", { variant: "success" });
      return result;
    },
  );

  const openModal = useHandler(() => {
    setIsModalOpen(true);
    setDropdownOpen(false);
  });

  const closeModal = useHandler(() => {
    setIsModalOpen(false);
  });

  if (!giftCardUrl) {
    return null;
  }

  return (
    <>
      <RedoButton
        hierarchy="tertiary"
        IconLeading={HorizontalDots}
        onClick={() => setDropdownOpen(true)}
        ref={setAnchorRef}
        size="sm"
      />

      <RedoCommandMenu
        anchor={anchorRef}
        items={[{ text: "Resend gift card", onClick: openModal }]}
        open={dropdownOpen}
        placement="bottom-end"
        setOpen={setDropdownOpen}
      />

      <RedoModal
        isOpen={isModalOpen}
        modalSize="sm"
        onModalCloseRequested={closeModal}
        primaryButton={{
          text: "Send",
          onClick: doResendGiftCard,
          pending: resendGiftCardState.pending,
        }}
        secondaryButton={{ text: "Cancel", onClick: closeModal }}
        subtitle="This will send an email to the recipient of the gift card. According to Shopify, it may take up to an hour for the email to be delivered."
        title="Resend gift card"
      >
        <Flex dir="column" gap="md">
          <Flex align="flex-start" dir="row" gap="sm">
            <IconContainer color="gray" IconSvg={InfoCircleIcon} size="sm" />

            <Text fontSize="xs" textColor="quaternary">
              Ensure the recipient is properly configured in Shopify. You can
              check the gift card details in{" "}
              <ExternalLink
                className={activityCss.externalLink}
                url={giftCardUrl}
              >
                Shopify admin
              </ExternalLink>
              .
            </Text>
          </Flex>
          {resendGiftCardState.error && (
            <Text fontSize="xs" textColor="error">
              Unable to resend gift card. Check the recipient in Shopify. Error:{" "}
              {resendGiftCardState.error?.message}
            </Text>
          )}
        </Flex>
      </RedoModal>
    </>
  );
});
