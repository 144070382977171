import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import { RedoMerchantClient } from "@redotech/redo-merchant-app-common/client";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { Team } from "@redotech/redo-model/team";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { Checkbox } from "@redotech/redo-web/checkbox";
import { Modal, ModalSize } from "@redotech/redo-web/modal";
import { ProgressBar } from "@redotech/redo-web/progress-bar";
import { memo, useEffect, useState } from "react";
import { bulkUpdateConversationsStatus } from "../../client/conversations";
import { UpdateConversationStateContext } from "../context/update-conversations-context";
import { closeTicket, getNextBatch } from "../utils";
import * as closeTicketModalCss from "./close-ticket-modal.module.css";

export const handleCloseTickets = async ({
  client,
  conversations,
  setActiveConversation,
  team,
  inDetailView,
  cleanupFn,
  removeConversation,
  nextConversationInList,
  prevConversationInList,
}: {
  client: RedoMerchantClient;
  conversations: ExpandedConversation[];
  setActiveConversation: (val: ExpandedConversation | undefined) => void;
  team: Team;
  inDetailView: boolean;
  cleanupFn?: (clearSelected?: boolean) => void;
  removeConversation: (conversation: ExpandedConversation) => void;
  nextConversationInList?: ExpandedConversation;
  prevConversationInList?: ExpandedConversation;
}) => {
  if (conversations.length === 1) {
    await closeTicket(
      client,
      conversations[0],
      setActiveConversation,
      team,
      inDetailView,
      nextConversationInList || prevConversationInList,
    );
    removeConversation(conversations[0]);
    amplitude.logEvent("close-conversation", {
      mode: "single",
      conversationIds: [conversations[0]._id],
      channels: [conversations[0].platform],
    });
  } else if (conversations.length > 1) {
    const conversationIds = conversations
      .filter((conversation) => conversation.status !== "closed")
      .map((conversation) => conversation._id);
    if (conversationIds.length > 0) {
      await bulkUpdateConversationsStatus(client, {
        conversationIds,
        status: "closed",
      });
      const channels = [
        ...new Set(conversations.map((conversation) => conversation.platform)),
      ];
      amplitude.logEvent("close-conversation", {
        mode: "multiple",
        conversationIds,
        channels,
      });
    }
  }
  cleanupFn && cleanupFn(true);
};

export const CloseTicketModal = memo(function CloseTicketModal({
  open,
  setOpen,
  conversations,
  setActiveConversation,
  inDetailView,
  cleanupFn,
  selectAllInfo,
  nextConversationInList,
  prevConversationInList,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  conversations: ExpandedConversation[];
  setActiveConversation: (val: ExpandedConversation | undefined) => void;
  inDetailView: boolean;
  cleanupFn?: (clearSelected?: boolean) => void;
  selectAllInfo?: {
    count: number;
    deselectedConversations: ExpandedConversation[];
    bulkActionIterator: AsyncIterator<{
      conversations: ExpandedConversation[];
    }>;
    setBlockRefresh: (val: boolean) => void;
    closeImmediately: boolean;
  };
  nextConversationInList?: ExpandedConversation;
  prevConversationInList?: ExpandedConversation;
}) {
  const team = useRequiredContext(TeamContext);
  const client = useRequiredContext(RedoMerchantClientContext);
  const { removeConversation } = useRequiredContext(
    UpdateConversationStateContext,
  );
  const [conversationsCanClose, setConversationsCanClose] = useState<
    ExpandedConversation[]
  >(conversations.filter((conversation) => conversation.status !== "closed"));
  const [closingSelectAll, setClosingSelectAll] = useState(false);
  const [numDone, setNumDone] = useState(0);
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);

  useEffect(() => {
    setConversationsCanClose(
      conversations.filter((conversation) => conversation.status !== "closed"),
    );
  }, [conversations]);

  useEffect(() => {
    if (open && selectAllInfo?.closeImmediately) {
      void handleCloseSelectAll();
    }
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAllInfo?.closeImmediately, open]);

  const closeBatch = async (): Promise<{
    batchSize: number;
    done: boolean;
  }> => {
    if (!selectAllInfo) return { batchSize: 0, done: true };
    const { done, value } = await getNextBatch(
      selectAllInfo.bulkActionIterator,
    );
    if (done) {
      return { batchSize: 0, done };
    }
    const conversationIds = value.conversations
      .filter(
        (conversation) =>
          conversation.status !== "closed" &&
          !selectAllInfo.deselectedConversations.some(
            (deselectedConversation) =>
              deselectedConversation._id === conversation._id,
          ),
      )
      .map((conversation) => conversation._id);
    if (conversationIds.length > 0) {
      await bulkUpdateConversationsStatus(client, {
        conversationIds,
        status: "closed",
      });
    }
    return { batchSize: value.conversations.length, done: false };
  };

  // This function synchronously closes each page of conversations.
  // If speed becomes an issue, we could asynchronously close each page.
  const handleCloseSelectAll = async () => {
    if (!selectAllInfo) return;
    setClosingSelectAll(true);
    selectAllInfo.setBlockRefresh(true);
    let done = false;
    do {
      const result = await closeBatch();
      setNumDone((prev) => prev + result.batchSize);
      done = result.done;
    } while (!done);
    amplitude.logEvent("close-conversation", { mode: "all" });
    setClosingSelectAll(false);
    selectAllInfo.setBlockRefresh(false);
    cleanupFn && cleanupFn(true);
    setOpen(false);
  };

  const plural = conversationsCanClose.length > 1 || selectAllInfo;

  const footer = (
    <div className={closeTicketModalCss.actionButtonsContainer}>
      <Button onClick={() => setOpen(false)} theme={ButtonTheme.OUTLINED}>
        Cancel
      </Button>
      <Button
        onClick={async () => {
          if (doNotShowAgain) {
            localStorage.setItem("redo.doNotShowCloseTicketModal", "true");
          }
          if (selectAllInfo) {
            await handleCloseSelectAll();
          } else {
            await handleCloseTickets({
              client,
              conversations: conversationsCanClose,
              setActiveConversation,
              team,
              inDetailView,
              cleanupFn,
              removeConversation,
              nextConversationInList,
              prevConversationInList,
            });
            setOpen(false);
          }
        }}
        theme={ButtonTheme.PRIMARY}
      >
        Yes, close ticket{plural ? "s" : ""}
      </Button>
    </div>
  );

  return (
    <Modal
      footer={closingSelectAll ? undefined : footer}
      onClose={() => {
        cleanupFn && cleanupFn();
        setOpen(false);
      }}
      open={open}
      showHeaderBorder={false}
      size={ModalSize.SMALL}
      title={
        closingSelectAll
          ? "Closing tickets"
          : `Close ${
              selectAllInfo
                ? "all selected "
                : plural
                  ? conversationsCanClose.length + " "
                  : ""
            }ticket${plural ? "s" : ""}?`
      }
    >
      {closingSelectAll ? (
        <ProgressBar
          value={(numDone / (selectAllInfo?.count || numDone)) * 100}
        />
      ) : (
        <div className={closeTicketModalCss.actionModalContentContainer}>
          <div>
            {plural ? "These tickets" : "This ticket"} can be reopened if
            needed.
          </div>
          <Checkbox
            onChange={(val) => setDoNotShowAgain(val)}
            value={doNotShowAgain}
          >
            Don't show this again
          </Checkbox>
        </div>
      )}
    </Modal>
  );
});
