import { ClickAwayListener } from "@mui/material";
import {
  CustomDate,
  DateFilterOperator,
  KnownDateFilterTimeFrame,
} from "@redotech/redo-model/views/advanced-filters/date-filter";
import { assertNever, Tuple } from "@redotech/util/type";
import { memo, useEffect, useMemo, useState } from "react";
import Calendar from "react-calendar";
import { RedoCheckbox } from "../../arbiter-components/checkbox/redo-checkbox";
import {
  RedoFilterDropdownAnchor,
  RedoFilterGroup,
} from "../../arbiter-components/filter-group/redo-filter-group";
import {
  RedoTimePicker,
  TimeOfDay,
} from "../../arbiter-components/time/redo-time-picker";
import { Dropdown } from "../../dropdown";
import { Flex } from "../../flex";
import { Text } from "../../text";

// CSS imports must be ordered
import "react-time-picker/dist/TimePicker.css";
//
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
//
import "react-calendar/dist/Calendar.css";
//
import { useHandler } from "@redotech/react-util/hook";
import { AdvancedFilterType } from "@redotech/redo-model/views/advanced-filters/generic-advanced-filter-data";
import { RedoListItem } from "../../arbiter-components/list/redo-list";
import { RedoListDropdown } from "../../arbiter-components/list/redo-list-dropdown";
import "../../date-range.module.css";
import { SimpleFilterDropdown } from "../../filters/simple-filter-dropdown";
import { DateTableFilter } from "../advanced-filter";

export const DateFilterGroup = memo(function DateFilterGroup({
  filter,
  setFilter,
  removeFilter,
  openOnRender,
}: {
  filter: DateTableFilter;
  setFilter(filter: DateTableFilter): void;
  removeFilter(): void;
  openOnRender: boolean;
}) {
  const { name, operator, value, customDate, type } = filter.data;

  const [valueRef, setValueRef] = useState<HTMLButtonElement | null>(null);

  const handleSetCustomDateValue = useHandler((value: CustomDate) => {
    const sortedDates = sortDates(value);
    setFilter({
      ...filter,
      data: {
        type: AdvancedFilterType.DATE,
        name,
        operator,
        value: KnownDateFilterTimeFrame.CUSTOM,
        customDate: sortedDates,
      },
    });
  });

  const handleSetTimeFrame = useHandler(
    ({
      item: newTimeFrameItem,
    }: {
      item: RedoListItem<KnownDateFilterTimeFrame>;
    }) => {
      const newTimeFrame = newTimeFrameItem.value;
      if (newTimeFrame === KnownDateFilterTimeFrame.CUSTOM) {
        setCustomDateDropdownOpen(true);
        return;
      }
      const oldTimeFrame = value;
      if (newTimeFrame === oldTimeFrame) {
        return;
      }
      setFilter({
        ...filter,
        data: {
          type,
          operator,
          value: newTimeFrame,
          customDate: undefined,
          name,
        },
      });
    },
  );

  const handleSetDateOperatorType = useHandler(
    (newOperatorType: DateFilterOperator) => {
      const oldOpType = operator;
      if (newOperatorType === oldOpType) {
        return;
      }
      const newCustomDate =
        value === KnownDateFilterTimeFrame.CUSTOM
          ? wrangleCustomDateIntoProperForm(newOperatorType, customDate)
          : undefined;
      setFilter({
        ...filter,
        data: {
          type: AdvancedFilterType.DATE,
          operator: newOperatorType,
          value,
          name,
          customDate: newCustomDate,
        },
      });
    },
  );

  const [customDateDropdownOpen, setCustomDateDropdownOpen] = useState(false);

  const [valueDropdownOpen, setValueDropdownOpen] = useState(false);

  const valueAnchor = (
    <RedoFilterDropdownAnchor
      color="primary"
      onClick={() => setValueDropdownOpen(!valueDropdownOpen)}
      ref={setValueRef}
      text={getValueString(value, customDate)}
      weight="medium"
    />
  );

  const valueOptions = useMemo(() => {
    return Object.values(KnownDateFilterTimeFrame).map<
      RedoListItem<KnownDateFilterTimeFrame>
    >((item) => {
      return {
        id: item,
        type: "text",
        text: timeFrameToText[item],
        value: item,
      };
    });
  }, []);

  const valueDropdown = (
    <RedoListDropdown
      dropdownAnchor={valueRef}
      dropdownOpen={valueDropdownOpen}
      items={valueOptions}
      itemSelected={handleSetTimeFrame}
      selectedItems={value || undefined}
      setDropdownOpen={setValueDropdownOpen}
      size="xs"
    />
  );

  return (
    <>
      {valueDropdown}
      <CustomDatePickerDropdown
        anchor={valueRef}
        customDate={customDate}
        open={customDateDropdownOpen}
        operator={operator}
        setCustomDate={handleSetCustomDateValue}
        setOpen={setCustomDateDropdownOpen}
      />
      <RedoFilterGroup
        Icon={filter.Icon}
        propertyName={filter.displayName}
        query={
          <SimpleFilterDropdown
            filterStyle="query"
            options={filter.operators || Object.values(DateFilterOperator)}
            optionToFriendlyName={(value) =>
              dateFilterOperatorTypeToText[value]
            }
            setValue={handleSetDateOperatorType}
            value={operator}
          />
        }
        removeFilter={removeFilter}
        value={valueAnchor}
      />
    </>
  );
});

type TimeOfDayTuple = Tuple<TimeOfDay, 1> | Tuple<TimeOfDay, 2>;

export function CustomDatePickerDropdown({
  anchor,
  open,
  setOpen,
  customDate,
  setCustomDate,
  operator,
}: {
  anchor: HTMLElement | null;
  open: boolean;
  setOpen(open: boolean): void;
  customDate: CustomDate | null | undefined;
  setCustomDate(dates: CustomDate): void;
  operator: DateFilterOperator;
}) {
  const [timesOfDayFromPicker, setTimesOfDayFromPicker] = useState<
    TimeOfDayTuple | undefined | null
  >();

  const [localCustomDates, setLocalCustomDates] = useState<
    CustomDate | null | undefined
  >(customDate);

  useEffect(() => {
    setLocalCustomDates(customDate);
    setTimesOfDayFromPicker(undefined);
  }, [customDate]);

  const [customizeTimeOfDay, setCustomizeTimeOfDay] = useState(false);

  const timesOfDay: TimeOfDayTuple | undefined = useMemo(() => {
    if (!customizeTimeOfDay || !localCustomDates) {
      return undefined;
    }

    if (localCustomDates?.length === 2) {
      const timesOfDayFromDates = [
        localCustomDates[0],
        localCustomDates[1],
      ].map((date) => {
        return { hours: date.getHours(), minutes: date.getMinutes() };
      }) as Tuple<TimeOfDay, 2>;

      if (timesOfDayFromPicker) {
        if (timesOfDayFromPicker.length === 2) {
          return timesOfDayFromPicker;
        } else {
          return [timesOfDayFromPicker[0], timesOfDayFromDates[1]];
        }
      } else {
        return timesOfDayFromDates;
      }
    } else {
      if (timesOfDayFromPicker) {
        return timesOfDayFromPicker;
      }
      return [
        {
          hours: localCustomDates[0].getHours(),
          minutes: localCustomDates[0].getMinutes(),
        },
      ];
    }
  }, [localCustomDates, timesOfDayFromPicker, customizeTimeOfDay]);

  function handleClose(datesToCloseWith?: CustomDate) {
    const dates = datesToCloseWith || localCustomDates;

    if (!dates) {
      setOpen(false);
      return;
    }

    if (!timesOfDay) {
      setCustomDate(dates);
      setOpen(false);
      return;
    }

    if (timesOfDay?.length === 2 && dates?.length === 2) {
      const [startTime, endTime] = timesOfDay;
      const [startDate, endDate] = dates;
      const newCustomDate: [Date, Date] = [
        new Date(startDate),
        new Date(endDate),
      ];
      newCustomDate[0].setHours(startTime.hours, startTime.minutes);
      newCustomDate[1].setHours(endTime.hours, endTime.minutes);
      setCustomDate(newCustomDate);
    } else if (timesOfDay?.length === 1 && dates?.length === 1) {
      const newCustomDate = [new Date(dates[0])];
      newCustomDate[0].setHours(timesOfDay[0].hours, timesOfDay[0].minutes);
      setCustomDate(newCustomDate as CustomDate);
    }

    setOpen(false);
  }

  function handleSetCustomizeTimeOfDay(customize: boolean) {
    if (!customize) {
      setTimesOfDayFromPicker(undefined);
      setLocalCustomDates(
        wrangleDatePickerSubmission(operator, localCustomDates || null),
      );
    }
    setCustomizeTimeOfDay(customize);
  }

  function handleSetCustomDate(value: CustomDate) {
    const formattedCustomDate = wrangleDatePickerSubmission(
      operator,
      Array.isArray(value) ? value : [value],
    );
    setLocalCustomDates(formattedCustomDate);
    if (!customizeTimeOfDay) {
      handleClose(formattedCustomDate);
    }
  }

  return (
    <>
      {open && (
        <ClickAwayListener onClickAway={() => handleClose()}>
          <Dropdown
            anchor={anchor}
            constrainHeight={false}
            fitToAnchor={false}
            open={open}
          >
            <Flex dir="column">
              <Calendar
                maxDate={new Date()}
                minDetail="year"
                onChange={handleSetCustomDate}
                selectRange={operator === DateFilterOperator.WITHIN}
                value={localCustomDates}
              />
              <RedoCheckbox
                label="Customize time of day"
                setValue={(value) => handleSetCustomizeTimeOfDay(!!value)}
                value={customizeTimeOfDay}
              />
              {customizeTimeOfDay &&
                localCustomDates?.length === 2 &&
                timesOfDay?.length === 2 && (
                  <RangeTimePicker
                    dates={localCustomDates}
                    setTimesOfDay={setTimesOfDayFromPicker}
                    timesOfDay={timesOfDay}
                  />
                )}
              {customizeTimeOfDay &&
                localCustomDates &&
                timesOfDay &&
                localCustomDates.length === 1 &&
                timesOfDay.length === 1 && (
                  <SingleDateTimePicker
                    date={localCustomDates[0]}
                    setTimeOfDay={setTimesOfDayFromPicker}
                    timeOfDay={timesOfDay[0]}
                  />
                )}
            </Flex>
          </Dropdown>
        </ClickAwayListener>
      )}
    </>
  );
}

function dateToTimeOfDay(date: Date): TimeOfDay {
  return { hours: date.getHours(), minutes: date.getMinutes() };
}

function SingleDateTimePicker({
  date,
  timeOfDay,
  setTimeOfDay,
}: {
  date: Date;
  timeOfDay: TimeOfDay;
  setTimeOfDay(date: TimeOfDayTuple): void;
}) {
  function handleSetStartTimeVal(newTimeOfDay: TimeOfDay | null) {
    const timeOfDayToSet = newTimeOfDay || dateToTimeOfDay(date);
    setTimeOfDay([timeOfDayToSet]);
  }

  return (
    <Flex justify="space-between">
      <Text>{date.toDateString()}</Text>
      <Text> at </Text>
      <RedoTimePicker setValue={handleSetStartTimeVal} value={timeOfDay} />
    </Flex>
  );
}

function RangeTimePicker({
  dates,
  timesOfDay,
  setTimesOfDay,
}: {
  dates: Tuple<Date, 2>;
  timesOfDay: Tuple<TimeOfDay, 2>;
  setTimesOfDay(date: Tuple<TimeOfDay, 2>): void;
}) {
  function handleSetStartTimeVal(newTimeOfDay: TimeOfDay | null) {
    const timeOfDayToSet = newTimeOfDay || dateToTimeOfDay(dates[0]);
    setTimesOfDay([timeOfDayToSet, timesOfDay[1]]);
  }

  function handleSetEndTimeVal(newTimeOfDay: TimeOfDay | null) {
    const timeOfDayToSet = newTimeOfDay || dateToTimeOfDay(dates[1]);
    setTimesOfDay([timesOfDay[0], timeOfDayToSet]);
  }

  return (
    <Flex dir="column">
      <Flex justify="space-between">
        <Text>{dates[0].toDateString()}</Text>
        <Text> at </Text>
        <RedoTimePicker
          setValue={handleSetStartTimeVal}
          value={timesOfDay[0]}
        />
      </Flex>
      <Flex justify="space-between">
        <Text>{dates[1].toDateString()}</Text>
        <Text> at </Text>
        <RedoTimePicker setValue={handleSetEndTimeVal} value={timesOfDay[1]} />
      </Flex>
    </Flex>
  );
}

function formatDateTime(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  return date.toLocaleString("en-US", options).replace(", ", " at ");
}

function getValueString(
  timeFrame: KnownDateFilterTimeFrame | null | undefined,
  customDate: CustomDate | null | undefined,
): string {
  if (!timeFrame) {
    return "...";
  }
  if (!customDate || timeFrame !== KnownDateFilterTimeFrame.CUSTOM) {
    return timeFrameToText[timeFrame];
  }
  if (customDate?.length === 2) {
    const [startDate, endDate] = customDate;
    return `${formatDateTime(startDate)} - ${formatDateTime(endDate)}`;
  }
  return formatDateTime(customDate[0]);
}

export const timeFrameToText: Record<KnownDateFilterTimeFrame, string> = {
  [KnownDateFilterTimeFrame.TODAY]: "Today",
  [KnownDateFilterTimeFrame.THIS_WEEK]: "This Week",
  [KnownDateFilterTimeFrame.LAST_WEEK]: "Last Week",
  [KnownDateFilterTimeFrame.THIS_MONTH]: "This Month",
  [KnownDateFilterTimeFrame.LAST_MONTH]: "Last Month",
  [KnownDateFilterTimeFrame.THIS_YEAR]: "This Year",
  [KnownDateFilterTimeFrame.LAST_YEAR]: "Last Year",
  [KnownDateFilterTimeFrame.CUSTOM]: "Custom",
};

export const dateFilterOperatorTypeToText: Record<DateFilterOperator, string> =
  {
    [DateFilterOperator.WITHIN]: "during",
    [DateFilterOperator.BEFORE]: "before",
    [DateFilterOperator.AFTER]: "after",
  };

export function sortDates(dates: CustomDate): CustomDate {
  return dates.sort((a, b) => a.getTime() - b.getTime()) as CustomDate;
}

export function wrangleDatePickerSubmission(
  operator: DateFilterOperator,
  value: CustomDate | null | undefined,
): CustomDate {
  if (value?.length === 2) {
    const [startDate, endDate] = value;
    const definedStartDate = startDate || new Date();
    const definedEndDate = endDate || new Date();
    const newCustomDateRange = sortDates([definedStartDate, definedEndDate]);

    const newCustomDate = wrangleCustomDateIntoProperForm(
      operator,
      newCustomDateRange,
    );

    return newCustomDate;
  } else if (value) {
    const newCustomDate = wrangleCustomDateIntoProperForm(operator, value);
    return newCustomDate;
  } else {
    const newCustomDate = wrangleCustomDateIntoProperForm(operator, [
      new Date(),
    ]);
    return newCustomDate;
  }
}

export function wrangleCustomDateIntoProperForm(
  operatorType: DateFilterOperator,
  customDate: CustomDate | undefined | null,
): CustomDate {
  if (operatorType === DateFilterOperator.WITHIN) {
    if (customDate?.length === 2) {
      return customDate;
    } else if (customDate?.length === 1) {
      const startOfDay = new Date(customDate[0]);
      startOfDay.setHours(0, 0, 0, 0);
      const endofDay = new Date(customDate[0]);
      endofDay.setHours(23, 59, 59, 999);
      return [startOfDay, endofDay];
    } else {
      const startOfDay = new Date();
      startOfDay.setHours(0, 0, 0, 0);
      const endOfDay = new Date();
      endOfDay.setHours(23, 59, 59, 999);
      return [startOfDay, endOfDay];
    }
  } else if (operatorType === DateFilterOperator.BEFORE) {
    if (customDate && customDate.length > 0) {
      const beforeDate = new Date(customDate[0]);
      beforeDate.setHours(0, 0, 0, 0);
      return [beforeDate];
    } else {
      const beforeDate = new Date();
      beforeDate.setHours(0, 0, 0, 0);
      return [beforeDate];
    }
  } else if (operatorType === DateFilterOperator.AFTER) {
    if (customDate?.length === 2) {
      const afterDate = new Date(customDate[1]);
      afterDate.setHours(23, 59, 59, 999);
      return [afterDate];
    } else if (customDate?.length === 1) {
      const afterDate = new Date(customDate[0]);
      afterDate.setHours(23, 59, 59, 999);
      return [afterDate];
    } else {
      const afterDate = new Date();
      afterDate.setHours(23, 59, 59, 999);
      return [afterDate];
    }
  } else {
    assertNever(operatorType);
  }
}
