import { useLazyContext } from "@redotech/react-util/context";
import {
  ConversationTagFilterType,
  ConversationTagsFilter,
  FilterGroupFilterOption,
  PendingFilter,
} from "@redotech/redo-model/conversation-filters/conversation-filters";
import {
  RedoFilterDropdownAnchor,
  RedoFilterGroup,
} from "@redotech/redo-web/arbiter-components/filter-group/redo-filter-group";
import {
  ConversationFilterOptionToIcon,
  ConversationFilterOptionToName,
} from "@redotech/redo-web/conversation-filters/conversation-filter-icons";
import { SimpleFilterDropdown } from "@redotech/redo-web/filters/simple-filter-dropdown";
import { Flex } from "@redotech/redo-web/flex";
import { memo, useEffect, useMemo, useState } from "react";
import { ConversationTagsContext } from "../../services/support/conversation-tags-service";
import { ConversationTagInput } from "../conversation-tags/conversation-tag-input";
import { ConversationTagPill } from "../conversation-tags/conversation-tag-pill";
export const ConversationTagFilter = memo(function ConversationTagFilter({
  filter,
  removeFilter,
  setFilter,
  openOnRender,
}: {
  filter: ConversationTagsFilter | PendingFilter<ConversationTagsFilter>;
  removeFilter(): void;
  setFilter(
    filter: ConversationTagsFilter | PendingFilter<ConversationTagsFilter>,
  ): void;
  openOnRender: boolean;
}) {
  const [conversationTags] = useLazyContext(ConversationTagsContext);

  const allTags = useMemo(
    () => conversationTags.value || [],
    [conversationTags],
  );

  const conversationTagFilterType = filter.query;

  const rawConversationTags = new Set(filter.value);
  const selectedTags = allTags.filter((tag) =>
    rawConversationTags.has(tag.name),
  );

  const [valueRef, setValueRef] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (openOnRender && valueRef) {
      valueRef.click();
    }
  }, [openOnRender, valueRef]);

  const valueText = filter.value
    ? selectedTags.length === 1
      ? "1 Tag"
      : `${selectedTags.length} Tags`
    : "...";

  const [tagDropdownOpen, setTagDropdownOpen] = useState(false);

  const value = (
    <RedoFilterDropdownAnchor
      color="primary"
      onClick={() => setTagDropdownOpen(!tagDropdownOpen)}
      ref={setValueRef}
      text={valueText}
      tooltip={
        selectedTags.length > 0 &&
        !tagDropdownOpen && (
          <Flex wrap="wrap">
            {selectedTags.map((tag) => (
              <ConversationTagPill key={tag.name} tag={tag} />
            ))}
          </Flex>
        )
      }
      weight="medium"
    />
  );

  const valueDropdown = (
    <ConversationTagInput
      currentTags={selectedTags}
      onlyDropdownMode={{
        anchor: valueRef,
        open: tagDropdownOpen,
        setOpen: setTagDropdownOpen,
      }}
      setCurrentTags={(tags) =>
        setFilter({ ...filter, value: tags.map((tag) => tag.name) })
      }
      showAddButtonToEnterInput
    />
  );

  return (
    <>
      {valueDropdown}
      <RedoFilterGroup
        Icon={
          ConversationFilterOptionToIcon[
            FilterGroupFilterOption.CONVERSATION_TAGS
          ]
        }
        propertyName={
          ConversationFilterOptionToName[
            FilterGroupFilterOption.CONVERSATION_TAGS
          ]
        }
        query={
          <SimpleFilterDropdown
            filterStyle="query"
            options={Object.values(ConversationTagFilterType)}
            optionToFriendlyName={(option) => tagFilterTypeToText[option]}
            setValue={(query) => setFilter({ ...filter, query })}
            value={conversationTagFilterType}
          />
        }
        removeFilter={removeFilter}
        value={value}
      />
    </>
  );
});

const tagFilterTypeToText: Record<ConversationTagFilterType, string> = {
  [ConversationTagFilterType.ALL_OF]: "have all of",
  [ConversationTagFilterType.ANY_OF]: "include any of",
  [ConversationTagFilterType.NONE_OF]: "include none of",
};
