import { ReturnTypeEnum } from "@redotech/redo-model/return";
import { z } from "zod";

export const getReturnAnalyticsSeriesData = {
  input: z.object({
    startDate: z.string(),
    endDate: z.string(),
    types: z.array(z.nativeEnum(ReturnTypeEnum)),
  }),
  output: z.object({
    data: z.array(
      z.object({
        date: z.string(),
        returnedOrdersRate: z.number(),
        returnedItemsRate: z.number(),
        returnedRevenueRate: z.number(),
      }),
    ),
  }),
};

export type ReturnAnalyticsSeriesData = z.infer<
  typeof getReturnAnalyticsSeriesData.output
>;
