import { z } from "zod";

export const getMetaAdsAccounts = {
  input: z.null(),
  output: z.object({
    accounts: z.array(
      z.object({ accountId: z.string(), id: z.string(), name: z.string() }),
    ),
  }),
};
