import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { Text } from "@redotech/redo-web/text";
import { memo, useContext } from "react";
import { Notification } from "../notification";

export const NewPermissions = memo(function NewPermissions() {
  const team = useContext(TeamContext);
  if (!team?.updatePermissionsLink) {
    return null;
  }
  return (
    <Notification type="warning">
      <Text fontSize="sm" fontWeight="semibold" textColor="primary">
        Redo needs new permissions to continue providing the best experience.
      </Text>
      <a href={team.updatePermissionsLink}>Update permissions</a>
    </Notification>
  );
});
