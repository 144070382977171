import { z } from "zod";
import { zExt } from "../common/zod-util";
import { TimeUnit } from "../time";
import {
  ComparisonOperator,
  ProductRecommendationType,
  UniqueProductFilterType,
} from "./product-filters-types";

export const additionalProductFilterSchema = z.discriminatedUnion("type", [
  z.object({
    type: z.literal(UniqueProductFilterType.COLLECTION),
    collectionIds: z.array(z.string()),
  }),
  z.object({
    type: z.literal(UniqueProductFilterType.METAFIELD),
    metafields: z.array(
      z.object({ metafieldKey: z.string(), namespace: z.string() }),
    ),
  }),
  z.object({
    type: z.literal(UniqueProductFilterType.TAG),
    tags: z.array(z.string()),
  }),
  z.object({
    type: z.literal(UniqueProductFilterType.PRICE),
    price: z.number(),
    comparisonOperator: z.nativeEnum(ComparisonOperator),
  }),
  z.object({
    type: z.literal(UniqueProductFilterType.INVENTORY),
    inventory: z.number(),
    comparisonOperator: z.nativeEnum(ComparisonOperator),
  }),
]);

const baseSchema = {
  name: z.string(),
  additionalProductFilters: z.array(additionalProductFilterSchema).optional(),
};

const metafieldSchema = z.object({
  ...baseSchema,
  productRecommendationType: z.literal(ProductRecommendationType.METAFIELD),
  metafield: z.object({ key: z.string(), namespace: z.string() }),
});

const tagSchema = z.object({
  ...baseSchema,
  productRecommendationType: z.literal(ProductRecommendationType.TAG),
  tags: z.array(z.string()),
});

const collectionSchema = z.object({
  ...baseSchema,
  productRecommendationType: z.literal(ProductRecommendationType.COLLECTION),
  collectionId: z.string(),
});

const viewedProductsSchema = z.object({
  ...baseSchema,
  productRecommendationType: z.literal(
    ProductRecommendationType.VIEWED_PRODUCTS,
  ),
  unit: z.nativeEnum(TimeUnit),
  value: z.number(),
});

const productsAddedToCartSchema = z.object({
  ...baseSchema,
  productRecommendationType: z.literal(
    ProductRecommendationType.PRODUCTS_ADDED_TO_CART,
  ),
  unit: z.nativeEnum(TimeUnit),
  value: z.number(),
});

const providerRecommendationsSchema = z.object({
  ...baseSchema,
  productRecommendationType: z.literal(
    ProductRecommendationType.PROVIDER_RECOMMENDATIONS,
  ),
});

const bestSellersSchema = z.object({
  ...baseSchema,
  productRecommendationType: z.literal(ProductRecommendationType.BEST_SELLERS),
});

export const baseProductFilterSchema = z.discriminatedUnion(
  "productRecommendationType",
  [
    metafieldSchema,
    collectionSchema,
    viewedProductsSchema,
    productsAddedToCartSchema,
    providerRecommendationsSchema,
    tagSchema,
    bestSellersSchema,
  ],
);

export type ProductFilter = z.infer<typeof baseProductFilterSchema>;

export const ProductFilterDbParser = z.intersection(
  baseProductFilterSchema,
  z.object({ _id: zExt.objectId(), team: zExt.objectId() }),
);
