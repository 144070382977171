import { z } from "zod";

export const outreachGroupKeys = [
  "automation",
  "automationStepId",
  "campaign",
] as const;

export type OutreachGroupKey = Partial<
  Record<(typeof outreachGroupKeys)[number], string>
>;

export const OutreachGroupKeySchema = z
  .object({
    automation: z.string().optional(),
    automationStepId: z.string().optional(),
    campaign: z.string().optional(),
  })
  .partial();

export const outreachProducts = ["marketing", "order tracking"] as const;

export const outreachMetrics = [
  "complaints",
  "bounces",
  "sends",
  "deliveries",
  "opens",
  "uniqueOpens",
  "clicks",
  "uniqueClicks",
  "upsellCount",
  "upsellRevenue",
] as const;

export type OutreachMetric = (typeof outreachMetrics)[number];

export enum OutreachEventType {
  Complaint = "complaint",
  Bounce = "bounce",
  Send = "send",
  Delivery = "delivery",
  Open = "open",
  Click = "click",
}
