import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { RedoMerchantRpcClientContext } from "@redotech/redo-merchant-app-common/rpc-client";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import type { Product, Return } from "@redotech/redo-model/return";
import { RedoModal } from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import { Flex } from "@redotech/redo-web/flex";
import { LoadingRedoAnimation } from "@redotech/redo-web/loading-redo-animation";
import { Text } from "@redotech/redo-web/text";
import { assertNever } from "@redotech/util/type";
import { memo, useContext, useEffect, useMemo, useState } from "react";
import { EditItemDisposition } from "../edit-item-disposition/edit-item-disposition";

enum EditSingleItemDispositionModalState {
  LOADING = "LOADING",
  LOADED = "LOADED",
  ERROR = "ERROR",
}

export const EditSingleItemDispositionModal = memo(
  function EditSingleItemDispositionModal({
    open,
    closeModal,
    item,
    return_,
  }: {
    open: boolean;
    closeModal: (_reload?: boolean) => void;
    item: Product;
    return_: Return;
  }) {
    const client = useRequiredContext(RedoMerchantRpcClientContext);
    const team = useContext(TeamContext);

    const [selectedGrade, setSelectedGrade] = useState<string | undefined>(
      item.merchant_grade,
    );
    const [selectedOutcome, setSelectedOutcome] = useState<string | undefined>(
      item.merchant_outcome,
    );
    const [notes, setNotes] = useState<string>(item.merchant_notes ?? "");

    useEffect(() => {
      setSelectedGrade(item.merchant_grade);
      setSelectedOutcome(item.merchant_outcome);
      setNotes(item.merchant_notes ?? "");
    }, [item]);

    const handleSaveItemDisposition = useHandler(async () => {
      await client.setReturnItemDisposition({
        returnId: return_._id,
        itemsWithDisposition: {
          [item._id]: {
            grade: selectedGrade,
            outcome: selectedOutcome,
            notes: notes,
          },
        },
      });
      closeModal(true);
    });

    const editItemDispositionGrade = useMemo(() => {
      const options = team?.settings.returnItemDisposition.gradeOptions;
      if (options) {
        return {
          options: options || [],
          selectedOption: selectedGrade,
          setSelectedOption: setSelectedGrade,
        };
      }
      return undefined;
    }, [team, selectedGrade, setSelectedGrade]);

    const editItemDispositionOutcome = useMemo(() => {
      const options = team?.settings.returnItemDisposition.outcomeOptions;
      if (options) {
        return {
          options: options,
          selectedOption: selectedOutcome,
          setSelectedOption: setSelectedOutcome,
        };
      }
      return undefined;
    }, [team, selectedOutcome, setSelectedOutcome]);

    const editItemDispositionNotes = useMemo(() => {
      return { value: notes, setValue: setNotes };
    }, [notes, setNotes]);

    const modalState = useMemo(() => {
      if (!(editItemDispositionGrade && editItemDispositionOutcome)) {
        return EditSingleItemDispositionModalState.LOADING;
      } else if (
        editItemDispositionGrade.options.length === 0 ||
        editItemDispositionOutcome.options.length === 0
      ) {
        return EditSingleItemDispositionModalState.ERROR;
      } else {
        return EditSingleItemDispositionModalState.LOADED;
      }
    }, [editItemDispositionGrade, editItemDispositionOutcome]);

    const modalContent = useMemo(() => {
      switch (modalState) {
        case EditSingleItemDispositionModalState.LOADED:
          if (!editItemDispositionGrade || !editItemDispositionOutcome) {
            throw new Error(
              "Loaded state should have grade and outcome options",
            );
          }
          return (
            <EditItemDisposition
              grade={editItemDispositionGrade}
              notes={editItemDispositionNotes}
              outcome={editItemDispositionOutcome}
            />
          );
        case EditSingleItemDispositionModalState.ERROR:
          return (
            <Flex align="center" flex={1} justify="center">
              <Text>Error loading item disposition data</Text>
            </Flex>
          );
        case EditSingleItemDispositionModalState.LOADING:
          return (
            <Flex align="center" flex={1} justify="center">
              <LoadingRedoAnimation />
            </Flex>
          );
        default:
          assertNever(modalState);
      }
    }, [
      modalState,
      editItemDispositionGrade,
      editItemDispositionOutcome,
      editItemDispositionNotes,
    ]);

    return (
      <RedoModal
        buttonPlacement="tight"
        isOpen={open}
        modalSize="sm"
        onModalCloseRequested={() => closeModal()}
        primaryButton={{ text: "Save", onClick: handleSaveItemDisposition }}
        secondaryButton={{ text: "Cancel", onClick: () => closeModal() }}
        title="Edit item disposition"
      >
        {modalContent}
      </RedoModal>
    );
  },
);
