import { RedoSecondaryNavItem } from "@redotech/redo-web/arbiter-components/navigation/secondary/redo-secondary-nav-item";
import EditPencilIcon from "@redotech/redo-web/arbiter-icon/edit-01.svg";
import FolderOpenIcon from "@redotech/redo-web/arbiter-icon/folder-open.svg";
import FolderIcon from "@redotech/redo-web/arbiter-icon/folder.svg";
import { memo, ReactNode } from "react";

export const RedoNavFolder = memo(function RedoNavFolder({
  children,
  folderEditRequested,
  folderId,
  folderName,
  anyChildSelected,
}: {
  folderId: string;
  folderName: string;
  anyChildSelected: boolean;
  children: ReactNode | ReactNode[];
  folderEditRequested: () => void;
}) {
  return (
    <RedoSecondaryNavItem
      action={{ type: "button" }}
      anyChildSelected={anyChildSelected}
      FilledIcon={FolderOpenIcon}
      friendlyName={folderName}
      id={folderId}
      isFolder
      UnfilledIcon={FolderIcon}
    >
      {[
        children,
        <RedoSecondaryNavItem
          action={{ type: "button", onClick: folderEditRequested }}
          FilledIcon={EditPencilIcon}
          friendlyName="Edit folder"
          id="edit-folder"
          key="edit-folder"
          UnfilledIcon={EditPencilIcon}
        />,
      ]}
    </RedoSecondaryNavItem>
  );
});
