import { useHandler } from "@redotech/react-util/hook";
import { AuthRequired } from "@redotech/redo-merchant-app-common/auth";
import {
  TeamAndUserRequired,
  TeamContext,
} from "@redotech/redo-merchant-app-common/team";
import { ErrorBoundary } from "@redotech/redo-web/error-page";
import { Page as CommonPage } from "@redotech/redo-web/page";
import { memo, useContext, useEffect, useMemo, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { GlobalSearch } from "../global-search/global-search";
import { DataServices } from "../services/data-services";
import { AlertContent } from "./alert";
import { NotificationBanner } from "./banner/banner";
import { MerchantAppSideNav } from "./navigation/side-navigation/merchant-side-navigation";
import { UserCacheContext } from "./user-cache";
import { VoiceWidget } from "./voice/voice-widget";

function urlIsInSettings(url: string): boolean {
  const matchesStoreUrl = matchPath("/stores/:storeId/settings*", url);
  const matchesSettingsRoute = matchPath("/settings/*", url);
  return !!(matchesStoreUrl || matchesSettingsRoute);
}

export const Page = memo(function Page({
  hideHeader = () => false,
  hideNavbar = () => false,
  hidePadding = () => false,
}: {
  hideHeader?: (url: string, search: { search: string }) => boolean;
  hideNavbar?: (url: string) => boolean;
  hidePadding?: (url: string) => boolean;
}) {
  const team = useContext(TeamContext);

  const [lastNonSettingsUrl, setLastNonSettingsUrl] = useState<
    string | undefined
  >(undefined);

  const location = useLocation();

  const stableHideNavbar = useHandler(hideNavbar);

  const stableHidePadding = useHandler(hidePadding);

  const shouldHidePadding = useMemo(() => {
    return stableHidePadding(location.pathname);
  }, [location, stableHidePadding]);

  const hideNavigation = useMemo(() => {
    return stableHideNavbar(location.pathname);
  }, [location, stableHideNavbar]);

  useEffect(() => {
    if (!urlIsInSettings(location.pathname)) {
      setLastNonSettingsUrl(location.pathname);
    }
  }, [location]);

  const settingsUrl = `/stores/${team?._id}/settings/users`;
  const nonSettingsUrl = lastNonSettingsUrl || `/stores/${team?._id}/summary`;
  const settingsRoot = `/stores/${team?._id}/settings`;

  const intercom = useIntercom();

  return (
    <AuthRequired>
      <TeamAndUserRequired>
        <DataServices>
          <UserCacheContext.Provider value={new Map()}>
            <CommonPage
              banner={<NotificationBanner />}
              ErrorBoundary={ErrorBoundary}
              hideHeader={hideHeader}
              hidePadding={shouldHidePadding}
              nav={
                <MerchantAppSideNav
                  enterSettingsUrl={settingsUrl}
                  exitSettingsUrl={nonSettingsUrl}
                  hideNav={hideNavigation}
                  settingsRoot={settingsRoot}
                />
              }
              showNewIntercomMessage={() => intercom.showNewMessage()}
            />
            <AlertContent />
            <VoiceWidget />
            <GlobalSearch />
          </UserCacheContext.Provider>
        </DataServices>
      </TeamAndUserRequired>
    </AuthRequired>
  );
});
