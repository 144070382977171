// extracted by mini-css-extract-plugin
export var container = "_7l-0";
export var content = "_7l-3";
export var customerTitle = "_7l-4";
export var empty = "_7l-5";
export var gray = "_7l-6";
export var header = "_7l-7";
export var info = "_7l-8";
export var loading = "_7l-2";
export var loadingCard = "_7l-9";
export var oneLine = "_7l-a";
export var personName = "_7l-e";
export var pillContainer = "_7l-b";
export var selected = "_7l-1";
export var text = "_7l-c";
export var twoLines = "_7l-d";
export var unread = "_7l-f";
export var unreadIndicator = "_7l-g";