import { z } from "zod";
import { ItemSoldFilterSchema } from "./item-sold-filter";
import { OrderCreatedDateFilterSchema } from "./order-created-date-filter";
import { ReturnTypeFilterSchema } from "./return-type-filter";

export const AnalyticsFilterSchema = z.union([
  OrderCreatedDateFilterSchema,
  ItemSoldFilterSchema,
  ReturnTypeFilterSchema,
]);

export const ProductAnalyticsFilterSchema = z.union([
  OrderCreatedDateFilterSchema,
  ItemSoldFilterSchema,
  ReturnTypeFilterSchema,
]);

export const VariantAnalyticsFilterSchema = z.union([
  OrderCreatedDateFilterSchema,
  ItemSoldFilterSchema,
  ReturnTypeFilterSchema,
]);

export type AnalyticsFilter = z.infer<typeof AnalyticsFilterSchema>;
export type ProductAnalyticsFilter = z.infer<
  typeof ProductAnalyticsFilterSchema
>;
export type VariantAnalyticsFilter = z.infer<
  typeof VariantAnalyticsFilterSchema
>;
