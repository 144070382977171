import { OmsTableInputSchema } from "@redotech/redo-model/outbound-labels/fulfillment-group-summary-input";
import { z } from "zod";

export const getFulfillmentGroupUniqueValues = {
  input: OmsTableInputSchema.extend({
    filterName: z.string(),
    filterSearch: z.string().nullish(),
  }),
  output: z.object({ values: z.array(z.string()) }),
};
