import { ReturnTypeEnum } from "@redotech/redo-model/return";
import { camelToTitleCase } from "@redotech/string/transform";

/**
 * If we get a purely numeric name for an order, add a hashtag. Otherwise, don't touch it.
 *
 * If it's an order, don't add "for".
 *
 * If there's no name, just use the detail type.
 * */
export const getOrderOrReturnDetailName = ({
  detailType = "Order",
  name,
}: {
  detailType?: "Order" | ReturnTypeEnum;
  name?: string;
}) => {
  const shouldPutHashtagBecauseNameIsJustANumber = /^\d+$/.test(name || "");
  return `${camelToTitleCase(detailType)}${name ? " " : ""}${shouldPutHashtagBecauseNameIsJustANumber ? "#" : ""}${name || ""}`;
};
