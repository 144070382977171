import { advancedFlowSchema } from "@redotech/redo-model/advanced-flow/advanced-flow-db-parser";
import {
  AutomationChannel,
  AutomationSortKey,
  AutomationStatus,
} from "@redotech/redo-model/advanced-flow/common";
import { categories } from "@redotech/redo-model/advanced-flow/triggers";
import { SortDirection } from "@redotech/redo-model/tables/table";
import { z } from "zod";

export const UserInfo = z.object({
  _id: z.string(),
  name: z.string().nullish(),
  email: z.string().nullish(),
});

export type UserInfo = z.infer<typeof UserInfo>;

export const getAdvancedFlows = {
  input: z.object({
    getUsers: z.boolean(),
    includeMetrics: z.boolean(),
    filters: z
      .object({
        status: z.array(z.nativeEnum(AutomationStatus)).optional(),
        channel: z.array(z.nativeEnum(AutomationChannel)).optional(),
        category: z.array(z.enum(categories)).optional(),
      })
      .optional(),
    sort: z
      .object({
        key: z.nativeEnum(AutomationSortKey),
        direction: z.nativeEnum(SortDirection),
      })
      .optional(),
  }),
  output: z.object({
    advancedFlows: z.array(advancedFlowSchema.extend({ metrics: z.any() })),
    users: z.map(z.string(), UserInfo).nullish(),
  }),
};
