import { UserContext } from "@redotech/redo-merchant-app-common/user";
import { Step as ModelStep } from "@redotech/redo-model/return-flow";
import { Permission, permitted } from "@redotech/redo-model/user";
import { BlockLayout } from "@redotech/redo-web/flowchart";
import InfoIcon from "@redotech/redo-web/icon-old/info.svg";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import {
  InputLines,
  InputTheme,
  TextInput,
} from "@redotech/redo-web/text-input";
import { produce } from "immer";
import { memo, useContext } from "react";
import { StepDownstream, StepId, StepType, StepTypeDetailsProps } from "./step";

export interface State {
  message: string;
  continueButtonText: string;
  header: string;
  customTitle?: string;
  next?: StepId;
}

const Details = memo(function Details({
  stepSelect,
  state,
  setState,
}: StepTypeDetailsProps<State>) {
  const user = useContext(UserContext);
  const canEditSettings =
    !!user && permitted(user.permissions, Permission.EDIT_SETTINGS);
  return (
    <>
      <LabeledInput
        description="Title/Prompt to show to the customer."
        label="Title"
      >
        <TextInput
          lines={InputLines.SINGLE}
          onChange={(header) => setState((state) => ({ ...state, header }))}
          readonly={!canEditSettings}
          theme={InputTheme.FORM}
          value={state.header}
        />
      </LabeledInput>
      <LabeledInput
        description="Message to show to the customer. Can include HTML."
        label="Message"
      >
        <TextInput
          lines={InputLines.MULTI}
          onChange={(message) => setState((state) => ({ ...state, message }))}
          readonly={!canEditSettings}
          theme={InputTheme.FORM}
          value={state.message}
        />
      </LabeledInput>
      <LabeledInput
        description="Text to show on the continue button."
        label="Continue Button Text"
      >
        <TextInput
          lines={InputLines.SINGLE}
          onChange={(continueButtonText) =>
            setState((state) => ({ ...state, continueButtonText }))
          }
          readonly={!canEditSettings}
          theme={InputTheme.FORM}
          value={state.continueButtonText}
        />
      </LabeledInput>
      <LabeledInput errors={!state.next ? ["Required"] : []} label="Next step">
        {stepSelect({
          value: state.next,
          valueChange: (next) => setState((state) => ({ ...state, next })),
        })}
      </LabeledInput>
    </>
  );
});

export const INFORMATION: StepType<State, ModelStep.Information> = {
  Details,
  customTitle(state) {
    return state.customTitle;
  },
  description(state: State) {
    return state.message;
  },
  downstream(state: State) {
    const result: StepDownstream[] = [];
    if (state.next !== undefined) {
      result.push({ id: state.next });
    }
    return result;
  },
  stepDeleted(state, stepId) {
    return produce(state, (state) => {
      if (state.next === stepId) {
        state.next = undefined;
      }
    });
  },
  empty: { message: "", continueButtonText: "", header: "" },
  layout() {
    return BlockLayout.FULL;
  },
  title: "Information",
  valid(state) {
    return (
      !!state.message &&
      !!state.header &&
      !!state.continueButtonText &&
      !!state.next
    );
  },
  fromModel(model: ModelStep.Information, id) {
    return {
      continueButtonText: model.continueButtonText,
      customTitle: model.customTitle,
      header: model.header,
      message: model.message,
      next: model.next ? id(model.next) : undefined,
    };
  },
  toModel(state: State, id) {
    return {
      customTitle: state.customTitle,
      type: ModelStep.INFORMATION,
      message: state.message,
      continueButtonText: state.continueButtonText,
      header: state.header,
      next: state.next ? id(state.next) : undefined,
    };
  },
  Icon: InfoIcon,
};
