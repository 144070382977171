import { useRequiredContext } from "@redotech/react-util/context";
import { LoadState, useLoad } from "@redotech/react-util/load";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { ConversationTimelineEvent } from "@redotech/redo-model/conversation-timeline";
import { CustomerActivityFilterOptions } from "@redotech/redo-model/customer-activity/customer-activity-filters";
import { CustomerEvent } from "@redotech/redo-model/customer-event/customer-event-definition";
import FilterFunnelIcon from "@redotech/redo-web/arbiter-icon/filter-funnel-02.svg";
import { IconButton } from "@redotech/redo-web/button";
import { Flex } from "@redotech/redo-web/flex";
import { LoadingRedoAnimation } from "@redotech/redo-web/loading-redo-animation";
import { Header, Text } from "@redotech/redo-web/text";
import { memo, useState } from "react";
import { getConversationTimeline } from "../../client/conversations";
import { getCustomerEvents } from "../../client/customer";
import { CustomerActivityCard } from "../../customers/customer-detail/customer-activity/cards/customer-activity-card";
import * as customerActivityCss from "../../customers/customer-detail/customer-activity/customer-activity.module.css";
import { ActivityFilterDrawer } from "./activity-filter-drawer";
import { ConversationActivityCard } from "./conversation-activity/conversation-activity-card";

export enum EventType {
  CONVERSATION = "conversation",
  CUSTOMER = "customer",
}

interface ConversationEventWrapper {
  type: EventType.CONVERSATION;
  event: ConversationTimelineEvent;
}

interface CustomerEventWrapper {
  type: EventType.CUSTOMER;
  event: CustomerEvent;
}

type CombinedEvent = CustomerEventWrapper | ConversationEventWrapper;

export interface ConversationTimelineProps {
  conversationActivityLoad: LoadState<CombinedEvent[]>;
  showCustomerEvents: boolean;
  showConversationEvents: boolean;
}

enum ActivityPanelContentState {
  LOADING = 0,
  HAS_EVENTS = 1,
  NO_EVENTS = 2,
}

export const ActivityPanelContent = memo(
  function ConversationActivityPanelContent({
    conversationActivityLoad,
    showCustomerEvents,
    showConversationEvents,
  }: ConversationTimelineProps) {
    function getActivityPanelContentState(): ActivityPanelContentState {
      if (conversationActivityLoad.pending) {
        return ActivityPanelContentState.LOADING;
      } else if (
        conversationActivityLoad.value &&
        conversationActivityLoad.value.length > 0 &&
        conversationActivityLoad.value.some(
          (event) =>
            (event.type === EventType.CUSTOMER && showCustomerEvents) ||
            (event.type === EventType.CONVERSATION && showConversationEvents),
        )
      ) {
        return ActivityPanelContentState.HAS_EVENTS;
      } else {
        return ActivityPanelContentState.NO_EVENTS;
      }
    }

    function getActivityPanelContent() {
      switch (getActivityPanelContentState()) {
        case ActivityPanelContentState.LOADING:
          return <LoadingRedoAnimation />;
        case ActivityPanelContentState.HAS_EVENTS:
          return conversationActivityLoad.value?.map((eventObject, i) => {
            if (eventObject.type === EventType.CUSTOMER && showCustomerEvents) {
              return (
                <CustomerActivityCard
                  customerActivity={eventObject.event}
                  key={eventObject.event._id.toString()}
                />
              );
            } else if (
              eventObject.type === EventType.CONVERSATION &&
              showConversationEvents
            ) {
              return (
                <ConversationActivityCard
                  conversationActivity={eventObject.event}
                  key={i}
                />
              );
            } else {
              return null;
            }
          });
        case ActivityPanelContentState.NO_EVENTS:
          return (
            <Flex dir="column" gap="xs" pt="3xl">
              <Text
                className={customerActivityCss.emptyStateText}
                fontSize="md"
              >
                No activity to display
              </Text>
              <Text
                className={customerActivityCss.emptyStateText}
                fontSize="sm"
                textColor="tertiary"
              >
                No activities match your current filters
              </Text>
            </Flex>
          );
      }
    }

    return (
      <Flex
        className={customerActivityCss.contentContainer}
        dir="column"
        gap="xl"
        pl="3xl"
        pr="3xl"
        pt="xl"
      >
        {getActivityPanelContent()}
      </Flex>
    );
  },
);

export const ActivityPanel = memo(function ConversationActivityPanel({
  conversationId,
  customerId,
  headerText = "Activity",
}: {
  conversationId: string;
  customerId?: string;
  headerText?: string;
}) {
  const client = useRequiredContext(RedoMerchantClientContext);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [customerActivityFilterOptions, setCustomerActivityFilterOptions] =
    useState<CustomerActivityFilterOptions | undefined>(undefined);
  const [showCustomerEvents, setShowCustomerEvents] = useState(true);
  const [showConversationEvents, setShowConversationEvents] = useState(true);

  const conversationActivityLoad = useLoad(
    async (signal): Promise<CombinedEvent[]> => {
      const conversationActivity = await getConversationTimeline(client, {
        conversationId,
        signal,
      });
      const typedConversationActivity: CombinedEvent[] =
        conversationActivity.map((entry) => ({
          event: { ...entry, timestamp: new Date(entry.timestamp) },
          type: EventType.CONVERSATION,
        }));
      let typedCustomerActivity: CombinedEvent[] = [];
      if (customerId) {
        const customerActivity = await getCustomerEvents(client, {
          customerId,
          filterOptions: customerActivityFilterOptions,
          signal,
        });
        typedCustomerActivity = customerActivity.customerEvents.map(
          (event) => ({ event, type: EventType.CUSTOMER }),
        );
      }

      const combinedActivity = [
        ...typedCustomerActivity,
        ...typedConversationActivity,
      ];
      return combinedActivity.sort(
        (a, b) => b.event.timestamp.getTime() - a.event.timestamp.getTime(),
      );
    },
    [conversationId, customerActivityFilterOptions, customerId],
  );

  const onFilterDrawerClose = (
    customerActivityFilterOptions: CustomerActivityFilterOptions,
    showCustomer: boolean,
    showConversation: boolean,
  ) => {
    setFilterDrawerOpen(false);
    setCustomerActivityFilterOptions(customerActivityFilterOptions);
    setShowCustomerEvents(showCustomer);
    setShowConversationEvents(showConversation);
  };

  return (
    <>
      <Flex
        className={customerActivityCss.panelContainer}
        dir="column"
        gap="none"
      >
        <Flex
          className={customerActivityCss.headerContainer}
          justify="space-between"
          pb="2xl"
          pl="3xl"
          pr="3xl"
          pt="3xl"
        >
          <Header fontFamily="inherit" fontSize="xxs">
            {headerText}
          </Header>
          <IconButton onClick={() => setFilterDrawerOpen(true)}>
            <FilterFunnelIcon />
          </IconButton>
        </Flex>
        <ActivityPanelContent
          conversationActivityLoad={conversationActivityLoad}
          showConversationEvents={showConversationEvents}
          showCustomerEvents={showCustomerEvents}
        />
      </Flex>
      <ActivityFilterDrawer
        initialFilterOptions={customerActivityFilterOptions}
        initialShowConversationEvents={showConversationEvents}
        initialShowCustomerEvents={showCustomerEvents}
        onClose={onFilterDrawerClose}
        open={filterDrawerOpen}
      />
    </>
  );
});
