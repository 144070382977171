import type { UniqueIdentifier } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";

import { assertNever } from "@redotech/util/type";
import {
  TreeFolderItem,
  TreeItemType,
  type FlattenedItem,
  type TreeItem,
} from "./types";

function getDragDepth(offset: number, indentationWidth: number) {
  return Math.round(offset / indentationWidth);
}

export function getProjection(
  items: FlattenedItem[],
  activeId: UniqueIdentifier,
  overId: UniqueIdentifier,
  dragOffset: number,
  indentationWidth: number,
) {
  const activeItemIndex = items.findIndex(({ id }) => id === activeId);
  const activeItem = items[activeItemIndex];

  const dragDepth = getDragDepth(dragOffset, indentationWidth);
  const overItemIndex = items.findIndex(({ id }) => id === overId);
  const newItems = arrayMove(items, activeItemIndex, overItemIndex);
  const previousItem = newItems[overItemIndex - 1];
  const projectedDepth = activeItem.depth + dragDepth;

  if (activeItem.type === TreeItemType.Folder) {
    return { depth: 0, maxDepth: 0, minDepth: 0, parentId: null };
  } else if (!previousItem) {
    return { depth: 0, maxDepth: 0, minDepth: 0, parentId: null };
  } else if (
    previousItem.type === TreeItemType.View &&
    previousItem.depth === 0
  ) {
    return { depth: 0, maxDepth: 0, minDepth: 0, parentId: null };
  }

  const maxDepth = 1;
  const depth = Math.min(projectedDepth, maxDepth);

  return { depth, maxDepth, minDepth: 0, parentId: getParentId() };

  function getParentId() {
    if (depth === 0 || !previousItem) {
      return null;
    }

    if (depth === previousItem.depth) {
      return previousItem.parentId;
    }

    if (depth > previousItem.depth) {
      return previousItem.id;
    }

    const newParent = newItems
      .slice(0, overItemIndex)
      .reverse()
      .find((item) => item.depth === depth)?.parentId;

    return newParent ?? null;
  }
}

function flatten(
  items: TreeItem[],
  parentId: UniqueIdentifier | null = null,
  depth = 0,
): FlattenedItem[] {
  return items.reduce<FlattenedItem[]>((acc, item, index) => {
    const children = item.type === TreeItemType.View ? [] : item.children;
    return [
      ...acc,
      { ...item, parentId, depth, index },
      ...flatten(children, item.id, depth + 1),
    ];
  }, []);
}

export function flattenTree(items: TreeItem[]): FlattenedItem[] {
  return flatten(items);
}

export function buildTree(flattenedItems: FlattenedItem[]): TreeItem[] {
  const root: TreeFolderItem = {
    id: "root",
    name: "root",
    friendlyName: "root",
    type: TreeItemType.Folder,
    children: [],
  };
  const nodes: Record<string, TreeItem> = { [root.id]: root };
  const items = flattenedItems.map((item) => ({ ...item, children: [] }));

  for (const item of items) {
    const { id, children } = item;
    const parentId = item.parentId ?? root.id;
    const parent = nodes[parentId] ?? findItem(items, parentId);

    let newItem: TreeItem;

    switch (item.type) {
      case TreeItemType.Folder:
        newItem = {
          id,
          children,
          name: item.name,
          friendlyName: item.friendlyName,
          type: item.type,
        };
        break;
      case TreeItemType.View:
        newItem = {
          id,
          name: item.name,
          friendlyName: item.friendlyName,
          type: item.type,
          actionHref: item.actionHref,
        };
        break;
      default:
        assertNever(item);
    }

    nodes[id] = newItem;

    if (parent.type !== TreeItemType.View) {
      parent.children.push(newItem);
    }
  }

  return root.children;
}

export function findItem(items: TreeItem[], itemId: UniqueIdentifier) {
  return items.find(({ id }) => id === itemId);
}

export function removeChildrenOf(
  items: FlattenedItem[],
  ids: UniqueIdentifier[],
) {
  const excludeParentIds = [...ids];

  return items.filter((item) => {
    if (item.parentId && excludeParentIds.includes(item.parentId)) {
      if (item.type === TreeItemType.Folder && item.children.length) {
        excludeParentIds.push(item.id);
      }
      return false;
    }

    return true;
  });
}
