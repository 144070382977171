import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { useLoad } from "@redotech/react-util/load";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { MerchantAppTopic } from "@redotech/redo-merchant-app-common/events/merchant-app-event-server";
import { MerchantAppEventServerContext } from "@redotech/redo-merchant-app-common/events/merchant-app-event-server-provider";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { TeamCount } from "@redotech/redo-model/connected-team";
import {
  ConnectedTeamData,
  TeamDataContext,
  TeamSwitcherData,
} from "@redotech/redo-web/arbiter-components/navigation/common/redo-nav-team-item";
import { memo, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CurrentTeamData } from "../../../../web/src/arbiter-components/navigation/common/redo-nav-team-item";
import { getConnectedStores } from "../../../login/src/client/auth";
import { TeamSwitcherLoginModal } from "../../../login/src/team-switcher-login-modal";

export const TeamSwitcherService = memo(function TeamSwitcherService({
  children,
}: {
  children: React.ReactNode;
}) {
  const team = useRequiredContext(TeamContext);
  const [addStoreModalOpen, setAddStoreModalOpen] = useState(false);

  const client = useRequiredContext(RedoMerchantClientContext);
  const eventServer = useRequiredContext(MerchantAppEventServerContext);
  const [connectedTeamCounts, setConnectedTeamCounts] = useState<
    Record<string, number>
  >({});
  const navigate = useNavigate();

  const connectedStores = useLoad(
    async (signal) => {
      if (team) {
        return await getConnectedStores(client, { signal });
      }
      return [];
    },
    [team],
  );

  const updateConnectedTeamCounts = useHandler(
    (newTeamCount: TeamCount | null) => {
      if (newTeamCount) {
        setConnectedTeamCounts((oldConnectedTeamCounts) => {
          return {
            ...oldConnectedTeamCounts,
            [newTeamCount.teamId]: newTeamCount.count,
          };
        });
      }
    },
  );

  useEffect(() => {
    if ((connectedStores.value?.length || 0) > 0) {
      const unlistenCallback = eventServer.subscribeAndCallOnce({
        topic: MerchantAppTopic.CONNECTED_TEAMS_COUNTS,
        callback: (value) => updateConnectedTeamCounts(value),
      });
      return () => unlistenCallback();
    }
    return;
  }, [connectedStores.value, updateConnectedTeamCounts, eventServer]);

  const handleTeamSwitch = useHandler((teamId: string) => {
    if (teamId === team._id) {
      return;
    }
    navigate(`/stores/${teamId}`);
    navigate(0);
  });

  const countTotal = Object.values(connectedTeamCounts).reduce(
    (sum: number, count: number) => sum + count,
    0,
  );

  const data: TeamSwitcherData = useMemo(() => {
    const currentTeamData: CurrentTeamData = {
      teamId: team._id,
      name: team.name,
      logoUrl: team.settings.brandKit?.images.logoUrl,
    };

    const connectedTeamData: ConnectedTeamData[] = (
      connectedStores.value || []
    ).map((connectedStore) => {
      return {
        teamId: connectedStore.teamId,
        name: connectedStore.name,
        logoUrl: connectedStore.logoUrl,
        ticketCount: connectedTeamCounts[connectedStore.teamId] || 0,
      };
    });

    return {
      connectedStoresHaveTickets: countTotal > 0,
      connectedStores: connectedTeamData,
      setCurrentTeam: handleTeamSwitch,
      currentTeam: currentTeamData,
      addStore: () => setAddStoreModalOpen(true),
    };
  }, [
    countTotal,
    connectedStores.value,
    connectedTeamCounts,
    team,
    handleTeamSwitch,
  ]);

  return (
    <TeamDataContext.Provider value={data}>
      <>
        {children}
        <TeamSwitcherLoginModal
          open={addStoreModalOpen}
          setOpen={setAddStoreModalOpen}
        />
      </>
    </TeamDataContext.Provider>
  );
});
