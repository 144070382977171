import { z } from "zod";

export const markAsFulfilled = {
  input: z.object({
    fulfillmentGroupIds: z.array(z.string()),
    fulfillmentDate: z.date().nullish(),
    notifyMarketplace: z.boolean().nullish(),
    userId: z.string(),
  }),
  output: z.object({ batchId: z.string(), batchNumber: z.string() }),
};
