import { useRequiredContext } from "@redotech/react-util/context";
import { useTriggerLoad } from "@redotech/react-util/load";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { createContext, memo, ReactNode, useEffect, useState } from "react";
import { getReturnsFacets } from "../../client/return";

export interface OrdersReviewCount {
  returnsNeedingReview?: number;
  claimsNeedingReview?: number;
}

export const OrdersReviewCountContext = createContext<
  OrdersReviewCount | undefined
>(undefined);

export const OrdersReviewCountService = memo(function OrdersReviewCountService({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const client = useRequiredContext(RedoMerchantClientContext);

  const [returnsNeedingReview, setReturnsNeedingReview] = useState<
    number | undefined
  >(undefined);
  const [claimsNeedingReview, setClaimsNeedingReview] = useState<
    number | undefined
  >(undefined);

  const [_, fetchReturnsNeedingReviewCounts] = useTriggerLoad(
    async (signal) => {
      try {
        const returnsFacets = await getReturnsFacets(client, {
          type: "return",
          signal,
        });
        setReturnsNeedingReview(returnsFacets.byReturnFilterFacet.needs_review);

        const claimsFacets = await getReturnsFacets(client, {
          type: "claim",
          signal,
        });
        setClaimsNeedingReview(claimsFacets.byReturnFilterFacet.needs_review);
      } catch (error) {
        console.error("Error fetching returns/claims counts:", error);
        setReturnsNeedingReview(undefined);
        setClaimsNeedingReview(undefined);
      }
    },
  );

  useEffect(() => {
    let mounted = true;
    const fetchCounts = () => {
      if (!mounted) return;
      try {
        fetchReturnsNeedingReviewCounts();
      } catch (e: any) {
        if (e.name !== "AbortError") {
          console.error("Error fetching counts:", e);
        }
      }
    };
    fetchCounts();
    const intervalId = setInterval(fetchCounts, 60 * 1000);
    return () => {
      mounted = false;
      clearInterval(intervalId);
    };
  }, [client, fetchReturnsNeedingReviewCounts]);

  return (
    <OrdersReviewCountContext.Provider
      value={{ returnsNeedingReview, claimsNeedingReview }}
    >
      {children}
    </OrdersReviewCountContext.Provider>
  );
});
