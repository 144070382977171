import { useHandler } from "@redotech/react-util/hook";
import { InputMask } from "@redotech/react-util/input-mask";
import * as classNames from "classnames";
import { ForwardedRef, forwardRef, memo, useMemo, useState } from "react";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { RedoListItem } from "../list/redo-list";
import {
  RedoDropdownInputSize,
  RedoSingleSelectDropdownInput,
} from "../select-dropdown/redo-single-select-dropdown-input";
import { BaseRedoInputContainer } from "./base-redo-input-container";
import {
  BaseRedoInput,
  RedoInputSize,
  RedoInputState,
} from "./base-redo-text-input";
import { BaseRedoInputInfoTooltip } from "./base-redo-text-input-info-tooltip";
import { CountryCode, CountryDialingCode } from "./phone-number-country";
import * as redoPhoneNumberInputCss from "./redo-phone-number-input.module.css";

export interface RedoPhoneNumberInputProps {
  value: string;
  setValue(value: string): void;
  size?: RedoInputSize;
  state?: RedoInputState;
  label?: string;
  description?: string;
  infoTooltip?: string;
  className?: string;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  dangerousStyleThatShouldOnlyBeUsedForMerchantBranding?: React.CSSProperties;
  required?: boolean;
  name?: string;
}

export const RedoPhoneNumberInput = memo(
  forwardRef(function RedoPhoneNumberInput(
    {
      value,
      setValue,
      size = "sm",
      state = "default",
      label,
      description,
      infoTooltip,
      className,
      onFocus,
      onBlur,
      dangerousStyleThatShouldOnlyBeUsedForMerchantBranding,
      required,
      name,
    }: RedoPhoneNumberInputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) {
    const countryOptions: RedoListItem<CountryCode>[] = useMemo(() => {
      if (dangerousStyleThatShouldOnlyBeUsedForMerchantBranding) {
        const { fontSize, lineHeight, fontWeight, fontFamily, color } =
          dangerousStyleThatShouldOnlyBeUsedForMerchantBranding || {};
        const textStyle = {
          fontSize,
          lineHeight,
          fontWeight,
          fontFamily,
          color,
        };

        return Object.values(CountryCode).map<RedoListItem<CountryCode>>(
          (country) => ({
            id: country,
            type: "custom",
            render: <Text style={textStyle}>{country}</Text>,
            value: country,
            useWrapper: true,
          }),
        );
      } else {
        return Object.values(CountryCode).map<RedoListItem<CountryCode>>(
          (country) => ({
            id: country,
            type: "text",
            text: country,
            value: country,
          }),
        );
      }
    }, [dangerousStyleThatShouldOnlyBeUsedForMerchantBranding]);

    const [selectedCountry, setSelectedCountry] = useState<
      RedoListItem<CountryCode>
    >(countryOptions[0]);

    const handleSetSelectedCountry = useHandler(
      ({ item: country }: { item: RedoListItem<CountryCode> }) => {
        const oldPrefix = CountryDialingCode[selectedCountry.value];
        const newPrefix = CountryDialingCode[country.value];
        const replacedPrefix = value.replace(`+${oldPrefix}`, `+${newPrefix} `);
        setValue(replacedPrefix);
        setSelectedCountry(country);
      },
    );

    const mask = getPhoneInputMask(selectedCountry.value);

    return (
      <BaseRedoInputContainer
        className={classNames(
          className,
          redoPhoneNumberInputCss.phoneNumberInput,
        )}
        dangerousStyleThatShouldOnlyBeUsedForMerchantBranding={
          dangerousStyleThatShouldOnlyBeUsedForMerchantBranding
        }
        description={description}
        label={label}
        labelProps={{ htmlFor: "phone-input" }}
        size={size}
        state={state}
      >
        <Flex>
          <RedoSingleSelectDropdownInput
            dangerousStyleThatShouldOnlyBeUsedForMerchantBranding={
              dangerousStyleThatShouldOnlyBeUsedForMerchantBranding
            }
            disabled={state === "disabled"}
            fitToAnchor={false}
            hideBorder
            options={countryOptions}
            optionSelected={handleSetSelectedCountry}
            readonly={state === "readonly"}
            selectedItem={selectedCountry}
            size={redoInputSizeToDropdownSize[size]}
          />
        </Flex>
        <BaseRedoInput
          htmlId="phone-input"
          inputTypeHint="tel"
          mask={mask}
          name={name}
          onBlur={onBlur}
          onFocus={onFocus}
          placeholder={getPhonePlaceholder(selectedCountry.value)}
          ref={ref}
          required={required}
          setValue={setValue}
          size={size}
          state={state}
          value={value}
        />
        {infoTooltip && (
          <BaseRedoInputInfoTooltip
            infoTooltip={infoTooltip}
            size={size}
            state={state}
          />
        )}
      </BaseRedoInputContainer>
    );
  }),
);

function getPhoneInputMask(country: CountryCode): InputMask | undefined {
  if (country !== CountryCode.UNITED_STATES && country !== CountryCode.CANADA) {
    return undefined;
  }

  const code = CountryDialingCode[country];
  const mask = `+${code} (###) ###-####`;

  return {
    mask,
    replacementMap: { "#": /\d/ },
    pasteHelpers: [
      {
        expression: new RegExp(`^\\D*(\\+\\d{1,2}\\s)?((\\D*\\d){10})\\D*$`),
        extract: (regexMatches: string[]) =>
          regexMatches[2].match(/\d/g)?.join("") || "",
      },
    ],
  };
}

function getPhonePlaceholder(country: CountryCode): string {
  if (country !== CountryCode.UNITED_STATES && country !== CountryCode.CANADA) {
    return "Enter phone number...";
  }

  const code = CountryDialingCode[country];

  return `+${code} (555) 000-0000`;
}

const redoInputSizeToDropdownSize: Record<
  RedoInputSize,
  RedoDropdownInputSize
> = { ["sm"]: "xs", ["md"]: "sm", ["lg"]: "md" };
