import { PageFormat } from "@redotech/redo-model/outbound-labels";
import { z } from "zod";

export const printTest = {
  input: z.object({
    printerId: z.number(),
    pageFormat: z.nativeEnum(PageFormat),
    paper: z.string().optional(),
    rotate: z.union([
      z.literal(0),
      z.literal(90),
      z.literal(180),
      z.literal(270),
    ]),
    fit_to_page: z.boolean().optional(),
    dpi: z.string().optional(),
  }),
  output: z.object({}),
};

export type PrintTestInput = z.infer<typeof printTest.input>;
