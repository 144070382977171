import { useRequiredContext } from "@redotech/react-util/context";
import { useTriggerLoad } from "@redotech/react-util/load";
import TriangleDown from "@redotech/redo-web/arbiter-icon/triangle-down.svg";
import { SkeletonBlock } from "@redotech/redo-web/skeleton";

import { type NewItem, type Return } from "@redotech/redo-model/return";
import { alertOnFailure } from "@redotech/redo-web/alert";
import { RedoBadge } from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import TrashIcon from "@redotech/redo-web/arbiter-icon/trash-01.svg";

import { IconButton } from "@redotech/redo-web/button";

import { RedoMerchantRpcClientContext } from "@redotech/redo-merchant-app-common/rpc-client";
import {
  shopifyAdminProductUrl,
  shopifyStoreName,
} from "@redotech/redo-merchant-app-common/shopify";
import { RedoExternalLink } from "@redotech/redo-web/arbiter-components/links/redo-external-link";
import CircleSpinner from "@redotech/redo-web/circle-spinner.svg";
import { TeamContext } from "@redotech/redo-web/context/team-context";
import { CurrencyContext } from "@redotech/redo-web/currency";
import { ExpandableImage } from "@redotech/redo-web/expandable-image";
import { Flex } from "@redotech/redo-web/flex";
import { Icon } from "@redotech/redo-web/icon";
import { Text } from "@redotech/redo-web/text";
import * as classNames from "classnames";
import { memo, useContext, useEffect, useState } from "react";
import * as detailsCardCss from "./details-card.module.css";
import * as returnCss from "./details-card.module.css";

export const NewReturnItem = memo(function NewReturnItem({
  defaultItemExpanded,
  item,
  return: return_,
  reload,
  editable,
  deleteExchangeItem,
}: {
  defaultItemExpanded: boolean;
  item: NewItem;
  return: Return;
  reload: () => void;
  editable: boolean;
  deleteExchangeItem: (variantId: string) => Promise<boolean>;
}) {
  const [isExpanded, setIsExpanded] = useState(defaultItemExpanded);
  const [isClosing, setIsClosing] = useState(false);
  const [loading, setLoading] = useState(true);
  const team = useContext(TeamContext);
  const rpcClient = useRequiredContext(RedoMerchantRpcClientContext);
  const { formatCurrency } = useContext(CurrencyContext);

  useEffect(() => {
    if (item.images?.[0]) {
      const img = new Image();
      img.onload = () => setLoading(false);
      img.src = item.images[0];
    } else {
      setLoading(false);
    }
  }, [item.images]);

  const handleExpand = () => {
    if (isExpanded) {
      setIsClosing(true);
      setTimeout(() => {
        setIsExpanded(false);
        setIsClosing(false);
      }, 200);
    } else {
      setIsExpanded(true);
    }
  };

  const [removeItemLoad, doRemoveItem] = useTriggerLoad((signal) =>
    alertOnFailure("Failed to remove item")(async () => {
      if (!team || !item.variantId) {
        throw new Error("Missing team or variant ID");
      }
      let success = false;
      if (item.isVariantExchange) {
        const response = await rpcClient.removeVariantExchangeItem(
          {
            teamId: team._id,
            returnId: return_._id,
            variantId: item.variantId,
          },
          { signal },
        );
        success = response.success;
      } else {
        success = await deleteExchangeItem(item.variantId);
      }
      if (!success) {
        throw new Error("Failed to remove item");
      }
      reload();
    }),
  );

  return (
    <div className={returnCss.item}>
      <div className={returnCss.itemMain}>
        <div className={returnCss.itemHeader}>
          <div className={returnCss.expandButtonContainer}>
            <div className={returnCss.expandButton} onClick={handleExpand}>
              <TriangleDown
                className={classNames(returnCss.chevron, {
                  [returnCss.expanded]: isExpanded || isClosing,
                })}
              />
            </div>
            {loading ? (
              <SkeletonBlock />
            ) : (
              <ExpandableImage
                className={returnCss.itemImage}
                src={item.images?.[0] || ""}
              />
            )}
          </div>
          <div className={returnCss.product}>
            <div className={returnCss.itemTitle}>
              <div className={returnCss.nonPriceContainer}>
                {item?.shopifyVariant && team?.platform === "shopify" ? (
                  <RedoExternalLink
                    fontSize="lg"
                    showIcon={false}
                    text={item.title}
                    textClassName={classNames(
                      returnCss.itemLink,
                      returnCss.productTitle,
                    )}
                    url={
                      team &&
                      shopifyAdminProductUrl(
                        shopifyStoreName(team.storeUrl),
                        item.shopifyVariant.product_id.toString(),
                      ).toString()
                    }
                  />
                ) : (
                  <Text className={returnCss.productTitle}>{item.title}</Text>
                )}
                {!!item.outOfStock && (
                  <RedoBadge color="error" size="xs" text="Out of stock" />
                )}
              </div>
              <div className={returnCss.rightSide}>
                <div className={returnCss.itemPriceContainer}>
                  <Text className={returnCss.itemPriceText}>
                    {item.itemValue && item.price
                      ? formatCurrency(
                          Math.min(
                            parseFloat(item.itemValue),
                            parseFloat(item.price),
                          ),
                        )
                      : formatCurrency(item.itemValue || item.price || 0)}
                  </Text>
                  {editable && (
                    <div className={returnCss.deleteIconContainer}>
                      {removeItemLoad.pending ? (
                        <CircleSpinner />
                      ) : (
                        <IconButton
                          onClick={() => {
                            doRemoveItem();
                          }}
                        >
                          <Icon
                            arbiterIconSvg={TrashIcon}
                            className={returnCss.deleteIcon}
                            color="ghost"
                            style={{ height: 14, width: 14 }}
                          />
                        </IconButton>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={returnCss.itemSubtitle}>
              <Text className={returnCss.itemSubtitleText}>
                Qty: {item.quantity}
              </Text>
              {item?.variantTitle && (
                <Text className={returnCss.itemSubtitleText}>
                  {item.variantTitle}
                </Text>
              )}
              {item.shopifyVariant?.sku && (
                <Text className={returnCss.itemSubtitleText}>
                  {`sku: ${item.shopifyVariant.sku}`}
                </Text>
              )}
            </div>
          </div>
        </div>

        {(isExpanded || isClosing) && (
          <div
            className={classNames({
              [returnCss.hiddenValues]: isExpanded && !isClosing,
              [returnCss.hiddenValuesClosing]: isClosing,
            })}
          >
            {item.attributes && item.attributes.length > 0 && (
              <div>
                {item.attributes?.map((attribute, index) => (
                  <div className={returnCss.itemOptions} key={index}>
                    {attribute.key}: {attribute.value}
                  </div>
                ))}
              </div>
            )}
            <Flex
              className={classNames(detailsCardCss.itemDetailsContainer)}
              dir="column"
              gap="sm"
            >
              <div className={returnCss.itemSummary}>
                <Flex dir="column" gap="lg">
                  <Text fontSize="xs" fontWeight="semibold" textColor="primary">
                    Summary
                  </Text>
                  {!!item.price && (
                    <Flex
                      className={classNames(
                        returnCss.itemExtra,
                        returnCss.summaryElement,
                      )}
                    >
                      <Text fontSize="xs" fontWeight="thin">
                        {item.isVariantExchange
                          ? "Exchange value"
                          : "Retail Price"}
                      </Text>
                      <Text fontSize="xs" fontWeight="thin">
                        {formatCurrency(item.price || 0)}
                      </Text>
                    </Flex>
                  )}
                  {!!item.itemValue &&
                    !!item.price &&
                    item.itemValue < item.price && (
                      <Flex
                        className={classNames(
                          returnCss.itemExtra,
                          returnCss.summaryElement,
                        )}
                      >
                        <Text fontSize="xs" fontWeight="thin">
                          Discount
                        </Text>
                        <Text fontSize="xs" fontWeight="thin">
                          {formatCurrency(
                            parseFloat(item.itemValue) - parseFloat(item.price),
                          )}
                        </Text>
                      </Flex>
                    )}
                  <div className={returnCss.itemSummaryLine} />
                  <Flex
                    className={classNames(
                      returnCss.itemExtra,
                      returnCss.summaryElement,
                    )}
                  >
                    <Text fontSize="xs" fontWeight="regular">
                      Final Price
                    </Text>
                    <Text fontSize="xs" fontWeight="regular">
                      {item.itemValue && item.price
                        ? formatCurrency(
                            Math.min(
                              parseFloat(item.itemValue),
                              parseFloat(item.price),
                            ),
                          )
                        : formatCurrency(item.itemValue || item.price || 0)}
                    </Text>
                  </Flex>
                </Flex>
              </div>
            </Flex>
          </div>
        )}
      </div>
    </div>
  );
});
