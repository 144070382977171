import { Redirect } from "@redotech/react-router-util/redirect";
import { RouteObject } from "react-router-dom";
import { analyticsRoutes } from "../../../analytics/route";
import { summaryRoutes } from "../../../summary/route";
import { Page } from "../../page";

const ANALYTICS_REPORT_ROUTE = "analytics/reports";
const RETURN_CLAIMS_ROUTE = "analytics/returns-claims";

export function hidePadding(url: string) {
  const routes = [ANALYTICS_REPORT_ROUTE, RETURN_CLAIMS_ROUTE];
  return routes.some((route) => url.includes(route));
}

export const homeRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Home" },
    async lazy() {
      const { Page } = await import("../../page");
      return { element: <Page hidePadding={hidePadding} /> };
    },
    children: [
      { path: "analytics", children: analyticsRoutes },
      ...summaryRoutes,
    ],
  },
];

export const backwardsCompatibleHomeRoutes: RouteObject[] = [
  {
    path: "analytics",
    children: [
      {
        element: <Page />,
        children: [
          {
            index: true,
            element: (
              <Redirect match={/analytics/} replaceValue="home/analytics" />
            ),
          },
          {
            path: "*",
            element: (
              <Redirect match={/analytics/} replaceValue="home/analytics" />
            ),
          },
        ],
      },
    ],
  },
  {
    // Redirect old reports/* URLs to analytics/reports/*
    path: "reports",
    children: [
      {
        element: <Page />,
        children: [
          {
            index: true,
            element: (
              <Redirect
                match={/reports/}
                replaceValue="home/analytics/reports"
              />
            ),
          },
          {
            path: "*",
            element: (
              <Redirect
                match={/reports/}
                replaceValue="home/analytics/reports"
              />
            ),
          },
        ],
      },
    ],
  },
  {
    path: "summary",
    children: [
      {
        element: <Page />,
        children: [
          {
            index: true,
            element: <Redirect match={/summary/} replaceValue="home" />,
          },
          {
            path: "*",
            element: <Redirect match={/summary/} replaceValue="home" />,
          },
        ],
      },
    ],
  },
];
