import { createContext, memo, useContext, useMemo } from "react";
import { Flex } from "../../../flex";
import { Text } from "../../../text";
import { Tooltip } from "../../../tooltip/tooltip";
import {
  AvatarStatus,
  RedoAvatar,
  RedoAvatarProps,
  StatusDotColor,
} from "../../avatar/redo-avatar";
import * as navigationCss from "../redo-navigation.module.css";

export interface NavigationUserProfile {
  name: string;
  teamName: string;
  status?: StatusDotColor;
  profileImageUrl: string | null;
  profileDropdownOpen: boolean;
  setProfileDropdownOpen: (open: boolean) => void;
  setDropdownAnchor: (anchor: HTMLElement | null) => void;
}

export const UserProfileContext = createContext<
  NavigationUserProfile | undefined
>(undefined);

export const UserProfile = memo(function UserProfile({
  collapsed,
}: {
  collapsed: boolean;
}) {
  const userProfile = useContext(UserProfileContext);

  const avatar: RedoAvatarProps | undefined = useMemo(() => {
    if (!userProfile) {
      return undefined;
    }

    const { name, profileImageUrl, status } = userProfile;

    const avatarStatus: AvatarStatus | undefined = status
      ? { type: "presence", color: status }
      : undefined;

    return {
      content: {
        type: "image",
        imageUrl: profileImageUrl,
        altText: name,
        backup: { type: "text", text: name },
      },
      avatarStatus,
      size: "sm",
    };
  }, [userProfile]);

  if (!userProfile || !avatar) {
    return null;
  }

  const {
    name,
    teamName,
    profileDropdownOpen,
    setProfileDropdownOpen,
    setDropdownAnchor,
  } = userProfile;

  if (!collapsed) {
    return (
      <Flex px="xl" py="xl">
        <Flex
          as="button"
          className={navigationCss.navProfile}
          gap="md"
          grow={1}
          onClick={() => setProfileDropdownOpen(!profileDropdownOpen)}
          px="md"
          py="sm"
          radius="sm"
          ref={setDropdownAnchor}
        >
          <RedoAvatar {...avatar} />
          <Flex dir="column" gap="none">
            <Text fontSize="xs" fontWeight="regular" textColor="primary">
              {name}
            </Text>

            <Text fontSize="xs" fontWeight="regular" textColor="tertiary">
              {teamName}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    );
  } else {
    return (
      <Tooltip darkMode placement="right" title={name}>
        <button
          className={navigationCss.userProfileItem}
          onClick={() => setProfileDropdownOpen(!profileDropdownOpen)}
          ref={setDropdownAnchor}
        >
          <RedoAvatar {...avatar} />
        </button>
      </Tooltip>
    );
  }
});
