import { GoogleAdsConnectionSchema } from "@redotech/redo-model/integration/google-ads/google-ads-definition";
import { z } from "zod";

export const saveConnectionsToGoogleAds = {
  input: z.object({
    customerId: z.string(),
    connections: z.array(GoogleAdsConnectionSchema),
  }),
  output: z.object({ success: z.boolean() }),
};
