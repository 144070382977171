import { RedoMerchantClient } from "@redotech/redo-merchant-app-common/client";
import { Order, OrdersFacets } from "@redotech/redo-model/order";
import { AxiosHeaders } from "axios";

export interface Address {
  street1: string;
  street2: string;
  state: string;
  city: string;
  country: string;
  zip: string;
  firstName?: string;
  lastName?: string;
}

export interface ContactInfo {
  name: string;
  email: string;
  phone: string;
}

/**
 * GET /orders
 */
export async function getOrders(
  client: RedoMerchantClient,
  {
    status = "all",
    protected: protected_,
    pageSize,
    signal,
    search,
    sort,
    orderNumber,
    dateRange,
    hasLabels,
    nonCancelled,
    printedLabels,
    shipmentStatus,
    email,
    pageContinue,
  }: {
    protected?: boolean;
    status?: string;
    pageSize: number;
    sort: { key: string; direction: string } | undefined;
    signal?: AbortSignal;
    search?: string;
    orderNumber?: string;
    dateRange?: string;
    hasLabels?: string;
    nonCancelled?: string;
    printedLabels?: string;
    shipmentStatus?: string;
    email?: string;
    pageContinue?: string;
  },
): Promise<{ data: Order[]; pageNext: string | undefined }> {
  const response = await client.client.get("orders", {
    headers: {
      ...client.authorization(),
      "X-Page-Continue": pageContinue,
      "X-Page-Size": pageSize,
    },
    params: {
      status,
      protected: protected_,
      search,
      orderNumber,
      shipmentStatus,
      sort: search ? undefined : `${sort?.key}:${sort?.direction}`,
      hasLabels,
      printedLabels,
      dateRange,
      nonCancelled,
      email,
    },
    signal,
  });
  return {
    data: response.data,
    pageNext:
      <string>(<AxiosHeaders>response.headers).get("X-Page-Next") || undefined,
  };
}

/**
 * GET /orders/facets
 */
export async function getOrderFacets(
  client: RedoMerchantClient,
  {
    protected: protected_,
    status = "all",
    signal,
    search,
    shipmentStatus,
    dateRange,
  }: {
    protected?: boolean;
    status?: string;
    signal?: AbortSignal;
    search?: string;
    shipmentStatus?: string;
    dateRange?: string;
  },
): Promise<OrdersFacets> {
  const response = await client.client.get(`orders/facets`, {
    headers: client.authorization(),
    params: {
      search,
      status,
      protected: protected_,
      dateRange,
      shipmentStatus,
    },
    signal,
  });
  return response.data;
}

/**
 * GET /orders/:orderId
 */
export async function getOrder(
  client: RedoMerchantClient,
  orderId: string,
  signal?: AbortSignal,
): Promise<Order> {
  const response = await client.client.get(
    `orders/${encodeURIComponent(orderId)}`,
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * POST /orders/tracker-sample
 */
export async function getSampleOrderWithTracker(
  client: RedoMerchantClient,
  signal?: AbortSignal,
): Promise<Order> {
  const response = await client.client.get(`orders/tracker-sample`, {
    headers: client.authorization(),
    signal,
  });
  return response.data;
}

/**
 * POST /orders/:orderId/duplicate
 */
export async function duplicateOrder(
  client: RedoMerchantClient,
  { orderId, signal }: { orderId: string; signal?: AbortSignal },
): Promise<{ draftOrderUrl: string }> {
  const response = await client.client.post(
    `orders/${encodeURIComponent(orderId)}/duplicate`,
    {},
    { headers: client.authorization(), signal },
  );
  return response.data;
}
