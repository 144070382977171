// Optional protocol, domain + subdomains, top-level domain, optional path + query
const linkRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;

/**
 * Sanitizes a URL by removing invisible characters.
 */
export function sanitizeUrl(url: string): string {
  return url
    .replaceAll(/%E2%80%8B/g, "") // URL-encoded zero-width space
    .replaceAll(/%EF%BB%BF/g, ""); // URL-encoded byte order mark
}

export function isValidLink(link: string): boolean {
  return linkRegex.test(link.toLowerCase());
}

export function ensureProtocolPresent(link: string): string {
  if (!/^https?:\/\//i.test(link)) {
    return `https://${link}`;
  }
  return link;
}
