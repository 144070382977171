import { useHover } from "@redotech/react-util/hover";
import EditIcon from "@redotech/redo-web/arbiter-icon/edit-02.svg";
import TriangleDown from "@redotech/redo-web/arbiter-icon/triangle-down_filled.svg";
import * as classNames from "classnames";
import { memo, useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import DotsReorderIcon from "../../../../arbiter-icon/dots-reorder.svg";
import { Flex } from "../../../../flex";
import { Text } from "../../../../text";
import { OverflowTooltip } from "../../../../tooltip/overflow-tooltip";
import { RedoButton } from "../../../buttons/redo-button";
import { RedoIcon } from "../../../icon/redo-icon";
import * as navigationCss from "../../redo-navigation.module.css";
import * as folderItemCss from "./tree-item.module.css";

export type RedoFolderItemProps = {
  friendlyName: string;
  dragHandleProps: object;
  onToggleExpanded: () => void;
  onRequestEdit: () => void;
  childrenLinks: string[];
  expanded: boolean;
  clone?: boolean;
};

export const RedoFolderItem = memo(function RedoFolderItem({
  friendlyName,
  dragHandleProps,
  onToggleExpanded,
  onRequestEdit,
  childrenLinks,
  expanded,
  clone,
}: RedoFolderItemProps) {
  const [componentRef, setComponentRef] = useState<HTMLDivElement | null>(null);
  const [overflowRef, setOverflowRef] = useState<HTMLDivElement | null>(null);

  const location = useLocation();
  const matchedPath = childrenLinks.find((path) =>
    matchPath({ path }, location.pathname),
  );

  const componentHovered = useHover(componentRef);

  useEffect(() => {
    if (matchedPath && !expanded) {
      onToggleExpanded();
    }
    // I only want to run this once, on render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      as="button"
      className={classNames(navigationCss.navItem, folderItemCss.folderItem)}
      justify="space-between"
      onClick={(e) => {
        e.preventDefault();
        onToggleExpanded();
      }}
      onContextMenu={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onRequestEdit();
      }}
      px="md"
      py="sm"
      radius="sm"
      ref={setComponentRef}
      w="full"
      {...dragHandleProps}
    >
      <OverflowTooltip
        overflowRef={clone ? null : overflowRef}
        tooltipProps={{ title: friendlyName, darkMode: true }}
        wrapperStyle={{
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Text
          fontSize="xs"
          overflow="hidden"
          ref={setOverflowRef}
          textColor="secondary"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {friendlyName}
        </Text>
      </OverflowTooltip>
      <Flex
        align="center"
        className={classNames(
          componentHovered && navigationCss.show,
          navigationCss.dragHandle,
        )}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <RedoIcon color="tertiary" Icon={DotsReorderIcon} size={16} />
      </Flex>
      <Flex align="center" gap="none">
        {componentHovered && (
          <RedoButton
            hierarchy="tertiary"
            IconLeading={EditIcon}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onRequestEdit();
            }}
            size="xs"
            theme="normal"
          />
        )}
        <RedoIcon
          className={classNames(
            navigationCss.dropdownIcon,
            expanded && navigationCss.active,
          )}
          color="tertiary"
          Icon={TriangleDown}
          size={16}
        />
      </Flex>
    </Flex>
  );
});
