import { Carriers } from "@redotech/redo-model/fulfillments/fulfillment-carriers-and-services";
import { z } from "zod";

export const createEodManifestForAll = {
  input: z.object({
    carrier: z.nativeEnum(Carriers),
    closeAllOn: z.date(),
    addressName: z.string(),
  }),
  output: z.object({ success: z.boolean() }),
};
