import {
  getEndOfDayWithTimezone,
  getEndOfMonthWithTimezone,
  getEndOfWeekWithTimezone,
  getEndOfYearWithTimezone,
  getStartOfDayWithTimezone,
  getStartOfMonthWithTimezone,
  getStartOfWeekWithTimezone,
  getStartOfYearWithTimezone,
} from "@redotech/util/date";
import { assertNever, Tuple } from "@redotech/util/type";
import { z } from "zod";
import {
  AdvancedFilterType,
  createAdvancedFilterDataSchema,
  GenericFilterBuilder,
} from "./generic-advanced-filter-data";

export enum DateFilterOperator {
  WITHIN = "within",
  BEFORE = "before",
  AFTER = "after",
}
const DateFilterOperatorSchema = z.nativeEnum(DateFilterOperator);

export enum KnownDateFilterTimeFrame {
  TODAY = "today",
  THIS_WEEK = "this week",
  LAST_WEEK = "last week",
  THIS_MONTH = "this month",
  LAST_MONTH = "last month",
  THIS_YEAR = "this year",
  LAST_YEAR = "last year",
  CUSTOM = "custom",
}
const KnownDateFilterTimeFrameSchema = z.nativeEnum(KnownDateFilterTimeFrame);

const TwoDateTupleSchema = z.tuple([z.date(), z.date()]);
const OneDateTupleSchema = z.tuple([z.date()]);
export type TwoDateTuple = Tuple<Date, 2>;
export type OneDateTuple = Tuple<Date, 1>;

const CustomDateSchema = z.union([OneDateTupleSchema, TwoDateTupleSchema]);
export type CustomDate = z.infer<typeof CustomDateSchema>;

export const DateFilterDataSchema = createAdvancedFilterDataSchema(
  AdvancedFilterType.DATE,
  KnownDateFilterTimeFrameSchema,
  DateFilterOperatorSchema,
).extend({ customDate: CustomDateSchema.nullish() });
export type DateFilterData = z.infer<typeof DateFilterDataSchema>;

export const DateFilterBuilder: GenericFilterBuilder<
  KnownDateFilterTimeFrame,
  DateFilterOperator,
  DateFilterData
> = {
  type: AdvancedFilterType.DATE,
  valueSchema: KnownDateFilterTimeFrameSchema,
  operatorSchema: DateFilterOperatorSchema,
  schema: DateFilterDataSchema,
  buildAtlasSearchQuery({ filter, atlasPath, searchCompound, timeZoneId }) {
    const { operator, value, customDate } = filter;
    if (!value) {
      return searchCompound;
    }

    if (operator === DateFilterOperator.WITHIN) {
      const dates = getWithinDatesForDateFilter(value, customDate, timeZoneId);
      if (dates) {
        searchCompound.filter.push({
          range: { path: atlasPath, gte: dates.start, lte: dates.end },
        });
      }
    } else if (operator === DateFilterOperator.BEFORE) {
      const date = getBeforeDateForDateFilter(value, customDate, timeZoneId);
      if (date) {
        searchCompound.filter.push({ range: { path: atlasPath, lt: date } });
      }
    } else if (operator === DateFilterOperator.AFTER) {
      const date = getAfterDateForDateFilter(value, customDate, timeZoneId);
      if (date) {
        searchCompound.filter.push({ range: { path: atlasPath, gt: date } });
      }
    }
    return searchCompound;
  },
  readFromString(name, operatorValueEncoded, defaultFilter) {
    try {
      const decoded = decodeURIComponent(operatorValueEncoded);
      const operatorValueDate = decoded.split("::");

      const operator = operatorValueDate?.[0];
      const value = operatorValueDate?.[1];
      const customDateString = operatorValueDate?.[2];

      if (!operator) {
        return defaultFilter;
      }

      const parsedOperator = DateFilterOperatorSchema.safeParse(operator);
      if (!parsedOperator.success) {
        console.warn("Invalid operator type");
        return undefined;
      }

      if (!value) {
        return {
          type: AdvancedFilterType.DATE,
          name,
          value: null,
          operator: parsedOperator.data,
          customDate: undefined,
        };
      }

      const timeframeParsed = KnownDateFilterTimeFrameSchema.safeParse(
        value.trim(),
      );

      if (!timeframeParsed.success) {
        console.warn("Invalid value type for DateFilter");
        return undefined;
      }

      const parsedValue = timeframeParsed.data;

      let parsedCustomDate = undefined;
      if (parsedValue === KnownDateFilterTimeFrame.CUSTOM) {
        try {
          parsedCustomDate = CustomDateSchema.parse(
            ((JSON.parse(customDateString ?? "") as string[]) || []).map(
              (d) => new Date(d),
            ),
          );
        } catch (error) {
          return {
            type: AdvancedFilterType.DATE,
            name,
            value: parsedValue,
            operator: parsedOperator.data,
            customDate: undefined,
          };
        }
      }

      return {
        type: AdvancedFilterType.DATE,
        name,
        value: parsedValue,
        operator: parsedOperator.data,
        customDate:
          parsedValue === KnownDateFilterTimeFrame.CUSTOM
            ? parsedCustomDate
            : undefined,
      };
    } catch (error) {
      console.error("Error parsing DateFilter", error);
      return undefined;
    }
  },
  writeToString(filter) {
    try {
      if (!filter) {
        return "";
      }
      const { operator, value, customDate } = filter;
      const parsedValue = KnownDateFilterTimeFrameSchema.safeParse(value);

      if (parsedValue.success) {
        let stringValue = `${operator}::${parsedValue.data}`;

        if (
          parsedValue.data === KnownDateFilterTimeFrame.CUSTOM &&
          customDate
        ) {
          stringValue = `${operator}::${parsedValue.data}::${JSON.stringify(customDate)}`;
        }

        return stringValue;
      } else {
        return `${operator}::`;
      }
    } catch (error) {
      console.error("Error writing DateFilter", error);
      return "";
    }
  },
  isPartial(filter) {
    return (
      !filter.value ||
      !filter.operator ||
      (filter.value === KnownDateFilterTimeFrame.CUSTOM &&
        filter.customDate == undefined)
    );
  },
};

export function getWithinDatesForDateFilter(
  timeFrame: KnownDateFilterTimeFrame,
  customDate: CustomDate | undefined | null,
  timeZoneId?: string,
): { start: Date; end: Date } {
  const tz = timeZoneId || Temporal.Now.timeZoneId();

  if (timeFrame === KnownDateFilterTimeFrame.CUSTOM) {
    if (!customDate) {
      throw new Error(
        "Custom date was specified, but no custom date was provided",
      );
    }
    if (customDate.length !== 2) {
      throw new Error(
        "Custom date was specified, but provided custom date is a date range",
      );
    }
    return { start: customDate[0], end: customDate[1] };
  }

  const now = Temporal.Now.zonedDateTimeISO(tz).toPlainDate();

  switch (timeFrame) {
    case KnownDateFilterTimeFrame.TODAY: {
      return {
        start: getStartOfDayWithTimezone(now, tz),
        end: getEndOfDayWithTimezone(now, tz),
      };
    }
    case KnownDateFilterTimeFrame.THIS_WEEK: {
      return {
        start: getStartOfWeekWithTimezone(now, tz),
        end: getEndOfWeekWithTimezone(now, tz),
      };
    }
    case KnownDateFilterTimeFrame.LAST_WEEK: {
      const oneWeekAgo = Temporal.Now.zonedDateTimeISO(tz)
        .subtract({ weeks: 1 })
        .toPlainDate();

      return {
        start: getStartOfWeekWithTimezone(oneWeekAgo, tz),
        end: getEndOfWeekWithTimezone(oneWeekAgo, tz),
      };
    }
    case KnownDateFilterTimeFrame.THIS_MONTH: {
      return {
        start: getStartOfMonthWithTimezone(now, tz),
        end: getEndOfDayWithTimezone(now, tz),
      };
    }
    case KnownDateFilterTimeFrame.LAST_MONTH: {
      const oneMonthAgo = Temporal.Now.zonedDateTimeISO(tz)
        .subtract({ months: 1 })
        .toPlainDate();

      return {
        start: getStartOfMonthWithTimezone(oneMonthAgo, tz),
        end: getEndOfMonthWithTimezone(oneMonthAgo, tz),
      };
    }
    case KnownDateFilterTimeFrame.THIS_YEAR: {
      return {
        start: getStartOfYearWithTimezone(now, tz),
        end: getEndOfYearWithTimezone(now, tz),
      };
    }
    case KnownDateFilterTimeFrame.LAST_YEAR: {
      const oneYearAgo = Temporal.Now.zonedDateTimeISO(tz)
        .subtract({ years: 1 })
        .toPlainDate();

      return {
        start: getStartOfYearWithTimezone(oneYearAgo, tz),
        end: getEndOfYearWithTimezone(oneYearAgo, tz),
      };
    }
    default:
      return assertNever(timeFrame);
  }
}

export function getAfterDateForDateFilter(
  timeFrame: KnownDateFilterTimeFrame,
  customDate: CustomDate | undefined | null,
  timeZoneId?: string,
): Date {
  const tz = timeZoneId || Temporal.Now.timeZoneId();

  if (timeFrame === KnownDateFilterTimeFrame.CUSTOM) {
    if (!customDate) {
      throw new Error(
        "Custom date was specified, but no custom date was provided",
      );
    }
    if (customDate.length === 2) {
      throw new Error(
        "Single custom date was specified, but provided custom date in date range",
      );
    }
    return customDate[0];
  }

  const now = Temporal.Now.zonedDateTimeISO(tz).toPlainDate();
  switch (timeFrame) {
    case KnownDateFilterTimeFrame.TODAY: {
      return getEndOfDayWithTimezone(now, tz);
    }
    case KnownDateFilterTimeFrame.THIS_WEEK: {
      return getEndOfWeekWithTimezone(now, tz);
    }
    case KnownDateFilterTimeFrame.LAST_WEEK: {
      const oneWeekAgo = Temporal.Now.zonedDateTimeISO(tz)
        .subtract({ weeks: 1 })
        .toPlainDate();
      return getEndOfWeekWithTimezone(oneWeekAgo, tz);
    }
    case KnownDateFilterTimeFrame.THIS_MONTH: {
      return getEndOfMonthWithTimezone(now, tz);
    }
    case KnownDateFilterTimeFrame.LAST_MONTH: {
      const oneMonthAgo = Temporal.Now.zonedDateTimeISO(tz)
        .subtract({ months: 1 })
        .toPlainDate();
      return getEndOfMonthWithTimezone(oneMonthAgo, tz);
    }
    case KnownDateFilterTimeFrame.THIS_YEAR: {
      return getEndOfYearWithTimezone(now, tz);
    }
    case KnownDateFilterTimeFrame.LAST_YEAR: {
      const oneYearAgo = Temporal.Now.zonedDateTimeISO(tz)
        .subtract({ years: 1 })
        .toPlainDate();
      return getEndOfYearWithTimezone(oneYearAgo, tz);
    }
    default:
      return assertNever(timeFrame);
  }
}

export function getBeforeDateForDateFilter(
  timeFrame: KnownDateFilterTimeFrame,
  customDate: CustomDate | undefined | null,
  timeZoneId?: string,
): Date {
  const tz = timeZoneId || Temporal.Now.timeZoneId();

  if (timeFrame === KnownDateFilterTimeFrame.CUSTOM) {
    if (!customDate) {
      throw new Error(
        "Custom date was specified, but no custom date was provided",
      );
    }
    if (customDate.length === 2) {
      throw new Error(
        "Single custom date was specified, but provided custom date in date range",
      );
    }
    return customDate[0];
  }
  const now = Temporal.Now.zonedDateTimeISO(tz).toPlainDate();

  switch (timeFrame) {
    case KnownDateFilterTimeFrame.TODAY: {
      return getStartOfDayWithTimezone(now, tz);
    }
    case KnownDateFilterTimeFrame.THIS_WEEK: {
      return getStartOfWeekWithTimezone(now, tz);
    }
    case KnownDateFilterTimeFrame.LAST_WEEK: {
      const oneWeekAgo = Temporal.Now.zonedDateTimeISO(tz)
        .subtract({ weeks: 1 })
        .toPlainDate();
      return getStartOfWeekWithTimezone(oneWeekAgo, tz);
    }
    case KnownDateFilterTimeFrame.THIS_MONTH: {
      return getStartOfMonthWithTimezone(now, tz);
    }
    case KnownDateFilterTimeFrame.LAST_MONTH: {
      const oneMonthAgo = Temporal.Now.zonedDateTimeISO(tz)
        .subtract({ months: 1 })
        .toPlainDate();
      return getStartOfMonthWithTimezone(oneMonthAgo, tz);
    }
    case KnownDateFilterTimeFrame.THIS_YEAR: {
      return getStartOfYearWithTimezone(now, tz);
    }
    case KnownDateFilterTimeFrame.LAST_YEAR: {
      const oneYearAgo = Temporal.Now.zonedDateTimeISO(tz)
        .subtract({ years: 1 })
        .toPlainDate();
      return getStartOfYearWithTimezone(oneYearAgo, tz);
    }
    default:
      return assertNever(timeFrame);
  }
}
