export enum FulfillmentGroupStatus {
  Cancelled = "CANCELLED",
  Closed = "CLOSED",
  Incomplete = "INCOMPLETE",
  Open = "OPEN",
  OnHold = "ON_HOLD",
  Scheduled = "SCHEDULED",
  Pending = "PENDING",
  Voided = "VOIDED",
  PaymentPending = "PAYMENT_PENDING",
  Merged = "MERGED",
  Unmerged = "UNMERGED",
  Invalid = "INVALID",
  ReadyForPickup = "READY_FOR_PICKUP",
  ThirdPartyInProgress = "THIRD_PARTY_IN_PROGRESS",
}

// these should never be shown to the user
export const HiddenStatuses = [
  FulfillmentGroupStatus.Merged,
  FulfillmentGroupStatus.Unmerged,
  FulfillmentGroupStatus.Invalid,
];

export const OpenLikeStatuses = [
  FulfillmentGroupStatus.Open,
  FulfillmentGroupStatus.OnHold,
  FulfillmentGroupStatus.PaymentPending,
  FulfillmentGroupStatus.Scheduled,
  FulfillmentGroupStatus.ReadyForPickup,
];

export function canPurchaseLabelStatus(
  status: FulfillmentGroupStatus | null | undefined,
): boolean {
  if (!status) {
    return false;
  }
  return [
    FulfillmentGroupStatus.Open,
    FulfillmentGroupStatus.PaymentPending,
  ].includes(status);
}

export function isDoneStatus(
  status: FulfillmentGroupStatus | null | undefined,
): boolean {
  if (!status) {
    return false;
  }
  return [
    FulfillmentGroupStatus.Closed,
    FulfillmentGroupStatus.Cancelled,
    FulfillmentGroupStatus.Voided,
    FulfillmentGroupStatus.Merged,
    FulfillmentGroupStatus.Unmerged,
    FulfillmentGroupStatus.ThirdPartyInProgress,
  ].includes(status);
}

export function canSplitStatus(
  status: FulfillmentGroupStatus | null | undefined,
): boolean {
  if (!status) {
    return false;
  }
  return [
    FulfillmentGroupStatus.Open,
    FulfillmentGroupStatus.OnHold,
    FulfillmentGroupStatus.PaymentPending,
    FulfillmentGroupStatus.Scheduled,
  ].includes(status);
}
