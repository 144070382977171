import {
  ExpandedConversation,
  getConversationStatus,
} from "@redotech/redo-model/conversation";
import { RedoSupportChannelBadge } from "@redotech/redo-web/support/redo-support-channel-badge";
import { Text } from "@redotech/redo-web/text";
import { ConversationStatusPill } from "../../common/conversation-status-pill";
import * as mergeSuggestionSummaryModalCss from "./merge-suggestion-summary-modal.module.css";

export const MergeSuggestionCarousel = ({
  conversation,
  showSubject,
}: {
  conversation: ExpandedConversation;
  showSubject?: boolean;
}) => {
  return (
    <>
      {showSubject && (
        <Text fontSize="md" fontWeight="medium">
          {conversation.subject}
        </Text>
      )}
      <div className={mergeSuggestionSummaryModalCss.pillContainer}>
        <ConversationStatusPill
          conversationStatus={getConversationStatus(conversation)}
        />
        <RedoSupportChannelBadge platform={conversation.platform} />
      </div>
      <Text
        className={mergeSuggestionSummaryModalCss.conversationPreview}
        textOverflow="ellipsis"
      >
        {conversation.messages[0].content}
      </Text>
    </>
  );
};
