import { Redirect } from "@redotech/react-router-util/redirect";
import { loginRoutes } from "@redotech/redo-merchant-app-login/route";
import { ErrorPage } from "@redotech/redo-web/error-page";
import { useEffect } from "react";
import { Navigate, RouteObject, useLocation } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { conversionRoutes } from "./app/navigation/routing/conversion-routes";
import {
  backwardsCompatibleHomeRoutes,
  homeRoutes,
} from "./app/navigation/routing/home-routes";
import {
  backwardsCompatibleMarketingRoutes,
  marketingRoutes,
} from "./app/navigation/routing/marketing-routes";
import {
  backwardsCompatibleOrderManagementRoutes,
  orderManagementRoutes,
} from "./app/navigation/routing/order-management-routes";
import { settingRoutes } from "./app/navigation/routing/settings-routes";
import {
  backwardsCompatibleSupportRoutes,
  supportRoutes,
} from "./app/navigation/routing/support-routes";
import { approvalRoutes } from "./approvals/route";
import { gorgiasRoutes } from "./gorgias/route";
import { riseRoutes } from "./rise.ai/route";
import { RouterRoot } from "./root";
import { shopifyRoutes } from "./shopify/route";
import "./styles.css";

function MerchantAppErrorBoundary() {
  const intercom = useIntercom();

  const location = useLocation();

  useEffect(() => {
    console.error("Route not found at location", location.pathname);
  }, [location]);

  return <ErrorPage showNewIntercomMessage={() => intercom.showNewMessage()} />;
}

const children = [
  { index: true, element: <Navigate to="home" /> },
  { path: "home", children: homeRoutes },
  ...backwardsCompatibleHomeRoutes,

  { path: "support", children: supportRoutes },
  ...backwardsCompatibleSupportRoutes,

  { path: "marketing", children: marketingRoutes },
  ...backwardsCompatibleMarketingRoutes,

  { path: "conversion", children: conversionRoutes },

  { path: "order-management", children: orderManagementRoutes },
  ...backwardsCompatibleOrderManagementRoutes,
  { path: "order-management/approvals", children: approvalRoutes },

  { path: "settings", children: settingRoutes },

  { path: "login", children: loginRoutes },

  { path: "shopify", children: shopifyRoutes },
  {
    path: "gorgias/*",
    element: <Redirect match={/gorgias/} replaceValue="support/gorgias" />,
  },
  { path: "gorgias", children: gorgiasRoutes },
  { path: "rise-ai", children: riseRoutes },
];

export const routes: RouteObject[] = [
  {
    path: "stores/:teamId/",
    Component: RouterRoot,
    children,
    errorElement: <MerchantAppErrorBoundary />,
  },
  {
    path: "/",
    Component: RouterRoot,
    errorElement: <MerchantAppErrorBoundary />,
    children,
  },
];
