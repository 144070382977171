import { useHandler } from "@redotech/react-util/hook";
import { useEffect, useState } from "react";

interface UseDebouncedHoverProps {
  delay: number;
  onDebounceComplete: (id: string) => void;
}

export function useDebouncedHover({
  delay,
  onDebounceComplete,
}: UseDebouncedHoverProps) {
  const [hoverTimeoutId, setHoverTimeoutId] = useState<number | null>(null);
  const [hoverTimeoutItemId, setHoverTimeoutItemId] = useState<string | null>(
    null,
  );

  const resetHoverState = useHandler(() => {
    if (hoverTimeoutId !== null) {
      window.clearTimeout(hoverTimeoutId);
      setHoverTimeoutId(null);
      setHoverTimeoutItemId(null);
    }
  });

  const handleItemHover = useHandler((id: string | null) => {
    // Clear existing timeout if we're moving to a different item
    if (hoverTimeoutId !== null && hoverTimeoutItemId !== id) {
      resetHoverState();
    }

    // If there's no ID or we already have a timeout for this item, do nothing
    if (!id || hoverTimeoutItemId === id) {
      return;
    }

    // Set a new timeout
    const timeoutId = window.setTimeout(() => {
      onDebounceComplete(id);
      resetHoverState();
    }, delay);

    setHoverTimeoutId(timeoutId);
    setHoverTimeoutItemId(id);
  });

  // Clean up on unmount
  useEffect(() => {
    return resetHoverState;
  }, [resetHoverState]);

  return { hoverTimeoutItemId, handleItemHover, resetHoverState };
}
