import { z } from "zod";
import { ReturnTypeEnum } from "../../../return";
import { AnalyticsFilterType } from "./analytics-filter-type";
import { makeReportFilterSchema } from "./base-report-filter";

export enum ReturnTypeFilterQuery {
  IS_ANY = "isAny",
  IS_NONE = "isNone",
}

export const ReturnTypeFilterSchema = makeReportFilterSchema(
  AnalyticsFilterType.RETURN_TYPE,
  ReturnTypeFilterQuery,
  z.array(z.nativeEnum(ReturnTypeEnum)),
);

export type ReturnTypeFilter = z.infer<typeof ReturnTypeFilterSchema>;
