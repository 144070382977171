import { useRequiredContext } from "@redotech/react-util/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { UserContext } from "@redotech/redo-merchant-app-common/user";
import { RegistrationRewardType } from "@redotech/redo-model/extended-warranty";
import {
  SettingItem,
  SettingsSection,
} from "@redotech/redo-web/arbiter-components/navigation/redo-settings-nav";
import { filterTruthy } from "@redotech/util/array";
import { FAQ_HOME_ROUTE } from "../../../setting/faq/route";

export function useSettingsItems(): SettingsSection[] | null {
  const generalSettings = useGeneralSettings();
  const aiSettings = useAiSettings();
  const checkoutOptimizationSettings = useCheckoutOptimizationSettings();
  const customersSettings = useCustomersSettings();
  const marketingSettings = useMarketingSettings();
  const returnsSettings = useReturnsSettings();
  const warrantiesSettings = useWarrantiesSettings();
  const reviewsSettings = useReviewsSettings();
  const fulfillmentSettings = useFulfillmentSettings();
  const shippingSettings = useShippingSettings();
  const supportSettings = useSupportSettings();

  const user = useRequiredContext(UserContext);

  const isAudienUser = user.roles.includes("audien-support");

  if (isAudienUser) {
    return null;
  }

  const allSettingsItems: SettingsSection[] = filterTruthy([
    generalSettings,
    aiSettings,
    checkoutOptimizationSettings,
    customersSettings,
    marketingSettings,
    returnsSettings,
    warrantiesSettings,
    reviewsSettings,
    fulfillmentSettings,
    shippingSettings,
    supportSettings,
  ]);

  return allSettingsItems;
}

function useAiSettings(): SettingsSection | undefined {
  const team = useRequiredContext(TeamContext);

  const urlPrefix = `/stores/${team._id}`;

  const items: SettingItem[] = [
    {
      id: "ai-general",
      href: `${urlPrefix}/settings/ai/general`,
      friendlyName: "General",
    },
    {
      id: "ai-knowledge",
      href: `${urlPrefix}/settings/ai/knowledge`,
      friendlyName: "Knowledge",
    },
    {
      id: "ai-data-sources",
      href: `${urlPrefix}/settings/ai/product-meta-fields`,
      friendlyName: "Product meta fields",
    },
    {
      id: "ai-skills",
      href: `${urlPrefix}/settings/ai/skills`,
      friendlyName: "Skills",
    },
    {
      id: "ai-support",
      href: `${urlPrefix}/settings/ai/support`,
      friendlyName: "Support",
    },
  ];

  return { name: "AI", items: items };
}

function useCheckoutOptimizationSettings(): SettingsSection | undefined {
  const team = useRequiredContext(TeamContext);

  const urlPrefix = `/stores/${team._id}`;

  const items: SettingItem[] = [
    {
      id: "checkout-optimization-delivery-estimate",
      href: `${urlPrefix}/settings/checkout-optimization/delivery-estimate`,
      friendlyName: "Delivery estimate",
    },
  ];

  return { name: "Checkout optimization", items: items };
}

function useCustomersSettings(): SettingsSection | undefined {
  const team = useRequiredContext(TeamContext);

  const urlPrefix = `/stores/${team._id}`;

  const customerAccountsEnabled = team.settings.customerAccounts?.enabled;

  if (!customerAccountsEnabled) {
    return undefined;
  }

  const items: SettingItem[] = [
    {
      id: "customer-accounts-ad-blocks",
      href: `${urlPrefix}/settings/customer-accounts-ad-blocks`,
      friendlyName: "Ad blocks",
    },
    {
      id: "customer-accounts-integrations",
      href: `${urlPrefix}/settings/customer-accounts-integrations`,
      friendlyName: "Integrations",
    },
    {
      id: "customer-accounts-storefront-display",
      href: `${urlPrefix}/settings/customer-accounts-storefront-display`,
      friendlyName: "Storefront display",
    },
  ];

  return { name: "Customer accounts", items: items };
}

function useFulfillmentSettings(): SettingsSection | undefined {
  const team = useRequiredContext(TeamContext);

  const urlPrefix = `/stores/${team._id}`;

  const outboundLabelsEnabled = team.settings.outboundLabels?.newOmsEnabled;

  if (!outboundLabelsEnabled) {
    return undefined;
  }

  const items: SettingItem[] = [
    {
      id: "general-oms",
      href: `${urlPrefix}/settings/oms/general`,
      friendlyName: "General",
    },
    {
      id: "automerge-link",
      href: `${urlPrefix}/settings/oms/automerge-shipments`,
      friendlyName: "Auto-merge shipments",
    },
    {
      id: "automations-oms",
      href: `${urlPrefix}/settings/oms/automations`,
      friendlyName: "Automations",
    },
    {
      id: "balance-link",
      href: `${urlPrefix}/settings/oms/balance`,
      friendlyName: "Balance",
    },
    {
      id: "parcels-link",
      href: `${urlPrefix}/settings/oms/packages`,
      friendlyName: "Packages",
    },
    {
      id: "presets-link",
      href: `${urlPrefix}/settings/oms/presets`,
      friendlyName: "Presets",
    },
    {
      id: "international",
      href: `${urlPrefix}/settings/oms/international`,
      friendlyName: "International",
    },
  ];

  return { name: "Order fulfillment", items: items };
}

function useGeneralSettings(): SettingsSection | undefined {
  const team = useRequiredContext(TeamContext);

  const urlPrefix = `/stores/${team._id}`;

  const conciergeEnabled = team.settings.support?.ai?.enabled;
  const customerAccountsEnabled = team.settings.customerAccounts?.enabled;
  const supportEnabled = !!team.settings.support?.enabled;

  const items: SettingItem[] = filterTruthy([
    {
      id: "billing-button",
      href: `${urlPrefix}/settings/billing`,
      friendlyName: "Billing",
    },
    {
      id: "brand-kit-button",
      href: `${urlPrefix}/settings/brand-kit`,
      friendlyName: "Brand kit",
    },
    {
      id: "communication-button",
      href: `${urlPrefix}/settings/communication`,
      friendlyName: "Communication",
    },
    {
      id: "customer-portal-button",
      href: `${urlPrefix}/settings/customer-portal`,
      friendlyName: "Customer portal",
    },
    (supportEnabled || customerAccountsEnabled || conciergeEnabled) && {
      id: "customer-widget-button",
      href: `${urlPrefix}/settings/customer-widget`,
      friendlyName: "Customer widget",
    },
    {
      id: "developer-button",
      href: `${urlPrefix}/settings/developer`,
      friendlyName: "Developer",
    },
    {
      id: "invoices-button",
      href: `${urlPrefix}/settings/invoices`,
      friendlyName: "Invoices",
    },
    {
      id: "notifications-button",
      href: `${urlPrefix}/settings/notifications`,
      friendlyName: "Notifications",
    },
    {
      id: "theme-button",
      href: `${urlPrefix}/settings/theme`,
      friendlyName: "Theme",
    },
    {
      id: "users-button",
      href: `${urlPrefix}/settings/users`,
      friendlyName: "Users",
    },
  ]);

  return { name: "General", items: items };
}

function useMarketingSettings(): SettingsSection | undefined {
  const team = useRequiredContext(TeamContext);

  const urlPrefix = `/stores/${team._id}`;

  const returnTrackingEnabled = team.settings.returnTracking?.enabled;
  const marketingEnabled = team.settings.marketing?.enabled;
  const orderTrackingEnabled = team.settings.orderTracking?.enabled;

  if (!returnTrackingEnabled && !marketingEnabled && !orderTrackingEnabled) {
    return undefined;
  }

  const items: SettingItem[] = [
    {
      id: "email-domain",
      href: `${urlPrefix}/settings/email-domain`,
      friendlyName: "Email domain",
    },
    {
      id: "marketing-sms",
      href: `${urlPrefix}/settings/sms-marketing`,
      friendlyName: "SMS",
    },
    {
      id: "marketing-integrations",
      href: `${urlPrefix}/settings/marketing-integrations`,
      friendlyName: "Integrations",
    },
  ];

  return { name: "Marketing", items: items };
}

function useReturnsSettings(): SettingsSection | undefined {
  const team = useRequiredContext(TeamContext);

  const urlPrefix = `/stores/${team._id}`;

  const bundlesEnabled = team.platform === "shopify";
  const packageProtectionEnabled = team.settings.packageProtection?.enabled;
  const returnsEnabled = team.settings.returns?.enabled;

  const items: SettingItem[] = filterTruthy([
    {
      id: "automations-button",
      href: `${urlPrefix}/settings/automations`,
      friendlyName: "Automations",
    },
    bundlesEnabled && {
      id: "bundles",
      href: `${urlPrefix}/settings/bundles`,
      friendlyName: "Bundles",
    },
    packageProtectionEnabled && {
      id: "claim-flow-button",
      href: `${urlPrefix}/settings/claim-flow`,
      friendlyName: "Claim flow",
    },
    {
      id: "coverage-button",
      href: `${urlPrefix}/settings/coverage`,
      friendlyName: "Coverage",
    },
    {
      id: "exchanges-button",
      href: `${urlPrefix}/settings/exchanges`,
      friendlyName: "Exchanges",
    },
    {
      id: "integrations",
      href: `${urlPrefix}/settings/integrations`,
      friendlyName: "Integrations",
    },
    returnsEnabled && {
      id: "return-flow-button",
      href: `${urlPrefix}/settings/return-flow`,
      friendlyName: "Return flow",
    },
    {
      id: "shipping-button",
      href: `${urlPrefix}/settings/shipping`,
      friendlyName: "Shipping",
    },
    {
      id: "returns-ui",
      href: `${urlPrefix}/settings/returns-ui`,
      friendlyName: "UI",
    },
    {
      id: "info-modal",
      href: `${urlPrefix}/settings/info-modal`,
      friendlyName: "Info Modal",
    },
  ]);

  return { name: "Returns & claims", items: items };
}

function useWarrantiesSettings(): SettingsSection | undefined {
  const team = useRequiredContext(TeamContext);
  const warrantiesEnabled = team.settings.warranties?.enabled;

  const registrationRewardsEnabled =
    team.settings.warranties?.registrationRewardType !==
    RegistrationRewardType.DISABLED;

  const urlPrefix = `/stores/${team._id}/settings`;

  if (!warrantiesEnabled) {
    return undefined;
  }

  const items: SettingItem[] = filterTruthy([
    {
      id: "warranty-flow-button",
      href: `${urlPrefix}/warranty-flow`,
      friendlyName: "Warranty flow",
    },
    registrationRewardsEnabled && {
      id: "warranty-registration-button",
      href: `${urlPrefix}/registration-rewards`,
      friendlyName: "Registration rewards",
    },
  ]);

  return { name: "Warranties", items: items };
}

function useReviewsSettings(): SettingsSection | undefined {
  const team = useRequiredContext(TeamContext);

  const urlPrefix = `/stores/${team._id}`;

  const reviewsEnabled = team.settings.reviews?.enabled;

  if (!reviewsEnabled) {
    return undefined;
  }

  const items: SettingItem[] = [
    // {
    //   id: "reviews-general-button",
    //   href: `${urlPrefix}/settings/reviews/reviews-general`,
    //   friendlyName: "General",
    // },
    {
      id: "reviews-displays-widgets-button",
      href: `${urlPrefix}/settings/reviews/reviews-displays-widgets`,
      friendlyName: "Displays & widgets",
    },
    {
      id: "reviews-response-button",
      href: `${urlPrefix}/settings/reviews/reviews-response`,
      friendlyName: "Response",
    },
    // {
    //   id: "reviews-emails-button",
    //   href: `${urlPrefix}/settings/reviews/reviews-emails`,
    //   friendlyName: "Emails",
    // },
  ];

  return { name: "Reviews", items: items };
}

function useShippingSettings(): SettingsSection | undefined {
  const team = useRequiredContext(TeamContext);

  const urlPrefix = `/stores/${team._id}`;

  const items: SettingItem[] = [
    {
      id: "carriers-link",
      href: `${urlPrefix}/settings/carriers`,
      friendlyName: "Carriers",
    },
    {
      id: "end-shippers-link",
      href: `${urlPrefix}/settings/end-shippers`,
      friendlyName: "End shippers",
    },
  ];

  return { name: "Shipping", items: items };
}

function useSupportSettings(): SettingsSection | undefined {
  const team = useRequiredContext(TeamContext);

  const urlPrefix = `/stores/${team._id}`;

  const supportEnabled = !!team.settings.support?.enabled;

  if (!supportEnabled) {
    return undefined;
  }

  const items: SettingItem[] = [
    {
      id: "support-general",
      href: `${urlPrefix}/settings/general`,
      friendlyName: "General",
    },
    {
      id: "support-channels",
      href: `${urlPrefix}/settings/channels`,
      friendlyName: "Channels",
    },
    {
      id: "support-chat-automations",
      href: `${urlPrefix}/settings/chat-flow`,
      friendlyName: "Chat automations",
    },
    {
      id: "support-customer-satisfaction",
      href: `${urlPrefix}/settings/customer-satisfaction`,
      friendlyName: "Customer satisfaction",
    },
    {
      id: "support-faq",
      href: `${urlPrefix}/settings/${FAQ_HOME_ROUTE}`,
      friendlyName: "FAQs",
    },
    {
      id: "support-rules",
      href: `${urlPrefix}/settings/rules`,
      friendlyName: "Rules",
    },
  ];

  return { name: "Support", items: items };
}
