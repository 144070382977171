import {
  DependencyList,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";

export function useMaxAvailableHeight({
  areaRef,
  contentBelowRef,
  deps,
}: {
  areaRef: React.RefObject<HTMLDivElement>;
  contentBelowRef: React.RefObject<HTMLDivElement> | undefined;
  deps?: DependencyList;
}) {
  const [availableHeight, setAvailableHeight] = useState(0);
  const [prevContentHeight, setPrevContentHeight] = useState(0);

  const calculateAvailableHeight = useCallback(() => {
    const distFromTop = areaRef.current?.getBoundingClientRect().top || 0;
    const contentBelowHeight = contentBelowRef?.current?.offsetHeight || 0;
    const newAvailableHeight =
      window.innerHeight - distFromTop - contentBelowHeight;

    setAvailableHeight(newAvailableHeight);
    setPrevContentHeight(contentBelowHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaRef, contentBelowRef, deps]);

  useEffect(() => {
    calculateAvailableHeight();

    const handleResize = () => {
      calculateAvailableHeight();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [calculateAvailableHeight]);

  useLayoutEffect(() => {
    const contentBelowHeight = contentBelowRef?.current?.offsetHeight || 0;

    if (contentBelowHeight !== prevContentHeight) {
      calculateAvailableHeight();
    }
  });

  return availableHeight;
}
