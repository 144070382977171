export enum TimeUnit {
  YEAR = "year",
  MONTH = "month",
  WEEK = "week",
  DAY = "day",
  HOUR = "hour",
  MINUTE = "minute",
  SECOND = "second",
  MILLISECOND = "millisecond",
}

export function getTimeUnitName(unit: TimeUnit, value: number) {
  switch (unit) {
    case TimeUnit.YEAR:
      return value === 1 ? "year" : "years";
    case TimeUnit.MONTH:
      return value === 1 ? "month" : "months";
    case TimeUnit.WEEK:
      return value === 1 ? "week" : "weeks";
    case TimeUnit.DAY:
      return value === 1 ? "day" : "days";
    case TimeUnit.HOUR:
      return value === 1 ? "hour" : "hours";
    case TimeUnit.MINUTE:
      return value === 1 ? "minute" : "minutes";
    case TimeUnit.SECOND:
      return value === 1 ? "second" : "seconds";
    case TimeUnit.MILLISECOND:
      return value === 1 ? "millisecond" : "milliseconds";
  }
}
