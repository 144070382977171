import { useInput } from "@redotech/react-util/form";
import { RedoMerchantClient } from "@redotech/redo-merchant-app-common/client";
import { alertOnFailure } from "@redotech/redo-web/alert";
import { RedoBadge } from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import { RedoCheckbox } from "@redotech/redo-web/arbiter-components/checkbox/redo-checkbox";
import { RedoModal } from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import CornerUpRightSvg from "@redotech/redo-web/arbiter-icon/corner-up-right_filled.svg";
import Edit02Svg from "@redotech/redo-web/arbiter-icon/edit-02.svg";
import { Flex } from "@redotech/redo-web/flex";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { TextInput } from "@redotech/redo-web/text-input";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import { groupInput, input } from "@redotech/ui/form";
import { emailValidator } from "@redotech/web-util/form";
import { memo, useState } from "react";
import { sendMessageDraft } from "../../../client/conversations";

const ForwardMessageBadge = memo(function AddInternalNoteBadge({
  enabled,
  email,
  content,
  onEdit,
}: {
  enabled: boolean;
  email: string;
  content: string;
  onEdit?: () => void;
}) {
  if (!enabled) {
    return null;
  }
  return (
    <Tooltip title={`Forward message to ${email}:\n\n${content}`}>
      <div>
        <RedoBadge
          color="gray"
          iconTrailing={
            onEdit
              ? { type: "icon", Icon: Edit02Svg, onClick: onEdit }
              : undefined
          }
          segmentLeading={{ type: "icon", Icon: CornerUpRightSvg }}
          text={`Forward to ${email}`}
        />
      </div>
    </Tooltip>
  );
});

const ForwardMessageEditOptions = memo(function ForwardMessageEditOptions({
  enabled,
  setEnabled,
  content,
  setContent,
  email,
  setEmail,
}: {
  enabled: boolean;
  setEnabled?: (enabled: boolean) => void;
  content: string;
  setContent: (content: string) => void;
  email: string;
  setEmail: (email: string) => void;
}) {
  return (
    <>
      {setEnabled && (
        <RedoCheckbox
          description="When this template is used, this forwards the message to the email address specified below."
          label="Forward message when used"
          setValue={(value) => setEnabled(value && value !== "indeterminate")}
          size="sm"
          value={enabled}
        />
      )}
      {enabled && (
        <Flex dir="column" gap="sm">
          <LabeledInput label="Forward Email">
            <TextInput
              onChange={setEmail}
              placeholder="Email address to forward the message to"
              value={email}
            />
          </LabeledInput>
          <LabeledInput label="Preface">
            <TextInput
              onChange={setContent}
              placeholder="Add a preface to the top of the forwarded message"
              value={content}
            />
          </LabeledInput>
        </Flex>
      )}
    </>
  );
});

export const ForwardMessageAutomationEditMode = memo(
  function ForwardMessageAutomationEditMode({
    content,
    setContent,
    email,
    setEmail,
    enabled,
    setEnabled,
  }: {
    content: string;
    setContent: (content: string) => void;
    email: string;
    setEmail: (email: string) => void;
    enabled: boolean;
    setEnabled: (enabled: boolean) => void;
  }) {
    return (
      <ForwardMessageEditOptions
        content={content}
        email={email}
        enabled={enabled}
        setContent={setContent}
        setEmail={setEmail}
        setEnabled={setEnabled}
      />
    );
  },
);

export const ForwardMessageAutomationPreviewMode = memo(
  function ForwardMessageAutomationPreviewMode({
    content,
    email,
    enabled,
  }: {
    content: string;
    email: string;
    enabled: boolean;
  }) {
    return (
      <ForwardMessageBadge content={content} email={email} enabled={enabled} />
    );
  },
);

export const ForwardMessagePendingOnMessageTag = memo(
  function ForwardMessagePendingOnMessageTag({
    content,
    setContent,
    email,
    setEmail,
    shouldForwardMessage,
    setShouldForwardMessage,
  }: {
    content: string;
    setContent: (content: string) => void;
    email: string;
    setEmail: (email: string) => void;
    shouldForwardMessage: boolean;
    setShouldForwardMessage: (shouldForwardMessage: boolean) => void;
  }) {
    const [showEditModal, setShowEditModal] = useState(false);

    const inputGroup = groupInput({
      email: input<string>({ validator: emailValidator }),
      content: input<string>(),
    });

    const forwardMessageInputs = useInput(inputGroup, { email, content });

    const handleSetNoteToAddContent = () => {
      if (forwardMessageInputs.allErrors.length === 0) {
        setEmail(forwardMessageInputs.inputs.email.value.toLowerCase());
        setContent(forwardMessageInputs.inputs.content.value);
        setShowEditModal(false);
      }
    };

    const handleSetShouldForwardMessage = (value: boolean) => {
      setShouldForwardMessage(value);
      setShowEditModal(false);
    };

    return (
      <>
        {shouldForwardMessage && (
          <ForwardMessageBadge
            content={content}
            email={email}
            enabled={shouldForwardMessage}
            onEdit={() => setShowEditModal(true)}
          />
        )}
        {showEditModal && (
          <RedoModal
            onModalCloseRequested={() => setShowEditModal(false)}
            primaryButton={{
              text: "Save",
              onClick: handleSetNoteToAddContent,
              disabled: forwardMessageInputs.allErrors.length > 0,
            }}
            secondaryButton={{
              text: "Remove",
              onClick: () => handleSetShouldForwardMessage(false),
            }}
            title="Edit note to add"
          >
            <ForwardMessageEditOptions
              content={forwardMessageInputs.inputs.content.value}
              email={forwardMessageInputs.inputs.email.value}
              enabled
              setContent={forwardMessageInputs.inputs.content.setValue}
              setEmail={forwardMessageInputs.inputs.email.setValue}
              setEnabled={undefined}
            />
          </RedoModal>
        )}
      </>
    );
  },
);

export const PerformForwardMessageAutomation = async ({
  client,
  conversationId,
  shouldForwardMessage,
  messageToForwardMessageId,
}: {
  client: RedoMerchantClient;
  conversationId: string;
  shouldForwardMessage: boolean;
  messageToForwardMessageId: string;
}) => {
  await alertOnFailure("Failed to forward email")(async () => {
    if (shouldForwardMessage) {
      await sendMessageDraft(client, {
        conversationId: conversationId,
        redoDraftMessageId: messageToForwardMessageId,
      });
    }
  });
};
