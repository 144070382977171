// extracted by mini-css-extract-plugin
export var arrows = "_4m-5";
export var decrementer = "_4m-9";
export var disabled = "_4m-3";
export var error = "_4m-1";
export var hiddenText = "_4m-a";
export var incrementer = "_4m-8";
export var input = "_4m-7";
export var inputWrapper = "_4m-0";
export var readonly = "_4m-2";
export var regular = "_4m-6";
export var small = "_4m-4";