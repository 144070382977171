// extracted by mini-css-extract-plugin
export var chip = "_1t-9";
export var chipLabel = "_1t-a";
export var close = "_1t-7";
export var closeIcon = "_1t-8";
export var error = "_1t-6";
export var infoTooltip = "_1t-2";
export var innerContainer = "_1t-1";
export var input = "_1t-b";
export var medium = "_1t-5";
export var outerContainer = "_1t-0";
export var small = "_1t-4";
export var xSmall = "_1t-3";