export enum CountryCode {
  UNITED_STATES = "US",
  CANADA = "CA",
  ABKHAZIA = "AB",
  AFGHANISTAN = "AF",
  ALAND = "AX",
  ALBANIA = "AL",
  ALGERIA = "DZ",
  AMERICAN_SAMOA = "AS",
  ANDORRA = "AD",
  ANGOLA = "AO",
  ANGUILLA = "AI",
  ANTIGUA_AND_BARBUDA = "AG",
  ARGENTINA = "AR",
  ARMENIA = "AM",
  ARUBA = "AW",
  ASCENSION = "AC",
  AUSTRALIA = "AU",
  AUSTRIA = "AT",
  AZERBAIJAN = "AZ",
  BAHAMAS = "BS",
  BAHRAIN = "BH",
  BANGLADESH = "BD",
  BARBADOS = "BB",
  BELARUS = "BY",
  BELGIUM = "BE",
  BELIZE = "BZ",
  BENIN = "BJ",
  BERMUDA = "BM",
  BHUTAN = "BT",
  BOLIVIA = "BO",
  BONAIRE = "BQ",
  BOSNIA_AND_HERZEGOVINA = "BA",
  BOTSWANA = "BW",
  BRAZIL = "BR",
  BRITISH_INDIAN_OCEAN_TERRITORY = "IO",
  BRITISH_VIRGIN_ISLANDS = "VG",
  BRUNEI_DARUSSALAM = "BN",
  BULGARIA = "BG",
  BURKINA_FASO = "BF",
  BURUNDI = "BI",
  CAPE_VERDE = "CV",
  CAMBODIA = "KH",
  CAMEROON = "CM",
  CARIBBEAN_NETHERLANDS = "BQ-CN",
  CAYMAN_ISLANDS = "KY",
  CENTRAL_AFRICAN_REPUBLIC = "CF",
  CHAD = "TD",
  CHILE = "CL",
  CHINA = "CN",
  CHRISTMAS_ISLAND = "CX",
  COCOS_ISLANDS = "CC",
  COLOMBIA = "CO",
  COMOROS = "KM",
  CONGO = "CG",
  CONGO_DEMOCRATIC_REPUBLIC = "CD",
  COOK_ISLANDS = "CK",
  COSTA_RICA = "CR",
  IVORY_COAST = "CI",
  CROATIA = "HR",
  CUBA = "CU",
  CURACAO = "CW",
  CYPRUS = "CY",
  CZECH_REPUBLIC = "CZ",
  DENMARK = "DK",
  DJIBOUTI = "DJ",
  DOMINICA = "DM",
  DOMINICAN_REPUBLIC = "DO",
  EASTER_ISLAND = "CL-EI",
  ECUADOR = "EC",
  EGYPT = "EG",
  EL_SALVADOR = "SV",
  EQUATORIAL_GUINEA = "GQ",
  ERITREA = "ER",
  ESTONIA = "EE",
  ESWATINI = "SZ",
  ETHIOPIA = "ET",
  FALKLAND_ISLANDS = "FK",
  FAROE_ISLANDS = "FO",
  FIJI = "FJ",
  FINLAND = "FI",
  FRANCE = "FR",
  FRENCH_GUIANA = "GF",
  FRENCH_POLYNESIA = "PF",
  GABON = "GA",
  GAMBIA = "GM",
  GEORGIA = "GE",
  GERMANY = "DE",
  GHANA = "GH",
  GIBRALTAR = "GI",
  GREECE = "GR",
  GREENLAND = "GL",
  GRENADA = "GD",
  GUADELOUPE = "GP",
  GUAM = "GU",
  GUATEMALA = "GT",
  GUERNSEY = "GG",
  GUINEA = "GN",
  GUINEA_BISSAU = "GW",
  GUYANA = "GY",
  HAITI = "HT",
  HONDURAS = "HN",
  HONG_KONG = "HK",
  HUNGARY = "HU",
  ICELAND = "IS",
  INDIA = "IN",
  INDONESIA = "ID",
  IRAN = "IR",
  IRAQ = "IQ",
  IRELAND = "IE",
  ISLE_OF_MAN = "IM",
  ISRAEL = "IL",
  ITALY = "IT",
  JAMAICA = "JM",
  JAPAN = "JP",
  JERSEY = "JE",
  JORDAN = "JO",
  KAZAKHSTAN = "KZ",
  KENYA = "KE",
  KIRIBATI = "KI",
  KOREA_NORTH = "KP",
  KOREA_SOUTH = "KR",
  KOSOVO = "XK",
  KUWAIT = "KW",
  KYRGYZSTAN = "KG",
  LAOS = "LA",
  LATVIA = "LV",
  LEBANON = "LB",
  LESOTHO = "LS",
  LIBERIA = "LR",
  LIBYA = "LY",
  LIECHTENSTEIN = "LI",
  LITHUANIA = "LT",
  LUXEMBOURG = "LU",
  MACAU = "MO",
  MADAGASCAR = "MG",
  MALAWI = "MW",
  MALAYSIA = "MY",
  MALDIVES = "MV",
  MALI = "ML",
  MALTA = "MT",
  MARSHALL_ISLANDS = "MH",
  MARTINIQUE = "MQ",
  MAURITANIA = "MR",
  MAURITIUS = "MU",
  MAYOTTE = "YT",
  MEXICO = "MX",
  MICRONESIA = "FM",
  MOLDOVA = "MD",
  MONACO = "MC",
  MONGOLIA = "MN",
  MONTENEGRO = "ME",
  MONTSERRAT = "MS",
  MOROCCO = "MA",
  MOZAMBIQUE = "MZ",
  MYANMAR = "MM",
  NAMIBIA = "NA",
  NAURU = "NR",
  NEPAL = "NP",
  NETHERLANDS = "NL",
  NEW_CALEDONIA = "NC",
  NEW_ZEALAND = "NZ",
  NICARAGUA = "NI",
  NIGER = "NE",
  NIGERIA = "NG",
  NIUE = "NU",
  NORFOLK_ISLAND = "NF",
  NORTH_MACEDONIA = "MK",
  NORTHERN_CYPRUS = "CY-N",
  NORTHERN_IRELAND = "GB-NIR",
  NORTHERN_MARIANA_ISLANDS = "MP",
  NORWAY = "NO",
  OMAN = "OM",
  PAKISTAN = "PK",
  PALAU = "PW",
  PALESTINE = "PS",
  PANAMA = "PA",
  PAPUA_NEW_GUINEA = "PG",
  PARAGUAY = "PY",
  PERU = "PE",
  PHILIPPINES = "PH",
  PITCAIRN_ISLANDS = "PN",
  POLAND = "PL",
  PORTUGAL = "PT",
  PUERTO_RICO = "PR",
  QATAR = "QA",
  REUNION = "RE",
  ROMANIA = "RO",
  RUSSIA = "RU",
  RWANDA = "RW",
  SAINT_BARTHELEMY = "BL",
  SAINT_HELENA = "SH",
  SAINT_KITTS_AND_NEVIS = "KN",
  SAINT_LUCIA = "LC",
  SAINT_MARTIN_FRANCE = "MF",
  SAINT_PIERRE_AND_MIQUELON = "PM",
  SAINT_VINCENT_AND_THE_GRENADINES = "VC",
  SAMOA = "WS",
  SAN_MARINO = "SM",
  SAO_TOME_AND_PRINCIPE = "ST",
  SAUDI_ARABIA = "SA",
  SENEGAL = "SN",
  SERBIA = "RS",
  SEYCHELLES = "SC",
  SIERRA_LEONE = "SL",
  SINGAPORE = "SG",
  SINT_EUSTATIUS = "BQ-SE",
  SINT_MAARTEN = "SX",
  SLOVAKIA = "SK",
  SLOVENIA = "SI",
  SOLOMON_ISLANDS = "SB",
  SOMALIA = "SO",
  SOUTH_AFRICA = "ZA",
  SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS = "GS",
  SOUTH_OSSETIA = "OS",
  SOUTH_SUDAN = "SS",
  SPAIN = "ES",
  SRI_LANKA = "LK",
  SUDAN = "SD",
  SURINAME = "SR",
  SVALBARD = "SJ",
  SWEDEN = "SE",
  SWITZERLAND = "CH",
  SYRIA = "SY",
  TAIWAN = "TW",
  TAJIKISTAN = "TJ",
  TANZANIA = "TZ",
  THAILAND = "TH",
  TIMOR_LESTE = "TL",
  TOGO = "TG",
  TOKELAU = "TK",
  TONGA = "TO",
  TRANSNISTRIA = "PMR",
  TRINIDAD_AND_TOBAGO = "TT",
  TRISTAN_DA_CUNHA = "TA",
  TUNISIA = "TN",
  TURKEY = "TR",
  TURKMENISTAN = "TM",
  TURKS_AND_CAICOS_ISLANDS = "TC",
  TUVALU = "TV",
  UGANDA = "UG",
  UKRAINE = "UA",
  UNITED_ARAB_EMIRATES = "AE",
  UNITED_KINGDOM = "GB",
  URUGUAY = "UY",
  US_VIRGIN_ISLANDS = "VI",
  UZBEKISTAN = "UZ",
  VANUATU = "VU",
  VATICAN_CITY = "VA",
  VENEZUELA = "VE",
  VIETNAM = "VN",
  WAKE_ISLAND = "UM",
  WALLIS_AND_FUTUNA = "WF",
  YEMEN = "YE",
  ZAMBIA = "ZM",
  ZANZIBAR = "TZ-Z",
  ZIMBABWE = "ZW",
}

export const CountryDialingCode: Record<CountryCode, string> = {
  [CountryCode.ABKHAZIA]: "7",
  [CountryCode.AFGHANISTAN]: "93",
  [CountryCode.ALAND]: "358",
  [CountryCode.ALBANIA]: "355",
  [CountryCode.ALGERIA]: "213",
  [CountryCode.AMERICAN_SAMOA]: "1",
  [CountryCode.ANDORRA]: "376",
  [CountryCode.ANGOLA]: "244",
  [CountryCode.ANGUILLA]: "1",
  [CountryCode.ANTIGUA_AND_BARBUDA]: "1",
  [CountryCode.ARGENTINA]: "54",
  [CountryCode.ARMENIA]: "374",
  [CountryCode.ARUBA]: "297",
  [CountryCode.ASCENSION]: "247",
  [CountryCode.AUSTRALIA]: "61",
  [CountryCode.AUSTRIA]: "43",
  [CountryCode.AZERBAIJAN]: "994",
  [CountryCode.BAHAMAS]: "1",
  [CountryCode.BAHRAIN]: "973",
  [CountryCode.BANGLADESH]: "880",
  [CountryCode.BARBADOS]: "1",
  [CountryCode.BELARUS]: "375",
  [CountryCode.BELGIUM]: "32",
  [CountryCode.BELIZE]: "501",
  [CountryCode.BENIN]: "229",
  [CountryCode.BERMUDA]: "1",
  [CountryCode.BHUTAN]: "975",
  [CountryCode.BOLIVIA]: "591",
  [CountryCode.BONAIRE]: "599",
  [CountryCode.BOSNIA_AND_HERZEGOVINA]: "387",
  [CountryCode.BOTSWANA]: "267",
  [CountryCode.BRAZIL]: "55",
  [CountryCode.BRITISH_INDIAN_OCEAN_TERRITORY]: "246",
  [CountryCode.BRITISH_VIRGIN_ISLANDS]: "1",
  [CountryCode.BRUNEI_DARUSSALAM]: "673",
  [CountryCode.BULGARIA]: "359",
  [CountryCode.BURKINA_FASO]: "226",
  [CountryCode.BURUNDI]: "257",
  [CountryCode.CAPE_VERDE]: "238",
  [CountryCode.CAMBODIA]: "855",
  [CountryCode.CAMEROON]: "237",
  [CountryCode.CANADA]: "1",
  [CountryCode.CARIBBEAN_NETHERLANDS]: "599",
  [CountryCode.CAYMAN_ISLANDS]: "1",
  [CountryCode.CENTRAL_AFRICAN_REPUBLIC]: "236",
  [CountryCode.CHAD]: "235",
  [CountryCode.CHILE]: "56",
  [CountryCode.CHINA]: "86",
  [CountryCode.CHRISTMAS_ISLAND]: "61",
  [CountryCode.COCOS_ISLANDS]: "61",
  [CountryCode.COLOMBIA]: "57",
  [CountryCode.COMOROS]: "269",
  [CountryCode.CONGO]: "242",
  [CountryCode.CONGO_DEMOCRATIC_REPUBLIC]: "243",
  [CountryCode.COOK_ISLANDS]: "682",
  [CountryCode.COSTA_RICA]: "506",
  [CountryCode.IVORY_COAST]: "225",
  [CountryCode.CROATIA]: "385",
  [CountryCode.CUBA]: "53",
  [CountryCode.CURACAO]: "599",
  [CountryCode.CYPRUS]: "357",
  [CountryCode.CZECH_REPUBLIC]: "420",
  [CountryCode.DENMARK]: "45",
  [CountryCode.DJIBOUTI]: "253",
  [CountryCode.DOMINICA]: "1",
  [CountryCode.DOMINICAN_REPUBLIC]: "1",
  [CountryCode.EASTER_ISLAND]: "56",
  [CountryCode.ECUADOR]: "593",
  [CountryCode.EGYPT]: "20",
  [CountryCode.EL_SALVADOR]: "503",
  [CountryCode.EQUATORIAL_GUINEA]: "240",
  [CountryCode.ERITREA]: "291",
  [CountryCode.ESTONIA]: "372",
  [CountryCode.ESWATINI]: "268",
  [CountryCode.ETHIOPIA]: "251",
  [CountryCode.FALKLAND_ISLANDS]: "500",
  [CountryCode.FAROE_ISLANDS]: "298",
  [CountryCode.FIJI]: "679",
  [CountryCode.FINLAND]: "358",
  [CountryCode.FRANCE]: "33",
  [CountryCode.FRENCH_GUIANA]: "594",
  [CountryCode.FRENCH_POLYNESIA]: "689",
  [CountryCode.GABON]: "241",
  [CountryCode.GAMBIA]: "220",
  [CountryCode.GEORGIA]: "995",
  [CountryCode.GERMANY]: "49",
  [CountryCode.GHANA]: "233",
  [CountryCode.GIBRALTAR]: "350",
  [CountryCode.GREECE]: "30",
  [CountryCode.GREENLAND]: "299",
  [CountryCode.GRENADA]: "1",
  [CountryCode.GUADELOUPE]: "590",
  [CountryCode.GUAM]: "1",
  [CountryCode.GUATEMALA]: "502",
  [CountryCode.GUERNSEY]: "44",
  [CountryCode.GUINEA]: "224",
  [CountryCode.GUINEA_BISSAU]: "245",
  [CountryCode.GUYANA]: "592",
  [CountryCode.HAITI]: "509",
  [CountryCode.HONDURAS]: "504",
  [CountryCode.HONG_KONG]: "852",
  [CountryCode.HUNGARY]: "36",
  [CountryCode.ICELAND]: "354",
  [CountryCode.INDIA]: "91",
  [CountryCode.INDONESIA]: "62",
  [CountryCode.IRAN]: "98",
  [CountryCode.IRAQ]: "964",
  [CountryCode.IRELAND]: "353",
  [CountryCode.ISLE_OF_MAN]: "44",
  [CountryCode.ISRAEL]: "972",
  [CountryCode.ITALY]: "39",
  [CountryCode.JAMAICA]: "1",
  [CountryCode.JAPAN]: "81",
  [CountryCode.JERSEY]: "44",
  [CountryCode.JORDAN]: "962",
  [CountryCode.KAZAKHSTAN]: "7",
  [CountryCode.KENYA]: "254",
  [CountryCode.KIRIBATI]: "686",
  [CountryCode.KOREA_NORTH]: "850",
  [CountryCode.KOREA_SOUTH]: "82",
  [CountryCode.KOSOVO]: "383",
  [CountryCode.KUWAIT]: "965",
  [CountryCode.KYRGYZSTAN]: "996",
  [CountryCode.LAOS]: "856",
  [CountryCode.LATVIA]: "371",
  [CountryCode.LEBANON]: "961",
  [CountryCode.LESOTHO]: "266",
  [CountryCode.LIBERIA]: "231",
  [CountryCode.LIBYA]: "218",
  [CountryCode.LIECHTENSTEIN]: "423",
  [CountryCode.LITHUANIA]: "370",
  [CountryCode.LUXEMBOURG]: "352",
  [CountryCode.MACAU]: "853",
  [CountryCode.MADAGASCAR]: "261",
  [CountryCode.MALAWI]: "265",
  [CountryCode.MALAYSIA]: "60",
  [CountryCode.MALDIVES]: "960",
  [CountryCode.MALI]: "223",
  [CountryCode.MALTA]: "356",
  [CountryCode.MARSHALL_ISLANDS]: "692",
  [CountryCode.MARTINIQUE]: "596",
  [CountryCode.MAURITANIA]: "222",
  [CountryCode.MAURITIUS]: "230",
  [CountryCode.MAYOTTE]: "262",
  [CountryCode.MEXICO]: "52",
  [CountryCode.MICRONESIA]: "691",
  [CountryCode.MOLDOVA]: "373",
  [CountryCode.MONACO]: "377",
  [CountryCode.MONGOLIA]: "976",
  [CountryCode.MONTENEGRO]: "382",
  [CountryCode.MONTSERRAT]: "1",
  [CountryCode.MOROCCO]: "212",
  [CountryCode.MOZAMBIQUE]: "258",
  [CountryCode.MYANMAR]: "95",
  [CountryCode.NAMIBIA]: "264",
  [CountryCode.NAURU]: "674",
  [CountryCode.NEPAL]: "977",
  [CountryCode.NETHERLANDS]: "31",
  [CountryCode.NEW_CALEDONIA]: "687",
  [CountryCode.NEW_ZEALAND]: "64",
  [CountryCode.NICARAGUA]: "505",
  [CountryCode.NIGER]: "227",
  [CountryCode.NIGERIA]: "234",
  [CountryCode.NIUE]: "683",
  [CountryCode.NORFOLK_ISLAND]: "672",
  [CountryCode.NORTH_MACEDONIA]: "389",
  [CountryCode.NORTHERN_CYPRUS]: "90",
  [CountryCode.NORTHERN_IRELAND]: "44",
  [CountryCode.NORTHERN_MARIANA_ISLANDS]: "1",
  [CountryCode.NORWAY]: "47",
  [CountryCode.OMAN]: "968",
  [CountryCode.PAKISTAN]: "92",
  [CountryCode.PALAU]: "680",
  [CountryCode.PALESTINE]: "970",
  [CountryCode.PANAMA]: "507",
  [CountryCode.PAPUA_NEW_GUINEA]: "675",
  [CountryCode.PARAGUAY]: "595",
  [CountryCode.PERU]: "51",
  [CountryCode.PHILIPPINES]: "63",
  [CountryCode.PITCAIRN_ISLANDS]: "64",
  [CountryCode.POLAND]: "48",
  [CountryCode.PORTUGAL]: "351",
  [CountryCode.PUERTO_RICO]: "1",
  [CountryCode.QATAR]: "974",
  [CountryCode.REUNION]: "262",
  [CountryCode.ROMANIA]: "40",
  [CountryCode.RUSSIA]: "7",
  [CountryCode.RWANDA]: "250",
  [CountryCode.SAINT_BARTHELEMY]: "590",
  [CountryCode.SAINT_HELENA]: "290",
  [CountryCode.SAINT_KITTS_AND_NEVIS]: "1",
  [CountryCode.SAINT_LUCIA]: "1",
  [CountryCode.SAINT_MARTIN_FRANCE]: "590",
  [CountryCode.SAINT_PIERRE_AND_MIQUELON]: "508",
  [CountryCode.SAINT_VINCENT_AND_THE_GRENADINES]: "1",
  [CountryCode.SAMOA]: "685",
  [CountryCode.SAN_MARINO]: "378",
  [CountryCode.SAO_TOME_AND_PRINCIPE]: "239",
  [CountryCode.SAUDI_ARABIA]: "966",
  [CountryCode.SENEGAL]: "221",
  [CountryCode.SERBIA]: "381",
  [CountryCode.SEYCHELLES]: "248",
  [CountryCode.SIERRA_LEONE]: "232",
  [CountryCode.SINGAPORE]: "65",
  [CountryCode.SINT_EUSTATIUS]: "599",
  [CountryCode.SINT_MAARTEN]: "1",
  [CountryCode.SLOVAKIA]: "421",
  [CountryCode.SLOVENIA]: "386",
  [CountryCode.SOLOMON_ISLANDS]: "677",
  [CountryCode.SOMALIA]: "252",
  [CountryCode.SOUTH_AFRICA]: "27",
  [CountryCode.SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS]: "500",
  [CountryCode.SOUTH_OSSETIA]: "7",
  [CountryCode.SOUTH_SUDAN]: "211",
  [CountryCode.SPAIN]: "34",
  [CountryCode.SRI_LANKA]: "94",
  [CountryCode.SUDAN]: "249",
  [CountryCode.SURINAME]: "597",
  [CountryCode.SVALBARD]: "47",
  [CountryCode.SWEDEN]: "46",
  [CountryCode.SWITZERLAND]: "41",
  [CountryCode.SYRIA]: "963",
  [CountryCode.TAIWAN]: "886",
  [CountryCode.TAJIKISTAN]: "992",
  [CountryCode.TANZANIA]: "255",
  [CountryCode.THAILAND]: "66",
  [CountryCode.TIMOR_LESTE]: "670",
  [CountryCode.TOGO]: "228",
  [CountryCode.TOKELAU]: "690",
  [CountryCode.TONGA]: "676",
  [CountryCode.TRANSNISTRIA]: "373",
  [CountryCode.TRINIDAD_AND_TOBAGO]: "1",
  [CountryCode.TRISTAN_DA_CUNHA]: "290",
  [CountryCode.TUNISIA]: "216",
  [CountryCode.TURKEY]: "90",
  [CountryCode.TURKMENISTAN]: "993",
  [CountryCode.TURKS_AND_CAICOS_ISLANDS]: "1",
  [CountryCode.TUVALU]: "688",
  [CountryCode.UGANDA]: "256",
  [CountryCode.UKRAINE]: "380",
  [CountryCode.UNITED_ARAB_EMIRATES]: "971",
  [CountryCode.UNITED_KINGDOM]: "44",
  [CountryCode.UNITED_STATES]: "1",
  [CountryCode.URUGUAY]: "598",
  [CountryCode.US_VIRGIN_ISLANDS]: "1",
  [CountryCode.UZBEKISTAN]: "998",
  [CountryCode.VANUATU]: "678",
  [CountryCode.VATICAN_CITY]: "39",
  [CountryCode.VENEZUELA]: "58",
  [CountryCode.VIETNAM]: "84",
  [CountryCode.WAKE_ISLAND]: "1",
  [CountryCode.WALLIS_AND_FUTUNA]: "681",
  [CountryCode.YEMEN]: "967",
  [CountryCode.ZAMBIA]: "260",
  [CountryCode.ZANZIBAR]: "255",
  [CountryCode.ZIMBABWE]: "263",
};
