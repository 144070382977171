import { FulfillmentGroupStatus } from "../fulfillments/fulfillment-group-status";
import { SortDirection, TableSort } from "../tables/table";
import { ArrayStringFilterOperator } from "./advanced-filters/array-string-filter";
import { AdvancedFilterType } from "./advanced-filters/generic-advanced-filter-data";
import { AdvancedFilterData, DEFAULT_PAGE_SIZE, viewTypes } from "./views";

export enum OutboundSystemView {
  AWAITING_SHIPMENT = "awaiting-shipment",
  SHIPMENTS = "shipments",
  CREATE = "create",
}

export const toTitleCase = (str: string) => {
  return str
    .trim()
    .replace(/\w\S*/g, (txt) =>
      txt.length > 2
        ? txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
        : txt,
    );
};

export const sortDefault: TableSort = {
  direction: SortDirection.DESC,
  key: "createdAt",
};

const completeFilter: AdvancedFilterData = {
  name: "status",
  type: AdvancedFilterType.ARRAY_TO_STRING,
  value: [toTitleCase(FulfillmentGroupStatus.Closed)],
  operator: ArrayStringFilterOperator.ANY_OF,
};

const incompleteFilter: AdvancedFilterData = {
  name: "status",
  type: AdvancedFilterType.ARRAY_TO_STRING,
  value: [toTitleCase(FulfillmentGroupStatus.Open)],
  operator: ArrayStringFilterOperator.ANY_OF,
};

const shippingMethodFilter: AdvancedFilterData = {
  name: "deliveryMethod",
  type: AdvancedFilterType.ARRAY_TO_STRING,
  value: ["SHIPPING"],
  operator: ArrayStringFilterOperator.ANY_OF,
};

// These must match the column definitions found in the table, else "Save as
const defaultColumns = [
  { key: "split-accordian", width: 15, hidden: false },
  { key: "selected", width: 24, hidden: false },
  { key: "order-name", width: 120, hidden: false },
  { key: "customer", width: 160, hidden: false },
  { key: "order-date", width: 120, hidden: false },
  { key: "total", width: 100, hidden: false },
  { key: "quantity", width: 80, hidden: false },
  { key: "weight", width: 80, hidden: false },
  { key: "parcel-selection", width: 160, hidden: false },
  { key: "shippingSelection", width: 320, hidden: false },
  { key: "delivery-method", width: 120, hidden: false },
  { key: "order-age", width: 100, hidden: false },
  { key: "assigned-user", width: 120, hidden: false },
  { key: "destination-country", width: 80, hidden: false },
  { key: "rate", width: 80, hidden: false },
  { key: "item-name", width: 80, hidden: true },
  { key: "item-sku", width: 80, hidden: false },
  { key: "requested-service", width: 160, hidden: false },
  { key: "shippingPaid", width: 120, hidden: false },
];

const awaitingShipmentDefaultColumns = [
  { key: "split-accordian", width: 15, hidden: false },
  { key: "selected", width: 24, hidden: false },
  { key: "fraud-risk", width: 120, hidden: false },
  { key: "address-verification", width: 160, hidden: false },
  { key: "order-name", width: 120, hidden: false },
  { key: "order-date", width: 120, hidden: false },
  { key: "order-age", width: 100, hidden: false },
  { key: "quantity", width: 80, hidden: false },
  { key: "weight", width: 80, hidden: false },
  { key: "parcel-selection", width: 160, hidden: false },
  { key: "item-name", width: 80, hidden: false },
  { key: "shippingSelection", width: 320, hidden: false },
  { key: "requested-service", width: 160, hidden: false },
  { key: "destination-country", width: 80, hidden: false },
  { key: "internal-notes", width: 310, hidden: false },
];

export const StaticSystemViews: Record<
  OutboundSystemView,
  viewTypes["fulfillment-groups"]
> = {
  [OutboundSystemView.AWAITING_SHIPMENT]: {
    table: "fulfillment-groups",
    name: OutboundSystemView.AWAITING_SHIPMENT,
    filters: [incompleteFilter, shippingMethodFilter],
    columns: awaitingShipmentDefaultColumns,
    pageSize: DEFAULT_PAGE_SIZE,
    sort: sortDefault,
    position: 0,
  },
  [OutboundSystemView.SHIPMENTS]: {
    table: "fulfillment-groups",
    name: OutboundSystemView.SHIPMENTS,
    filters: [completeFilter, shippingMethodFilter],
    columns: defaultColumns,
    pageSize: DEFAULT_PAGE_SIZE,
    sort: sortDefault,
    position: 1,
  },
  [OutboundSystemView.CREATE]: {
    table: "fulfillment-groups",
    name: OutboundSystemView.CREATE,
    filters: [],
    columns: defaultColumns,
    pageSize: DEFAULT_PAGE_SIZE,
    sort: sortDefault,
    position: -1,
  },
};

export function friendlyViewName(view: viewTypes["fulfillment-groups"]) {
  if (view.name === OutboundSystemView.AWAITING_SHIPMENT) {
    return "Ready to ship";
  }
  if (view.name === OutboundSystemView.SHIPMENTS) {
    return "Shipments";
  }

  return view.name;
}
