import { useRequiredContext } from "@redotech/react-util/context";
import { MerchantAppTopic } from "@redotech/redo-merchant-app-common/events/merchant-app-event-server";
import { MerchantAppEventServerContext } from "@redotech/redo-merchant-app-common/events/merchant-app-event-server-provider";
import { RedoMerchantRpcClientContext } from "@redotech/redo-merchant-app-common/rpc-client";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { UserAvailabilities } from "@redotech/redo-model/support/voice/voice-types";
import {
  FrontendTeamNoticeEvent,
  TeamNoticeEventType,
} from "@redotech/redo-model/team-notifications/team-notifications";
import { createContext, memo, ReactNode, useEffect, useState } from "react";

const TEN_SECONDS_IN_MS = 10000;
export const TeamCallAvailabilities = createContext<
  UserAvailabilities | undefined
>(undefined);
export const TeamCallAvailabilitiesService = memo(
  function TeamCallAvailabilitiesService({
    children,
  }: {
    children: ReactNode | ReactNode[];
  }) {
    const team = useRequiredContext(TeamContext);
    const eventServer = useRequiredContext(MerchantAppEventServerContext);
    const rpcClient = useRequiredContext(RedoMerchantRpcClientContext);
    const [currentTimeout, setCurrentTimeout] = useState<NodeJS.Timeout | null>(
      null,
    );

    const [userAvailabilities, setUserAvailabilities] =
      useState<UserAvailabilities>([]);

    const handleUserAvailabilityUpdate = async () => {
      if (currentTimeout) {
        clearTimeout(currentTimeout);
      }
      const response = await rpcClient.getTeamCallAvailabilities({});
      setUserAvailabilities(response.callAvailabilities);
      // This will catch any updates caused by expiring availability statuses
      const timeout = setTimeout(
        handleUserAvailabilityUpdate,
        TEN_SECONDS_IN_MS,
      );
      setCurrentTimeout(timeout);
    };

    useEffect(() => {
      if (team?.settings.support?.enabled) {
        const unlistenCallback = eventServer.subscribeAndCallOnce({
          topic: MerchantAppTopic.TEAM,
          callback: async (notification: FrontendTeamNoticeEvent | null) => {
            if (
              notification === null ||
              notification.type ===
                TeamNoticeEventType.USER_AVAILABILITY_UPDATED
            ) {
              const response = await rpcClient.getTeamCallAvailabilities({});
              setUserAvailabilities(response.callAvailabilities);
            }
          },
        });
        return () => unlistenCallback();
      }
      return undefined;
      // FIXME
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventServer, team]);

    return (
      <TeamCallAvailabilities.Provider value={userAvailabilities}>
        {children}
      </TeamCallAvailabilities.Provider>
    );
  },
);
