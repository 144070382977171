import { ClickAwayListener, Popper } from "@mui/material";
import { useHandler } from "@redotech/react-util/hook";
import CalendarIcon from "@redotech/redo-web/arbiter-icon/calendar-date.svg";
import { memo, useState } from "react";
import { getDateString } from "../../date-utils";
import { Flex } from "../../flex";
import { RedoButton, RedoButtonSize } from "../buttons/redo-button";
import { DatePicker } from "./date-picker/date-picker-menu";

export interface RedoSingleDatePickerProps {
  date: Temporal.PlainDate | undefined;
  disabled?: boolean;
  setDate: (date: Temporal.PlainDate) => void;
  buttonSize?: RedoButtonSize;
  minDate?: Temporal.PlainDate;
  maxDate?: Temporal.PlainDate;
  allowNoDateOption?: boolean;
  dateStringOptions?: Pick<
    Intl.DateTimeFormatOptions,
    "year" | "month" | "day"
  >;
}

export const RedoSingleDatePicker = memo(function RedoSingleDatePicker({
  date,
  disabled,
  setDate,
  minDate,
  maxDate,
  buttonSize = "lg",
  allowNoDateOption = false,
  dateStringOptions = {},
}: RedoSingleDatePickerProps) {
  const buttonDisplay = date
    ? getDateString(date, dateStringOptions)
    : "Select date";

  const [dropdownAnchor, setDropdownAnchor] = useState<HTMLElement | null>(
    null,
  );

  const [pickerOpen, setPickerOpen] = useState(false);

  const handleSetCustomDate = useHandler((value: Temporal.PlainDate | null) => {
    if (!value) {
      return;
    }
    setDate(value);
    setPickerOpen(false);
  });

  const handleClick = useHandler((e: React.MouseEvent) => {
    e.stopPropagation();
    setPickerOpen((open) => !open);
  });

  return (
    <>
      <RedoButton
        centerItems={false}
        disabled={disabled}
        hierarchy="secondary"
        IconLeading={CalendarIcon}
        onClick={handleClick}
        ref={setDropdownAnchor}
        size={buttonSize}
        text={buttonDisplay}
      />
      <ClickAwayListener onClickAway={() => setPickerOpen(false)}>
        <Popper
          anchorEl={dropdownAnchor}
          modifiers={[{ name: "offset", options: { offset: [0, 8] } }]}
          open={pickerOpen}
        >
          <Flex>
            <DatePicker
              allowNoDateOption={allowNoDateOption}
              maxDate={maxDate}
              minDate={minDate}
              onDateChange={handleSetCustomDate}
              selectedDate={date || null}
            />
          </Flex>
        </Popper>
      </ClickAwayListener>
    </>
  );
});
