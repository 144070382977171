import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import { useLoad } from "@redotech/react-util/load";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { FiltersStatus } from "@redotech/redo-model/conversation-filters/conversation-filters";
import { getPrimaryCustomerEmail } from "@redotech/redo-model/customer";
import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoListItem } from "@redotech/redo-web/arbiter-components/list/redo-list";
import { RedoSingleSelectDropdownInput } from "@redotech/redo-web/arbiter-components/select-dropdown/redo-single-select-dropdown-input";
import MailIcon from "@redotech/redo-web/arbiter-icon/mail-01.svg";
import { Divider } from "@redotech/redo-web/divider";
import { Flex } from "@redotech/redo-web/flex";
import { SkeletonText } from "@redotech/redo-web/skeleton";
import { assertNever } from "@redotech/util/type";
import { Fragment, memo, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getRecentCustomerConversations } from "../../../client/conversations";
import { ConversationInfoCard } from "../../../support/conversation-info-card";
import { NewMessageModal } from "../../../support/new-message-modal";
import * as customerSupportTabCss from "./customer-support-tab.module.css";

export const CustomerSupportTab = memo(function CustomerSupportTab({
  returnType,
  customerEmail,
}: {
  returnType?: "return" | "claim" | "warranty" | "managed_claim";
  customerEmail?: string;
}) {
  const team = useRequiredContext(TeamContext);
  const navigate = useNavigate();
  const client = useRequiredContext(RedoMerchantClientContext);

  const [statusFilter, setStatusFilter] = useState<FiltersStatus | "all">(
    FiltersStatus.OPEN,
  );

  const [createConversationModalOpen, setCreateConversationModalOpen] =
    useState(false);

  const handleNavigateAfterCreate = async ({
    conversationId,
    stayOnPage = true,
  }: {
    conversationId: string;
    stayOnPage?: boolean;
  }) => {
    amplitude.logEvent(`create-conversation-${returnType}-detail-page`);
    if (!stayOnPage) {
      navigate(
        `/stores/${team?._id}/support/table/all?activeConversationId=${conversationId}`,
      );
    }
  };

  const conversationsLoad = useLoad(
    async (signal) => {
      if (!customerEmail) {
        return;
      }

      const conversations = await getRecentCustomerConversations(client, {
        customerEmail,
        status: statusFilter,
        signal,
      });

      return conversations;
    },
    [customerEmail, team, statusFilter],
  );

  const conversationCustomerEmail = getPrimaryCustomerEmail(
    conversationsLoad.value?.[0]?.customer,
  );

  const handleSearchSupport = () => {
    if (!conversationCustomerEmail || !team?._id) {
      return;
    }

    const searchString = conversationCustomerEmail;
    amplitude.logEvent(`search-conversation-from-${returnType}`, {
      mode: "all",
      searchString,
    });
    navigate({
      pathname: `/stores/${team?._id}/support/table/all`,
      search: `?initialSearch=${encodeURIComponent(searchString || "")}`,
    });
  };

  const statusOptions: RedoListItem<FiltersStatus | "all">[] = useMemo(() => {
    const filterStatusesToUse: (FiltersStatus | "all")[] = [
      FiltersStatus.OPEN,
      FiltersStatus.CLOSED,
      ...(team.settings.support?.useInProgressStatus
        ? [FiltersStatus.IN_PROGRESS]
        : []),
      FiltersStatus.SNOOZED,
      "all",
    ];

    return filterStatusesToUse.map<RedoListItem<FiltersStatus | "all">>(
      (s) => ({ id: s, value: s, type: "text", text: getItemDisplay(s) }),
    );
  }, [team]);

  return (
    <>
      <Flex className={customerSupportTabCss.container} dir="column" gap="xs">
        <RedoSingleSelectDropdownInput
          options={statusOptions}
          optionSelected={({ item }) => setStatusFilter(item.value)}
          selectedItem={statusOptions.find(
            (option) => option.value === statusFilter,
          )}
          size="xs"
        />
        {conversationsLoad.value ? (
          conversationsLoad.value.length ? (
            <>
              {conversationsLoad.value.map(
                (conversation: ExpandedConversation, index: number) => (
                  <Fragment key={`${index}`}>
                    {index > 0 && <Divider />}
                    <ConversationInfoCard
                      item={conversation}
                      key={`${index}`}
                      onClick={() => {
                        navigate(
                          `/stores/${team?._id}/support/table/all?activeConversationId=${conversation._id}`,
                        );
                      }}
                    />
                  </Fragment>
                ),
              )}
              {conversationCustomerEmail && (
                <>
                  <Divider />
                  <RedoButton
                    hierarchy="secondary"
                    onClick={handleSearchSupport}
                    size="sm"
                    text="See all support tickets"
                  />
                </>
              )}
            </>
          ) : (
            <ConversationInfoCard empty />
          )
        ) : (
          <div>
            <SkeletonText length={30} />
          </div>
        )}
        <RedoButton
          hierarchy="secondary"
          IconLeading={MailIcon}
          onClick={() => setCreateConversationModalOpen(true)}
          text="Send email"
        />
      </Flex>
      <NewMessageModal
        customerInfo={{ email: conversationCustomerEmail || customerEmail }}
        handleNavigate={handleNavigateAfterCreate}
        open={createConversationModalOpen}
        setOpen={setCreateConversationModalOpen}
        showStayOption
      />
    </>
  );
});

function getItemDisplay(status: FiltersStatus | "all"): string {
  if (status === "all") {
    return `All`;
  } else if (status === FiltersStatus.OPEN) {
    return `Open`;
  } else if (status === FiltersStatus.CLOSED) {
    return `Closed`;
  } else if (status === FiltersStatus.SNOOZED) {
    return `Snoozed`;
  } else if (status === FiltersStatus.IN_PROGRESS) {
    return `In progress`;
  } else {
    return assertNever(status);
  }
}
