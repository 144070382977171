import { useRequiredContext } from "@redotech/react-util/context";
import { useLoad } from "@redotech/react-util/load";
import { getProduct, WidgetClient } from "@redotech/redo-api-client/widget";
import { Order, Provider } from "@redotech/redo-model/order";

import { REDO_MERCHANT_SERVER_URL } from "@redotech/redo-merchant-app-common/config";
import {
  ReturnedProductStatus,
  ReturnTypeEnum,
  type Return,
} from "@redotech/redo-model/return";
import { ReturnStatus } from "@redotech/redo-model/return-status";
import { ProductTotals } from "@redotech/redo-model/return-totals-calculator";
import { DiscountDistributionMethod } from "@redotech/redo-model/team";
import { RedoBadge } from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import EditIcon from "@redotech/redo-web/arbiter-icon/edit-05.svg";
import TriangleDown from "@redotech/redo-web/arbiter-icon/triangle-down_filled.svg";

import { RedoExternalLink } from "@redotech/redo-web/arbiter-components/links/redo-external-link";

import { RedoMerchantRpcClientContext } from "@redotech/redo-merchant-app-common/rpc-client";
import {
  shopifyAdminProductUrl,
  shopifyStoreName,
} from "@redotech/redo-merchant-app-common/shopify";
import { UserContext } from "@redotech/redo-merchant-app-common/user";
import { RedoClientContext } from "@redotech/redo-web/client";
import { TeamContext } from "@redotech/redo-web/context/team-context";
import { CURRENCY_FORMAT, CurrencyContext } from "@redotech/redo-web/currency";
import { ExpandableImage } from "@redotech/redo-web/expandable-image";
import { Flex } from "@redotech/redo-web/flex";
import { ExternalLink } from "@redotech/redo-web/link";
import {
  MessageBubble,
  MessageBubbleAlignment,
  MessageBubbleTheme,
} from "@redotech/redo-web/message-bubble/message-bubble";
import { SkeletonBlock, SkeletonText } from "@redotech/redo-web/skeleton";
import { Text } from "@redotech/redo-web/text";
import * as classNames from "classnames";
import { memo, useContext, useEffect, useMemo, useState } from "react";
import {
  hasEditReturnPermissions,
  isVariantExchange,
  ItemDispositionModalOpen,
  itemStatusName,
  returnKindNameProduct,
  returnTypeName,
} from "../util";
import * as detailsCardCss from "./details-card.module.css";
import * as returnCss from "./details-card.module.css";

export const Item = memo(function Item({
  return_,
  returnLoadPending,
  itemToDisplay,
  productTotals,
  handleChangeReturnType,
  setEditItemModalOpen,
  setItemBeingEdited,
  setEditSingleItemDispositionModalOpen,
  order,
  defaultItemExpanded,
}: {
  itemToDisplay: Return["products"][number];
  return_: Return;
  returnLoadPending: boolean;
  productTotals?: ProductTotals;
  handleChangeReturnType: (
    newType: string,
    product: Return["products"][number],
  ) => void;
  setEditItemModalOpen: (open: boolean) => void;
  setEditSingleItemDispositionModalOpen: (
    open: ItemDispositionModalOpen,
  ) => void;
  setItemBeingEdited: (item: any) => void; // TODO type
  order?: Order;
  defaultItemExpanded: boolean;
}) {
  const [variantImage, setVariantImage] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const team = useContext(TeamContext);
  const user = useContext(UserContext);
  const client = useRequiredContext(RedoClientContext);
  const merchantClient = useRequiredContext(RedoMerchantRpcClientContext);
  const [viewingOriginal, setViewingOriginal] = useState(false);
  const [item, setItem] = useState<Return["products"][number]>({
    ...itemToDisplay,
  });
  const [returnTypeModalOpen, setReturnTypeModalOpen] = useState(false);

  const { formatCurrency } = useContext(CurrencyContext);

  const warehouseLocationLoad = useLoad(async () => {
    if (!team || !team.settings.returns?.warehouseLocationEnabled) {
      return undefined;
    }
    return (
      await merchantClient.getWarehouseForProduct({
        variantId: itemToDisplay.variant_id,
      })
    ).warehouseName;
  }, [team]);

  const binLocationLoad = useLoad(async () => {
    if (return_.provider !== Provider.COMMENTSOLD) {
      return undefined;
    }
    return (
      await merchantClient.getReturnItemBinLocation({
        returnId: return_.id,
        lineItemId: itemToDisplay.line_item_id,
      })
    ).binLocation;
  }, [team]);

  useEffect(() => {
    setItem({ ...itemToDisplay });
  }, [itemToDisplay]);

  useEffect(() => {
    if (team) {
      const widgetClient = new WidgetClient(client, team.widget_slug);
      const lineItem = (order?.shopify?.line_items || []).find(
        (line_item: any) => line_item.id.toString() === item.line_item_id,
      );
      if (lineItem) {
        void getProduct(widgetClient, {
          productId: String(item.product_id),
        }).then((res: any) => {
          const product = res;
          const variant = product.variants.find(
            (variant: any) => variant.id.toString() === item.variant_id,
          );
          if (variant && variant.image_id) {
            const image = product.images.find(
              (image: any) => image.id === variant.image_id,
            );
            setVariantImage(image.src);
          }
          setLoading(false);
        });
      }
    }
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team]);

  useEffect(() => {
    if (viewingOriginal && item.originalVariantOptions) {
      item.strategy = item.originalVariantOptions.strategy;
      item.exchange_for = item.originalVariantOptions.exchange_for;
      item.merchant_adjustment = undefined;
      item.price_adjustment =
        item.originalVariantOptions.price_adjustment || "0";
      setItem({ ...item });
    } else {
      setItem({ ...itemToDisplay });
    }
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewingOriginal]);

  if (!item.sku) {
    const shopifyProduct =
      return_.products[0]?.order?.shopify?.line_items?.find(
        (lineItem: any) => lineItem.id == item.line_item_id,
      );
    if (shopifyProduct?.sku) {
      item.sku = shopifyProduct?.sku;
    }
  }

  const canEditReturnType =
    hasEditReturnPermissions(team, user) &&
    !viewingOriginal &&
    ![
      ReturnedProductStatus.COMPLETE,
      ReturnedProductStatus.PENDING,
      ReturnedProductStatus.REJECTED,
    ].includes(item.status) &&
    !return_.draftOrderId &&
    !return_.exchangeOrder.length &&
    !return_.instantRefund;

  const originalPrice =
    (productTotals?.finalPrice || 0) -
    (productTotals?.merchantAdjustment || 0) -
    (productTotals?.priceAdjustment || 0) +
    parseFloat(item.originalVariantOptions?.price_adjustment || "0");
  const priceWithMerchantAdjustment = productTotals?.finalPrice || 0;
  const returnType = isVariantExchange(item)
    ? "exchange"
    : item.strategy === "store_credit"
      ? "store_credit"
      : "refund";
  const openModal = () => {
    if (canEditReturnType) {
      setReturnTypeModalOpen(true);
    }
  };

  const canAdjustReturnValue =
    hasEditReturnPermissions(team, user) &&
    ![ReturnStatus.COMPLETE, ReturnStatus.REJECTED].includes(return_.status) &&
    ![ReturnedProductStatus.COMPLETE, ReturnedProductStatus.PENDING].includes(
      item.status,
    ) &&
    !["repair"].includes(item.strategy);

  const closeModal = () => {
    setReturnTypeModalOpen(false);
  };

  const nonZeroValueExchange =
    team?.settings?.exchanges?.nonZeroValueExchange &&
    return_.type === ReturnTypeEnum.CLAIM &&
    return_.advancedExchangeItems.length > 0;

  const getItemType = () => {
    let itemType = returnKindNameProduct(item, return_.provision, return_.type);
    if (itemType !== "Exchange" && return_.advancedExchangeItems?.length) {
      itemType = `Exchange + ${itemType}`;
    }

    if (nonZeroValueExchange) {
      itemType = "Exchange";
    }

    return itemType;
  };
  const itemType = getItemType();
  const bonusType = itemType.includes("Exchange") ? "Shop now" : itemType;
  let discountType;

  if (
    team?.settings.discountDistributionMethod ===
    DiscountDistributionMethod.ORDER
  ) {
    discountType = "Discount distributed across order";
  } else if (
    team?.settings.discountDistributionMethod ===
      DiscountDistributionMethod.FAMILY &&
    order &&
    itemHasSharedDiscount(item, order)
  ) {
    discountType = "Discount distributed across discount family";
  } else {
    discountType = "Discount";
  }

  const totalReturnPrice = viewingOriginal
    ? originalPrice
    : priceWithMerchantAdjustment;

  const questionAnswers = item.multipleChoiceAnswers || [];

  const shouldShowItemDisposition = useMemo(
    () =>
      team?.settings.returnItemDisposition.enabled ||
      item.merchant_grade ||
      item.merchant_outcome ||
      item.merchant_notes,
    [team, item],
  );

  const itemDispositionEmptyValue = "-";
  const isItemDropshipped = !!item?.dropshipSupplierName;

  const [isExpanded, setIsExpanded] = useState(defaultItemExpanded);
  const [isClosing, setIsClosing] = useState(false);

  const handleExpand = () => {
    if (isExpanded) {
      setIsClosing(true);
      // Wait for animation to complete before fully collapsing
      setTimeout(() => {
        setIsExpanded(false);
        setIsClosing(false);
      }, 200); // Match this with animation duration (0.2s = 200ms)
    } else {
      setIsExpanded(true);
    }
  };

  // TODO: fix the typing of this.
  const imagesFromCustomer: string[] =
    (item as any)?.inputAnswers
      ?.filter((answer: any) => answer.inputType === "images")
      .map((answer: any) => answer.image) || [];

  const videosFromCustomer: string[] =
    (item as any)?.inputAnswers
      ?.filter((answer: any) => answer.inputType === "videos")
      .map((answer: any) => answer.image) || [];

  const customerComments: string[] =
    (item as any)?.inputAnswers
      ?.filter((answer: any) => answer.inputType === "text_area")
      .map((answer: any) => answer.answer) || [];

  return (
    <div className={returnCss.item}>
      <div className={returnCss.itemMain}>
        <div className={returnCss.itemHeader}>
          <div className={returnCss.expandButtonContainer}>
            <div className={returnCss.expandButton} onClick={handleExpand}>
              <TriangleDown
                className={classNames(returnCss.chevron, {
                  [returnCss.expanded]: isExpanded || isClosing,
                })}
              />
            </div>
            {loading ? (
              <div className={returnCss.itemImage}>
                <SkeletonBlock />
              </div>
            ) : (
              <ExpandableImage
                className={returnCss.itemImage}
                src={variantImage || item.images[0]}
              />
            )}
          </div>
          <div className={returnCss.product}>
            <div className={returnCss.itemTitle}>
              <div className={returnCss.nonPriceContainer}>
                {team?.platform === "shopify" ? (
                  <RedoExternalLink
                    fontSize="lg"
                    showIcon={false}
                    text={item.product_title}
                    textClassName={classNames(
                      returnCss.itemLink,
                      returnCss.productTitle,
                    )}
                    url={
                      team &&
                      shopifyAdminProductUrl(
                        shopifyStoreName(team.storeUrl),
                        item.product_id,
                      ).toString()
                    }
                  />
                ) : (
                  <Text
                    className={returnCss.productTitle}
                    fontSize="lg"
                    fontWeight="regular"
                  >
                    {item.product_title}
                  </Text>
                )}
                <div className={returnCss.itemType}>
                  <RedoBadge
                    size="xs"
                    text={itemStatusName(item.status, return_.status)}
                  />
                </div>
                <div>
                  {returnTypeModalOpen && (
                    <div
                      className={returnCss.clickAwayListener}
                      onClick={closeModal}
                    />
                  )}
                  {item.strategy !== "none" && (
                    <div
                      className={
                        canEditReturnType
                          ? classNames(returnCss.itemType, returnCss.clickable)
                          : returnCss.itemType
                      }
                      onClick={openModal}
                    >
                      <RedoBadge
                        iconTrailing={
                          canEditReturnType
                            ? { type: "icon", Icon: EditIcon }
                            : undefined
                        }
                        size="xs"
                        text={getItemType()}
                      />
                      {returnTypeModalOpen && (
                        <div className={returnCss.returnTypeModal}>
                          <div className={returnCss.returnTypeModalHeader}>
                            <div>
                              {return_.type === ReturnTypeEnum.CLAIM
                                ? "Edit Fulfillment Method"
                                : `Edit ${returnTypeName(return_.type, true)} Type`}
                            </div>
                          </div>
                          {returnType !== "store_credit" && (
                            <div
                              className={
                                canEditReturnType
                                  ? classNames(
                                      returnCss.itemType,
                                      returnCss.clickable,
                                    )
                                  : returnCss.itemType
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                handleChangeReturnType("store_credit", item);
                                closeModal();
                              }}
                            >
                              <RedoBadge
                                size="xs"
                                text={
                                  return_.advancedExchangeItems?.length
                                    ? "Exchange + Store Credit "
                                    : "Store Credit"
                                }
                              />
                            </div>
                          )}
                          {returnType !== "refund" && (
                            <div
                              className={
                                canEditReturnType
                                  ? classNames(
                                      returnCss.itemType,
                                      returnCss.clickable,
                                    )
                                  : returnCss.itemType
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                handleChangeReturnType("refund", item);
                                closeModal();
                              }}
                            >
                              <RedoBadge
                                size="xs"
                                text={
                                  return_.advancedExchangeItems?.length
                                    ? "Exchange + Refund"
                                    : "Refund"
                                }
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {item.green_return && (
                  <div className={returnCss.itemType}>
                    <RedoBadge color="success" size="xs" text="Green Return" />
                  </div>
                )}
                {isItemDropshipped && return_.type !== ReturnTypeEnum.CLAIM && (
                  <div className={returnCss.itemType}>
                    <RedoBadge color="warning" size="xs" text="Dropshipped" />
                  </div>
                )}
                {imagesFromCustomer.length + videosFromCustomer.length > 0 && (
                  <RedoBadge
                    color="blueLight"
                    size="xs"
                    text={`${imagesFromCustomer.length + videosFromCustomer.length} image${
                      imagesFromCustomer.length + videosFromCustomer.length > 1
                        ? "s"
                        : ""
                    }`}
                  />
                )}
                {customerComments.length > 0 && (
                  <RedoBadge
                    color="blueLight"
                    size="xs"
                    text={`${customerComments.length} comment${
                      customerComments.length > 1 ? "s" : ""
                    }`}
                  />
                )}
              </div>
              <div className={returnCss.itemPriceContainer}>
                <Text className={returnCss.itemPriceText}>
                  {viewingOriginal
                    ? formatCurrency(originalPrice)
                    : formatCurrency(priceWithMerchantAdjustment)}
                </Text>
                {item.originalVariantOptions && (
                  <ExternalLink
                    className={classNames(
                      returnCss.originalPrice,
                      returnCss.right,
                    )}
                    onClick={() => setViewingOriginal(!viewingOriginal)}
                    showIcon={false}
                  >
                    <Text className={returnCss.originalPriceText}>
                      {viewingOriginal ? "Actual: " : "Original: "}
                      {viewingOriginal
                        ? formatCurrency(priceWithMerchantAdjustment)
                        : formatCurrency(originalPrice)}
                    </Text>
                  </ExternalLink>
                )}
                {canAdjustReturnValue && (
                  <ExternalLink
                    onClick={() => {
                      setItemBeingEdited(item);
                      setEditItemModalOpen(true);
                    }}
                    showIcon={false}
                  >
                    <EditIcon className={returnCss.editIcon} />
                  </ExternalLink>
                )}
              </div>
            </div>
            <div className={returnCss.itemSubtitle}>
              <Text className={returnCss.itemSubtitleText}>
                Qty: {item.quantity}
              </Text>
              {item?.variant_title && (
                <Text className={returnCss.itemSubtitleText}>
                  {item.variant_title}
                </Text>
              )}
              {item.sku && (
                <Text className={returnCss.itemSubtitleText}>
                  sku: {item.sku}
                </Text>
              )}
              {binLocationLoad.value && (
                <div className={returnCss.itemSubtitleText}>
                  Bin Location: {binLocationLoad.value}
                </div>
              )}
              {warehouseLocationLoad.value && (
                <div className={returnCss.itemSubtitleText}>
                  Warehouse: {warehouseLocationLoad.value}
                </div>
              )}
              {team?.settings.products.barcode && (
                <Text className={returnCss.itemSubtitleText}>
                  <ExternalLink
                    className={returnCss.linkColor}
                    url={`${REDO_MERCHANT_SERVER_URL}/returns2/${return_._id}/items/${item._id}/barcode`}
                  >
                    Barcode
                  </ExternalLink>
                </Text>
              )}
            </div>
          </div>
        </div>
        {(isExpanded || isClosing) && (
          <div
            className={classNames({
              [returnCss.hiddenValues]: isExpanded && !isClosing,
              [returnCss.hiddenValuesClosing]: isClosing,
            })}
          >
            <div className={returnCss.itemSummary}>
              <Flex dir="column" gap="lg">
                <Text fontSize="xs" fontWeight="semibold" textColor="primary">
                  Summary
                </Text>
                {productTotals &&
                  productTotals.originalPrice >=
                    productTotals.discountPrice && (
                    <Flex
                      className={classNames(
                        returnCss.itemExtra,
                        returnCss.summaryElement,
                      )}
                    >
                      <Text fontSize="xs" fontWeight="thin">
                        Retail price
                      </Text>
                      <Text fontSize="xs" fontWeight="thin">
                        {formatCurrency(productTotals.originalPrice || 0)}
                      </Text>
                    </Flex>
                  )}
                {productTotals &&
                  productTotals.discountPrice < productTotals.originalPrice && (
                    <Flex
                      className={classNames(
                        returnCss.itemExtra,
                        returnCss.summaryElement,
                      )}
                    >
                      <Text fontSize="xs" fontWeight="thin">
                        {discountType}
                      </Text>
                      <Text fontSize="xs" fontWeight="thin">
                        {`${formatCurrency(productTotals ? productTotals?.discountPrice - productTotals?.originalPrice : 0)}`}
                      </Text>
                    </Flex>
                  )}
                {(productTotals?.priceAdjustment !== 0 ||
                  item.green_return) && (
                  <Flex
                    align="center"
                    className={classNames(
                      returnCss.itemExtra,
                      returnCss.summaryElement,
                    )}
                    dir="row"
                    gap="xs"
                  >
                    <Flex align="center" dir="row" gap="xs">
                      <Text fontSize="xs" fontWeight="thin">
                        Merchant adjustment
                      </Text>
                      {getAdjustmentTextAndValue(
                        viewingOriginal
                          ? parseFloat(
                              item.originalVariantOptions?.price_adjustment ||
                                "0",
                            )
                          : productTotals?.priceAdjustment || 0,
                        item.green_return,
                        bonusType,
                      ).badge !== "" && (
                        <RedoBadge
                          size="xs"
                          text={
                            getAdjustmentTextAndValue(
                              viewingOriginal
                                ? parseFloat(
                                    item.originalVariantOptions
                                      ?.price_adjustment || "0",
                                  )
                                : productTotals?.priceAdjustment || 0,
                              item.green_return,
                              bonusType,
                            ).badge
                          }
                        />
                      )}
                    </Flex>
                    <Text fontSize="xs" fontWeight="thin">
                      {
                        getAdjustmentTextAndValue(
                          viewingOriginal
                            ? parseFloat(
                                item.originalVariantOptions?.price_adjustment ||
                                  "0",
                              )
                            : productTotals?.priceAdjustment || 0,
                          item.green_return,
                          bonusType,
                        ).text
                      }
                    </Text>
                  </Flex>
                )}
                {!!productTotals?.tax && (
                  <Flex
                    className={classNames(
                      returnCss.itemExtra,
                      returnCss.summaryElement,
                    )}
                  >
                    <Text fontSize="xs" fontWeight="thin">
                      Tax
                    </Text>
                    <Text fontSize="xs" fontWeight="thin">
                      {formatCurrency(productTotals?.tax || 0)}
                    </Text>
                  </Flex>
                )}
                {productTotals &&
                  productTotals.merchantAdjustment !== 0 &&
                  !viewingOriginal && (
                    <Flex
                      className={classNames(
                        returnCss.itemExtra,
                        returnCss.summaryElement,
                      )}
                    >
                      <Text fontSize="xs" fontWeight="thin">
                        Merchant Adjustment
                      </Text>
                      <Text fontSize="xs" fontWeight="thin">
                        {productTotals?.merchantAdjustment > 0 && <>+</>}
                        {formatCurrency(productTotals.merchantAdjustment)}
                      </Text>
                    </Flex>
                  )}
                {return_.settlement?.accepted && (
                  <Flex
                    className={classNames(
                      returnCss.itemExtra,
                      returnCss.summaryElement,
                    )}
                  >
                    <Text fontSize="xs" fontWeight="thin">
                      Settlement
                    </Text>
                    <Text fontSize="xs" fontWeight="thin">
                      {formatCurrency(
                        -(
                          totalReturnPrice -
                          totalReturnPrice * return_.settlement?.refund
                        ),
                      )}
                    </Text>
                  </Flex>
                )}
                <div className={returnCss.itemSummaryLine} />
                <Flex
                  className={classNames(
                    returnCss.itemExtra,
                    returnCss.summaryElement,
                  )}
                >
                  <Text fontSize="xs" fontWeight="regular">
                    Return value
                  </Text>
                  <Text fontSize="xs" fontWeight="regular">
                    {return_.settlement?.accepted
                      ? formatCurrency(
                          totalReturnPrice * return_.settlement.refund,
                        )
                      : formatCurrency(totalReturnPrice)}
                  </Text>
                </Flex>
              </Flex>
            </div>

            {(!!item.rejectMessage ||
              !!item.reason ||
              !!item.flaggedReason ||
              !!item.manualReviewReason ||
              questionAnswers.length > 0) && (
              <dl className={returnCss.itemProperties}>
                {(!!item.rejectMessage || !!item.reason) && (
                  <>
                    <div
                      className={classNames(
                        returnCss.itemProperty,
                        returnCss.itemPropertyName,
                      )}
                    >
                      {item.rejectMessage
                        ? "Reject reason"
                        : `${returnTypeName(return_.type, true)} reasons`}
                    </div>
                    {questionAnswers.length <= 0 && (
                      <Text className={returnCss.itemPropertyValue}>
                        {item.rejectMessage || item.reason}
                      </Text>
                    )}
                  </>
                )}
                {!!item.flaggedReason && (
                  <div className={returnCss.itemProperty}>
                    <dt className={returnCss.itemPropertyName}>
                      Flagged reason
                    </dt>
                    <dd className={returnCss.itemPropertyValue}>
                      {item.flaggedReason}
                    </dd>
                  </div>
                )}
                {!!item.manualReviewReason && (
                  <div className={returnCss.itemProperty}>
                    <dt className={returnCss.itemPropertyName}>
                      Manual review reason
                    </dt>
                    <dd className={returnCss.itemPropertyValue}>
                      {item.manualReviewReason}
                    </dd>
                  </div>
                )}
                <Flex className={returnCss.questions} flexDirection="column">
                  {questionAnswers.map((answer, index) => (
                    <Flex
                      className={returnCss.question}
                      flexDirection="column"
                      key={index}
                    >
                      <Text fontSize="xs" fontWeight="regular">
                        {answer.questionText}
                      </Text>
                      <Text fontSize="xs" fontWeight="thin">
                        {answer.answer[0].toUpperCase() +
                          answer.answer.slice(1)}
                      </Text>
                    </Flex>
                  ))}
                </Flex>
              </dl>
            )}
            {shouldShowItemDisposition && (
              <Flex
                className={classNames(detailsCardCss.itemDetailsContainer)}
                dir="column"
                gap="sm"
              >
                <Flex dir="column" gap="lg" mt="lg">
                  <Flex align="center" justify="space-between">
                    <Text
                      fontSize="xs"
                      fontWeight="semibold"
                      textColor="primary"
                    >
                      Item disposition
                    </Text>
                    {team?.settings.returnItemDisposition.enabled && (
                      <RedoButton
                        hierarchy="link"
                        onClick={() =>
                          setEditSingleItemDispositionModalOpen({
                            open: true,
                            item,
                          })
                        }
                        size="xs"
                        text="Edit"
                      />
                    )}
                  </Flex>
                  <Flex dir="column" gap="xl">
                    <Flex dir="column">
                      <Text
                        fontSize="xs"
                        fontWeight="medium"
                        textColor="primary"
                      >
                        Grade
                      </Text>
                      {returnLoadPending ? (
                        <SkeletonText length={16} />
                      ) : (
                        <Text
                          fontSize="xs"
                          fontWeight="regular"
                          textColor="secondary"
                        >
                          {item.merchant_grade
                            ? item.merchant_grade
                            : itemDispositionEmptyValue}
                        </Text>
                      )}
                    </Flex>
                    <Flex dir="column">
                      <Text
                        fontSize="xs"
                        fontWeight="medium"
                        textColor="primary"
                      >
                        Outcome
                      </Text>
                      {returnLoadPending ? (
                        <SkeletonText length={16} />
                      ) : (
                        <Text
                          fontSize="xs"
                          fontWeight="regular"
                          textColor="secondary"
                        >
                          {item.merchant_outcome
                            ? item.merchant_outcome
                            : itemDispositionEmptyValue}
                        </Text>
                      )}
                    </Flex>
                    <Flex dir="column">
                      <Text
                        fontSize="xs"
                        fontWeight="medium"
                        textColor="primary"
                      >
                        Notes
                      </Text>
                      {returnLoadPending ? (
                        <SkeletonText length={16} />
                      ) : item.merchant_notes ? (
                        <MessageBubble
                          alignment={MessageBubbleAlignment.LEFT}
                          theme={MessageBubbleTheme.LIGHT}
                        >
                          <Text
                            fontSize="sm"
                            fontWeight="regular"
                            textColor="primary"
                          >
                            {item.merchant_notes}
                          </Text>
                        </MessageBubble>
                      ) : (
                        <Text
                          fontSize="sm"
                          fontWeight="regular"
                          textColor="secondary"
                        >
                          {itemDispositionEmptyValue}
                        </Text>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            )}

            {(customerComments.length > 0 ||
              imagesFromCustomer.length > 0 ||
              videosFromCustomer.length > 0) && (
              <div className={returnCss.itemComments}>
                {customerComments.length > 0 && (
                  <>
                    <Text
                      fontSize="xs"
                      fontWeight="semibold"
                      textColor="primary"
                    >
                      Customer comments
                    </Text>
                    <Text color="secondary" fontSize="xs" fontWeight="thin">
                      {`"${customerComments.join("\n")}"`}
                    </Text>
                  </>
                )}
                {imagesFromCustomer.length > 0 && (
                  <div className={returnCss.itemCommentImages}>
                    {imagesFromCustomer.map((image: string) => (
                      <ExpandableImage
                        className={returnCss.itemCommentImage}
                        key={image}
                        src={image}
                      />
                    ))}
                  </div>
                )}
                {videosFromCustomer.length > 0 && (
                  <div className={returnCss.itemCommentImages}>
                    {videosFromCustomer.map((video: string, index: number) => (
                      <ExpandableImage
                        className={returnCss.itemCommentImage}
                        key={index}
                        src={video}
                      />
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
});

function itemHasSharedDiscount(item: any, order?: Order) {
  if (!order) {
    return false;
  }
  const shopifyLineItem = order?.shopify?.line_items?.find(
    (lineItem) => lineItem.id === +item.line_item_id,
  );
  const associatedDiscounts = shopifyLineItem?.discount_allocations.map(
    (discount: any) => discount.discount_application_index,
  );
  let hasSharedDiscount = false;

  for (const lineItem of order?.shopify?.line_items || []) {
    if (lineItem.id === +item.line_item_id) {
      continue;
    }
    hasSharedDiscount = !!lineItem?.discount_allocations?.find(
      (discount: any) =>
        associatedDiscounts.includes(discount.discount_application_index),
    );
    if (hasSharedDiscount) {
      return true;
    }
  }
  return hasSharedDiscount;
}

function getAdjustmentTextAndValue(
  adjustmentValue: number,
  isGreenReturn: boolean,
  itemType?: string,
) {
  let badge = "";
  let currencyText = "";
  if (isGreenReturn) {
    badge = "";
    currencyText = CURRENCY_FORMAT().format(adjustmentValue);
  } else {
    if (adjustmentValue > 0 && !isGreenReturn && itemType) {
      badge = `${itemType} bonus `;
    }
    currencyText = CURRENCY_FORMAT().format(adjustmentValue);
  }
  return { badge, text: currencyText };
}
