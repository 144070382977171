import { camelToSnakeCase } from "@redotech/util/string";

export enum TextMessageType {
  SMS = "sms",
  MMS = "mms",
}

export const textMessageTypeToFriendly: Record<TextMessageType, string> = {
  [TextMessageType.SMS]: "SMS",
  [TextMessageType.MMS]: "MMS",
};

/**
 * Returns the regex to find a template variable, optionally with its default value
 *
 * @capture-groups
 *  1. variable name
 *  2. variable default, if present
 */
export function getTemplateVariableRegex({
  variable,
  flags,
  captureGroupNames = { variable: "variable", defaultValue: "defaultValue" },
  allowAngleBrackets = true,
  global = false,
}: {
  variable?: string;
  flags?: string;
  captureGroupNames?: { variable: string; defaultValue: string };
  allowAngleBrackets?: boolean;
  global?: boolean;
}) {
  const variableStartRegex = allowAngleBrackets ? "(?:<|{{)" : "{{";
  const variableEndRegex = allowAngleBrackets ? "(?:>|}})" : "}}";
  const whiteSpaceRegex = "\\s*";
  const defaultValueRegex = [
    "\\|",
    whiteSpaceRegex,
    `\`(?<${captureGroupNames?.defaultValue ?? "defaultValue"}>[^\`>}]+)\``,
    whiteSpaceRegex,
  ].join("");

  // Combine user-provided flags with global flag if needed
  const finalFlags = global ? `${flags || ""}g` : flags;

  return new RegExp(
    [
      variableStartRegex,
      whiteSpaceRegex,
      `(?<${captureGroupNames?.variable ?? "variable"}>${
        variable ? camelToSnakeCase(variable) : "\\S+"
      })`,
      whiteSpaceRegex,
      `(?:${defaultValueRegex})?`,
      variableEndRegex,
    ].join(""),
    finalFlags,
  );
}
