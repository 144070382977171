import { Team } from "@redotech/redo-model/team";
import Quill from "quill";

export const getPdpUrl = (
  team: Team,
  productHandle: string,
  variantId?: string,
) => {
  return `https://${team?.storeUrl}/products/${productHandle}${variantId ? `?variant=${variantId}` : ""}`;
};

/* Edit history of Quill editor by find-replace on attributes. Used to clear the formatting of the macro variables. */
const findReplaceQuillInsertAttribute = (
  quill: Quill,
  attrName: string,
  attrValueToMatch: string | any,
  attrValueToSub: string | any,
) => {
  const content = quill.getContents();
  const ops = content.ops;

  for (let i = 0; i < ops.length; i++) {
    const op = ops[i];
    if (typeof op.insert === "string") {
      const attributes = op.attributes;

      if (attributes && attributes[attrName] === attrValueToMatch) {
        attributes[attrName] = attrValueToSub;
      }
    }
  }

  quill.setContents(quill.getContents());
};

export const clearFormattingFromMacroAutomationsText = (quill: Quill) => {
  findReplaceQuillInsertAttribute(quill, "color", "#5f45e2", "black");
  findReplaceQuillInsertAttribute(quill, "background", "#ebe9fd", "inherit");
};

export const clearColoringAtCursor = (
  quill: Quill,
  newCursorPosition: number,
) => {
  quill.setSelection(newCursorPosition, 0);
  quill.format("color", false);
  quill.format("background", false);
};
