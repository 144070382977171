import { useAnimateHeight } from "@redotech/react-animation/transition";
import { useRequiredContext } from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { useHandler } from "@redotech/react-util/hook";
import { RedoMerchantRpcClientContext } from "@redotech/redo-merchant-app-common/rpc-client";
import { Order, Provider } from "@redotech/redo-model/order";
import { Return } from "@redotech/redo-model/return";
import { PickupPayer } from "@redotech/redo-model/return-flow";
import { closedReturnStatuses } from "@redotech/redo-model/return-status";
import { ReturnTotals } from "@redotech/redo-model/return-totals-calculator";
import { RedoTextInput } from "@redotech/redo-web/arbiter-components/input/redo-text-input";
import { RedoToggle } from "@redotech/redo-web/arbiter-components/toggle/redo-toggle";
import ChevronDownIcon from "@redotech/redo-web/arbiter-icon/chevron-down_filled.svg";
import { CurrencyContext } from "@redotech/redo-web/currency";
import { getDateString } from "@redotech/redo-web/date-utils";
import { Divider } from "@redotech/redo-web/divider";
import { Flex } from "@redotech/redo-web/flex";
import { PageForm, PageFormSave } from "@redotech/redo-web/page-form";
import { SkeletonText } from "@redotech/redo-web/skeleton";
import { Text } from "@redotech/redo-web/text";
import { groupInput, input, numberValidator } from "@redotech/ui/form";
import * as classnames from "classnames";
import { memo, useContext, useEffect, useMemo, useState } from "react";
import { returnTypeName } from "../util";
import * as panelCss from "./return-page-right-panel.module.css";

interface SubtotalBreakdown {
  title: string;
  total: string;
}

const getNewOrderValueBreakdown = (
  returnTotals: ReturnTotals,
  advancedExchangeItems: any[],
  products: any[],
  formatCurrency: (value: number) => string,
) => {
  const newOrderValues: SubtotalBreakdown[] = [];
  const groupedItems = new Map<string, { count: number; price: number }>();

  // Group advanced exchange and variant exchange items
  for (const item of advancedExchangeItems.filter(
    (product) => !product.outOfStockDuringProcessing,
  )) {
    const key = `${item.title}:${item.itemValue}`;
    const existing = groupedItems.get(key);
    if (existing) {
      existing.count += item.quantity;
    } else {
      groupedItems.set(key, {
        count: item.quantity,
        price: Math.min(parseFloat(item.itemValue), parseFloat(item.price)),
      });
    }
  }

  // Group same item exchanges
  for (const product of products.filter(
    (product) => !product.outOfStockDuringProcessing,
  )) {
    if (product.exchange_for) {
      const price = product.price || product.exchange_for.price || 0;
      const key = `${product.exchange_for?.product_title}:${price}`;
      const existing = groupedItems.get(key);
      if (existing) {
        existing.count += product.quantity;
      } else {
        groupedItems.set(key, { count: product.quantity, price });
      }
    } else if (product.exchangeGroupItem) {
      const price = product.price || product.exchangeGroupItem.price || 0;
      const key = `${product.exchangeGroupItem.title}:${price}`;
      const existing = groupedItems.get(key);
      if (existing) {
        existing.count += product.quantity;
      } else {
        groupedItems.set(key, { count: product.quantity, price });
      }
    }
  }

  // Out of stock exchange items
  for (const item of advancedExchangeItems.filter(
    (product) => product.outOfStockDuringProcessing,
  )) {
    const key = `${item.title} (Out of stock):${item.itemValue}`;
    groupedItems.set(key, { count: item.quantity, price: 0 });
  }

  for (const product of products.filter(
    (product) => product.outOfStockDuringProcessing,
  )) {
    const price = 0;
    const key = `${product.exchange_for?.product_title || product.exchangeGroupItem?.title} (Out of stock):${price}`;
    const existing = groupedItems.get(key);
    if (existing) {
      existing.count += product.quantity;
    } else {
      groupedItems.set(key, { count: product.quantity, price });
    }
  }

  // Aggregate the grouped items
  for (const [key, value] of groupedItems) {
    const [title] = key.split(":");
    newOrderValues.push({
      title: value.count > 1 ? `${title} (x${value.count})` : title,
      total: formatCurrency(value.price * value.count),
    });
  }

  const newOrderTaxes =
    returnTotals.totalNewOrderTaxes ||
    returnTotals.advancedExchangeItemsTaxInNewOrder ||
    0;

  newOrderValues.push({
    title: "New order subtotal",
    total: formatCurrency(returnTotals.rawNewOrderValue),
  });

  if (newOrderTaxes > 0) {
    newOrderValues.push({
      title: "Taxes (estimated)",
      total: formatCurrency(newOrderTaxes),
    });
  }

  if (returnTotals.totalMerchantAdjustment !== 0) {
    newOrderValues.push({
      title: "Merchant adjustment (after tax)",
      total: formatCurrency(returnTotals.totalMerchantAdjustment),
    });
  }

  return newOrderValues;
};

// ResendGiftCardButton component moved to activity-card.tsx

export const SummaryCard = memo(function SummaryCard({
  return: return_,
  returnTotals,
  orders,
  nonZeroValueExchange,
  reload,
  onChange,
}: {
  return: Return;
  returnTotals?: ReturnTotals;
  orders: Order[];
  nonZeroValueExchange?: boolean;
  reload: () => void;
  onChange: (unsavedChanges: boolean) => void;
}) {
  const rpcClient = useRequiredContext(RedoMerchantRpcClientContext);

  const returnForm = groupInput({
    refundOriginalShipping: input<boolean>(),
    refundOriginalShippingAmount: input<string>({
      validator: numberValidator({
        min: 0.01,
        max: returnTotals?.totalOrderShipping,
      }),
    }),
  });
  const save: PageFormSave = useHandler(async (signal) => {
    const { refundOriginalShipping, refundOriginalShippingAmount } =
      returnInput.inputs;

    await rpcClient.updateReturnWithShippingRefund({
      id: return_?._id,
      refundOriginalShipping: refundOriginalShipping.value || false,
      refundOriginalShippingAmount: parseFloat(
        refundOriginalShippingAmount.value,
      ),
    });
    reload();
  });

  const initialValue = {
    refundOriginalShipping: return_.refundOriginalShipping || false,
    refundOriginalShippingAmount: (
      return_.refundOriginalShippingAmount || 0
    ).toFixed(2),
  };

  const returnInput = useInput(returnForm, initialValue);
  const { refundOriginalShipping, refundOriginalShippingAmount } =
    returnInput.inputs;
  const { formatCurrency } = useContext(CurrencyContext);
  const deductedPickupFee =
    return_?.pickupMetrics?.rate &&
    return_?.pickup?.pickupPayer === PickupPayer.CUSTOMER_DEDUCT
      ? return_?.pickupMetrics?.rate
      : 0;

  // Prevent the page from exploding when there's an improperly formatted pickup date
  const pickupDateString = useMemo(() => {
    if (!return_?.pickup) {
      return undefined;
    }
    try {
      return getDateString(Temporal.PlainDate.from(return_.pickup.pickupDate));
    } catch {
      console.warn(`Could not parse pickup date: ${return_.pickup.pickupDate}`);
      return return_.pickup.pickupDate;
    }
  }, [return_?.pickup]);

  const isAdvancedExchange =
    return_?.advancedExchangeItems && return_?.advancedExchangeItems.length > 0;

  // TODO: update this if we ever support refunding shipping for multiple orders
  const primaryOrder = orders[0];
  const primaryShippingLine = useMemo(
    () => primaryOrder?.shopify?.shipping_lines?.[0],
    [primaryOrder],
  );

  useEffect(() => {
    onChange(returnInput.changed);
  }, [onChange, returnInput.changed]);

  useEffect(() => {
    if (
      !!refundOriginalShipping.value &&
      !parseFloat(refundOriginalShippingAmount.value)
    ) {
      refundOriginalShippingAmount.setValue(
        (returnTotals?.totalOrderShipping || 0).toFixed(2),
      );
    }
  }, [
    refundOriginalShipping.value,
    refundOriginalShippingAmount,
    returnTotals?.totalOrderShipping,
  ]);

  if (!return_) {
    return (
      <Flex className={panelCss.cardPadding} dir="column" gap="xl">
        <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
          Summary
        </Text>
        <SkeletonText animated length={25} />
      </Flex>
    );
  }

  const showRefundShipping =
    return_?.provider === Provider.SHOPIFY &&
    !!returnTotals?.totalOrderShipping &&
    !closedReturnStatuses.includes(return_.status);

  return (
    <Flex className={panelCss.cardPadding} dir="column" gap="xl">
      <Flex align="center" justify="space-between">
        <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
          Summary
        </Text>
      </Flex>
      <Flex justify="space-between">
        <Text fontSize="xs" fontWeight="regular" textColor="tertiary">
          {returnTypeName(return_?.type, true)} value
        </Text>
        <Text fontSize="xs" fontWeight="regular" textColor="tertiary">
          {return_ && formatCurrency(returnTotals?.totalReturnValue || 0)}
        </Text>
      </Flex>
      {!!returnTotals?.newOrderValue && (
        <SubtotalCollapseSection
          subtotals={getNewOrderValueBreakdown(
            returnTotals,
            return_?.advancedExchangeItems ?? [],
            return_?.products ?? [],
            formatCurrency,
          )}
          total={formatCurrency(returnTotals?.newOrderValue)}
        />
      )}
      {isAdvancedExchange && !!returnTotals?.nonAccruableCredit && (
        <Flex justify="space-between">
          <Text fontSize="xs" fontWeight="regular" textColor="tertiary">
            Non-accruable credit
          </Text>
          <Text fontSize="xs" fontWeight="regular" textColor="tertiary">
            -{return_ && formatCurrency(returnTotals?.nonAccruableCredit)}
          </Text>
        </Flex>
      )}
      {return_?.refundOriginalShipping &&
        !!return_.refundOriginalShippingAmount && (
          <Flex justify="space-between">
            <Text fontSize="xs" fontWeight="regular" textColor="tertiary">
              Shipping refund
            </Text>
            <Text fontSize="xs" fontWeight="regular" textColor="tertiary">
              {return_ && formatCurrency(return_.refundOriginalShippingAmount)}
            </Text>
          </Flex>
        )}
      {return_?.labelDeductedFromCredit &&
        !!(return_.totals.fee - deductedPickupFee) && (
          <Flex justify="space-between">
            <Text fontSize="xs" fontWeight="regular" textColor="tertiary">
              Return shipping
            </Text>
            <Text fontSize="xs" fontWeight="regular" textColor="tertiary">
              {return_ && formatCurrency(return_.totals.fee)}
            </Text>
          </Flex>
        )}
      {return_?.pickup && deductedPickupFee > 0 && (
        <Flex justify="space-between">
          <Text fontSize="xs" fontWeight="regular" textColor="tertiary">
            Package pickup on {pickupDateString}
          </Text>
          <Text fontSize="xs" fontWeight="regular" textColor="tertiary">
            {return_ && formatCurrency(deductedPickupFee)}
          </Text>
        </Flex>
      )}
      {(!!returnTotals?.displayCharge ||
        !!returnTotals?.totalStoreCredit ||
        !!returnTotals?.totalRefundWithoutShipping ||
        !!return_?.totals.fee ||
        (return_?.pickup && !deductedPickupFee)) && <Divider />}
      {!!returnTotals?.displayCharge && (
        <Flex justify="space-between">
          <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
            {`Outstanding Balance${
              return_?.options?.waiveDraftFee || nonZeroValueExchange
                ? " (waived)"
                : return_?.paymentIntents?.some((pi) => pi.type === "upsell")
                  ? " (paid)"
                  : ""
            }`}
          </Text>
          <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
            {return_ && formatCurrency(returnTotals?.displayCharge)}
          </Text>
        </Flex>
      )}
      {!!returnTotals?.totalStoreCredit && (
        <Flex justify="space-between">
          <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
            Store credit
          </Text>
          <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
            {return_ && formatCurrency(returnTotals?.totalStoreCredit)}
          </Text>
        </Flex>
      )}
      {!!returnTotals?.totalRefundWithShipping && (
        <Flex justify="space-between">
          <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
            Refund
          </Text>
          <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
            {return_ && formatCurrency(returnTotals?.totalRefundWithShipping)}
          </Text>
        </Flex>
      )}
      {return_?.pickup && !deductedPickupFee && (
        <Flex justify="space-between">
          <div>
            <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
              Package pickup on {pickupDateString}
            </Text>
            {return_.pickup.pickupPayer !== PickupPayer.CUSTOMER_DEDUCT ? (
              <Text fontSize="xxs" fontWeight="regular" textColor="tertiary">
                Paid for by{" "}
                {return_.pickup.pickupPayer === PickupPayer.MERCHANT
                  ? "merchant"
                  : "customer"}
              </Text>
            ) : undefined}
          </div>
          <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
            {return_?.pickupMetrics?.rate &&
              formatCurrency(return_.pickupMetrics.rate)}
          </Text>
        </Flex>
      )}
      {!!primaryOrder && showRefundShipping && (
        <PageForm initial={initialValue} input={returnInput} save={save}>
          <RedoToggle
            label="Refund original shipping"
            setValue={(value: boolean) =>
              refundOriginalShipping.setValue(value)
            }
            value={refundOriginalShipping.value || false}
          />
          {refundOriginalShipping.value && (
            <Flex dir="column" gap="xl" pl="5xl">
              <Flex dir="column" gap="xxs">
                <Text fontSize="xs">Original Shipping</Text>
                <Text fontSize="xs">
                  {primaryShippingLine?.title} -{" "}
                  {formatCurrency(returnTotals?.totalOrderShipping || 0)}
                </Text>
              </Flex>
              <RedoTextInput
                inputTypeHint="number"
                label="Refund Amount"
                onBlur={() =>
                  refundOriginalShippingAmount.setValue(
                    parseFloat(refundOriginalShippingAmount.value).toFixed(2),
                  )
                }
                setValue={(value: string) =>
                  refundOriginalShippingAmount.setValue(value)
                }
                size="sm"
                step={0.01}
                value={refundOriginalShippingAmount.value || ""}
              />
            </Flex>
          )}
        </PageForm>
      )}
    </Flex>
  );
});

const SubtotalCollapseSection = memo(function SubtotalCollapseSection({
  total,
  subtotals,
  defaultCollapsed = true,
}: {
  total: string | number;
  subtotals: { title: string; total: string | number }[];
  defaultCollapsed?: boolean;
}) {
  const [element, setElement] = useState<HTMLElement | null>(null);
  const [animateStyles, animating] = useAnimateHeight(element);
  const [collapsed, setCollapsed] = useState<boolean>(defaultCollapsed);

  const onClick = useHandler(() => setCollapsed((value) => !value));

  return (
    <Flex dir="column" gap="xl" ref={setElement} style={animateStyles}>
      <Flex justify="space-between" onClick={onClick}>
        <Flex>
          <Text fontSize="xs" fontWeight="regular" textColor="tertiary">
            New order value
          </Text>
          <ChevronDownIcon
            className={classnames(panelCss.collapseIcon, {
              [panelCss.collapsed]: collapsed,
            })}
          />
        </Flex>
        <Text fontSize="xs" fontWeight="regular" textColor="tertiary">
          {total}
        </Text>
      </Flex>
      {(animating || !collapsed) &&
        subtotals.map((subtotal, index) => (
          <Flex
            justify="space-between"
            key={index}
            style={{ paddingLeft: "8px" }}
          >
            <Text fontSize="xs" fontWeight="regular" textColor="tertiary">
              {subtotal.title}
            </Text>
            <Text fontSize="xs" fontWeight="regular" textColor="tertiary">
              {subtotal.total}
            </Text>
          </Flex>
        ))}
    </Flex>
  );
});
