import { Currency } from "@redotech/money/currencies";
import { MetricDefinitionPartZodSchema } from "@redotech/redo-model/checkouts/metric-definition";
import { z } from "zod";

export const getCheckoutOptimizationSummaryMetrics = {
  input: z.null(),
  output: z.object({
    annualizedCumulativeImpactToRevenuePerCheckoutFromAbTestsLastYear: z.object(
      {
        shopCurrency: z.nativeEnum(Currency),
        value: z.number().optional(),
        calculationUsed: z.array(MetricDefinitionPartZodSchema).optional(),
      },
    ),
    checkoutConversionRateOverLast30Days: z.number().optional(),
    averageShippingRevenuePerOrderLast30Days: z.object({
      shopCurrency: z.nativeEnum(Currency),
      value: z.number().optional(),
    }),
  }),
};
