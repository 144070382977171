export enum ProviderType {
  Exchange = "exchange",
  StoreCredit = "storeCredit",
  Refund = "refund",
}

export interface Integration {
  _id: string;
  team: string;
  createdAt: Date;
  updatedAt: Date;
  provides: ProviderType[];
}

export enum SupportIntegrations {
  GMAIL = "Gmail",
  OUTLOOK = "Outlook",
  FACEBOOK = "Facebook",
  INSTAGRAM = "Instagram",
  ATTENTIVE = "Attentive",
  POSTSCRIPT = "Postscript",
}
