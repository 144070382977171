import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import * as classnames from "classnames";
import { memo } from "react";
import XIcon from "../../arbiter-icon/x-close.svg";
import * as darkModeCss from "../../theme/component-colors-dark.module.css";
import { RedoButton } from "../buttons/redo-button";
import * as styles from "./redo-feature-notification-card.module.css";

export interface RedoFeatureNotificationCardProps {
  title: string;
  description: string;
  image?: string;
  actionText?: string;
  onDismiss?: () => void;
  onAction?: () => void;
}

export const RedoFeatureNotificationCard = memo(
  function RedoFeatureNotificationCard({
    title,
    description,
    image,
    actionText = "Learn more",
    onDismiss,
    onAction,
  }: RedoFeatureNotificationCardProps) {
    const handleAction = () => {
      onAction?.();
    };

    return (
      <Flex
        bgColor="tertiary"
        borderColor="primary"
        borderStyle="solid"
        borderWidth="1px"
        className={classnames(styles.container, darkModeCss.darkMode)}
        flexDirection="column"
        gap="xl"
        px="xl"
        py="2xl"
        radius="lg"
        shadow="lg"
      >
        <Flex flexDirection="column" gap="xs">
          <Text fontSize="sm" fontWeight="semibold" textColor="primary">
            {title}
          </Text>
          <Text fontSize="sm" textColor="secondary">
            {description}
          </Text>
        </Flex>

        {image && (
          <Flex radius="md">
            <img alt={title} className={styles.image} src={image} />
          </Flex>
        )}

        <Flex flexDirection="row" gap="lg" justify="space-between">
          <RedoButton
            hierarchy="link"
            onClick={handleAction}
            text={actionText}
            theme="action"
          />
        </Flex>
        {onDismiss && (
          <Flex className={styles.closeX}>
            <RedoButton IconLeading={XIcon} onClick={onDismiss} size="sm" />
          </Flex>
        )}
      </Flex>
    );
  },
);
