import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import { useTriggerLoad } from "@redotech/react-util/load";
import { RedoMerchantClient } from "@redotech/redo-merchant-app-common/client";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { updateEmailExclusions } from "@redotech/redo-merchant-app-common/client/team";
import {
  ReloadTeamContext,
  TeamContext,
} from "@redotech/redo-merchant-app-common/team";
import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { getPrimaryCustomerEmail } from "@redotech/redo-model/customer";
import { CloseActionMethod, Team } from "@redotech/redo-model/team";
import { alertOnFailure } from "@redotech/redo-web/alert";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { Modal, ModalSize } from "@redotech/redo-web/modal";
import { filterTruthy } from "@redotech/util/array";
import { Dispatch, memo, SetStateAction, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  bulkAddTags,
  bulkUpdateConversationsStatus,
  updateConversation,
} from "../../client/conversations";
import { UpdateConversationStateContext } from "../context/update-conversations-context";
import * as markSpamModalCss from "./spam-modals.module.css";

async function handleMarkTicketsSpam({
  client,
  team,
  conversations,
  setActiveConversation,
  setConversationsInProximity,
  navigate,
  reloadTeam,
  inDetailView,
  cleanupFn,
  removeConversation,
  nextConversationInList,
  prevConversationInList,
}: {
  client: RedoMerchantClient;
  team: Team;
  conversations: ExpandedConversation[];
  setActiveConversation: (val: ExpandedConversation | undefined) => void;
  setConversationsInProximity?: Dispatch<
    SetStateAction<ExpandedConversation[]>
  >;
  navigate: (url: string) => void;
  reloadTeam?: () => void;
  inDetailView: boolean;
  cleanupFn?: (clearSelected?: boolean) => void;
  removeConversation: (conversation: ExpandedConversation) => void;
  nextConversationInList?: ExpandedConversation;
  prevConversationInList?: ExpandedConversation;
}) {
  if (conversations.length === 1) {
    setConversationsInProximity &&
      setConversationsInProximity((prev: ExpandedConversation[]) => [
        ...prev.filter((conv) => conversations[0]._id !== conv._id),
      ]);
    if (conversations[0].status !== "closed") {
      conversations[0].status = "closed";
      removeConversation(conversations[0]);
    }
    const addressToExclude = getPrimaryCustomerEmail(
      conversations[0]?.customer,
    );

    void alertOnFailure("Failed to update conversation")((async) =>
      updateConversation(client, conversations[0], {
        status: "closed",
        tags: [...(conversations[0].tagIds || []), { name: "spam" }],
      }),
    );

    if (
      addressToExclude &&
      team.settings.support?.enabled &&
      !team.settings.support?.excludedEmails?.includes(addressToExclude)
    ) {
      await alertOnFailure("Failed to add sender to exclude filter")(() =>
        updateEmailExclusions(client, {
          exclusionList: [
            ...(team.settings.support?.excludedEmails || []),
            addressToExclude,
          ],
        }),
      );
      reloadTeam?.();
    }

    if (inDetailView) {
      if (team.settings.support?.closeAction === CloseActionMethod.NEXT) {
        setActiveConversation(nextConversationInList || prevConversationInList);
      } else if (
        team.settings.support?.closeAction === CloseActionMethod.STAY
      ) {
        setActiveConversation({ ...conversations[0], status: "closed" });
      } else if (
        team.settings.support?.closeAction === CloseActionMethod.TABLE
      ) {
        const searchParams = new URLSearchParams(window.location.search);
        const ACTIVE_CONVERSATION_QUERY_PARAMETER = "activeConversationId";
        searchParams.delete(ACTIVE_CONVERSATION_QUERY_PARAMETER);
        navigate(`${location.pathname}?${searchParams}`);
        setActiveConversation(undefined);
      }
    }

    amplitude.logEvent("markSpam-conversation", {
      mode: "single",
      conversationIds: [conversations[0]._id],
    });
  } else if (conversations.length > 1) {
    const updatePromises = [];

    const conversationIdsToClose = conversations
      .filter((conversation) => conversation.status !== "closed")
      .map((conversation) => conversation._id);
    if (conversationIdsToClose.length > 0) {
      updatePromises.push(
        bulkUpdateConversationsStatus(client, {
          conversationIds: conversationIdsToClose,
          status: "closed",
        }),
      );
    }

    const conversationIdsToTagSpam = conversations
      .filter((conversation) =>
        (conversation.tagIds || []).every((tag) => tag.name !== "spam"),
      )
      .map((conversation) => conversation._id);
    updatePromises.push(
      alertOnFailure("Failed to add spam tag")(async () =>
        bulkAddTags(client, {
          conversationIds: conversationIdsToTagSpam,
          tags: [{ name: "spam" }],
        }),
      ),
    );

    const emailsToExclude = filterTruthy(
      conversations.map((conversation) =>
        getPrimaryCustomerEmail(conversation.customer),
      ),
    );

    if (team.settings.support?.enabled) {
      void alertOnFailure("Failed to add sender to exclude filter")(() =>
        updateEmailExclusions(client, {
          exclusionList: [
            ...(team.settings.support?.excludedEmails ?? []),
            ...emailsToExclude,
          ],
        }),
      );
      amplitude.logEvent("markSpam-conversation", {
        mode: "multiple",
        conversationIds: conversations.map((conversation) => conversation._id),
      });
    }
    await Promise.all(updatePromises);
  }
  cleanupFn && cleanupFn(true);
}

export const MarkSpamModal = memo(function MarkSpamModal({
  open,
  setOpen,
  conversations,
  setActiveConversation,
  setConversationsInProximity,
  inDetailView,
  cleanupFn,
  nextConversationInList,
  prevConversationInList,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  conversations: ExpandedConversation[];
  setActiveConversation: (val: ExpandedConversation | undefined) => void;
  setConversationsInProximity?: Dispatch<
    SetStateAction<ExpandedConversation[]>
  >;
  inDetailView: boolean;
  cleanupFn?: (clearSelected?: boolean) => void;
  nextConversationInList?: ExpandedConversation;
  prevConversationInList?: ExpandedConversation;
}) {
  const navigate = useNavigate();
  const client = useRequiredContext(RedoMerchantClientContext);
  const reloadTeam = useContext(ReloadTeamContext);
  const team = useRequiredContext(TeamContext);
  const { removeConversation } = useRequiredContext(
    UpdateConversationStateContext,
  );
  const markableConversations = useMemo(
    () =>
      conversations.filter((conversation) => {
        const primaryEmail = getPrimaryCustomerEmail(conversation.customer);

        return (
          conversation.status !== "closed" ||
          !conversation.tagIds?.some((tag) => tag.name === "spam") ||
          (primaryEmail &&
            !team.settings.support?.excludedEmails?.includes(primaryEmail))
        );
      }),
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [conversations],
  );
  const [markSpamLoad, doMarkSpam] = useTriggerLoad(async (signal) => {
    await handleMarkTicketsSpam({
      client,
      team,
      conversations: markableConversations,
      setActiveConversation,
      setConversationsInProximity,
      navigate,
      reloadTeam,
      inDetailView,
      removeConversation,
      nextConversationInList,
      prevConversationInList,
    });
    setOpen(false);
  });

  const plural = markableConversations.length > 1;

  const footer = (
    <div className={markSpamModalCss.actionButtonsContainer}>
      <Button onClick={() => setOpen(false)} theme={ButtonTheme.OUTLINED}>
        Cancel
      </Button>
      <Button
        onClick={() => doMarkSpam()}
        pending={markSpamLoad.pending}
        theme={ButtonTheme.PRIMARY}
      >
        Yes, mark ticket{plural ? "s" : ""} as spam
      </Button>
    </div>
  );

  return (
    <Modal
      footer={footer}
      onClose={() => {
        cleanupFn && cleanupFn();
        setOpen(false);
      }}
      open={open}
      showHeaderBorder={false}
      size={ModalSize.SMALL}
      title={
        plural
          ? `Mark ${markableConversations.length} tickets as spam?`
          : `Mark ticket as spam?`
      }
    >
      <div className={markSpamModalCss.actionModalContentContainer}>
        <div>
          {plural ? "These tickets" : "This ticket"} will be closed and tagged
          "spam", and the
          {plural ? " senders' email addresses " : " sender's email address "}
          will be added to your team's exclusion list.
        </div>
      </div>
    </Modal>
  );
});
