import { useRequiredContext } from "@redotech/react-util/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { RedoSecondaryNavItem } from "@redotech/redo-web/arbiter-components/navigation/secondary/redo-secondary-nav-item";
import { memo } from "react";

export const AnalyticsItems = memo(function AnalyticsItems({
  pathRoot,
}: {
  pathRoot: string;
}) {
  const team = useRequiredContext(TeamContext);

  const urlPrefix = `${pathRoot}/analytics`;

  const returnsEnabled = team?.settings.returns?.enabled;
  const packageProtectionEnabled = team?.settings.packageProtection?.enabled;
  const supportEnabled = team?.settings.support?.enabled;
  const orderTrackingEnabled = team?.settings.orderTracking?.enabled;

  return (
    <>
      {returnsEnabled && (
        <RedoSecondaryNavItem
          action={{ type: "link", href: `${urlPrefix}/returns-claims` }}
          friendlyName="Returns & Claims"
          id="analytics-returns-claims"
          isNew
        />
      )}
      <RedoSecondaryNavItem
        action={{ type: "link", href: `${urlPrefix}/reports` }}
        friendlyName="Reports"
        id="analytics-reports"
      />
      <RedoSecondaryNavItem
        action={{ type: "link", href: `${urlPrefix}/operations` }}
        friendlyName="Operations"
        id="analytics-operations"
      />
      {returnsEnabled && (
        <RedoSecondaryNavItem
          action={{ type: "link", href: `${urlPrefix}/returns` }}
          friendlyName="Returns"
          id="analytics-returns"
        />
      )}
      {packageProtectionEnabled && (
        <RedoSecondaryNavItem
          action={{ type: "link", href: `${urlPrefix}/claims` }}
          friendlyName="Claims"
          id="analytics-claims"
        />
      )}
      {supportEnabled && (
        <RedoSecondaryNavItem
          action={{ type: "link", href: `${urlPrefix}/support` }}
          friendlyName="Support"
          id="analytics-support"
        />
      )}
      {orderTrackingEnabled && (
        <RedoSecondaryNavItem
          action={{ type: "link", href: `${urlPrefix}/order-tracking` }}
          friendlyName="Order tracking"
          id="analytics-order-tracking"
        />
      )}
    </>
  );
});
