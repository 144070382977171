import { TextMessageType } from "@redotech/redo-model/sms";
import { z } from "zod";

export type EstimatedSmsCost = z.infer<typeof estimatedSmsCost>;

const estimatedSmsCost = z.object({
  messageType: z.nativeEnum(TextMessageType),
  partCount: z.number(),
  carrierFeeForMessageTypePart: z.number(),
  redoCostForMessageTypePart: z.number(),
});

export const getEstimatedSmsCost = {
  input: z.object({
    message: z.string(),
    containsAttachments: z.boolean(),
    linksShortened: z.boolean(),
  }),
  output: estimatedSmsCost.nullable(),
};
