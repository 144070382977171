import * as classNames from "classnames";
import { JSXElementConstructor, memo } from "react";
import { Flex } from "../../../flex";
import { RedoIcon, RedoIconColor } from "../../icon/redo-icon";
import * as navigationCss from "../redo-navigation.module.css";

export const NavIconSwap = memo(function IconSwap({
  isSelected,
  UnfilledIcon,
  FilledIcon,
  size,
}: {
  size: number;
  isSelected: boolean;
  UnfilledIcon: JSXElementConstructor<any>;
  FilledIcon: JSXElementConstructor<any>;
}) {
  const color: RedoIconColor = isSelected ? "primary" : "tertiary";

  return (
    <Flex
      align="center"
      as="span"
      className={navigationCss.iconSwapper}
      style={{ minWidth: `${size}px`, minHeight: `${size}px` }}
    >
      <RedoIcon
        className={classNames(!isSelected && navigationCss.hidden)}
        color={color}
        Icon={FilledIcon}
        size={size}
      />
      <RedoIcon
        className={classNames(isSelected && navigationCss.hidden)}
        color={color}
        Icon={UnfilledIcon}
        size={size}
      />
    </Flex>
  );
});
