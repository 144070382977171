import { useRequiredContext } from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { RedoModal } from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import { LabelTheme } from "@redotech/redo-web/labeled-input";
import { FormTextInput } from "@redotech/redo-web/text-input";
import { groupInput, input } from "@redotech/ui/form";
import { emailValidator } from "@redotech/web-util/form";
import { memo, useState } from "react";
import { sendAddStoreEmail } from "./client/auth";

import { useHandler } from "@redotech/react-util/hook";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { Flex } from "@redotech/redo-web/flex";
import * as teamSwitcherLoginModalCss from "./team-switcher-login-modal.module.css";

const emailForm = groupInput({ email: input({ validator: emailValidator }) });

export const TeamSwitcherLoginModal = memo(function TeamSwitcherLoginModal({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const [showEmailLoginMessage, setShowEmailLoginMessage] = useState(false);
  const [showEmailLoginErrorMessage, setShowEmailLoginErrorMessage] =
    useState(false);

  const emailInput = useInput(emailForm, { email: "" });
  const { email } = emailInput.inputs;

  const client = useRequiredContext(RedoMerchantClientContext);
  const team = useRequiredContext(TeamContext);

  const handleEmailLogin = useHandler(async () => {
    try {
      await sendAddStoreEmail(client, {
        email: email.value,
        baseStoreId: team._id,
      });
    } catch (error) {
      setShowEmailLoginMessage(false);
      setShowEmailLoginErrorMessage(true);
      return;
    }
    setShowEmailLoginErrorMessage(false);
    setShowEmailLoginMessage(true);
  });

  return (
    <RedoModal
      isOpen={open}
      onModalCloseRequested={() => setOpen(false)}
      primaryButton={{
        text: "Send email sign in link",
        onClick: handleEmailLogin,
        disabled: !email.value || !!emailInput.allErrors.length,
      }}
      secondaryButton={{ text: "Back", onClick: () => setOpen(false) }}
      title="Sign in to another store"
    >
      <Flex dir="column" gap="sm">
        <div className={teamSwitcherLoginModalCss.formContainer}>
          <FormTextInput
            input={email}
            label="Email address"
            labelTheme={LabelTheme.LIGHT}
            placeholder="Enter Email Address"
          />
        </div>
        <Flex
          className={teamSwitcherLoginModalCss.message}
          dir="column"
          gap="xs"
        >
          <div>
            {showEmailLoginMessage && (
              <p>
                We have sent you a login email. Please check your inbox and
                follow the link in the email to sign in.
              </p>
            )}
            {showEmailLoginErrorMessage && (
              <p>Something went wrong. Please try again or contact support.</p>
            )}
          </div>
        </Flex>
      </Flex>
    </RedoModal>
  );
});
