import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { queryStringToFilters } from "@redotech/redo-model/conversation-filters/conversation-filter-query";
import {
  AssigneesFilterType,
  ChannelFilterType,
  ConversationTagFilterType,
  CustomerTagsFilterType,
  DateFilterQueryType,
  FilterGroupFilterOption,
  FiltersStatus,
} from "@redotech/redo-model/conversation-filters/conversation-filters";
import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoTextInput } from "@redotech/redo-web/arbiter-components/input/redo-text-input";
import { RedoListItem } from "@redotech/redo-web/arbiter-components/list/redo-list";
import { RedoListDropdown } from "@redotech/redo-web/arbiter-components/list/redo-list-dropdown";
import { RedoSingleSelectDropdownInput } from "@redotech/redo-web/arbiter-components/select-dropdown/redo-single-select-dropdown-input";
import FilterLinesIcon from "@redotech/redo-web/arbiter-icon/filter-lines_filled.svg";
import {
  ConversationFilterOptionToIcon,
  ConversationFilterOptionToName,
} from "@redotech/redo-web/conversation-filters/conversation-filter-icons";
import { Flex } from "@redotech/redo-web/flex";
import { assertNever } from "@redotech/util/type";
import { memo, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebounce } from "usehooks-ts";
import { ActiveViewContext } from "../conversations-table-filters/active-view-context";
import { ActiveViewConversationCountsContext } from "../conversations-table-filters/conversation-counts-context";
import {
  AdvancedFiltersContext,
  HaveFiltersResolvedOnInitialPageLoadContext,
  SetAdvancedFiltersContext,
  SetUniqueFiltersContext,
  UniqueFiltersContext,
} from "../conversations-table-filters/filters-context";

export const ConversationsTableHeaderFilterControls = memo(
  function ConversationsTableHeaderFilterControls() {
    const currentUniqueFilters = useContext(UniqueFiltersContext);
    const setUniqueFilters = useContext(SetUniqueFiltersContext);
    const advancedFilters = useContext(AdvancedFiltersContext);
    const setAdvancedFilters = useContext(SetAdvancedFiltersContext);
    const conversationCounts = useContext(ActiveViewConversationCountsContext);
    const activeView = useContext(ActiveViewContext);

    const location = useLocation();
    const navigate = useNavigate();

    const haveFiltersResolvedOnInitialPageLoad = useContext(
      HaveFiltersResolvedOnInitialPageLoadContext,
    );

    function initSearchString() {
      if (!haveFiltersResolvedOnInitialPageLoad) {
        return "";
      }
      const searchParams = new URLSearchParams(location.search);
      const rawFilters = searchParams.get("filters");
      if (rawFilters) {
        const filters = queryStringToFilters(rawFilters);
        if (filters.search) {
          return filters.search;
        }
      }
      return "";
    }

    const [searchString, setSearchString] = useState<string>(initSearchString);

    useEffect(() => {
      setSearchString(initSearchString());
      // FIXME
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [haveFiltersResolvedOnInitialPageLoad, activeView]);

    const debouncedSearch = useDebounce(searchString, 500);

    const team = useRequiredContext(TeamContext);

    useEffect(() => {
      const urlParams = new URLSearchParams(location.search);

      const initialSearch = urlParams.get("initialSearch");
      if (initialSearch) {
        urlParams.delete("initialSearch");
        navigate({ search: urlParams.toString() });
        setSearchString(initialSearch);
      }
      // FIXME
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const isNewFocusedSearch = useRef(false);
    const onFocus = () => {
      isNewFocusedSearch.current = true;
    };

    const onBlur = () => {
      isNewFocusedSearch.current = false;
    };

    useEffect(() => {
      if (searchString.length && isNewFocusedSearch.current) {
        amplitude.logEvent("search-conversation", {
          mode: "view",
          searchString,
          activeView,
        });
        isNewFocusedSearch.current = false;
      }
      // FIXME
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearch, isNewFocusedSearch]);

    useEffect(() => {
      if (!haveFiltersResolvedOnInitialPageLoad) {
        return;
      }
      const currentSearch = currentUniqueFilters.search || "";
      if (currentSearch !== debouncedSearch) {
        setUniqueFilters({ ...currentUniqueFilters, search: debouncedSearch });
      }
    }, [
      debouncedSearch,
      setUniqueFilters,
      currentUniqueFilters,
      haveFiltersResolvedOnInitialPageLoad,
    ]);

    const [filtersDropdownButtonRef, setFiltersDropdownButtonRef] =
      useState<HTMLButtonElement | null>(null);

    function addFilter(filter: FilterGroupFilterOption) {
      switch (filter) {
        case FilterGroupFilterOption.ASSIGNEES:
          setAdvancedFilters((old) => [
            ...old,
            {
              type: FilterGroupFilterOption.ASSIGNEES,
              value: null,
              query: AssigneesFilterType.INCLUDES,
            },
          ]);
          break;
        case FilterGroupFilterOption.CHANNELS:
          setAdvancedFilters((old) => [
            ...old,
            {
              type: FilterGroupFilterOption.CHANNELS,
              value: null,
              query: ChannelFilterType.INCLUDES,
            },
          ]);
          break;
        case FilterGroupFilterOption.READ:
          setAdvancedFilters((old) => [
            ...old,
            { type: FilterGroupFilterOption.READ, value: null },
          ]);
          break;
        case FilterGroupFilterOption.CONVERSATION_TAGS:
          setAdvancedFilters((old) => [
            ...old,
            {
              type: FilterGroupFilterOption.CONVERSATION_TAGS,
              value: null,
              query: ConversationTagFilterType.ANY_OF,
            },
          ]);
          break;
        case FilterGroupFilterOption.WORDS:
          setAdvancedFilters((old) => [
            ...old,
            { type: FilterGroupFilterOption.WORDS, value: null },
          ]);
          break;
        case FilterGroupFilterOption.CUSTOMER_TAGS:
          setAdvancedFilters((old) => [
            ...old,
            {
              type: FilterGroupFilterOption.CUSTOMER_TAGS,
              value: null,
              query: CustomerTagsFilterType.ANY_OF,
            },
          ]);
          break;
        case FilterGroupFilterOption.CREATED_DATE:
          setAdvancedFilters((old) => [
            ...old,
            {
              type: FilterGroupFilterOption.CREATED_DATE,
              value: null,
              query: DateFilterQueryType.WITHIN,
            },
          ]);
          break;
        case FilterGroupFilterOption.LAST_RESPONSE_AT:
          setAdvancedFilters((old) => [
            ...old,
            {
              type: FilterGroupFilterOption.LAST_RESPONSE_AT,
              value: null,
              query: DateFilterQueryType.WITHIN,
            },
          ]);
          break;
        case FilterGroupFilterOption.CLOSED_DATE:
          setAdvancedFilters((old) => [
            ...old,
            {
              type: FilterGroupFilterOption.CLOSED_DATE,
              value: null,
              query: DateFilterQueryType.WITHIN,
            },
          ]);
          break;
        case FilterGroupFilterOption.MENTIONS:
          setAdvancedFilters((old) => [
            ...old,
            { type: FilterGroupFilterOption.MENTIONS, value: null },
          ]);
          break;
        default:
          assertNever(filter);
      }
      setFiltersDropdownOpen(false);
    }

    const options: RedoListItem<FilterGroupFilterOption>[] = useMemo(() => {
      const filterOptions = Object.values(FilterGroupFilterOption);

      return filterOptions.map<RedoListItem<FilterGroupFilterOption>>(
        (option) => ({
          id: option,
          value: option,
          type: "text",
          text: ConversationFilterOptionToName[option],
          leadingItem: {
            type: "icon",
            Icon: ConversationFilterOptionToIcon[option],
          },
        }),
      );
    }, []);

    const [filtersDropdownOpen, setFiltersDropdownOpen] = useState(false);

    const statusOptions: RedoListItem<FiltersStatus | "all">[] = useMemo(() => {
      const getItemDisplay = (status: FiltersStatus | "all") => {
        const count = conversationCounts
          ? (conversationCounts[`${activeView.name}-${status}`] ?? 0)
          : 0;
        if (status === "all") {
          return `All (${count})`;
        } else if (status === FiltersStatus.OPEN) {
          return `Open (${count})`;
        } else if (status === FiltersStatus.CLOSED) {
          return `Closed (${count})`;
        } else if (status === FiltersStatus.SNOOZED) {
          return `Snoozed (${count})`;
        } else if (status === FiltersStatus.IN_PROGRESS) {
          return `In progress (${count})`;
        } else {
          return assertNever(status);
        }
      };

      const filterStatusesToUse: (FiltersStatus | "all")[] = [
        FiltersStatus.OPEN,
        FiltersStatus.CLOSED,
        ...(team.settings.support?.useInProgressStatus
          ? [FiltersStatus.IN_PROGRESS]
          : []),
        FiltersStatus.SNOOZED,
        "all",
      ];

      return filterStatusesToUse.map<RedoListItem<FiltersStatus | "all">>(
        (s) => ({ id: s, value: s, type: "text", text: getItemDisplay(s) }),
      );
    }, [team, conversationCounts, activeView.name]);

    const selectedItem = currentUniqueFilters.status
      ? statusOptions.find((o) => o.id === currentUniqueFilters.status)
      : statusOptions.find((o) => o.id === "all");

    const isAlreadyInSearchAll =
      !currentUniqueFilters.status && advancedFilters.length === 0;

    return (
      <>
        <RedoListDropdown
          dropdownAnchor={filtersDropdownButtonRef}
          dropdownOpen={filtersDropdownOpen}
          fitToAnchor={false}
          items={options}
          itemSelected={({ item }) => addFilter(item.value)}
          setDropdownOpen={setFiltersDropdownOpen}
          size="xs"
        />
        <Flex dir="column" gap="lg">
          <Flex gap="md">
            <RedoTextInput
              onBlur={onBlur}
              onFocus={onFocus}
              placeholder="Search"
              setValue={setSearchString}
              value={searchString || ""}
            />
            <RedoButton
              hierarchy="secondary"
              IconLeading={FilterLinesIcon}
              onClick={() => {
                setFiltersDropdownOpen((old) => !old);
              }}
              ref={setFiltersDropdownButtonRef}
            />
            <RedoSingleSelectDropdownInput
              fitToAnchor={false}
              options={statusOptions}
              optionSelected={({ item }) => {
                setUniqueFilters({
                  ...currentUniqueFilters,
                  status: item.value === "all" ? null : item.value,
                });
              }}
              selectedItem={selectedItem}
              size="xs"
            />
            {debouncedSearch && !isAlreadyInSearchAll && (
              <RedoButton
                hierarchy="tertiary"
                onClick={() => {
                  setAdvancedFilters([]);
                  setUniqueFilters({ search: debouncedSearch, status: null });
                }}
                text="Search all"
              />
            )}
          </Flex>
        </Flex>
      </>
    );
  },
);
