import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";
import {
  AlignSelf,
  FONT_FAMILIES,
  FontWeight,
  OrderItemSort,
  OrderItemTableColumnType,
  PackingSlipBlockType,
  PageOrientation,
  PageSize,
  QrCodeSize,
} from "./packing-slip-builder";

const baseSectionSchema = z.object({
  sectionPadding: z.object({
    top: z.number(),
    right: z.number(),
    bottom: z.number(),
    left: z.number(),
  }),
  alignSelf: z.nativeEnum(AlignSelf).optional(),
  blockId: zExt.objectId(),
});

const fontSectionSchema = baseSectionSchema.extend({
  fontFamily: z.enum(FONT_FAMILIES).nullish(),
  fontSize: z.number().nullish(),
  textColor: z.string().nullish(),
  fontWeight: z.nativeEnum(FontWeight).nullish(),
});

const addressSectionSchema = fontSectionSchema.extend({
  showHeader: z.boolean().optional(),
  header: z.string().optional(),
  showName: z.boolean().optional(),
  showAddress: z.boolean().optional(),
  showPhone: z.boolean().optional(),
  showEmail: z.boolean().optional(),
});

const billingAddressSchema = addressSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.BILLING_ADDRESS),
});

const companyAddressSchema = addressSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.COMPANY_ADDRESS),
});

const contactInformationSchema = fontSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.CONTACT_INFORMATION),
  showName: z.boolean(),
  showAddress: z.boolean(),
  showPhone: z.boolean(),
  showEmail: z.boolean(),
});

const noteFromCustomerSchema = fontSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.NOTE_FROM_CUSTOMER),
});

const noteToCustomerSchema = fontSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.NOTE_TO_CUSTOMER),
});

const orderBarcodeSchema = baseSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.ORDER_BARCODE),
  width: z.number().optional(),
  height: z.number().optional(),
});

const orderDateSchema = fontSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.ORDER_DATE),
});

const orderItemsSchema = fontSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.ORDER_ITEMS),
  sort: z.nativeEnum(OrderItemSort),
  tableColumns: z.array(
    z.object({
      type: z.nativeEnum(OrderItemTableColumnType),
      enabled: z.boolean(),
      id: z.string().optional(),
      title: z.string().optional(),
      relativeFontSize: z.number().optional(),
      fontWeight: z.nativeEnum(FontWeight).optional(),
    }),
  ),
  includeMetafields: z.boolean().optional(),
  rowHeight: z.number().nullish(),
  emphasizeQuantity: z.boolean().nullish(),
  showDescription: z.boolean().nullish(),
  includeHeader: z.boolean().nullish(),
  tableBorderColor: z.string().nullish(),
  customAttributes: z.array(z.string()).optional(),
});

const orderDetailsSchema = fontSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.ORDER_DETAILS),
  showRecipientInformation: z.boolean(),
  showOrderNumber: z.boolean(),
  showOrderDate: z.boolean(),
  showShippingService: z.boolean(),
  stacked: z.boolean().optional(),
});

const orderNumberSchema = fontSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.ORDER_NUMBER),
});

const packageInformationSchema = fontSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.PACKAGE_INFORMATION),
});

const paymentBreakdownSchema = fontSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.PAYMENT_BREAKDOWN),
});

const paymentMethodSchema = fontSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.PAYMENT_METHOD),
  showSubtotal: z.boolean(),
  showPaymentMethod: z.boolean(),
  showPaymentDate: z.boolean(),
});

const packageWeightSchema = fontSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.PACKAGE_WEIGHT),
});

const shippingAddressSchema = addressSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.SHIPPING_ADDRESS),
});

const requestedShippingMethodSchema = fontSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.REQUESTED_SHIPPING_METHOD),
});

const shippingMethodSchema = fontSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.SHIPPING_METHOD),
});

const trackingNumberSchema = fontSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.TRACKING_NUMBER),
});

const textSchema = baseSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.TEXT),
  textColor: z.string(),
  fontSize: z.number(),
  fontFamily: z.enum(FONT_FAMILIES),
  sectionColor: z.string(),
  linkColor: z.string(),
  text: z.string(),
});

const lineSchema = baseSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.LINE),
  color: z.string(),
  sectionColor: z.string(),
});

const spacerSchema = baseSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.SPACER),
  sectionColor: z.string(),
  height: z.number(),
});

const imageSchema = baseSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.IMAGE),
  imageUrl: z.string(),
  sectionColor: z.string(),
  showCaption: z.boolean(),
  caption: z.string().optional(),
  altText: z.string().optional(),
  clickthroughUrl: z.string().optional(),
  aspectRatio: z.number().optional(),
  maxHeight: z.number().nullish(),
});

const qrCodeSchema = baseSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.QR_CODE),
  url: z.string(),
  size: z.nativeEnum(QrCodeSize),
  useReturnsPortalUrl: z.boolean(),
});

const logoSchema = baseSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.LOGO),
  maxHeight: z.number().nullish(),
  imageUrl: z.string().optional(),
});

const headerMarkerSchema = baseSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.HEADER_MARKER),
});

const sectionWithoutColumnSchema = z.discriminatedUnion("type", [
  billingAddressSchema,
  companyAddressSchema,
  contactInformationSchema,
  noteFromCustomerSchema,
  noteToCustomerSchema,
  orderBarcodeSchema,
  orderDateSchema,
  orderItemsSchema,
  orderDetailsSchema,
  orderNumberSchema,
  packageInformationSchema,
  paymentBreakdownSchema,
  paymentMethodSchema,
  packageWeightSchema,
  shippingAddressSchema,
  requestedShippingMethodSchema,
  shippingMethodSchema,
  trackingNumberSchema,
  textSchema,
  lineSchema,
  spacerSchema,
  imageSchema,
  logoSchema,
  headerMarkerSchema,
  qrCodeSchema,
]);

const columnSchema = baseSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.COLUMN),
  columns: z.array(z.lazy(() => sectionWithoutColumnSchema)),
  columnCount: z.number(),
  sectionColor: z.string(),
  gap: z.number(),
});

// Finally, combine all schemas
const sectionSchema = z.discriminatedUnion("type", [
  ...sectionWithoutColumnSchema.options,
  columnSchema,
]);

export type PackingSlipSection = z.infer<typeof sectionSchema>;

const pageLayoutSchema = z.object({
  orientation: z.nativeEnum(PageOrientation),
  size: z.nativeEnum(PageSize),
});
export type PackingSlipPageLayout = z.infer<typeof pageLayoutSchema>;

export interface IPackingSlipPageLayout
  extends z.ZodType<PackingSlipPageLayout> {}

export const packingSlipSchema = z.object({
  _id: zExt.objectId(),
  name: z.string(),
  sections: z.array(sectionSchema),
  team: zExt.objectId(),
  updatedAt: z.string(),
  pageLayout: pageLayoutSchema as IPackingSlipPageLayout,
});

export type PackingSlip = z.infer<typeof packingSlipSchema>;

export interface IPackingSlip extends z.ZodType<PackingSlip> {}
