import { Currency } from "@redotech/money/currencies";
import { z } from "zod";

const timeSeriesMetric = z.array(
  z.object({ plainDate: z.string(), value: z.number() }),
);

export const getThresholdUpsellWidgetAnalytics = {
  input: z.null(),
  output: z.object({
    ordersWithWidgetAdditionsOverTime: timeSeriesMetric,
    totalOrdersWithWidgetAdditions: z.number(),
    addedRevenueOverTime: timeSeriesMetric,
    totalAddedRevenue: z.number(),
    currency: z.nativeEnum(Currency),
    ordersByNumberOfProductsAdded: z.array(
      z.object({
        numberOfProductsAdded: z.number(),
        numberOfOrders: z.number(),
      }),
    ),
  }),
};
