import { useRequiredContext } from "@redotech/react-util/context";
import { useLoad } from "@redotech/react-util/load";
import { RedoMerchantRpcClientContext } from "@redotech/redo-merchant-app-common/rpc-client";
import {
  ExpandedConversation,
  ExpandedConversationMessage,
} from "@redotech/redo-model/conversation";
import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoFeaturedIcon } from "@redotech/redo-web/arbiter-components/featured-icon/redo-featured-icon";
import HelpCircleIcon from "@redotech/redo-web/arbiter-icon/help-circle.svg";
import PhoneIcon from "@redotech/redo-web/arbiter-icon/phone-call.svg";
import RecordingIcon from "@redotech/redo-web/arbiter-icon/recording-02_filled.svg";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import { getEstimatedTimeAgo } from "@redotech/redo-web/utils/date";
import { memo } from "react";
import { TranscriptDrawer } from "./transcript-drawer";
import * as voiceTranscriptMessageCss from "./voice-transcript-message.module.css";

export const VoiceTranscriptMessage = memo(function VoiceTranscriptMessage({
  message,
  open,
  handleDrawerState,
  conversation,
  isEmail = false,
  transcriptionMessage,
}: {
  message: ExpandedConversationMessage;
  open: boolean;
  handleDrawerState: (message: ExpandedConversationMessage) => void;
  conversation: ExpandedConversation;
  isEmail?: boolean;
  transcriptionMessage?: ExpandedConversationMessage;
}) {
  const client = useRequiredContext(RedoMerchantRpcClientContext);
  const transcriptData = useLoad(async () => {
    const response = await client.getCallRecordWithTranscript({
      callRecordId: message.callRecord.toString(),
    });
    return response.data;
  }, [message._id]);

  const customerDisplayName =
    transcriptData.value?.callRecord.customer?.name ||
    transcriptData.value?.callRecord.customer?.phone ||
    "Unknown customer";

  return (
    <>
      <Flex
        className={isEmail ? "" : voiceTranscriptMessageCss.callCard}
        justify={isEmail ? "flex-start" : "center"}
        mb="2xl"
        mt="2xl"
        w="full"
      >
        <Flex
          className={isEmail ? "" : voiceTranscriptMessageCss.callMessageButton}
          ml={isEmail ? "xl" : "none"}
        >
          <Flex align="center">
            <RedoFeaturedIcon color="brand" Icon={PhoneIcon} />
            <Flex dir="column" gap="none">
              <Flex gap="none">
                <Text fontSize="sm" fontWeight="semibold">
                  Phone call placed
                </Text>
                {transcriptData.value && (
                  <Text
                    fontSize="sm"
                    textColor="secondary"
                  >{` - ${customerDisplayName}`}</Text>
                )}
              </Flex>
              {transcriptData.value && (
                <Text fontSize="xxs" textColor="tertiary">
                  {`${transcriptData.value.callRecord.createdAt.toLocaleTimeString(
                    "en-US",
                    { hour: "numeric", minute: "numeric", hour12: true },
                  )} - `}
                  {getEstimatedTimeAgo(
                    transcriptData.value.callRecord.createdAt,
                  )}
                </Text>
              )}
            </Flex>
            {transcriptData.value?.callRecord.deleted ? (
              <>
                <div className={voiceTranscriptMessageCss.verticalDivider} />
                <Flex align="center">
                  <RedoButton
                    disabled
                    hierarchy="secondary"
                    IconLeading={RecordingIcon}
                    onClick={() => {}}
                    text="Recording deleted"
                  />
                  {transcriptData.value?.callRecord.deletedAt &&
                    transcriptData.value?.callRecord.deletedBy && (
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          <span>
                            Deleted by{" "}
                            {transcriptData.value!.callRecord.deletedBy.name} on{" "}
                            <br />
                            {transcriptData.value.callRecord.deletedAt.toLocaleString(
                              "en-US",
                              {
                                month: "short",
                                day: "numeric",
                                year: "numeric",
                              },
                            )}
                          </span>
                        }
                      >
                        <div
                          className={
                            voiceTranscriptMessageCss.helpIconContainer
                          }
                        >
                          <HelpCircleIcon
                            className={voiceTranscriptMessageCss.helpIcon}
                          />
                        </div>
                      </Tooltip>
                    )}
                </Flex>
              </>
            ) : (
              <>
                {transcriptData.value?.callRecord.recordingUrl && (
                  <>
                    <div
                      className={voiceTranscriptMessageCss.verticalDivider}
                    />
                    <RedoButton
                      hierarchy="secondary"
                      IconLeading={RecordingIcon}
                      onClick={() => handleDrawerState(message)}
                      text="View recording"
                    />
                  </>
                )}
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
      <TranscriptDrawer
        conversation={conversation}
        handleDrawerState={handleDrawerState}
        message={message}
        open={open}
        transcriptData={transcriptData}
        transcriptionMessageBeingShown={transcriptionMessage}
      />
    </>
  );
});
