import { z } from "zod";
import { SortDirection } from "../tables/table";
import {
  ArrayStringFilterBuilder,
  ArrayStringFilterDataSchema,
} from "./advanced-filters/array-string-filter";
import { BooleanFilterDataSchema } from "./advanced-filters/boolean-filter";
import {
  DateFilterBuilder,
  DateFilterDataSchema,
} from "./advanced-filters/date-filter";
import { ExistsFilterDataSchema } from "./advanced-filters/exists-filter";
import {
  NumberFilterBuilder,
  NumberFilterDataSchema,
} from "./advanced-filters/number-filter";
import {
  StringFilterBuilder,
  StringFilterDataSchema,
} from "./advanced-filters/string-filter";

export const DEFAULT_PAGE_SIZE = 50;

export const ViewSortSchema = z.object({
  direction: z.nativeEnum(SortDirection),
  key: z.string(),
});
export type ViewSort = z.infer<typeof ViewSortSchema>;

export const ViewColumnSchema = z.object({
  key: z.string(),
  width: z.number(),
  hidden: z.boolean(),
});
export type ViewColumn = z.infer<typeof ViewColumnSchema>;

export const AdvancedFilterDataSchema = z.discriminatedUnion("type", [
  DateFilterDataSchema,
  ArrayStringFilterDataSchema,
  NumberFilterDataSchema,
  StringFilterDataSchema,
  ExistsFilterDataSchema,
  BooleanFilterDataSchema,
]);
export type AdvancedFilterData = z.infer<typeof AdvancedFilterDataSchema>;

export const BaseViewSchema = z.object({
  _id: z.string().nullish(),
  table: z.string(),
  name: z.string(),
  filters: z.array(AdvancedFilterDataSchema),
  columns: z.array(ViewColumnSchema),
  sort: ViewSortSchema.nullish(),
  pageSize: z.number().nullish(),
  folderId: z.string().nullish(),
  position: z.number().nullish(),
  deleted: z.boolean().nullish(),
});

// Map view types to a Mongoose model and a Typescript interface
export const viewSchemas = { "fulfillment-groups": BaseViewSchema };

export enum ViewTypesNames {
  FULFILLMENT_GROUPS = "fulfillment-groups",
}

export type viewTypes = {
  [key in keyof typeof viewSchemas]: z.infer<(typeof viewSchemas)[key]>;
};

export type FilterBuilder =
  | typeof DateFilterBuilder
  | typeof ArrayStringFilterBuilder
  | typeof NumberFilterBuilder
  | typeof StringFilterBuilder;

export const baseViewWithIdSchema = BaseViewSchema.extend({ _id: z.string() });
export const viewWithIdSchemas = { "fulfillment-groups": baseViewWithIdSchema };

export type viewWithIdTypes = {
  [key in keyof typeof viewWithIdSchemas]: z.infer<
    (typeof viewWithIdSchemas)[key]
  >;
};

export type FulfillmentGroupView =
  viewWithIdTypes[ViewTypesNames.FULFILLMENT_GROUPS];
