/**
 * Types and utilities for dealing with email domain verification
 */

/**
 * Status of DKIM verification for a domain
 */
export type DkimStatus = "pending" | "verified" | "failed" | "not_started";

/**
 * Email domain settings for a team
 */
export interface EmailDomainSettings {
  enabled: boolean;
  domain: string;
  fromName?: string | null;
  fromEmail?: string | null;
  dkimSelector: string;
  dkimStatus: DkimStatus;
  configurationSets?: { transactional: string; marketing: string } | null;
  redoMailEnabled?: boolean | null;
}

/**
 * Checks if an email domain is verified
 *
 * @param emailDomainSettings The email domain settings to check
 * @returns A type guard that ensures the domain is both enabled and verified
 */
export function isVerifiedEmailDomain(
  emailDomainSettings?: EmailDomainSettings,
): emailDomainSettings is EmailDomainSettings & {
  enabled: true;
  dkimStatus: "verified";
} {
  return (
    !!emailDomainSettings &&
    emailDomainSettings.enabled &&
    emailDomainSettings.dkimStatus === "verified"
  );
}
