import { breadcrumbSlot } from "@redotech/redo-web/breadcrumb";
import { Navigate, RouteObject } from "react-router-dom";

const reportBreadcrumb = breadcrumbSlot();

export const analyticsRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Analytics", breadcrumbNavigate: false },
    children: [
      { index: true, element: <Navigate replace to="reports" /> },
      {
        path: "reports",
        handle: { breadcrumb: reportBreadcrumb },
        async lazy() {
          const { ReportPage } = await import("./reports/report");
          return { element: <ReportPage breadcrumb={reportBreadcrumb} /> };
        },
      },
      {
        handle: { breadcrumb: "Operations" },
        path: "operations",
        lazy: async () => {
          const { OperationsAnalytics } = await import(
            "./operations/operations-analytics"
          );
          return { Component: OperationsAnalytics };
        },
      },
      {
        path: "returns",
        handle: { breadcrumb: "Returns" },
        async lazy() {
          const { ReturnAnalytics: Analytics } = await import(
            "./returns/return-analytics"
          );
          return { Component: Analytics };
        },
      },
      {
        path: "returns-claims",
        handle: { breadcrumb: "Returns & Claims" },
        children: [
          {
            index: true,
            async lazy() {
              const { ReturnClaimAnalytics: ReturnClaimAnalytics } =
                await import("./returns-claims/return-claim-analytics");
              return { Component: ReturnClaimAnalytics };
            },
          },
          {
            path: "products/:productId",
            handle: { breadcrumb: "Details" },
            async lazy() {
              const { ProductDetails } = await import(
                "./returns-claims/product/product-details"
              );
              return { Component: ProductDetails };
            },
          },
        ],
      },
      {
        // Old claims path
        path: "claims-summary",
        element: <Navigate to="../claims" />,
      },
      {
        handle: { breadcrumb: "Claims" },
        path: "claims",
        lazy: async () => {
          const { ClaimsSummary } = await import("./claims/claims-analytics");
          return { Component: ClaimsSummary };
        },
      },
      {
        path: "support",
        handle: { breadcrumb: "Support" },
        async lazy() {
          const { SupportAnalytics: Analytics } = await import(
            "./support/support-analytics"
          );
          return { Component: Analytics };
        },
      },
      {
        path: "order-tracking",
        handle: { breadcrumb: "Order Tracking" },
        async lazy() {
          const { OrderTrackingAnalytics: Analytics } = await import(
            "./order-tracking/order-tracking-analytics"
          );
          return { Component: Analytics };
        },
      },
      {
        path: "order-fulfillment",
        handle: { breadcrumb: "Order Fulfillment" },
        async lazy() {
          const { OutboundLabelsAnalytics: Analytics } = await import(
            "./outbound-labels/outbound-labels-analytics"
          );
          return { Component: Analytics };
        },
      },
    ],
  },
];
