import { RouteObject } from "react-router-dom";

export const aboutOneClickUpsellRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "One-click upsell", breadcrumbNavigate: false },
    children: [
      {
        handle: { breadcrumb: "Upsell Page" },
        path: "upsell-page",
        children: [
          {
            index: true,
            lazy: async () => {
              const { UpsellPage } = await import(
                "../order-tracking-settings/one-click-upsell/upsell-page"
              );
              return { Component: UpsellPage };
            },
          },
          {
            handle: { breadcrumb: "Upsell Flow Builder" },
            path: ":upsellFlowId/:conditionId",
            children: [
              {
                index: true,
                lazy: async () => {
                  const { UpsellFlowBuilder } = await import(
                    "../order-tracking-settings/one-click-upsell/flow-builder/upsell-flow-builder"
                  );
                  return { Component: UpsellFlowBuilder };
                },
              },
              {
                handle: { breadcrumb: "Upsell Builder" },
                path: "upsell-page-builder/:upsellId",
                lazy: async () => {
                  const { UpsellPageBuilder } = await import(
                    "../order-tracking-settings/one-click-upsell/upsell-page-builder"
                  );
                  return { element: <UpsellPageBuilder /> };
                },
              },
            ],
          },
        ],
      },
      {
        path: "about",
        async lazy() {
          const { AboutOneClickUpsell } = await import(
            "./about-one-click-upsell"
          );
          return { element: <AboutOneClickUpsell /> };
        },
      },
    ],
  },
];
