import { useHandler } from "@redotech/react-util/hook";
import { UserContext } from "@redotech/redo-merchant-app-common/user";
import { FlowType, Step as ModelStep } from "@redotech/redo-model/return-flow";
import { Permission, permitted } from "@redotech/redo-model/user";
import { BlockLayout } from "@redotech/redo-web/flowchart";
import EyeIcon from "@redotech/redo-web/icon-old/eye.svg";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import {
  InputLines,
  InputTheme,
  TextInput,
} from "@redotech/redo-web/text-input";
import { produce } from "immer";
import { memo, useContext } from "react";
import { StepDownstream, StepId, StepType, StepTypeDetailsProps } from "./step";

export interface State {
  reason: string;
  next: StepId | undefined;
  customTitle?: string;
}

function valid(state: State, flowType: FlowType) {
  return (
    state.next !== undefined ||
    flowType === FlowType.FINALIZE_RETURN ||
    flowType === FlowType.FINALIZE_CLAIM
  );
}

const Details = memo(function Details({
  stepSelect,
  state,
  setState,
  flowType,
}: StepTypeDetailsProps<State>) {
  const user = useContext(UserContext);
  const canEditSettings =
    !!user && permitted(user.permissions, Permission.EDIT_SETTINGS);
  const nextStepChange = useHandler((next: StepId | undefined) => {
    setState((state) => ({ ...state, next }));
  });
  const handleReason = useHandler((reason: string) => {
    setState((state) => ({ ...state, reason }));
  });

  return (
    <>
      <LabeledInput
        description="A description of why this return is being marked for manual review."
        label="Reason"
      >
        <TextInput
          lines={InputLines.MULTI}
          onChange={handleReason}
          readonly={!canEditSettings}
          theme={InputTheme.FORM}
          value={state.reason}
        />
      </LabeledInput>
      <LabeledInput
        errors={
          flowType !== FlowType.FINALIZE_RETURN &&
          flowType !== FlowType.FINALIZE_CLAIM &&
          !state.next
            ? ["Required"]
            : []
        }
        label="Next step"
      >
        {stepSelect({ value: state.next, valueChange: nextStepChange })}
      </LabeledInput>
    </>
  );
});

export const MANUAL_REVIEW: StepType<State, ModelStep.ManualReview> = {
  Details,
  customTitle(state) {
    return state.customTitle;
  },
  title: "Mark for manual review",
  valid,
  Icon: EyeIcon,
  description() {
    return "Mark for manual review";
  },
  downstream(state) {
    const result: StepDownstream[] = [];
    if (state.next !== undefined) {
      result.push({ id: state.next });
    }
    return result;
  },
  empty: { next: undefined, reason: "" },
  stepDeleted(state, stepId) {
    return produce(state, (state) => {
      if (state.next === stepId) {
        state.next = undefined;
      }
    });
  },
  layout() {
    return BlockLayout.FULL;
  },
  toModel(state, id): ModelStep.ManualReview {
    return {
      customTitle: state.customTitle,
      type: ModelStep.MANUAL_REVIEW,
      next: state.next ? id(state.next) : undefined,
      reason: state.reason,
    };
  },
  fromModel(model, id) {
    return {
      customTitle: model.customTitle,
      next: model.next ? id(model.next) : undefined,
      reason: model.reason || "",
    };
  },
};
