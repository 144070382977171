import { z } from "zod";
import { AnalyticsFilterType } from "./analytics-filter-type";
import { makeAnalyticsDateFilterSchema } from "./base-report-date-filter";

export const OrderCreatedDateFilterSchema = makeAnalyticsDateFilterSchema(
  AnalyticsFilterType.ORDER_CREATED_DATE,
);

export type OrderCreatedDateFilter = z.infer<
  typeof OrderCreatedDateFilterSchema
>;
