import { RedoFeatureNotificationCard } from "@redotech/redo-web/arbiter-components/feature-notification/redo-feature-notification-card";
import { assertNever } from "@redotech/util/type";
import { memo, useMemo, useState } from "react";
import {
  RedoWalkthrough,
  WalkthroughStep,
} from "../../../web/src/arbiter-components/walkthrough/redo-walkthrough";
import * as styles from "./index.module.css";
import { FeatureOnboardingType } from "./onboarding-types";
import { useFeatureOnboardingManager } from "./use-feature-onboarding";

export const FeatureOnboarding = memo(function FeatureOnboarding() {
  const { onboardingType, showOnboarding, handleDismiss, handleComplete } =
    useFeatureOnboardingManager();

  const card = useMemo(() => {
    if (
      !onboardingType ||
      !showOnboarding ||
      onboardingType === FeatureOnboardingType.DISABLED
    ) {
      return null;
    }
    switch (onboardingType) {
      case FeatureOnboardingType.PACKAGE_PROTECTION_PLUS:
        return (
          <PackageProtectionPlusOnboarding
            handleComplete={handleComplete}
            handleDismiss={handleDismiss}
          />
        );
      case FeatureOnboardingType.EXPANDED_WARRANTY:
        return (
          <ExpandedWarrantyOnboarding
            handleComplete={handleComplete}
            handleDismiss={handleDismiss}
          />
        );
      case FeatureOnboardingType.NEW_NAVIGATION:
        return (
          <NewNavOnboarding
            handleComplete={handleComplete}
            handleDismiss={handleDismiss}
          />
        );
      default:
        assertNever(onboardingType);
    }
  }, [handleComplete, handleDismiss, onboardingType, showOnboarding]);

  if (!card) {
    return null;
  }

  return <div className={styles.container}>{card}</div>;
});

interface OnboardingProps {
  handleDismiss: () => void;
  handleComplete: () => void;
}

const CROSS_SELL_POPUP_URL = "https://assets.getredo.com/cross-sell-popup";

const PackageProtectionPlusOnboarding = memo(
  function PackageProtectionPlusOnboarding({
    handleDismiss,
    handleComplete,
  }: OnboardingProps) {
    const handleAction = () => {
      window.open(`${CROSS_SELL_POPUP_URL}/pp-one-pager.pdf`, "_blank");
      handleComplete();
    };

    return (
      <RedoFeatureNotificationCard
        actionText="Learn more"
        description="Tired of managing your claims? Let us handle it for you!"
        image={`${CROSS_SELL_POPUP_URL}/pp-popup.png`}
        onAction={handleAction}
        onDismiss={handleDismiss}
        title="Package Protection+"
      />
    );
  },
);

const ExpandedWarrantyOnboarding = memo(function ExpandedWarrantyOnboarding({
  handleDismiss,
  handleComplete,
}: OnboardingProps) {
  const handleAction = () => {
    window.open(`${CROSS_SELL_POPUP_URL}/ew-one-pager.pdf`, "_blank");
    handleComplete();
  };

  return (
    <RedoFeatureNotificationCard
      actionText="Learn more"
      description="Check out our new extended warranties capabilities."
      image={`${CROSS_SELL_POPUP_URL}/ew-popup.png`}
      onAction={handleAction}
      onDismiss={handleDismiss}
      title="Extended Warranties now available!"
    />
  );
});

const NAV_BASE_URL = "https://assets.getredo.com/nav-onboarding";

const navWalkthroughSteps: WalkthroughStep[] = [
  {
    title: "Overview",
    description:
      "As Redo has expanded to include more features we have introduced a new layer of navigation to help you better find the features you need.",
    image: `${NAV_BASE_URL}/overview.png`,
  },
  {
    title: "Platform product areas",
    description:
      "The new layer of navigation you see on the left represents the product areas; Conversion, Marketing, Order management, and Support.",
    image: `${NAV_BASE_URL}/product-areas.png`,
  },
  {
    title: "Secondary navigation items",
    description:
      "You can now find all relevant products nested within the product area.",
    image: `${NAV_BASE_URL}/secondary-navigation-items.png`,
  },
];

const NewNavOnboarding = memo(function NewNavOnboarding({
  handleDismiss,
  handleComplete,
}: OnboardingProps) {
  const [showWalkthrough, setShowWalkthrough] = useState(false);

  if (process.env.TEST_TYPE === "e2e_test") {
    return null;
  }

  return (
    <>
      {!showWalkthrough && (
        <RedoFeatureNotificationCard
          actionText="Learn more"
          description="We’ve updated our navigation to help you more easily find the things that matter most."
          onAction={() => setShowWalkthrough(true)}
          onDismiss={handleDismiss}
          title="Navigation has changed"
        />
      )}
      {showWalkthrough && (
        <RedoWalkthrough
          onComplete={handleComplete}
          steps={navWalkthroughSteps}
        />
      )}
    </>
  );
});
