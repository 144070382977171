import {
  segmentMemberColumnKeys,
  segmentMemberSchema,
} from "@redotech/redo-model/marketing/segments/segment-types";
import { segmentSchema } from "@redotech/redo-model/marketing/segments/segment-zod-schema";
import { SortDirection } from "@redotech/redo-model/tables/table";
import { z } from "zod";

export const getSegmentMembers = {
  input: z.object({
    segment: segmentSchema,
    sort: z.object({
      direction: z.nativeEnum(SortDirection),
      key: z.enum(segmentMemberColumnKeys),
    }),
    page: z.number(),
    pageSize: z.number(),
    searchString: z.string().optional(),
  }),
  output: z.array(segmentMemberSchema),
};
