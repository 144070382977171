import { z } from "zod";
import { zExt } from "../common/zod-util";
import { Provider } from "../order";
import { FulfillmentOrder } from "./fulfillment-group";
import { fulfillmentOrderStatusIsDone } from "./fulfillment-order-status";

export const LocationForMoveSchema = z.object({
  id: z.string(),
  name: z.string(),
  movable: z.boolean().nullish(),
  message: z.string().nullish(),
  unavailableLineItemsCount: z.number().nullish(),
});

export type LocationForMove = z.infer<typeof LocationForMoveSchema>;

export const ShopifyLocationAddressSchema = z.object({
  address1: z.string().nullish(),
  address2: z.string().nullish(),
  city: z.string().nullish(),
  country: z.string().nullish(),
  countryCode: z.string().nullish(),
  phone: z.string().nullish(),
  province: z.string().nullish(),
  provinceCode: z.string().nullish(),
  zip: z.string().nullish(),
});

export const ShopifyFulfillmentServiceSchema = z.object({
  id: z.string(),
  type: z.string().nullish(),
  serviceName: z.string().nullish(),
});

export const ShopifyLocationSchema = z.object({
  id: z.string(),
  name: z.string(),
  address: ShopifyLocationAddressSchema,
  activatable: z.boolean(),
  addressVerified: z.boolean(),
  createdAt: z.string(),
  deactivatable: z.boolean(),
  deactivatedAt: z.string().nullish(),
  deletable: z.boolean(),
  fulfillmentService: ShopifyFulfillmentServiceSchema.nullish(),
  fulfillsOnlineOrders: z.boolean(),
  hasActiveInventory: z.boolean(),
  hasUnfulfilledOrders: z.boolean(),
  isActive: z.boolean(),
  isFulfillmentService: z.boolean(),
  shipsInventory: z.boolean(),
  updatedAt: z.string(),
});

export type ShopifyFulfillmentLocation = z.infer<typeof ShopifyLocationSchema>;

export const RedoFulfillmentLocationSchema = ShopifyLocationSchema.omit({
  id: true,
}).extend({
  _id: zExt.objectId(),
  provider: z.nativeEnum(Provider),
  externalId: z.string(),
  teamId: z.string(),
  providerCreatedAt: z.date(),
  providerUpdatedAt: z.date(),
  updatedAt: z.date(),
  createdAt: z.date(),
});

export type RedoFulfillmentLocation = z.infer<
  typeof RedoFulfillmentLocationSchema
>;

export const CreateRedoFulfillmentLocationSchema =
  RedoFulfillmentLocationSchema.omit({
    _id: true,
    updatedAt: true,
    createdAt: true,
  });

export type CreateRedoFulfillmentLocation = z.infer<
  typeof CreateRedoFulfillmentLocationSchema
>;

export function mapShopifyFulfillmentLocation(
  location: ShopifyFulfillmentLocation,
  teamId: string,
): CreateRedoFulfillmentLocation {
  const data = {
    ...location,
    provider: Provider.SHOPIFY,
    externalId: location.id,
    teamId: teamId,
    providerCreatedAt: new Date(location.createdAt),
    providerUpdatedAt: new Date(location.updatedAt),
  };
  return CreateRedoFulfillmentLocationSchema.parse(data);
}

export function canMoveFulfillmentOrder(
  fo: FulfillmentOrder,
  externalLocationId: string,
) {
  if (fulfillmentOrderStatusIsDone(fo.status)) {
    return false;
  }

  if (fo.provider === Provider.MANUAL) {
    return true;
  }

  return fo.locationsForMove?.some(
    (location) =>
      location.id === externalLocationId &&
      location.movable &&
      location.unavailableLineItemsCount === 0,
  );
}
