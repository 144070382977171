import {
  CallState,
  UserCallAvailability,
} from "@redotech/redo-model/support/voice/voice-types";
import PhoneCallIcon from "@redotech/redo-web/arbiter-icon/phone-call.svg";
import PhoneXIcon from "@redotech/redo-web/arbiter-icon/phone-x.svg";
import PhoneIcon from "@redotech/redo-web/arbiter-icon/phone.svg";
import PlusIcon from "@redotech/redo-web/arbiter-icon/plus.svg";
import XIcon from "@redotech/redo-web/arbiter-icon/x-close.svg";
import { Text } from "@redotech/redo-web/text";
import { assertNever } from "@redotech/util/type";
import { memo, useContext } from "react";
import { RedoButton } from "../arbiter-components/buttons/redo-button";
import { Flex } from "../flex";
import { TimeElapsed } from "./active-call-elapsed-time";
import { getCallText } from "./call-progress";
import { PhoneWidgetStatus } from "./phone-status";
import * as phoneWidgetCss from "./phone-widget.module.css";
import { VoiceContext } from "./voice-context";

export const CollapsedPhoneWidget = memo(function CollapsedPhoneWidget({
  onClose,
  onExpand,
  status,
}: {
  onClose(): void;
  onExpand(): void;
  status: UserCallAvailability;
}) {
  return (
    <Flex align="center" justify="space-between" px="xl" py="sm">
      <Flex align="center" overflow="hidden">
        <CollapsedPhoneWidgetContent status={status} />
      </Flex>
      <CollapsedPhoneWidgetControls onClose={onClose} onExpand={onExpand} />
    </Flex>
  );
});

const CollapsedPhoneWidgetContent = memo(function CollapsedPhoneWidgetContent({
  status,
}: {
  status: UserCallAvailability;
}) {
  const { call, answerCall, endCall, rejectCall } = useContext(VoiceContext);

  if (!call) {
    return (
      <>
        <Flex align="center" className={phoneWidgetCss.phoneIcon}>
          <PhoneIcon />
        </Flex>
        <Flex align="center">
          <PhoneWidgetStatus status={status} />
        </Flex>
      </>
    );
  } else if (
    call.state === CallState.RINGING ||
    call.state === CallState.CONNECTING
  ) {
    const text = getCallText(call);

    return (
      <>
        <Flex>
          <RedoButton
            hierarchy="primary"
            IconLeading={PhoneCallIcon}
            onClick={answerCall}
            pending={call.state === CallState.CONNECTING}
            theme="success"
          />
          <RedoButton
            disabled={call.state === CallState.CONNECTING}
            hierarchy="primary"
            IconLeading={PhoneXIcon}
            onClick={rejectCall}
            theme="destructive"
          />
        </Flex>
        <Flex dir="column" overflow="hidden">
          <Text fontSize="xxs" fontWeight="regular" textColor="primary">
            {text}
          </Text>
          <Text
            fontSize="xxs"
            fontWeight="regular"
            overflow="hidden"
            textColor="secondary"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {call.remoteCallerName || call.remoteCallerNumber}
          </Text>
        </Flex>
      </>
    );
  } else if (
    call.state === CallState.ACTIVE ||
    call.state === CallState.HANGUP
  ) {
    return (
      <>
        <Flex>
          <RedoButton
            disabled={call.state === CallState.HANGUP}
            hierarchy="primary"
            IconLeading={PhoneXIcon}
            onClick={endCall}
            theme="destructive"
          />
        </Flex>
        {call.state === CallState.ACTIVE && (
          <Flex align="center" overflow="hidden">
            <TimeElapsed startTime={call.startTime} />
            <Text
              fontSize="xxs"
              overflow="hidden"
              textColor="secondary"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >{` - ${call.remoteCallerName || call.remoteCallerNumber}`}</Text>
          </Flex>
        )}
        {call.state === CallState.HANGUP && (
          <Flex dir="column" overflow="hidden">
            <Text fontSize="xxs" fontWeight="regular" textColor="primary">
              {getCallText(call)}
            </Text>
          </Flex>
        )}
      </>
    );
  } else {
    assertNever(call);
  }
});

const CollapsedPhoneWidgetControls = memo(
  function CollapsedPhoneWidgetControls({
    onExpand,
    onClose,
  }: {
    onExpand: () => void;
    onClose: () => void;
  }) {
    return (
      <Flex gap="none">
        <RedoButton IconLeading={PlusIcon} onClick={onExpand} size="xs" />
        <RedoButton IconLeading={XIcon} onClick={onClose} size="xs" />
      </Flex>
    );
  },
);
