import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import {
  ConversationStatus,
  ExpandedConversation,
} from "@redotech/redo-model/conversation";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { Modal, ModalSize } from "@redotech/redo-web/modal";
import { ProgressBar } from "@redotech/redo-web/progress-bar";
import { memo, useEffect, useState } from "react";
import {
  bulkUpdateConversationsStatus,
  updateConversation,
} from "../../client/conversations";
import { getNextBatch } from "../utils";
import * as reopenTicketModalCss from "./reopen-ticket-modal.module.css";

export const InProgressTicketModal = memo(function InProgressTicketModal({
  open,
  setOpen,
  conversations,
  setActiveConversation,
  inDetailView,
  cleanupFn,
  selectAllInfo,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  conversations: ExpandedConversation[];
  setActiveConversation: (val: ExpandedConversation) => void;
  inDetailView: boolean;
  cleanupFn?: (clearSelected?: boolean) => void;
  selectAllInfo?: {
    count: number;
    deselectedConversations: ExpandedConversation[];
    bulkActionIterator: AsyncIterator<{
      conversations: ExpandedConversation[];
    }>;
    setBlockRefresh: (val: boolean) => void;
  };
}) {
  const client = useRequiredContext(RedoMerchantClientContext);
  const [conversationsCanMarkInProgress, setConversationsCanMarkInProgress] =
    useState<ExpandedConversation[]>(
      conversations.filter(
        (conversation) =>
          conversation.status !== ConversationStatus.IN_PROGRESS.toLowerCase(),
      ),
    );
  const [markInProgressSelectAll, setMarkInProgressSelectAll] = useState(false);
  const [numDone, setNumDone] = useState(0);

  const handleMarkInProgressTickets = async () => {
    if (conversations.length === 1) {
      void updateConversation(client, conversations[0], {
        status: "in_progress",
      });
      amplitude.logEvent("markInProgress", {
        mode: "single",
        conversationIds: [conversations[0]._id],
        channels: [conversations[0].platform],
      });
      if (inDetailView) {
        setActiveConversation({
          ...conversations[0],
          status: ConversationStatus.IN_PROGRESS.toLowerCase(),
        });
      }
    } else if (conversations.length > 1) {
      const conversationIds = conversations
        .filter(
          (conversation) =>
            conversation.status !==
            ConversationStatus.IN_PROGRESS.toLowerCase(),
        )
        .map((conversation) => conversation._id);
      if (conversationIds.length > 0) {
        await bulkUpdateConversationsStatus(client, {
          conversationIds,
          status: ConversationStatus.IN_PROGRESS.toLowerCase(),
        });
        const channels = [
          ...new Set(
            conversations.map((conversation) => conversation.platform),
          ),
        ];
        amplitude.logEvent("markInProgress", {
          mode: "multiple",
          conversationIds,
          channels,
        });
      }
    }
    cleanupFn && cleanupFn(true);
    setOpen(false);
  };

  const markBatchInProgress = async (): Promise<{
    batchSize: number;
    done: boolean;
  }> => {
    if (!selectAllInfo) return { batchSize: 0, done: true };
    const { done, value } = await getNextBatch(
      selectAllInfo.bulkActionIterator,
    );
    if (done) {
      return { batchSize: 0, done };
    }
    const conversationIds = value.conversations
      .filter(
        (conversation) =>
          conversation.status !== ConversationStatus.IN_PROGRESS &&
          !selectAllInfo.deselectedConversations.some(
            (deselectedConversation) =>
              deselectedConversation._id === conversation._id,
          ),
      )
      .map((conversation) => conversation._id);
    if (conversationIds.length > 0) {
      await bulkUpdateConversationsStatus(client, {
        conversationIds,
        status: ConversationStatus.IN_PROGRESS.toLowerCase(),
      });
    }
    return { batchSize: value.conversations.length, done: false };
  };

  // This function synchronously each page of conversations in progress.
  // If speed becomes an issue, we could asynchronously mark each page.
  const handleMarkInProgressSelectAll = async () => {
    if (!selectAllInfo) return;
    setMarkInProgressSelectAll(true);
    selectAllInfo.setBlockRefresh(true);
    let done = false;
    do {
      const result = await markBatchInProgress();
      setNumDone((prevNumDone) => prevNumDone + result.batchSize);
      done = result.done;
    } while (!done);
    amplitude.logEvent("markInProgress", { mode: "all" });
    setMarkInProgressSelectAll(false);
    selectAllInfo.setBlockRefresh(false);
    cleanupFn && cleanupFn(true);
    setOpen(false);
  };

  useEffect(() => {
    setConversationsCanMarkInProgress(
      conversations.filter(
        (conversation) =>
          conversation.status !== ConversationStatus.IN_PROGRESS.toLowerCase(),
      ),
    );
  }, [conversations]);

  const plural = conversationsCanMarkInProgress.length > 1 || selectAllInfo;

  const footer = (
    <div className={reopenTicketModalCss.actionButtonsContainer}>
      <Button onClick={() => setOpen(false)} theme={ButtonTheme.OUTLINED}>
        Cancel
      </Button>
      <Button
        onClick={async () => {
          selectAllInfo
            ? await handleMarkInProgressSelectAll()
            : await handleMarkInProgressTickets();
        }}
        theme={ButtonTheme.PRIMARY}
      >
        Yes, mark in progress
      </Button>
    </div>
  );

  return (
    <Modal
      footer={markInProgressSelectAll ? undefined : footer}
      onClose={() => {
        cleanupFn && cleanupFn();
        setOpen(false);
      }}
      open={open}
      showHeaderBorder={false}
      size={ModalSize.SMALL}
      title={
        markInProgressSelectAll
          ? "Marking tickets in progress"
          : `Mark ${
              selectAllInfo
                ? "all selected "
                : plural
                  ? conversationsCanMarkInProgress.length + " "
                  : ""
            }ticket${plural ? "s" : ""} as in progress?`
      }
    >
      {markInProgressSelectAll ? (
        <ProgressBar
          value={(numDone / (selectAllInfo?.count || numDone)) * 100}
        />
      ) : (
        <div className={reopenTicketModalCss.actionModalContentContainer}>
          <div>
            {plural ? "These tickets" : "This ticket"} will show up in your in
            progress tickets list.
          </div>
        </div>
      )}
    </Modal>
  );
});
