import { RedoBadge } from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import SearchIcon from "@redotech/redo-web/arbiter-icon/search-sm_filled.svg";
import XIcon from "@redotech/redo-web/arbiter-icon/x-close.svg";
import { Divider } from "@redotech/redo-web/divider";
import { Flex } from "@redotech/redo-web/flex";
import { Modal, ModalSize, PaddingAmount } from "@redotech/redo-web/modal";
import { Text } from "@redotech/redo-web/text";
import * as classNames from "classnames";
import {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type ChangeEvent,
} from "react";
import { useDebounce } from "usehooks-ts";
import {
  allMerchantAppSettings,
  MerchantAppSetting,
  ProductId,
} from "../setting/settings";
import { useSettingSearch } from "./global-search-context";
import * as globalSearchCss from "./global-search.module.css";
import { useSettingNavigation } from "./setting-search/setting-navigator-context";

const DEBOUNCE_MS = 300;
const MAX_RECENT_ITEMS = 3;

const STORAGE_KEYS = {
  VIEWED_ITEMS: "global.search.viewedItems",
  RECENT_ITEMS: "global.search.recentItems",
} as const;

export enum Location {
  SETTINGS = "settings",
}

const SearchInput = memo(function SearchInput({
  value,
  onChange,
  isSearching,
  shortcutText,
}: {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  isSearching: boolean;
  shortcutText: string;
}) {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      inputRef.current?.focus();
    }, 0);

    return () => clearTimeout(timeoutId);
  }, []);
  return (
    <Flex
      align="center"
      className={globalSearchCss.searchInputContainer}
      dir="row"
      gap="md"
    >
      <SearchIcon className={globalSearchCss.searchIcon} />
      <input
        autoFocus
        className={globalSearchCss.searchInput}
        disabled={isSearching}
        onChange={onChange}
        placeholder="Search"
        type="text"
        value={value}
      />
      <Flex
        align="center"
        className={globalSearchCss.shortcutContainer}
        dir="row"
        gap="sm"
      >
        {shortcutText}
      </Flex>
    </Flex>
  );
});

const FilterSection = memo(function FilterSection({
  filters,
}: {
  filters: string[];
}) {
  return (
    <Flex align="center" dir="row" gap="sm" p="md">
      {filters.map((filter) => (
        <RedoBadge
          color="gray"
          iconTrailing={{ type: "icon", Icon: XIcon }}
          key={filter}
          size="sm"
          text={filter}
          type="primary"
        />
      ))}
    </Flex>
  );
});

const SearchResultItem = memo(function SearchResultItem({
  title,
  description,
  onClick,
}: {
  title: string;
  description: string;
  onClick: () => void;
}) {
  return (
    <Flex
      className={classNames(globalSearchCss.menuItem, "hover:bg-gray-50")}
      dir="column"
      gap="xs"
      mb="xs"
      onClick={onClick}
      py="md"
    >
      <Text
        as="span"
        color="var(--redo-colors-text-text-primary-900)"
        fontSize="sm"
        fontWeight="medium"
      >
        {title}
      </Text>
      <Text
        as="span"
        color="var(--redo-colors-text-text-tertiary-600)"
        fontSize="sm"
      >
        {description}
      </Text>
    </Flex>
  );
});

const SearchResultsSection = memo(function SearchResultsSection({
  title,
  results,
  onSelect,
}: {
  title: string;
  results: MerchantAppSetting[];
  onSelect: (setting: MerchantAppSetting) => void;
}) {
  return (
    <Flex
      className={globalSearchCss.resultsSection}
      dir="column"
      gap="xxs"
      p="xl"
    >
      <Text
        as="span"
        color="var(--redo-colors-text-text-tertiary-600)"
        fontSize="sm"
        fontWeight="regular"
      >
        {title}
      </Text>

      <Flex dir="column" gap="xxs">
        {results.map((result) => (
          <SearchResultItem
            description={result.description}
            key={result.id}
            onClick={() => onSelect(result)}
            title={result.title}
          />
        ))}
      </Flex>
    </Flex>
  );
});

export const GlobalSearch = memo(function GlobalSearch() {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [searchResults, setSearchResults] = useState<MerchantAppSetting[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [viewedIds, setViewedIds] = useState<Set<string>>(new Set());
  const [recentItems, setRecentItems] = useState<MerchantAppSetting[]>([]);
  const [newItems, setNewItems] = useState<MerchantAppSetting[]>([]);
  const [productFilters] = useState<ProductId[]>([ProductId.SUPPORT]);
  const [locationFilters] = useState<Location[]>([Location.SETTINGS]);

  const { search } = useSettingSearch();
  const { navigateToSetting } = useSettingNavigation();
  const searchRef = useRef<typeof search>(search);
  const abortController = useRef<AbortController>();
  const isMac = useMemo(
    () => navigator.platform.toLowerCase().includes("mac"),
    [],
  );
  const shortcutKey = isMac ? "⌘" : "Ctrl";
  const shortcutLetterKey = "k";

  const debouncedSearch = useDebounce(query, DEBOUNCE_MS);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === shortcutLetterKey) {
        event.preventDefault();
        setIsOpen((prevState) => !prevState);
      }

      if (event.key === "Escape" && isOpen) {
        handleClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const saveViewedItems = useCallback(() => {
    try {
      const allSettings = allMerchantAppSettings;
      const allSettingIds = allSettings.map((setting) => setting.id);
      setViewedIds(new Set(allSettingIds));
      localStorage.setItem(
        STORAGE_KEYS.VIEWED_ITEMS,
        JSON.stringify(allSettingIds),
      );
    } catch (error) {
      console.error("Failed to save viewed items:", error);
    }
  }, []);

  const handleClose = useCallback(() => {
    saveViewedItems();
    setIsOpen(false);
    setQuery("");
  }, [saveViewedItems]);

  const loadStoredData = useCallback(() => {
    try {
      const storedIds = localStorage.getItem(STORAGE_KEYS.VIEWED_ITEMS);
      if (storedIds) {
        setViewedIds(new Set(JSON.parse(storedIds)));
      }

      const storedRecent = localStorage.getItem(STORAGE_KEYS.RECENT_ITEMS);
      if (storedRecent) {
        const parsed = JSON.parse(storedRecent);
        setRecentItems(Array.isArray(parsed) ? parsed.slice(0, 3) : []);
      }
    } catch (error) {
      console.error("Failed to load stored data:", error);
    }
  }, []);

  const updateRecentItems = useCallback((setting: MerchantAppSetting) => {
    setRecentItems((prev) => {
      const filtered = prev.filter((item) => item.id !== setting.id);
      const updated = [setting, ...filtered].slice(0, MAX_RECENT_ITEMS);
      try {
        localStorage.setItem(
          STORAGE_KEYS.RECENT_ITEMS,
          JSON.stringify(updated),
        );
      } catch (error) {
        console.error("Failed to save recent items:", error);
      }
      return updated;
    });
  }, []);

  const loadNewItems = useCallback(() => {
    const allSettings = allMerchantAppSettings;
    setNewItems(allSettings.filter((item) => !viewedIds.has(item.id)));
  }, [viewedIds]);

  const performSearch = useCallback(async (searchQuery: string) => {
    if (!searchQuery?.trim()) {
      setSearchResults([]);
      return;
    }

    abortController.current?.abort();
    abortController.current = new AbortController();

    setIsSearching(true);

    try {
      const results = await searchRef.current(searchQuery);
      setSearchResults(results);
    } catch (error) {
      if (!(error instanceof Error && error.name === "AbortError")) {
        console.error("Search failed:", error);
        setSearchResults([]);
      }
    } finally {
      setIsSearching(false);
    }
  }, []);

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  }, []);

  const handleSettingSelect = useCallback(
    (setting: MerchantAppSetting) => {
      updateRecentItems(setting);
      void navigateToSetting(setting);
      saveViewedItems();
      setQuery("");
      setIsOpen(false);
    },
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateRecentItems, handleClose],
  );

  useEffect(() => {
    loadStoredData();
  }, [loadStoredData]);

  useEffect(() => {
    loadNewItems();
  }, [loadNewItems]);

  useEffect(() => {
    if (query) {
      void performSearch(query);
    }
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, performSearch]);

  useEffect(() => {
    searchRef.current = search;
  }, [search]);

  useEffect(() => {
    return () => {
      abortController.current?.abort();
      saveViewedItems();
    };
  }, [saveViewedItems]);

  const activeFilters = useMemo(
    () => [
      ...productFilters.map(
        (p) => "In:" + p.charAt(0).toUpperCase() + p.slice(1).toLowerCase(),
      ),
      ...locationFilters.map(
        (l) => "In:" + l.charAt(0).toUpperCase() + l.slice(1).toLowerCase(),
      ),
    ],
    [productFilters, locationFilters],
  );

  return (
    <Modal
      className={classNames(globalSearchCss.commandBarModal)}
      hideCloseButton
      onClose={handleClose}
      open={isOpen}
      paddingAmount={PaddingAmount.NONE}
      showHeader={false}
      size={ModalSize.FLEXIBLE}
      title=""
    >
      <Flex dir="column" gap="md" p="xl">
        <SearchInput
          isSearching={isSearching}
          onChange={handleInputChange}
          shortcutText={`${shortcutKey} + ${shortcutLetterKey}`}
          value={query}
        />
      </Flex>
      <Divider />
      <FilterSection filters={activeFilters} />

      <Flex className="overflow-y-auto" dir="column">
        {isSearching ? (
          <Flex className="p-3">
            <Text className="text-sm text-gray-500">Searching...</Text>
          </Flex>
        ) : query ? (
          searchResults.length > 0 ? (
            <SearchResultsSection
              onSelect={handleSettingSelect}
              results={searchResults}
              title="Results"
            />
          ) : (
            <Flex className="p-3">
              <Text className="text-sm text-gray-500">No results found</Text>
            </Flex>
          )
        ) : (
          <Flex dir="column">
            {recentItems.length > 0 && (
              <SearchResultsSection
                onSelect={handleSettingSelect}
                results={recentItems.slice(0, 3)}
                title="Recent"
              />
            )}
            {newItems.length > 0 && (
              <Flex
                className={
                  recentItems.length > 0 ? globalSearchCss.scrollContainer : ""
                }
              >
                <SearchResultsSection
                  onSelect={handleSettingSelect}
                  results={newItems}
                  title="New pages"
                />
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
    </Modal>
  );
});
