import { CustomerActivityCategory } from "@redotech/redo-model/customer-activity/customer-activity-filters";

export type CustomerActivityCategoryEnabled = {
  [key in CustomerActivityCategory]: boolean;
};
export const DEFAULT_CUSTOMER_ACTIVITY_CATEGORIES_ENABLED: CustomerActivityCategoryEnabled =
  {
    [CustomerActivityCategory.CLAIMS]: true,
    [CustomerActivityCategory.ORDERS]: true,
    [CustomerActivityCategory.RETURNS]: true,
    [CustomerActivityCategory.SUPPORT]: true,
    [CustomerActivityCategory.SHOPPING]: true,
    [CustomerActivityCategory.MARKETING]: true,
  };

export const CATEGORY_LABELS: Record<CustomerActivityCategory, string> = {
  [CustomerActivityCategory.CLAIMS]: "Claims",
  [CustomerActivityCategory.ORDERS]: "Orders",
  [CustomerActivityCategory.RETURNS]: "Returns",
  [CustomerActivityCategory.SHOPPING]: "Shopping activity",
  [CustomerActivityCategory.SUPPORT]: "Support",
  [CustomerActivityCategory.MARKETING]: "Marketing",
};

export function customerActivityCategoriesToEnabledMap(
  customerActivityCategories: CustomerActivityCategory[] | undefined,
): CustomerActivityCategoryEnabled {
  if (customerActivityCategories) {
    return {
      [CustomerActivityCategory.CLAIMS]: customerActivityCategories.includes(
        CustomerActivityCategory.CLAIMS,
      ),
      [CustomerActivityCategory.ORDERS]: customerActivityCategories.includes(
        CustomerActivityCategory.ORDERS,
      ),
      [CustomerActivityCategory.RETURNS]: customerActivityCategories.includes(
        CustomerActivityCategory.RETURNS,
      ),
      [CustomerActivityCategory.SUPPORT]: customerActivityCategories.includes(
        CustomerActivityCategory.SUPPORT,
      ),
      [CustomerActivityCategory.SHOPPING]: customerActivityCategories.includes(
        CustomerActivityCategory.SHOPPING,
      ),
      [CustomerActivityCategory.MARKETING]: customerActivityCategories.includes(
        CustomerActivityCategory.MARKETING,
      ),
    };
  } else {
    return DEFAULT_CUSTOMER_ACTIVITY_CATEGORIES_ENABLED;
  }
}

export function updateCustomerActivityCategoriesEnabled(
  CustomerActivityCategoryEnabled: CustomerActivityCategoryEnabled,
  customerActivityCategory: CustomerActivityCategory,
  enabled: boolean,
): CustomerActivityCategoryEnabled {
  return {
    ...CustomerActivityCategoryEnabled,
    [customerActivityCategory]: enabled,
  };
}
