import { EmojiSelectionModal } from "@redotech/redo-web/emoji-selection-modal";

import { useTriggerLoad } from "@redotech/react-util/load";
import { alertOnFailure } from "@redotech/redo-web/alert";
import ArrowIcon from "@redotech/redo-web/arbiter-icon/send-03_filled.svg";
import { Flex } from "@redotech/redo-web/flex";
import { memo, useRef, useState } from "react";
import { returnCommentCreate } from "../../client/comment";

import { RedoMerchantClient } from "@redotech/redo-merchant-app-common/client";
import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import FaceSmile from "@redotech/redo-web/arbiter-icon/face-smile.svg";
import PaperclipIcon from "@redotech/redo-web/arbiter-icon/paperclip_filled.svg";
import XCloseIcon from "@redotech/redo-web/arbiter-icon/x-close.svg";
import { ExpandableImage } from "@redotech/redo-web/expandable-image";
import { QuillEditor } from "@redotech/redo-web/quill/quill-editor";
import Quill, { Range } from "quill";
import { Params } from "react-router-dom";
import * as activityCss from "./activity-card-input.module.css";

type UploadedImage = { base64: string; name: string };

export const ActivityCardInput = ({
  params,
  client,
  reloadReturn,
}: {
  params: Readonly<Params<string>>;
  client: RedoMerchantClient;
  reloadReturn: () => Promise<void>;
}) => {
  const [quill, setQuill] = useState<Quill | null>(null);
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const emojiButtonRef = useRef<HTMLButtonElement>(null);
  const [uploadedImages, setUploadedImages] = useState<UploadedImage[]>([]);
  const [selectionRange, setSelectionRange] = useState<Range | null>(null);

  const [submitLoad, submit] = useTriggerLoad(async (signal) => {
    const currentText = quill?.getText() || "";

    await alertOnFailure("Could not create new comment")(async () => {
      if (!params.returnId) return;
      return returnCommentCreate(client, {
        message: currentText,
        images: uploadedImages.map((image) => ({
          base64: image.base64,
          name: image.name,
        })),
        returnId: params.returnId,
        signal,
      });
    });
    setUploadedImages([]);
    await reloadReturn();
  });

  const handleEmojiPickerOpen = () => {
    const selectionRange = quill?.getSelection();
    if (selectionRange) {
      setSelectionRange(selectionRange);
    }
    setEmojiPickerOpen(true);
  };

  const handleEmojiSelected = (emoji: any) => {
    if (!("native" in emoji)) return;
    if (quill) {
      const range = selectionRange;
      quill.focus();
      if (range) {
        quill.setSelection(range);
        quill.insertText(range.index, emoji.native);
        quill.setSelection(range.index + emoji.native.length);
      } else {
        const length = quill.getLength();
        quill.setSelection(length - 1);
        quill.insertText(length - 1, emoji.native);
        quill.setSelection(length - 1 + emoji.native.length);
      }
    }
  };

  const handleImageUpload = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setUploadedImages((prev) => [
        ...prev,
        { base64: e.target?.result as string, name: file.name },
      ]);
    };
    reader.readAsDataURL(file);
  };

  const removeImage = (index: number) => {
    setUploadedImages((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <>
      <form
        className={activityCss.messageInputForm}
        onKeyDown={(e: React.KeyboardEvent) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            e.stopPropagation();
            void submit();
          }
        }}
      >
        {uploadedImages.length > 0 && (
          <Flex
            className={activityCss.imagePreviewContainer}
            dir="row"
            gap="lg"
            wrap="wrap"
          >
            {uploadedImages.map((image, index) => (
              <div className={activityCss.imagePreviewItem} key={index}>
                <ExpandableImage alt="Uploaded preview" src={image.base64} />
                <button
                  className={activityCss.imagePreviewDeleteButton}
                  onClick={() => removeImage(index)}
                  type="button"
                >
                  <XCloseIcon
                    className={activityCss.imagePreviewDeleteButtonIcon}
                  />
                </button>
              </div>
            ))}
          </Flex>
        )}
        <QuillEditor
          defaultValue=""
          editorClassName={activityCss.quillEditor}
          placeholder="Leave a comment..."
          readOnly={submitLoad.pending}
          ref={setQuill}
        />
        <ActivityCardFooter
          emojiButtonRef={emojiButtonRef}
          handleImageUpload={handleImageUpload}
          onEmojiClick={handleEmojiPickerOpen}
          onSubmit={submit}
          pending={submitLoad.pending}
        />
      </form>
      <EmojiSelectionModal
        anchor={emojiButtonRef.current}
        handleEmojiSelected={handleEmojiSelected}
        onClose={() => setEmojiPickerOpen(false)}
        open={emojiPickerOpen}
        theme="light"
      />
    </>
  );
};

const ActivityCardFooter = memo(function ActivityCardFooter({
  onSubmit,
  pending,
  onEmojiClick,
  emojiButtonRef,
  handleImageUpload,
}: {
  onSubmit: () => void;
  pending: boolean;
  onEmojiClick: () => void;
  emojiButtonRef: React.RefObject<HTMLButtonElement>;
  handleImageUpload: (file: File) => void;
}) {
  return (
    <Flex
      align="center"
      className={activityCss.footer}
      flex="1 1 0%"
      justify="space-between"
      p="sm"
      pb="lg"
      px="lg"
    >
      <Flex align="center" gap="none">
        <RedoButton
          className={activityCss.footerButton}
          hierarchy="tertiary"
          IconLeading={FaceSmile}
          onClick={onEmojiClick}
          ref={emojiButtonRef}
          size="md"
        />
        <RedoButton
          className={activityCss.footerButton}
          hierarchy="tertiary"
          IconLeading={PaperclipIcon}
          onClick={() => {
            const input = document.createElement("input");
            input.type = "file";
            input.accept = "image/*";
            input.onchange = (e) => {
              const file = (e.target as HTMLInputElement).files?.[0];
              if (file) {
                handleImageUpload(file);
              }
            };
            input.click();
          }}
          size="md"
        />
      </Flex>
      <Flex align="center" overflow="hidden">
        <RedoButton
          className={activityCss.submitButton}
          disabled={pending}
          hierarchy="primary"
          IconLeading={ArrowIcon}
          onClick={() => onSubmit()}
          size="sm"
        />
      </Flex>
    </Flex>
  );
});
