import {
  useLazyContextProvider,
  useRequiredContext,
} from "@redotech/react-util/context";
import {
  CollectionsContext,
  DiscountMethodsContext,
  DiscountMethodsContextI,
  DiscountsContext,
} from "@redotech/redo-web/discounts/discount-selector";
import {
  convertShopifyIdToGid,
  ShopifyIdType,
} from "@redotech/util/shopify-id";
import { memo, ReactNode, useMemo } from "react";
import { RedoMarketingRpcClientContext } from "../../app/redo-marketing-rpc-client-provider";

export const DiscountsService = memo(function DiscountsService({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const marketingRpc = useRequiredContext(RedoMarketingRpcClientContext);

  const discounts = useLazyContextProvider(async (signal) => {
    return marketingRpc.getDiscountsByTeam({}, { signal });
  });

  const collections = useLazyContextProvider(async (signal) => {
    const collections = await marketingRpc.getSegmentCollections(null, {
      signal,
    });
    return collections.map((c) => {
      return {
        id: convertShopifyIdToGid(c.id, ShopifyIdType.Collection),
        name: c.title,
      };
    });
  });

  const discountMethods = useMemo(() => {
    const methods: DiscountMethodsContextI = {
      createDiscount: async ({ discountConfiguration }) => {
        const discount = await marketingRpc.createDiscount({
          discountConfiguration,
        });
        discounts.invalidate();
        return discount._id;
      },
      deleteDiscount: async (discountId) => {
        await marketingRpc.deleteDiscount({ _id: discountId });
        discounts.invalidate();
      },
      updateDiscount: async ({ discountId, discountConfiguration }) => {
        await marketingRpc.updateDiscount({
          _id: discountId,
          configuration: discountConfiguration,
        });
        discounts.invalidate();
      },
    };
    return methods;
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DiscountMethodsContext.Provider value={discountMethods}>
      <CollectionsContext.internalContext.Provider value={collections}>
        <DiscountsContext.internalContext.Provider value={discounts}>
          {children}
        </DiscountsContext.internalContext.Provider>
      </CollectionsContext.internalContext.Provider>
    </DiscountMethodsContext.Provider>
  );
});
