// extracted by mini-css-extract-plugin
export var chevron = "_4r-b";
export var disabled = "_4r-a";
export var hideBorder = "_4r-1";
export var icon = "_4r-c";
export var input = "_4r-0";
export var open = "_4r-8";
export var placeholder = "_4r-9";
export var regular = "_4r-4";
export var singleSelectInput = "_4r-2 _4r-0";
export var small = "_4r-3";
export var spinnerContainer = "_4r-e";
export var tag = "_4r-6";
export var tagsContainer = "_4r-7";
export var tagsInput = "_4r-5 _4r-0";
export var xSmall = "_4r-d";