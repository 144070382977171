import { z } from "zod";

export const MarketplaceNotificationStatusSchema = z.discriminatedUnion(
  "status",
  [
    z.object({ status: z.literal("complete") }),
    z.object({ status: z.literal("skipped") }),
    z.object({ status: z.literal("pending"), date: z.date().nullish() }),
  ],
);

export type MarketplaceNotificationStatus = z.infer<
  typeof MarketplaceNotificationStatusSchema
>;
