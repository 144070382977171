import { useTriggerLoad } from "@redotech/react-util/load";
import { RedoMerchantClient } from "@redotech/redo-merchant-app-common/client";
import {
  searchDiscountCodes,
  searchProducts,
} from "@redotech/redo-merchant-app-common/client/shopify";
import {
  ConversationPlatform,
  doesPlatformUseHtmlContent,
  MessageVisibility,
} from "@redotech/redo-model/conversation";
import { Team } from "@redotech/redo-model/team";
import { GetUser } from "@redotech/redo-model/user";
import { Autocomplete } from "@redotech/redo-web/autocomplete";
import { filterTruthy } from "@redotech/util/array";
import { assertNever } from "@redotech/util/type";
import Quill from "quill";
import { Delta as DeltaType, EmitterSource } from "quill/core";
import { memo, useEffect, useState } from "react";
import { useDebounce } from "usehooks-ts";
import { ShopifyProduct, ShopifyVariant } from "./create-order";
import { clearColoringAtCursor, getPdpUrl } from "./macros/quill-macro-utils";

interface SupportMessageAutocompleteProps {
  team: Team | null;

  /**
   * To allow the autocomplete to be opened, we don't conditionally render it.
   * So, the visible prop is used to control the visibility of the autocomplete.
   */
  visible: boolean;
  setVisible: (visible: boolean) => void;
  quill: Quill | null;
  client: RedoMerchantClient;
  messageVisibility: MessageVisibility;
  productsInMessage: ShopifyProduct[];
  setProductsInMessage: (products: ShopifyProduct[]) => void;
  discountCodesInMessage: string[];
  setDiscountCodesInMessage: (codes: string[]) => void;
  usersMentionedInMessage: { name: string; id: string }[];
  setUsersMentionedInMessage: (users: { name: string; id: string }[]) => void;
  autocompleteActionToPerform: AutocompletionAction | null;
  setAutocompleteActionToPerform: (action: AutocompletionAction | null) => void;
  shouldOpenFromExternalTrigger: AutocompleteType | undefined;
  setShouldOpenFromExternalTrigger: (
    type: AutocompleteType | undefined,
  ) => void;
  platform: ConversationPlatform;
}

export enum AutocompleteType {
  MENTION = "mention",
  DISCOUNT_CODE = "discountCode",
  PRODUCT = "product",
  MACRO = "macro",
}

const characterToAutoCompleteType: Record<string, AutocompleteType> = {
  "@": AutocompleteType.MENTION,
};

/** Most autocomplete actions are just inserting text, but some are more complex, e.g. applying formatting.*/
export enum AutocompletionActionType {
  INSERT_TEXT = "INSERT_TEXT",
  PERFORM_QUILL_OP = "PERFORM_QUILL_OP",
}

export type AutocompletionAction = { text: string } & (
  | { type: AutocompletionActionType.INSERT_TEXT }
  | {
      type: AutocompletionActionType.PERFORM_QUILL_OP;
      quillFn: (quill: Quill, cursorPosition: number) => void;
    }
);

const SPACER = " - ";

export const SupportMessageAutocomplete = memo(
  function SupportMessageAutocomplete({
    team,
    visible,
    setVisible,
    quill,
    client,
    messageVisibility,
    productsInMessage,
    setProductsInMessage,
    discountCodesInMessage,
    setDiscountCodesInMessage,
    usersMentionedInMessage,
    setUsersMentionedInMessage,
    shouldOpenFromExternalTrigger,
    setShouldOpenFromExternalTrigger,
    autocompleteActionToPerform,
    setAutocompleteActionToPerform,
    platform,
  }: SupportMessageAutocompleteProps) {
    const [shouldInsertText, setShouldInsertText] = useState(false);

    const [autocompleteInfo, setAutocompleteInfo] = useState<{
      top: number;
      left: number;
      indexOfSymbol: number;
      type: AutocompleteType;
    }>({ top: 0, left: 0, indexOfSymbol: 0, type: AutocompleteType.MENTION });

    const [discountCodeSearch, doDiscountCodeSearch] = useTriggerLoad(
      async (signal) => {
        if (!autocompleteActionToPerform) {
          return [];
        }
        const responseData = await searchDiscountCodes(client, {
          search: autocompleteActionToPerform.text,
          signal,
        });
        return responseData.discountCodes;
      },
    );
    const [productsSearch, doProductsSearch] = useTriggerLoad(
      async (signal) => {
        if (!autocompleteActionToPerform) {
          return [];
        }
        const responseData = await searchProducts(client, {
          search: autocompleteActionToPerform.text,
          signal,
        });
        return responseData.products;
      },
    );

    useEffect(() => {
      if (visible) {
        const input = document.getElementById("autocomplete-input");
        if (input) {
          input.focus();
        }
      }
    }, [visible]);

    const debouncedSearch = useDebounce(autocompleteActionToPerform, 500);
    useEffect(() => {
      if (
        autocompleteActionToPerform &&
        !shouldInsertText &&
        autocompleteInfo.type === AutocompleteType.DISCOUNT_CODE
      ) {
        // Search for discount codes
        doDiscountCodeSearch();
      }
      if (
        autocompleteActionToPerform &&
        !shouldInsertText &&
        autocompleteInfo.type === AutocompleteType.PRODUCT
      ) {
        // Search for products
        doProductsSearch();
      }
      // FIXME
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearch]);

    useEffect(() => {
      if (!autocompleteActionToPerform || !shouldInsertText) {
        return;
      }

      if (!quill) {
        return;
      }

      /** Trailing space isn't strictly necessary here, but visually shows the formatting ended */
      const textToInsertWithSpace = autocompleteActionToPerform.text + " ";

      switch (autocompleteActionToPerform.type) {
        case AutocompletionActionType.INSERT_TEXT:
          quill.insertText(
            autocompleteInfo.indexOfSymbol,
            textToInsertWithSpace,
            Quill.sources.USER,
          );
          break;
        case AutocompletionActionType.PERFORM_QUILL_OP:
          autocompleteActionToPerform.quillFn(
            quill,
            autocompleteInfo.indexOfSymbol,
          );
          break;
        default:
          assertNever(autocompleteActionToPerform);
      }
      setAutocompleteActionToPerform(null);
      setShouldInsertText(false);
      closeAutoComplete();

      quill.setSelection(
        autocompleteInfo.indexOfSymbol + textToInsertWithSpace.length,
      );
      // FIXME
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autocompleteActionToPerform, shouldInsertText]);

    useEffect(() => {
      if (!quill) {
        return;
      }
      const characterHotkeyCallback = (
        delta: DeltaType,
        oldDelta: DeltaType,
        source: EmitterSource,
      ) => {
        if (source === "user") {
          maybePerformCharacterHotkeyAction(delta);
        }
      };

      quill.on(Quill.events.TEXT_CHANGE, characterHotkeyCallback);
      return () => {
        quill.off(Quill.events.TEXT_CHANGE, characterHotkeyCallback);
      };
      // FIXME
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quill, messageVisibility]);

    /** Apply formatting to users mentioned in message if applicable */
    useEffect(() => {
      if (quill && team) {
        clearColoringAtCursor(quill, quill.getText().length);
      }
      // FIXME
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usersMentionedInMessage]);

    function maybePerformCharacterHotkeyAction(delta: DeltaType) {
      const autoCompleteToOpen: AutocompleteType | undefined = filterTruthy(
        delta.ops.map(
          (op) =>
            typeof op.insert === "string" &&
            characterToAutoCompleteType[op.insert],
        ),
      )[0];

      if (autoCompleteToOpen) {
        handleOpenHotkeyMenu(autoCompleteToOpen, { offsetLineStarts: true });
      }
    }

    /**
     * For hotkeys where we actually type a character, we need some 'off by one' logic
     * to paste the autocomplete content correctly
     */
    const handleOpenHotkeyMenu = (
      type: AutocompleteType,
      options?: { offsetLineStarts: boolean },
    ) => {
      if (!quill) {
        return;
      }

      if (
        messageVisibility !== MessageVisibility.INTERNAL &&
        type === AutocompleteType.MENTION
      ) {
        return;
      }
      const range = quill.getSelection();
      if (!range) {
        return;
      }

      let cursorPosition = range.index;

      const needsOffset = ["", "\n"].includes(
        quill.getText(cursorPosition - 1, 1),
      );
      if (options?.offsetLineStarts && needsOffset) {
        cursorPosition += 1;
      }

      const bounds = quill.getBounds(cursorPosition);
      if (!bounds) {
        return;
      }
      setAutocompleteInfo({
        top: bounds.top + 66,
        left: bounds.left + 24,
        indexOfSymbol: cursorPosition,
        type,
      });
      setVisible(true);
    };

    useEffect(() => {
      if (shouldOpenFromExternalTrigger) {
        handleOpenHotkeyMenu(shouldOpenFromExternalTrigger, {
          offsetLineStarts: true,
        });
        setShouldOpenFromExternalTrigger(undefined);
      }
      // FIXME
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldOpenFromExternalTrigger]);

    const closeAutoComplete = () => {
      setAutocompleteInfo({
        top: 0,
        left: 0,
        indexOfSymbol: 0,
        type: AutocompleteType.MENTION,
      });
      setVisible(false);
      if (quill) {
        quill.focus();
      }
    };

    const getAutocompleteOptions = (): string[] => {
      if (!team) {
        return [];
      }
      switch (autocompleteInfo.type) {
        case AutocompleteType.MENTION:
          return team.users.map((user) => (user.user as GetUser)._id);
        case AutocompleteType.DISCOUNT_CODE:
          return (
            discountCodeSearch.value?.map((discountCode) => discountCode) || []
          );
        case AutocompleteType.PRODUCT: {
          const variantLines: string[] = [];
          productsSearch.value?.forEach((product: ShopifyProduct) => {
            product.variants.forEach((variant) => {
              const line = `${product.title}${variant.title === "Default Title" ? "" : `${SPACER}${variant.title}`}\nIn stock: ${variant.inventory_quantity}`;
              variantLines.push(line);
            });
          });
          return variantLines;
        }
        default:
          return [];
      }
    };

    const getPlaceHolderText = () => {
      switch (autocompleteInfo.type) {
        case AutocompleteType.MENTION:
          return "Search users...";
        case AutocompleteType.DISCOUNT_CODE:
          return "Search discount codes...";
        case AutocompleteType.PRODUCT:
          return "Search products...";
      }
      return "Search...";
    };

    const getAutocompleteNoOptionsText = () => {
      if (autocompleteInfo.type === AutocompleteType.DISCOUNT_CODE) {
        return discountCodeSearch.pending
          ? "Searching..."
          : "No discount codes found";
      } else if (autocompleteInfo.type === AutocompleteType.PRODUCT) {
        return productsSearch.pending ? "Searching..." : "No products found";
      }
      return "No options";
    };
    const handleAutocompleteChange = (value: string | null) => {
      if (!value || !team) {
        closeAutoComplete();
        return;
      }

      if (autocompleteInfo.type === AutocompleteType.MENTION) {
        const user = team?.users.find(
          (user) => (user.user as GetUser)._id === value,
        );
        if (!user) {
          return;
        }
        setUsersMentionedInMessage([
          ...usersMentionedInMessage,
          { name: (user.user as GetUser).name, id: (user.user as GetUser)._id },
        ]);
        setAutocompleteActionToPerform({
          type: AutocompletionActionType.PERFORM_QUILL_OP,
          text: (user.user as GetUser).name,
          quillFn: (quill, cursorPosition) => {
            quill.insertText(
              cursorPosition,
              (user.user as GetUser).name + " ",
              Quill.sources.USER,
            );
            quill.formatText(
              cursorPosition - 1,
              (user.user as GetUser).name.length + 1,
              { color: "#194185", background: "#84CAFF" },
            );
          },
        });
      } else if (autocompleteInfo.type === AutocompleteType.DISCOUNT_CODE) {
        setDiscountCodesInMessage([...discountCodesInMessage, value]);
        setAutocompleteActionToPerform({
          type: AutocompletionActionType.PERFORM_QUILL_OP,
          text: value,
          quillFn: (quill, cursorPosition) => {
            quill.insertText(cursorPosition, value! + " ", Quill.sources.USER);
            quill.formatText(cursorPosition, value!.length, {
              color: "#067647",
              background: "#DCFAE6",
            });
          },
        });
      } else if (autocompleteInfo.type === AutocompleteType.PRODUCT) {
        const variantTitle =
          value.split(SPACER)[1]?.split("\n")[0] || "Default Title";
        const productTitle = value.split(
          variantTitle === "Default Title" ? "\n" : SPACER,
        )[0];
        const currentProduct = productsSearch.value?.find(
          (product: ShopifyProduct) => product.title === productTitle,
        );
        if (currentProduct) {
          setProductsInMessage([...productsInMessage, currentProduct]);
        }
        /** on non-email platform, list the title _and_ the link separately. */
        if (!doesPlatformUseHtmlContent[platform]) {
          const variant = currentProduct?.variants.find(
            (variant: ShopifyVariant) => variant.title === variantTitle,
          );
          const url = getPdpUrl(team, currentProduct.handle, variant?.id);
          const wholeText = `${currentProduct.title}${SPACER}${variantTitle === "Default Title" ? "" : `${variantTitle}${SPACER}`}${url}`;
          setAutocompleteActionToPerform({
            type: AutocompletionActionType.PERFORM_QUILL_OP,
            text: wholeText,
            quillFn: (quill, cursorPosition) => {
              quill.insertText(
                cursorPosition,
                wholeText + " ",
                Quill.sources.USER,
              );
              quill.formatText(
                cursorPosition + wholeText.length - url.length,
                url.length,
                { link: url },
              );
            },
          });

          /** On email platform, just hyperlink the title */
        } else {
          const variantTitle =
            value.split(SPACER)[1]?.split("\n")[0] || "Default Title";
          const productTitle = value.split(
            variantTitle === "Default Title" ? "\n" : SPACER,
          )[0];
          const currentProduct = productsSearch.value?.find(
            (product: ShopifyProduct) => product.title === productTitle,
          );
          const variant = currentProduct?.variants.find(
            (variant: ShopifyVariant) => variant.title === variantTitle,
          );
          setAutocompleteActionToPerform({
            type: AutocompletionActionType.PERFORM_QUILL_OP,
            text: currentProduct.title,
            quillFn: (quill, cursorPosition) => {
              quill.insertText(
                cursorPosition,
                currentProduct.title + " ",
                Quill.sources.USER,
              );
              quill.formatText(cursorPosition, currentProduct.title.length, {
                link: getPdpUrl(team, currentProduct.handle, variant?.id),
              });
            },
          });
        }
      }

      setShouldInsertText(true);
    };

    if (!visible) {
      return null;
    }

    return (
      <Autocomplete
        getLabel={(option) => {
          const user = team?.users.find(
            (user) => (user.user as GetUser)._id === option,
          );
          return user ? (user.user as GetUser).name : option;
        }}
        id="autocomplete-input"
        noOptionsText={getAutocompleteNoOptionsText()}
        onInputChange={(event, newInputValue) => {
          if (event?.type === "change") {
            if (!quill) {
              return;
            }
            if (!newInputValue.includes("In stock:")) {
              setAutocompleteActionToPerform({
                type: AutocompletionActionType.INSERT_TEXT,
                text: newInputValue,
              });
            }
          } else if (event?.type === "blur") {
            setShouldInsertText(true);
          }
        }}
        options={getAutocompleteOptions()}
        placeholder={getPlaceHolderText()}
        style={{
          position: "absolute",
          top: autocompleteInfo.top,
          left: autocompleteInfo.left,
          width: "250px",
          zIndex: 1000,
        }}
        valueChange={(submittedValue) =>
          handleAutocompleteChange(submittedValue)
        }
      />
    );
  },
);
