import { Currency } from "@redotech/money/currencies";
import { DateRange } from "@redotech/redo-model/concierge-conversation/analytics";
import { ConciergeConversationCategory } from "@redotech/redo-model/concierge-conversation/concierge-conversation-categories";
import { z } from "zod";

const conciergeMetrics = z.object({
  engagedSessionConversionRate: z.number(),
  sessionConversionRate: z.number(),
  ordersGenerated: z.number(),
  engagementRate: z.number(),
  revenueGenerated: z.number(),
  averageOrderValue: z.number(),
  shopCurrency: z.nativeEnum(Currency),
  deflectedTickets: z.number(),
  //  addToCartRate: z.number(),
});

export const getConciergeDashboardMetrics = {
  input: z.object({
    dateRange: z.nativeEnum(DateRange),
    attributionWindow: z.number().optional(),
  }),
  output: z.object({
    current: conciergeMetrics,
    previous: conciergeMetrics,
    conversationCategories: z.array(ConciergeConversationCategory),
    overallConversionOverTime: z.map(z.date(), z.number()),
    engagedConversionOverTime: z.map(z.date(), z.number()),
    nonEngagedConversionOverTime: z.map(z.date(), z.number()),
  }),
};
