import { CallState } from "@redotech/redo-model/support/voice/voice-types";
import ChevronDown from "@redotech/redo-web/arbiter-icon/chevron-down.svg";
import DotsGridIcon from "@redotech/redo-web/arbiter-icon/dots-grid.svg";
import DotsHorizontalIcon from "@redotech/redo-web/arbiter-icon/dots-horizontal.svg";
import MicrophoneIcon from "@redotech/redo-web/arbiter-icon/microphone-01.svg";
import PhonePauseIcon from "@redotech/redo-web/arbiter-icon/phone-pause.svg";
import PhoneXIcon from "@redotech/redo-web/arbiter-icon/phone-x.svg";
import VolumeMaxIcon from "@redotech/redo-web/arbiter-icon/volume-max.svg";
import { sinkPromise } from "@redotech/util/promise";
import { sinkValue } from "@redotech/util/type";
import { memo, useContext, useEffect, useMemo, useState } from "react";
import { RedoButton } from "../arbiter-components/buttons/redo-button";
import {
  RedoCommandMenu,
  RedoCommandMenuItem,
} from "../arbiter-components/command-menu/redo-command-menu";
import { RedoListItem } from "../arbiter-components/list/redo-list";
import { RedoListDropdown } from "../arbiter-components/list/redo-list-dropdown";
import { Flex } from "../flex";
import {
  handleInputDeviceChange,
  handleOutputDeviceChange,
  retrieveIODevices,
} from "./utils";
import { VoiceContext } from "./voice-context";

export const ActiveCallControls = memo(function ActiveCallSection({
  numpadShowing,
  toggleNumpadShowing,
}: {
  numpadShowing: boolean;
  toggleNumpadShowing(): void;
}) {
  const { endCall, call } = useContext(VoiceContext);

  const [availableInputDevices, setAvailableInputDevices] = useState<
    MediaDeviceInfo[]
  >([]);
  const [availableOutputDevices, setAvailableOutputDevices] = useState<
    MediaDeviceInfo[]
  >([]);
  const [currentInputDevice, setCurrentInputDevice] = useState<string | null>(
    null,
  );
  const [currentOutputDevice, setCurrentOutputDevice] = useState<string | null>(
    null,
  );

  const [audioStream, setAudioStream] = useState<MediaStream | null>(null);

  useEffect(() => {
    async function getInputDevices() {
      const devices = await retrieveIODevices({});
      setAvailableInputDevices(devices.audioInputDevices);
      setAvailableOutputDevices(devices.audioOutputDevices);
      setCurrentInputDevice(devices.currentInputDevice);
      setCurrentOutputDevice(devices.currentOutputDevice);
    }
    // Remove when we actualy use audio stream, this is to have ts pass
    sinkValue(audioStream);
    sinkPromise(getInputDevices());
  }, [audioStream]);

  return (
    <Flex bgColor="secondary" dir="column" p="xl" radius="sm">
      <Flex justify="space-evenly">
        <Microphone
          availableInputDevices={availableInputDevices}
          currentInputDevice={currentInputDevice}
          setAudioStream={setAudioStream}
          setCurrentInputDevice={setCurrentInputDevice}
        />
        <Speaker
          availableOutputDevices={availableOutputDevices}
          currentOutputDevice={currentOutputDevice}
          setCurrentOutputDevice={setCurrentOutputDevice}
        />
        <Hold />
        <OtherCallSettings
          numpadShowing={numpadShowing}
          toggleNumpadShowing={toggleNumpadShowing}
        />
      </Flex>
      <RedoButton
        disabled={call?.state === CallState.HANGUP}
        hierarchy="primary"
        IconTrailing={PhoneXIcon}
        onClick={endCall}
        text="Hang up"
        theme="destructive"
      />
    </Flex>
  );
});

const OtherCallSettings = memo(function OtherCallSettings({
  numpadShowing,
  toggleNumpadShowing,
}: {
  numpadShowing: boolean;
  toggleNumpadShowing(): void;
}) {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  const [settingsOpen, setSettingsOpen] = useState(false);

  const items: RedoCommandMenuItem[] = [
    // Todo add back when ready
    // {
    //   text: "Transfer call",
    //   onClick: fn,
    //   Icon: PhoneOutgoingIcon,
    // },
    // {
    //   text: "Add person to call",
    //   onClick: fn,
    //   Icon: UserPlusIcon,
    // },
    // {
    //   text: "View customer profile",
    //   onClick: fn,
    //   Icon: ImageUserRightIcon,
    // },
    // {
    //   text: "View transcript",
    //   onClick: fn,
    //   Icon: RecordingIcon,
    // },
    {
      text: numpadShowing ? "Hide numpad" : "Show numpad",
      onClick: toggleNumpadShowing,
      Icon: DotsGridIcon,
    },
  ];

  return (
    <>
      <RedoButton
        hierarchy="secondary"
        IconLeading={DotsHorizontalIcon}
        onClick={() => setSettingsOpen((open) => !open)}
        ref={setAnchor}
      />
      <RedoCommandMenu
        anchor={anchor}
        items={items}
        open={settingsOpen}
        setOpen={setSettingsOpen}
      />
    </>
  );
});

const Hold = memo(function Hold() {
  const { onHold, toggleHold } = useContext(VoiceContext);

  return (
    <RedoButton
      hierarchy="secondary"
      IconLeading={PhonePauseIcon}
      onClick={toggleHold}
      pressed={onHold}
    />
  );
});

const Microphone = memo(function Microphone({
  availableInputDevices,
  currentInputDevice,
  setCurrentInputDevice,
  setAudioStream,
}: {
  availableInputDevices: MediaDeviceInfo[];
  currentInputDevice: string | null;
  setCurrentInputDevice(deviceId: string): void;
  setAudioStream(stream: MediaStream | null): void;
}) {
  const { muted, toggleMute } = useContext(VoiceContext);
  const [dropdownButtonRef, setDropdownButtonRef] =
    useState<HTMLButtonElement | null>(null);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const deviceOptions: RedoListItem<MediaDeviceInfo>[] = useMemo(() => {
    return availableInputDevices.map((device) => ({
      id: device.deviceId,
      value: device,
      type: "text",
      text: device.label,
    }));
  }, [availableInputDevices]);

  return (
    <Flex gap="none">
      <RedoButton
        hierarchy="secondary"
        IconLeading={MicrophoneIcon}
        onClick={toggleMute}
        pressed={muted}
      />
      <RedoButton
        hierarchy="tertiary"
        IconLeading={ChevronDown}
        onClick={() => setDropdownOpen((open) => !open)}
        ref={setDropdownButtonRef}
      />
      <RedoListDropdown
        dropdownAnchor={dropdownButtonRef}
        dropdownOpen={dropdownOpen}
        items={deviceOptions}
        itemSelected={({ item }) => {
          sinkPromise(
            handleInputDeviceChange({
              deviceId: item.value.deviceId,
              setCurrentInputDevice,
              setAudioStream,
            }),
          );
        }}
        selectedItems={currentInputDevice || undefined}
        setDropdownOpen={setDropdownOpen}
        size="xs"
      />
    </Flex>
  );
});

const Speaker = memo(function Microphone({
  availableOutputDevices,
  currentOutputDevice,
  setCurrentOutputDevice,
}: {
  availableOutputDevices: MediaDeviceInfo[];
  currentOutputDevice: string | null;
  setCurrentOutputDevice(deviceId: string): void;
}) {
  const { deafened, toggleDeafen } = useContext(VoiceContext);
  const [dropdownButtonRef, setDropdownButtonRef] =
    useState<HTMLButtonElement | null>(null);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const deviceOptions: RedoListItem<MediaDeviceInfo>[] = useMemo(() => {
    return availableOutputDevices.map((device) => ({
      id: device.deviceId,
      value: device,
      type: "text",
      text: device.label,
    }));
  }, [availableOutputDevices]);

  return (
    <Flex gap="none">
      <RedoButton
        hierarchy="secondary"
        IconLeading={VolumeMaxIcon}
        onClick={toggleDeafen}
        pressed={deafened}
      />
      <RedoButton
        hierarchy="tertiary"
        IconLeading={ChevronDown}
        ref={setDropdownButtonRef}
      />
      <RedoListDropdown
        dropdownAnchor={dropdownButtonRef}
        dropdownOpen={dropdownOpen}
        items={deviceOptions}
        itemSelected={({ item }) => {
          sinkPromise(
            handleOutputDeviceChange({
              deviceId: item.value.deviceId,
              setCurrentOutputDevice,
            }),
          );
        }}
        selectedItems={currentOutputDevice || undefined}
        setDropdownOpen={setDropdownOpen}
        size="xs"
      />
    </Flex>
  );
});
