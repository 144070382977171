import {
  discriminatedUnion,
  literal,
  nativeEnum,
  object,
  string,
  infer as zInfer,
} from "zod";
import { FedexCrossBorderHubOrigin, RedoCarrierName } from "./carrier-account";

export const DHLCarrierAccountCredentials = object({
  distribution_center: string(),
  pickup_id: string(),
  client_id: string(),
  client_secret: string(),
});

export type IDHLCarrierAccountCredentials = zInfer<
  typeof DHLCarrierAccountCredentials
>;

export const UPSCarrierAccountCredentials = object({
  account_number: string(),
});

export type IUPSCarrierAccountCredentials = zInfer<
  typeof UPSCarrierAccountCredentials
>;

export const UPSSurepostCarrierAccountCredentials = object({
  account_number: string(),
});

export type IUPSSurepostCarrierAccountCredentials = zInfer<
  typeof UPSSurepostCarrierAccountCredentials
>;

export const USPSCarrierAccountCredentials = object({
  company_name: string(),
  address_street: string(),
  address_city: string(),
  address_state: string(),
  address_zip: string(),
  phone: string(),
  email: string(),
  shipper_id: string().optional(),
});

export type IUSPSCarrierAccountCredentials = zInfer<
  typeof USPSCarrierAccountCredentials
>;

export const FedexCrossBorderCarrierAccountCredentials = object({
  api_key: string(),
  connect_hub_origin: nativeEnum(FedexCrossBorderHubOrigin),
});

export const defaultCarrierCredentials = object({
  company_name: string(),
  address_street: string(),
  address_city: string(),
  address_state: string(),
  address_zip: string(),
  address_country: string(),
  phone: string(),
  email: string(),
});

export type IDefaultCarrierCredentials = zInfer<
  typeof defaultCarrierCredentials
>;

export type IFedexCrossBorderCarrierAccountCredentials = zInfer<
  typeof FedexCrossBorderCarrierAccountCredentials
>;

export const FedexCarrierAccountCredentials = object({
  account_number: string(),
  shipping_city: string(),
  shipping_country_code: string(),
  shipping_postal_code: string(),
  shipping_state: string(),
  shipping_streets: string(),
  corporate_city: string(),
  corporate_company_name: string(),
  corporate_country_code: string(),
  corporate_email_address: string(),
  corporate_first_name: string(),
  corporate_job_title: string(),
  corporate_last_name: string(),
  corporate_phone_number: string(),
  corporate_postal_code: string(),
  corporate_state: string(),
  corporate_streets: string(),
});

export type IFedexCarrierAccountCredentials = zInfer<
  typeof FedexCarrierAccountCredentials
>;

export const CreateCarrierAccount = object({
  name: string(),
  data: discriminatedUnion("redoCarrierName", [
    object({
      redoCarrierName: literal(RedoCarrierName.DHL_ECS),
      ...DHLCarrierAccountCredentials.shape,
    }),
    object({
      redoCarrierName: literal(RedoCarrierName.UPS),
      ...UPSCarrierAccountCredentials.shape,
    }),
    object({
      redoCarrierName: literal(RedoCarrierName.UPS_SUREPOST),
      ...UPSSurepostCarrierAccountCredentials.shape,
    }),
    object({
      redoCarrierName: literal(RedoCarrierName.USPS),
      ...USPSCarrierAccountCredentials.shape,
    }),
    object({
      redoCarrierName: literal(RedoCarrierName.FEDEX_CROSS_BORDER),
      ...FedexCrossBorderCarrierAccountCredentials.shape,
    }),
    object({
      redoCarrierName: literal(RedoCarrierName.FEDEX),
      ...FedexCarrierAccountCredentials.shape,
    }),
  ]),
});

export type ICreateCarrierAccount = zInfer<typeof CreateCarrierAccount>;
