import { RedoModal } from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import TrashIcon from "@redotech/redo-web/icon-old/trash.svg";
import { memo } from "react";
import { TagWithId } from "./create-tag-modal";

export const DeleteTagModal = memo(function DeleteTagModal({
  tag,
  cancelled,
  handleDelete,
}: {
  tag: TagWithId;
  cancelled(): void;
  handleDelete: ({ tagId }: { tagId: string }) => Promise<void>;
}) {
  async function handleSubmit() {
    await handleDelete({ tagId: tag.tagId });
  }

  return (
    <RedoModal
      isOpen
      onModalCloseRequested={cancelled}
      primaryButton={{ text: "Delete", onClick: handleSubmit }}
      secondaryButton={{ text: "Cancel", onClick: cancelled }}
      subtitle="Are you sure you want to delete this tag? This will remove this tag from all conversations, filters, and views."
      theme="error"
      title="Delete tag"
      TitleIcon={TrashIcon}
    />
  );
});
