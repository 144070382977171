import { useHandler } from "@redotech/react-util/hook";
import { UserContext } from "@redotech/redo-merchant-app-common/user";
import { FlowType, Step as ModelStep } from "@redotech/redo-model/return-flow";
import { Permission, permitted } from "@redotech/redo-model/user";
import { BlockLayout } from "@redotech/redo-web/flowchart";
import MoneyIcon from "@redotech/redo-web/icon-old/share-money.svg";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { produce } from "immer";
import { memo, useContext } from "react";
import { AdjustmentElement } from "./return";
import { StepDownstream, StepId, StepType, StepTypeDetailsProps } from "./step";

export interface State {
  adjustmentFlat: string;
  adjustmentPercent: string;
  next: StepId | undefined;
  customTitle?: string;
}

function valid(state: State, flowType: FlowType) {
  return (
    state.adjustmentFlat !== undefined || state.adjustmentPercent !== undefined
  );
}

const Details = memo(function Details({
  stepSelect,
  state,
  setState,
  flowType,
}: StepTypeDetailsProps<State>) {
  const user = useContext(UserContext);
  const canEditSettings =
    !!user && permitted(user.permissions, Permission.EDIT_SETTINGS);
  const nextStepChange = useHandler((next: StepId | undefined) => {
    setState((state) => ({ ...state, next }));
  });
  const handleAdjustmentFlat = useHandler((adjustmentFlat: string) => {
    setState((state) => ({ ...state, adjustmentFlat }));
  });
  const handleAdjustmentPercent = useHandler((adjustmentPercent: string) => {
    setState((state) => ({ ...state, adjustmentPercent }));
  });

  return (
    <>
      <AdjustmentElement
        adjustmentFlat={state.adjustmentFlat}
        adjustmentPercent={state.adjustmentPercent}
        canEditSettings={canEditSettings}
        handleAdjustmentFlat={handleAdjustmentFlat}
        handleAdjustmentPercent={handleAdjustmentPercent}
      />
      <LabeledInput label="Next step">
        {stepSelect({ value: state.next, valueChange: nextStepChange })}
      </LabeledInput>
    </>
  );
});

export const OVERRIDE_ADJUSTMENT: StepType<
  State,
  ModelStep.OverrideAdjustment
> = {
  Details,
  customTitle(state) {
    return state.customTitle;
  },
  title: "Override Adjustment",
  valid,
  Icon: MoneyIcon,
  description(state) {
    // Show adjustmentFlat first if not 0, then show adjustmentPercent if not 0, then default to $0
    return `Set adjustment to ${
      state?.adjustmentFlat && +state.adjustmentFlat !== 0
        ? // Show negative sign in front of dollar sign
          `${+state.adjustmentFlat < 0 ? "-" : ""}$${Math.abs(
            +state.adjustmentFlat,
          )}`
        : state?.adjustmentPercent && +state.adjustmentPercent !== 0
          ? `${state.adjustmentPercent}%`
          : "$0"
    }`;
  },
  downstream(state) {
    const result: StepDownstream[] = [];
    if (state.next !== undefined) {
      result.push({ id: state.next });
    }
    return result;
  },
  empty: { next: undefined, adjustmentFlat: "0", adjustmentPercent: "0" },
  stepDeleted(state, stepId) {
    return produce(state, (state) => {
      if (state.next === stepId) {
        state.next = undefined;
      }
    });
  },
  layout() {
    return BlockLayout.FULL;
  },
  toModel(state, id): ModelStep.OverrideAdjustment {
    return {
      customTitle: state.customTitle,
      type: ModelStep.OVERRIDE_ADJUSTMENT,
      next: state.next ? id(state.next) : undefined,
      adjustment: {
        flat: +state.adjustmentFlat,
        proportion: +state.adjustmentPercent / 100 + 1,
      },
    };
  },
  fromModel(model, id) {
    return {
      customTitle: model.customTitle,
      next: model.next ? id(model.next) : undefined,
      adjustmentFlat: model.adjustment.flat.toString(),
      adjustmentPercent: (
        Math.round((model.adjustment.proportion - 1) * 10000) / 100
      ).toString(),
    };
  },
};
