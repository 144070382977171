import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { useWaitForPrevious } from "@redotech/react-util/wait-for-previous";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { getConversationsCounts } from "@redotech/redo-merchant-app-common/client/team";
import { MerchantAppTopic } from "@redotech/redo-merchant-app-common/events/merchant-app-event-server";
import { MerchantAppEventServerContext } from "@redotech/redo-merchant-app-common/events/merchant-app-event-server-provider";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { UserContext } from "@redotech/redo-merchant-app-common/user";
import { ConversationFiltersV3 } from "@redotech/redo-model/conversation-filters/conversation-filters";
import { FrontendTeamNoticeEvent } from "@redotech/redo-model/team-notifications/team-notifications";
import { sinkPromise } from "@redotech/util/promise";
import { createContext, memo, useContext, useEffect, useState } from "react";
import { ViewsContext } from "../../app/views";
import { maybeRunConversationsRefresh } from "../../support/handle-notice-event";
import { getPresetViewFilters } from "../../support/utils";

export type ConversationCounts = Record<string, number>;
export const ConversationCountsContext = createContext<
  ConversationCounts | undefined
>(undefined);

export const ConversationCountsService = memo(
  function ConversationCountsService({
    children,
  }: {
    children: React.ReactNode;
  }) {
    const user = useRequiredContext(UserContext);
    const team = useRequiredContext(TeamContext);
    const client = useRequiredContext(RedoMerchantClientContext);
    const eventServer = useRequiredContext(MerchantAppEventServerContext);
    const customViews = useContext(ViewsContext);
    const [conversationCounts, setConversationCounts] = useState<
      Record<string, number>
    >({});

    const supportEnabled = team?.settings.support?.enabled;

    const builtInViewNames: readonly string[] = [
      "all",
      "assigned",
      "mentions",
      "drafts",
    ];

    const fetchConversationCounts = useHandler(async () => {
      const presetViewFilters = builtInViewNames.reduce(
        (acc, viewName) => {
          const filters = getPresetViewFilters(viewName, user);
          if (filters) {
            acc[viewName] = filters;
          }
          return acc;
        },
        {} as Record<string, ConversationFiltersV3>,
      );

      const conversationCounts = await getConversationsCounts(client, {
        views: customViews,
        presetViewFilters,
      });

      setConversationCounts(conversationCounts);
    });

    const throttledFetchConversationCounts = useWaitForPrevious(
      fetchConversationCounts,
    );

    const fetchConversationCountsIgnoreResult = useHandler(() => {
      sinkPromise(throttledFetchConversationCounts());
    });

    useEffect(() => {
      if ("Notification" in window) {
        void Notification.requestPermission();
      }
      if (supportEnabled && user && client && customViews) {
        const unlistenCallback = eventServer.subscribeAndCallOnce({
          topic: MerchantAppTopic.TEAM,
          callback: async (message: FrontendTeamNoticeEvent | null) => {
            maybeRunConversationsRefresh(
              message,
              fetchConversationCountsIgnoreResult,
            );
          },
        });
        return () => unlistenCallback();
      }
      return undefined;
      // FIXME
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supportEnabled, user, client, customViews]);

    return (
      <ConversationCountsContext.Provider value={conversationCounts}>
        {children}
      </ConversationCountsContext.Provider>
    );
  },
);
