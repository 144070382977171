function getUrlPrefix(teamId: string) {
  return `/stores/${teamId}`;
}

export function getBaseSettingsLink(teamId: string) {
  return `${getUrlPrefix(teamId)}/settings`;
}

export enum BillingTab {
  RETURNS_LABELS = "returns-labels",
  AI_SUPPORT = "ai-support",
  ORDER_TRACKING = "order-tracking",
  SMS_MMS = "sms-mms",
  MARKETING_EMAIL_SMS = "marketing-email-sms",
  OUTBOUND_LABELS = "outbound-labels",
  CHECKOUT_OPTIMIZATION = "checkout-optimization",
}
export const CURRENT_BILLING_TAB_QUERY = "currentTab";

export function getBillingPageLink(teamId: string, tab: BillingTab): string {
  return `${getBaseSettingsLink(teamId)}/billing?${CURRENT_BILLING_TAB_QUERY}=${tab}`;
}

export function getCustomerSatisfactionPageLink(teamId: string): string {
  return `${getBaseSettingsLink(teamId)}/customer-satisfaction`;
}

export function getBaseSupportLink(teamId: string) {
  return `${getUrlPrefix(teamId)}/support/table/all`;
}

export function getSingleConversationLink(
  teamId: string,
  conversationId: string,
) {
  return `${getBaseSupportLink(teamId)}?activeConversationId=${conversationId}`;
}
