// This is a copy of the OrderTransactionKind enum from @redotech/shopify-client/admin.graphql, but that can also be used in redo/model and therefore the frontend

export enum OrderTransactionKind {
  /**
   * An amount reserved against the cardholder's funding source.
   * Money does not change hands until the authorization is captured.
   */
  Authorization = "AUTHORIZATION",
  /** A transfer of the money that was reserved by an authorization. */
  Capture = "CAPTURE",
  /** The money returned to the customer when they've paid too much during a cash transaction. */
  Change = "CHANGE",
  /** An authorization for a payment taken with an EMV credit card reader. */
  EmvAuthorization = "EMV_AUTHORIZATION",
  /**
   * A partial or full return of captured funds to the cardholder.
   * A refund can happen only after a capture is processed.
   */
  Refund = "REFUND",
  /** An authorization and capture performed together in a single step. */
  Sale = "SALE",
  /** A suggested refund transaction that can be used to create a refund. */
  SuggestedRefund = "SUGGESTED_REFUND",
  /** A cancelation of an authorization transaction. */
  Void = "VOID",
}
