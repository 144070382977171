import { Currency } from "@redotech/money/currencies";
import { RegisterInit } from "@shopify/web-pixels-extension";
import {
  MoneyV2,
  PixelEventsCheckoutCompleted,
  PixelEventsCheckoutStarted,
  PixelEventsCollectionViewed,
  PixelEventsProductAddedToCartData,
  PixelEventsProductRemovedFromCart,
  PixelEventsProductViewedData,
} from "@shopify/web-pixels-extension/build/ts/types/PixelEvents";
import { MarketingEntryPoint } from "../advanced-flow/schemas/marketing/marketing";
import { OutreachGroupKey } from "../aggregated-metrics/outreach";
import { ConversationStatus } from "../conversation";
import { CustomerIdentifiers } from "../customer";
import { MarketingChannel } from "../marketing";
import { SesEvent, ShopifyAddress } from "../order";
import { ReturnStatus } from "../return-status";
import { CompleteOrderFulfillmentStatus } from "../shopify-order";
import { SignupForm } from "../signup-form-schema";

export enum CustomerEventType {
  ORDER_CREATED = "ORDER_CREATED",
  ORDER_STATUS_UPDATED = "ORDER_STATUS_UPDATED",
  ORDER_UPDATED = "ORDER_UPDATED",

  RETURN_CREATED = "RETURN_CREATED",
  RETURN_STATUS_UPDATED = "RETURN_STATUS_UPDATED",
  RETURN_COMMENT_CREATED = "RETURN_COMMENT_CREATED",
  RETURN_PROCESSED = "RETURN_PROCESSED",

  CLAIM_CREATED = "CLAIM_CREATED",
  CLAIM_STATUS_UPDATED = "CLAIM_STATUS_UPDATED",
  CLAIM_COMMENT_CREATED = "CLAIM_COMMENT_CREATED",

  CONVERSATION_CREATED = "CONVERSATION_CREATED",
  CONVERSATION_STATUS_UPDATED = "CONVERSATION_STATUS_UPDATED",
  CONVERSATION_MESSAGE_CREATED = "CONVERSATION_MESSAGE_CREATED",
  EXTERNAL_PLATFORM_CUSTOMER_CREATED = "EXTERNAL_PLATFORM_CUSTOMER_CREATED",

  STOREFRONT_LOGIN = "STOREFRONT_LOGIN",

  MARKETING_SUBSCRIBED = "MARKETING_SUBSCRIBED",
  MARKETING_CONFIRMED = "MARKETING_CONFIRMED",
  MARKETING_UNSUBSCRIBED = "MARKETING_UNSUBSCRIBED",

  PRODUCT_VIEWED = "PRODUCT_VIEWED",
  PRODUCT_ADDED_TO_CART = "PRODUCT_ADDED_TO_CART",
  PRODUCT_REMOVED_FROM_CART = "PRODUCT_REMOVED_FROM_CART",
  CHECKOUT_STARTED = "CHECKOUT_STARTED",
  CHECKOUT_CONTACT_INFO_SUBMITTED = "CHECKOUT_CONTACT_INFO_SUBMITTED",
  CHECKOUT_COMPLETED = "CHECKOUT_COMPLETED",
  STORE_PAGE_VIEWED = "STORE_PAGE_VIEWED",
  COLLECTION_VIEWED = "COLLECTION_VIEWED",

  OUTREACH_SEND = "OUTREACH_SEND",
  OUTREACH_DELIVERY = "OUTREACH_DELIVERY",
  OUTREACH_OPEN = "OUTREACH_OPEN",
  OUTREACH_CLICK = "OUTREACH_CLICK",
  OUTREACH_BOUNCE = "OUTREACH_BOUNCE",
  OUTREACH_COMPLAINT = "OUTREACH_COMPLAINT",

  CUSTOMER_GROUP_ENTER = "CUSTOMER_GROUP_ENTER",
  CUSTOMER_GROUP_EXIT = "CUSTOMER_GROUP_EXIT",

  CUSTOMER_INFORMATION_UPDATE = "CUSTOMER_INFORMATION_UPDATE",
}

export const CUSTOMER_EVENT_DISCRIMINATOR_KEY = "eventType" as const;

export enum ExternalCustomerPlatform {
  SHOPIFY = "shopify",
}

export enum UnsubscribeSource {
  SHOPIFY = "shopify",
  EMAIL = "email",
  ONE_CLICK_EMAIL = "one-click-email",
  SMS_STOP_MESSAGE = "sms-stop-message",
  MERCHANT = "merchant",
  SMS_LINK = "sms-link",
}

export interface BaseCustomerEvent
  extends Omit<CustomerIdentifiers, "redoCustomerId"> {
  _id: string;
  customer?: string; // TODO: make required (a customer will always exist for an event after the customer merging algorithm finishes)
  team: string;
  idempotencyKey?: string; // TODO: make required when we implement idempotency for every event type
  // TODO: the potential identifiers on an event need to exactly match the potential identifiers on a customer
  // except it should be `redoId` instead of `_id`
  // This is different from the `customer` field,
  // because redoId is the id at the time of event creation (and will not change),
  // while customer is the current id (which may change if the customer is merged)
  timestamp: Date;
  [CUSTOMER_EVENT_DISCRIMINATOR_KEY]: CustomerEventType;
}

// ###
// OrderCustomerEvents
// ###
export interface IOrderCustomerEvent extends BaseCustomerEvent {
  orderId: string; // id of the Redo Order document
  shopifyId: string; // id of the Shopify order
}

interface OrderLineItem {
  productId: string;
  variantId: string;
}

export interface OrderCreatedCustomerEvent extends IOrderCustomerEvent {
  eventType: CustomerEventType.ORDER_CREATED;
  itemCount: number;
  orderName: string;
  totalPrice: string;
  sourceName?: string;
  currency?: Currency;
  lineItems?: OrderLineItem[];
  billingAddress?: ShopifyAddress;
  usedRedoCoverage?: boolean;
}

export interface OrderUpdatedCustomerEvent extends IOrderCustomerEvent {
  eventType: CustomerEventType.ORDER_UPDATED;
}

export interface OrderStatusUpdatedCustomerEvent extends IOrderCustomerEvent {
  eventType: CustomerEventType.ORDER_STATUS_UPDATED;
  newStatus: CompleteOrderFulfillmentStatus;
}

// ###
// ReturnCustomerEvents
// ###
export interface IReturnCustomerEvent extends BaseCustomerEvent {
  returnId: string;
}

export interface ReturnCreatedCustomerEvent extends IReturnCustomerEvent {
  eventType: CustomerEventType.RETURN_CREATED;
}

export interface ReturnStatusUpdatedCustomerEvent extends IReturnCustomerEvent {
  eventType: CustomerEventType.RETURN_STATUS_UPDATED;
  newStatus: ReturnStatus;
}

export interface ReturnCommentCreatedCustomerEvent
  extends IReturnCustomerEvent {
  eventType: CustomerEventType.RETURN_COMMENT_CREATED;
  messageId: string;
}

export interface ReturnProcessedCustomerEvent extends IReturnCustomerEvent {
  eventType: CustomerEventType.RETURN_PROCESSED;
}

// ###
// ClaimCustomerEvents
// ###
export interface IClaimCustomerEvent extends BaseCustomerEvent {
  claimId: string;
}

export interface ClaimCreatedCustomerEvent extends IClaimCustomerEvent {
  eventType: CustomerEventType.CLAIM_CREATED;
}

export interface ClaimStatusUpdatedCustomerEvent extends IClaimCustomerEvent {
  eventType: CustomerEventType.CLAIM_STATUS_UPDATED;
  newStatus: ReturnStatus;
}

export interface ClaimCommentCreatedCustomerEvent extends IClaimCustomerEvent {
  eventType: CustomerEventType.CLAIM_COMMENT_CREATED;
  messageId: string;
}

// ###
// ConversationCustomerEvents
// ###
export interface IConversationCustomerEvent extends BaseCustomerEvent {
  conversationId: string;
}

export interface ConversationCreatedCustomerEvent
  extends IConversationCustomerEvent {
  eventType: CustomerEventType.CONVERSATION_CREATED;
}

export interface ConversationStatusUpdatedCustomerEvent
  extends IConversationCustomerEvent {
  eventType: CustomerEventType.CONVERSATION_STATUS_UPDATED;
  newStatus: ConversationStatus;
}

export interface ConversationMessageCreatedCustomerEvent
  extends IConversationCustomerEvent {
  eventType: CustomerEventType.CONVERSATION_MESSAGE_CREATED;
  messageId: string;
}

// ###
// ExternalPlatformCustomerCreatedEvent
// ###
export interface ExternalPlatformCustomerCreatedEvent
  extends BaseCustomerEvent {
  eventType: CustomerEventType.EXTERNAL_PLATFORM_CUSTOMER_CREATED;
  externalPlatform: ExternalCustomerPlatform;
  externalPlatformCustomerId: string;
}

// ###
// StorefrontLoginEvent
// ###
export interface StorefrontLoginEvent extends BaseCustomerEvent {
  eventType: CustomerEventType.STOREFRONT_LOGIN;
  multipassLogin: boolean;
}

// ###
// MarketingSubscribeEvent
// ###
export interface MarketingSubscriptionMetadata {
  entryPoint: MarketingEntryPoint;
  formId?: string;
  /**
   * Only needed for auditing purposes.
   * Don't sweat it too much.
   */
  formSnapshot: FormSnapshot;
  customerIpAddress?: string;
}
export type FormSnapshot = SignupForm | string;
export interface MarketingSubscribeEvent
  extends BaseCustomerEvent,
    MarketingSubscriptionMetadata {
  eventType: CustomerEventType.MARKETING_SUBSCRIBED;
}

export enum MarketingConfirmSource {
  AUTOCONFIRM = "autoconfirm",
  BACKFILL = "backfill",
  CSV_IMPORT = "csv-import",
  MERCHANT = "merchant",
  SMS_MESSAGE = "sms-message",
}

// ###
// MarketingConfirmEvent
// ###
export interface MarketingConfirmEvent extends BaseCustomerEvent {
  eventType: CustomerEventType.MARKETING_CONFIRMED;
  subscriptionType: MarketingChannel;
  source?: MarketingConfirmSource;
}

// ###
// MarketingUnsubscribeEvent
// ###
export interface MarketingUnsubscribeEvent extends BaseCustomerEvent {
  eventType: CustomerEventType.MARKETING_UNSUBSCRIBED;
  source?: UnsubscribeSource;
  subscriptionType: MarketingChannel;
}

const marketingEventTypes = [
  CustomerEventType.MARKETING_SUBSCRIBED,
  CustomerEventType.MARKETING_CONFIRMED,
  CustomerEventType.MARKETING_UNSUBSCRIBED,
] as const;
export type MarketingEventType = (typeof marketingEventTypes)[number];

export interface ProductInfo {
  productId: string;
  productTitle: string;
  variantId?: string;
  variantTitle?: string;
  url?: string;
  price: MoneyV2;
  priceRange?: { minVariantPrice: MoneyV2; maxVariantPrice: MoneyV2 };
  imageUrl?: string;
  handle?: string;
}

export interface BaseShoppingEvent extends BaseCustomerEvent {
  /**
   * The shopify event id of the shopping event
   */
  externalEventId: string;
  externalCustomer: RegisterInit["data"]["customer"] | undefined;
}

export interface ProductViewedShoppingEvent extends BaseShoppingEvent {
  eventType: CustomerEventType.PRODUCT_VIEWED;
  productVariant: PixelEventsProductViewedData["productVariant"];
  cart: RegisterInit["data"]["cart"];
}

export interface ProductAddedToCartShoppingEvent extends BaseShoppingEvent {
  eventType: CustomerEventType.PRODUCT_ADDED_TO_CART;
  cartLine: PixelEventsProductAddedToCartData["cartLine"];
  cart?: RegisterInit["data"]["cart"];
}

export interface CheckoutStartedShoppingEvent extends BaseShoppingEvent {
  eventType: CustomerEventType.CHECKOUT_STARTED;
  checkout: PixelEventsCheckoutStarted["data"]["checkout"];
  cart: RegisterInit["data"]["cart"] | null;
}

export interface CheckoutCompletedShoppingEvent extends BaseShoppingEvent {
  eventType: CustomerEventType.CHECKOUT_COMPLETED;
  checkout: PixelEventsCheckoutCompleted["data"]["checkout"];
}

export interface StorePageViewedShoppingEvent extends BaseShoppingEvent {
  eventType: CustomerEventType.STORE_PAGE_VIEWED;
  urlWithoutParams?: string;
  urlWithParams?: string;
}

export interface CollectionViewedShoppingEvent extends BaseShoppingEvent {
  eventType: CustomerEventType.COLLECTION_VIEWED;
  collection: PixelEventsCollectionViewed["data"]["collection"];
}

export interface ProductRemovedFromCartShoppingEvent extends BaseShoppingEvent {
  eventType: CustomerEventType.PRODUCT_REMOVED_FROM_CART;
  cartLine: PixelEventsProductRemovedFromCart["data"]["cartLine"];
  cart: RegisterInit["data"]["cart"];
}

export interface CheckoutContactInfoSubmittedShoppingEvent
  extends BaseShoppingEvent {
  eventType: CustomerEventType.CHECKOUT_CONTACT_INFO_SUBMITTED;
  checkout: PixelEventsCheckoutStarted["data"]["checkout"];
}

export interface OutreachBaseEvent extends BaseCustomerEvent, OutreachGroupKey {
  template?: string;
  category?: string;
  channel?: MarketingChannel;
  channelId: string;
  automationExecutionId?: string;
  rawEvent?: SesEvent;
}

export interface OutreachSendEvent extends OutreachBaseEvent {
  eventType: CustomerEventType.OUTREACH_SEND;
}

export interface OutreachDeliveryEvent extends OutreachBaseEvent {
  eventType: CustomerEventType.OUTREACH_DELIVERY;
}

export interface OutreachOpenEvent extends OutreachBaseEvent {
  eventType: CustomerEventType.OUTREACH_OPEN;
}

export interface OutreachClickEvent extends OutreachBaseEvent {
  eventType: CustomerEventType.OUTREACH_CLICK;
}

export interface OutreachBounceEvent extends OutreachBaseEvent {
  eventType: CustomerEventType.OUTREACH_BOUNCE;
}

export interface OutreachComplaintEvent extends OutreachBaseEvent {
  eventType: CustomerEventType.OUTREACH_COMPLAINT;
}

interface CustomerGroupMembershipEvent extends BaseCustomerEvent {
  customerGroupId: string;
}

export interface CustomerGroupEnterEvent extends CustomerGroupMembershipEvent {
  eventType: CustomerEventType.CUSTOMER_GROUP_ENTER;
}

export interface CustomerGroupExitEvent extends CustomerGroupMembershipEvent {
  eventType: CustomerEventType.CUSTOMER_GROUP_EXIT;
}

export type CustomerGroupEvent =
  | CustomerGroupEnterEvent
  | CustomerGroupExitEvent;

export function isCustomerGroupEvent(
  event: CustomerEvent,
): event is CustomerGroupEvent {
  return [
    CustomerEventType.CUSTOMER_GROUP_ENTER,
    CustomerEventType.CUSTOMER_GROUP_EXIT,
  ].includes(event.eventType);
}

export interface CustomerInformationUpdatedEvent extends BaseCustomerEvent {
  eventType: CustomerEventType.CUSTOMER_INFORMATION_UPDATE;
  newPhoneNumber?: string;
  newFirstName?: string;
  newLastName?: string;
}

export type OutreachEvent =
  | OutreachSendEvent
  | OutreachDeliveryEvent
  | OutreachOpenEvent
  | OutreachClickEvent
  | OutreachBounceEvent
  | OutreachComplaintEvent;

export function isOutreachEvent(event: CustomerEvent): event is OutreachEvent {
  return [
    CustomerEventType.OUTREACH_SEND,
    CustomerEventType.OUTREACH_DELIVERY,
    CustomerEventType.OUTREACH_OPEN,
    CustomerEventType.OUTREACH_CLICK,
    CustomerEventType.OUTREACH_BOUNCE,
    CustomerEventType.OUTREACH_COMPLAINT,
  ].includes(event.eventType);
}

export type CustomerEvent =
  | OrderCreatedCustomerEvent
  | OrderUpdatedCustomerEvent
  | OrderStatusUpdatedCustomerEvent
  | ReturnCreatedCustomerEvent
  | ReturnStatusUpdatedCustomerEvent
  | ReturnCommentCreatedCustomerEvent
  | ReturnProcessedCustomerEvent
  | ClaimCreatedCustomerEvent
  | ClaimStatusUpdatedCustomerEvent
  | ClaimCommentCreatedCustomerEvent
  | ConversationCreatedCustomerEvent
  | ConversationStatusUpdatedCustomerEvent
  | ConversationMessageCreatedCustomerEvent
  | ExternalPlatformCustomerCreatedEvent
  | StorefrontLoginEvent
  | MarketingSubscribeEvent
  | MarketingConfirmEvent
  | MarketingUnsubscribeEvent
  | ProductViewedShoppingEvent
  | ProductAddedToCartShoppingEvent
  | OutreachSendEvent
  | OutreachDeliveryEvent
  | OutreachOpenEvent
  | OutreachClickEvent
  | OutreachBounceEvent
  | OutreachComplaintEvent
  | CheckoutStartedShoppingEvent
  | CheckoutCompletedShoppingEvent
  | ProductRemovedFromCartShoppingEvent
  | CheckoutContactInfoSubmittedShoppingEvent
  | StorePageViewedShoppingEvent
  | CollectionViewedShoppingEvent
  | CustomerGroupEnterEvent
  | CustomerGroupExitEvent
  | CustomerInformationUpdatedEvent;

export type Create<T> = T extends OrderStatusUpdatedCustomerEvent
  ? Omit<T, "_id" | "timestamp" | "newStatus"> & { newStatus: string }
  : Omit<T, "_id" | "timestamp"> & { _id?: string; timestamp?: Date };
