import { RedoTextInput } from "@redotech/redo-web/arbiter-components/input/redo-text-input";
import {
  RedoModal,
  RedoModalButtonProps,
} from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo, useState } from "react";

export const SaveTableViewModal = memo(function SaveTableViewModal({
  cancel,
  save,
  reservedViewNames,
}: {
  save(name: string): Promise<void>;
  cancel(): void;
  reservedViewNames: Set<string>;
}) {
  const [viewName, setViewName] = useState("");

  const sumitDisabledBecauseOfEmptyName =
    viewName.length === 0 ? "You must name this view" : false;
  const submitDisabledBecauseOfDuplicateName = reservedViewNames.has(
    viewName.toLowerCase(),
  )
    ? "A view with this name already exists"
    : false;

  const submitDisabled =
    sumitDisabledBecauseOfEmptyName || submitDisabledBecauseOfDuplicateName;

  const primaryButton: RedoModalButtonProps = {
    text: "Confirm",
    onClick: async () => {
      await save(viewName);
    },
    disabled: submitDisabled,
  };

  const secondaryButton: RedoModalButtonProps = {
    text: "Cancel",
    onClick: cancel,
  };

  return (
    <RedoModal
      buttonPlacement="full"
      onModalCloseRequested={cancel}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      title="Save as view"
    >
      <Flex dir="column" gap="xl">
        <Flex dir="column">
          <Text>Name</Text>
          <RedoTextInput setValue={setViewName} size="md" value={viewName} />
        </Flex>
        <div />
      </Flex>
    </RedoModal>
  );
});
