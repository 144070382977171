import { SECONDS_IN_A_DAY, SECONDS_IN_AN_HOUR } from "@redotech/util/time";
import { assertNever } from "@redotech/util/type";
import { SegmentConditionBlock } from "../marketing/segments/segment-types";
import { BaseStep, StepType, SupportedWaitForEventType } from "./common";
import { SchemaBooleanExpression } from "./expressions/index";
import {
  AddTagActionStep,
  PlaceHoldActionStep,
  PlaceHoldUntilActionStep,
  SetAssignedUserActionStep,
  SetDutiesPayerActionStep,
  SetPackingSlipTemplateActionStep,
  SetParcelActionStep,
  SetReturnAddressActionStep,
  SetShippingOptionsActionStep,
  SetShippingServiceActionStep,
  SetShippingServiceCheapestActionStep,
  SetShippingServiceFastestActionStep,
  SetTaxIdentifiersActionStep,
  SetWeightActionStep,
} from "./oms/actions";
import {
  OmsDeliveryMethodConditionStep,
  OmsDestinationCountryConditionStep,
  OmsDestinationProvinceConditionStep,
  OmsItemQuantityConditionStep,
  OmsOrderTagConditionStep,
  OmsPackageWeightOzConditionStep,
  OmsProductNameConditionStep,
  OmsProductTagConditionStep,
  OmsSalesChannelConditionStep,
  OmsSelectedShippingConditionStep,
  OmsSkuConditionStep,
  OmsStreetAddressConditionStep,
  OmsTotalValueConditionStep,
} from "./oms/conditions";
import { SchemaType } from "./schemas/schemas";
import { Category, TriggerKey } from "./triggers";

export { StepType };

/** @deprecated Use the new zod flow step types instead */
export interface TriggerStep extends BaseStep {
  type: StepType.TRIGGER;
  schemaType: SchemaType;
  category: Category;
  key: TriggerKey;
  nextId: string;
  next: number;
}

export function waitStepSeconds(step: Partial<WaitStep>): number | undefined {
  if (step.numSeconds !== undefined) {
    return step.numSeconds;
  } else if (step.numDays !== undefined) {
    return step.numDays * SECONDS_IN_A_DAY;
  }
  return undefined;
}

export function hoursOrDays(seconds: number): WaitTimeUnit {
  if (seconds % SECONDS_IN_A_DAY === 0) {
    return WaitTimeUnit.DAYS;
  }
  return WaitTimeUnit.HOURS;
}

export function getSeconds({
  timeValue,
  timeUnit,
}: {
  timeValue: number;
  timeUnit: WaitTimeUnit;
}): number {
  switch (timeUnit) {
    case WaitTimeUnit.DAYS:
      return timeValue * SECONDS_IN_A_DAY;
    case WaitTimeUnit.HOURS:
      return timeValue * SECONDS_IN_AN_HOUR;
    default:
      assertNever(timeUnit);
  }
}

export function getTimeValue({
  seconds,
  timeUnit,
}: {
  seconds: number;
  timeUnit: WaitTimeUnit;
}): number {
  switch (timeUnit) {
    case WaitTimeUnit.DAYS:
      return Math.floor(seconds / SECONDS_IN_A_DAY);
    case WaitTimeUnit.HOURS:
      return Math.floor(seconds / SECONDS_IN_AN_HOUR);
    default:
      assertNever(timeUnit);
  }
}

export enum WaitTimeUnit {
  DAYS = "Days",
  HOURS = "Hours",
}
/** @deprecated Use the new zod flow step types instead */
export interface WaitStep extends BaseStep {
  type: StepType.WAIT;
  numDays: number;
  numSeconds?: number;
  timeUnit: WaitTimeUnit;
  nextId: string;
  next: number;
}
/** @deprecated Use the new zod flow step types instead */
export interface WaitForEventStep extends BaseStep {
  type: StepType.WAIT_FOR_EVENT;
  eventType: SupportedWaitForEventType;
  nextId: string;
  next: number;
}
/** @deprecated Use the new zod flow step types instead */
export interface SendEmailStep extends BaseStep {
  type: StepType.SEND_EMAIL;
  templateId: string;
  recipientNameFieldName: string;
  emailAddressFieldName: string;
  nextId?: string;
  next?: number;
}
/** @deprecated Use the new zod flow step types instead */
export interface SendSmsStep extends BaseStep {
  type: StepType.SEND_SMS;
  templateId: string;
  recipientNameFieldName: string;
  phoneNumberFieldName: string;
  nextId?: string;
  next?: number;
}

export enum ConditionDataSource {
  TriggerData = "trigger-data",
  ExistingSegment = "existing-segment",
  InlineSegment = "inline-segment",
  OfferResponse = "offer-response",
}

export const conditionDataSourceToFriendly: Record<
  ConditionDataSource,
  string
> = {
  [ConditionDataSource.TriggerData]: "Trigger-specific condition",
  [ConditionDataSource.ExistingSegment]: "Existing segment membership",
  [ConditionDataSource.InlineSegment]: "Custom segment conditions",
  [ConditionDataSource.OfferResponse]: "Action taken on offer",
};

/** @deprecated Use the new zod flow step types instead */
export interface ConditionTriggerDataExpression {
  dataSource: ConditionDataSource.TriggerData;
  schemaBooleanExpression: SchemaBooleanExpression;
}

/** @deprecated Use the new zod flow step types instead */
export interface ConditionExistingSegmentExpression {
  dataSource: ConditionDataSource.ExistingSegment;
  segmentIds: string[];
}

/** @deprecated Use the new zod flow step types instead */
export interface ConditionInlineSegmentExpression {
  dataSource: ConditionDataSource.InlineSegment;
  inlineSegment: SegmentConditionBlock;
}

/** @deprecated Use the new zod flow step types instead */
export interface ConditionOfferResponseExpression {
  dataSource: ConditionDataSource.OfferResponse;
}

/** @deprecated Use the new zod flow step types instead */
export type ConditionExpression =
  | ConditionTriggerDataExpression
  | ConditionExistingSegmentExpression
  | ConditionInlineSegmentExpression
  | ConditionOfferResponseExpression;

export enum UpsellConditionSource {
  PRODUCT = "product",
  COLLECTION = "collection",
}

/** @deprecated Use the new zod flow step types instead */
//TODO: Write a migration script to make these required, and enforce them in the builder
//This should be a discriminated union
export interface UpsellConditionExpression {
  source: UpsellConditionSource;
  products?: { id: string; name: string }[] | undefined;
  collection?: string | undefined;
}

/** @deprecated Use the new zod flow step types instead */
export interface ConditionStep extends BaseStep {
  type: StepType.CONDITION;
  expression: ConditionExpression;
  nextTrueId: string;
  nextTrue: number;
  nextFalseId: string;
  nextFalse: number;
}

/** @deprecated Use the new zod flow step types instead */
export interface UpsellConditionStep extends BaseStep {
  //TODO: Write a migration script to make these required, and enforce them in the builder
  type: StepType.UPSELL_CONDITION;
  isDefault?: boolean;
  expression?: UpsellConditionExpression;
  nextTrueId?: string;
  nextFalseId?: string;
  name?: string;
}

/** @deprecated Use the new zod flow step types instead */
export interface DoNothingStep extends BaseStep {
  type: StepType.DO_NOTHING;
}

/** @deprecated Use the new zod flow step types instead */
export interface UpsellStep extends BaseStep {
  type: StepType.UPSELL;
  name: string;
  upsellId: string;
  nextId: string;
}

/** @deprecated Use the new zod flow step types instead */
export interface DownsellStep extends BaseStep {
  type: StepType.DOWNSELL;
  name: string;
  downsellId: string;
  nextId: string;
}

/** @deprecated Use the new zod flow step types instead */
export type OmsConditionStep =
  | OmsOrderTagConditionStep
  | OmsSelectedShippingConditionStep
  | OmsSkuConditionStep
  | OmsProductNameConditionStep
  | OmsTotalValueConditionStep
  | OmsPackageWeightOzConditionStep
  | OmsItemQuantityConditionStep
  | OmsDestinationProvinceConditionStep
  | OmsDestinationCountryConditionStep
  | OmsDeliveryMethodConditionStep
  | OmsStreetAddressConditionStep
  | OmsSalesChannelConditionStep
  | OmsProductTagConditionStep;

/** @deprecated Use the new zod flow step types instead */
export type OmsActionStep =
  | SetShippingServiceActionStep
  | SetParcelActionStep
  | SetShippingServiceCheapestActionStep
  | SetShippingServiceFastestActionStep
  | SetPackingSlipTemplateActionStep
  | AddTagActionStep
  | SetShippingOptionsActionStep
  | PlaceHoldActionStep
  | PlaceHoldUntilActionStep
  | SetWeightActionStep
  | SetAssignedUserActionStep
  | SetDutiesPayerActionStep
  | SetTaxIdentifiersActionStep
  | SetReturnAddressActionStep;

/** @deprecated Use the new zod flow step types instead */
export type OmsFlowStep =
  | OmsActionStep
  | OmsConditionStep
  | TriggerStep
  | DoNothingStep;

/** @deprecated Use the new zod flow step types instead */
export type MarketingFlowStep =
  | TriggerStep
  | WaitStep
  | WaitForEventStep
  | SendEmailStep
  | SendSmsStep
  | ConditionStep
  | DoNothingStep;

/** @deprecated Use the new zod flow step types instead */
export type UpsellFlowStep =
  | UpsellConditionStep
  | UpsellStep
  | DownsellStep
  | DoNothingStep
  | TriggerStep;

/** @deprecated Use the new zod flow step types instead */
export type FlowStep = OmsFlowStep | MarketingFlowStep | UpsellFlowStep;
