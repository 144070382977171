import { Tuple } from "@redotech/util/type";
import { z } from "zod";
import {
  AdvancedFilterType,
  createAdvancedFilterDataSchema,
  GenericFilterBuilder,
} from "./generic-advanced-filter-data";

export enum NumberFilterOperator {
  EQUAL = "=",
  LESS_THAN = "<",
  GREATER_THAN = ">",
  BETWEEN = "between",
}
const NumberFilterOperatorSchema = z.nativeEnum(NumberFilterOperator);

const TwoNumberTupleSchema = z.tuple([z.number(), z.number()]);
const OneNumberTupleSchema = z.tuple([z.number()]);
export type TwoNumberTuple = Tuple<number, 2>;
export type OneNumberTuple = Tuple<number, 1>;

const NumberValueSchema = z.union([OneNumberTupleSchema, TwoNumberTupleSchema]);
export type NumberValue = z.infer<typeof NumberValueSchema>;

export const NumberFilterDataSchema = createAdvancedFilterDataSchema(
  AdvancedFilterType.NUMBER,
  NumberValueSchema,
  NumberFilterOperatorSchema,
).extend({ modifier: z.number().nullish() });
export type NumberFilterData = z.infer<typeof NumberFilterDataSchema>;

export const NumberFilterBuilder: GenericFilterBuilder<
  NumberValue,
  NumberFilterOperator,
  NumberFilterData
> = {
  type: AdvancedFilterType.NUMBER,
  valueSchema: NumberValueSchema,
  operatorSchema: NumberFilterOperatorSchema,
  schema: NumberFilterDataSchema,
  buildAtlasSearchQuery({ filter, atlasPath, searchCompound }) {
    const { operator, value } = filter;
    if (!value) {
      return searchCompound;
    }

    const numbers = value.sort((a, b) => a - b);

    if (filter.modifier) {
      numbers[0] = filter.modifier * numbers[0];
      if (numbers.length === 2) {
        numbers[1] = filter.modifier * numbers[1];
      }
    }

    if (operator === NumberFilterOperator.BETWEEN) {
      if (numbers.length === 2) {
        searchCompound.filter.push({
          range: { path: atlasPath, gte: numbers[0], lte: numbers[1] },
        });
      }
    } else if (operator === NumberFilterOperator.LESS_THAN) {
      searchCompound.filter.push({
        range: { path: atlasPath, lt: numbers[0] },
      });
    } else if (operator === NumberFilterOperator.GREATER_THAN) {
      searchCompound.filter.push({
        range: { path: atlasPath, gt: numbers[0] },
      });
    } else if (operator === NumberFilterOperator.EQUAL) {
      searchCompound.filter.push({
        equals: { path: atlasPath, value: numbers[0] },
      });
    }
    return searchCompound;
  },
  readFromString(name, operatorValueEncoded, defaultFilter) {
    try {
      const decoded = decodeURIComponent(operatorValueEncoded);
      const operatorValue = decoded.split("::");

      const operator = operatorValue?.[0];
      const value = operatorValue?.[1];

      if (!operator) {
        return defaultFilter;
      }

      const parsedOperator = NumberFilterOperatorSchema.safeParse(operator);
      if (!parsedOperator.success) {
        console.warn("Invalid operator type");
        return undefined;
      }

      if (!value) {
        return {
          type: AdvancedFilterType.NUMBER,
          name,
          value: null,
          operator: parsedOperator.data,
        };
      }

      const parsedValue = NumberValueSchema.safeParse(
        value.trim().split(",").map(Number),
      );

      if (!parsedValue.success) {
        console.warn("Invalid value type for NumberFilter");
        return undefined;
      }

      return {
        type: AdvancedFilterType.NUMBER,
        name,
        value: parsedValue.data,
        operator: parsedOperator.data,
      };
    } catch (error) {
      console.error("Error parsing NumberFilter", error);
      return undefined;
    }
  },
  writeToString(filter) {
    try {
      if (!filter) {
        return "";
      }
      const { operator, value } = filter;
      const parsedValue = NumberValueSchema.safeParse(value);

      if (parsedValue.success) {
        return `${operator}::${parsedValue.data.join(",")}`;
      } else {
        return `${operator}::`;
      }
    } catch (error) {
      console.error("Error writing NumberFilter", error);
      return "";
    }
  },
  isPartial(filter) {
    return (
      !filter.operator ||
      !filter.value ||
      (filter.operator === NumberFilterOperator.BETWEEN &&
        filter.value.length !== 2)
    );
  },
};
