import {
  RedoModal,
  RedoModalProps,
} from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import { createContext, ReactNode, useContext, useState } from "react";

export type ConfirmationModalProps = Partial<RedoModalProps> &
  Pick<RedoModalProps, "title">;

interface ConfirmationContextOptions {
  onConfirm: () => void;
  onCancel: () => void;
}

export interface ConfirmationContextType {
  requireConfirmation: (props: ConfirmationModalProps) => Promise<boolean>;
}

export const ConfirmationContext = createContext<
  ConfirmationContextType | undefined
>(undefined);

export const useConfirmation = () => {
  const context = useContext(ConfirmationContext);
  if (!context) {
    throw new Error(
      "useConfirmation must be used within a ConfirmationProvider",
    );
  }
  return context;
};

export function ConfirmationProvider({ children }: { children: ReactNode }) {
  const [confirmation, setConfirmation] = useState<
    (ConfirmationModalProps & ConfirmationContextOptions) | null
  >(null);

  const requireConfirmation = (props: ConfirmationModalProps) => {
    return new Promise<boolean>((resolve) => {
      setConfirmation({
        ...props,
        onConfirm: () => {
          setConfirmation(null);
          resolve(true);
        },
        onCancel: () => {
          setConfirmation(null);
          resolve(false);
        },
      });
    });
  };

  return (
    <ConfirmationContext.Provider value={{ requireConfirmation }}>
      {children}
      {confirmation && (
        <RedoModal
          {...confirmation}
          isOpen={!!confirmation}
          onModalCloseRequested={confirmation.onCancel}
          primaryButton={{
            text: confirmation.primaryButton?.text ?? "Confirm",
            onClick: confirmation.onConfirm,
            ...confirmation.primaryButton,
          }}
          secondaryButton={{
            text: confirmation.secondaryButton?.text ?? "Cancel",
            onClick: confirmation.onCancel,
            ...confirmation.secondaryButton,
          }}
        />
      )}
    </ConfirmationContext.Provider>
  );
}
