import { ConversationPlatform } from "@redotech/redo-model/conversation";
import { JSXElementConstructor, memo } from "react";
import {
  RedoBadge,
  RedoBadgeColor,
  RedoBadgeSize,
} from "../arbiter-components/badge/redo-badge";
import Mail01Svg from "../arbiter-icon/mail-01.svg";
import PhoneIcon from "../arbiter-icon/phone-call.svg";
import PostscriptLogoIcon from "../arbiter-icon/postscript-favicon.svg";
import AttentiveLogoIcon from "../icon-old/attentive-logo.svg";
import ChatBubbleIcon from "../icon-old/chat-bubble.svg";
import FacebookIcon from "../icon-old/facebook-color.svg";
import InstagramIcon from "../icon-old/instagram-color.svg";
import LogoIcon from "../icon-old/logo-icon.svg";

const platformMappings: Record<
  ConversationPlatform,
  { text: string; icon: JSXElementConstructor<unknown>; color: RedoBadgeColor }
> = {
  redoChat: { text: "Redo Chat", icon: LogoIcon, color: "brand" },
  instagram: { text: "DM", icon: InstagramIcon, color: "pink" },
  instagramComments: { text: "Comments", icon: InstagramIcon, color: "pink" },
  facebook: { text: "Messenger", icon: FacebookIcon, color: "blueLight" },
  facebookComments: {
    text: "Comments",
    icon: FacebookIcon,
    color: "blueLight",
  },
  email: { text: "Email", icon: Mail01Svg, color: "gray" },
  sms: { text: "Text message", icon: ChatBubbleIcon, color: "success" },
  attentive: { text: "Attentive", icon: AttentiveLogoIcon, color: "warning" },
  postscript: { text: "Postscript", icon: PostscriptLogoIcon, color: "purple" },
  voice: { text: "Voice", icon: PhoneIcon, color: "success" },
};

export const RedoSupportChannelBadge = memo(function RedoSupportChannelBadge({
  platform,
  size = "sm",
}: {
  platform: ConversationPlatform;
  size?: RedoBadgeSize;
}) {
  const { text, icon, color } = platformMappings[platform];

  return (
    <RedoBadge
      color={color}
      segmentLeading={{ type: "icon", Icon: icon }}
      size={size}
      text={text}
      type="primary"
    />
  );
});
