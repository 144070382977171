import { MetaAdsConnectionSchema } from "@redotech/redo-model/integration/meta-ads/meta-ads-definition";
import { z } from "zod";

export const getMetaAdsIntegration = {
  input: z.null(),
  output: z.object({
    exists: z.boolean(),
    accountId: z.string().optional(),
    connections: z.array(MetaAdsConnectionSchema),
  }),
};
