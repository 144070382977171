import { useRequiredContext } from "@redotech/react-util/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { RedoSecondaryNavItem } from "@redotech/redo-web/arbiter-components/navigation/secondary/redo-secondary-nav-item";
import BarChart10FilledIcon from "@redotech/redo-web/icon-old/bar-chart-10-filled.svg";
import BarChart10Icon from "@redotech/redo-web/icon-old/bar-chart-10.svg";
import { memo } from "react";

export const AnalyticsItems = memo(function AnalyticsItems({
  conversionRoot,
}: {
  conversionRoot: string;
}) {
  const team = useRequiredContext(TeamContext);

  const checkoutOptimizationEnabled =
    team?.settings.checkoutOptimization?.enabled;

  if (!checkoutOptimizationEnabled) {
    return null;
  }
  return (
    <RedoSecondaryNavItem
      action={{ type: "link", href: `${conversionRoot}/analytics` }}
      FilledIcon={BarChart10FilledIcon}
      friendlyName="Analytics"
      id="analytics"
      UnfilledIcon={BarChart10Icon}
    />
  );
});
