import { useRequiredContext } from "@redotech/react-util/context";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { MerchantAppTopic } from "@redotech/redo-merchant-app-common/events/merchant-app-event-server";
import { MerchantAppEventServerContext } from "@redotech/redo-merchant-app-common/events/merchant-app-event-server-provider";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { UserContext } from "@redotech/redo-merchant-app-common/user";
import { MessageVisibility } from "@redotech/redo-model/conversation";
import { getCustomerDisplayName } from "@redotech/redo-model/customer";
import {
  NotificationType,
  Notification as RedoNotification,
} from "@redotech/redo-model/notification";
import logoBgUrl from "@redotech/redo-web/icon-old/logo-bg.png?url";
import notificationUrl from "@redotech/redo-web/notification-sound-1.mp3?url";
import * as reverse from "lodash/reverse";
import { useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { getConversation } from "../../../../client/conversations";

export function useSupportNotifications() {
  const team = useContext(TeamContext);
  const supportEnabled = team?.settings.support?.enabled;
  const user = useContext(UserContext);
  const client = useRequiredContext(RedoMerchantClientContext);
  const navigate = useNavigate();

  const eventServer = useRequiredContext(MerchantAppEventServerContext);

  const notificationSound = useMemo(() => new Audio(notificationUrl), []);
  useEffect(() => {
    if (supportEnabled && user && client) {
      const unlistenCallback = eventServer.subscribe({
        topic: MerchantAppTopic.USER_NOTIFICATIONS,
        callback: async (notification: RedoNotification) => {
          if ("Notification" in window) {
            if (
              notification.type === NotificationType.NEW_MESSAGE ||
              notification.type === NotificationType.TICKET_ASSIGNED ||
              notification.type === NotificationType.MENTIONED
            ) {
              if (notification.conversationId) {
                const conversation = await getConversation(client, {
                  conversationId: notification.conversationId,
                });
                const customerName = await getCustomerDisplayName(
                  conversation?.customer,
                );

                let notificationTitle = "";
                if (notification.type === NotificationType.NEW_MESSAGE) {
                  notificationTitle = `New message from ${customerName}`;
                } else if (
                  notification.type === NotificationType.TICKET_ASSIGNED
                ) {
                  notificationTitle = "You've been assigned a ticket";
                } else if (notification.type === NotificationType.MENTIONED) {
                  const mentionerName = (
                    conversation.messages[conversation.messages.length - 1]
                      .user as any
                  ).name;
                  notificationTitle = `${mentionerName} mentioned you in a conversation`;
                }

                let notificationBody = "";
                if (
                  notification.type === NotificationType.NEW_MESSAGE ||
                  notification.type === NotificationType.TICKET_ASSIGNED
                ) {
                  for (const message of reverse(conversation.messages)) {
                    if (
                      message.type === "customer" ||
                      message.visibility === MessageVisibility.INTERNAL
                    ) {
                      notificationBody = message.content;
                      break;
                    }
                  }
                } else if (notification.type === NotificationType.MENTIONED) {
                  notificationBody =
                    conversation.messages[conversation.messages.length - 1]
                      .content;
                }

                const desktopNotification = new Notification(
                  notificationTitle,
                  { icon: logoBgUrl, body: notificationBody },
                );
                desktopNotification.onclick = () => {
                  const url = `/stores/${team._id}/support/table/all?activeConversationId=${conversation._id}`;
                  navigate(url);
                };
                if (
                  user.notifications?.playSoundOnDesktopNotification ??
                  true
                ) {
                  desktopNotification.onshow = () => {
                    void notificationSound.play().catch((e) => {
                      /** This will throw if the user hasn't interacted with the page yet */
                      console.error("error playing sound: ", e);
                    });
                  };
                }
              }
            }
          }
        },
      });
      return () => unlistenCallback();
    }
    return undefined;
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportEnabled, user, client]);
}
