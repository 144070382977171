import { z } from "zod";

export const createMetaAdsAudience = {
  input: z.object({ accountId: z.string(), name: z.string() }),
  output: z.object({
    audience: z
      .object({
        id: z.string(),
        name: z.string(),
        description: z.string(),
        createdTime: z.number(),
        updatedTime: z.number(),
      })
      .nullable(),
  }),
};
