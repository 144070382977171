// extracted by mini-css-extract-plugin
export var disabled = "_b6-6";
export var error = "_b6-7";
export var icon = "_b6-0";
export var inherit = "_b6-a";
export var primary = "_b6-1";
export var quaternary = "_b6-4";
export var quinary = "_b6-5";
export var secondary = "_b6-2";
export var success = "_b6-9";
export var tertiary = "_b6-3";
export var warning = "_b6-8";