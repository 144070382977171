import { folderSchemas } from "@redotech/redo-model/views/folders";
import { viewWithIdSchemas } from "@redotech/redo-model/views/views";
import { z, null as zNull } from "zod";

export const getViewsAndFolders = {
  input: zNull(),
  output: z.object({
    folders: z.array(folderSchemas["fulfillment-groups"]),
    views: z.array(viewWithIdSchemas["fulfillment-groups"]),
  }),
};
