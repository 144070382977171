import {
  useLazyContext,
  useRequiredContext,
} from "@redotech/react-util/context";
import { RedoMerchantRpcClientContext } from "@redotech/redo-merchant-app-common/rpc-client";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { getPrimaryCustomerEmail } from "@redotech/redo-model/customer";
import { toast } from "@redotech/redo-web/alert";
import { RedoTextInput } from "@redotech/redo-web/arbiter-components/input/redo-text-input";
import { RedoModal } from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import { Flex } from "@redotech/redo-web/flex";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { SelectDropdown } from "@redotech/redo-web/select-dropdown";
import { isValidEmail } from "@redotech/util/email";
import { memo, useEffect, useState } from "react";
import { EmailIntegrationsContext } from "../services/support/email-integrations";

export const ConvertChatToEmailModal = memo(function ConvertChatToEmailModal({
  open,
  conversation,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  conversation: ExpandedConversation;
}) {
  const team = useRequiredContext(TeamContext);
  const [pending, setPending] = useState(false);
  const [merchantEmail, setMerchantEmail] = useState(
    team.settings?.support?.defaultEmail ?? "",
  );
  const [emailIntegrationsLoad] = useLazyContext(EmailIntegrationsContext);
  const rpcClient = useRequiredContext(RedoMerchantRpcClientContext);
  const [availableEmails, setAvailableEmails] = useState<string[]>([]);
  const [customerEmail, setCustomerEmail] = useState<string>(
    getPrimaryCustomerEmail(conversation?.customer) ?? "",
  );
  const [customerEmailValid, setCustomerEmailValid] = useState(false);

  useEffect(() => {
    setCustomerEmail(getPrimaryCustomerEmail(conversation?.customer) ?? "");
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation?.customer?.customer]);

  useEffect(() => {
    if (isValidEmail(customerEmail)) {
      setCustomerEmailValid(true);
    } else {
      setCustomerEmailValid(false);
    }
  }, [customerEmail]);

  const handleCancel = () => {
    setOpen(false);
  };

  const findIntegrationTypeFromEmail = (
    email: string,
  ): "custom" | "gmail" | "outlook" => {
    if (!emailIntegrationsLoad.value) {
      toast("Integrations not yet loaded. Please refresh and try again.", {
        variant: "error",
      });
      throw new Error("Integrations data is not loaded");
    }

    const { gmailIntegrations, outlookIntegrations } =
      emailIntegrationsLoad.value;

    if (gmailIntegrations.find((integration) => integration.email === email)) {
      return "gmail";
    } else if (
      outlookIntegrations.find((integration) => integration.email === email)
    ) {
      return "outlook";
    } else {
      return "custom";
    }
  };

  useEffect(() => {
    if (!emailIntegrationsLoad.value) {
      return;
    }
    const emails = [
      ...(emailIntegrationsLoad.value.customIntegrations || []),
      ...(emailIntegrationsLoad.value.gmailIntegrations || []),
      ...(emailIntegrationsLoad.value.outlookIntegrations || []),
    ].map((integration) => integration.email);
    setAvailableEmails(emails);

    if (!merchantEmail && emails.length > 0) {
      const defaultEmail = team.settings?.support?.defaultEmail;
      if (defaultEmail && emails.includes(defaultEmail)) {
        setMerchantEmail(defaultEmail);
      } else {
        setMerchantEmail(emails[0]);
      }
    }
  }, [
    emailIntegrationsLoad.value,
    merchantEmail,
    team.settings?.support?.defaultEmail,
  ]);

  const convertChatToEmail = async () => {
    try {
      setPending(true);
      const integrationType = findIntegrationTypeFromEmail(merchantEmail);
      if (!integrationType) {
        throw new Error(`Email Integration not found for ${merchantEmail}`);
      }
      await rpcClient.manuallyConvertChatToEmail({
        conversationId: conversation._id,
        customerEmail: customerEmail,
        emailToSendAs: {
          email: merchantEmail,
          name: team.name || "",
          integrationKind: integrationType,
        },
      });
      setOpen(false);
    } catch (err) {
      console.error("Failed to convert chat to email:", err);
      toast("Failed to convert chat to email", { variant: "error" });
    } finally {
      setPending(false);
    }
  };

  return (
    <RedoModal
      buttonPlacement="full"
      isOpen={open}
      onModalCloseRequested={handleCancel}
      primaryButton={{
        text: "Convert to email",
        onClick: () => {
          void convertChatToEmail();
        },
        disabled:
          !merchantEmail || pending || !customerEmail || !customerEmailValid,
      }}
      secondaryButton={{
        text: "Cancel",
        onClick: () => {
          handleCancel();
        },
      }}
      title="Convert chat to email"
    >
      <Flex dir="column">
        <SelectDropdown
          options={availableEmails}
          placeholder="Email that the converted message will be from"
          value={merchantEmail}
          valueChange={(e) => {
            if (e) {
              return setMerchantEmail(e);
            }
          }}
        >
          {(option) => option}
        </SelectDropdown>
        <LabeledInput label="Enter customer email">
          <RedoTextInput
            setValue={setCustomerEmail}
            size="md"
            value={customerEmail}
          />
        </LabeledInput>
      </Flex>
    </RedoModal>
  );
});
