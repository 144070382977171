import { Navigate, RouteObject } from "react-router-dom";

export const reviewsRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Reviews", breadcrumbNavigate: false },
    children: [
      { index: true, element: <Navigate to="dashboard" /> },
      {
        path: "dashboard",
        handle: { breadcrumb: "Dashboard" },
        async lazy() {
          const { Dashboard } = await import(
            "@redotech/redo-merchant-app-review/dashboard/dashboard"
          );
          return { Component: Dashboard };
        },
      },
      {
        path: "all-reviews",
        handle: { breadcrumb: "All reviews" },
        async lazy() {
          const { AllReviews } = await import(
            "@redotech/redo-merchant-app-review/all-reviews/all-reviews"
          );
          return { Component: AllReviews };
        },
      },
      {
        path: "products",
        handle: { breadcrumb: "Products" },
        async lazy() {
          const { Products } = await import(
            "@redotech/redo-merchant-app-review/products/products"
          );
          return { Component: Products };
        },
      },
    ],
  },
];
