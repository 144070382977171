import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { TOKEN_KEY } from "@redotech/redo-merchant-app-common/auth";
import { getUserAvatarUrl } from "@redotech/redo-merchant-app-common/get-avatar-url";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { UserContext } from "@redotech/redo-merchant-app-common/user";
import { UserCallAvailability } from "@redotech/redo-model/support/voice/voice-types";
import { StatusDotColor } from "@redotech/redo-web/arbiter-components/list/redo-list-item-lead";
import { NavigationUserProfile } from "@redotech/redo-web/arbiter-components/navigation/common/redo-nav-user-profile";
import ArrowRightIcon from "@redotech/redo-web/arbiter-icon/arrow-right_filled.svg";
import LogOut04 from "@redotech/redo-web/arbiter-icon/log-out-04.svg";
import { filterTruthy } from "@redotech/util/array";
import { memo, ReactNode, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { RedoListItem } from "../../../../web/src/arbiter-components/list/redo-list";
import { RedoListDropdown } from "../../../../web/src/arbiter-components/list/redo-list-dropdown";
import { UserProfileContext } from "../../../../web/src/arbiter-components/navigation/common/redo-nav-user-profile";
import TeamMemberIcon from "../../../../web/src/arbiter-icon/user-01.svg";
import {
  StatusText,
  UserCallAvailabilityToStatusColor,
} from "../../../../web/src/voice/phone-status";
import { UserCallAvailabilityContext } from "../support/user-call-availability";

export const UserProfileService = memo(function UserProfileService({
  children,
}: {
  children: ReactNode;
}) {
  const user = useRequiredContext(UserContext);

  const team = useRequiredContext(TeamContext);

  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);

  const { availabilityStatus, setAvailabilityStatus } = useRequiredContext(
    UserCallAvailabilityContext,
  );

  const [availabilityStatusPickerOpen, setAvailabilityStatusPickerOpen] =
    useState(false);

  const [dropdownAnchor, setDropdownAnchor] = useState<HTMLElement | null>(
    null,
  );

  const voiceEnabled = !!team?.settings.support?.voice?.enabled;

  const settingsUrl = `/stores/${team._id}/settings/users/${user._id}`;

  const intercom = useIntercom();
  const navigate = useNavigate();

  const handleLogout = useHandler(() => {
    if (team) {
      const team_token_key = `${TOKEN_KEY}.${team._id}`;
      localStorage.removeItem(team_token_key);
    } else {
      localStorage.clear();
    }
    intercom.shutdown();
    location.href = `https://${team.storeUrl}/admin`;
  });

  const profileImageUrl = useMemo(() => {
    return getUserAvatarUrl({
      email: user.email || "",
      size: 48,
      userId: user._id,
    });
  }, [user]);

  const dropdownItems: RedoListItem<() => void>[] = useMemo(() => {
    const availabilityItem: RedoListItem<() => void> = {
      id: "availability",
      type: "text",
      text: StatusText[availabilityStatus],
      TrailingIcon: ArrowRightIcon,
      leadingItem: {
        type: "status",
        color: UserCallAvailabilityToStatusColor[availabilityStatus],
      },
      value: () => {
        setProfileDropdownOpen(false);
        setAvailabilityStatusPickerOpen(true);
      },
    };

    const profileItem: RedoListItem<() => void> = {
      id: "profile",
      type: "text",
      text: "Profile",
      leadingItem: { type: "icon", Icon: TeamMemberIcon },
      value: () => {
        navigate(settingsUrl);
      },
    };

    const logoutItem: RedoListItem<() => void> = {
      id: "logout",
      type: "text",
      text: "Logout",
      leadingItem: { type: "icon", Icon: LogOut04 },
      value: handleLogout,
    };

    return filterTruthy([
      voiceEnabled && availabilityItem,
      profileItem,
      logoutItem,
    ]);
  }, [handleLogout, navigate, settingsUrl, voiceEnabled, availabilityStatus]);

  const statusItems: RedoListItem<UserCallAvailability>[] = useMemo(() => {
    const options = Object.values(UserCallAvailability).filter(
      (avail) => avail !== UserCallAvailability.IN_CALL,
    );

    return options.map<RedoListItem<UserCallAvailability>>((availability) => {
      return {
        id: availability,
        type: "text",
        leadingItem: {
          type: "status",
          color: UserCallAvailabilityToStatusColor[availability],
        },
        text: StatusText[availability],
        value: availability,
      };
    });
  }, []);

  const profileData: NavigationUserProfile = {
    name: user.name,
    teamName: team.name,
    setDropdownAnchor,
    setProfileDropdownOpen,
    profileImageUrl: profileImageUrl,
    status: callAvailabilityStatusToColor[availabilityStatus],
    profileDropdownOpen,
  };

  return (
    <UserProfileContext.Provider value={profileData}>
      <RedoListDropdown<() => void>
        darkMode
        dropdownAnchor={dropdownAnchor}
        dropdownOpen={profileDropdownOpen}
        items={dropdownItems}
        itemSelected={({ item }) => {
          item.value();
        }}
        setDropdownOpen={setProfileDropdownOpen}
        size="sm"
      />
      <RedoListDropdown
        darkMode
        dropdownAnchor={dropdownAnchor}
        dropdownOpen={availabilityStatusPickerOpen}
        items={statusItems}
        itemSelected={({ item }) => {
          setAvailabilityStatus(item.value);
        }}
        setDropdownOpen={(open) => {
          setAvailabilityStatusPickerOpen(open);
          if (!open) {
            setProfileDropdownOpen(true);
          }
        }}
        size="sm"
      />
      {children}
    </UserProfileContext.Provider>
  );
});

const callAvailabilityStatusToColor: Record<
  UserCallAvailability,
  StatusDotColor
> = {
  [UserCallAvailability.AVAILABLE]: "green",
  [UserCallAvailability.UNAVAILABLE]: "red",
  [UserCallAvailability.IN_CALL]: "yellow",
};
