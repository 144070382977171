import { useRequiredContext } from "@redotech/react-util/context";
import { useLoad } from "@redotech/react-util/load";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { RedoReviewsRpcClientContext } from "@redotech/redo-merchant-app-review/rpc-client";
import { ReviewStatus } from "@redotech/redo-model/review/review-field-schema";
import { createContext, memo, ReactNode } from "react";

export const ReviewsToReviewContext = createContext<number>(0);

export const ReviewsToReviewService = memo(function ReviewsToReviewService({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const team = useRequiredContext(TeamContext);
  const redoReviewsRpcClient = useRequiredContext(RedoReviewsRpcClientContext);

  const countOfReviewsNeedingReview = useLoad(async () => {
    const reviews = await redoReviewsRpcClient.getReviews({
      teamId: team?._id,
      statuses: [ReviewStatus.MANUAL_REVIEW],
    });
    return reviews?.length ?? 0;
  }, [team?._id, redoReviewsRpcClient]);

  return (
    <ReviewsToReviewContext.Provider
      value={countOfReviewsNeedingReview.value || 0}
    >
      {children}
    </ReviewsToReviewContext.Provider>
  );
});
