// extracted by mini-css-extract-plugin
export var acitonButtons = "_7v-0";
export var block = "_7v-1";
export var blockContent = "_7v-7";
export var blockDescription = "_7v-8";
export var blockIcon = "_7v-9";
export var blockTitle = "_7v-6";
export var border = "_7v-a";
export var center = "_7v-k";
export var edge = "_7v-b";
export var edgeLabel = "_7v-c";
export var edgePath = "_7v-d";
export var error = "_7v-e";
export var flowchart = "_7v-f";
export var flowchartContent = "_7v-g";
export var highlighted = "_7v-3";
export var hover = "_7v-4";
export var icon = "_7v-h";
export var left = "_7v-l";
export var navButton = "_7v-i";
export var navButtons = "_7v-j";
export var right = "_7v-m";
export var selected = "_7v-2";
export var small = "_7v-5";