import { useRequiredContext } from "@redotech/react-util/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { RedoSecondaryNavItem } from "@redotech/redo-web/arbiter-components/navigation/secondary/redo-secondary-nav-item";
import StarsIcon from "@redotech/redo-web/arbiter-icon/stars-01.svg";
import StarsFilledIcon from "@redotech/redo-web/arbiter-icon/stars-01_filled.svg";
import { memo } from "react";
import { useMatch } from "react-router-dom";

export const ConciergeItems = memo(function ConciergeItems({
  supportRoot,
}: {
  supportRoot: string;
}) {
  const team = useRequiredContext(TeamContext);
  const aiEnabled = team?.settings.support?.ai?.enabled;

  const urlPrefix = `${supportRoot}/concierge`;

  const aboutLink = `${urlPrefix}/preview`;

  const link = aiEnabled ? `${urlPrefix}/dashboard` : aboutLink;

  const anyChildSelected = useMatch(`${urlPrefix}/*`);

  const children = aiEnabled
    ? [
        <RedoSecondaryNavItem
          action={{ type: "link", href: `${urlPrefix}/dashboard` }}
          friendlyName="Dashboard"
          id="concierge-dashboard"
          key="concierge-dashboard"
        />,
        <RedoSecondaryNavItem
          action={{ type: "link", href: `${urlPrefix}/conversations` }}
          friendlyName="Conversations"
          id="concierge-conversations"
          key="concierge-conversations"
        />,
      ]
    : undefined;

  return (
    <RedoSecondaryNavItem
      action={{ type: "link", href: link }}
      anyChildSelected={!!anyChildSelected}
      FilledIcon={StarsFilledIcon}
      friendlyName="Concierge"
      id="concierge"
      UnfilledIcon={StarsIcon}
    >
      {children}
    </RedoSecondaryNavItem>
  );
});
