import { useLoad } from "@redotech/react-util/load";
import { StringFilterOperator } from "@redotech/redo-model/views/advanced-filters/string-filter";
import { memo, useEffect, useMemo, useState } from "react";
import {
  RedoFilterDropdownAnchor,
  RedoFilterGroup,
} from "../../arbiter-components/filter-group/redo-filter-group";
import { RedoTextInput } from "../../arbiter-components/input/redo-text-input";
import {
  ListItemRender,
  RedoListItem,
} from "../../arbiter-components/list/redo-list";
import { RedoListDropdown } from "../../arbiter-components/list/redo-list-dropdown";
import { SimpleFilterDropdown } from "../../filters/simple-filter-dropdown";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { AdvancedTableFilter, StringTableFilter } from "../advanced-filter";
import * as styles from "./filter.module.css";

export const StringFilterGroup = memo(function StringFilterGroup({
  filter,
  setFilter,
  removeFilter,
  openOnRender,
  filters,
}: {
  filter: StringTableFilter;
  setFilter(filter: StringTableFilter): void;
  removeFilter(): void;
  openOnRender: boolean;
  filters: AdvancedTableFilter[];
}) {
  const [valueDropdownOpen, setValueDropdownOpen] = useState(false);

  const [valueRef, setValueRef] = useState<HTMLButtonElement | null>(null);

  const { value, operator } = filter.data;

  const valueOptionsLoad = useLoad(
    async (signal) => {
      if (filter.valueFetcher) {
        return await filter.valueFetcher(
          {
            filters: filters.filter(
              (otherFilter) => filter.data.name !== otherFilter.data.name,
            ),
          },
          signal,
        );
      }
      return filter.values ?? [];
    },
    [filter],
  );

  useEffect(() => {
    if (openOnRender && valueRef) {
      valueRef.click();
    }
  }, [openOnRender, valueRef]);

  const valueText = value == null ? "..." : getDisplayString(value, filter);

  const valueInput = (
    <RedoTextInput
      className={styles.stringInput}
      fullWidth={false}
      placeholder={valueText}
      setValue={(value) => {
        setFilter({
          ...filter,
          data: { ...filter.data, value: getStringValue(value, filter) },
        });
      }}
      state="default"
      value={getStringValue(value, filter)}
    />
  );

  const valueDropdownAnchor = (
    <RedoFilterDropdownAnchor
      color="primary"
      onClick={() => setValueDropdownOpen(!valueDropdownOpen)}
      ref={setValueRef}
      text={valueText}
      tooltip={
        value && filter.itemGenerator ? (
          <ListItemRender
            card={false}
            disabled={false}
            focused={false}
            idx={0}
            item={filter.itemGenerator(value)}
            selected={false}
            selectionVariant="none"
            size="sm"
            useWrapper={false}
          />
        ) : value ? (
          <Flex dir="column">
            <Text>{getDisplayString(value, filter)}</Text>
          </Flex>
        ) : undefined
      }
      weight="medium"
    />
  );

  const valueOptions: RedoListItem<string>[] = useMemo(() => {
    const baseValueOptions = valueOptionsLoad.value || [];

    if (filter.itemGenerator) {
      return baseValueOptions.map<RedoListItem<string>>(filter.itemGenerator);
    } else {
      return baseValueOptions.map((option) => {
        return {
          id: `${option}`,
          value: getStringValue(option, filter),
          type: "text",
          text: getDisplayString(option, filter),
        };
      });
    }
  }, [valueOptionsLoad, filter]);

  const valueDropdown = (
    <RedoListDropdown
      dropdownAnchor={valueRef}
      dropdownOpen={valueDropdownOpen}
      items={valueOptions}
      itemSelected={({ item }) => {
        setFilter({ ...filter, data: { ...filter.data, value: item.value } });
      }}
      selectedItems={value || undefined}
      setDropdownOpen={setValueDropdownOpen}
      size="xs"
    />
  );

  const operatorOptions = useMemo(() => {
    if (filter.operators && filter.operators.length > 0) {
      return filter.operators;
    }
    return Object.values(StringFilterOperator);
  }, [filter.operators]);

  return (
    <>
      {inputOperators.includes(operator) ? undefined : valueDropdown}
      <RedoFilterGroup
        Icon={filter.Icon}
        propertyName={filter.displayName}
        query={
          <SimpleFilterDropdown
            filterStyle="query"
            options={operatorOptions}
            optionToFriendlyName={(option) => stringTypeToText[option]}
            setValue={(operator) => {
              setFilter({ ...filter, data: { ...filter.data, operator } });
            }}
            value={operator}
          />
        }
        removeFilter={removeFilter}
        value={
          inputOperators.includes(operator) ? valueInput : valueDropdownAnchor
        }
      />
    </>
  );
});

function getStringValue(item: any, filter: StringTableFilter): string {
  return filter.stringify && item != null
    ? filter.stringify(item)
    : item != null
      ? item.toString()
      : "";
}

function getDisplayString(item: any, filter: StringTableFilter): string {
  return filter.display && item
    ? filter.display(item)
    : item
      ? item.toString()
      : "";
}

const stringTypeToText: Record<StringFilterOperator, string> = {
  [StringFilterOperator.EQUALS]: "is",
  [StringFilterOperator.NOT_EQUALS]: "is not",
  [StringFilterOperator.CONTAINS]: "contains",
};

const inputOperators = [StringFilterOperator.CONTAINS];
