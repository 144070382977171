import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const concludeCheckoutAbTest = {
  input: z.object({
    splitId: zExt.objectId(),
    selectedTreatmentId: zExt.objectId(),
  }),
  output: z.null(),
};
