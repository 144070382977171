// extracted by mini-css-extract-plugin
export var commandBarModal = "_1x-0";
export var commandIcon = "_1x-5";
export var menuItem = "_1x-8";
export var resultsSection = "_1x-7";
export var scrollContainer = "_1x-9";
export var searchIcon = "_1x-2";
export var searchInput = "_1x-3";
export var searchInputContainer = "_1x-1";
export var shortcutContainer = "_1x-4";
export var shortcutText = "_1x-6";