import { useRequiredContext } from "@redotech/react-util/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { RedoSecondaryNavItem } from "@redotech/redo-web/arbiter-components/navigation/secondary/redo-secondary-nav-item";
import AlertHexagonIcon from "@redotech/redo-web/arbiter-icon/alert-hexagon.svg";
import AlertHexagonFilledIcon from "@redotech/redo-web/icon-old/alert-hexagon-filled.svg";
import { memo } from "react";
import { OrdersReviewCountContext } from "../../../../services/orders/orders-review-count-service";

export const ClaimsItem = memo(function ClaimsItem({
  ordersRoot,
}: {
  ordersRoot: string;
}) {
  const team = useRequiredContext(TeamContext);
  const packageProtectionEnabled = team?.settings.packageProtection?.enabled;

  const { claimsNeedingReview } = useRequiredContext(OrdersReviewCountContext);

  const baseUrl = `${ordersRoot}/claims`;

  if (!packageProtectionEnabled) {
    return null;
  }

  return (
    <RedoSecondaryNavItem
      action={{ type: "link", href: baseUrl }}
      FilledIcon={AlertHexagonFilledIcon}
      friendlyName="Claims"
      id="claims"
      notificationCount={claimsNeedingReview}
      UnfilledIcon={AlertHexagonIcon}
    />
  );
});
