export const DAYS_IN_A_YEAR = 365;

export const HOURS_IN_A_DAY = 24;

export const MINUTES_IN_AN_HOUR = 60;

export const SECONDS_IN_A_MINUTE = 60;
export const SECONDS_IN_AN_HOUR = SECONDS_IN_A_MINUTE * MINUTES_IN_AN_HOUR;
export const SECONDS_IN_A_DAY = SECONDS_IN_AN_HOUR * HOURS_IN_A_DAY;
export const SECONDS_IN_A_YEAR = SECONDS_IN_A_DAY * DAYS_IN_A_YEAR;

// https://stackoverflow.com/questions/221294/how-do-i-get-a-timestamp-in-javascript
export const getUnixTimestampInSeconds = () => {
  return Math.floor(Date.now() / 1000);
};
