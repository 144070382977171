import { RedoModal } from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import TrashIcon from "@redotech/redo-web/arbiter-icon/trash-01.svg";
import { Text } from "@redotech/redo-web/text";
import { memo, useState } from "react";

export const ConfirmDeleteOmsFolderModal = memo(
  function ConfirmDeleteOmsFolderModal({
    isOpen,
    onClose,
    onConfirm,
    folderName,
  }: {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => Promise<void>;
    folderName: string;
  }) {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleConfirm = async () => {
      setIsSubmitting(true);
      try {
        await onConfirm();
        onClose();
      } finally {
        setIsSubmitting(false);
      }
    };

    return (
      <RedoModal
        isOpen={isOpen}
        onModalCloseRequested={isSubmitting ? () => {} : onClose}
        primaryButton={{
          text: "Delete",
          onClick: handleConfirm,
          disabled: isSubmitting,
          theme: "destructive",
          IconLeading: TrashIcon,
        }}
        secondaryButton={{
          text: "Cancel",
          onClick: onClose,
          disabled: isSubmitting,
        }}
        theme="error"
        title="Confirm delete"
      >
        <Text>Are you sure you want to delete the folder "{folderName}"?</Text>
      </RedoModal>
    );
  },
);
