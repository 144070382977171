// extracted by mini-css-extract-plugin
export var avatarContainer = "_b5-0";
export var gray = "_b5-f";
export var green = "_b5-c";
export var icon = "_b5-9";
export var image = "_b5-1";
export var lg = "_b5-6";
export var md = "_b5-5";
export var presenceDot = "_b5-b";
export var red = "_b5-d";
export var sm = "_b5-4";
export var status = "_b5-a";
export var xl = "_b5-7";
export var xs = "_b5-3";
export var xxl = "_b5-8";
export var xxs = "_b5-2";
export var yellow = "_b5-e";