import { useRequiredContext } from "@redotech/react-util/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { RedoSecondaryNavItem } from "@redotech/redo-web/arbiter-components/navigation/secondary/redo-secondary-nav-item";
import ShieldTickIcon from "@redotech/redo-web/arbiter-icon/shield-tick.svg";
import ShieldTickFilledIcon from "@redotech/redo-web/arbiter-icon/shield-tick_filled.svg";
import { memo } from "react";

export const WarrantiesItem = memo(function WarrantiesItem({
  ordersRoot,
}: {
  ordersRoot: string;
}) {
  const team = useRequiredContext(TeamContext);
  const urlPrefix = `${ordersRoot}/warranties`;

  const warrantiesEnabled = team?.settings.warranties?.enabled;
  const viewedLandingPage = localStorage.getItem("viewed_warranties_page");

  const link = warrantiesEnabled ? `${urlPrefix}` : `${urlPrefix}/about`;

  return (
    <RedoSecondaryNavItem
      action={{ type: "link", href: link }}
      FilledIcon={ShieldTickFilledIcon}
      friendlyName="Warranties"
      id="warranties"
      isNew={!viewedLandingPage && !warrantiesEnabled}
      UnfilledIcon={ShieldTickIcon}
    />
  );
});
