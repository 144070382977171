// extracted by mini-css-extract-plugin
export var copyableField = "_ca-4";
export var customerBadge = "_ca-0";
export var customerInfoTitle = "_ca-2";
export var details = "_ca-1";
export var dropdownContainer = "_ca-5";
export var dropdownImage = "_ca-6";
export var editAssigneeForm = "_ca-a";
export var editButton = "_ca-3";
export var email = "_ca-7";
export var hidden = "_ca-d";
export var input = "_ca-8";
export var saveButton = "_ca-b";
export var shopifyIcon = "_ca-c";
export var username = "_ca-9";