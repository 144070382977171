import { getDateTimeString } from "@redotech/redo-web/date-utils";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo } from "react";
import * as timelineCss from "./shipping-timeline.module.css";

export interface ShippingEvent {
  message: string;
  datetime: string;
  location?: string;
}

export const ShippingTimeline = memo(function ShippingTimeline({
  events,
}: {
  events: ShippingEvent[];
}) {
  return (
    <div className={timelineCss.timeline}>
      {events.map((event, index) => (
        <TimelineItem event={event} key={index} />
      ))}
    </div>
  );
});

const TimelineItem = memo(function TimelineItem({
  event,
}: {
  event: ShippingEvent;
}) {
  return (
    <Flex className={timelineCss.timelineItem} dir="row" gap="lg">
      <Flex className={timelineCss.leftContent} dir="column">
        <div className={timelineCss.dot} />
        <div className={timelineCss.timelineLine} />
      </Flex>
      <Flex className={timelineCss.rightContent} dir="column">
        <Text fontSize="xs" fontWeight="regular">
          {event.message}
        </Text>
        <Text fontSize="xs" fontWeight="thin" textColor="tertiary">
          {getDateTimeString(new Date(event.datetime))}
          {event.location && ` — ${event.location}`}
        </Text>
      </Flex>
    </Flex>
  );
});
