import { useHandler } from "@redotech/react-util/hook";
import {
  ConversationPlatform,
  ConversationPlatformString,
} from "@redotech/redo-model/conversation";
import {
  ChannelFilterType,
  FilterGroupFilterOption,
  ChannelsFilter as ModelChannelsFilter,
  PendingFilter,
} from "@redotech/redo-model/conversation-filters/conversation-filters";
import { RedoFilterGroup } from "@redotech/redo-web/arbiter-components/filter-group/redo-filter-group";
import {
  ConversationFilterOptionToIcon,
  ConversationFilterOptionToName,
} from "@redotech/redo-web/conversation-filters/conversation-filter-icons";
import { SimpleFilterDropdown } from "@redotech/redo-web/filters/simple-filter-dropdown";
import { memo } from "react";
import { SimpleMultiSelectFilterDropdown } from "../../../../web/src/filters/simple-multiselect-filter-dropdown";

export const ChannelsFilter = memo(function ConversationTagFilter({
  filter,
  setFilter,
  removeFilter,
  openOnRender,
}: {
  filter: ModelChannelsFilter | PendingFilter<ModelChannelsFilter>;
  setFilter(
    filter: ModelChannelsFilter | PendingFilter<ModelChannelsFilter>,
  ): void;
  removeFilter(): void;
  openOnRender: boolean;
}) {
  const channelFilterType = filter.query;
  const selectedChannels = filter?.value;

  const selectedChannelsDisplay = useHandler(
    (channels: ConversationPlatform[] | undefined) => {
      if (!channels || channels.length === 0) {
        return "...";
      }
      return channels.length === 1
        ? `1 Channel`
        : `${channels.length} Channels`;
    },
  );

  return (
    <RedoFilterGroup
      Icon={ConversationFilterOptionToIcon[FilterGroupFilterOption.CHANNELS]}
      propertyName={
        ConversationFilterOptionToName[FilterGroupFilterOption.CHANNELS]
      }
      query={
        <SimpleFilterDropdown
          filterStyle="query"
          options={Object.values(ChannelFilterType)}
          optionToFriendlyName={(option) => channelFilterTypeToText[option]}
          setValue={(query) => setFilter({ ...filter, query })}
          value={channelFilterType}
        />
      }
      removeFilter={removeFilter}
      value={
        <SimpleMultiSelectFilterDropdown
          openOnRender={openOnRender}
          options={Object.values(ConversationPlatform)}
          optionToFriendlyName={(option) => ConversationPlatformString[option]}
          selectedOptionsToDisplay={selectedChannelsDisplay}
          setValues={(channels) => {
            if (channels.length === 0) {
              setFilter({ ...filter, value: null });
            } else {
              setFilter({ ...filter, value: channels });
            }
          }}
          values={selectedChannels || undefined}
        />
      }
    />
  );
});

const channelFilterTypeToText: Record<ChannelFilterType, string> = {
  [ChannelFilterType.INCLUDES]: "include",
  [ChannelFilterType.EXCLUDES]: "do not include",
};
