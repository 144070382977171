import {
  CheckoutAbTestMetricToOptimize,
  CheckoutAbTestTreatmentSchema,
} from "@redotech/redo-model/checkouts/checkout-ab-test-builder";
import { z } from "zod";

export const createCheckoutSplitFromBuilder = {
  input: z.object({
    testName: z.string(),
    testHypothesis: z.string(),
    metricToOptimize: z.nativeEnum(CheckoutAbTestMetricToOptimize),
    controlTreatment: CheckoutAbTestTreatmentSchema,
    variantTreatments: z.array(CheckoutAbTestTreatmentSchema),
    enableSplit: z.boolean(),
  }),
  output: z.null(),
};
