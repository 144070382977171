import { SavableRateTableSchema } from "@redotech/redo-model/checkouts/checkout-ab-test-builder";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const getCheckoutExperiencesForAbTestBuilder = {
  input: z.null(),
  output: z.array(
    z.object({
      _id: zExt.objectId(),
      name: z.string(),
      active: z.boolean(),
      rateTables: z.array(SavableRateTableSchema),
    }),
  ),
};
export type GetCheckoutExperiencesForAbTestBuilderOutput = z.infer<
  typeof getCheckoutExperiencesForAbTestBuilder.output
>;
