import { ManagedClaimStatus } from "@redotech/merchant-sdk/rpc/schema/get-managed-claim-status";
import { useRequiredContext } from "@redotech/react-util/context";
import { useLoad } from "@redotech/react-util/load";
import { RedoMerchantRpcClientContext } from "@redotech/redo-merchant-app-common/rpc-client";
import { MerchantAppReturn } from "@redotech/redo-model/return";
import {
  RedoBadge,
  RedoBadgeColor,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import { Flex } from "@redotech/redo-web/flex";
import { SkeletonText } from "@redotech/redo-web/skeleton";
import { Text } from "@redotech/redo-web/text";
import { memo } from "react";
import * as warrantiesInsuranceCardCss from "./warranties-insurance-card.module.css";

const claimStatusBadgeColor: Record<ManagedClaimStatus, RedoBadgeColor> = {
  [ManagedClaimStatus.SUBMITTED]: "gray",
  [ManagedClaimStatus.UNDER_REVIEW]: "gray",
  [ManagedClaimStatus.PENDING_DOCS]: "gray",
  [ManagedClaimStatus.CLOSED]: "gray",
  [ManagedClaimStatus.NOT_PROTECTED]: "gray",
  [ManagedClaimStatus.APPROVED]: "success",
  [ManagedClaimStatus.NOT_SUBMITTED]: "warning",
  [ManagedClaimStatus.REJECTED]: "error",
};

export const WarrantiesInsuranceCard = memo(function WarrantiesInsuranceCard({
  return: return_,
}: {
  return?: MerchantAppReturn;
}) {
  const rpcClient = useRequiredContext(RedoMerchantRpcClientContext);

  const managedClaimStatusLoad = useLoad(
    async (signal) => {
      if (!return_) {
        return null;
      }

      return await rpcClient.getManagedClaimStatus(
        { returnId: return_.id },
        { signal },
      );
    },
    [return_],
  );

  const managedClaimLoading =
    managedClaimStatusLoad.pending ||
    (managedClaimStatusLoad.value === null && !managedClaimStatusLoad.error);
  const managedClaimStatus =
    managedClaimStatusLoad.value?.status ?? ManagedClaimStatus.NOT_SUBMITTED;
  const reportedClaims = return_?.reportedClaims ?? [];

  return (
    <Flex
      className={warrantiesInsuranceCardCss.card}
      flexDirection="column"
      gap="md"
    >
      <Text
        className={warrantiesInsuranceCardCss.headerColor}
        fontFamily="text"
        fontSize="xs"
        fontWeight="regular"
      >
        Insurance details
      </Text>
      {managedClaimLoading ? (
        <SkeletonText length={30} />
      ) : (
        <Flex flexDirection="column" gap="lg">
          <Flex flexDirection="column" gap="sm">
            <Text
              className={warrantiesInsuranceCardCss.contentColor}
              fontFamily="text"
              fontSize="xs"
            >
              Insurance provider:
            </Text>
            <Text
              className={warrantiesInsuranceCardCss.contentColorSecondary}
              fontFamily="text"
              fontSize="xs"
            >
              Shipsurance
            </Text>
          </Flex>
          <Flex flexDirection="column" gap="sm">
            <Text
              className={warrantiesInsuranceCardCss.contentColor}
              fontFamily="text"
              fontSize="xs"
              fontWeight="regular"
            >
              Status
            </Text>
            <RedoBadge
              color={claimStatusBadgeColor[managedClaimStatus]}
              size="xs"
              text={managedClaimStatus}
            />
          </Flex>
          <Flex flexDirection="column" gap="sm">
            <Text
              className={warrantiesInsuranceCardCss.contentColor}
              fontFamily="text"
              fontSize="xs"
              fontWeight="regular"
            >
              Reported claim(s):
            </Text>
            {reportedClaims.map((claim) => (
              <div key={claim.claimId}>
                <Text
                  className={warrantiesInsuranceCardCss.contentColorSecondary}
                  fontFamily="text"
                  fontSize="xs"
                  fontWeight="thin"
                >
                  Claim id: {claim.claimId}
                </Text>
                <Text
                  className={warrantiesInsuranceCardCss.contentColorSecondary}
                  fontFamily="text"
                  fontSize="xs"
                  fontWeight="thin"
                >
                  Retrieval code: {claim.claimRetrievalCode}
                </Text>
              </div>
            ))}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
});
