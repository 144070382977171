// extracted by mini-css-extract-plugin
export var active = "_q-d";
export var blink = "_q-e";
export var container = "_q-5";
export var controls = "_q-0";
export var controlsRight = "_q-1";
export var fakeCursor = "_q-c";
export var hidden = "_q-b";
export var quillContainerSmall = "_q-7";
export var quillEditor = "_q-6";
export var quillEditorSmall = "_q-8";
export var quillFormatButtons = "_q-a";
export var quillToolbar = "_q-9";
export var removePadding = "_q-2";
export var state = "_q-3";
export var stateTitle = "_q-4";