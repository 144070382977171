import { CustomsDeclarationSchema } from "@redotech/redo-model/fulfillments/customs-declaration";
import { SelectedShippingRateSchema } from "@redotech/redo-model/fulfillments/fulfillment-carriers-and-services";
import { RedoShopifyDeliveryMethodType } from "@redotech/redo-model/fulfillments/fulfillment-delivery-method-type";
import {
  FulfillmentGroupDataSchema,
  OMSEventSchema,
} from "@redotech/redo-model/fulfillments/fulfillment-group";
import { PrintStatusSchema } from "@redotech/redo-model/fulfillments/fullfilment-print-status";
import { Hazmat } from "@redotech/redo-model/fulfillments/hazmat";
import { OMSUserSchema } from "@redotech/redo-model/fulfillments/oms-user";
import {
  ContentsType,
  NonDeliveryOption,
  USPSExemptionCode,
} from "@redotech/redo-model/outbound-labels/customs";
import { SpecialRateEligibility } from "@redotech/redo-model/outbound-labels/outbound-labels";
import { ParcelSchema } from "@redotech/redo-model/outbound-labels/parcel";
import {
  MoneySchema,
  WeightSchema,
} from "@redotech/redo-model/outbound-labels/util";
import { TagSchema } from "@redotech/redo-model/tag";
import { z } from "zod";

const FulfillmentOrderUpdateSchema = z.object({
  fulfillmentGroupId: z.string(),
  selectedParcel: ParcelSchema.nullish(),
  overrideWeight: WeightSchema.nullish(),
  selectedShippingRate: SelectedShippingRateSchema.nullish(),
  displayWeightGrams: z.number().nullish(),
  assignedUser: OMSUserSchema.nullish(),
  tags: z.array(TagSchema).nullish(),
  noteFromBuyer: z.string().nullish(),
  noteToBuyer: z.string().nullish(),
  internalNote: z.string().nullish(),
  printStatus: PrintStatusSchema.nullish(),
  signatureRequired: z.boolean().nullish(),
  adultSignatureRequired: z.boolean().nullish(),
  includeInsurance: z.boolean().nullish(),
  specialRateEligibility: z.nativeEnum(SpecialRateEligibility).nullish(),
  overrideDeliveryMethod: z.nativeEnum(RedoShopifyDeliveryMethodType).nullish(),
  scanVerified: z
    .object({
      verifiedBy: OMSUserSchema.nullish(),
      verifiedAt: z.date().nullish(),
    })
    .nullish(),
  packingSlipTemplateId: z.string().nullish(),
  newEvent: OMSEventSchema.nullish(),
  customsDeclarations: z.array(CustomsDeclarationSchema).nullish(),
  contentsType: z.nativeEnum(ContentsType).nullish(),
  contentsExplanation: z.string().nullish(),
  nonDeliveryOption: z.nativeEnum(NonDeliveryOption).nullish(),
  uspsExemptionCode: z.nativeEnum(USPSExemptionCode).nullish(),
  exportDeclarationNumber: z.string().nullish(),
  itn: z.string().nullish(),
  dutiesPaid: MoneySchema.nullish(),
  postagePaid: MoneySchema.nullish(),
  taxIdentifierIds: z.array(z.string()).nullish(),
  ddp: z.boolean().nullish(),
  hazmat: z.nativeEnum(Hazmat).nullish(),
});

export type FulfillmentOrderUpdate = z.infer<
  typeof FulfillmentOrderUpdateSchema
>;

export const updateFulfillmentGroups = {
  input: z.object({ updates: z.array(FulfillmentOrderUpdateSchema) }),
  output: z.record(z.string(), FulfillmentGroupDataSchema),
};
