import { TeamContext } from "@redotech/redo-web/context/team-context";
import { ShopifyStorefrontClient } from "@redotech/shopify-storefront";
import { ReactNode, createContext, memo, useContext } from "react";

export {
  ReloadTeamContext,
  TeamContext,
} from "@redotech/redo-web/context/team-context";

import { UserContext } from "./user";

export const StorefrontClientContext = createContext<
  ShopifyStorefrontClient | undefined
>(undefined);

export const TeamAndUserRequired = memo(function TeamAndUserRequired({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const team = useContext(TeamContext);
  const user = useContext(UserContext);

  if (!team || !user) {
    return null;
  } else {
    return <>{children}</>;
  }
});
