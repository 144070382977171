import { z } from "zod";
import { numberlikeSchema } from "./common/zod-util";

export enum GeographicUnitType {
  COUNTRY = "country",
  STATE = "state",
  CITY = "city",
}

export const timezoneSchema = z.object({
  zoneName: z.string(),
  gmtOffset: z.number(),
  gmtOffsetName: z.string(),
  abbreviation: z.string(),
  tzName: z.string(),
});
export type Timezone = z.infer<typeof timezoneSchema>;

export const baseGeographicUnitSchema = z.object({
  importedId: z.string(),
  name: z.string(),
  latitude: numberlikeSchema as z.ZodType<number>, // since it's a Decimal128 object in Mongo
  longitude: numberlikeSchema as z.ZodType<number>, // since it's a Decimal128 object in Mongo
});

export const countrySchema = baseGeographicUnitSchema.extend({
  type: z.literal(GeographicUnitType.COUNTRY),
  iso3: z.string(),
  iso2: z.string(),
  numericCode: z.string(),
  phoneCode: z.string(),
  capital: z.string(),
  currency: z.string(),
  currencyName: z.string(),
  currencySymbol: z.string(),
  tld: z.string(),
  native: z.string().optional().nullable(),
  region: z.string(),
  regionId: z.string(),
  subregion: z.string(),
  subregionId: z.string(),
  nationality: z.string(),
  timezones: z.array(timezoneSchema),
  translations: z.record(z.string()),
  emoji: z.string(),
  emojiU: z.string(),
});
export type Country = z.infer<typeof countrySchema>;

export const stateSchema = baseGeographicUnitSchema.extend({
  type: z.literal(GeographicUnitType.STATE),
  name: z.string(),
  countryId: z.string(),
  countryCode: z.string(),
  countryName: z.string(),
  stateCode: z.string(),
  stateType: z.string().optional().nullable(),
});
export type State = z.infer<typeof stateSchema>;

export const citySchema = baseGeographicUnitSchema.extend({
  type: z.literal(GeographicUnitType.CITY),
  stateId: z.string(),
  stateCode: z.string(),
  stateName: z.string(),
  countryId: z.string(),
  countryCode: z.string(),
  countryName: z.string(),
  wikiDataId: z.string().optional().nullable(),
});
export type City = z.infer<typeof citySchema>;

export const geographicUnitSchema = z.union([
  countrySchema,
  stateSchema,
  citySchema,
]);
export type GeographicUnit = z.infer<typeof geographicUnitSchema>;
