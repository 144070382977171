// extracted by mini-css-extract-plugin
export var defaultWrapper = "_ar-3";
export var downloadButton = "_ar-4";
export var fileContainer = "_ar-7";
export var fileInfo = "_ar-5";
export var fileInfoWidthRestricted = "_ar-6";
export var fileType = "_ar-1";
export var fileTypeName = "_ar-2";
export var image = "_ar-0";
export var link = "_ar-9";
export var overflowEllipsis = "_ar-8";