import { RedoBadgeColor } from "@redotech/redo-web/arbiter-components/badge/redo-badge";

export function shippingStatusToBadgeColor(status: string): RedoBadgeColor {
  switch (status) {
    case "pre_transit":
      return "indigo";
    case "in_transit":
      return "brand";
    case "out_for_delivery":
      return "warning";
    case "delivered":
      return "success";
    case "available_for_pickup":
      return "purple";
    case "return_to_sender":
      return "blue";
    case "cancelled":
      return "orange";
    case "failure":
    case "error":
      return "error";
    case "unknown":
    default:
      return "gray";
  }
}
