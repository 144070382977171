import { composeWrappers } from "@redotech/react-util/component";
import { LocationService } from "@redotech/redo-merchant-app-fulfillment/services/location-service";
import { ParcelService } from "@redotech/redo-merchant-app-fulfillment/services/parcel-service";
import { memo, ReactElement } from "react";
import { ViewsProvider } from "../app/views";
import { CreateAbTestInitializationParamsContextProvider } from "../conversion/ab-tests/create-ab-test-initialization-params-context";
import { TeamSwitcherService } from "./global/team-switcher-service";
import { UserProfileService } from "./global/user-profile-service";
import { DiscountsService } from "./marketing/discounts-service";
import { ReviewsToReviewService } from "./marketing/reviews-to-review-service";
import { SegmentsService } from "./marketing/segments-service";
import { OmsViewOrderingService } from "./orders/oms-view-ordering-service";
import { OrdersReviewCountService } from "./orders/orders-review-count-service";
import { ConversationCountsService } from "./support/conversation-counts-service";
import { ConversationTagsService } from "./support/conversation-tags-service";
import { CustomerTagsService } from "./support/customer-tags-services";
import { EmailIntegrationsService } from "./support/email-integrations";
import { MacrosService } from "./support/macros-service";
import { PhoneNumberService } from "./support/phone-number-service";
import { SupportViewOrderingService } from "./support/support-view-ordering-service";
import { SupportVoiceProvider } from "./support/support-voice-provider";
import { TeamCallAvailabilitiesService } from "./support/team-call-availabilities";
import { UserCallAvailabilityContextProvider } from "./support/user-call-availability";

export const DataServices = memo(function DataServices({
  children,
}: {
  children: ReactElement;
}) {
  return composeWrappers(
    (inner) => <PhoneNumberService>{inner}</PhoneNumberService>,
    (inner) => <ConversationTagsService>{inner}</ConversationTagsService>,
    (inner) => <CustomerTagsService>{inner}</CustomerTagsService>,
    (inner) => <MacrosService>{inner}</MacrosService>,
    (inner) => <SupportVoiceProvider>{inner}</SupportVoiceProvider>,
    (inner) => (
      <UserCallAvailabilityContextProvider>
        {inner}
      </UserCallAvailabilityContextProvider>
    ),
    (inner) => (
      <TeamCallAvailabilitiesService>{inner}</TeamCallAvailabilitiesService>
    ),
    (inner) => <EmailIntegrationsService>{inner}</EmailIntegrationsService>,
    (inner) => <ParcelService>{inner}</ParcelService>,
    (inner) => <LocationService>{inner}</LocationService>,
    (inner) => <SegmentsService>{inner}</SegmentsService>,
    (inner) => <DiscountsService>{inner}</DiscountsService>,
    (inner) => <ViewsProvider>{inner}</ViewsProvider>,
    (inner) => <ConversationCountsService>{inner}</ConversationCountsService>,
    (inner) => <SupportViewOrderingService>{inner}</SupportViewOrderingService>,
    (inner) => <OmsViewOrderingService>{inner}</OmsViewOrderingService>,
    (inner) => <OrdersReviewCountService>{inner}</OrdersReviewCountService>,
    (inner) => <UserProfileService>{inner}</UserProfileService>,
    (inner) => <TeamSwitcherService>{inner}</TeamSwitcherService>,
    (inner) => <ReviewsToReviewService>{inner}</ReviewsToReviewService>,
    (inner) => (
      <CreateAbTestInitializationParamsContextProvider>
        {inner}
      </CreateAbTestInitializationParamsContextProvider>
    ),
  )(children);
});
