import DotIcon from "@redotech/redo-web/arbiter-icon/dot-01.svg";
import * as classNames from "classnames";
import { memo } from "react";
import { StatusDotColor } from "../arbiter-components/list/redo-list-item-lead";
import * as phoneWidgetCss from "./phone-widget.module.css";

export const StatusDot = memo(function StatusDot({
  color,
}: {
  color: StatusDotColor;
}) {
  return (
    <DotIcon
      className={classNames(phoneWidgetCss.statusDot, phoneWidgetCss[color])}
    />
  );
});
