import { z } from "zod";
import { AnalyticsFilterType } from "./analytics-filter-type";
import { makeReportFilterSchema } from "./base-report-filter";

export enum ItemSoldFilterQuery {
  IS_GREATER_THAN = "isGreaterThan",
  IS_LESS_THAN = "isLessThan",
  IS_EQUAL_TO = "isEqualTo",
}

export const ItemSoldFilterSchema = makeReportFilterSchema(
  AnalyticsFilterType.NUM_SOLD,
  ItemSoldFilterQuery,
  z.number(),
);

export type ItemSoldFilter = z.infer<typeof ItemSoldFilterSchema>;
