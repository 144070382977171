import { z } from "zod";
import { zExt } from "../common/zod-util";
import {
  DiscountGroupType,
  DiscountProvider,
  DiscountValueType,
  ExpirationType,
} from "../discount";

const expireAfterDaysSchema = z.object({
  expirationType: z.literal(ExpirationType.EXPIRATION_DAYS),
  days: z.number(),
});
const expireAfterDateSchema = z.object({
  expirationType: z.literal(ExpirationType.DATE),
  date: z.date(),
});
const expireNeverSchema = z.object({
  expirationType: z.literal(ExpirationType.NEVER),
});
const expireAfterDeliverySchema = z.object({
  expirationType: z.literal(ExpirationType.DELIVERY),
});

export const expirationSchema = z.discriminatedUnion("expirationType", [
  expireAfterDaysSchema,
  expireAfterDateSchema,
  expireNeverSchema,
  expireAfterDeliverySchema,
]);

// Define schemas for discount settings types
const combinesWithSchema = z.object({
  orderDiscounts: z.boolean().optional(),
  productDiscounts: z.boolean().optional(),
  shippingDiscount: z.boolean().optional(),
});

export enum DiscountSettingsType {
  SHOPIFY_BASIC = "SHOPIFY_BASIC",
}

export type BasicShopifyDiscountConfiguration = z.infer<
  typeof basicShopifyDiscountSchema
>;
const basicShopifyDiscountSchema = z.object({
  settingsType: z.literal(DiscountSettingsType.SHOPIFY_BASIC),
  discountValueType: z.nativeEnum(DiscountValueType),
  discountValueAmount: z.number(),
  combinesWith: combinesWithSchema,
  excludeItemsOnSale: z.boolean().optional(),
  appliesToCollections: z.array(z.string()).optional(),
  endsAt: z.date().optional(),
});

// Using discriminatedUnion for future extensibility
export const discountSettingsSchema = z.discriminatedUnion("settingsType", [
  basicShopifyDiscountSchema,
]);

export const databaseDiscountSchema = z.object({
  title: z.string(),
  provider: z.nativeEnum(DiscountProvider),
  groupType: z.nativeEnum(DiscountGroupType),
  discountId: z.string(),
  code: z.string(),
  expiration: expirationSchema,
  discountSettings: discountSettingsSchema.optional(),
});

export type RedoDatabaseDiscount = z.infer<typeof DiscountDbParser>;
export const DiscountDbParser = z.intersection(
  databaseDiscountSchema,
  z.object({ _id: zExt.objectId(), teamId: zExt.objectId() }),
);
