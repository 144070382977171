import { assertNever } from "@redotech/util/type";
import { ReportDataType } from "../report-data-type";

export enum ReportFilterType {
  RETURN_TYPE = "returnType",
  ORDER_CREATED_DATE = "orderCreatedDate",
  // ORDER_DELIVERY_DATE = "orderDeliveryDate",
  RETURN_CREATED_DATE = "returnCreatedDate",
  RETURN_DELIVERY_DATE = "returnDeliveryDate",
  APPROVAL_DATE = "approvalDate",
  RETURN_PROCESSED_DATE = "returnProcessedDate",
  // PURCHASED_REDO = "purchasedRedo",
  RETURN_STATUS = "returnStatus",
  COMPENSATION_METHODS = "compensationMethods",

  PRODUCT_PROCESSED_DATE = "productProcessedDate",
  PRODUCT_STATUS = "productStatus",
  COMPENSATION_METHOD = "compensationMethod",
  PRODUCT_NAME = "productName",
  VARIANT_NAME = "variantName",
}

export const ORDERS_REPORT_FILTER_TYPES = [
  ReportFilterType.RETURN_TYPE,
  ReportFilterType.ORDER_CREATED_DATE,
  // ReportFilterType.ORDER_DELIVERY_DATE,
  ReportFilterType.RETURN_CREATED_DATE,
  // ReportFilterType.RETURN_DELIVERY_DATE,
  // ReportFilterType.APPROVAL_DATE,
  ReportFilterType.RETURN_PROCESSED_DATE,
  // ReportFilterType.PURCHASED_REDO,
  ReportFilterType.RETURN_STATUS,
  ReportFilterType.COMPENSATION_METHODS,
] as const;

export function isValidOrdersReportFilterType(
  type: ReportFilterType,
): type is (typeof ORDERS_REPORT_FILTER_TYPES)[number] {
  return (ORDERS_REPORT_FILTER_TYPES as readonly string[]).includes(type);
}

export const PRODUCTS_REPORT_FILTER_TYPES = [
  ReportFilterType.RETURN_TYPE,
  ReportFilterType.ORDER_CREATED_DATE,
  // ReportFilterType.ORDER_DELIVERY_DATE,
  ReportFilterType.RETURN_CREATED_DATE,
  ReportFilterType.RETURN_STATUS,
  ReportFilterType.RETURN_DELIVERY_DATE,
  ReportFilterType.APPROVAL_DATE,
  ReportFilterType.PRODUCT_PROCESSED_DATE,
  ReportFilterType.PRODUCT_STATUS,
  ReportFilterType.COMPENSATION_METHOD,
  ReportFilterType.PRODUCT_NAME,
  ReportFilterType.VARIANT_NAME,
] as const;

export function isValidProductsReportFilterType(
  type: ReportFilterType,
): type is (typeof PRODUCTS_REPORT_FILTER_TYPES)[number] {
  return (PRODUCTS_REPORT_FILTER_TYPES as readonly string[]).includes(type);
}

export const WARRANTY_CUSTOMERS_REPORT_FILTER_TYPES = [] as const;

export function isValidWarrantyCustomersReportFilterType(
  type: ReportFilterType,
): type is (typeof WARRANTY_CUSTOMERS_REPORT_FILTER_TYPES)[number] {
  return (WARRANTY_CUSTOMERS_REPORT_FILTER_TYPES as readonly string[]).includes(
    type,
  );
}

export const PURCHASED_EXTENDED_WARRANTY_REPORT_FILTER_TYPES = [] as const;

export function isValidPurchasedExtendedWarrantyReportFilterType(
  type: ReportFilterType,
): type is (typeof PURCHASED_EXTENDED_WARRANTY_REPORT_FILTER_TYPES)[number] {
  return (
    PURCHASED_EXTENDED_WARRANTY_REPORT_FILTER_TYPES as readonly string[]
  ).includes(type);
}

export function getValidFilterTypes(
  dataType: ReportDataType,
): ReportFilterType[] {
  switch (dataType) {
    case ReportDataType.ORDERS:
      return [...ORDERS_REPORT_FILTER_TYPES];
    case ReportDataType.PRODUCTS:
      return [...PRODUCTS_REPORT_FILTER_TYPES];
    case ReportDataType.WARRANTY_CUSTOMERS:
      return [...WARRANTY_CUSTOMERS_REPORT_FILTER_TYPES];
    case ReportDataType.PURCHASED_EXTENDED_WARRANTY:
      return [...PURCHASED_EXTENDED_WARRANTY_REPORT_FILTER_TYPES];
    default:
      assertNever(dataType);
  }
}
