import { RedoMerchantClient } from "@redotech/redo-merchant-app-common/client";
import { CONVERSATIONS_FILTERS_VERSION } from "@redotech/redo-model/conversation-filters/conversation-filters";
import { CreateViewBody } from "@redotech/redo-model/create-view-body";
import { UpdateViewBody } from "@redotech/redo-model/update-view-body";
import { View, viewJsonFormat } from "@redotech/redo-model/view";

export const createView = async (
  client: RedoMerchantClient,
  body: CreateViewBody,
  signal?: AbortSignal,
) => {
  const v3Header = { "x-api-version": CONVERSATIONS_FILTERS_VERSION };
  try {
    const response = await client.client.post("team/view", body, {
      headers: { ...v3Header, ...client.authorization() },
      signal,
    });
    return viewJsonFormat.read(response.data);
  } catch (error: any) {
    if (error.response && error.response.status === 409) {
      throw new Error("NAME_CONFLICT");
    }
    throw error;
  }
};

export const updateView = async (
  client: RedoMerchantClient,
  { viewId, body }: { body: UpdateViewBody; viewId: string },
  signal?: AbortSignal,
) => {
  const v3Header = { "x-api-version": CONVERSATIONS_FILTERS_VERSION };
  try {
    const response = await client.client.put(`team/view/${viewId}`, body, {
      headers: { ...v3Header, ...client.authorization() },
      signal,
    });
    const updatedView = response.data;
    return viewJsonFormat.read(updatedView);
  } catch (error: any) {
    if (error.response && error.response.status === 409) {
      throw new Error("NAME_CONFLICT");
    }
    throw error;
  }
};

export const deleteView = async (
  client: RedoMerchantClient,
  { id, signal }: { id: string; signal?: AbortSignal },
) => {
  await client.client.delete(`team/view/${id}`, {
    headers: client.authorization(),
    signal,
  });
};

export const getTeamViews = async (
  client: RedoMerchantClient,
  { signal }: { signal: AbortSignal },
): Promise<View[]> => {
  const v3Header = { "x-api-version": CONVERSATIONS_FILTERS_VERSION };
  const response = await client.client.get("team/views", {
    headers: { ...v3Header, ...client.authorization() },
    signal,
  });

  const rawData = response.data.views;
  const views = rawData.map((view: any) => {
    return viewJsonFormat.read(view);
  });
  return views;
};

export const getUserViews = async (
  client: RedoMerchantClient,
  { signal }: { signal: AbortSignal },
): Promise<View[]> => {
  const v3Header = { "x-api-version": CONVERSATIONS_FILTERS_VERSION };
  const response = await client.client.get("user/support-private-views", {
    headers: { ...v3Header, ...client.authorization() },
    signal,
  });
  const rawData = response.data.views;
  const views = rawData.map((view: any) => {
    return viewJsonFormat.read(view);
  });
  return views;
};
