import { useOnHover } from "@redotech/react-util/hover";
import { memo, useMemo, useState } from "react";
import { Flex } from "../../../../flex";
import { Text } from "../../../../text";
import { BgColorValue } from "../../../../theme/box";
import {
  datesEqual,
  isDateInHoverRange,
  isDateInRange,
  isEndOfWeek,
  isSelectedDate,
  isStartOfWeek,
  isToday,
} from "../utils/date-picker-utils";

interface CalendarDayProps {
  date: Temporal.PlainDate;
  isCurrentMonth: boolean;
  onSelect: (date: Temporal.PlainDate) => void;
  onHover: (hovered: boolean) => void;
  // Single date props
  selectedDate?: Temporal.PlainDate | null;
  // Range date props
  rangeStartDate?: Temporal.PlainDate | null;
  rangeEndDate?: Temporal.PlainDate | null;
  hoveredDate?: Temporal.PlainDate | null;
  isRangeMode?: boolean;
  isRangeSelecting?: boolean;
  isDisabled?: boolean; // Date is outside the allowed min/max range
}

export const CalendarDay = memo(function CalendarDay({
  date,
  isCurrentMonth,
  onSelect,
  onHover,
  selectedDate,
  rangeStartDate,
  rangeEndDate,
  hoveredDate,
  isRangeMode = false,
  isRangeSelecting = false,
  isDisabled = false,
}: CalendarDayProps) {
  const today = isToday(date);

  const isRangeStart =
    rangeStartDate !== null && datesEqual(date, rangeStartDate);
  const isRangeEnd = rangeEndDate !== null && datesEqual(date, rangeEndDate);

  const selected = (() => {
    if (isRangeMode) {
      return isRangeStart || isRangeEnd;
    }
    return selectedDate !== null && isSelectedDate(date, selectedDate);
  })();

  const isWeekStart = isStartOfWeek(date, { startOfWeek: 1 });
  const isWeekEnd = isEndOfWeek(date, { startOfWeek: 1 });

  const [dayContainerRef, setDayContainerRef] = useState<HTMLElement | null>(
    null,
  );
  useOnHover(dayContainerRef, onHover);

  const [containerBackgroundColor, containerBorderRadius]: [
    BgColorValue | undefined,
    string | undefined,
  ] = useMemo(() => {
    if (!isRangeMode) {
      return [undefined, undefined];
    }

    const isRangeStart =
      rangeStartDate !== null && datesEqual(date, rangeStartDate);
    const isRangeEnd = rangeEndDate !== null && datesEqual(date, rangeEndDate);
    const isInRange = isDateInRange(date, rangeStartDate, rangeEndDate);

    const isInHoverRange =
      isRangeSelecting &&
      !isRangeStart &&
      !isRangeEnd &&
      isDateInHoverRange(date, rangeStartDate, hoveredDate);

    // Case 1: Selected range is complete
    if (rangeStartDate && rangeEndDate) {
      if (isRangeStart) {
        if (isWeekEnd || isRangeEnd) {
          return [undefined, undefined];
        }
        return ["disabled", BORDER_RADIUS_FILL_RIGHT];
      }
      if (isRangeEnd) {
        if (isWeekStart) {
          return [undefined, undefined];
        }
        return ["disabled", BORDER_RADIUS_FILL_LEFT];
      }
      if (isInRange) {
        if (isWeekStart) {
          return ["disabled", BORDER_RADIUS_FILL_RIGHT];
        } else if (isWeekEnd) {
          return ["disabled", BORDER_RADIUS_FILL_LEFT];
        }
        return ["disabled", BORDER_RADIUS_SQUARE];
      }
    }

    // Case 2: Hover preview while selecting range
    if (isRangeSelecting && rangeStartDate && hoveredDate) {
      const hoveredDateIsActingAsRangeStart =
        Temporal.PlainDate.compare(hoveredDate, rangeStartDate) < 0;

      const hoveredDateIsActingAsRangeEnd =
        Temporal.PlainDate.compare(hoveredDate, rangeStartDate) > 0;

      const isHoveredDate = datesEqual(date, hoveredDate);

      if (isRangeStart) {
        if (isHoveredDate) {
          return [undefined, undefined];
        }
        if (hoveredDateIsActingAsRangeEnd) {
          if (isWeekEnd) {
            return [undefined, undefined];
          }
          return ["disabled", BORDER_RADIUS_FILL_RIGHT];
        }
        if (hoveredDateIsActingAsRangeStart) {
          if (isWeekStart) {
            return [undefined, undefined];
          }
          return ["disabled", BORDER_RADIUS_FILL_LEFT];
        }
      }

      if (isHoveredDate) {
        if (hoveredDateIsActingAsRangeStart) {
          if (isWeekEnd) {
            return [undefined, undefined];
          }
          return ["disabled", BORDER_RADIUS_FILL_RIGHT];
        }
        if (hoveredDateIsActingAsRangeEnd) {
          if (isWeekStart) {
            return [undefined, undefined];
          }
          return ["disabled", BORDER_RADIUS_FILL_LEFT];
        }
      }

      if (isInHoverRange) {
        if (isWeekStart) {
          return ["disabled", BORDER_RADIUS_FILL_RIGHT]; // Border radius for week start
        } else if (isWeekEnd) {
          return ["disabled", BORDER_RADIUS_FILL_LEFT]; // Border radius for week end
        }
        return ["disabled", BORDER_RADIUS_SQUARE]; // Square for in-hover-range dates
      }
    }

    return [undefined, undefined]; // Default case
  }, [
    isRangeMode,
    rangeStartDate,
    rangeEndDate,
    hoveredDate,
    date,
    isWeekStart,
    isWeekEnd,
    isRangeSelecting,
  ]);

  return (
    <Flex
      align="center"
      as="button"
      bgColor={containerBackgroundColor}
      disabled={isDisabled}
      justify="center"
      onClick={() => onSelect(date)}
      ref={setDayContainerRef}
      style={{
        borderRadius: containerBorderRadius,
        opacity: !isCurrentMonth && isDisabled ? 0.5 : 1,
        position: "relative",
        height: "40px",
        transition: "background-color 0.2s",
      }}
    >
      <Flex
        align="center"
        bgColor={selected ? "alternate" : today ? "disabled" : undefined}
        hoverColor={selected ? "primary-alternate" : "secondary"}
        justify="center"
        position="relative"
        radius="full"
        style={{
          width: "40px",
          height: "40px",
          transition: "background-color 0.2s",
        }}
      >
        <Text
          fontSize="sm"
          fontWeight="medium"
          textColor={
            selected
              ? "primary-on-brand"
              : isDisabled
                ? "disabled"
                : "secondary"
          }
        >
          {date.day}
        </Text>

        {today && <TodayIndicatorDot isSelected={selected} />}
      </Flex>
    </Flex>
  );
});

/**
 * Small indicator dot component to mark today's date
 * It appears white when the day is selected, black otherwise
 */
const TodayIndicatorDot = memo(function TodayIndicatorDot({
  isSelected,
}: {
  isSelected: boolean;
}) {
  return (
    <Flex
      bgColor={isSelected ? "primary" : "alternate"}
      bottom="xs"
      position="absolute"
      radius="full"
      style={{ width: "5px", height: "5px" }}
    />
  );
});

const BORDER_RADIUS_FILL_LEFT = "0 999px 999px 0";
const BORDER_RADIUS_FILL_RIGHT = "999px 0 0 999px";
const BORDER_RADIUS_SQUARE = "0";
