import { CreateFulfillmentOrderSchema } from "@redotech/redo-model/fulfillments/create-fulfillment-order";
import { z } from "zod";

export const editManualFulfillmentGroups = {
  input: z.object({
    fulfillmentGroupEdits: z.array(
      z.object({
        fulfillmentGroupId: z.string(),
        data: CreateFulfillmentOrderSchema,
      }),
    ),
  }),
  output: z.object({ success: z.boolean() }),
};
