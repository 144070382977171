import { useHandler } from "@redotech/react-util/hook";
import { UserContext } from "@redotech/redo-merchant-app-common/user";
import { Step as ModelStep } from "@redotech/redo-model/return-flow";
import { Permission, permitted } from "@redotech/redo-model/user";
import { BlockLayout } from "@redotech/redo-web/flowchart";
import CheckCircle from "@redotech/redo-web/icon-old/check-circle.svg";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import Quill from "quill";
import { memo, useContext, useRef } from "react";
import { StepType, StepTypeDetailsProps } from "../return-flow/step";
import { containsImg, StepRichTextEditor } from "./step-rich-text";

export interface State {
  message: string;
  messageHtml?: string;
  customTitle?: string;
}

const Details = memo(function Details({
  state,
  setState,
}: StepTypeDetailsProps<State>) {
  const user = useContext(UserContext);
  const canEditSettings =
    !!user && permitted(user.permissions, Permission.EDIT_SETTINGS);

  const messageQuillRef = useRef<Quill | undefined>(undefined);

  const handleMessageChange = useHandler(() => {
    if (!messageQuillRef.current) {
      return;
    }
    let message = messageQuillRef.current.getText();
    let messageHtml: string | undefined =
      messageQuillRef.current.getSemanticHTML();
    if (message === "\n") {
      message = "";
    }
    if (messageHtml === "<p></p>") {
      messageHtml = undefined;
    }

    if (messageHtml !== state.messageHtml || message !== state.message) {
      setState((state) => ({ ...state, message, messageHtml }));
    }
  });

  return (
    <LabeledInput
      description="Message to show to the customer when the chat is closed"
      errors={containsImg(state.messageHtml) ? ["Cannot contain images"] : []}
      label="Message"
    >
      <StepRichTextEditor
        htmlValue={state.messageHtml}
        onTextChange={handleMessageChange}
        quillRef={messageQuillRef}
        readOnly={!canEditSettings}
        textValue={state.message}
      />
    </LabeledInput>
  );
});

export const CLOSE_CHAT: StepType<State, ModelStep.CloseChat> = {
  Details,
  customTitle(state) {
    return state.customTitle;
  },
  description(state: State) {
    return state.message;
  },
  downstream() {
    return [];
  },
  empty: { message: "", messageHtml: undefined },
  layout() {
    return BlockLayout.FULL;
  },
  stepDeleted(state) {
    return state;
  },
  title: "Close chat",
  valid(state) {
    return !!state.message && !containsImg(state.messageHtml);
  },
  fromModel(model: ModelStep.CloseChat) {
    return {
      customTitle: model.customTitle,
      message: model.message,
      messageHtml: model.messageHtml,
    };
  },
  toModel(state: State) {
    return {
      customTitle: state.customTitle,
      type: ModelStep.CLOSE_CHAT,
      message: state.message,
      messageHtml: state.messageHtml,
    };
  },
  Icon: CheckCircle,
};
