import * as classNames from "classnames";
import { memo, useEffect, useState } from "react";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { OptionalTooltip } from "../../tooltip/optional-tooltip";
import * as progressBarCss from "./redo-progress-bar.module.css";

export const redoProgressBarStyle = ["thick", "thin"];
export type RedoProgressBarStyle = (typeof redoProgressBarStyle)[number];

export const redoProgressBarLabelOrientation = [
  "left",
  "right",
  "top",
  "bottom",
];
export type RedoProgressBarLabelOrientation =
  (typeof redoProgressBarLabelOrientation)[number];

// Person who first uses the 'float' style -- it was unclear if we wanted to
// account for the float in the height. If it turns out we don't, we should
// update it to have absolute positioning
export const labelStyle = ["tooltip", "text", "float"];
export type RedoProgressBarLabelStyle = (typeof labelStyle)[number];

export interface RedoProgressBarProps {
  percentage: number;
  style?: RedoProgressBarStyle;
  label?: boolean;
  labelOrientation?: RedoProgressBarLabelOrientation;
  labelStyle?: RedoProgressBarLabelStyle;
}

export const RedoProgressBar = memo(function RedoProgressBar({
  percentage,
  style = "thick",
  labelOrientation = "right",
  labelStyle = "tooltip",
}: RedoProgressBarProps) {
  const boundedRegularPercentage = getBoundedPercentage(percentage);

  const boundedOverflowPercentage = getBoundedOverflowPercentage(percentage);

  const [progressBarContainer, setProgressBarContainer] =
    useState<HTMLDivElement | null>(null);

  useEffect(() => {
    progressBarContainer?.style.setProperty(
      "--percentage",
      `${boundedRegularPercentage}%`,
    );
  }, [boundedRegularPercentage, progressBarContainer]);

  useEffect(() => {
    progressBarContainer?.style.setProperty(
      "--overflow-percentage",
      `${boundedOverflowPercentage}%`,
    );
  }, [boundedOverflowPercentage, progressBarContainer]);

  return (
    <OptionalTooltip
      meetsCondition={labelStyle === "tooltip"}
      tooltipProps={{ title: `${percentage}%` }}
    >
      <Flex dir="column" grow={1}>
        {labelOrientation === "top" && labelStyle === "text" && (
          <ProgressBarLabelText percentage={percentage} />
        )}
        {labelOrientation === "top" && labelStyle === "float" && (
          <ProgressBarFloatingText
            boundedOverflowPercentage={boundedOverflowPercentage}
            boundedPercentage={boundedRegularPercentage}
            percentage={percentage}
          />
        )}
        <Flex align="center">
          {labelOrientation === "left" && labelStyle === "text" && (
            <ProgressBarLabelText percentage={percentage} />
          )}
          <div
            className={classNames(
              progressBarCss.barContainer,
              styleToClass[style],
            )}
            ref={setProgressBarContainer}
          >
            <div className={classNames(progressBarCss.fillContainer)}>
              <div
                className={classNames(
                  progressBarCss.fillBar,
                  progressBarCss.regular,
                  styleToClass[style],
                )}
              />
            </div>
            <div
              className={classNames(
                progressBarCss.fillContainer,
                progressBarCss.overflowContainer,
              )}
            >
              <div
                className={classNames(
                  progressBarCss.fillBar,
                  progressBarCss.overflow,
                  styleToClass[style],
                )}
              />
            </div>
          </div>
          {labelOrientation === "right" && labelStyle === "text" && (
            <ProgressBarLabelText percentage={percentage} />
          )}
        </Flex>
        {labelOrientation === "bottom" && labelStyle === "text" && (
          <Flex justify="flex-end">
            <ProgressBarLabelText percentage={percentage} />
          </Flex>
        )}
        {labelOrientation === "bottom" && labelStyle === "float" && (
          <ProgressBarFloatingText
            boundedOverflowPercentage={boundedOverflowPercentage}
            boundedPercentage={boundedRegularPercentage}
            percentage={percentage}
          />
        )}
      </Flex>
    </OptionalTooltip>
  );
});

const styleToClass: Record<RedoProgressBarStyle, string> = {
  thick: progressBarCss.thick,
  thin: progressBarCss.thin,
};

const ProgressBarFloatingText = memo(function ProgressBarFloatingText({
  percentage,
  boundedPercentage,
  boundedOverflowPercentage,
}: {
  percentage: number;
  boundedPercentage: number;
  boundedOverflowPercentage: number;
}) {
  const offset =
    (boundedOverflowPercentage
      ? 100 - boundedOverflowPercentage
      : boundedPercentage) - 5;

  return (
    <div style={{ width: 0, position: "relative", left: `${offset}%` }}>
      <Flex>
        <Flex
          bgColor="primary-alt"
          borderColor="secondary"
          borderStyle="solid"
          borderWidth="1px"
          px="lg"
          py="md"
          radius="lg"
          shadow="lg"
        >
          <Text
            fontSize="xs"
            fontWeight="semibold"
            textColor="secondary"
          >{`${percentage}%`}</Text>
        </Flex>
      </Flex>
    </div>
  );
});

const ProgressBarLabelText = memo(function ProgressBarLabelText({
  percentage,
}: {
  percentage: number;
}) {
  return (
    <Text
      fontSize="sm"
      fontWeight="medium"
      textColor="secondary"
    >{`${percentage}%`}</Text>
  );
});

function getBoundedPercentage(percentage: number) {
  if (percentage < 0) return 0;
  if (percentage > 100) return 100;
  return percentage;
}

function getBoundedOverflowPercentage(percentage: number) {
  return Math.min(100, Math.max(0, percentage - 100));
}
