import * as dayjsImport from "dayjs";
import * as utcImport from "dayjs/plugin/utc";

const dayjs = dayjsImport;
const utc = utcImport;
dayjs.extend(utc);

interface DateParams {
  day?: number;
  month: number;
  year: number;
}

interface Holiday {
  name: string;
  alsoObservedAs?: string;
  date: Date;
  dateString: string;
}

interface ShiftOptions {
  shiftSaturdayHolidays?: boolean;
  shiftSundayHolidays?: boolean;
}

const getDateFor = ({ day = 1, month, year }: DateParams) =>
  dayjs(`${year}-${month}-${day}`, "YYYY-M-D");

const getNthDayOf = (n: number, day: number, month: number, year: number) => {
  let result = dayjs(getDateFor({ month, year })).day(day);

  if (result.month() !== month - 1) {
    result = result.add(1, "week");
  }

  result = result.add(n - 1, "week");

  return result;
};

const getLastDayOf = (day: number, month: number, year: number) => {
  const daysInMonth = dayjs(getDateFor({ month, year })).daysInMonth();
  const lastDayOfMonth = dayjs(`${year}-${month}-${daysInMonth}`, "YYYY-M-D");

  let result = lastDayOfMonth.day(day);

  if (result.month() !== month - 1) {
    result = result.subtract(1, "week");
  }

  return result;
};

export const allFederalHolidaysForYear = (
  year: number = new Date().getFullYear(),
  {
    shiftSaturdayHolidays = true,
    shiftSundayHolidays = true,
  }: ShiftOptions = {},
): Holiday[] => {
  const holidays: Array<{ name: string; alsoObservedAs?: string; date: any }> =
    [];

  // New Year's Day
  holidays.push({
    name: `New Year's Day`,
    date: getDateFor({ day: 1, month: 1, year }),
  });

  // Birthday of Martin Luther King, Jr.
  // Third Monday of January
  holidays.push({
    name: `Birthday of Martin Luther King, Jr.`,
    date: getNthDayOf(3, 1, 1, year),
  });

  // Washington's Birthday
  // Third Monday of February
  holidays.push({
    name: `Washington's Birthday`,
    alsoObservedAs: "Presidents' Day",
    date: getNthDayOf(3, 1, 2, year),
  });

  // Memorial Day
  // Last Monday of May
  holidays.push({ name: `Memorial Day`, date: getLastDayOf(1, 5, year) });

  if (year > 2020) {
    // Juneteenth
    holidays.push({
      name: `Juneteenth National Independence Day`,
      date: getDateFor({ day: 19, month: 6, year }),
    });
  }

  // Independence Day
  holidays.push({
    name: `Independence Day`,
    date: getDateFor({ day: 4, month: 7, year }),
  });

  // Labor Day
  // First Monday in September
  holidays.push({ name: `Labor Day`, date: getNthDayOf(1, 1, 9, year) });

  // Columbus Day
  // Second Monday in October
  holidays.push({
    name: `Columbus Day`,
    alsoObservedAs: "Indigenous Peoples' Day",
    date: getNthDayOf(2, 1, 10, year),
  });

  // Veterans Day
  holidays.push({
    name: `Veterans Day`,
    date: getDateFor({ day: 11, month: 11, year }),
  });

  // Thanksgiving Day
  // Fourth Thursday of November
  holidays.push({
    name: `Thanksgiving Day`,
    date: getNthDayOf(4, 4, 11, year),
  });

  // Christmas Day
  holidays.push({
    name: `Christmas Day`,
    date: getDateFor({ day: 25, month: 12, year }),
  });

  // Test Day
  // holidays.push({
  //   name: `Test Day`,
  //   date: getDateFor({ day: 21, month: 3, year }),
  // });

  // Test Day 3rd Friday in March
  // holidays.push({
  //   name: `Test nth Day of Month`,
  //   date: getNthDayOf(3, 5, 3, year),
  // });

  return holidays.map((holiday) => {
    let date = dayjs(holiday.date);

    if (date.day() === 0 && shiftSundayHolidays) {
      date = date.add(1, "day");
    }

    if (date.day() === 6 && shiftSaturdayHolidays) {
      date = date.subtract(1, "day");
    }

    return {
      name: holiday.name,
      alsoObservedAs: holiday.alsoObservedAs,
      date: date.toDate(),
      dateString: date.format("YYYY-MM-DD"),
    };
  });
};

export const isAHoliday = (
  date: Date = new Date(),
  {
    shiftSaturdayHolidays = true,
    shiftSundayHolidays = true,
    utc = false,
  }: ShiftOptions & { utc?: boolean } = {},
): boolean => {
  const newDate = utc ? dayjs.utc(date) : dayjs(date);
  const year = newDate.year();

  const shift = { shiftSaturdayHolidays, shiftSundayHolidays };

  const allForYear = allFederalHolidaysForYear(year, shift);
  const nextYear = allFederalHolidaysForYear(year + 1, shift);
  const holidays = [...allForYear];
  if (nextYear[0]) {
    holidays.push(nextYear[0]);
  }

  return holidays.some(
    (holiday) => holiday.dateString === newDate.format("YYYY-MM-DD"),
  );
};

const getOneYearFromNow = (): Date => {
  const future = new Date();
  future.setUTCFullYear(future.getUTCFullYear() + 1);
  return future;
};

export const federalHolidaysInRange = (
  startDate: Date = new Date(),
  endDate: Date = getOneYearFromNow(),
  options?: ShiftOptions,
): Holiday[] => {
  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();

  const candidates: Holiday[] = [];
  for (let year = startYear; year <= endYear; year += 1) {
    candidates.push(...allFederalHolidaysForYear(year, options));
  }
  return candidates.filter((h) => h.date >= startDate && h.date <= endDate);
};
