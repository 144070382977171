import MuiModal from "@mui/material/Modal";
import { useAnimateHeight } from "@redotech/react-animation/transition";
import * as classNames from "classnames";
import {
  HTMLAttributes,
  JSXElementConstructor,
  ReactNode,
  memo,
  useContext,
  useEffect,
  useState,
} from "react";
import { IconButton } from "./button";
import CloseIcon from "./icon-old/modal-close-button.svg";
import { ModalRootContext } from "./modal-root";
import * as modalCss from "./modal.module.css";

/**
 * @deprecated use RedoModal/RedoBaseModal instead
 */
export const ModalContainer = memo(function ModalContainer({
  children,
  ...attributes
}: { children: ReactNode | ReactNode[] } & HTMLAttributes<HTMLElement>) {
  const modalRoot = useContext(ModalRootContext);
  const [ref, setRef] = useState(modalRoot);
  return (
    <ModalRootContext.Provider value={ref}>
      {children}
      <div ref={setRef as any} {...attributes} />
    </ModalRootContext.Provider>
  );
});

export type ModalSize =
  | typeof ModalSize.SKINNY
  | typeof ModalSize.SMALL
  | typeof ModalSize.MEDIUM
  | typeof ModalSize.LARGE
  | typeof ModalSize.FLEXIBLE;

export namespace ModalSize {
  export const SKINNY = Symbol("Skinny");
  export const SMALL = Symbol("Small");
  export const MEDIUM = Symbol("Medium");
  export const LARGE = Symbol("Large");
  export const FLEXIBLE = Symbol("Flexible");
}

const modalSizeClasses = {
  [ModalSize.SKINNY]: modalCss.skinny,
  [ModalSize.SMALL]: modalCss.small,
  [ModalSize.MEDIUM]: modalCss.medium,
  [ModalSize.LARGE]: undefined,
  [ModalSize.FLEXIBLE]: modalCss.flexible,
};

export type PaddingAmount =
  | typeof PaddingAmount.NONE
  | typeof PaddingAmount.SMALL
  | typeof PaddingAmount.MEDIUM
  | typeof PaddingAmount.LARGE;

export namespace PaddingAmount {
  export const NONE = Symbol("None");
  /** Corresponds to "md" in Arbiter */
  export const SMALL = Symbol("Small");
  /** Corresponds to "lg" in Arbiter */
  export const MEDIUM = Symbol("Medium");
  export const LARGE = Symbol("Large");
}

export type HeaderStyle =
  | typeof HeaderStyle.DEFAULT
  | typeof HeaderStyle.MEDIUM;

export namespace HeaderStyle {
  export const DEFAULT = Symbol("Default");
  export const MEDIUM = Symbol("Medium");
}

const headerStyleClasses = {
  [HeaderStyle.DEFAULT]: modalCss.headerDefault,
  [HeaderStyle.MEDIUM]: modalCss.headerMedium,
};

const paddingAmountClasses = {
  [PaddingAmount.NONE]: modalCss.noPadding,
  [PaddingAmount.SMALL]: modalCss.smallPadding,
  [PaddingAmount.MEDIUM]: modalCss.mediumPadding,
  [PaddingAmount.LARGE]: modalCss.largePadding,
};

export interface ModalProps {
  children: ReactNode;
  open: boolean;
  onClose?(): void;
  size?: ModalSize;
  paddingAmount?: PaddingAmount;
  footer?: ReactNode | ReactNode[];
  TitleIcon?: JSXElementConstructor<unknown>;
  title: ReactNode;
  subtitle?: ReactNode;
  hideCloseButton?: boolean;
  showHeader?: boolean;
  showHeaderBorder?: boolean;
  showFooterBorder?: boolean;
  headerStyle?: HeaderStyle;
  className?: string;
  centered?: boolean;
  container?: HTMLElement;
  slotProps?: any;
}

/**
 * @deprecated use RedoModal instead
 */
export const Modal = memo(function Modal({
  children,
  open,
  footer,
  onClose,
  size = ModalSize.MEDIUM,
  paddingAmount = PaddingAmount.MEDIUM,
  TitleIcon,
  title,
  subtitle,
  hideCloseButton = false,
  showHeader = true,
  showHeaderBorder = true,
  showFooterBorder = false,
  headerStyle = HeaderStyle.DEFAULT,
  className,
  centered = false,
  container,
  slotProps,
}: ModalProps) {
  const [element, setElement] = useState<HTMLElement | null>(null);
  const [properties] = useAnimateHeight(element);

  useEffect(() => {
    // Blocks background scrolling while modal is open
    document.documentElement.style.overflow = open ? "hidden" : "";
    return () => {
      document.documentElement.style.overflow = "";
    };
  }, [open]);

  const headerClassname = [modalCss.header];
  if (showHeaderBorder) {
    headerClassname.push(modalCss.headerBorder);
  }
  if (paddingAmount === PaddingAmount.NONE) {
    headerClassname.push(modalCss.headerPadding);
  }

  const footerClassname = [modalCss.footer];
  if (showFooterBorder) {
    footerClassname.push(modalCss.footerBorder);
  }

  return (
    <MuiModal
      className={modalCss.modalContainer}
      container={container}
      onClose={onClose}
      open={open}
      slotProps={slotProps}
    >
      <article
        className={classNames(
          modalCss.modal,
          modalSizeClasses[size],
          paddingAmountClasses[paddingAmount],
          className,
          centered ? modalCss.centered : undefined,
        )}
        ref={setElement}
        style={properties}
      >
        {showHeader && (
          <header className={classNames(headerClassname, modalCss.title)}>
            <div>
              <div className={modalCss.iconAndTitle}>
                {TitleIcon && <TitleIcon />}
                <h1 className={headerStyleClasses[headerStyle]}>{title}</h1>
              </div>
              {subtitle && <div className={modalCss.subtitle}>{subtitle}</div>}
            </div>

            {!hideCloseButton && (
              <div>
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </div>
            )}
          </header>
        )}
        {children}
        {footer && (
          <footer className={classNames(footerClassname)}>{footer}</footer>
        )}
      </article>
    </MuiModal>
  );
});
