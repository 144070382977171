import type { RedoReviewsRpcClient } from "@redotech/merchant-sdk/reviews-rpc/client";
import { createRedoReviewsRpcClient } from "@redotech/merchant-sdk/reviews-rpc/client";
import { Context, createContext, ReactNode, useMemo } from "react";

export const RedoReviewsRpcClientContext: Context<
  RedoReviewsRpcClient | undefined
> = createContext<RedoReviewsRpcClient | undefined>(undefined);
RedoReviewsRpcClientContext.displayName = "RedoReviewsRpcClientContext";

export function RedoReviewsRpcClientProvider({
  children,
  baseUrl,
  authToken,
}: {
  children: ReactNode | ReactNode[];
  baseUrl: string;
  authToken?: string;
}) {
  const redoReviewsRpcClient = useMemo(() => {
    if (!authToken) {
      return undefined;
    }
    return createRedoReviewsRpcClient({
      baseURL: new URL(`${baseUrl}/reviews-rpc`),
      headers: { Authorization: authToken },
    });
  }, [baseUrl, authToken]);
  return (
    <RedoReviewsRpcClientContext.Provider value={redoReviewsRpcClient}>
      {children}
    </RedoReviewsRpcClientContext.Provider>
  );
}
