import { useRequiredContext } from "@redotech/react-util/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { RedoSecondaryNavItem } from "@redotech/redo-web/arbiter-components/navigation/secondary/redo-secondary-nav-item";
import PinIcon from "@redotech/redo-web/arbiter-icon/marker-pin-02.svg";
import PinIconFilled from "@redotech/redo-web/arbiter-icon/marker-pin-02_filled.svg";
import { memo } from "react";
import { matchPath, useLocation } from "react-router-dom";

export const OrderTrackingItems = memo(function OrderTrackingItems({
  marketingRoot,
}: {
  marketingRoot: string;
}) {
  const team = useRequiredContext(TeamContext);
  const urlPrefix = `${marketingRoot}/tracking`;

  const orderTrackingEnabled = team?.settings.orderTracking?.enabled;

  const location = useLocation();
  const anyChildSelected = !!matchPath(`${urlPrefix}/*`, location.pathname);

  const viewedAboutTrackingPage = localStorage.getItem(
    "viewed_about_tracking_page",
  );

  if (!orderTrackingEnabled) {
    return (
      <RedoSecondaryNavItem
        action={{ type: "link", href: `${urlPrefix}/about` }}
        FilledIcon={PinIconFilled}
        friendlyName="Tracking"
        id="order-tracking"
        isNew={!viewedAboutTrackingPage}
        UnfilledIcon={PinIcon}
      />
    );
  } else {
    return (
      <RedoSecondaryNavItem
        action={{ type: "link", href: `${urlPrefix}/discount-flow` }}
        anyChildSelected={anyChildSelected}
        FilledIcon={PinIconFilled}
        friendlyName="Tracking"
        id="order-tracking"
        UnfilledIcon={PinIcon}
      >
        <RedoSecondaryNavItem
          action={{ type: "link", href: `${urlPrefix}/discount-flow` }}
          friendlyName="Discount flow"
          id="discount-flow"
        />
        <RedoSecondaryNavItem
          action={{ type: "link", href: `${urlPrefix}/tracking-page` }}
          friendlyName="Tracking page"
          id="tracking-page"
        />
      </RedoSecondaryNavItem>
    );
  }
});
