import { isDef } from "@redotech/util/checks";
import * as classNames from "classnames";
import { CSSProperties } from "react";
import * as skeletonCss from "./skeleton.module.css";

export function SkeletonBlock() {
  return <div className={skeletonCss.skeleton} />;
}

export function SkeletonAnimatedBlock() {
  return <div className={skeletonCss.animatedSkeletonBlock} />;
}

export function SkeletonText({
  length,
}: {
  length: number;
  animated?: boolean;
}) {
  return <span className={skeletonCss.skeletonText}>{"_".repeat(length)}</span>;
}

export function AnimatedSkeletonBlock({
  height,
  width,
  className,
}: {
  height?: string;
  width?: string;
  className?: string;
}) {
  let style: CSSProperties | undefined;
  if (isDef(width)) {
    style = { ...(style ?? {}), width };
  }
  if (isDef(height)) {
    style = { ...(style ?? {}), height };
  }

  return (
    <span
      className={classNames(skeletonCss.skeletonText, className)}
      style={style}
    >
      _
    </span>
  );
}
