import { useHandler } from "@redotech/react-util/hook";
import * as classnames from "classnames";
import { memo, useState } from "react";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { RedoButton } from "../buttons/redo-button";
import * as styles from "./redo-walkthrough.module.css";

export interface WalkthroughStep {
  title: string;
  description: string;
  image: string;
  hideBackButton?: boolean;
}

export interface RedoWalkthroughProps {
  steps: WalkthroughStep[];
  onComplete?: () => void;
  onNext?: (currentStep: number, nextStep: number) => void;
  onBack?: (currentStep: number, prevStep: number) => void;
  nextText?: string;
  backText?: string;
  finishText?: string;
}

export const RedoWalkthrough = memo(function RedoWalkthrough({
  steps,
  onComplete,
  onNext,
  onBack,
  nextText = "Next",
  backText = "Back",
  finishText = "Done",
}: RedoWalkthroughProps) {
  const [currentStep, setCurrentStep] = useState(0);

  const handleNext = useHandler(() => {
    if (currentStep < steps.length - 1) {
      const nextStep = currentStep + 1;
      setCurrentStep(nextStep);
      onNext?.(currentStep, nextStep);
    } else {
      onComplete?.();
    }
  });

  const handleBack = useHandler(() => {
    if (currentStep > 0) {
      const prevStep = currentStep - 1;
      setCurrentStep(prevStep);
      onBack?.(currentStep, prevStep);
    }
  });

  const step = steps[currentStep];

  const isFinalStep = currentStep === steps.length - 1;

  const showBackButton = !step.hideBackButton;

  return (
    <Flex
      align="center"
      bgColor="primary"
      borderColor="primary"
      borderStyle="solid"
      borderWidth="1px"
      className={classnames(styles.walkthrough)}
      dir="column"
      gap="2xl"
      p="3xl"
      radius="md"
      shadow="lg"
    >
      <img alt={step.title} src={step.image} />

      <Flex align="center" dir="column" gap="xs">
        <Text fontSize="lg" fontWeight="semibold" textColor="primary">
          {step.title}
        </Text>
        <Text fontSize="sm" fontWeight="regular" textColor="secondary">
          {step.description}
        </Text>
      </Flex>

      {steps.length > 1 && (
        <Flex align="center" gap="xl" justify="center">
          {steps.map((_, index) => (
            <div
              className={classnames(
                styles.dot,
                index === currentStep && styles.active,
              )}
              key={index}
            />
          ))}
        </Flex>
      )}

      <Flex gap="md" justify="space-between" w="full">
        {showBackButton && (
          <RedoButton
            hierarchy="secondary"
            onClick={handleBack}
            size="sm"
            text={backText}
          />
        )}
        <RedoButton
          hierarchy="primary"
          onClick={handleNext}
          size="sm"
          text={isFinalStep ? finishText : nextText}
        />
      </Flex>
    </Flex>
  );
});
