import { z } from "zod";

export const getReturnOrderNotes = {
  input: z.object({ returnId: z.string() }),
  output: z.array(
    z.object({ orderName: z.string(), orderNote: z.string().nullish() }),
  ),
};

export type GetReturnOrderNotesOutput = z.infer<
  typeof getReturnOrderNotes.output
>;
