import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import ArrowUpRightSvg from "@redotech/redo-web/arbiter-icon/arrow-up-right_filled.svg";

import { memo } from "react";
import { Tooltip } from "./tooltip/tooltip";
export const ExternalLinkButton = memo(function ExternalLinkButton({
  url,
  placement = "top",
}: {
  url: string;
  placement?: Parameters<typeof Tooltip>[0]["placement"];
}) {
  return (
    <Tooltip placement={placement} title={`Click to open ${url}`}>
      <span>
        <RedoButton
          hierarchy="tertiary"
          IconLeading={ArrowUpRightSvg}
          onClick={() => {
            window.open(url, "_blank");
          }}
          size="xs"
        />
      </span>
    </Tooltip>
  );
});
