import { memo, useMemo, useState } from "react";
import { Flex } from "../../../../flex";
import { RedoList, RedoListItem } from "../../../list/redo-list";
import { DateVariant } from "../utils/date-picker-utils";

interface DateOptionsPanelProps {
  onVariantSelect: (variant: DateVariant) => void;
  allowNoDateOption?: boolean;
}

interface DateOption {
  label: string;
  variant: DateVariant;
}

/**
 * Get date options for quick selection
 */
const dateOptions: DateOption[] = [
  { label: "No Date", variant: "no-date" },
  { label: "Today", variant: "today" },
  { label: "Yesterday", variant: "yesterday" },
  { label: "This Week", variant: "this-week" },
  { label: "Last Week", variant: "last-week" },
  { label: "This Month", variant: "this-month" },
  { label: "Last Month", variant: "last-month" },
  { label: "This Year", variant: "this-year" },
  { label: "Last Year", variant: "last-year" },
];

export const DateOptionsPanel = memo(function DateOptionsPanel({
  onVariantSelect,
  allowNoDateOption,
}: DateOptionsPanelProps) {
  const [focusedIndex, setFocusedIndex] = useState<number | undefined>(
    undefined,
  );

  // Convert date options to RedoList items
  const listItems: RedoListItem<DateOption>[] = useMemo(() => {
    return dateOptions
      .filter((option) => allowNoDateOption || option.variant !== "no-date")
      .map((option, index) => {
        return {
          id: `date-option-${index}`,
          type: "text",
          text: option.label,
          value: option,
        };
      });
  }, [allowNoDateOption]);

  // Handle item selection
  const itemSelected = useMemo(() => {
    return ({ item }: { item: RedoListItem<DateOption> }) => {
      const selectedOption = item.value;
      if (selectedOption) {
        onVariantSelect(selectedOption.variant);
      }
    };
  }, [onVariantSelect]);

  return (
    <Flex
      borderColor="secondary"
      borderRightWidth="1px"
      borderStyle="solid"
      dir="column"
      p="md"
      style={{ minWidth: "160px" }}
    >
      <Flex>
        <RedoList
          card={false}
          focusedIndex={focusedIndex}
          items={listItems}
          itemSelected={itemSelected}
          refToListenTo={null}
          selectionVariant="none"
          setFocusedIndex={setFocusedIndex}
          size="lg"
        />
      </Flex>
    </Flex>
  );
});
