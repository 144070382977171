import { useHandler } from "@redotech/react-util/hook";
import { sinkValue } from "@redotech/util/type";
import { ForwardedRef, forwardRef, memo, useEffect, useState } from "react";
import { Flex } from "../../flex";
import { BaseRedoInputContainer } from "./base-redo-input-container";
import {
  BaseRedoInput,
  RedoInputSize,
  RedoInputState,
} from "./base-redo-text-input";
import { BaseRedoInputInfoTooltip } from "./base-redo-text-input-info-tooltip";

export interface RedoDateInputProps {
  value: Temporal.PlainDate | null;
  setValue(value: Temporal.PlainDate | null): void;
  size?: RedoInputSize;
  state?: RedoInputState;
  label?: string;
  description?: string;
  infoTooltip?: string;
  required?: boolean;
  className?: string;
  fullWidth?: boolean;
  autoFocus?: boolean;
  dangerousStyleThatShouldOnlyBeUsedForMerchantBranding?: React.CSSProperties;
  name?: string;
}

export const RedoDateInput = memo(
  forwardRef(function RedoDateInput(
    {
      size = "sm",
      value,
      setValue,
      infoTooltip,
      label,
      description,
      state = "default",
      className,
      required,
      fullWidth,
      autoFocus,
      dangerousStyleThatShouldOnlyBeUsedForMerchantBranding,
      name,
    }: RedoDateInputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) {
    const [inputText, setInputText] = useState<string>(formatDate(value));

    useEffect(() => {
      setInputText(formatDate(value));
    }, [value]);

    const parseInputDate = useHandler((input: string) => {
      if (!input.trim()) {
        setValue(null);
        setInputText(input);
        return;
      }

      const mmddyyyyRegex = /^(\d{1,2})-(\d{1,2})-(\d{4,})$/;
      const match = input.match(mmddyyyyRegex);

      if (match) {
        const month = match[1];
        const day = match[2];
        const year = match[3];

        const numericYear = Number(year);
        const maxYear = Math.min(numericYear, 9999);

        try {
          const plainDate = Temporal.PlainDate.from(
            `${maxYear}-${month}-${day}`,
          );
          setValue(plainDate);
        } catch (e) {
          sinkValue(e);
        }
      }

      const ignoreAutoComplete = input.length < inputText.length;

      if (ignoreAutoComplete) {
        setInputText(input);
        return;
      }

      const monthOnlyRegex = /^(\d{1,2})$/;
      const monthMatch = input.match(monthOnlyRegex);
      if (monthMatch) {
        const numericMonth = Number(monthMatch[1]);
        const maxMonth = Math.min(numericMonth, 12);
        const monthStr = maxMonth.toString().padStart(2, "0");
        if (maxMonth > 1) {
          setInputText(`${monthStr}-`);
          return;
        }
      }

      const monthDayRegex = /^(\d{1,2})-(\d{1,2})$/;
      const monthDayMatch = input.match(monthDayRegex);
      if (monthDayMatch) {
        const numericMonth = Number(monthDayMatch[1]);
        const maxMonth = Math.min(numericMonth, 12);
        const monthStr = maxMonth.toString().padStart(2, "0");
        const temporalMonth = Temporal.PlainYearMonth.from({
          month: maxMonth,
          year: 2000,
        });

        const numericDay = Number(monthDayMatch[2]);
        const maxDay = Math.min(numericDay, temporalMonth.daysInMonth);

        const dayStr = maxDay.toString().padStart(2, "0");
        if (maxDay > 3) {
          setInputText(`${monthStr}-${dayStr}-`);
          return;
        }
      }

      setInputText(input);
    });

    return (
      <BaseRedoInputContainer
        className={className}
        dangerousStyleThatShouldOnlyBeUsedForMerchantBranding={
          dangerousStyleThatShouldOnlyBeUsedForMerchantBranding
        }
        description={description}
        fullWidth={fullWidth}
        label={label}
        size={size}
        state={state}
      >
        <Flex align="center" dir="row" grow={1} maxW="full">
          <BaseRedoInput
            autoFocus={autoFocus}
            name={name}
            placeholder="MM-DD-YYYY"
            ref={ref}
            required={required}
            setValue={parseInputDate}
            size={size}
            state={state}
            value={inputText}
          />
          {infoTooltip && (
            <BaseRedoInputInfoTooltip
              infoTooltip={infoTooltip}
              size={size}
              state={state}
            />
          )}
        </Flex>
      </BaseRedoInputContainer>
    );
  }),
);

function formatDate(date: Temporal.PlainDate | null): string {
  if (!date) {
    return "";
  }

  const year = date.toLocaleString("en-US", { year: "numeric" });
  const month = date.toLocaleString("en-US", { month: "2-digit" });
  const day = date.toLocaleString("en-US", { day: "2-digit" });
  return `${month}-${day}-${year}`;
}
