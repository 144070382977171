import { packingSlipSchema } from "@redotech/redo-model/packing-slips/packing-slip-definition";
import { z } from "zod";

export const createPackingSlipTemplate = {
  input: z.object({
    teamId: z.string(),
    name: z.string(),
    copyFromTemplateId: z.string().optional(),
  }),
  output: packingSlipSchema,
};
