import { FulfillmentGroupStatus } from "@redotech/redo-model/fulfillments/fulfillment-group-status";
import { ShopifyFulfillmentHoldReason } from "@redotech/redo-model/fulfillments/shopify-fulfillment-schemas";
import { z } from "zod";

const attributesForNewFulfillmentGroupsSchema = z.object({
  internalNote: z.string().nullish(),
  status: z.nativeEnum(FulfillmentGroupStatus),
  holdDetails: z
    .object({
      onHoldUntil: z.date().nullish(),
      reason: z.nativeEnum(ShopifyFulfillmentHoldReason),
    })
    .nullish(),
});
export type AttributesForNewFulfillmentGroups = z.infer<
  typeof attributesForNewFulfillmentGroupsSchema
>;

export const splitFulfillmentGroup = {
  input: z.object({
    originalGroupId: z.string(),
    itemsToSplit: z.array(
      z.object({
        fulfillmentOrderLineItemId: z.string(),
        quantity: z.number(),
      }),
    ),
    attributesForNewFulfillmentGroups:
      attributesForNewFulfillmentGroupsSchema.nullish(),
  }),
  output: z.object({ success: z.boolean() }),
};
