import { z } from "zod";

import { SortDirection } from "@redotech/redo-model/tables/table";

const CountSchema = z.object({ count: z.number() });
const ValueSchema = z.object({ value: z.number() });

export const ReturnReasonSchema = z.object({
  reason: z.string().nullable(),
  count: z.number(),
});
export type ReturnReason = z.infer<typeof ReturnReasonSchema>;

export const ProductSchema = z.object({
  id: z.string(),
  item: z.object({ image: z.any(), name: z.string() }),
  returned: CountSchema,
  sold: CountSchema,
  returnRevenue: ValueSchema,
  returnRate: ValueSchema,
  exchangeRate: ValueSchema,
  returnReasons: z.array(ReturnReasonSchema).nullish(),
});
export type Product = z.infer<typeof ProductSchema>;

export enum ProductValue {
  Item = "item",
  Returned = "returned",
  Sold = "sold",
  ReturnRevenue = "returnRevenue",
  ReturnRate = "returnRate",
  ExchangeRate = "exchangeRate",
}

export enum ProductSortKey {
  Item = ProductValue.Item,
  Returned = ProductValue.Returned,
  Sold = ProductValue.Sold,
  ReturnRevenue = ProductValue.ReturnRevenue,
  ReturnRate = ProductValue.ReturnRate,
  ExchangeRate = ProductValue.ExchangeRate,
}

export const ProductSortSchema = z.object({
  key: z.nativeEnum(ProductSortKey),
  direction: z.nativeEnum(SortDirection),
});

export type ProductSort = z.infer<typeof ProductSortSchema>;

export const VariantSchema = z.object({
  id: z.string(),
  productId: z.string(),
  item: z.object({ image: z.any(), name: z.string(), variant: z.string() }),
  returned: CountSchema,
  sold: CountSchema,
  returnRevenue: ValueSchema,
  returnRate: ValueSchema,
  exchangeRate: ValueSchema,
  returnReasons: z.array(ReturnReasonSchema).optional(),
});
export type Variant = z.infer<typeof VariantSchema>;

export enum VariantValue {
  VariantTitle = "variantTitle",
  Returned = "returned",
  Sold = "sold",
  ReturnRevenue = "returnRevenue",
  ReturnRate = "returnRate",
  ExchangeRate = "exchangeRate",
}

export enum VariantSortKey {
  VariantTitle = VariantValue.VariantTitle,
  Returned = VariantValue.Returned,
  Sold = VariantValue.Sold,
  ReturnRevenue = VariantValue.ReturnRevenue,
  ReturnRate = VariantValue.ReturnRate,
  ExchangeRate = VariantValue.ExchangeRate,
}

export const VariantSortSchema = z.object({
  key: z.nativeEnum(VariantSortKey),
  direction: z.nativeEnum(SortDirection),
});
export type VariantSort = z.infer<typeof VariantSortSchema>;
