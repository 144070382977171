import { z } from "zod";
import { zExt } from "../common/zod-util";
import { MarketingSuppressionReason } from "./marketing-suppression/marketing-suppression-zod-schema";

export const audienceMemberSchema = z.object({
  name: z.string(),
  customerId: zExt.objectId(),
  email: z.string().optional(),
  phoneNumber: z.string().optional(),
  emailMarketingSubscribed: z.boolean(),
  emailSuppressionReasons: z.array(z.nativeEnum(MarketingSuppressionReason)),
  smsMarketingSubscribed: z.boolean(),
  smsSuppressionReasons: z.array(z.nativeEnum(MarketingSuppressionReason)),
});

export type AudienceMember = z.infer<typeof audienceMemberSchema>;
