import { PillTheme } from "../pill-theme";

export enum CustomerTagSource {
  REDO_SUPPORT = "redo-support",
  SHOPIFY = "shopify",
}

export interface CustomerTagRecord {
  _id: string;
  teamId: string;
  name: string;
  pillTheme: PillTheme;
  source: CustomerTagSource;
}
