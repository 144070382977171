import { RedoMerchantClient } from "@redotech/redo-merchant-app-common/client";

export interface AuthTeam {
  authToken: string;
  user: any;
}

export interface AvailableStoreInfo {
  teamId: string;
  token: string;
  url: string;
  logoUrl?: string;
  name: string;
  connected: boolean;
}

export interface ConnectedStoreInfo {
  teamId: string;
  name: string;
  logoUrl?: string;
  active?: boolean;
}

/**
 * POST /auth/team
 */
export async function createAuthTeam(
  client: RedoMerchantClient,
  { teamId, signal }: { teamId: string; signal?: AbortSignal },
): Promise<AuthTeam> {
  const response = await client.client.post(
    "/auth/team",
    {},
    { params: { teamId }, signal },
  );
  return response.data;
}

export interface SendAuthEmailResponse {}

/**
 * POST /auth/email
 */
export const sendAuthEmail = async (
  client: RedoMerchantClient,
  { email, signal }: { email: string; signal?: AbortSignal },
): Promise<SendAuthEmailResponse> => {
  const response = await client.client.post(
    "/auth/email",
    { email },
    { signal },
  );
  return response.data;
};

export interface SendAddStoreEmailResponse {}

/**
 * POST /auth/addStoreEmail
 */
export const sendAddStoreEmail = async (
  client: RedoMerchantClient,
  {
    email,
    baseStoreId,
    signal,
  }: { email: string; baseStoreId: string; signal?: AbortSignal },
): Promise<SendAddStoreEmailResponse> => {
  const response = await client.client.post(
    "/auth/addStoreEmail",
    { email, baseStoreId },
    { signal },
  );
  return response.data;
};

/**
 * GET /auth/tokenlogin
 */
export const getLongTermTokens = async (
  client: RedoMerchantClient,
  { temporaryToken, signal }: { temporaryToken: string; signal?: AbortSignal },
): Promise<string[]> => {
  const response = await client.client.get(`/auth/tokenlogin`, {
    params: { email_token: temporaryToken },
    signal,
  });
  const tokens = response.data.tokens;
  return tokens;
};

/**
 * GET /auth/connectedStores
 */
export const getAvailableStoresToConnect = async (
  client: RedoMerchantClient,
  {
    temporaryLoginToken,
    baseStoreId,
    signal,
  }: { temporaryLoginToken: string; baseStoreId: string; signal?: AbortSignal },
): Promise<{ availableStores: AvailableStoreInfo[]; storeLimit: number }> => {
  const response = await client.client.get(`/auth/connectedStores`, {
    params: { email_token: temporaryLoginToken, base_store_id: baseStoreId },
    signal,
  });
  return response.data;
};

/**
 * PUT /auth/connectedStores
 */
export const saveConnectedStores = async (
  client: RedoMerchantClient,
  {
    temporaryLoginToken,
    baseStoreId,
    storeIds,
    signal,
  }: {
    temporaryLoginToken: string;
    baseStoreId: string;
    storeIds: string[];
    signal?: AbortSignal;
  },
) => {
  const response = await client.client.put(
    `/auth/connectedStores`,
    { storeIds, emailToken: temporaryLoginToken, baseStoreId },
    { signal },
  );
  return response.data;
};

/**
 * GET /auth/connectedStoresInfo
 */
export const getConnectedStores = async (
  client: RedoMerchantClient,
  { signal }: { signal?: AbortSignal },
): Promise<ConnectedStoreInfo[]> => {
  const response = await client.client.get(`/auth/connectedStoresInfo`, {
    headers: client.authorization(),
    signal,
  });
  return response.data;
};
