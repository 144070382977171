import { useRequiredContext } from "@redotech/react-util/context";
import { LoadState, useLoad } from "@redotech/react-util/load";
import { RedoMerchantRpcClientContext } from "@redotech/redo-merchant-app-common/rpc-client";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { RedoSecondaryNavItem } from "@redotech/redo-web/arbiter-components/navigation/secondary/redo-secondary-nav-item";
import CursorClickIcon from "@redotech/redo-web/arbiter-icon/cursor-click-01.svg";
import CursorClickFilledIcon from "@redotech/redo-web/arbiter-icon/cursor-click-01_filled.svg";
import { memo } from "react";

export const OneClickUpsellItems = memo(function OneClickUpsellItems({
  marketingRoot,
}: {
  marketingRoot: string;
}) {
  const team = useRequiredContext(TeamContext);
  const redoMerchantRpcClient = useRequiredContext(
    RedoMerchantRpcClientContext,
  );

  const urlPrefix = `${marketingRoot}/one-click-upsell`;

  const oneClickUpsellEnabled: LoadState<boolean> = useLoad(async () => {
    const upsellPage =
      await redoMerchantRpcClient.getActiveUpsellPageForTeam(null);
    const postPurchaseUpsellEnabled =
      team.settings?.orderTracking?.postPurchaseUpsell?.enabled ?? false;
    return upsellPage !== null || postPurchaseUpsellEnabled;
  }, [redoMerchantRpcClient, team.settings?.orderTracking?.postPurchaseUpsell]);

  const viewedOneClickUpsellPage = localStorage.getItem(
    "viewed_one_click_upsell_page",
  );

  const aboutLink = `${urlPrefix}/about`;
  const link = oneClickUpsellEnabled.value
    ? `${urlPrefix}/upsell-page`
    : aboutLink;

  return (
    <RedoSecondaryNavItem
      action={{ type: "link", href: link }}
      FilledIcon={CursorClickFilledIcon}
      friendlyName="One-click upsell"
      id="one-click-upsell"
      isNew={!viewedOneClickUpsellPage}
      UnfilledIcon={CursorClickIcon}
    />
  );
});
