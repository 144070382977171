import { memo, useMemo } from "react";
import { Flex } from "../../../../flex";
import { generateCalendarDays } from "../utils/date-picker-utils";
import { CalendarDay } from "./calendar-day";

interface CalendarGridProps {
  currentMonth: Temporal.PlainYearMonth;
  onDateSelect: (date: Temporal.PlainDate) => void;
  selectedDate?: Temporal.PlainDate | null;
  rangeStartDate?: Temporal.PlainDate | null;
  rangeEndDate?: Temporal.PlainDate | null;
  isRangeMode?: boolean;
  isRangeSelecting?: boolean;
  hoveredDate: Temporal.PlainDate | null;
  setHoveredDate: (date: Temporal.PlainDate | null) => void;
  minDate?: Temporal.PlainDate; // Minimum allowed date
  maxDate?: Temporal.PlainDate; // Maximum allowed date
}

export const CalendarGrid = memo(function CalendarGrid({
  currentMonth,
  selectedDate,
  onDateSelect,
  rangeStartDate,
  rangeEndDate,
  isRangeMode = false,
  isRangeSelecting = false,
  hoveredDate,
  setHoveredDate,
  minDate,
  maxDate,
}: CalendarGridProps) {
  const calendarDays = useMemo(
    () => generateCalendarDays(currentMonth),
    [currentMonth],
  );

  // Group days into weeks (7 days per row)
  // Calendar will always have exactly 6 weeks (42 days)
  const calendarWeeks = useMemo(() => {
    const weeks = [];
    for (let i = 0; i < calendarDays.length; i += 7) {
      weeks.push(calendarDays.slice(i, i + 7));
    }
    return weeks;
  }, [calendarDays]);

  return (
    <Flex dir="column" gap="sm">
      {calendarWeeks.map((week, weekIndex) => (
        <Flex dir="row" gap="none" key={weekIndex} style={{ width: "100%" }}>
          {week.map((day, dayIndex) => (
            <CalendarDay
              date={day.date}
              hoveredDate={hoveredDate}
              isCurrentMonth={day.isCurrentMonth}
              isDisabled={
                (minDate &&
                  Temporal.PlainDate.compare(day.date, minDate) < 0) ||
                (maxDate && Temporal.PlainDate.compare(day.date, maxDate) > 0)
              }
              isRangeMode={isRangeMode}
              isRangeSelecting={isRangeSelecting}
              key={dayIndex}
              onHover={(hovered) => {
                if (hovered) {
                  setHoveredDate(day.date);
                } else {
                  setHoveredDate(null);
                }
              }}
              onSelect={onDateSelect}
              rangeEndDate={rangeEndDate}
              rangeStartDate={rangeStartDate}
              selectedDate={selectedDate}
            />
          ))}
        </Flex>
      ))}
    </Flex>
  );
});
