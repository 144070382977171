import * as classNames from "classnames";
import { createContext, memo } from "react";
import { Flex } from "../../../flex";
import { Text } from "../../../text";
import * as darkModeCss from "../../../theme/component-colors-dark.module.css";
import * as navigationCss from "../redo-navigation.module.css";

export interface SecondaryNavigationData {
  id: string;
  name: string;
  NavContent: React.FC<{ pathRoot: string }>;
}

export const SecondaryNavigationContext = createContext<
  SecondaryNavigationData | undefined
>(undefined);

export const RedoSecondaryNav = memo(function RedoSecondaryNav({
  data,
  pathRoot,
}: {
  data: SecondaryNavigationData | undefined;
  pathRoot: string;
}) {
  if (!data) {
    return null;
  }
  const { name, NavContent } = data;

  return (
    <Flex
      bgColor="primary"
      className={classNames(darkModeCss.darkMode, navigationCss.secondaryNav)}
      dir="column"
      grow={1}
      px="md"
      py="md"
    >
      {name && (
        <Flex px="md" py="2xl">
          <Text fontSize="sm" fontWeight="regular" textColor="tertiary">
            {name}
          </Text>
        </Flex>
      )}
      <Flex dir="column" gap="xs">
        <NavContent pathRoot={pathRoot} />
      </Flex>
    </Flex>
  );
});
