import { z } from "zod";

export const saveConnectionsToMetaAds = {
  input: z.object({
    accountId: z.string(),
    connections: z.array(
      z.object({
        redoSegment: z.object({ id: z.string(), name: z.string() }),
        audienceList: z.object({ id: z.string(), name: z.string() }),
      }),
    ),
  }),
  output: z.object({ success: z.boolean() }),
};
