// extracted by mini-css-extract-plugin
export var animationContainer = "_7i-0";
export var conversationContainer = "_7i-1";
export var detailsContent = "_7i-2";
export var message = "_7i-3";
export var modalContent = "_7i-4";
export var modalFooter = "_7i-6";
export var modalHeader = "_7i-5";
export var name = "_7i-7";
export var optionContainer = "_7i-8";
export var optionTitle = "_7i-9";
export var pillContainer = "_7i-a";
export var selectedConversationsContainer = "_7i-b";
export var selectedOptionContainer = "_7i-c";