import * as classNames from "classnames";
import { forwardRef, HTMLAttributes } from "react";

import { Flex } from "../../../../flex";
import { FlattenedItem, TreeItemType } from "../types";
import { RedoFolderItem } from "./redo-folder-item";
import { RedoViewItem } from "./redo-view-item";
import * as styles from "./tree-item.module.css";

export interface TreeItemProps
  extends Omit<HTMLAttributes<HTMLLIElement>, "id"> {
  value: FlattenedItem;
  depth: number;
  indentationWidth: number;
  clone?: boolean;
  ghost?: boolean;
  handleProps?: any;
  onToggleExpanded?(): void;
  onRequestEdit?: () => void;
  wrapperRef?(node: HTMLLIElement): void;
  childrenLinks?: string[];
  expanded?: boolean;
}

export const TreeItemComponent = forwardRef<HTMLDivElement, TreeItemProps>(
  function TreeItemComponent(
    {
      clone,
      depth,
      ghost,
      handleProps,
      indentationWidth,
      onToggleExpanded,
      style,
      value,
      wrapperRef,
      onRequestEdit,
      childrenLinks,
      expanded,
    },
    ref,
  ) {
    return (
      <Flex
        className={classNames(
          styles.wrapper,
          ghost && styles.ghost,
          clone && styles.clone,
        )}
        gap="none"
        ref={wrapperRef}
        style={
          {
            "--spacing": `${indentationWidth * depth}px`,
          } as React.CSSProperties
        }
      >
        <Flex
          align="center"
          gap="none"
          position="relative"
          ref={ref}
          style={style}
          w="full"
        >
          {value.type === TreeItemType.Folder ? (
            <RedoFolderItem
              childrenLinks={childrenLinks ?? []}
              clone={clone}
              dragHandleProps={handleProps}
              expanded={!!expanded}
              friendlyName={value.friendlyName}
              onRequestEdit={onRequestEdit ?? (() => {})}
              onToggleExpanded={onToggleExpanded ?? (() => {})}
            />
          ) : (
            <RedoViewItem
              clone={clone}
              dragHandleProps={handleProps}
              friendlyName={value.friendlyName}
              linkHref={value.actionHref}
            />
          )}
        </Flex>
      </Flex>
    );
  },
);
