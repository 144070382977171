import { z } from "zod";
import { MoneySchema, WeightUnit } from "../outbound-labels/util";
import { RedoShopifyCountryCode } from "./fulfillment-country-code";

export const FulfillmentOrderLineItemSchema = z.object({
  externalId: z.string(),
  imageUrl: z.string().nullish(),
  customAttributes: z.array(z.object({ key: z.string(), value: z.string() })),
  totalQuantity: z.number(),
  unfulfilledQuantity: z.number(),
  quantity: z.number(),
  weight: z.object({ unit: z.nativeEnum(WeightUnit), value: z.number() }),
  // These three IDs are nullish because if the product is deleted they all become null
  productId: z.string().nullish(),
  variantId: z.string().nullish(),
  inventoryItemId: z.string().nullish(),
  lineItemId: z.string(),
  title: z.string(),
  productTitle: z.string(),
  variantTitle: z.string(),
  selectedOptions: z
    .array(z.object({ name: z.string(), value: z.string() }))
    .nullish(),
  requiresShipping: z.boolean().nullish(),
  sku: z.string().nullish(),
  unitPrice: MoneySchema,
  unitDiscountedPrice: MoneySchema,
  totalPrice: MoneySchema,
  totalDiscountedPrice: MoneySchema,
  totalTax: MoneySchema,
  hsCode: z.string().nullable(),
  countryCodeOfOrigin: z.nativeEnum(RedoShopifyCountryCode).nullish(),
  bin: z.string().nullish(),
  barcode: z.string().nullish(),
  productTags: z.array(z.string()).nullish(),
  vendor: z.string().nullish(),
  description: z.string().nullish(),
});

export type FulfillmentOrderLineItem = z.infer<
  typeof FulfillmentOrderLineItemSchema
>;
export interface IFulfillmentOrderLineItem
  extends z.ZodType<FulfillmentOrderLineItem> {}
