import type { Fulfillment } from "@redotech/redo-model/order";
import { Order } from "@redotech/redo-model/order";
import { RedoBadge } from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import { RedoExternalLink } from "@redotech/redo-web/arbiter-components/links/redo-external-link";
import { getCarrierLogoUrl } from "@redotech/redo-web/carrier/logo";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo } from "react";
import * as claimShipmentsCardCss from "./claim-shipments-card.module.css";
import * as returnPageRightPanelCss from "./return-page-right-panel.module.css";
import { shippingStatusToBadgeColor } from "./shipping-status-badge";

export const ClaimShipmentsCard = memo(function ClaimShipmentsCard({
  orders,
  returnLineItems,
}: {
  orders?: Order[];
  returnLineItems?: string[];
}) {
  if (!orders?.length || !returnLineItems?.length) return null;

  return (
    <Flex className={claimShipmentsCardCss.card} dir="column">
      <Text
        className={claimShipmentsCardCss.titleColor}
        fontSize="xs"
        fontWeight="regular"
      >
        Claim Shipments
      </Text>
      <Flex dir="column" gap="md">
        {orders.map((order) =>
          order?.shopify.fulfillments
            .filter((fulfillment) =>
              fulfillment.line_items.find((li) =>
                returnLineItems.includes(String(li.id)),
              ),
            )
            .map((fulfillment, index) => (
              <FulfillmentItem fulfillment={fulfillment} key={index} />
            )),
        )}
      </Flex>
    </Flex>
  );
});

const FulfillmentItem = memo(function FulfillmentItem({
  fulfillment,
}: {
  fulfillment: Fulfillment;
}) {
  return (
    <div className={returnPageRightPanelCss.item}>
      <div className={returnPageRightPanelCss.itemMain}>
        <Flex dir="column" gap="lg">
          <Flex dir="column" gap="md">
            <Text fontSize="xs" fontWeight="regular">
              Fulfillment name
            </Text>
            <Text fontSize="sm" fontWeight="semibold">
              {fulfillment.name}
            </Text>
          </Flex>
          <Flex dir="column" gap="md">
            <Text fontSize="xs" fontWeight="regular">
              Status
            </Text>
            <Text fontSize="xs" fontWeight="regular">
              <RedoBadge
                color={shippingStatusToBadgeColor(
                  fulfillment.shipment_status ?? "",
                )}
                text={fulfillment.shipment_status ?? "Unknown"}
              />
            </Text>
          </Flex>
          <Flex dir="column" gap="md">
            <Text fontSize="xs" fontWeight="regular">
              Carrier
            </Text>
            <Flex align="center" gap="md">
              {getCarrierLogoUrl(fulfillment.tracking_company ?? "") ? (
                <img
                  height={24}
                  src={getCarrierLogoUrl(fulfillment.tracking_company ?? "")}
                  style={{ borderRadius: "50%" }}
                  width={24}
                />
              ) : null}
              <Text fontSize="sm" fontWeight="semibold">
                {fulfillment.tracking_company}
              </Text>
            </Flex>
          </Flex>
          {fulfillment.tracking_numbers?.length ? (
            <>
              <Text fontSize="xs" fontWeight="regular">
                Tracking number(s)
              </Text>
              <Flex dir="column" gap="md">
                {fulfillment.tracking_numbers.map((trackingNumber, index) => (
                  <Flex key={trackingNumber}>
                    {fulfillment.tracking_urls?.[index] ? (
                      <RedoExternalLink
                        text={trackingNumber}
                        url={fulfillment.tracking_urls[index]}
                      />
                    ) : (
                      <Text fontSize="xs" fontWeight="regular">
                        {trackingNumber}
                      </Text>
                    )}
                  </Flex>
                ))}
              </Flex>
            </>
          ) : null}
        </Flex>
      </div>
    </div>
  );
});
