// extracted by mini-css-extract-plugin
export var actionButton = "_2j-0";
export var actionButtons = "_2j-2";
export var addIcon = "_2j-1p";
export var addItem = "_2j-22";
export var addressSpace = "_2j-3b";
export var adjustment = "_2j-s";
export var adjustmentBonus = "_2j-25";
export var adjustmentFee = "_2j-26";
export var animationContainer = "_2j-3";
export var buttonBar = "_2j-l";
export var buttonContent = "_2j-5";
export var buttonContentPrintBarcodes = "_2j-6";
export var buttonIcon = "_2j-4";
export var card = "_2j-7";
export var cardTitleContainer = "_2j-8";
export var centerButtons = "_2j-h";
export var checkbox = "_2j-r";
export var chevron = "_2j-12";
export var chevronDisabled = "_2j-13";
export var clickAwayListener = "_2j-2g";
export var clickable = "_2j-q";
export var code = "_2j-a";
export var column1 = "_2j-b";
export var column2 = "_2j-c";
export var container = "_2j-d";
export var coverage = "_2j-2q";
export var createLabelsImage = "_2j-34";
export var deleteIconContainer = "_2j-39";
export var editIcon = "_2j-k";
export var editItem = "_2j-i";
export var editNewItemsLink = "_2j-1o";
export var emptyExchangeOrder = "_2j-28";
export var error = "_2j-x";
export var external = "_2j-2p";
export var fraudRiskContainer = "_2j-30";
export var fullHeight = "_2j-33";
export var fullWidth = "_2j-32";
export var greenReturnAdjustment = "_2j-29";
export var greenReturnImage = "_2j-2d";
export var greenReturnSelectContainer = "_2j-2a";
export var greenReturnSelectTitle = "_2j-2b";
export var greenReturnText = "_2j-2c";
export var header = "_2j-11";
export var headerSubtitle = "_2j-n";
export var helpText = "_2j-j";
export var icon = "_2j-9";
export var imageUploadContainer = "_2j-1";
export var item = "_2j-16";
export var itemCard = "_2j-35";
export var itemExtra = "_2j-17";
export var itemHeader = "_2j-1e";
export var itemImage = "_2j-18";
export var itemIndexText = "_2j-15";
export var itemMain = "_2j-19";
export var itemOption = "_2j-1k";
export var itemOptions = "_2j-1h";
export var itemOptionsText = "_2j-1i";
export var itemPrice = "_2j-1n";
export var itemPriceContainer = "_2j-1u";
export var itemPriceEdit = "_2j-1m";
export var itemProperties = "_2j-1a";
export var itemProperty = "_2j-1b";
export var itemPropertyName = "_2j-1c";
export var itemPropertyValue = "_2j-1d";
export var itemSearch = "_2j-23";
export var itemSubtitle = "_2j-1g";
export var itemSummaryLine = "_2j-1w";
export var itemSummaryValue = "_2j-1x";
export var itemTitle = "_2j-1f";
export var itemTitleIcon = "_2j-1j";
export var itemType = "_2j-1l";
export var itemValue = "_2j-1v";
export var menuTitle = "_2j-1z";
export var modalButton = "_2j-2e";
export var modalContent = "_2j-2r";
export var modalFooterRight = "_2j-2f";
export var modalImage = "_2j-10";
export var modalProduct = "_2j-p";
export var modalProductInfo = "_2j-w";
export var modalProductInfoRight = "_2j-y";
export var modalProductInfoRightBottom = "_2j-z";
export var newAddress = "_2j-14";
export var newItemHeader = "_2j-27";
export var newItemsSection = "_2j-3a";
export var noConversations = "_2j-e";
export var noTextWrap = "_2j-36";
export var noTransition = "_2j-v";
export var optionContent = "_2j-20";
export var optionTitle = "_2j-1y";
export var orderLink = "_2j-2m";
export var originalPrice = "_2j-1q";
export var paddedModalContent = "_2j-2s";
export var priceSummaryTitle = "_2j-21";
export var processButtons = "_2j-2t";
export var processItemSubheader = "_2j-o";
export var product = "_2j-2n";
export var restock = "_2j-t";
export var restockSwitch = "_2j-u";
export var returnTypeModal = "_2j-2h";
export var returnTypeModalButton = "_2j-2k";
export var returnTypeModalHeader = "_2j-2j";
export var right = "_2j-1r";
export var rightAligned = "_2j-2i";
export var riskReportIframe = "_2j-31";
export var selectItemsModalButtons = "_2j-f";
export var selectItemsModalRightButtons = "_2j-g";
export var status = "_2j-2o";
export var subsection = "_2j-2y";
export var summary = "_2j-m";
export var summaryText = "_2j-1s";
export var summaryTextLight = "_2j-1t";
export var table = "_2j-2l";
export var tableCell = "_2j-2x";
export var tableDescription = "_2j-2w";
export var tableHeader = "_2j-2v";
export var tableHeaderGroup = "_2j-2u";
export var tooltipLink = "_2j-38";
export var trackingNumbers = "_2j-2z";
export var trash = "_2j-37";
export var voided = "_2j-24";