import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

const checkoutMetric = z.object({
  title: z.string(),
  valuesByTreatment: z.array(
    z.object({ treatmentId: zExt.objectId(), value: z.string() }),
  ),
});
export type CheckoutMetric = z.infer<typeof checkoutMetric>;

export const getAllCheckoutSplitMetrics = {
  input: z.object({ splitId: zExt.objectId() }),
  output: z.object({
    allTreatmentIds: z.array(zExt.objectId()),
    checkoutMetrics: z.array(checkoutMetric),
  }),
};
