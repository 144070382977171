import { useHandler } from "@redotech/react-util/hook";
import { folderTypes } from "@redotech/redo-model/views/folders";
import { FulfillmentGroupView } from "@redotech/redo-model/views/views";
import {
  ReactNode,
  createContext,
  memo,
  useContext,
  useEffect,
  useState,
} from "react";
import { RedoOutboundLabelsRpcClientContext } from "./outbound-labels-rpc-client";

export const OutboundViewsContext = createContext<
  | {
      teamViews?: FulfillmentGroupView[];
      folders?: folderTypes["fulfillment-groups"][];
    }
  | undefined
>(undefined);

export const ReloadOutboundViewsContext = createContext<
  (() => Promise<void>) | undefined
>(undefined);

export const OutboundViewsProvider = memo(function OutboundViewsProvider({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const client = useContext(RedoOutboundLabelsRpcClientContext);

  const [views, setViews] = useState<FulfillmentGroupView[]>([]);
  const [folders, setFolders] = useState<folderTypes["fulfillment-groups"][]>(
    [],
  );

  const reloadViews = useHandler(async () => {
    if (!client) {
      return;
    }
    const abortController = new AbortController();
    const { views, folders } = await client.getViewsAndFolders(null, {
      signal: abortController.signal,
    });
    setViews(views);
    setFolders(folders);
  });

  useEffect(() => {
    void reloadViews();
  }, [client, reloadViews]);

  return (
    <OutboundViewsContext.Provider value={{ teamViews: views, folders }}>
      <ReloadOutboundViewsContext.Provider value={reloadViews}>
        {children}
      </ReloadOutboundViewsContext.Provider>
    </OutboundViewsContext.Provider>
  );
});
