import { MenuItem, Select } from "@mui/material";
import { COUNTRIES_BY_CODE, COUNTRY_CODES } from "@redotech/locale/countries";
import { useHandler } from "@redotech/react-util/hook";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { UserContext } from "@redotech/redo-merchant-app-common/user";
import { Provider } from "@redotech/redo-model/order";
import { FlowType, Step as ModelStep } from "@redotech/redo-model/return-flow";
import {
  AdjustmentAmountOption,
  Condition,
  DateOption,
  DiscountMatchType,
  DiscountOptionModel,
  ItemDiscountMeasureOption,
  LoyaltyEarnedMeasureOption,
  MetafieldMatchType,
  Condition as ModelCondition,
  NumClaimsOption,
  NumItemsBeingReturnedOption,
  NumOrdersOption,
  NumReturnsOption,
  PaymentMethodOption,
  PriceOption,
  ReturnRateOption,
} from "@redotech/redo-model/return-flow/condition";
import { ReturnOptionMethod } from "@redotech/redo-model/return-flow/return-option";
import { Channel, SalesChannels } from "@redotech/redo-model/sales-channels";
import { Permission, permitted } from "@redotech/redo-model/user";
import { Autocomplete } from "@redotech/redo-web/autocomplete";
import { ButtonSize, IconButton } from "@redotech/redo-web/button";
import { ChipDelimiter, ChipInput } from "@redotech/redo-web/chip-input";
import { CURRENCY_FORMAT } from "@redotech/redo-web/currency";
import { DateInput } from "@redotech/redo-web/date-picker";
import { DateRangeInput, MaybeDate } from "@redotech/redo-web/date-range";
import { BlockLayout } from "@redotech/redo-web/flowchart";
import InfoIcon from "@redotech/redo-web/icon-old/info.svg";
import TrashIcon from "@redotech/redo-web/icon-old/trash.svg";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { Pill, PillSize } from "@redotech/redo-web/pill";
import { RadioGroup } from "@redotech/redo-web/radio";
import { SelectDropdown } from "@redotech/redo-web/select-dropdown";
import { InputTheme, TextInput } from "@redotech/redo-web/text-input";
import { titleCase } from "@redotech/web-util/strings";
import { produce } from "immer";
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  memo,
  useContext,
  useEffect,
  useState,
} from "react";
import { MESSAGE_BODY_MATCHES } from "../rules/conditions/message-body-matches";
import { SENT_FROM_EMAIL } from "../rules/conditions/sent-from-email";
import { SUBJECT_LINE } from "../rules/conditions/subject-line";
import * as multipleStepsCss from "../rules/multiple-steps.module.css";
import { RECEIVED_AT_EMAIL } from "../rules/received-at-email";
import { TICKET_CHANNEL } from "../rules/ticket-channel";
import { TOPIC } from "../rules/topic";
import { COLLECTION } from "./condition-types/collection";
import { CUSTOMER_TAG } from "./condition-types/customer-tag";
import { IS_REFUND_RETURN } from "./condition-types/is_refund_return";
import { ORDER_COUNT_GREATER_THAN } from "./condition-types/order-count_greater_than";
import { PRODUCT_TAG } from "./condition-types/product-tag";
import { SKU } from "./condition-types/sku";
import { TOTAL_SPENT_GREATER_THAN } from "./condition-types/total-spent_greater_than";
import { TRACKING_TYPE } from "./condition-types/tracking-type";
import { VENDOR } from "./condition-types/vendor";
import * as conditionCss from "./condition.module.css";
import * as selectCss from "./select.module.css";
import { StepDownstream, StepId, StepType, StepTypeDetailsProps } from "./step";

export interface State {
  conditionType: ConditionType<any, any> | undefined;
  nextTrue?: StepId;
  nextFalse?: StepId;
  conditionState?: any;
  customTitle?: string;
}

export namespace State {
  export function valid(state: State, flowType: FlowType) {
    return (
      (state.nextTrue !== undefined ||
        flowType === FlowType.FINALIZE_RETURN ||
        flowType === FlowType.FINALIZE_CLAIM) &&
      (state.nextFalse !== undefined ||
        flowType === FlowType.FINALIZE_RETURN ||
        flowType === FlowType.FINALIZE_CLAIM) &&
      state.conditionType !== undefined &&
      state.conditionType.valid(state.conditionState)
    );
  }
}

export interface ConditionType<T, M> {
  readonly empty: T;
  name: string;
  Details(props: {
    state: T;
    setState: Dispatch<SetStateAction<T>>;
    disabled?: boolean;
    flowType: FlowType;
  }): ReactElement | null;
  description(state: T): string;
  valid(state: T): boolean;
  fromModel(model: M): T;
  toModel(state: T): M;
}

const COVERAGE: ConditionType<void, ModelCondition.Coverage> = {
  name: "Returns covered by Redo",
  description() {
    return "Has Redo return coverage";
  },
  Details() {
    return null;
  },
  empty: undefined,
  fromModel() {},
  toModel() {
    return { type: ModelCondition.COVERAGE };
  },
  valid() {
    return true;
  },
};

const PACKAGE_PROTECTION: ConditionType<
  void,
  ModelCondition.PackageProtection
> = {
  name: "Covered by package protection",
  description() {
    return "Customer purchased package protection";
  },
  Details() {
    return null;
  },
  empty: undefined,
  fromModel() {},
  toModel() {
    return { type: ModelCondition.PACKAGE_PROTECTION };
  },
  valid() {
    return true;
  },
};

const CUSTOMER_EMAIL_SUBSCRIBER: ConditionType<
  void,
  ModelCondition.CustomerEmailSubscriber
> = {
  name: "Customer email subscriber",
  description() {
    return "Customer is an email subscriber";
  },
  Details() {
    return null;
  },
  empty: undefined,
  fromModel() {},
  toModel() {
    return { type: ModelCondition.CUSTOMER_EMAIL_SUBSCRIBER };
  },
  valid() {
    return true;
  },
};

const CUSTOMER_SMS_SUBSCRIBER: ConditionType<
  void,
  ModelCondition.CustomerSmsSubscriber
> = {
  name: "Customer sms subscriber",
  description() {
    return "Customer is an sms subscriber";
  },
  Details() {
    return null;
  },
  empty: undefined,
  fromModel() {},
  toModel() {
    return { type: ModelCondition.CUSTOMER_SMS_SUBSCRIBER };
  },
  valid() {
    return true;
  },
};

function itemDiscountMeasureOptionLabel(
  measureOption: ItemDiscountMeasureOption,
): string {
  switch (measureOption) {
    case ItemDiscountMeasureOption.PERCENT:
      return "%";
    case ItemDiscountMeasureOption.AMOUNT:
      return "Raw amount";
  }
}

function loyaltyEarnedMeasureOptionLabel(
  measureOption: LoyaltyEarnedMeasureOption,
): string {
  switch (measureOption) {
    case LoyaltyEarnedMeasureOption.PERCENT:
      return "%";
    case LoyaltyEarnedMeasureOption.AMOUNT:
      return "Raw amount";
  }
}

const ITEM_DISCOUNT: ConditionType<
  { measureOption: ItemDiscountMeasureOption; value: number },
  ModelCondition.ItemDiscount
> = {
  name: "Item discount",
  description(state) {
    const measureWording =
      state.measureOption == ItemDiscountMeasureOption.PERCENT
        ? "%"
        : "in your currency";

    return `Item discounted by ${state.value} ${measureWording} or greater`;
  },
  Details({ state, setState }) {
    const description =
      state.measureOption == ItemDiscountMeasureOption.PERCENT
        ? `If the item is discounted by more than ${state.value}% from its original price`
        : `If the item is discounted by ${state.value} or greater`;
    return (
      <>
        <RadioGroup
          optionLabel={itemDiscountMeasureOptionLabel}
          options={Object.values(ItemDiscountMeasureOption)}
          value={state.measureOption}
          valueChange={(e) => setState({ ...state, measureOption: e })}
        />
        <LabeledInput description={description} label="Value">
          <TextInput
            max={
              state.measureOption === ItemDiscountMeasureOption.PERCENT
                ? 100
                : undefined
            }
            min={0}
            onChange={(e) => {
              setState({ ...state, value: +e });
            }}
            theme={InputTheme.FORM}
            type="number"
            value={state.value.toString()}
          />
        </LabeledInput>
      </>
    );
  },
  empty: { measureOption: ItemDiscountMeasureOption.PERCENT, value: 10 },
  fromModel(model: ModelCondition.ItemDiscount) {
    return { measureOption: model.measureOption, value: model.value };
  },
  toModel(state) {
    return {
      type: ModelCondition.ITEM_DISCOUNT,
      measureOption: state.measureOption,
      value: state.value,
    };
  },
  valid() {
    return true;
  },
};

const LOYALTY_EARNED: ConditionType<
  { measureOption: LoyaltyEarnedMeasureOption; value: number },
  ModelCondition.LoyaltyEarned
> = {
  name: "Loyalty",
  description(state) {
    const measureWording =
      state.measureOption == LoyaltyEarnedMeasureOption.PERCENT
        ? "%"
        : "in your currency";

    return `Loyalty by ${state.value} ${measureWording} or greater`;
  },
  Details({ state, setState }) {
    return (
      <>
        <RadioGroup
          optionLabel={loyaltyEarnedMeasureOptionLabel}
          options={Object.values(LoyaltyEarnedMeasureOption)}
          value={state.measureOption}
          valueChange={(e) => setState({ ...state, measureOption: e })}
        />
        <LabeledInput description="" label="Value">
          <TextInput
            max={
              state.measureOption === LoyaltyEarnedMeasureOption.PERCENT
                ? 100
                : undefined
            }
            min={0}
            onChange={(e) => {
              setState({ ...state, value: +e });
            }}
            theme={InputTheme.FORM}
            type="number"
            value={state.value.toString()}
          />
        </LabeledInput>
      </>
    );
  },
  empty: { measureOption: LoyaltyEarnedMeasureOption.PERCENT, value: 10 },
  fromModel(model: ModelCondition.LoyaltyEarned) {
    return { measureOption: model.measureOption, value: model.value };
  },
  toModel(state) {
    return {
      type: ModelCondition.LOYALTY_EARNED,
      measureOption: state.measureOption,
      value: state.value,
    };
  },
  valid() {
    return true;
  },
};

function discountMatchTypeLabel(
  discountMatchType: DiscountMatchType | null,
): string | undefined {
  switch (discountMatchType) {
    case DiscountMatchType.EXACT:
      return "Exact match";
    case DiscountMatchType.CONTAINS:
      return "Contains";
    case null:
      return undefined;
  }
}

function discountCodeOptionLabel(
  discountOptionModel: DiscountOptionModel | null,
): string | undefined {
  switch (discountOptionModel) {
    case DiscountOptionModel.ORDER:
      return "All items in order";
    case DiscountOptionModel.ITEM:
      return "Only items with discount code applied";
    case null:
      return undefined;
  }
}

const DISCOUNT: ConditionType<
  {
    codes: readonly string[];
    codeOption: DiscountOptionModel | null;
    matchType: DiscountMatchType | null;
  },
  ModelCondition.Discount
> = {
  name: "Discounts",
  description(state) {
    return `Discounts: ${state.codes.join(", ")}`;
  },
  Details({ state, setState, disabled, flowType }) {
    useEffect(() => {
      if (
        flowType === FlowType.FINALIZE_RETURN ||
        flowType === FlowType.FINALIZE_CLAIM
      ) {
        setState({ ...state, codeOption: DiscountOptionModel.ORDER });
      }
      // FIXME
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
      <>
        <RadioGroup
          disabled={disabled}
          optionLabel={discountMatchTypeLabel}
          options={Object.values(DiscountMatchType)}
          value={state.matchType}
          valueChange={(e) => setState({ ...state, matchType: e })}
        />
        <LabeledInput
          description="List of discount codes or titles, case-insensitive"
          label="Discount codes/titles"
        >
          <ChipInput
            delimiter={ChipDelimiter.NEWLINE}
            disabled={disabled}
            trimWhitespace
            value={state.codes}
            valueChange={(e) => setState({ ...state, codes: e })}
          />
        </LabeledInput>
        {flowType !== FlowType.FINALIZE_RETURN &&
          flowType !== FlowType.FINALIZE_CLAIM && (
            <LabeledInput label="Condition is applied to">
              <RadioGroup
                disabled={disabled}
                optionLabel={discountCodeOptionLabel}
                options={Object.values(DiscountOptionModel)}
                value={state.codeOption}
                valueChange={(e) => setState({ ...state, codeOption: e })}
              />
            </LabeledInput>
          )}
      </>
    );
  },
  empty: {
    codes: [],
    codeOption: DiscountOptionModel.ITEM,
    matchType: DiscountMatchType.EXACT,
  },
  fromModel(model: ModelCondition.Discount) {
    return {
      codes: model.codes,
      codeOption: model.codeOption,
      matchType: model.matchType || DiscountMatchType.EXACT,
    };
  },
  toModel(state) {
    return {
      type: ModelCondition.DISCOUNT,
      codes: state.codes,
      codeOption: state.codeOption,
      matchType: state.matchType || DiscountMatchType.EXACT,
    };
  },
  valid() {
    return true;
  },
};

const FULFILLMENT_WITHIN: ConditionType<
  string,
  ModelCondition.FulfillmentPeriod
> = {
  name: "Time since fulfillment",
  description(state) {
    return `Within ${+state} days of fulfillment`;
  },
  Details({ state, setState, disabled, flowType }) {
    return (
      <LabeledInput
        description={
          flowType === FlowType.FINALIZE_RETURN ||
          (flowType === FlowType.FINALIZE_CLAIM &&
            "If any selected item was not fulfilled within this number of days, the condition will not match")
        }
        label="Max days"
      >
        <TextInput
          min={0}
          onChange={setState}
          readonly={disabled}
          theme={InputTheme.FORM}
          type="number"
          value={state}
        />
      </LabeledInput>
    );
  },
  empty: String(30),
  fromModel(model) {
    return String(model.duration.total("days"));
  },
  toModel(state) {
    return {
      type: ModelCondition.FULFILLMENT_WITHIN,
      duration: Temporal.Duration.from({ days: +state }),
    };
  },
  valid(state: string) {
    return +state >= 0;
  },
};

const FINAL_SALE_RETURN: ConditionType<
  { validCollections: readonly string[]; validProductTags: readonly string[] },
  ModelCondition.FinalSaleReturn
> = {
  name: "Final Sale Return Item",
  description(state) {
    return `Item is a final sale return`;
  },
  Details({ state, setState, disabled, flowType }) {
    const team = useContext(TeamContext);
    useEffect(() => {
      setState({
        validCollections:
          team?.settings?.returns?.finalSaleReturns?.validCollections || [],
        validProductTags:
          team?.settings?.returns?.finalSaleReturns?.validProductTags || [],
      });
      // FIXME
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
  },
  empty: { validCollections: [], validProductTags: [] },
  fromModel(model) {
    // FIXME: Not sure what this does
    return {
      validCollections: model.validCollections,
      validProductTags: model.validProductTags,
    };
  },
  toModel(state) {
    return {
      type: ModelCondition.FINAL_SALE_RETURN,
      validCollections: state.validCollections,
      validProductTags: state.validProductTags,
    };
  },
  valid(state) {
    return true;
  },
};

const ORDERLESS: ConditionType<void, ModelCondition.Orderless> = {
  name: "Orderless",
  description() {
    return "No order associated with the request";
  },
  Details() {
    return null;
  },
  empty: undefined,
  fromModel() {},
  toModel() {
    return { type: ModelCondition.ORDERLESS };
  },
  valid() {
    return true;
  },
};

const getDateDescription = (
  state: { start: MaybeDate; end: MaybeDate; dateOption: DateOption | null },
  dateType: "Order" | "Return" | "Request",
) => {
  if (
    state.dateOption === DateOption.AFTER ||
    state.dateOption === DateOption.BEFORE
  ) {
    return `${dateType} created ${
      state.dateOption === DateOption.AFTER ? "after" : "before"
    } ${state.start?.toLocaleDateString("en-us", {
      month: "short",
      day: "numeric",
      year: "numeric",
    })}`;
  } else if (state.dateOption === DateOption.BETWEEN) {
    return `${dateType} created between ${state.start?.toLocaleDateString(
      "en-us",
      { month: "short", day: "numeric" },
    )} — ${state.end?.toLocaleDateString("en-us", {
      month: "short",
      day: "numeric",
    })}
        })`;
  } else {
    return `${dateType} created date`;
  }
};

const DateDetails = (
  state: { start: MaybeDate; end: MaybeDate; dateOption: DateOption | null },
  setState: Dispatch<
    SetStateAction<{
      start: MaybeDate;
      end: MaybeDate;
      dateOption: DateOption | null;
    }>
  >,
  disabled?: boolean,
) => {
  const [comparison, setComparison] = useState<DateOption | null>(
    state.dateOption,
  );
  // For single date
  const valueChange = useHandler((v: Date | null) => {
    if (v) {
      setState({ start: v, end: null, dateOption: comparison });
    } else {
      setState({ start: null, end: null, dateOption: comparison });
    }
  });

  // For date range
  const multiValueChange = useHandler(
    (v: [Date | null, Date | null] | null) => {
      if (v && Array.isArray(v)) {
        const [newStart, newEnd] = v;
        setState({ start: newStart, end: newEnd, dateOption: comparison });
      } else {
        setState({ start: null, end: null, dateOption: comparison });
      }
    },
  );

  useEffect(() => {
    // If the comparison changes, we need to update the value
    if (state.dateOption !== comparison) {
      if (comparison === DateOption.AFTER || comparison === DateOption.BEFORE) {
        valueChange(state.start);
      } else {
        multiValueChange([state.start, state.end]);
      }
    }
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparison]);

  return (
    <>
      <LabeledInput label="Comparison">
        <SelectDropdown
          disabled={disabled}
          options={Object.values(DateOption)}
          value={comparison}
          valueChange={(e) => setComparison(e || null)}
        >
          {(type) => (
            <div className={selectCss.value}>
              <div className={selectCss.title}>{type}</div>
            </div>
          )}
        </SelectDropdown>
      </LabeledInput>
      {comparison === DateOption.AFTER || comparison === DateOption.BEFORE ? (
        <DateInput
          disabled={disabled}
          value={state.start}
          valueChange={valueChange}
        />
      ) : comparison === DateOption.BETWEEN ? (
        <DateRangeInput
          disabled={disabled}
          value={[state.start, state.end]}
          valueChange={multiValueChange}
        />
      ) : null}
    </>
  );
};

const validateDateOptions = (state: {
  start: MaybeDate;
  end: MaybeDate;
  dateOption: DateOption | null;
}) => {
  if (state.dateOption === null) {
    return false;
  }
  if (
    state.dateOption === DateOption.AFTER ||
    state.dateOption === DateOption.BEFORE
  ) {
    return !!state.start;
  } else {
    // Between
    return !!state.start && !!state.end;
  }
};

const RETURN_DATE: ConditionType<
  { start: MaybeDate; end: MaybeDate; dateOption: DateOption | null },
  ModelCondition.ReturnDate
> = {
  name: "Request created date",
  description(state) {
    return getDateDescription(state, "Request");
  },
  Details({ state, setState, disabled }) {
    return DateDetails(state, setState, disabled);
  },
  empty: { start: null, end: null, dateOption: null },
  fromModel(model) {
    return {
      start:
        model.start &&
        new Date(
          model.start.toZonedDateTime(
            Temporal.Now.timeZoneId(),
          ).epochMilliseconds,
        ),
      end:
        model.end &&
        new Date(
          model.end.toZonedDateTime(
            Temporal.Now.timeZoneId(),
          ).epochMilliseconds,
        ),
      dateOption: model.dateOption,
    };
  },
  toModel(state) {
    return {
      type: ModelCondition.RETURN_DATE,
      start:
        state.start &&
        state.start
          .toTemporalInstant()
          .toZonedDateTimeISO(Temporal.Now.timeZoneId())
          .toPlainDate(),
      end:
        state.end &&
        state.end
          .toTemporalInstant()
          .toZonedDateTimeISO(Temporal.Now.timeZoneId())
          .toPlainDate(),
      dateOption: state.dateOption,
    };
  },
  valid(state) {
    return validateDateOptions(state);
  },
};

const ORDER_DATE: ConditionType<
  { start: MaybeDate; end: MaybeDate; dateOption: DateOption | null },
  ModelCondition.OrderDate
> = {
  name: "Order created date",
  description(state) {
    return getDateDescription(state, "Order");
  },
  Details({ state, setState, disabled }) {
    return DateDetails(state, setState, disabled);
  },
  empty: { start: null, end: null, dateOption: null },
  fromModel(model) {
    return {
      start:
        model.start &&
        new Date(
          model.start.toZonedDateTime(
            Temporal.Now.timeZoneId(),
          ).epochMilliseconds,
        ),
      end:
        model.end &&
        new Date(
          model.end.toZonedDateTime(
            Temporal.Now.timeZoneId(),
          ).epochMilliseconds,
        ),
      dateOption: model.dateOption,
    };
  },
  toModel(state) {
    return {
      type: ModelCondition.ORDER_DATE,
      start:
        state.start &&
        state.start
          .toTemporalInstant()
          .toZonedDateTimeISO(Temporal.Now.timeZoneId())
          .toPlainDate(),
      end:
        state.end &&
        state.end
          .toTemporalInstant()
          .toZonedDateTimeISO(Temporal.Now.timeZoneId())
          .toPlainDate(),
      dateOption: state.dateOption,
    };
  },
  valid(state) {
    return validateDateOptions(state);
  },
};

const PRODUCT_PROPERTY: ConditionType<
  readonly string[],
  ModelCondition.ProductProperty
> = {
  name: "Product properties",
  description(state) {
    return `Product properties: ${state.join(", ")}`;
  },
  Details({ state, setState, disabled, flowType }) {
    return (
      <LabeledInput
        description={
          (flowType === FlowType.FINALIZE_RETURN ||
            flowType === FlowType.FINALIZE_CLAIM) &&
          "Property names to search for in all selected items"
        }
        label='Property names/values (e.g. "Size" or "Size: Small")'
      >
        <ChipInput
          delimiter={ChipDelimiter.NEWLINE}
          disabled={disabled}
          trimWhitespace
          value={state}
          valueChange={setState}
        />
      </LabeledInput>
    );
  },
  empty: [],
  fromModel(model: ModelCondition.ProductProperty) {
    return model.properties;
  },
  toModel(state) {
    return { type: ModelCondition.PRODUCT_PROPERTY, properties: state };
  },
  valid() {
    return true;
  },
};

const isValidRegex = (regex: string) => {
  try {
    new RegExp(regex);
    return true;
  } catch {
    return false;
  }
};

const PRODUCT_VARIANT_METAFIELD: ConditionType<
  Omit<ModelCondition.ProductVariantMetafield, "type">,
  ModelCondition.ProductVariantMetafield
> = {
  name: "Product variant metafield",
  description(state) {
    return `Metafield: ${state.namespace}.${state.key}`;
  },
  Details({ state, setState, disabled, flowType }) {
    const invalidRegex =
      state.comparison === MetafieldMatchType.REGEX &&
      !isValidRegex(state.value);
    return (
      <>
        <LabeledInput label="Namespace">
          <TextInput
            disabled={disabled}
            onChange={(value) => setState({ ...state, namespace: value })}
            value={state.namespace}
          />
        </LabeledInput>
        <LabeledInput label="Key">
          <TextInput
            disabled={disabled}
            onChange={(value) => setState({ ...state, key: value })}
            value={state.key}
          />
        </LabeledInput>
        <LabeledInput label="Comparison">
          <SelectDropdown
            disabled={disabled}
            options={Object.values(MetafieldMatchType)}
            value={state.comparison}
            valueChange={(e) => e && setState({ ...state, comparison: e })}
          >
            {(value) =>
              ({
                [MetafieldMatchType.EXACT]: "Exact match",
                [MetafieldMatchType.CONTAINS]: "Contains",
                [MetafieldMatchType.REGEX]: "Regular expression",
              })[value]
            }
          </SelectDropdown>
        </LabeledInput>
        <LabeledInput
          errors={invalidRegex ? ["Invalid regular expression"] : undefined}
          label="Value"
        >
          <TextInput
            disabled={disabled}
            error={invalidRegex}
            onChange={(value) => setState({ ...state, value })}
            value={state.value}
          />
        </LabeledInput>
      </>
    );
  },
  empty: {
    namespace: "",
    key: "",
    comparison: MetafieldMatchType.EXACT,
    value: "",
  },
  fromModel(model: ModelCondition.ProductVariantMetafield) {
    return { ...model };
  },
  toModel(state) {
    return { type: ModelCondition.PRODUCT_VARIANT_METAFIELD, ...state };
  },
  valid(state) {
    if (state.comparison === MetafieldMatchType.REGEX) {
      return isValidRegex(state.value);
    }
    return true;
  },
};

const PRODUCT_TYPE: ConditionType<
  readonly string[],
  ModelCondition.ProductType
> = {
  name: "Product type",
  description(state) {
    return `Product type: ${state.join(", ")}`;
  },
  Details({ state, setState, disabled, flowType }) {
    return (
      <LabeledInput
        description={
          (flowType === FlowType.FINALIZE_RETURN ||
            flowType === FlowType.FINALIZE_CLAIM) &&
          "Product types to search for in all selected items"
        }
        label="Product type"
      >
        <ChipInput
          delimiter={ChipDelimiter.NEWLINE}
          disabled={disabled}
          trimWhitespace
          value={state}
          valueChange={setState}
        />
      </LabeledInput>
    );
  },
  empty: [],
  fromModel(model: ModelCondition.ProductType) {
    return model.productTypes;
  },
  toModel(state) {
    return { type: ModelCondition.PRODUCT_TYPE, productTypes: state };
  },
  valid() {
    return true;
  },
};

const ORDER_TAG: ConditionType<readonly string[], ModelCondition.OrderTag> = {
  name: "Order tags",
  description(state) {
    return `Order tags: ${state.join(", ")}`;
  },
  Details({ state, setState, disabled }) {
    return (
      <LabeledInput label="Order tags">
        <ChipInput
          delimiter={ChipDelimiter.NEWLINE}
          disabled={disabled}
          trimWhitespace
          value={state}
          valueChange={setState}
        />
      </LabeledInput>
    );
  },
  empty: [],
  fromModel(model: ModelCondition.OrderTag) {
    return model.tags;
  },
  toModel(state) {
    return { type: ModelCondition.ORDER_TAG, tags: state };
  },
  valid() {
    return true;
  },
};

const SALES_CHANNEL: ConditionType<
  readonly string[],
  ModelCondition.SalesChannel
> = {
  name: "Sales channel",
  description(state) {
    const formattedChannels = state.map((string: string) => titleCase(string));
    return `Sales channels: ${formattedChannels.join(", ")}`;
  },
  Details({ state, setState, disabled }) {
    return (
      <LabeledInput label="Sales Channels">
        <Select
          disabled={disabled}
          multiple
          onChange={(e) => {
            setState(
              typeof e.target.value === "string"
                ? e.target.value.split(",")
                : e.target.value,
            );
          }}
          renderValue={(selected) => (
            <div className={conditionCss.dropdownPills}>
              {selected.map((value) => (
                <Pill key={value} size={PillSize.SMALL}>
                  {value}
                </Pill>
              ))}
            </div>
          )}
          sx={{ borderRadius: "10px" }}
          value={state}
        >
          {SalesChannels.map((channel: Channel) => (
            <MenuItem key={channel.id} value={channel.id}>
              {channel.displayName}
            </MenuItem>
          ))}
        </Select>
      </LabeledInput>
    );
  },
  empty: [],
  fromModel(model: ModelCondition.SalesChannel) {
    return model.channels;
  },
  toModel(state) {
    return { type: ModelCondition.SALES_CHANNEL, channels: state };
  },
  valid() {
    return true;
  },
};

const ORDER_WITHIN: ConditionType<string, ModelCondition.OrderWithin> = {
  name: "Time since order",
  description(state) {
    return `Within ${+state} days of order`;
  },
  Details({ state, setState, disabled }) {
    return (
      <LabeledInput label="Max days">
        <TextInput
          min={0}
          onChange={setState}
          readonly={disabled}
          theme={InputTheme.FORM}
          type="number"
          value={state}
        />
      </LabeledInput>
    );
  },
  empty: String(30),
  fromModel(model) {
    return String(model.duration.total("days"));
  },
  toModel(state) {
    return {
      type: ModelCondition.ORDER_WITHIN,
      duration: Temporal.Duration.from({ days: +state }),
    };
  },
  valid(state) {
    return +state >= 0;
  },
};

const RETURN_VALUE: ConditionType<
  { amount: string; priceOption: PriceOption | null },
  ModelCondition.ReturnValue
> = {
  name: `Return value`,
  description(state) {
    return this.valid(state)
      ? `Return value ${state.priceOption?.toLowerCase()} ${CURRENCY_FORMAT().format(+state.amount)}`
      : "";
  },
  Details({ state, setState, disabled }) {
    function handlePriceOptionChange(priceOption: PriceOption | null) {
      setState({
        ...state,
        priceOption,
        ...(priceOption === PriceOption.CENTS_EQUALS &&
          +state.amount >= 1 && { amount: "0.99" }),
      });
    }

    function handleAmountChange(amount: string) {
      setState({ ...state, amount });
    }

    const dropdownOptions = Object.values(PriceOption).filter(
      (option) => option !== PriceOption.CENTS_EQUALS,
    );
    return (
      <>
        <LabeledInput label="Comparison">
          <SelectDropdown
            disabled={disabled}
            options={dropdownOptions}
            value={state.priceOption}
            valueChange={(e) => handlePriceOptionChange(e ?? null)}
          >
            {(type) => (
              <div className={selectCss.value}>
                <div className={selectCss.title}>{type}</div>
              </div>
            )}
          </SelectDropdown>
        </LabeledInput>
        <LabeledInput description="Total return value of all items" label="">
          <TextInput
            min={0}
            onChange={(e) => handleAmountChange(e)}
            onFocus={() => handleAmountChange((+state.amount).toFixed(2))}
            prefix="$"
            readonly={disabled}
            theme={InputTheme.FORM}
            type="number"
            value={state.amount}
          />
        </LabeledInput>
      </>
    );
  },
  empty: { amount: (0).toFixed(2), priceOption: null },
  fromModel(model) {
    return { amount: model.amount.toFixed(2), priceOption: model.priceOption };
  },
  toModel(state) {
    return {
      type: ModelCondition.RETURN_VALUE,
      amount: +state.amount,
      priceOption: state.priceOption,
    };
  },
  valid(state) {
    return +state.amount >= 0 && state.priceOption !== null;
  },
};

const PRICE: ConditionType<
  { amount: string; priceOption: PriceOption | null },
  ModelCondition.Price
> = {
  name: `Price`,
  description(state) {
    return this.valid(state)
      ? `Price ${state.priceOption?.toLowerCase()} ${CURRENCY_FORMAT().format(+state.amount)}`
      : "";
  },
  Details({ state, setState, disabled, flowType }) {
    function handlePriceOptionChange(priceOption: PriceOption | null) {
      setState({
        ...state,
        priceOption,
        ...(priceOption === PriceOption.CENTS_EQUALS &&
          +state.amount >= 1 && { amount: "0.99" }),
      });
    }
    function handleAmountChange(amount: string) {
      setState({ ...state, amount });
    }
    const dropdownOptions =
      flowType === FlowType.FINALIZE_RETURN ||
      flowType === FlowType.FINALIZE_CLAIM
        ? Object.values(PriceOption).filter(
            (option) => option !== PriceOption.CENTS_EQUALS,
          )
        : Object.values(PriceOption);
    return (
      <>
        <LabeledInput label="Comparison">
          <SelectDropdown
            disabled={disabled}
            options={dropdownOptions}
            value={state.priceOption}
            valueChange={(e) => handlePriceOptionChange(e ?? null)}
          >
            {(type) => (
              <div className={selectCss.value}>
                <div className={selectCss.title}>{type}</div>
              </div>
            )}
          </SelectDropdown>
        </LabeledInput>
        {state.priceOption === PriceOption.CENTS_EQUALS ? (
          <TextInput
            max={0.99}
            min={0}
            onChange={(e) => handleAmountChange(e)}
            onFocus={() => handleAmountChange((+state.amount).toFixed(2))}
            prefix="$"
            readonly={disabled}
            step={0.01}
            theme={InputTheme.FORM}
            type="number"
            value={state.amount}
          />
        ) : (
          <LabeledInput
            description={
              flowType === FlowType.FINALIZE_RETURN ||
              (flowType === FlowType.FINALIZE_CLAIM &&
                "Total price of all selected items")
            }
            label=""
          >
            <TextInput
              min={0}
              onChange={(e) => handleAmountChange(e)}
              onFocus={() => handleAmountChange((+state.amount).toFixed(2))}
              prefix="$"
              readonly={disabled}
              theme={InputTheme.FORM}
              type="number"
              value={state.amount}
            />
          </LabeledInput>
        )}
      </>
    );
  },
  empty: { amount: (0).toFixed(2), priceOption: null },
  fromModel(model) {
    return { amount: model.amount.toFixed(2), priceOption: model.priceOption };
  },
  toModel(state) {
    return {
      type: ModelCondition.PRICE,
      amount: +state.amount,
      priceOption: state.priceOption,
    };
  },
  valid(state) {
    return +state.amount >= 0 && state.priceOption !== null;
  },
};

const DELIVERY_WITHIN: ConditionType<string, ModelCondition.ShippingWithin> = {
  name: "Time since delivery",
  description(state) {
    return `Within ${state} days of delivery`;
  },
  Details({ state, setState, disabled, flowType }) {
    return (
      <LabeledInput
        description={
          flowType === FlowType.FINALIZE_RETURN ||
          (flowType === FlowType.FINALIZE_CLAIM &&
            "If any selected item was not delivered within this number of days, the condition will not match")
        }
        label="Max days"
      >
        <TextInput
          min={0}
          onChange={setState}
          readonly={disabled}
          theme={InputTheme.FORM}
          type="number"
          value={state}
        />
      </LabeledInput>
    );
  },
  empty: String(30),
  fromModel(model) {
    return String(model.duration.total("days"));
  },
  toModel(state) {
    return {
      type: ModelCondition.DELIVERY_WITHIN,
      duration: Temporal.Duration.from({ days: +state }),
    };
  },
  valid(state) {
    return +state >= 0;
  },
};

const CUSTOMER_COUNTRY: ConditionType<
  readonly string[],
  ModelCondition.CustomerCountry
> = {
  name: "Customer country",
  description(state) {
    return `Countries: ${state.length} selected`;
  },
  Details({ state, setState, disabled }) {
    return (
      <LabeledInput label="Countries">
        <Autocomplete
          disabled={disabled}
          getLabel={(country) => COUNTRIES_BY_CODE[country] || country}
          keyFn={(country) => country}
          multiple
          options={COUNTRY_CODES}
          value={[...state]}
          valueChange={setState}
        >
          {(country) => COUNTRIES_BY_CODE[country] || country}
        </Autocomplete>
      </LabeledInput>
    );
  },
  empty: [],
  fromModel(model) {
    return model.countries;
  },
  toModel(state) {
    return { type: ModelCondition.CUSTOMER_COUNTRY, countries: state };
  },
  valid(state) {
    return (
      // Make sure all countries are valid.
      state.length > 0 && state.every((country) => !!COUNTRIES_BY_CODE[country])
    );
  },
};

const EXCHANGE_COUNT: ConditionType<string, ModelCondition.ExchangeCount> = {
  name: "Number of exchanges",
  description(state) {
    switch (state) {
      case undefined:
        return "";
      case "1":
        return "No previous exchanges";
      case "2":
        return "Maximum 1 previous exchange";
      default:
        return `Maximum ${+state - 1} previous exchanges`;
    }
  },
  Details({ state, setState, disabled }) {
    return (
      <LabeledInput label="Maximum allowed exchanges">
        <TextInput
          min={1}
          onChange={(e) => setState(e)}
          onFocus={() => setState((+state).toFixed(0))}
          readonly={disabled}
          theme={InputTheme.FORM}
          type="number"
          value={state}
        />
      </LabeledInput>
    );
  },
  empty: (1).toFixed(0),
  fromModel(model) {
    return model.numExchanges.toFixed(0);
  },
  toModel(state) {
    return { type: ModelCondition.EXCHANGE_COUNT, numExchanges: +state };
  },
  valid(state) {
    return +state >= 1 && +state % 1 === 0; // Must be whole number
  },
};

const ITEMS_BEING_RETURNED_COUNT: ConditionType<
  { numItemsOption: NumItemsBeingReturnedOption; numItems: string },
  ModelCondition.ItemsBeingReturnedCount
> = {
  name: "Number of items being returned",
  description(state) {
    return `Number of items ${state.numItemsOption.toLowerCase()} ${state.numItems}`;
  },
  Details({ state, setState, disabled }) {
    return (
      <>
        <LabeledInput label="Match type">
          <SelectDropdown
            disabled={disabled}
            options={Object.values(NumItemsBeingReturnedOption)}
            value={state.numItemsOption}
            valueChange={(e) => e && setState({ ...state, numItemsOption: e })}
          >
            {(type) => (
              <div className={selectCss.value}>
                <div className={selectCss.title}>{type}</div>
              </div>
            )}
          </SelectDropdown>
        </LabeledInput>
        <LabeledInput label="Number of items selected for return">
          <TextInput
            min={0}
            onChange={(e) => setState({ ...state, numItems: e })}
            onFocus={() =>
              setState({ ...state, numItems: (+state.numItems).toFixed(0) })
            }
            readonly={disabled}
            theme={InputTheme.FORM}
            type="number"
            value={state.numItems}
          />
        </LabeledInput>
      </>
    );
  },
  empty: { numItemsOption: NumItemsBeingReturnedOption.EQUALS, numItems: "1" },
  fromModel(model) {
    return {
      numItemsOption: model.numItemsOption,
      numItems: model.numItems.toFixed(0),
    };
  },
  toModel(state) {
    return {
      type: ModelCondition.ITEMS_BEING_RETURNED_COUNT,
      numItemsOption: state.numItemsOption,
      numItems: +state.numItems,
    };
  },
  valid(state) {
    return +state.numItems >= 0 && +state.numItems % 1 === 0; // Must be whole number
  },
};

const ADJUSTMENT_AMOUNT: ConditionType<
  { adjustmentAmountOption: AdjustmentAmountOption; adjustmentAmount: string },
  ModelCondition.AdjustmentAmount
> = {
  name: "Total adjustment amount",
  description(state) {
    return `Adjustment amount ${state.adjustmentAmountOption.toLowerCase()} ${CURRENCY_FORMAT().format(+state.adjustmentAmount)}`;
  },
  Details({ state, setState, disabled }) {
    return (
      <>
        <LabeledInput label="Match type">
          <SelectDropdown
            disabled={disabled}
            options={Object.values(AdjustmentAmountOption)}
            value={state.adjustmentAmountOption}
            valueChange={(e) =>
              e && setState({ ...state, adjustmentAmountOption: e })
            }
          >
            {(type) => (
              <div className={selectCss.value}>
                <div className={selectCss.title}>{type}</div>
              </div>
            )}
          </SelectDropdown>
        </LabeledInput>
        <LabeledInput label="Total adjustment amount of selected items">
          <TextInput
            onChange={(e) => setState({ ...state, adjustmentAmount: e })}
            onFocus={() =>
              setState({
                ...state,
                adjustmentAmount: (+state.adjustmentAmount).toFixed(2),
              })
            }
            prefix="$"
            readonly={disabled}
            theme={InputTheme.FORM}
            type="number"
            value={state.adjustmentAmount}
          />
        </LabeledInput>
      </>
    );
  },
  empty: {
    adjustmentAmountOption: AdjustmentAmountOption.EQUALS,
    adjustmentAmount: (0).toFixed(2),
  },
  fromModel(model) {
    return {
      adjustmentAmountOption: model.adjustmentAmountOption,
      adjustmentAmount: model.adjustmentAmount.toFixed(2),
    };
  },
  toModel(state) {
    return {
      type: ModelCondition.ADJUSTMENT_AMOUNT,
      adjustmentAmountOption: state.adjustmentAmountOption,
      adjustmentAmount: +state.adjustmentAmount,
    };
  },
  valid(state) {
    return true;
  },
};

const getLabelForReturnOption = (option: ReturnOptionMethod["type"]) => {
  return option === ReturnOptionMethod.EXCHANGE
    ? "Exchange"
    : option === ReturnOptionMethod.STORE_CREDIT
      ? "Store credit"
      : "Refund";
};

const RETURN_METHOD: ConditionType<
  { returnMethod: ReturnOptionMethod["type"] },
  ModelCondition.ReturnMethod
> = {
  name: "Return method",
  description(state) {
    return `Return method = ${getLabelForReturnOption(state.returnMethod)}`;
  },
  Details({ state, setState, disabled, flowType }) {
    return (
      <LabeledInput
        description={
          flowType === FlowType.FINALIZE_CLAIM &&
          "Condition will match if any selected items have this compensation method"
        }
        label={
          flowType === FlowType.FINALIZE_CLAIM
            ? "Compensation method"
            : "Return method"
        }
      >
        <SelectDropdown
          disabled={disabled}
          options={[
            ReturnOptionMethod.EXCHANGE,
            ReturnOptionMethod.REFUND,
            ReturnOptionMethod.STORE_CREDIT,
          ]}
          value={state.returnMethod}
          valueChange={(e?: ReturnOptionMethod["type"]) => {
            setState({ returnMethod: e! });
          }}
        >
          {(type: ReturnOptionMethod["type"]) => (
            <div className={selectCss.value}>
              <div className={selectCss.title}>
                {getLabelForReturnOption(type)}
              </div>
            </div>
          )}
        </SelectDropdown>
      </LabeledInput>
    );
  },
  empty: { returnMethod: ReturnOptionMethod.EXCHANGE },
  fromModel(model) {
    return { returnMethod: model.returnMethod };
  },
  toModel(state) {
    return {
      type: ModelCondition.RETURN_METHOD,
      returnMethod: state.returnMethod,
    };
  },
  valid(state) {
    return state.returnMethod !== undefined;
  },
};

const COMPENSATION_METHOD: ConditionType<
  { compensationMethod: ReturnOptionMethod["type"] },
  ModelCondition.CompensationMethod
> = {
  name: "Compensation method",
  description(state) {
    return `Compensation method = ${getLabelForReturnOption(
      state.compensationMethod,
    )}`;
  },
  Details({ state, setState, disabled }) {
    return (
      <LabeledInput
        description="Condition will match if any selected items have this compensation method"
        label="Compensation method"
      >
        <SelectDropdown
          disabled={disabled}
          options={[
            ReturnOptionMethod.EXCHANGE,
            ReturnOptionMethod.REFUND,
            ReturnOptionMethod.STORE_CREDIT,
          ]}
          value={state.compensationMethod}
          valueChange={(e?: ReturnOptionMethod["type"]) => {
            setState({ compensationMethod: e! });
          }}
        >
          {(type: ReturnOptionMethod["type"]) => (
            <div className={selectCss.value}>
              <div className={selectCss.title}>
                {type === ReturnOptionMethod.EXCHANGE
                  ? "Same item exchange"
                  : getLabelForReturnOption(type)}
              </div>
            </div>
          )}
        </SelectDropdown>
      </LabeledInput>
    );
  },
  empty: { compensationMethod: ReturnOptionMethod.EXCHANGE },
  fromModel(model) {
    return { compensationMethod: model.compensationMethod };
  },
  toModel(state) {
    return {
      type: ModelCondition.COMPENSATION_METHOD,
      compensationMethod: state.compensationMethod,
    };
  },
  valid(state) {
    return state.compensationMethod !== undefined;
  },
};

const NUM_CUSTOMER_CLAIMS: ConditionType<
  { numClaims: string; claimOption: NumClaimsOption | null },
  ModelCondition.NumCustomerClaims
> = {
  name: "Number of customer claims",
  description(state) {
    return this.valid(state)
      ? `Number of claims ${state.claimOption?.toLowerCase()} ${state.numClaims}`
      : "";
  },
  Details({ state, setState, disabled }) {
    return (
      <>
        <LabeledInput label="Comparison">
          <SelectDropdown
            disabled={disabled}
            options={Object.values(NumClaimsOption)}
            value={state.claimOption}
            valueChange={(e) => setState({ ...state, claimOption: e ?? null })}
          >
            {(type) => (
              <div className={selectCss.value}>
                <div className={selectCss.title}>{type}</div>
              </div>
            )}
          </SelectDropdown>
        </LabeledInput>
        <LabeledInput label="Number of claims">
          <TextInput
            min={0}
            onChange={(e) =>
              setState({ numClaims: e, claimOption: state.claimOption || null })
            }
            onFocus={() =>
              setState({ ...state, numClaims: (+state.numClaims).toFixed(0) })
            }
            readonly={disabled}
            theme={InputTheme.FORM}
            type="number"
            value={state.numClaims}
          />
        </LabeledInput>
      </>
    );
  },
  empty: { numClaims: (0).toFixed(0), claimOption: null },
  fromModel(model) {
    return {
      numClaims: model.numClaims.toFixed(0),
      claimOption: model.numClaimsOption,
    };
  },
  toModel(state) {
    return {
      type: ModelCondition.NUM_CUSTOMER_CLAIMS,
      numClaims: +state.numClaims,
      numClaimsOption: state.claimOption,
    };
  },
  valid(state) {
    return (
      state.claimOption !== null &&
      +state.numClaims >= 0 &&
      +state.numClaims % 1 === 0 // Must be whole number
    );
  },
};

const NUM_CUSTOMER_RETURNS: ConditionType<
  { numReturns: string; returnOption: NumReturnsOption | null },
  ModelCondition.NumCustomerReturns
> = {
  name: "Number of customer returns",
  description(state) {
    return this.valid(state)
      ? `Number of returns ${state.returnOption?.toLowerCase()} ${state.numReturns}`
      : "";
  },
  Details({ state, setState, disabled }) {
    return (
      <>
        <LabeledInput label="Comparison">
          <SelectDropdown
            disabled={disabled}
            options={Object.values(NumReturnsOption)}
            value={state.returnOption}
            valueChange={(e) => setState({ ...state, returnOption: e ?? null })}
          >
            {(type) => (
              <div className={selectCss.value}>
                <div className={selectCss.title}>{type}</div>
              </div>
            )}
          </SelectDropdown>
        </LabeledInput>
        <LabeledInput label="Number of returns">
          <TextInput
            min={0}
            onChange={(e) => setState({ ...state, numReturns: e })}
            onFocus={() =>
              setState({ ...state, numReturns: (+state.numReturns).toFixed(0) })
            }
            readonly={disabled}
            theme={InputTheme.FORM}
            type="number"
            value={state.numReturns}
          />
        </LabeledInput>
      </>
    );
  },
  empty: { numReturns: (0).toFixed(0), returnOption: null },
  fromModel(model) {
    return {
      numReturns: model.numReturns.toFixed(0),
      returnOption: model.numReturnsOption,
    };
  },
  toModel(state) {
    return {
      type: ModelCondition.NUM_CUSTOMER_RETURNS,
      numReturns: +state.numReturns,
      numReturnsOption: state.returnOption,
    };
  },
  valid(state) {
    return (
      state.returnOption !== null &&
      +state.numReturns >= 0 &&
      +state.numReturns % 1 === 0 // Must be whole number
    );
  },
};

const NUM_ORDERS: ConditionType<
  { numOrders: string; orderOption: NumOrdersOption | null },
  ModelCondition.NumOrders
> = {
  name: "Number of orders",
  description(state) {
    return this.valid(state)
      ? `Number of orders ${state.orderOption?.toLowerCase()} ${state.numOrders}`
      : "";
  },
  Details({ state, setState, disabled }) {
    return (
      <>
        <LabeledInput label="Comparison">
          <SelectDropdown
            disabled={disabled}
            options={Object.values(NumOrdersOption)}
            value={state.orderOption}
            valueChange={(e) => setState({ ...state, orderOption: e ?? null })}
          >
            {(type) => (
              <div className={selectCss.value}>
                <div className={selectCss.title}>{type}</div>
              </div>
            )}
          </SelectDropdown>
        </LabeledInput>
        <LabeledInput label="Number of orders">
          <TextInput
            min={0}
            onChange={(e) => setState({ ...state, numOrders: e })}
            onFocus={() =>
              setState({ ...state, numOrders: (+state.numOrders).toFixed(0) })
            }
            readonly={disabled}
            theme={InputTheme.FORM}
            type="number"
            value={state.numOrders}
          />
        </LabeledInput>
      </>
    );
  },
  empty: { numOrders: (0).toFixed(0), orderOption: null },
  fromModel(model) {
    return {
      numOrders: model.numOrders.toFixed(0),
      orderOption: model.numOrdersOption,
    };
  },
  toModel(state) {
    return {
      type: ModelCondition.NUM_ORDERS,
      numOrders: +state.numOrders,
      numOrdersOption: state.orderOption,
    };
  },
  valid(state) {
    return (
      state.orderOption !== null &&
      +state.numOrders >= 0 &&
      +state.numOrders % 1 === 0 // Must be whole number
    );
  },
};

const EXTENDED_WARRANTY_PURCHASED: ConditionType<
  void,
  ModelCondition.ExtendedWarrantyPurchased
> = {
  name: "Extended warranty purchased",
  description() {
    return "Extended warranty purchased";
  },
  Details() {
    return null;
  },
  empty: undefined,
  fromModel() {},
  toModel() {
    return { type: ModelCondition.EXTENDED_WARRANTY_PURCHASED };
  },
  valid() {
    return true;
  },
};

const EXTENDED_WARRANTY: ConditionType<{}, ModelCondition.ExtendedWarranty> = {
  name: "Extended warranty is live on item",
  description() {
    return "Extended warranty is live on item";
  },
  Details({ state, setState }) {
    return null;
  },
  empty: {},
  fromModel(model) {
    return {};
  },
  toModel(state) {
    return { type: ModelCondition.EXTENDED_WARRANTY };
  },
  valid(state) {
    return true;
  },
};

const RETURN_RATE: ConditionType<
  { returnRate: string; comparison: ReturnRateOption | null },
  ModelCondition.ReturnRate
> = {
  name: "Customer return rate",
  description(state) {
    return this.valid(state)
      ? `Customer return rate ${state.comparison?.toLowerCase()} ${state.returnRate}`
      : "";
  },
  Details({ state, setState, disabled }) {
    return (
      <>
        <LabeledInput label="Comparison">
          <SelectDropdown
            disabled={disabled}
            options={Object.values(ReturnRateOption)}
            value={state.comparison}
            valueChange={(value) =>
              setState({ ...state, comparison: value ?? null })
            }
          >
            {(type) => (
              <div className={selectCss.value}>
                <div className={selectCss.title}>{type}</div>
              </div>
            )}
          </SelectDropdown>
        </LabeledInput>
        <LabeledInput
          description="The percentage of orders that have been returned by the customer"
          label="Customer return rate"
        >
          <TextInput
            max={100}
            min={0}
            onChange={(value) => setState({ ...state, returnRate: value })}
            readonly={disabled}
            suffix="%"
            theme={InputTheme.FORM}
            type="number"
            value={state.returnRate}
          />
        </LabeledInput>
      </>
    );
  },
  empty: { returnRate: "0", comparison: null },
  fromModel(model) {
    const decimalStr = model.returnRate.toString();
    // Convert to percentage
    const formatted = (+decimalStr * 100).toFixed(0);
    return { returnRate: formatted, comparison: model.comparison };
  },
  toModel(state) {
    return {
      type: ModelCondition.RETURN_RATE,
      // Store as decimal
      returnRate: +state.returnRate / 100,
      comparison: state.comparison,
    };
  },
  valid(state) {
    return state.comparison !== null;
  },
};

const PAYMENT_METHOD: ConditionType<
  PaymentMethodOption | null,
  ModelCondition.PaymentMethod
> = {
  name: "Original Payment Method",
  description(state) {
    return state !== undefined ? `Payment method was: ${state}` : "";
  },
  Details({ state, setState, disabled }) {
    return (
      <LabeledInput label="Original payment method">
        <SelectDropdown
          disabled={disabled}
          options={Object.values(PaymentMethodOption)}
          value={state}
          valueChange={(e) => setState(e ?? null)}
        >
          {(method) => (
            <div className={selectCss.value}>
              <div className={selectCss.title}>{method}</div>
            </div>
          )}
        </SelectDropdown>
      </LabeledInput>
    );
  },
  empty: null,
  fromModel(model) {
    return model.method;
  },
  toModel(state) {
    return { type: ModelCondition.PAYMENT_METHOD, method: state };
  },
  valid() {
    return true;
  },
};

const platformMap = new Map<Provider, string>();
platformMap.set(Provider.SHOPIFY, "Shopify");
platformMap.set(Provider.COMMENTSOLD, "Comment Sold");
platformMap.set(Provider.COMMERCE_CLOUD, "Commerce Cloud");

const PLATFORM: ConditionType<Provider | null, ModelCondition.Platform> = {
  name: "Platform",
  description(state) {
    return state ? `Platform: ${platformMap.get(state)}` : "";
  },
  Details({ state, setState, disabled }) {
    return (
      <LabeledInput label="Platform">
        <SelectDropdown
          disabled={disabled}
          options={[...platformMap.values()]}
          value={state ? platformMap.get(state) : null}
          valueChange={(e) => {
            if (e === null || e === undefined) {
              setState(null);
            } else if (e === "Shopify") {
              setState(Provider.SHOPIFY);
            } else if (e === "Comment Sold") {
              setState(Provider.COMMENTSOLD);
            } else {
              setState(Provider.COMMERCE_CLOUD);
            }
          }}
        >
          {(method) => (
            <div className={selectCss.value}>
              <div className={selectCss.title}>{method}</div>
            </div>
          )}
        </SelectDropdown>
      </LabeledInput>
    );
  },
  empty: null,
  fromModel(model) {
    return model.method;
  },
  toModel(state) {
    return { type: ModelCondition.PLATFORM, method: state };
  },
  valid() {
    return true;
  },
};

export const AFTER_HOURS: ConditionType<void, ModelCondition.AfterHours> = {
  name: "Customer contacted after hours",
  description() {
    return "Customer contacted after hours";
  },
  Details() {
    return null;
  },
  empty: undefined,
  fromModel() {},
  toModel() {
    return { type: ModelCondition.AFTER_HOURS };
  },
  valid() {
    return true;
  },
};

const FULFILLMENT_TRACKING_UPDATE_WITHIN: ConditionType<
  string,
  ModelCondition.FulfillmentTrackingUpdateWithin
> = {
  name: "Time since last tracking update",
  description(numDays) {
    return `Within ${numDays} days of last tracking update`;
  },
  Details({ state, setState, disabled }) {
    return (
      <LabeledInput label="Max days">
        <TextInput
          min={0}
          onChange={setState}
          onFocus={() => setState((+state).toFixed(0))}
          readonly={disabled}
          theme={InputTheme.FORM}
          type="number"
          value={state}
        />
      </LabeledInput>
    );
  },
  empty: (30).toFixed(0),
  fromModel(model) {
    return model.duration.total("days").toFixed(0);
  },
  toModel(state) {
    return {
      type: ModelCondition.FULFILLMENT_TRACKING_UPDATE_WITHIN,
      duration: Temporal.Duration.from({ days: +state }),
    };
  },
  valid(state) {
    return +state >= 0 && +state % 1 === 0; // Must be whole number
  },
};

const types: { [K in Condition["type"]]: ConditionType<any, any> } = {
  [Condition.COLLECTION]: COLLECTION,
  [Condition.COVERAGE]: COVERAGE,
  [Condition.PACKAGE_PROTECTION]: PACKAGE_PROTECTION,
  [Condition.CUSTOMER_COUNTRY]: CUSTOMER_COUNTRY,
  [Condition.CUSTOMER_TAG]: CUSTOMER_TAG,
  [Condition.DISCOUNT]: DISCOUNT,
  [Condition.ITEM_DISCOUNT]: ITEM_DISCOUNT,
  [Condition.LOYALTY_EARNED]: LOYALTY_EARNED,
  [Condition.EXCHANGE_COUNT]: EXCHANGE_COUNT,
  [Condition.ITEMS_BEING_RETURNED_COUNT]: ITEMS_BEING_RETURNED_COUNT,
  [Condition.ADJUSTMENT_AMOUNT]: ADJUSTMENT_AMOUNT,
  [Condition.RETURN_METHOD]: RETURN_METHOD,
  [Condition.NUM_CUSTOMER_CLAIMS]: NUM_CUSTOMER_CLAIMS,
  [Condition.NUM_CUSTOMER_RETURNS]: NUM_CUSTOMER_RETURNS,
  [Condition.NUM_ORDERS]: NUM_ORDERS,
  [Condition.EXTENDED_WARRANTY]: EXTENDED_WARRANTY,
  [Condition.EXTENDED_WARRANTY_PURCHASED]: EXTENDED_WARRANTY_PURCHASED,
  [Condition.RETURN_RATE]: RETURN_RATE,
  [Condition.ORDER_DATE]: ORDER_DATE,
  [Condition.ORDER_TAG]: ORDER_TAG,
  [Condition.PAYMENT_METHOD]: PAYMENT_METHOD,
  [Condition.PLATFORM]: PLATFORM,
  [Condition.PRICE]: PRICE,
  [Condition.RETURN_VALUE]: RETURN_VALUE,
  [Condition.PRODUCT_PROPERTY]: PRODUCT_PROPERTY,
  [Condition.PRODUCT_TAG]: PRODUCT_TAG,
  [Condition.PRODUCT_TYPE]: PRODUCT_TYPE,
  [Condition.PRODUCT_VARIANT_METAFIELD]: PRODUCT_VARIANT_METAFIELD,
  [Condition.RETURN_DATE]: RETURN_DATE,
  [Condition.SALES_CHANNEL]: SALES_CHANNEL,
  [Condition.SKU]: SKU,
  [Condition.DELIVERY_WITHIN]: DELIVERY_WITHIN,
  [Condition.FULFILLMENT_WITHIN]: FULFILLMENT_WITHIN,
  [Condition.FINAL_SALE_RETURN]: FINAL_SALE_RETURN,
  [Condition.ORDERLESS]: ORDERLESS,
  [Condition.ORDER_WITHIN]: ORDER_WITHIN,
  [Condition.AFTER_HOURS]: AFTER_HOURS,
  [Condition.FULFILLMENT_TRACKING_UPDATE_WITHIN]:
    FULFILLMENT_TRACKING_UPDATE_WITHIN,
  [Condition.COMPENSATION_METHOD]: COMPENSATION_METHOD,
  [Condition.CUSTOMER_EMAIL_SUBSCRIBER]: CUSTOMER_EMAIL_SUBSCRIBER,
  [Condition.CUSTOMER_SMS_SUBSCRIBER]: CUSTOMER_SMS_SUBSCRIBER,
  [Condition.TICKET_CHANNEL]: TICKET_CHANNEL,
  [Condition.TOPIC]: TOPIC,
  [Condition.RECEIVED_AT_EMAIL]: RECEIVED_AT_EMAIL,
  [Condition.SENT_FROM_EMAIL]: SENT_FROM_EMAIL,
  [Condition.SUBJECT_LINE]: SUBJECT_LINE,
  [Condition.MESSAGE_BODY_MATCHES]: MESSAGE_BODY_MATCHES,
  [Condition.ORDER_COUNT_GREATER_THAN]: ORDER_COUNT_GREATER_THAN,
  [Condition.TOTAL_SPENT_GREATER_THAN]: TOTAL_SPENT_GREATER_THAN,
  [Condition.VENDOR]: VENDOR,
  [Condition.TRACKING_TYPE]: TRACKING_TYPE,
  [Condition.IS_REFUND_RETURN]: IS_REFUND_RETURN,
};

// Alphabetized by display name
const returnFlowOptions: ConditionType<any, any>[] = [
  COLLECTION,
  COVERAGE,
  CUSTOMER_COUNTRY,
  CUSTOMER_TAG,
  DISCOUNT,
  ITEM_DISCOUNT,
  LOYALTY_EARNED,
  NUM_CUSTOMER_CLAIMS,
  NUM_CUSTOMER_RETURNS,
  EXCHANGE_COUNT,
  NUM_ORDERS,
  RETURN_RATE,
  ORDER_DATE,
  ORDER_TAG,
  PAYMENT_METHOD,
  PLATFORM,
  PRICE,
  PRODUCT_PROPERTY,
  PRODUCT_TAG,
  PRODUCT_TYPE,
  PRODUCT_VARIANT_METAFIELD,
  RETURN_DATE,
  SALES_CHANNEL,
  SKU,
  DELIVERY_WITHIN,
  FULFILLMENT_WITHIN,
  FINAL_SALE_RETURN,
  ORDER_WITHIN,
  ORDERLESS,
];

// Alphabetized by display name
const finalizeReturnFlowOptions: ConditionType<any, any>[] = [
  COLLECTION,
  COVERAGE,
  CUSTOMER_COUNTRY,
  CUSTOMER_TAG,
  DISCOUNT,
  NUM_CUSTOMER_CLAIMS,
  NUM_CUSTOMER_RETURNS,
  EXCHANGE_COUNT,
  ITEMS_BEING_RETURNED_COUNT,
  NUM_ORDERS,
  RETURN_RATE,
  ORDER_DATE,
  ORDER_TAG,
  PAYMENT_METHOD,
  PLATFORM,
  PRICE,
  RETURN_VALUE,
  PRODUCT_PROPERTY,
  PRODUCT_TAG,
  RETURN_DATE,
  RETURN_METHOD,
  SALES_CHANNEL,
  SKU,
  DELIVERY_WITHIN,
  FULFILLMENT_WITHIN,
  ORDER_WITHIN,
  ADJUSTMENT_AMOUNT,
];

// Alphabetized by display name
const finalizeClaimFlowOptions: ConditionType<any, any>[] = [
  COLLECTION,
  COMPENSATION_METHOD,
  COVERAGE,
  PACKAGE_PROTECTION,
  CUSTOMER_COUNTRY,
  CUSTOMER_TAG,
  DISCOUNT,
  NUM_CUSTOMER_CLAIMS,
  NUM_CUSTOMER_RETURNS,
  EXCHANGE_COUNT,
  NUM_ORDERS,
  RETURN_RATE,
  ORDER_DATE,
  ORDER_TAG,
  PAYMENT_METHOD,
  PLATFORM,
  PRICE,
  PRODUCT_PROPERTY,
  PRODUCT_TAG,
  RETURN_DATE,
  SALES_CHANNEL,
  SKU,
  DELIVERY_WITHIN,
  FULFILLMENT_WITHIN,
  ORDER_WITHIN,
  ADJUSTMENT_AMOUNT,
];

// Alphabetized by display name
const claimFlowOptions: ConditionType<any, any>[] = [
  COLLECTION,
  COVERAGE,
  PACKAGE_PROTECTION,
  CUSTOMER_COUNTRY,
  CUSTOMER_TAG,
  DISCOUNT,
  ITEM_DISCOUNT,
  LOYALTY_EARNED,
  NUM_CUSTOMER_CLAIMS,
  NUM_CUSTOMER_RETURNS,
  EXCHANGE_COUNT,
  NUM_ORDERS,
  RETURN_RATE,
  ORDER_DATE,
  ORDER_TAG,
  PAYMENT_METHOD,
  PLATFORM,
  PRICE,
  PRODUCT_PROPERTY,
  PRODUCT_TAG,
  PRODUCT_TYPE,
  PRODUCT_VARIANT_METAFIELD,
  RETURN_DATE,
  SALES_CHANNEL,
  SKU,
  DELIVERY_WITHIN,
  FULFILLMENT_WITHIN,
  ORDER_WITHIN,
  FULFILLMENT_TRACKING_UPDATE_WITHIN,
  ORDERLESS,
];

// Alphabetized by display name
const emailFlowOptions: ConditionType<any, any>[] = [
  CUSTOMER_EMAIL_SUBSCRIBER,
  CUSTOMER_SMS_SUBSCRIBER,
  CUSTOMER_COUNTRY,
  PRODUCT_TAG,
  PRODUCT_PROPERTY,
  SKU,
  ORDER_TAG,
  COLLECTION,
];

const discountFlowOptions: ConditionType<any, any>[] = [
  ORDER_COUNT_GREATER_THAN,
  CUSTOMER_TAG,
  TOTAL_SPENT_GREATER_THAN,
  // COLLECTION,
  // SKU,
  // PRODUCT_TAG,
  VENDOR,
  TRACKING_TYPE,
  IS_REFUND_RETURN,
];

const ruleFlowOptions: ConditionType<any, any>[] = [
  AFTER_HOURS,
  TICKET_CHANNEL,
  TOPIC,
  RECEIVED_AT_EMAIL,
  SENT_FROM_EMAIL,
  SUBJECT_LINE,
  MESSAGE_BODY_MATCHES,
];

const warrantyFlowOptions: ConditionType<any, any>[] = [
  ...claimFlowOptions,
  EXTENDED_WARRANTY,
  EXTENDED_WARRANTY_PURCHASED,
];

// Alphabetized by display name
const chatFlowOptions: ConditionType<any, any>[] = [AFTER_HOURS];

function optionLabel(option: ConditionType<any, any>) {
  return option.name;
}

const flowTypeToOptions = {
  [FlowType.CHAT]: chatFlowOptions,
  [FlowType.CLAIM]: claimFlowOptions,
  [FlowType.FINALIZE_RETURN]: finalizeReturnFlowOptions,
  [FlowType.FINALIZE_CLAIM]: finalizeClaimFlowOptions,
  [FlowType.EMAIL]: emailFlowOptions,
  [FlowType.DISCOUNT]: discountFlowOptions,
  [FlowType.RULE]: ruleFlowOptions,
  [FlowType.RETURN]: returnFlowOptions,
  [FlowType.WARRANTY]: warrantyFlowOptions,
  [FlowType.WARRANTY_REGISTRATION]: warrantyFlowOptions,
};

export const Details = memo(function Details({
  stepSelect,
  state,
  setState,
  flowType,
  onDeleteChild,
}: StepTypeDetailsProps<State>) {
  const user = useContext(UserContext);
  const canEditSettings =
    !!user && permitted(user.permissions, Permission.EDIT_SETTINGS);
  const setConditionState = useHandler((conditionState: any) => {
    setState((state) => ({ ...state, conditionState }));
  });
  const handleType = useHandler(
    async (conditionType: ConditionType<any, any>) => {
      setState((state) => ({
        ...state,
        conditionType,
        conditionState: conditionType!.empty,
      }));
    },
  );
  return (
    <>
      <LabeledInput
        label={
          onDeleteChild ? (
            <div className={multipleStepsCss.labelWrapper}>
              <p>Condition</p>{" "}
              <IconButton onClick={onDeleteChild} size={ButtonSize.SMALL}>
                <TrashIcon />
              </IconButton>
            </div>
          ) : (
            "Condition"
          )
        }
      >
        <SelectDropdown
          disabled={!canEditSettings}
          options={flowTypeToOptions[flowType]}
          value={state.conditionType}
          valueChange={handleType}
        >
          {optionLabel}
        </SelectDropdown>
      </LabeledInput>
      {state.conditionType && (
        <state.conditionType.Details
          disabled={!canEditSettings}
          flowType={flowType}
          setState={setConditionState}
          state={state.conditionState}
        />
      )}
      {flowType !== FlowType.RULE && (
        <>
          <LabeledInput
            errors={
              flowType === FlowType.FINALIZE_RETURN ||
              flowType === FlowType.FINALIZE_CLAIM ||
              state.nextTrue
                ? []
                : ["Required"]
            }
            label="If matches, then"
          >
            {stepSelect({
              value: state.nextTrue,
              valueChange: (nextTrue) =>
                setState((state) => ({ ...state, nextTrue })),
            })}
          </LabeledInput>
          <LabeledInput
            errors={
              flowType === FlowType.FINALIZE_RETURN ||
              flowType === FlowType.FINALIZE_CLAIM ||
              state.nextFalse
                ? []
                : ["Required"]
            }
            label="If does not match, then"
          >
            {stepSelect({
              value: state.nextFalse,
              valueChange: (nextFalse) =>
                setState((state) => ({ ...state, nextFalse })),
            })}
          </LabeledInput>
        </>
      )}
    </>
  );
});

export const CONDITION: StepType<State, ModelStep.Condition> = {
  Details,
  customTitle(state) {
    return state.customTitle;
  },
  Icon: InfoIcon,
  description(state) {
    return state.conditionType
      ? state.conditionType.description(state.conditionState)
      : "";
  },
  downstream(state) {
    const result: StepDownstream[] = [];
    if (state.nextFalse !== undefined) {
      result.push({ id: state.nextFalse, label: "False" });
    }
    if (state.nextTrue !== undefined) {
      result.push({ id: state.nextTrue, label: "True" });
    }
    return result;
  },
  empty: {
    conditionType: undefined,
    nextFalse: undefined,
    nextTrue: undefined,
  },
  fromModel(model, id) {
    const type = types[model.condition.type];
    return {
      customTitle: model.customTitle,
      conditionType: type,
      nextFalse: model.nextFalse ? id(model.nextFalse) : undefined,
      nextTrue: model.nextTrue ? id(model.nextTrue) : undefined,
      conditionState: type.fromModel(model.condition),
    };
  },
  layout() {
    return BlockLayout.FULL;
  },
  stepDeleted(state, stepId) {
    return produce(state, (state) => {
      if (state.nextFalse === stepId) {
        state.nextFalse = undefined;
      }
      if (state.nextTrue === stepId) {
        state.nextTrue = undefined;
      }
    });
  },
  title: "Condition",
  toModel(state, id) {
    return {
      customTitle: state.customTitle,
      type: ModelStep.CONDITION,
      condition: state.conditionType!.toModel(state.conditionState),
      nextTrue: state.nextTrue ? id(state.nextTrue) : undefined,
      nextFalse: state.nextFalse ? id(state.nextFalse!) : undefined,
    };
  },
  valid: State.valid,
};
