import { useRequiredContext } from "@redotech/react-util/context";
import { FolderData } from "@redotech/redo-model/support/folder-types";
import { RedoCheckbox } from "@redotech/redo-web/arbiter-components/checkbox/redo-checkbox";
import { RedoTextInput } from "@redotech/redo-web/arbiter-components/input/redo-text-input";
import {
  RedoModal,
  RedoModalButtonProps,
} from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import TrashIcon from "@redotech/redo-web/arbiter-icon/trash-01.svg";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo, useEffect, useState } from "react";
import { DefaultViews, ViewsContext } from "../app/views";
import { ConfirmDeleteFolderModal } from "./confirm-delete-folder-modal";
import { viewUrlNameToDisplayName } from "./view-name-utils";

export const EditFolderModal = memo(function EditFolderModal({
  isOpen,
  onClose,
  folder,
  onSubmit,
  deleteFolder,
}: {
  isOpen: boolean;
  onClose: () => void;
  folder: FolderData;
  onSubmit: (newFolder: FolderData) => void;
  deleteFolder: (folderId: string) => Promise<void>;
}) {
  const [editedFolder, setEditedFolder] = useState<FolderData>(folder);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  useEffect(() => {
    if (folder) {
      setEditedFolder({ ...folder });
    }
  }, [folder]);

  const handleFolderNameChange = (newName: string) => {
    setEditedFolder((prev) => {
      if (prev) {
        return { ...prev, name: newName };
      }
      return prev;
    });
  };

  const handleViewSelect = (viewId: string, checked: boolean) => {
    if (Object.values(DefaultViews).some((view) => view === viewId)) {
      setEditedFolder((prev) => {
        if (prev) {
          return {
            ...prev,
            defaultViews: checked
              ? [...prev.defaultViews, viewId]
              : prev.defaultViews.filter((id) => id !== viewId),
          };
        }
        return prev;
      });
    } else {
      setEditedFolder((prev) => {
        if (prev) {
          return {
            ...prev,
            viewIds: checked
              ? [...prev.viewIds, viewId]
              : prev.viewIds.filter((id) => id !== viewId),
          };
        }
        return prev;
      });
    }
  };

  const viewsContext = useRequiredContext(ViewsContext);

  const allViews = [
    ...Object.values(DefaultViews).map((view) => ({
      _id: view,
      name: viewUrlNameToDisplayName(view),
    })),
    ...viewsContext,
  ].map((view) => ({ _id: view?._id || view?.name, name: view?.name }));

  const submitDisabled = !editedFolder?.name.trim().length || isSubmitting;

  const handleSubmit = async () => {
    if (editedFolder && folder) {
      try {
        setIsSubmitting(true);
        if (
          editedFolder?.viewIds.length === 0 &&
          editedFolder?.defaultViews.length === 0
        ) {
          await deleteFolder(folder._id);
          onClose();
          return;
        }
        onSubmit(editedFolder);
        onClose();
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const primaryButton: RedoModalButtonProps = {
    text: "Submit",
    onClick: handleSubmit,
    disabled: submitDisabled,
  };

  const secondaryButton: RedoModalButtonProps = {
    text: "Delete",
    onClick: () => setShowConfirmDelete(true),
    disabled: isSubmitting,
    theme: "destructive",
    IconLeading: TrashIcon,
  };

  return (
    <>
      <RedoModal
        buttonPlacement="full"
        isOpen={isOpen}
        onModalCloseRequested={isSubmitting ? () => {} : onClose}
        primaryButton={primaryButton}
        secondaryButton={secondaryButton}
        title="Edit folder"
      >
        <Flex dir="column" gap="xl">
          <Flex dir="column">
            <Text>Folder name</Text>
            <div data-devinid="folder-name-input">
              <RedoTextInput
                setValue={handleFolderNameChange}
                size="md"
                state={isSubmitting ? "readonly" : "default"}
                value={editedFolder?.name || ""}
              />
            </div>
          </Flex>
          <Text>Select views</Text>
          <Flex dir="column" gap="md" pl="sm">
            {allViews.map((view) => (
              <div key={view._id}>
                <Flex>
                  <RedoCheckbox
                    label={view.name}
                    setValue={(val: boolean) => handleViewSelect(view._id, val)}
                    size="md"
                    value={
                      editedFolder?.viewIds.includes(view._id) ||
                      editedFolder?.defaultViews.includes(view._id) ||
                      false
                    }
                  />
                </Flex>
              </div>
            ))}
          </Flex>
        </Flex>
      </RedoModal>
      <ConfirmDeleteFolderModal
        folderName={editedFolder?.name || ""}
        isOpen={showConfirmDelete && !!editedFolder}
        onClose={() => setShowConfirmDelete(false)}
        onConfirm={async () => {
          if (editedFolder) {
            await deleteFolder(editedFolder._id);
            onClose();
          }
        }}
      />
    </>
  );
});
