import type { InferRpcDefinition } from "@redotech/rpc/definition";
import { getCheckoutOptimizationAnalyticsAssumptions } from "./schema/analytics/get-checkout-optimization-analytics-assumptions";
import { getThresholdUpsellWidgetAnalytics } from "./schema/analytics/get-threshold-upsell-widget-analytics";
import { getWidgetsSummaryMetrics } from "./schema/analytics/get-widgets-summary-metrics";
import { getWidgetsTimeSeriesMetrics } from "./schema/analytics/get-widgets-time-series-metrics";
import { updateCheckoutOptimizationAnalyticsAssumptions } from "./schema/analytics/update-checkout-optimization-analytics-assumptions";
import { generatePdfForInvoice } from "./schema/billing/generate-pdf-for-invoice";
import { getCurrentCheckoutOptimizationInvoice } from "./schema/billing/get-current-checkout-optimization-invoice";
import { getFinalizedServiceInvoices } from "./schema/billing/get-finalized-service-invoices";
import { calculateCheckoutSplitResults } from "./schema/calculate-checkout-split-results";
import { canStartCheckoutAbTest } from "./schema/can-start-checkout-ab-test";
import { updateCheckoutExperienceWidgets } from "./schema/checkout-experiences/update-checkout-experience-widgets";
import { concludeCheckoutAbTest } from "./schema/conclude-checkout-ab-test";
import { createCheckoutExperience } from "./schema/create-checkout-experience";
import { createCheckoutSplitFromBuilder } from "./schema/create-checkout-split-from-builder";
import { deleteCheckoutExperience } from "./schema/delete-checkout-experience";
import { deleteCheckoutSplit } from "./schema/delete-checkout-split";
import { duplicateCheckoutExperience } from "./schema/duplicate-checkout-experience";
import { getAllCheckoutSplitMetrics } from "./schema/get-all-checkout-split-metrics";
import { getCheckoutExperienceById } from "./schema/get-checkout-experience-by-id";
import { getCheckoutExperiences } from "./schema/get-checkout-experiences";
import { getCheckoutExperiencesForAbTestBuilder } from "./schema/get-checkout-experiences-for-ab-test-builder";
import { getCheckoutOptimizationRevenuePerCheckoutExperienceMetrics } from "./schema/get-checkout-optimization-revenue-per-checkout-experience-metrics";
import { getCheckoutOptimizationSummaryMetrics } from "./schema/get-checkout-optimization-summary-metrics";
import { getCheckoutOptimizationTimeSeriesMetrics } from "./schema/get-checkout-optimization-time-series-metrics";
import { getCheckoutSplitDetails } from "./schema/get-checkout-split-details";
import { getCheckoutSplits } from "./schema/get-checkout-splits";
import { getFulfillmentCutoffSchedule } from "./schema/get-fulfillment-cutoff-schedule";
import { getRateTableDescriptionById } from "./schema/get-rate-table-description-by-id";
import { setCheckoutExperienceActive } from "./schema/set-checkout-experience-active";
import { updateCheckoutExperience } from "./schema/update-checkout-experience";
import { updateFulfillmentCutoffSchedule } from "./schema/update-fulfillment-cutoff-schedule";

const analytics = {
  getWidgetsTimeSeriesMetrics,
  getWidgetsSummaryMetrics,
  getCheckoutOptimizationAnalyticsAssumptions,
  updateCheckoutOptimizationAnalyticsAssumptions,
  getThresholdUpsellWidgetAnalytics,
};

const billing = {
  getCurrentCheckoutOptimizationInvoice,
  getFinalizedServiceInvoices,
  generatePdfForInvoice,
};

const checkoutExperiences = { updateCheckoutExperienceWidgets };

export const rpcDefinition = {
  getCheckoutOptimizationSummaryMetrics,
  getCheckoutOptimizationTimeSeriesMetrics,
  getCheckoutOptimizationRevenuePerCheckoutExperienceMetrics,
  createCheckoutSplitFromBuilder,
  calculateCheckoutSplitResults,
  getCheckoutSplitDetails,
  getAllCheckoutSplitMetrics,
  deleteCheckoutSplit,
  getCheckoutSplits,
  getFulfillmentCutoffSchedule,
  updateCheckoutExperience,
  updateFulfillmentCutoffSchedule,
  setCheckoutExperienceActive,
  createCheckoutExperience,
  duplicateCheckoutExperience,
  deleteCheckoutExperience,
  getCheckoutExperiences,
  getCheckoutExperiencesForAbTestBuilder,
  getCheckoutExperienceById,
  canStartCheckoutAbTest,
  getRateTableDescriptionById,
  concludeCheckoutAbTest,
  ...billing,
  ...analytics,
  ...checkoutExperiences,
};

export type RedoConversionRpcDefinition = InferRpcDefinition<
  typeof rpcDefinition
>;
