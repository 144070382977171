import { z } from "zod";

export const PrinterCapabilitiesSchema = z.object({
  // Paper tray/output bin names supported by printer
  bins: z.array(z.string()).nullish(),

  // Indicates if printer supports print copy collation
  collate: z.boolean().nullish(),

  // Indicates if printer is color-capable (false = grayscale)
  color: z.boolean().nullish(),

  // Maximum number of copies supported
  copies: z.number().nullish(),

  // Supported print resolution settings
  dpis: z.array(z.string()).nullish(),

  // Indicates two-sided printing capability
  duplex: z.boolean().nullish(),

  // Minimum and maximum supported paper sizes in tenths of millimeters
  // Format: [[min width, min height], [max width, max height]]
  extent: z.array(z.array(z.number())).nullish(),

  // Supported media types
  medias: z.array(z.string()).nullish(),

  // Supported "N pages per sheet" printing options
  nup: z.array(z.number()).nullish(),

  // Supported paper sizes, keys are paper names, values are [width, height] in tenths of millimeters
  papers: z.record(z.array(z.number().nullable()).nullable()),

  // Printer's reported print speed
  printrate: z.object({ unit: z.string(), rate: z.number() }).nullish(),

  // Indicates if custom paper sizes are supported
  supports_custom_paper_size: z.boolean().nullish(),
});

export const PrinterSchema = z.object({
  id: z.number(),
  computer: z.object({
    id: z.number(),
    name: z.string(),
    hostname: z.string().nullable(),
    version: z.string().nullable(),
    state: z.string(),
  }),
  name: z.string(),
  description: z.string(),
  capabilities: PrinterCapabilitiesSchema.nullable(),
  state: z.string(),
});

export const PrinterOptionsSchema = z.object({
  // The name of one of the paper trays or output bins
  bin: z.string().optional(),

  // Enables print copy collation when printing multiple copies
  collate: z.boolean().optional(),

  // Set to false for grayscale printing
  color: z.boolean().optional(),

  // Number of copies to be printed
  copies: z.number().optional(),

  // DPI setting to use for this print job
  dpi: z.string().optional(),

  // Duplex printing option
  duplex: z
    .union([
      z.literal("long-edge"),
      z.literal("short-edge"),
      z.literal("one-sided"),
    ])
    .optional(),

  // Automatically fit the document to the page
  fit_to_page: z.boolean().optional(),

  // The name of the medium to use for this print job
  media: z.string().optional(),

  // Allows printing multiple pages per physical sheet of paper
  nup: z.number().optional(),

  // Specifies a set of pages to print from a PDF
  pages: z.string().optional(),

  // The name of the paper size to use for the print job
  paper: z.string().optional(),

  // The rotation angle for each page in the print
  rotate: z
    .union([z.literal(0), z.literal(90), z.literal(180), z.literal(270)])
    .optional(),
});

export interface PrinterCapabilities
  extends z.infer<typeof PrinterCapabilitiesSchema> {}
export interface Printer extends z.infer<typeof PrinterSchema> {}
export interface PrinterOptions extends z.infer<typeof PrinterOptionsSchema> {}
