import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { ActionType, Assign } from "@redotech/redo-model/return-flow/action";
import { GetUser } from "@redotech/redo-model/user";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { SelectDropdown } from "@redotech/redo-web/select-dropdown";
import { useContext } from "react";
import * as selectCss from "../return-flow/select.module.css";
import { ActionTypeSetup } from "./multiple-actions";

export const ASSIGN: ActionTypeSetup<
  { user: { id: string; name: string } },
  Assign
> = {
  name: "Assign to agent",
  description() {
    return "Assign the ticket to an agent";
  },
  Details({ state, setState, disabled }) {
    const team = useContext(TeamContext);
    const users = (team?.users || []).map((teamUser) => {
      return {
        id: (teamUser.user as GetUser)._id,
        name: (teamUser.user as GetUser).name,
      };
    });
    return (
      <LabeledInput label="User">
        <SelectDropdown
          disabled={disabled}
          options={users}
          value={state.user}
          valueChange={(e) => {
            if (e) {
              setState({ user: e });
            }
          }}
        >
          {(user: { id: string; name: string }) => {
            return (
              <div className={selectCss.value}>
                <div className={selectCss.title}>{user.name}</div>
              </div>
            );
          }}
        </SelectDropdown>
      </LabeledInput>
    );
  },
  empty: { user: { id: "", name: "" } },
  fromModel(model) {
    return { user: model.user };
  },
  toModel(state) {
    return { type: ActionType.Assign, user: state.user };
  },
  valid(state) {
    return !!state.user?.id;
  },
};
