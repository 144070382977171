// extracted by mini-css-extract-plugin
export var checkbox = "_39-0";
export var circle = "_39-3";
export var horizontalContainer = "_39-7";
export var sm = "_39-5";
export var switch0 = "_39-2";
export var switchDisabled = "_39-8";
export var switchEnabled = "_39-1";
export var textSwitchContainer = "_39-9";
export var textSwitchOption = "_39-a";
export var textSwitchOptionSelected = "_39-b";
export var verticalContainer = "_39-6";
export var xs = "_39-4";