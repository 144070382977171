import {
  customerGroupSchema,
  CustomerGroupType,
} from "@redotech/redo-model/customers/customer-group-definition";
import { z } from "zod";

export const fetchTeamSegments = {
  input: z.object({ segmentType: z.nativeEnum(CustomerGroupType).optional() }),
  output: z.array(customerGroupSchema),
};
