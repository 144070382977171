import { baseProductFilterSchema } from "@redotech/redo-model/product-filters/product-filters-db-parser";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const getProductFiltersByTeam = {
  input: z.object({ teamId: z.string() }),
  output: z.array(
    z.intersection(z.object({ _id: zExt.objectId() }), baseProductFilterSchema),
  ),
};
