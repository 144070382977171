import { z } from "zod";

export const getReturnItemBinLocation = {
  input: z.object({ returnId: z.string(), lineItemId: z.string() }),
  output: z.object({ binLocation: z.string().nullish() }),
};

export type GetReturnItemBinLocationOutput = z.infer<
  typeof getReturnItemBinLocation.output
>;
