import { RedoMerchantClient } from "@redotech/redo-merchant-app-common/client";
import { REDO_API_URL } from "@redotech/redo-merchant-app-common/config";
import {
  ConversationPlatform,
  ExpandedConversation,
  MessageVisibility,
} from "@redotech/redo-model/conversation";
import {
  AssigneesFilter,
  AssigneesFilterType,
  ConversationFiltersV3,
  ConversationTagFilterType,
  ConversationTagsFilter,
  FilterGroupFilterOption,
  FiltersStatus,
  MentionsUsersFilter,
} from "@redotech/redo-model/conversation-filters/conversation-filters";
import { SortDirection } from "@redotech/redo-model/tables/table";
import { CloseActionMethod, Team } from "@redotech/redo-model/team";
import { GetUser } from "@redotech/redo-model/user";
import { DragEvent } from "react";
import { updateConversation } from "../client/conversations";

export const getPresetViewFilters = (
  viewName: string,
  user?: GetUser,
): ConversationFiltersV3 | null => {
  const assigneeFilter: AssigneesFilter[] = user
    ? [
        {
          type: FilterGroupFilterOption.ASSIGNEES,
          value: [user._id],
          query: AssigneesFilterType.INCLUDES,
        },
      ]
    : [];

  const mentionsFilter: MentionsUsersFilter[] = user
    ? [{ type: FilterGroupFilterOption.MENTIONS, value: [user._id] }]
    : [];

  const spamFilter: ConversationTagsFilter = {
    type: FilterGroupFilterOption.CONVERSATION_TAGS,
    value: ["spam"],
    query: ConversationTagFilterType.ANY_OF,
  };

  switch (viewName) {
    case "all":
      return { status: FiltersStatus.OPEN, advancedFilters: [] };
    case "assigned":
      return { advancedFilters: assigneeFilter, status: FiltersStatus.OPEN };
    case "sent":
      return {
        advancedFilters: [],
        sort: { key: "lastMerchantResponse", direction: SortDirection.DESC },
      };
    case "spam":
      return { status: FiltersStatus.OPEN, advancedFilters: [spamFilter] };
    case "mentions":
      return { status: FiltersStatus.OPEN, advancedFilters: mentionsFilter };
    case "drafts":
      return { drafts: true, advancedFilters: [] };
    default:
      return null;
  }
};

export const getConversationActivityStream = ({
  authorization,
  signal,
}: {
  authorization: { Authorization: string };
  signal: AbortSignal;
}) => {
  // See comment in getConversationsStream
  const url = `${REDO_API_URL}/conversations/conversation-activity?rand=${Math.random()}`;
  return fetch(url, { signal, headers: authorization });
};

export const sendConversationActivityUpdate = async ({
  authorization,
  signal,
  activeConversationId,
  prevConversationId,
}: {
  authorization: { Authorization: string };
  signal?: AbortSignal;
  activeConversationId?: string;
  prevConversationId?: string;
}) => {
  const url = `${REDO_API_URL}/conversations/conversation-activity`;
  await fetch(url, {
    signal,
    method: "POST",
    headers: { "Content-Type": "application/json", ...authorization },
    body: JSON.stringify({ activeConversationId, prevConversationId }),
  });
};

export const removeSimpleHTMLTags = (message: string) => {
  const regex = /(<(i|b|u)>)|(<a href=.*?>)|(<\/(i|b|u|a)>)/g;
  return message.replaceAll(regex, "").trim();
};

export const closeTicket = async (
  client: RedoMerchantClient,
  conversation: ExpandedConversation,
  setActiveConversation: (val: ExpandedConversation | undefined) => void,
  team: Team,
  inDetailView?: boolean,
  nextConversation?: ExpandedConversation,
): Promise<ExpandedConversation | undefined> => {
  const closePromise = updateConversation(client, conversation, {
    status: "closed",
    snoozedUntil: null,
  });
  let newActiveConversation;
  if (inDetailView) {
    if (team.settings.support?.closeAction === CloseActionMethod.NEXT) {
      newActiveConversation = nextConversation;
    } else if (team.settings.support?.closeAction === CloseActionMethod.STAY) {
      newActiveConversation = { ...conversation, status: "closed" };
    } else if (team.settings.support?.closeAction === CloseActionMethod.TABLE) {
      newActiveConversation = undefined;
    }
    setActiveConversation(newActiveConversation);
  }
  await closePromise;
  return newActiveConversation;
};

export const getNextBatch = async (
  iterator: AsyncIterator<{ conversations: ExpandedConversation[] }>,
): Promise<{
  done: boolean;
  value: { conversations: ExpandedConversation[] };
}> => {
  let done: boolean;
  let value: { conversations: ExpandedConversation[] };
  try {
    const results = await iterator.next();
    done = results.done || false;
    value = results.value;
  } catch {
    done = true;
    value = { conversations: [] };
  }
  if (done && iterator.return) {
    await iterator.return();
  }
  return { done, value };
};

const iterateOverHtmlDoc = (
  element: HTMLHtmlElement | ChildNode | null,
  messagePieces: string[],
) => {
  if (element) {
    const nodeList = element.childNodes;
    if (nodeList?.length) {
      nodeList.forEach((node) => {
        // Skip style and comment tags
        if (node.nodeName === "STYLE" || node.nodeName === "#comment") {
          return;
        }
        if (node.nodeType === 3 && node.nodeValue && node.nodeValue.trim()) {
          // Text node
          messagePieces.push(node.nodeValue);
        } else {
          // Element node
          if (node.nodeType === 1) {
            if (node.nodeName === "A") {
              messagePieces.push(
                `<a href="${(node as HTMLAnchorElement).href}">`,
              );
            }
            // Handle elements that always cause line breaks
            if (nonCollapsingElements.includes(node.nodeName)) {
              messagePieces.push("\n(noncollapse)");
            }
          }

          // When divs or other similar elements open, add a line break if there isn't already one
          // Don't add line breaks from divs at the beginning, before any content
          if (
            node.nodeType === 1 &&
            collapsingElements.includes(node.nodeName) &&
            messagePieces.length &&
            messagePieces[messagePieces.length - 1] !== "\n(collapse)" &&
            messagePieces[messagePieces.length - 1] !== "\n(noncollapse)"
          ) {
            messagePieces.push("\n(collapse)");
          }

          iterateOverHtmlDoc(node, messagePieces);
          if (node.nodeType === 1 && node.nodeName === "A") {
            messagePieces.push("</a>");
          }

          // When divs or other similar elements close, add a line break if there isn't already one
          // Don't add line breaks from divs at the beginning, before any content
          if (
            node.nodeType === 1 &&
            collapsingElements.includes(node.nodeName) &&
            messagePieces.length &&
            messagePieces[messagePieces.length - 1] !== "\n(collapse)" &&
            messagePieces[messagePieces.length - 1] !== "\n(noncollapse)"
          ) {
            messagePieces.push("\n(collapse)");
          }
        }
      });
    }
  }
};

const iterateOverHtmlDocForComments = (
  element: HTMLHtmlElement | ChildNode | null,
  messagePieces: string[],
) => {
  if (element) {
    const nodeList = element.childNodes;
    if (nodeList?.length) {
      nodeList.forEach((node) => {
        if (node.nodeName === "#comment") {
          node.remove();
        } else {
          messagePieces.push(node.nodeValue || "");
        }
        iterateOverHtmlDocForComments(node, messagePieces);
      });
    }
  }
};

const removeCollapseTags = (pieces: string[]) => {
  return pieces.map((piece: string) => {
    if (piece === "\n(collapse)" || piece === "\n(noncollapse)") {
      return "\n";
    } else {
      return piece;
    }
  });
};

export const parseHtml = (htmlText: string) => {
  const doc = new DOMParser().parseFromString(htmlText, "text/html");
  const element = doc.querySelector("html");
  const messagePieces: string[] = [];
  iterateOverHtmlDoc(element, messagePieces);
  const messagePiecesWithCollapseTagsRemoved =
    removeCollapseTags(messagePieces);
  return messagePiecesWithCollapseTagsRemoved.join("").trim();
};

const collapsingElements = [
  "DIV",
  "FOOTER",
  "MAIN",
  "SECTION",
  "ARTICLE",
  "DL",
  "FORM",
  "NAV",
  "TABLE",
  "ASIDE",
  "TFOOT",
  "HEADER",
  "FIGCAPTION",
  "DD",
  "OL",
  "UL",
  "TR",
  "TD",
];

const nonCollapsingElements = [
  "BR",
  "HR",
  "ADDRESS",
  "H1",
  "H2",
  "H3",
  "H4",
  "H5",
  "H6",
  "BLOCKQUOTE",
  "P",
  "VIDEO",
  "DT",
  "FIELDSET",
  "LI",
  "PRE",
  "FIGURE",
];

export const doFileDrop = async (
  event: DragEvent<HTMLDivElement>,
  handleUpload: ({
    event,
    file,
  }: {
    event?: any;
    file?: File;
  }) => Promise<void>,
) => {
  const nonImages = Array.from(event.dataTransfer.files).filter(
    (file: File) => {
      return !file.type.startsWith("image/");
    },
  );
  for (const file of nonImages) {
    await handleUpload({ file });
  }
  return;
};

export const removeHtmlComments = (htmlText: string) => {
  const doc = new DOMParser().parseFromString(htmlText, "text/html");
  const element = doc.querySelector("html");
  const messagePieces: string[] = [];
  iterateOverHtmlDocForComments(element, messagePieces);
  return doc.documentElement.innerHTML;
};

export const getLastCustomerDirectMessage = (
  conversation: ExpandedConversation,
) => {
  return conversation.messages
    .slice()
    .reverse()
    .find(
      (message) =>
        message.type === "customer" &&
        ((conversation.platform === ConversationPlatform.INSTAGRAM &&
          !!message.instagram?.messageId) ||
          (conversation.platform === ConversationPlatform.FACEBOOK &&
            !!message.facebook?.messageId)),
    );
};

// Must be a customer DM within 7 days of the last customer message
export const canSendMetaMessage = (conversation: ExpandedConversation) => {
  if (
    ![ConversationPlatform.FACEBOOK, ConversationPlatform.INSTAGRAM].includes(
      conversation.platform,
    )
  ) {
    return false;
  }

  const lastCustomerDirectMessage = getLastCustomerDirectMessage(conversation);
  if (!lastCustomerDirectMessage) {
    return false;
  }

  const lastCustomerResponseAt = new Date(lastCustomerDirectMessage.sentAt);
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

  return lastCustomerResponseAt > sevenDaysAgo;
};

// Last customer message must be a comment, there must be no (public) merchant messages after it,
// and it must be within 7 days
export const canSendInstagramPrivateReply = (
  conversation: ExpandedConversation,
) => {
  const lastCustomerMessageIndex = conversation.messages
    .map((message) => message.type)
    .lastIndexOf("customer");

  if (
    lastCustomerMessageIndex === -1 ||
    conversation.platform !== ConversationPlatform.INSTAGRAM
  ) {
    return false;
  }

  const lastCustomerMessage = conversation.messages[lastCustomerMessageIndex];
  if (!lastCustomerMessage.instagram?.comment) {
    return false;
  }

  for (
    let i = lastCustomerMessageIndex + 1;
    i < conversation.messages.length;
    i++
  ) {
    if (
      conversation.messages[i].type === "merchant" &&
      conversation.messages[i].visibility !== MessageVisibility.INTERNAL
    ) {
      return false;
    }
  }

  const lastCustomerResponseAt = new Date(lastCustomerMessage.sentAt);
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

  return lastCustomerResponseAt > sevenDaysAgo;
};

export const getPrivateReplyCommentId = (
  conversation: ExpandedConversation,
): string | null => {
  if (!canSendInstagramPrivateReply(conversation)) {
    return null;
  }

  const lastCustomerMessageIndex = conversation.messages
    .map((message) => message.type)
    .lastIndexOf("customer");

  const lastCustomerMessage = conversation.messages[lastCustomerMessageIndex];
  return lastCustomerMessage.instagram?.comment?.commentId || null;
};

export const channelDisplayName = (channelName: string) => {
  switch (channelName) {
    case "email":
      return "Email";
    case "redoChat":
      return "Redo chat";
    case "facebook":
      return "Facebook";
    case "instagram":
      return "Instagram DM";
    case "instagramComments":
      return "Instagram comments";
    case "sms":
      return "SMS";
    case "facebookComments":
      return "Facebook comments";
    case "attentive":
      return "Attentive";
    case "postscript":
      return "Postscript";
    default:
      return channelName;
  }
};

export const addZwspAfterLink = (html: string) => {
  const zwsp = "\u200B";
  return html.replace(/<\/a>(\u200B)*/g, `</a>${zwsp}`);
};
