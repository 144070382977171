import {
  CampaignChannel,
  CampaignSortKey,
  CampaignStatus,
  campaignWithOutreachAnalyticsSchema,
} from "@redotech/redo-model/campaigns/campaign-definition";
import { SortDirection } from "@redotech/redo-model/tables/table";
import { z } from "zod";

export const getTeamCampaigns = {
  input: z.object({
    includeStats: z.boolean().optional(),
    filters: z
      .object({
        channel: z.array(z.nativeEnum(CampaignChannel)).optional(),
        status: z.array(z.nativeEnum(CampaignStatus)).optional(),
      })
      .optional(),
    sort: z
      .object({
        key: z.nativeEnum(CampaignSortKey),
        direction: z.nativeEnum(SortDirection),
      })
      .optional(),
  }),
  output: z.array(campaignWithOutreachAnalyticsSchema),
};
