// extracted by mini-css-extract-plugin
export var active = "_ch-b";
export var arrow = "_ch-8";
export var arrowContainer = "_ch-4";
export var container = "_ch-0";
export var disabled = "_ch-5";
export var dot = "_ch-a";
export var dotContainer = "_ch-9";
export var leftArrowContainer = "_ch-6 _ch-4";
export var rightArrowContainer = "_ch-7 _ch-4";
export var slide = "_ch-3";
export var sliderContainer = "_ch-1";
export var sliderOverflow = "_ch-2";