import { Order, Provider } from "@redotech/redo-model/order";
import {
  Product,
  Return,
  ReturnedProductStatus,
  ReturnType,
  ShipmentGroup,
} from "@redotech/redo-model/return";
import { ReturnStatus } from "@redotech/redo-model/return-status";
import { Team } from "@redotech/redo-model/team";
import { GetUser, Permission, permitted } from "@redotech/redo-model/user";
import { RedoBadgeColor } from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import { assertNever } from "@redotech/util/type";

export function answerName(answer: string) {
  return answer[0].toUpperCase() + answer.slice(1);
}

export function shipmentStatusName(status: string) {
  if (status === ReturnStatus.DELETED) {
    return "Reset";
  }
  if (!status || status === "unknown") {
    return "-";
  }
  if (status === "pre_transit") {
    return "Pre-transit";
  }
  return status[0].toUpperCase() + status.slice(1).replace(/_/g, " ");
}

export function returnStatusName(status: ReturnStatus) {
  if (status === ReturnStatus.DELETED) {
    return "Reset";
  }
  return status[0].toUpperCase() + status.slice(1).replace(/_/g, " ");
}

export function returnKindNameProduct(
  product: Product,
  provision?: string,
  returnType?: string,
): string {
  const isExchange = isVariantExchange(product);
  if (returnType && returnType === "claim" && isExchange) {
    return "Replace";
  }
  if (isExchange) {
    return "Exchange";
  }
  return (
    product.strategy[0].toUpperCase() +
    product.strategy.slice(1).replace(/_/g, " ")
  );
}

export function returnKindNameReturn(
  return_: Return,
  abbreviate: boolean = true,
) {
  if (return_?.advancedExchangeItems?.length) {
    if (return_.provision === "instant") {
      return "Instant Exchange";
    } else {
      return "Exchange";
    }
  }
  const uniqueTypes = new Set(
    return_.products.map((product) =>
      returnKindNameProduct(product, return_.provision, return_.type),
    ),
  );
  const types = [...uniqueTypes].map((returnType) => {
    if (returnType === "Exchange" && return_.provision === "instant") {
      return "Instant Exchange";
    } else {
      return returnType;
    }
  });

  if (abbreviate) {
    let [type] = types.sort();
    if (1 < types.length) {
      type += " …";
    }
    return type;
  } else {
    return types.sort().join(", ");
  }
}

export function isVariantExchange(product: Product): boolean {
  return !!product.exchange_for || !!product.exchangeGroupItem;
}

export type ItemDispositionModalOpen =
  | { open: true; item: Return["products"][number] }
  | { open: false };

export const returnStatusToBadgeColor: Record<ReturnStatus, RedoBadgeColor> = {
  [ReturnStatus.COMPLETE]: "success",
  [ReturnStatus.DELIVERED]: "blue",
  [ReturnStatus.IN_TRANSIT]: "pink",
  [ReturnStatus.NEEDS_REVIEW]: "blue",
  [ReturnStatus.IN_REVIEW]: "orange",
  [ReturnStatus.OPEN]: "warning",
  [ReturnStatus.REJECTED]: "error",
  [ReturnStatus.DELETED]: "warning",
  [ReturnStatus.FLAGGED]: "orange",
  [ReturnStatus.PRE_SHIPMENT]: "blueLight",
};

export function itemStatusName(
  productStatus: ReturnedProductStatus,
  returnStatus: ReturnStatus,
) {
  if (returnStatus === ReturnStatus.DELETED) {
    return "Reset";
  }
  switch (productStatus) {
    case ReturnedProductStatus.COMPLETE:
      return "Processed";
    case ReturnedProductStatus.REJECTED:
      return "Rejected";
    case ReturnedProductStatus.PENDING:
      return "Pending";
    case ReturnedProductStatus.PRE_SHIPMENT:
      return "Pre-Shipment";
    case ReturnedProductStatus.APPROVED:
    case ReturnedProductStatus.IN_REVIEW:
    case ReturnedProductStatus.OPEN:
      return "Unprocessed";
    default:
      assertNever(productStatus);
  }
}

export function orderStatusName(shopifyFullfillmentStatus: string) {
  switch (shopifyFullfillmentStatus) {
    case null:
      return "Unfulfilled";
    case "partial":
      return "Partially fulfilled";
    default:
      return (
        shopifyFullfillmentStatus[0].toUpperCase() +
        shopifyFullfillmentStatus.slice(1)
      );
  }
}

export function hasEditReturnPermissions(team?: Team, user?: GetUser) {
  if (!team || !user) {
    return false;
  }
  if (team.storeUrl === "shopthemint.myshopify.com") {
    return user.roles.includes("admin");
  }
  return permitted(user.permissions, Permission.MANAGE_RETURN);
}

export function orderExternalUrl(team: Team, order: Order): string | undefined {
  switch (order.provider) {
    case Provider.COMMENTSOLD: {
      const url = new URL("https://commentsold.com/admin/orders");
      url.searchParams.set("order", order.shopify.name.replace("CS-", ""));
      url.searchParams.set("scanned", "false");
      return url.toString();
    }
    case Provider.SHOPIFY:
      return `https://${team.storeUrl}/admin/orders/${order.shopify_id || ""}`;
  }
  return undefined;
}

export function isExchangeOrderEditable(
  return_: Return,
  team: Team | undefined,
  user: GetUser | undefined,
) {
  const isProcessed = [ReturnStatus.COMPLETE, ReturnStatus.REJECTED].includes(
    return_.status,
  );
  const isReset = [ReturnStatus.DELETED].includes(return_.status);
  const exchangeOrderExists =
    !!return_.exchangeOrder.length || !!return_.draftOrderId;

  return (
    !!hasEditReturnPermissions(team, user) &&
    !return_.instantRefund &&
    !isProcessed &&
    !isReset &&
    !exchangeOrderExists
  );
}

export function hasNewOrderItems(return_: Return) {
  return (
    return_.products.some((product) => isVariantExchange(product)) ||
    return_.advancedExchangeItems.length > 0
  );
}

export const returnTypeName = (returnType?: ReturnType, titleCase = false) =>
  titleCase ? (returnType === "return" ? "Return" : "Claim") : returnType;

export function getProductsByShippingGroupTitle(return_: Return) {
  const productsByGroupName: { groupName: string; products: Product[] }[] = [];

  if (return_?.shipmentGroups?.length) {
    for (const product of return_.products) {
      const allGroups = return_.shipmentGroups;
      const shipmentGroupsIDs = product.shipmentGroupIDs?.length
        ? product.shipmentGroupIDs
        : product.shipmentGroupID
          ? [product.shipmentGroupID]
          : [];
      const shipmentGroups: ShipmentGroup[] = return_.shipmentGroups.filter(
        (group) => shipmentGroupsIDs.includes(group.id),
      );
      const shipmentIndices: number[] = shipmentGroups
        .map((group) => allGroups.indexOf(group))
        .sort();

      const groupName = `Shipment ${shipmentIndices.map((ind) => ind + 1).join(" + ")}`;

      const productGroup = productsByGroupName.find(
        (group) => group.groupName === groupName,
      );
      if (productGroup) {
        productGroup?.products.push(product);
      } else {
        productsByGroupName.push({ groupName, products: [product] });
      }
    }
  }

  return productsByGroupName;
}
