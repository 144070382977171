// extracted by mini-css-extract-plugin
export var button = "_5-0";
export var buttonContent = "_5-a";
export var dontRestrainIconWidth = "_5-7";
export var dropdownButton = "_5-8";
export var iconContainer = "_5-6";
export var lg = "_5-4";
export var md = "_5-3";
export var pending = "_5-b";
export var sm = "_5-2";
export var spinner = "_5-9";
export var xl = "_5-5";
export var xs = "_5-1";