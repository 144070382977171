import { CreateShippingRateTableInputSchema } from "@redotech/redo-model/integration/redo-in-shipping/shipping-rate-table";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const updateShippingMethod = {
  input: z.object({
    checkoutExperienceId: zExt.objectId(),
    shippingMethodId: zExt.objectId(),
    shippingRateTable: CreateShippingRateTableInputSchema,
  }),
  output: z.null(),
};
export type UpdateShippingMethodInput = z.infer<
  typeof updateShippingMethod.input
>;
