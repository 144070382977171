import { ObjectId } from "bson";
import * as escapeRegExp from "lodash/escapeRegExp";
import { z } from "zod";
import {
  AdvancedFilterType,
  createAdvancedFilterDataSchema,
  GenericFilterBuilder,
} from "./generic-advanced-filter-data";

export enum ArrayStringFilterOperator {
  CONTAINS = "contains",
  ALL_OF = "all_of",
  ANY_OF = "any_of",
  NONE_OF = "none_of",
  NOT_CONTAINS = "not_contains",
  IS_EMPTY = "is_empty",
  IS_NOT_EMPTY = "is_not_empty",
}
export const valuelessOperators = [
  ArrayStringFilterOperator.IS_EMPTY,
  ArrayStringFilterOperator.IS_NOT_EMPTY,
];

const ArrayStringFilterOperatorSchema = z.nativeEnum(ArrayStringFilterOperator);

const ArrayStringFilterValueSchema = z.array(z.string());

export const ArrayStringFilterDataSchema = createAdvancedFilterDataSchema(
  AdvancedFilterType.ARRAY_TO_STRING,
  ArrayStringFilterValueSchema,
  ArrayStringFilterOperatorSchema,
);
export type ArrayStringFilterData = z.infer<typeof ArrayStringFilterDataSchema>;

export const ArrayStringFilterBuilder: GenericFilterBuilder<
  string[],
  ArrayStringFilterOperator,
  ArrayStringFilterData
> = {
  type: AdvancedFilterType.ARRAY_TO_STRING,
  valueSchema: ArrayStringFilterValueSchema,
  operatorSchema: ArrayStringFilterOperatorSchema,
  schema: ArrayStringFilterDataSchema,
  buildAtlasSearchQuery: ({
    filter,
    atlasPath,
    atlasMultiPath,
    searchCompound,
    useObjectId = false,
  }) => {
    if (filter.value && filter.value.length > 0) {
      if (filter.operator === ArrayStringFilterOperator.CONTAINS) {
        searchCompound.filter.push({
          wildcard: {
            path: atlasMultiPath ?? atlasPath,
            query: `*${escapeRegExp(filter.value[0])}*`,
            allowAnalyzedField: true,
          },
        });
      }
      if (filter.operator === ArrayStringFilterOperator.NOT_CONTAINS) {
        searchCompound.mustNot.push({
          wildcard: {
            path: atlasMultiPath ?? atlasPath,
            query: `*${escapeRegExp(filter.value[0])}*`,
            allowAnalyzedField: true,
          },
        });
      }
      if (filter.operator === ArrayStringFilterOperator.ALL_OF) {
        for (const value of filter.value) {
          searchCompound.filter.push({
            equals: {
              path: atlasPath,
              value: useObjectId ? new ObjectId(value) : value,
            },
          });
        }
      }
      if (filter.operator === ArrayStringFilterOperator.NONE_OF) {
        for (const value of filter.value) {
          searchCompound.mustNot.push({
            equals: {
              path: atlasPath,
              value: useObjectId ? new ObjectId(value) : value,
            },
          });
        }
      }
      if (filter.operator === ArrayStringFilterOperator.ANY_OF) {
        searchCompound.filter.push({
          in: {
            path: atlasPath,
            value: useObjectId
              ? filter.value.map((value) => new ObjectId(value))
              : filter.value,
          },
        });
      }
    }

    if (filter.operator === ArrayStringFilterOperator.IS_EMPTY) {
      searchCompound.filter.push({
        compound: {
          should: [
            { compound: { mustNot: [{ exists: { path: atlasPath } }] } },
            {
              compound: {
                mustNot: [
                  {
                    wildcard: {
                      query: "*",
                      path: atlasPath,
                      allowAnalyzedField: true,
                    },
                  },
                ],
              },
            },
          ],
          minimumShouldMatch: 1,
        },
      });
    }
    if (filter.operator === ArrayStringFilterOperator.IS_NOT_EMPTY) {
      searchCompound.filter.push({
        wildcard: { query: "*", path: atlasPath, allowAnalyzedField: true },
      });
    }

    return searchCompound;
  },
  readFromString: (name, operatorValueEncoded, defaultFilter) => {
    try {
      const decoded = decodeURIComponent(operatorValueEncoded);
      const operatorValue = decoded.split("::");

      const operator = operatorValue?.[0];
      const value = operatorValue?.[1];

      if (!operator) {
        return defaultFilter;
      }

      const parsedOperator =
        ArrayStringFilterOperatorSchema.safeParse(operator);
      if (!parsedOperator.success) {
        console.warn("Invalid operator type");
        return undefined;
      }

      if (!value) {
        return {
          type: AdvancedFilterType.ARRAY_TO_STRING,
          name,
          value: [],
          operator: parsedOperator.data,
        };
      }

      const valueParsed = ArrayStringFilterValueSchema.safeParse(
        value.split(","),
      );

      if (!valueParsed.success) {
        console.warn("Invalid value type for ArrayStringFilter");
        return undefined;
      }

      return {
        type: AdvancedFilterType.ARRAY_TO_STRING,
        name,
        value: valueParsed.data,
        operator: parsedOperator.data,
      };
    } catch (error) {
      console.error("Error parsing ArrayStringFilter", error);
      return undefined;
    }
  },
  writeToString: (filter: ArrayStringFilterData | undefined) => {
    try {
      if (!filter) {
        return "";
      }
      const { operator, value } = filter;
      const parsedValue = ArrayStringFilterValueSchema.safeParse(value);

      if (parsedValue.success) {
        return `${operator}::${parsedValue.data.join(",")}`;
      } else {
        return `${operator}::`;
      }
    } catch (error) {
      console.error("Error writing ArrayStringFilter", error);
      return "";
    }
  },
  isPartial: (filter) => {
    if (!filter.operator) {
      return true;
    }

    if (valuelessOperators.includes(filter.operator)) {
      return false;
    }

    return !filter.value || filter.value.length === 0;
  },
};
