// extracted by mini-css-extract-plugin
export var alignCenter = "_35-d";
export var bannerShown = "_35-6";
export var buttonContent = "_35-0";
export var cardList = "_35-1";
export var centerContent = "_35-2";
export var chevron = "_35-3";
export var closePanelButton = "_35-k";
export var conversationContextContainer = "_35-l";
export var conversationDetailContainer = "_35-4";
export var detailWrapper = "_35-7";
export var dropdownButton = "_35-e";
export var dropdownTitle = "_35-9";
export var fullHeight = "_35-5";
export var hidden = "_35-a";
export var icon = "_35-b";
export var panel = "_35-i";
export var portalButtonsWrapper = "_35-c";
export var right = "_35-j";
export var tableSummaryContainer = "_35-8";
export var tableSummaryHeader = "_35-g";
export var threeDotsButton = "_35-f";
export var wide = "_35-h";