import { useRequiredContext } from "@redotech/react-util/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { LinkPrimaryTab } from "@redotech/redo-web/arbiter-components/navigation/primary/redo-primary-nav";
import { PageNavigationEntry } from "@redotech/redo-web/arbiter-components/navigation/redo-side-navigation";
import { SecondaryNavigationData } from "@redotech/redo-web/arbiter-components/navigation/secondary/redo-secondary-nav";
import PackageIcon from "@redotech/redo-web/arbiter-icon/package.svg";
import PackageFilledIcon from "@redotech/redo-web/arbiter-icon/package_filled.svg";
import { memo } from "react";
import { OrdersReviewCountContext } from "../../../../services/orders/orders-review-count-service";
import { ClaimsItem } from "./claims";
import { OrdersItem } from "./orders";
import { ReturnsItem } from "./returns";
import { WarrantiesItem } from "./warranties";

export function useOrdersNavigation(): PageNavigationEntry | null {
  const team = useRequiredContext(TeamContext);

  const pathRoot = `/stores/${team._id}/order-management`;

  const { claimsNeedingReview, returnsNeedingReview } = useRequiredContext(
    OrdersReviewCountContext,
  );

  const omsEnabled = team?.settings.outboundLabels?.newOmsEnabled;

  const hasNotification = !!claimsNeedingReview || !!returnsNeedingReview;

  const primaryTab: LinkPrimaryTab = {
    id: "orders",
    hasNotification: hasNotification,
    UnfilledIcon: PackageIcon,
    FilledIcon: PackageFilledIcon,
    type: "link",
    pathRoot: pathRoot,
    href: omsEnabled
      ? `${pathRoot}/outbound-labels/awaiting-shipment`
      : pathRoot,
    friendlyName: "Order Management",
  };

  const secondaryNavigationData: SecondaryNavigationData = {
    id: "orders",
    name: "Orders",
    NavContent: OrdersSecondaryNavigation,
  };

  return { primaryTab, secondaryNavigationData };
}

const OrdersSecondaryNavigation = memo(function OrdersSecondaryNavigation({
  pathRoot,
}: {
  pathRoot: string;
}) {
  return (
    <>
      <OrdersItem ordersRoot={pathRoot} />
      <ClaimsItem ordersRoot={pathRoot} />
      <ReturnsItem ordersRoot={pathRoot} />
      <WarrantiesItem ordersRoot={pathRoot} />
    </>
  );
});
