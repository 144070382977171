import { z } from "zod";
import { zExt } from "../common/zod-util";
import {
  CustomerEvent,
  CustomerEventType,
  OutreachClickEvent,
  OutreachOpenEvent,
} from "../customer-event/customer-event-definition";
import { MarketingChannel } from "../marketing";

export enum CampaignRecipientStatus {
  PENDING = "pending",
  PROCESSING = "processing",
  PROCESSED = "processed",
  FAILED = "failed",
}

export const terminalStatuses = [
  CampaignRecipientStatus.FAILED,
  CampaignRecipientStatus.PROCESSED,
];

const campaignRecipientMetricsSchema = z.object({
  opens: z.number(),
  clicks: z.number(),
  upsellRevenue: z.number(),
  upsellCount: z.number(),
  alreadyUnsubscribes: z.number(),
  invalidChannelIds: z.number(),
  suppressions: z.number(),
  smartSendingSkips: z.number(),
  unsupportedInternationals: z.number(),
});

export const campaignRecipientSchema = z.object({
  _id: zExt.objectId(),
  teamId: zExt.objectId(),
  campaignId: zExt.objectId().nullish(),
  automationId: zExt.objectId().nullish(),
  automationStepId: zExt.objectId().nullish(),
  automationExecutionId: zExt.objectId().nullish(),
  customerId: zExt.objectId(),
  channel: z.nativeEnum(MarketingChannel),
  channelId: z.string(),
  messageId: z.string().nullish(),
  firstName: z.string().nullish(),
  lastName: z.string().nullish(),
  metrics: campaignRecipientMetricsSchema,
  createdAt: z.date(),
  updatedAt: z.date(),
  status: z.nativeEnum(CampaignRecipientStatus),
  attemptsLeft: z.number(),
  error: z.any().optional(),
});

export type CampaignRecipientMetrics = z.infer<
  typeof campaignRecipientMetricsSchema
>;

export type CampaignRecipient = z.infer<typeof campaignRecipientSchema>;

export type CampaignRecipientMetricEvent =
  | OutreachOpenEvent
  | OutreachClickEvent;

export function isCampaignRecipientMetricEvent(
  event: CustomerEvent,
): event is CampaignRecipientMetricEvent {
  return [
    CustomerEventType.OUTREACH_OPEN,
    CustomerEventType.OUTREACH_CLICK,
  ].includes(event.eventType);
}
