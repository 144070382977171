import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import ArrowLeftIcon from "@redotech/redo-web/arbiter-icon/arrow-left_filled.svg";
import { Flex } from "@redotech/redo-web/flex";
import { Header, Text } from "@redotech/redo-web/text";
import { Component, memo } from "react";
import { Location, useNavigate } from "react-router-dom";

const defaultErrorDetails =
  "If you need assistance or want to share your feedback, feel free to chat with us.";
export const ErrorMessageSection = memo(function ErrorMessageSection({
  details = defaultErrorDetails,
  includeBackButton,
  showNewIntercomMessage,
}: {
  details?: string;
  includeBackButton?: boolean;
  showNewIntercomMessage?: () => void;
}) {
  const navigate = useNavigate();
  return (
    <Flex align="center" dir="column" flex={3}>
      <Header>Oops, we encountered an error</Header>
      <Text>{details}</Text>
      <Flex gap="lg" mt="xl">
        {includeBackButton && (
          <RedoButton
            hierarchy="secondary"
            IconLeading={ArrowLeftIcon}
            onClick={() => navigate(-1)}
            size="xl"
            text="Go back"
          />
        )}
        {showNewIntercomMessage && (
          <RedoButton
            hierarchy="primary"
            onClick={() => showNewIntercomMessage()}
            size="xl"
            text="Chat with us"
          />
        )}
      </Flex>
    </Flex>
  );
});

export const ErrorPage = memo(function ErrorPage({
  showNewIntercomMessage,
}: {
  showNewIntercomMessage?: () => void;
}) {
  return (
    <Flex dir="column" flex={1}>
      <Flex flex={1} />
      <ErrorMessageSection
        includeBackButton
        showNewIntercomMessage={showNewIntercomMessage}
      />
    </Flex>
  );
});

// Must be a class component to act as an error boundary
// https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
export class ErrorBoundary extends Component<
  {
    children: React.ReactNode;
    location: Location;
    showNewIntercomMessage?: () => void;
  },
  { hasError: boolean }
> {
  constructor(props: {
    children: React.ReactNode;
    location: Location;
    showNewIntercomMessage?: () => void;
  }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  override componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  override componentDidUpdate(prevProps: { location: Location }) {
    if (this.state.hasError && this.props.location !== prevProps.location) {
      this.setState({ hasError: false });
    }
  }

  override render() {
    if (this.state.hasError) {
      return (
        <ErrorPage showNewIntercomMessage={this.props.showNewIntercomMessage} />
      );
    }

    return this.props.children;
  }
}
