import { z } from "zod";
import { Provider } from "../order";
import { ShopifyFulfillmentHoldReason } from "./shopify-fulfillment-schemas";

export const FulfillmentHoldSchema = z.object({
  holdHandle: z.string(),
  provider: z.nativeEnum(Provider),
  reason: z.nativeEnum(ShopifyFulfillmentHoldReason),
  reasonNotes: z.string(),
});

export type FulfillmentHold = z.infer<typeof FulfillmentHoldSchema>;
