import { z } from "zod";
import { AnalyticsFilterType } from "./analytics-filter-type";

export enum DateFilterQuery {
  WITHIN = "within",
}

export type ReportFilterType = AnalyticsFilterType;

export function makeAnalyticsDateFilterSchema<Type extends AnalyticsFilterType>(
  type: Type,
) {
  return z.object({
    type: z.literal(type),
    query: z.literal(DateFilterQuery.WITHIN),
    value: z.tuple([z.string(), z.string()]).readonly(),
  });
}

export type BaseReportDateFilter<Type extends ReportFilterType> = z.infer<
  ReturnType<typeof makeAnalyticsDateFilterSchema<Type>>
>;
