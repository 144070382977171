import { useRequiredContext } from "@redotech/react-util/context";
import { LoadState, useLoad } from "@redotech/react-util/load";
import { RedoMerchantRpcClientContext } from "@redotech/redo-merchant-app-common/rpc-client";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { UserContext } from "@redotech/redo-merchant-app-common/user";
import { LinkPrimaryTab } from "@redotech/redo-web/arbiter-components/navigation/primary/redo-primary-nav";
import { PageNavigationEntry } from "@redotech/redo-web/arbiter-components/navigation/redo-side-navigation";
import { SecondaryNavigationData } from "@redotech/redo-web/arbiter-components/navigation/secondary/redo-secondary-nav";
import AnnouncementIcon3 from "@redotech/redo-web/arbiter-icon/announcement-03.svg";
import AnnouncementIcon3Filled from "@redotech/redo-web/arbiter-icon/announcement-03_filled.svg";
import { memo, useContext } from "react";
import { ReviewsToReviewContext } from "../../../../services/marketing/reviews-to-review-service";
import { AutomationsItems } from "./automations";
import { EmailSMSItems } from "./email-sms";
import { OneClickUpsellItems } from "./one-click-upsell";
import { OrderTrackingItems } from "./order-tracking";
import { ReviewsItems } from "./reviews";

export function useMarketingNavigation(): PageNavigationEntry | null {
  const team = useRequiredContext(TeamContext);

  const user = useRequiredContext(UserContext);

  const redoMerchantRpcClient = useRequiredContext(
    RedoMerchantRpcClientContext,
  );

  const oneClickUpsellEnabled: LoadState<boolean> = useLoad(async () => {
    const upsellPage =
      await redoMerchantRpcClient.getActiveUpsellPageForTeam(null);
    const postPurchaseUpsellEnabled =
      team.settings?.orderTracking?.postPurchaseUpsell?.enabled ?? false;
    return upsellPage !== null || postPurchaseUpsellEnabled;
  }, [redoMerchantRpcClient, team.settings?.orderTracking?.postPurchaseUpsell]);

  const reviewsEnabled = team?.settings.reviews?.enabled;

  const pathRoot = `/stores/${team._id}/marketing`;
  const settings = team.settings;
  const showMarketingSection =
    settings.marketing?.enabled ||
    settings.orderTracking?.enabled ||
    settings.returnTracking?.enabled ||
    oneClickUpsellEnabled.value ||
    reviewsEnabled;

  const reviewCount = useContext(ReviewsToReviewContext);

  if (user.roles.includes("audien-support")) {
    return null;
  }

  if (!showMarketingSection) {
    return null;
  }

  const hasNotification = reviewCount > 0;

  const primaryTab: LinkPrimaryTab = {
    id: "marketing",
    hasNotification: hasNotification,
    UnfilledIcon: AnnouncementIcon3,
    FilledIcon: AnnouncementIcon3Filled,
    type: "link",
    pathRoot: pathRoot,
    href: `${pathRoot}/automations`,
    friendlyName: "Marketing",
  };

  const secondaryNavigationData: SecondaryNavigationData = {
    id: "marketing",
    name: "Marketing",
    NavContent: SecondaryMarketingSection,
  };

  return { primaryTab, secondaryNavigationData };
}

const SecondaryMarketingSection = memo(function SecondaryMarketingSection({
  pathRoot,
}: {
  pathRoot: string;
}) {
  return (
    <>
      <AutomationsItems marketingRoot={pathRoot} />
      <EmailSMSItems marketingRoot={pathRoot} />
      <OneClickUpsellItems marketingRoot={pathRoot} />
      <OrderTrackingItems marketingRoot={pathRoot} />
      <ReviewsItems marketingRoot={pathRoot} />
    </>
  );
});
