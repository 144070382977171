import { assertNever, sinkValue, XorOfUnions } from "@redotech/util/type";

export enum TimeframeConditionType {
  BEFORE_NOW_RELATIVE = "before-now-relative",
  AFTER = "after",
  BEFORE = "before",
  BETWEEN_RELATIVE = "between-relative",
  BETWEEN_DATES = "between-dates",
  BEFORE_RELATIVE = "before-relative",
  ALL_TIME = "all-time",
  ON = "on",
  TODAY = "today",
  AUTOMATION_START = "automation-start",
  THIS_WEEK = "this-week",
  THIS_MONTH = "this-month",
}

export const fullDateTimeframeConditionTypes = [
  TimeframeConditionType.BEFORE_NOW_RELATIVE,
  TimeframeConditionType.AFTER,
  TimeframeConditionType.BEFORE,
  TimeframeConditionType.BETWEEN_RELATIVE,
  TimeframeConditionType.BETWEEN_DATES,
  TimeframeConditionType.BEFORE_RELATIVE,
  TimeframeConditionType.ALL_TIME,
  TimeframeConditionType.ON,
  TimeframeConditionType.TODAY,
  TimeframeConditionType.AUTOMATION_START,
] as const;

export type FullDateTimeframeCondition =
  | BeforeTimeframeCondition
  | AfterTimeframeCondition
  | AllTimeTimeframeCondition
  | BetweenRelativeTimeframeCondition
  | BetweenDatesTimeframeCondition
  | BeforeRelativeTimeframeCondition
  | BeforeNowRelativeTimeframeCondition
  | OnTimeframeCondition
  | TodayTimeframeCondition
  | AutomationStartTimeframeCondition;

export interface BeforeNowRelativeTimeframeCondition {
  type: TimeframeConditionType.BEFORE_NOW_RELATIVE;
  value: number;
  units: TimeframeUnit;
}

export interface OnTimeframeCondition {
  type: TimeframeConditionType.ON;
  date: Date;
}

export interface BeforeRelativeTimeframeCondition {
  type: TimeframeConditionType.BEFORE_RELATIVE;
  value: number;
  units: TimeframeUnit;
}
export interface BeforeTimeframeCondition {
  type: TimeframeConditionType.BEFORE;
  date: Date;
}

export interface AfterTimeframeCondition {
  type: TimeframeConditionType.AFTER;
  date: Date;
}

export interface AllTimeTimeframeCondition {
  type: TimeframeConditionType.ALL_TIME;
}

export interface TodayTimeframeCondition {
  type: TimeframeConditionType.TODAY;
}

export interface AutomationStartTimeframeCondition {
  type: TimeframeConditionType.AUTOMATION_START;
  automationStartTime: Date;
}

export enum TimeframeUnit {
  HOUR = "hour",
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
}

export const timeframeUnitToTemporalDurationLike: Record<
  TimeframeUnit,
  keyof Temporal.DurationLike
> = { hour: "hours", day: "days", week: "weeks", month: "months" };

export interface BetweenRelativeTimeframeCondition {
  type: TimeframeConditionType.BETWEEN_RELATIVE;
  start: number;
  end: number;
  units: TimeframeUnit;
}

export interface BetweenDatesTimeframeCondition {
  type: TimeframeConditionType.BETWEEN_DATES;
  range: [Date, Date];
}

export type AnnualTimeframeCondition =
  | BeforeNowRelativeTimeframeCondition
  | AfterTimeframeCondition
  | BeforeTimeframeCondition
  | BetweenDatesTimeframeCondition
  | OnTimeframeCondition
  | TodayTimeframeCondition
  | ThisWeekTimeframeCondition
  | ThisMonthTimeframeCondition;

export const annualTimeframeConditionTypes = [
  TimeframeConditionType.BEFORE_NOW_RELATIVE,
  TimeframeConditionType.AFTER,
  TimeframeConditionType.BEFORE,
  TimeframeConditionType.BETWEEN_DATES,
  TimeframeConditionType.ON,
  TimeframeConditionType.TODAY,
  TimeframeConditionType.THIS_WEEK,
  TimeframeConditionType.THIS_MONTH,
] as const;
export interface ThisWeekTimeframeCondition {
  type: TimeframeConditionType.THIS_WEEK;
}

export interface ThisMonthTimeframeCondition {
  type: TimeframeConditionType.THIS_MONTH;
}

// Some types in TimeframeConditionType are used for both full date and annual timeframe conditions.
// What I really want to do is have one enum with the shared types and two subenums that extend it.
// Since typescript doesn't support that, TimeframeConditionType has all the condition types,
// and fullDateTimeframeConditionTypes and annualTimeframeConditionTypes are subsets of it.
// The anonymous functions below provide type safety for the subsets.

() => {
  // Ensure that fullDateTimeframeConditionTypes is a subset of TimeframeConditionType
  const a: readonly TimeframeConditionType[] = fullDateTimeframeConditionTypes;
  sinkValue(a);
  // Ensure that fullDateTimeframeConditionTypes and FullDateTimeframeCondition["type"] match
  const b: XorOfUnions<
    FullDateTimeframeCondition["type"],
    (typeof fullDateTimeframeConditionTypes)[number]
  > = "" as unknown as never;
  assertNever(b);
};

() => {
  // Ensure that annualTimeframeConditionTypes is a subset of TimeframeConditionType
  const a: readonly TimeframeConditionType[] = annualTimeframeConditionTypes;
  sinkValue(a);
  // Ensure that annualTimeframeConditionTypes and AnnualTimeframeCondition["type"] match
  const b: XorOfUnions<
    AnnualTimeframeCondition["type"],
    (typeof annualTimeframeConditionTypes)[number]
  > = "" as unknown as never;
  assertNever(b);
};
