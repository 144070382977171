import { UniqueIdentifier } from "@dnd-kit/core";
import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { SortableTree } from "@redotech/redo-web/arbiter-components/navigation/folder-tree/sortable-tree";
import { TreeItem } from "@redotech/redo-web/arbiter-components/navigation/folder-tree/types";
import { flattenTree } from "@redotech/redo-web/arbiter-components/navigation/folder-tree/utilities";
import { RedoSecondaryNavItem } from "@redotech/redo-web/arbiter-components/navigation/secondary/redo-secondary-nav-item";
import PackageFilledIcon from "@redotech/redo-web/arbiter-icon/package_filled.svg";
import PickupTruckIcon from "@redotech/redo-web/arbiter-icon/truck-01.svg";
import PickupTruckFilledIcon from "@redotech/redo-web/arbiter-icon/truck-01_filled.svg";
import { memo, useMemo } from "react";
import { useMatch } from "react-router-dom";
import { OmsViewOrderingContext } from "../../../../services/orders/oms-view-ordering-service";
import {
  FolderWithView,
  treeToViewsAndFolders,
  viewsAndFoldersToTree,
} from "./view-to-tree-utils";

export const OrdersItem = memo(function OrdersItem({
  ordersRoot,
}: {
  ordersRoot: string;
}) {
  const team = useRequiredContext(TeamContext);
  const urlPrefix = `${ordersRoot}/outbound-labels`;

  const omsEnabled = team?.settings.outboundLabels?.newOmsEnabled;
  const anyChildSelected = useMatch(`${urlPrefix}/*`);

  const viewOrderingContext = useRequiredContext(OmsViewOrderingContext);

  const allViews = useMemo(() => {
    return [...viewOrderingContext.views];
  }, [viewOrderingContext]);

  const allFolders = useMemo(() => {
    return [...viewOrderingContext.folders];
  }, [viewOrderingContext]);

  const viewsWithoutFolders = useMemo(() => {
    return allViews.filter((view) => !view.folderId);
  }, [allViews]);

  const foldersWithViews = useMemo<FolderWithView[]>(() => {
    return allFolders.map((folder) => {
      return {
        folder,
        views: allViews.filter((view) => view.folderId === folder._id),
      };
    });
  }, [allFolders, allViews]);

  const sortableItems = useMemo(() => {
    return viewsAndFoldersToTree(
      viewsWithoutFolders,
      foldersWithViews,
      urlPrefix,
    );
  }, [viewsWithoutFolders, foldersWithViews, urlPrefix]);

  const handleRequestEdit = useHandler((id: UniqueIdentifier) => {
    viewOrderingContext.openEditFolderModal(id.toString());
  });

  const handleUpdateOrdering = useHandler((items: TreeItem[]) => {
    const flattenedItems = flattenTree(items);

    const { views, folders } = treeToViewsAndFolders(
      flattenedItems,
      allViews,
      allFolders,
    );

    viewOrderingContext.updateOrdering(views, folders);
  });

  if (!omsEnabled) {
    return (
      <>
        <RedoSecondaryNavItem
          action={{ type: "link", href: `${urlPrefix}/orders` }}
          FilledIcon={PackageFilledIcon}
          friendlyName="All orders"
          id="all-orders"
          UnfilledIcon={PackageFilledIcon}
        />
        <RedoSecondaryNavItem
          action={{ type: "link", href: `${urlPrefix}/about` }}
          FilledIcon={PickupTruckFilledIcon}
          friendlyName="Shipments"
          id="about-oms"
          isNew
          UnfilledIcon={PickupTruckIcon}
        />
      </>
    );
  }
  return (
    <RedoSecondaryNavItem
      action={{ type: "link", href: `${urlPrefix}/awaiting-shipment` }}
      anyChildSelected={!!anyChildSelected}
      FilledIcon={PackageFilledIcon}
      friendlyName="Orders"
      id="orders"
      UnfilledIcon={PackageFilledIcon}
    >
      {/* TODO: Enable once dashboard backend is implemented */}
      {/* <RedoSecondaryNavItem
        action={{ type: "link", href: `${urlPrefix}/dashboard` }}
        friendlyName="Dashboard"
        id="orders-outbound-labels-dashboard"
      /> */}
      <RedoSecondaryNavItem
        action={{ type: "link", href: `${urlPrefix}/orders` }}
        friendlyName="All orders"
        id="all-orders"
      />
      <SortableTree
        items={sortableItems}
        onRequestEdit={handleRequestEdit}
        setItems={handleUpdateOrdering}
      />
      <RedoSecondaryNavItem
        action={{ type: "link", href: `${urlPrefix}/create` }}
        friendlyName="+ Add new view"
        id="orders-outbound-labels-create"
      />
      <RedoSecondaryNavItem
        action={{
          type: "button",
          onClick: () => {
            viewOrderingContext.openCreateFolderModal();
          },
        }}
        friendlyName="+ Add new folder"
        id="orders-outbound-labels-create-folder"
      />
      <RedoSecondaryNavItem
        action={{ type: "link", href: `${urlPrefix}/batch-history` }}
        friendlyName="Batch history"
        id="orders-batch-history"
      />
      <RedoSecondaryNavItem
        action={{ type: "link", href: `${urlPrefix}/eod-manifest` }}
        friendlyName="EOD Manifest"
        id="orders-eod-manifest"
      />
      <RedoSecondaryNavItem
        action={{ type: "link", href: `${urlPrefix}/scan` }}
        friendlyName="Scan"
        id="scan"
      />
    </RedoSecondaryNavItem>
  );
});
