// extracted by mini-css-extract-plugin
export var actionButton = "_cz-1";
export var actions = "_cz-0";
export var copyableField = "_cz-2";
export var icon = "_cz-3";
export var overflowVisible = "_cz-4";
export var pillWrapper = "_cz-5";
export var productDetails = "_cz-7";
export var productPillsContainer = "_cz-8";
export var productTopDetails = "_cz-9";
export var products = "_cz-6";
export var returnDetails = "_cz-a";
export var strong = "_cz-b";
export var variantQuantityRow = "_cz-c";