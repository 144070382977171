import { ReturnTypeEnum } from "@redotech/redo-model/return";
import { z } from "zod";

export const getReturnOperationsKeyStats = {
  input: z.object({
    startDate: z.string(),
    endDate: z.string(),
    types: z.array(z.nativeEnum(ReturnTypeEnum)),
  }),
  output: z.object({
    numCreated: z.number(),
    numApproved: z.number(),
    numProcessedComplete: z.number(),
  }),
};

export type ReturnOperationsKeyStats = z.infer<
  typeof getReturnOperationsKeyStats.output
>;
