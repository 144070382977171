import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { useLoad } from "@redotech/react-util/load";
import { RedoMerchantRpcClientContext } from "@redotech/redo-merchant-app-common/rpc-client";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { Order } from "@redotech/redo-model/order";
import {
  Return,
  ReturnedProductStatus,
  ReturnTypeEnum,
} from "@redotech/redo-model/return";
import { ReturnStatus } from "@redotech/redo-model/return-status";
import {
  getReturnTotalsFrontendLogFn,
  ReturnTotalsCalculator,
} from "@redotech/redo-model/return-totals-calculator";
import { RedoBadge } from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import ChevronDownSvg from "@redotech/redo-web/arbiter-icon/chevron-down_filled.svg";
import ChevronUpSvg from "@redotech/redo-web/arbiter-icon/chevron-up_filled.svg";
import { Button, ButtonSize, ButtonTheme } from "@redotech/redo-web/button";
import { CopyableText } from "@redotech/redo-web/copyable-text";
import { CurrencyContext } from "@redotech/redo-web/currency";
import { ExternalLinkButton } from "@redotech/redo-web/external-link-button";
import { Flex } from "@redotech/redo-web/flex";
import MailIcon from "@redotech/redo-web/icon-old/mail.svg";
import RefreshIcon from "@redotech/redo-web/icon-old/refresh.svg";
import { SkeletonText } from "@redotech/redo-web/skeleton";
import { Text } from "@redotech/redo-web/text";
import { memo, useContext, useEffect, useState } from "react";
import { ResendModal } from "../../return/return-modals/resend-modal";
import { ResetModal } from "../../return/return-modals/reset-modal";
import { returnKindNameReturn, returnStatusName } from "../../return/util";
import { ProductDetailCard } from "../product-detail-card";
import { getOrderOrReturnDetailName } from "./naming-utils";
import * as orderDetailsCss from "./order-details/order-details.module.css";
import * as returnDetailCss from "./return-details.module.css";

export const ReturnDetails = memo(function ReturnDetails({
  returnItem,
  reloadReturns,
}: {
  returnItem: Return;
  reloadReturns: () => void;
}) {
  const redoMerchantRpcClient = useRequiredContext(
    RedoMerchantRpcClientContext,
  );
  const team = useRequiredContext(TeamContext);
  const [resendModalOpen, setResendModalOpen] = useState(false);
  const [resetModalOpen, setResetModalOpen] = useState(false);

  const { formatCurrency } = useContext(CurrencyContext);

  const returnTotals = useLoad(async () => {
    const res = await redoMerchantRpcClient.getOrders({
      teamId: team._id,
      orderIds: returnItem.orders.map((order) => order.order),
    });

    const orders = res.orders as Order[];

    const returnTotalsCalculator = new ReturnTotalsCalculator({
      return_: returnItem,
      order: orders[0],
      orders,
      team,
      originOrders: returnItem.originOrders,
      logFn: getReturnTotalsFrontendLogFn(),
    });

    const nonZeroValueExchange =
      team.settings.exchanges?.nonZeroValueExchange &&
      returnItem.type === "claim" &&
      returnItem.advancedExchangeItems.length > 0;

    return returnTotalsCalculator.getTotalsForProducts(
      returnItem.products.filter(
        (product) => product.status !== ReturnedProductStatus.REJECTED,
      ),
      false,
      nonZeroValueExchange,
    );
  }, [returnItem, team]);

  const handleResetClose = useHandler(() => {
    setResetModalOpen(false);
    reloadReturns();
  });

  const [isCollapsed, setIsCollapsed] = useState(true);
  const canReset = ![ReturnStatus.COMPLETE, ReturnStatus.DELETED].includes(
    returnItem.status,
  );

  useEffect(() => {
    setIsCollapsed(true);
  }, [returnItem]);

  return (
    <Flex
      align="stretch"
      className={orderDetailsCss.orderCard}
      dir="column"
      grow="1"
      pb="md"
      pl="md"
      pr="md"
      pt="xs"
    >
      <Flex align="stretch" dir="column">
        <Flex align="center" grow="1" justify="space-between">
          <Flex align="center" gap="xxs">
            <CopyableText
              altTextToCopy={
                returnItem.orders[0].name[0] === "#"
                  ? returnItem.orders[0].name.substring(1)
                  : returnItem.orders[0].name
              }
              fontSize="xs"
              fontWeight="medium"
            >
              {getOrderOrReturnDetailName({
                detailType: returnItem.type as ReturnTypeEnum,
                name: returnItem.orders[0].name,
              })}
            </CopyableText>
            <ExternalLinkButton
              url={`/stores/${team._id}/${returnItem.type}s/${returnItem._id}`}
            />
          </Flex>
          <Flex gap="md">
            <Button
              className={orderDetailsCss.orderCollapseButton}
              onClick={() => setIsCollapsed(!isCollapsed)}
              size={ButtonSize.NANO}
              theme={ButtonTheme.GHOST}
            >
              <Flex
                align="center"
                className={orderDetailsCss.orderCollapseButtonIcon}
                justify="center"
              >
                {!isCollapsed ? <ChevronUpSvg /> : <ChevronDownSvg />}
              </Flex>
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <Flex wrap="nowrap">
        <div className={orderDetailsCss.orderPillsContainer}>
          <RedoBadge
            color="gray"
            size="sm"
            text={returnStatusName(returnItem.status)}
          />
        </div>
      </Flex>
      {isCollapsed ? (
        <Flex gap="xl" wrap="nowrap">
          <Flex align="stretch" dir="column" grow="1">
            <Text
              color="var(--redo-colors-text-text-tertiary-600"
              fontSize="xs"
              fontWeight="medium"
            >
              Total items
            </Text>
            <CopyableText
              color="var(--redo-colors-text-text-primary-900"
              fontSize="xs"
              fontWeight="medium"
            >
              {`${returnItem.products.length}`}
            </CopyableText>
          </Flex>
          <Flex align="stretch" dir="column" grow="1">
            <Text
              color="var(--redo-colors-text-text-tertiary-600"
              fontSize="xs"
              fontWeight="medium"
            >
              Total amount
            </Text>
            {returnTotals.value?.totalReturnValue ? (
              <CopyableText
                color="var(--redo-colors-text-text-primary-900"
                fontSize="xs"
                fontWeight="medium"
              >
                {formatCurrency(returnTotals.value.totalReturnValue || 0)}
              </CopyableText>
            ) : (
              <SkeletonText length={4} />
            )}
          </Flex>
        </Flex>
      ) : (
        <div className={returnDetailCss.returnDetails}>
          <div className={returnDetailCss.products}>
            {returnItem.products.map((product) => {
              return (
                <div
                  className={returnDetailCss.productDetails}
                  key={product._id}
                >
                  <ProductDetailCard
                    imageName={product.product_title}
                    imageSrc={product.images?.[0] ?? ""}
                    item={product.product_title}
                    price={formatCurrency(Number(product.price))}
                    quantity={product.quantity}
                    variant={product.variant_title}
                  />

                  <div className={returnDetailCss.productPillsContainer}>
                    {product.reason && (
                      <div className={returnDetailCss.pillWrapper}>
                        <RedoBadge
                          color="gray"
                          size="sm"
                          text={product.reason}
                        />
                      </div>
                    )}
                    <div className={returnDetailCss.pillWrapper}>
                      <RedoBadge
                        color="brand"
                        size="sm"
                        text={returnKindNameReturn(returnItem)}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className={returnDetailCss.actions}>
            <Button
              onClick={() => {
                setResendModalOpen(true);
              }}
              size={ButtonSize.MICRO}
              theme={ButtonTheme.OUTLINED}
            >
              <div className={returnDetailCss.actionButton}>
                <MailIcon className={returnDetailCss.icon} />
                Resend label
              </div>
            </Button>
            {canReset && (
              <Button
                onClick={() => {
                  setResetModalOpen(true);
                }}
                size={ButtonSize.MICRO}
                theme={ButtonTheme.OUTLINED}
              >
                <div className={returnDetailCss.actionButton}>
                  <RefreshIcon className={returnDetailCss.icon} />
                  Reset
                </div>
              </Button>
            )}
          </div>
        </div>
      )}
      {resendModalOpen && (
        <ResendModal
          onClose={() => setResendModalOpen(false)}
          open={resendModalOpen}
          return={returnItem}
          widgetSlug={team.widget_slug}
        />
      )}
      {resetModalOpen && (
        <ResetModal
          onClose={handleResetClose}
          onSubmitFinished={handleResetClose}
          open={resetModalOpen}
          return={returnItem}
        />
      )}
    </Flex>
  );
});
