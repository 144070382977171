import { z } from "zod";
import { MoneySchema } from "../outbound-labels/util";

export const FulfillmentLineItemSchema = z.object({
  externalId: z.string(),
  imageUrl: z.string().nullish(),
  customAttributes: z.array(z.object({ key: z.string(), value: z.string() })),
  quantity: z.number(),
  currentQuantity: z.number(),
  // These three IDs are nullish because if the product is deleted they all become null
  productId: z.string().nullish(),
  variantId: z.string().nullish(),
  lineItemId: z.string(),
  title: z.string(),
  variantTitle: z.string(),
  productTitle: z.string().nullish(),
  sku: z.string().nullish(),
  bin: z.string().nullish(),
  barcode: z.string().nullish(),
  originalUnitPrice: MoneySchema,
  discountedUnitPrice: MoneySchema,
  totalPrice: MoneySchema,
  selectedOptions: z
    .array(z.object({ name: z.string(), value: z.string() }))
    .nullish(),
  totalDiscountedPrice: MoneySchema,
  totalTax: MoneySchema,
});

export type FulfillmentLineItem = z.infer<typeof FulfillmentLineItemSchema>;
export interface IFulfillmentLineItem extends z.ZodType<FulfillmentLineItem> {}
