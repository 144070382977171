import { useRequiredContext } from "@redotech/react-util/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { UserContext } from "@redotech/redo-merchant-app-common/user";
import { FolderData } from "@redotech/redo-model/support/folder-types";
import { LinkPrimaryTab } from "@redotech/redo-web/arbiter-components/navigation/primary/redo-primary-nav";
import { PageNavigationEntry } from "@redotech/redo-web/arbiter-components/navigation/redo-side-navigation";
import {
  RedoNavDraggableList,
  RedoNavDraggableListItem,
} from "@redotech/redo-web/arbiter-components/navigation/secondary/redo-nav-draggable-list";
import { RedoNavFolder } from "@redotech/redo-web/arbiter-components/navigation/secondary/redo-nav-folder";
import { SecondaryNavigationData } from "@redotech/redo-web/arbiter-components/navigation/secondary/redo-secondary-nav";
import { RedoSecondaryNavItem } from "@redotech/redo-web/arbiter-components/navigation/secondary/redo-secondary-nav-item";
import TextIcon from "@redotech/redo-web/arbiter-icon/message-text-square-02.svg";
import TextFilledIcon from "@redotech/redo-web/arbiter-icon/message-text-square-02_filled.svg";
import TicketIcon from "@redotech/redo-web/arbiter-icon/ticket-02.svg";
import TicketFilledIcon from "@redotech/redo-web/arbiter-icon/ticket-02_filled.svg";
import { memo, useMemo } from "react";
import { useLocation, useMatch } from "react-router-dom";
import { ConversationCountsContext } from "../../../../services/support/conversation-counts-service";
import { SupportViewOrderingContext } from "../../../../services/support/support-view-ordering-service";
import { ViewInfo } from "../../../views";
import { ConciergeItems } from "./concierge";
import { CustomerItems } from "./customers";
import { useSupportNotifications } from "./support-notifications";

export function useSupportNavigation(): PageNavigationEntry | null {
  const team = useRequiredContext(TeamContext);

  const user = useRequiredContext(UserContext);

  const supportEnabled = team?.settings.support?.enabled;

  const conversationCounts = useRequiredContext(ConversationCountsContext);

  useSupportNotifications();

  const hasAnyNonZeroCounts = useMemo(() => {
    return Object.values(conversationCounts).some((count) => count > 0);
  }, [conversationCounts]);

  if (user.roles.includes("audien-support")) {
    return null;
  }

  const baseUrl = `/stores/${team._id}/support`;
  const primaryUrl = supportEnabled
    ? `${baseUrl}/table/all`
    : `${baseUrl}/about`;

  const primaryTab: LinkPrimaryTab = {
    id: "support",
    hasNotification: hasAnyNonZeroCounts,
    UnfilledIcon: TextIcon,
    FilledIcon: TextFilledIcon,
    type: "link",
    pathRoot: baseUrl,
    href: primaryUrl,
    friendlyName: "Support",
  };

  const secondaryNavigationData: SecondaryNavigationData = {
    id: "support",
    name: "Support",
    NavContent: SecondarySupportSection,
  };

  return { primaryTab, secondaryNavigationData };
}

const SecondarySupportSection = memo(function SecondarySupportSection({
  pathRoot,
}: {
  pathRoot: string;
}) {
  return (
    <>
      <SupportTicketsItem pathRoot={pathRoot} />
      <CustomerItems supportRoot={pathRoot} />
      <ConciergeItems supportRoot={pathRoot} />
    </>
  );
});

const SupportTicketsItem = memo(function SupportTicketsItem({
  pathRoot,
}: {
  pathRoot: string;
}) {
  const team = useRequiredContext(TeamContext);
  const supportEnabled = team?.settings.support?.enabled;
  const conversationCounts = useRequiredContext(ConversationCountsContext);

  const {
    folderlessViewOrdering,
    setFolderBeingEdited,
    folders,
    updateViewOrdering,
    openCreateFolderModal,
  } = useRequiredContext(SupportViewOrderingContext);

  const reorderableViews: RedoNavDraggableListItem<ViewInfo>[] = useMemo(() => {
    const itemToProps = (
      view: ViewInfo,
    ): RedoNavDraggableListItem<ViewInfo> => {
      const viewNameToUse = view.identifier.name || view.title;
      const viewCountKey = view.identifier?._id
        ? view.title
        : view.identifier?.name || "";

      const unreadCount = conversationCounts[viewCountKey] ?? 0;

      return {
        id: view.identifier._id || view.identifier.name || "",
        friendlyName: view.title,
        notificationCount: unreadCount,
        itemValue: view,
        isPrivate: view.isPrivate,
        action: {
          type: "link",
          href: `${pathRoot}/table/${encodeURIComponent(viewNameToUse)}`,
        },
      };
    };

    return folderlessViewOrdering.map(itemToProps);
  }, [conversationCounts, folderlessViewOrdering, pathRoot]);

  const anyChildSelected = useMatch(`${pathRoot}/*`);

  if (!supportEnabled) {
    return null;
  }

  return (
    <RedoSecondaryNavItem
      action={{ type: "button" }}
      anyChildSelected={!!anyChildSelected}
      FilledIcon={TicketFilledIcon}
      friendlyName="Tickets"
      id="tickets"
      UnfilledIcon={TicketIcon}
    >
      {folders.map((folder) => (
        <SupportFolder
          baseUrl={pathRoot}
          folder={folder}
          folderEditRequested={() => setFolderBeingEdited(folder)}
          key={folder._id}
        />
      ))}
      <RedoNavDraggableList
        draggableItems={reorderableViews}
        setDraggableItems={(newOrder) =>
          updateViewOrdering(newOrder.map((item) => item.itemValue))
        }
      />
      <RedoSecondaryNavItem
        action={{ type: "link", href: `${pathRoot}/table/sent` }}
        friendlyName="Sent"
        id="view-sent"
      />
      <RedoSecondaryNavItem
        action={{ type: "link", href: `${pathRoot}/table/spam` }}
        friendlyName="Spam"
        id="view-spam"
      />
      <RedoSecondaryNavItem
        action={{ type: "link", href: `${pathRoot}/table/create` }}
        friendlyName="+ Add new view"
        id="create-view"
      />
      <RedoSecondaryNavItem
        action={{ type: "button", onClick: openCreateFolderModal }}
        friendlyName="+ Create folder"
        id="create-folder"
      />
    </RedoSecondaryNavItem>
  );
});

const SupportFolder = memo(function SupportFolder({
  folder,
  folderEditRequested,
  baseUrl,
}: {
  folder: FolderData;
  folderEditRequested: () => void;
  baseUrl: string;
}) {
  const supportTablePrefix = `${baseUrl}/table`;

  const { defaultViewOrdering } = useRequiredContext(
    SupportViewOrderingContext,
  );

  const conversationCounts = useRequiredContext(ConversationCountsContext);

  const userDefinedViewsInFolder = useMemo(() => {
    const viewIds = new Set(folder.viewIds);

    return defaultViewOrdering.filter((view) =>
      view.identifier._id ? viewIds.has(view.identifier._id) : false,
    );
  }, [folder.viewIds, defaultViewOrdering]);

  const defaultViewsInFolder = useMemo(() => {
    const viewNames = new Set(folder.defaultViews);

    return defaultViewOrdering.filter((view) =>
      view.identifier.name ? viewNames.has(view.identifier.name) : false,
    );
  }, [folder.defaultViews, defaultViewOrdering]);

  const allFolderViews = useMemo(() => {
    return [...userDefinedViewsInFolder, ...defaultViewsInFolder];
  }, [userDefinedViewsInFolder, defaultViewsInFolder]);

  const location = useLocation();

  const anyChildSelected = useMemo(() => {
    return allFolderViews.some((view) => {
      const viewNameToUse = view.identifier.name || view.title;
      const link = `${supportTablePrefix}/${encodeURIComponent(viewNameToUse)}`;
      return location.pathname.startsWith(link);
    });
  }, [location.pathname, allFolderViews, supportTablePrefix]);

  const folderChildren = allFolderViews.map((view) => {
    const uniqueId = view.identifier._id || view.identifier.name || "";

    const linkName = view.identifier.name || view.title;

    const link = `${supportTablePrefix}/${encodeURIComponent(linkName)}`;

    const viewCountKey = view.identifier?._id
      ? view.title
      : view.identifier?.name || "";

    const unreadCount = conversationCounts[viewCountKey] ?? 0;

    return (
      <RedoSecondaryNavItem
        action={{ type: "link", href: link }}
        friendlyName={view.title}
        id={uniqueId}
        isPrivate={view.isPrivate}
        key={uniqueId}
        notificationCount={unreadCount}
      />
    );
  });

  return (
    <RedoNavFolder
      anyChildSelected={anyChildSelected}
      folderEditRequested={folderEditRequested}
      folderId={folder._id}
      folderName={folder.name}
    >
      {folderChildren}
    </RedoNavFolder>
  );
});
