import { memo } from "react";
import ChevronLeftIcon from "../../../../arbiter-icon/chevron-left.svg";
import ChevronRightIcon from "../../../../arbiter-icon/chevron-right.svg";
import { Flex } from "../../../../flex";
import { Text } from "../../../../text";
import { RedoButton } from "../../../buttons/redo-button";

interface CalendarHeaderProps {
  currentMonth: Temporal.PlainYearMonth;
  onPrevMonth: () => void;
  onNextMonth: () => void;
}

export const CalendarHeader = memo(function CalendarHeader({
  currentMonth,
  onPrevMonth,
  onNextMonth,
}: CalendarHeaderProps) {
  return (
    <Flex align="center" dir="row" justify="space-between">
      <RedoButton
        hierarchy="tertiary"
        IconLeading={ChevronLeftIcon}
        onClick={onPrevMonth}
        size="md"
      />

      <Text fontSize="md" fontWeight="semibold" textColor="secondary">
        {currentMonth.toLocaleString("en-US", {
          month: "long",
          year: "numeric",
          calendar: "iso8601",
        })}
      </Text>

      <RedoButton
        hierarchy="tertiary"
        IconLeading={ChevronRightIcon}
        onClick={onNextMonth}
        size="md"
      />
    </Flex>
  );
});
