import { useHover } from "@redotech/react-util/hover";
import * as classNames from "classnames";
import { memo, useState } from "react";
import { useMatch } from "react-router-dom";
import DotsReorderIcon from "../../../../arbiter-icon/dots-reorder.svg";
import { Flex } from "../../../../flex";
import { Text } from "../../../../text";
import { TextWeightValue } from "../../../../theme/typography";
import { OverflowTooltip } from "../../../../tooltip/overflow-tooltip";
import { RedoIcon } from "../../../icon/redo-icon";
import * as navigationCss from "../../redo-navigation.module.css";

export type RedoViewItemProps = {
  friendlyName: string;
  linkHref: string;
  dragHandleProps: object;
  clone?: boolean;
};

export const RedoViewItem = memo(function RedoViewItem({
  friendlyName,
  dragHandleProps,
  linkHref,
  clone,
}: RedoViewItemProps) {
  const active = useMatch({ path: linkHref, caseSensitive: true });

  const color: "primary" | "secondary" = active ? "primary" : "secondary";
  const textWeight: TextWeightValue = active ? "semibold" : "regular";

  const [overflowRef, setOverflowRef] = useState<HTMLDivElement | null>(null);
  const [componentRef, setComponentRef] = useState<HTMLDivElement | null>(null);

  const hovered = useHover(componentRef);

  return (
    <Flex
      as="a"
      className={classNames(navigationCss.navItem)}
      justify="space-between"
      linkProps={{ to: linkHref }}
      px="md"
      py="sm"
      radius="sm"
      ref={setComponentRef}
      w="full"
      {...dragHandleProps}
    >
      <OverflowTooltip
        overflowRef={overflowRef}
        tooltipProps={{ title: friendlyName, darkMode: true }}
        wrapperStyle={{
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Text
          fontSize="xs"
          fontWeight={textWeight}
          overflow="hidden"
          ref={setOverflowRef}
          textColor={color}
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {friendlyName}
        </Text>
      </OverflowTooltip>
      <Flex
        align="center"
        className={classNames(
          hovered && navigationCss.show,
          navigationCss.dragHandle,
        )}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <RedoIcon color="tertiary" Icon={DotsReorderIcon} size={16} />
      </Flex>
    </Flex>
  );
});
