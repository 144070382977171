import { z } from "zod";
import { makeReportFilterSchema } from "./base-report-filter";
import { ReportFilterType } from "./report-filter-type";

export enum VariantNameFilterQuery {
  IS_ANY = "isAny",
  IS_NONE = "isNone",
}

export const VariantNameFilterSchema = makeReportFilterSchema(
  ReportFilterType.VARIANT_NAME,
  VariantNameFilterQuery,
  z.array(z.string()),
);

export type VariantNameFilter = z.infer<typeof VariantNameFilterSchema>;
