import { PrinterOptionsSchema } from "@redotech/redo-model/printnode/printer";
import { z } from "zod";

export const printPackingSlip = {
  input: z.intersection(
    z.union([
      z.object({ fulfillmentGroupIds: z.array(z.string()) }),
      z.object({ batchId: z.string() }),
    ]),
    z.object({
      templateId: z.string().optional(),
      printerId: z.number().optional(),
      printerOptions: PrinterOptionsSchema.optional(),
    }),
  ),
  output: z.object({ url: z.string() }),
};

export type PrintPackingSlipInput = z.infer<typeof printPackingSlip.input>;
