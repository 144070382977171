import { z } from "zod";

export const getCheckoutOptimizationRevenuePerCheckoutExperienceMetrics = {
  input: z.null(),
  output: z.object({
    series: z.array(
      z.object({
        checkoutName: z.string(),
        totalRevenuePerCheckoutByMonth: z.array(z.number()),
      }),
    ),
    months: z.array(z.string()),
  }),
};
