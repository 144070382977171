import {
  createLazyContext,
  useLazyContextProvider,
  useRequiredContext,
} from "@redotech/react-util/context";
import { RedoMerchantRpcClientContext } from "@redotech/redo-merchant-app-common/rpc-client";
import { RedoFulfillmentLocation } from "@redotech/redo-model/fulfillments/fulfillment-location";
import { memo, ReactNode } from "react";

export const LocationContext =
  createLazyContext<readonly RedoFulfillmentLocation[]>();
export const LocationService = memo(function LocationService({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const client = useRequiredContext(RedoMerchantRpcClientContext);

  const loadState = useLazyContextProvider(async (signal) => {
    const { locations } = await client.getLocations(null, { signal });
    return locations;
  });

  return (
    <LocationContext.internalContext.Provider value={loadState}>
      {children}
    </LocationContext.internalContext.Provider>
  );
});
