import { z } from "zod";

export const addLocation = {
  input: z.object({
    name: z.string(),
    address1: z.string(),
    address2: z.string(),
    city: z.string(),
    province: z.string(),
    zip: z.string(),
    countryCode: z.string(),
    phone: z.string(),
  }),
  output: z.null(),
};
