import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";
import { discountConfiguration } from "./discount-schemas";

export const updateDiscount = {
  input: z.object({
    _id: zExt.objectId(),
    configuration: discountConfiguration,
  }),
  output: z.null(),
};
