import {
  base64StringJsonFormat,
  booleanJsonFormat,
  objectJsonFormat,
  stringEnumJsonFormat,
  stringJsonFormat,
  typedStringJsonFormat,
} from "@redotech/json/format";

export enum CallFlow {
  CUSTOMER_CALLING_SUPPORT_AGENT = "customer-calling-support-agent",
  SUPPORT_AGENT_CALLING_CUSTOMER = "support-agent-calling-customer",
  CUSTOMER_CALLING_OFF_HOURS = "customer-calling-off-hours",
  CUSTOMER_REQUESTED_VOICEMAIL = "customer-requested-voicemail",
}

export interface BasicCallState {
  type: CallFlow;
  teamId: string;
  billingGroupId: string;
  callRecordId: string;
  customerId: string;
  customerNumber: string;
}

interface BasicInboundCall extends BasicCallState {
  type: CallFlow.CUSTOMER_CALLING_SUPPORT_AGENT;
}

export interface SupportAgentResponseCall extends BasicInboundCall {
  customerCallId: string;
  supportAgentUserId: string;
}

export interface CustomerInboundBridge extends BasicInboundCall {
  supportAgentCallId: string;
  supportAgentUserId: string;
  supportAgentCallSessionId: string;
}

export interface CustomerCallingOffHours extends BasicCallState {
  type: CallFlow.CUSTOMER_CALLING_OFF_HOURS;
  offerVoicemail: boolean;
}

export interface CustomerRequestedVoicemail extends BasicCallState {
  type: CallFlow.CUSTOMER_REQUESTED_VOICEMAIL;
}

export interface SupportAgentCallingCustomerState extends BasicCallState {
  type: CallFlow.SUPPORT_AGENT_CALLING_CUSTOMER;
  isCustomerLeg: boolean;
  supportAgentUserId: string;
  conferenceName?: string;
}

const _basicCallStateFormat = objectJsonFormat<BasicCallState>(
  {
    type: stringEnumJsonFormat(CallFlow),
    teamId: stringJsonFormat,
    billingGroupId: stringJsonFormat,
    callRecordId: stringJsonFormat,
    customerId: stringJsonFormat,
    customerNumber: stringJsonFormat,
  },
  {},
);

export const basicCallStateFormat = base64StringJsonFormat(
  _basicCallStateFormat,
);

export const supportAgentResponseCallFormat = base64StringJsonFormat(
  objectJsonFormat<SupportAgentResponseCall>(
    {
      type: typedStringJsonFormat(CallFlow.CUSTOMER_CALLING_SUPPORT_AGENT),
      customerCallId: stringJsonFormat,
      supportAgentUserId: stringJsonFormat,
      teamId: stringJsonFormat,
      billingGroupId: stringJsonFormat,
      callRecordId: stringJsonFormat,
      customerId: stringJsonFormat,
      customerNumber: stringJsonFormat,
    },
    {},
  ),
);

export const bridgeCustomerToSupportAgentCallFormat = base64StringJsonFormat(
  objectJsonFormat<CustomerInboundBridge>(
    {
      type: typedStringJsonFormat(CallFlow.CUSTOMER_CALLING_SUPPORT_AGENT),
      supportAgentCallSessionId: stringJsonFormat,
      supportAgentUserId: stringJsonFormat,
      supportAgentCallId: stringJsonFormat,
      teamId: stringJsonFormat,
      billingGroupId: stringJsonFormat,
      callRecordId: stringJsonFormat,
      customerId: stringJsonFormat,
      customerNumber: stringJsonFormat,
    },
    {},
  ),
);

export const customerCallingOffHoursFormat = base64StringJsonFormat(
  objectJsonFormat<CustomerCallingOffHours>(
    {
      type: typedStringJsonFormat(CallFlow.CUSTOMER_CALLING_OFF_HOURS),
      teamId: stringJsonFormat,
      billingGroupId: stringJsonFormat,
      offerVoicemail: booleanJsonFormat,
      callRecordId: stringJsonFormat,
      customerId: stringJsonFormat,
      customerNumber: stringJsonFormat,
    },
    {},
  ),
);

export const customerRequestedVoicemailFormat = base64StringJsonFormat(
  objectJsonFormat<CustomerRequestedVoicemail>(
    {
      type: typedStringJsonFormat(CallFlow.CUSTOMER_REQUESTED_VOICEMAIL),
      teamId: stringJsonFormat,
      billingGroupId: stringJsonFormat,
      callRecordId: stringJsonFormat,
      customerId: stringJsonFormat,
      customerNumber: stringJsonFormat,
    },
    {},
  ),
);

export const supportAgentCallingCustomerFormat = base64StringJsonFormat(
  objectJsonFormat<SupportAgentCallingCustomerState>(
    {
      type: typedStringJsonFormat(CallFlow.SUPPORT_AGENT_CALLING_CUSTOMER),
      teamId: stringJsonFormat,
      billingGroupId: stringJsonFormat,
      isCustomerLeg: booleanJsonFormat,
      supportAgentUserId: stringJsonFormat,
      callRecordId: stringJsonFormat,
      customerId: stringJsonFormat,
      customerNumber: stringJsonFormat,
    },
    { conferenceName: stringJsonFormat },
  ),
);
