import { z } from "zod";

export const getAutocompleteSuggestions = {
  input: z.object({ query: z.string(), sessionToken: z.string().optional() }),
  output: z.object({
    suggestions: z.array(
      z.object({ displayText: z.string(), keyToFullObject: z.string() }),
    ),
  }),
};
