export enum NotificationType {
  NEW_MESSAGE = "NEW_MESSAGE",
  MESSAGE_DELETED = "MESSAGE_DELETED",
  TICKET_ASSIGNED = "TICKET_ASSIGNED",
  MENTIONED = "MENTIONED",
  CONVERSATION_UPDATED = "CONVERSATION_UPDATED",
  EMPTY_MESSAGE = "EMPTY_MESSAGE",
  INCOMING_CALL = "INCOMING_CALL",
}

export type ConversationNotificationType =
  | NotificationType.NEW_MESSAGE
  | NotificationType.MESSAGE_DELETED
  | NotificationType.TICKET_ASSIGNED
  | NotificationType.MENTIONED
  | NotificationType.CONVERSATION_UPDATED;

interface BasicConversationNotification {
  type: ConversationNotificationType;
  conversationId: string;
}

interface EmptyNotification {
  type: NotificationType.EMPTY_MESSAGE;
}

export interface IncomingCallNotification {
  type: NotificationType.INCOMING_CALL;
  call_control_id: string;
  customerId: string;
  from_number: string;
  from_name?: string;
}

export type Notification =
  | BasicConversationNotification
  | EmptyNotification
  | IncomingCallNotification;
