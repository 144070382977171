import { UnableToStartCheckoutAbTestReason } from "@redotech/redo-model/checkouts/checkout-ab-test-utils";
import { z } from "zod";

export const canStartCheckoutAbTest = {
  input: z.null(),
  output: z.union([
    z.object({ canStart: z.literal(true) }),
    z.object({
      canStart: z.literal(false),
      reason: z.nativeEnum(UnableToStartCheckoutAbTestReason),
    }),
  ]),
};
export type CanStartCheckoutAbTestOutput = z.infer<
  typeof canStartCheckoutAbTest.output
>;
