import {
  genericForwardRefMemo,
  genericMemo,
} from "@redotech/react-util/component";
import { useHandler } from "@redotech/react-util/hook";
import { getKey } from "@redotech/react-util/key";
import ChevronDownIcon from "@redotech/redo-web/arbiter-icon/chevron-down_filled.svg";
import ChevronUpIcon from "@redotech/redo-web/arbiter-icon/chevron-up_filled.svg";
import XIcon from "@redotech/redo-web/arbiter-icon/x-close.svg";
import * as classNames from "classnames";
import { ForwardedRef, useState } from "react";
import { Flex } from "../../flex";
import { Icon } from "../../icon";
import { TextSizeValue } from "../../theme/typography";
import { DescriptionText, LabelText } from "../common/descriptors";
import { BadgeRedoListItem, RedoListItem } from "../list/redo-list";
import { dropdownSizeToListSize } from "../list/redo-list-dropdown";
import { RedoMultiselectDropdown } from "../list/redo-multiselect-dropdown";
import {
  BadgeRedoListItem as BadgeRedoListItemComponent,
  TextRedoListItem,
} from "../list/standard-redo-list-items";
import * as redoDropdownInputCss from "./redo-dropdown-input.module.css";
import { RedoDropdownInputSize } from "./redo-single-select-dropdown-input";

/**
 * @param searchSubmitted - use this to create a tag if that is applicable to your use case
 * This could be reworked into a 'create tag strategy' that could either be
 * search submission or creates a 'create tag' list item.
 */
export interface RedoTagsDropdownInputProps<T> {
  size?: RedoDropdownInputSize;
  options: BadgeRedoListItem<T>[];
  selectedOptions: BadgeRedoListItem<T>[];
  setSelectedOptions(items: BadgeRedoListItem<T>[]): void;
  searchSubmitted?: (searchString: string) => void;
  disabled?: boolean;
  className?: string;
  fitToAnchor?: boolean;
  label?: string;
  placeholder?: string;
  description?: string;
  searchString?: string;
  setSearchString?: (searchText: string) => void;
  searchPlaceholder?: string;
  loading?: boolean;
  flexGrow?: boolean;
}

export const RedoTagsDropdownInput = genericMemo(function RedoTagsDropdownInput<
  T,
>({
  options,
  selectedOptions = [],
  setSelectedOptions,
  searchSubmitted,
  size = "md",
  disabled = false,
  className,
  fitToAnchor = true,
  label,
  placeholder,
  description,
  searchString,
  setSearchString,
  searchPlaceholder,
  loading,
  flexGrow = true,
}: RedoTagsDropdownInputProps<T>) {
  const [dropdownButtonRef, setDropdownButtonRef] =
    useState<HTMLButtonElement | null>(null);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdownOpen = useHandler(() => {
    setDropdownOpen((prev) => !prev);
  });

  const descriptorFontSize = sizeToDescriptorTextProps[size];

  return (
    <>
      <Flex dir="column" gap="sm" grow={flexGrow ? 1 : undefined}>
        <LabelText label={label} size={descriptorFontSize} />
        <RedoTagsDropdownInputButton
          className={className}
          disabled={disabled}
          isDropdownOpen={dropdownOpen}
          onClick={(e) => {
            e.stopPropagation();
            toggleDropdownOpen();
          }}
          placeholder={placeholder}
          ref={setDropdownButtonRef}
          removeItem={(item) => {
            setSelectedOptions(
              selectedOptions.filter(
                (selectedItem) => selectedItem.id !== item.id,
              ),
            );
          }}
          selectedItems={selectedOptions}
          size={size}
        />
        <DescriptionText description={description} size={descriptorFontSize} />
      </Flex>
      <RedoMultiselectDropdown
        dropdownAnchor={dropdownButtonRef}
        dropdownOpen={dropdownOpen}
        fitToAnchor={fitToAnchor}
        items={options}
        itemsLoading={loading}
        searchPlaceholder={searchPlaceholder ?? placeholder}
        searchString={searchString}
        searchSubmitted={searchSubmitted}
        selectedItems={selectedOptions}
        selectionVariant="checkmark"
        setDropdownOpen={setDropdownOpen}
        setSearchString={setSearchString}
        setSelectedItems={
          setSelectedOptions as (items: RedoListItem<T>[]) => void
        }
        size={size}
      />
    </>
  );
});

interface RedoTagsDropdownInputButtonProps<T> {
  size?: RedoDropdownInputSize;
  isDropdownOpen: boolean;
  selectedItems?: BadgeRedoListItem<T>[];
  removeItem?: (item: BadgeRedoListItem<T>) => void;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
  ref?: ForwardedRef<HTMLButtonElement>;
}

export const RedoTagsDropdownInputButton = genericForwardRefMemo(
  function RedoTagsDropdownInputButton<T>(
    {
      selectedItems,
      removeItem,
      isDropdownOpen,
      size = "md",
      disabled = false,
      placeholder,
      className,
      onClick,
    }: RedoTagsDropdownInputButtonProps<T>,
    ref: ForwardedRef<HTMLButtonElement>,
  ) {
    return (
      <button
        className={classNames(
          className,
          redoDropdownInputCss.tagsInput,
          dropdownInputSizeToSizeClass[size],
          isDropdownOpen && redoDropdownInputCss.open,
          disabled && redoDropdownInputCss.disabled,
        )}
        disabled={disabled}
        onClick={onClick}
        ref={ref}
        type="button"
      >
        <Flex className={redoDropdownInputCss.tagsContainer} wrap="wrap">
          {selectedItems && selectedItems.length ? (
            selectedItems.map((selectedItem) => (
              <div
                className={redoDropdownInputCss.tag}
                key={getKey(selectedItem.id)}
                onClick={(e) => {
                  e.stopPropagation();
                  removeItem?.(selectedItem);
                }}
              >
                <BadgeRedoListItemComponent
                  badge={{
                    ...selectedItem.badge,
                    iconTrailing: { Icon: XIcon, type: "icon" },
                  }}
                  size={dropdownSizeToListSize[size]}
                  useWrapper={false}
                />
              </div>
            ))
          ) : (
            <TextRedoListItem
              placeholder
              size={dropdownSizeToListSize[size]}
              text={placeholder || ""}
              useWrapper={false}
            />
          )}
        </Flex>
        <Icon
          arbiterIconSvg={isDropdownOpen ? ChevronUpIcon : ChevronDownIcon}
          className={redoDropdownInputCss.chevron}
          color="ghost"
        />
      </button>
    );
  },
);

const dropdownInputSizeToSizeClass: Record<RedoDropdownInputSize, string> = {
  xs: redoDropdownInputCss.xSmall,
  sm: redoDropdownInputCss.small,
  md: redoDropdownInputCss.regular,
};

const sizeToDescriptorTextProps: Record<RedoDropdownInputSize, TextSizeValue> =
  { xs: "xs", sm: "xs", md: "sm" };
