import { z } from "zod";

import { ProductAnalyticsFilterSchema } from "@redotech/redo-model/analytics/returns-claims/filters/analytics-filter";
import {
  ProductSchema,
  ProductSortSchema,
  ReturnReasonSchema,
} from "./product-analytics.types";

export const getReturnAnalyticsByProduct = {
  input: z.object({
    sort: ProductSortSchema.optional(),
    filters: z.array(ProductAnalyticsFilterSchema).optional(),
  }),
  output: z.object({
    products: z.array(ProductSchema),
    returnReasons: z.array(ReturnReasonSchema),
  }),
};

export type ReturnAnalyticsByProduct = z.infer<
  typeof getReturnAnalyticsByProduct.output
>;
