import errorSound from "./error.mp3?url";
import redoRingtone from "./redo-ringtone.mp3?url";
import successSound2 from "./success-2.mp3?url";
import successSound from "./success.mp3?url";
export const ERROR_SOUND = new Audio(errorSound);

export const SUCCESS_SOUND = new Audio(successSound);
SUCCESS_SOUND.playbackRate = 1.5;

export const SUCCESS_SOUND_2 = new Audio(successSound2);

export const REDO_RINGTONE = new Audio(redoRingtone);
REDO_RINGTONE.loop = true;
