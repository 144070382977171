import { GetReturnOrderNotesOutput } from "@redotech/merchant-sdk/rpc/schema/return-page-details/get-return-order-notes";
import { useRequiredContext } from "@redotech/react-util/context";
import { useLoad } from "@redotech/react-util/load";
import { RedoMerchantRpcClientContext } from "@redotech/redo-merchant-app-common/rpc-client";
import { Return } from "@redotech/redo-model/return";
import { Divider } from "@redotech/redo-web/divider";
import { Flex } from "@redotech/redo-web/flex";
import { SkeletonText } from "@redotech/redo-web/skeleton";
import { Text } from "@redotech/redo-web/text";
import { isDefined } from "@redotech/util/type";
import { memo } from "react";
import * as returnPageRightPanelCss from "./return-page-right-panel.module.css";

interface CommentsoldOrderNotesCardProps {
  return?: Return;
}

export const CommentsoldOrderNotesCard = memo(
  function CommentsoldOrderNotesCard({
    return: return_,
  }: CommentsoldOrderNotesCardProps) {
    const rpcClient = useRequiredContext(RedoMerchantRpcClientContext);
    const orderNotesLoad = useLoad<
      GetReturnOrderNotesOutput | undefined
    >(async () => {
      if (!return_) {
        return undefined;
      }
      return await rpcClient.getReturnOrderNotes({ returnId: return_._id });
    }, [return_]);

    if (!return_) {
      return null;
    }

    if (orderNotesLoad.pending) {
      return <SkeletonText length={30} />;
    }
    if (orderNotesLoad.error) {
      return null;
    }
    if (orderNotesLoad.value?.length === 0) {
      return null;
    }
    if (orderNotesLoad.value?.every((note) => !isDefined(note.orderNote))) {
      return null;
    }

    return (
      <>
        <Flex
          className={returnPageRightPanelCss.cardPadding}
          dir="column"
          gap="xl"
        >
          <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
            Commentsold Order Note
          </Text>
          {orderNotesLoad.value?.map((note, index) => (
            <>
              <Flex dir="column">
                {(orderNotesLoad.value?.length ?? 0) > 1 && (
                  <Text
                    fontSize="xs"
                    fontWeight="regular"
                    textColor="secondary"
                  >
                    {note.orderName}
                  </Text>
                )}
                <Text fontSize="xs" fontWeight="regular" textColor="primary">
                  {note.orderNote}
                </Text>
              </Flex>
              {index < (orderNotesLoad.value?.length ?? 0) - 1 && <Divider />}
            </>
          ))}
        </Flex>
        <Divider />
      </>
    );
  },
);
