import { ObjectId } from "bson";
import { z } from "zod";
import {
  AdvancedFilterType,
  createAdvancedFilterDataSchema,
  GenericFilterBuilder,
} from "./generic-advanced-filter-data";

/**
 * @deprecated Use ArrayStringFilterOperator instead. This is kept only for migration purposes.
 */
export enum StringFilterOperator {
  EQUALS = "=",
  NOT_EQUALS = "!=",
  CONTAINS = "contains",
}
const StringFilterOperatorSchema = z.nativeEnum(StringFilterOperator);

const StringFilterValueSchema = z.string();

/**
 * @deprecated Use ArrayStringFilterDataSchema instead. This is kept only for migration purposes.
 */
export const StringFilterDataSchema = createAdvancedFilterDataSchema(
  AdvancedFilterType.STRING,
  StringFilterValueSchema,
  StringFilterOperatorSchema,
);

/**
 * @deprecated Use ArrayStringFilterData instead. This is kept only for migration purposes.
 */
export type StringFilterData = z.infer<typeof StringFilterDataSchema>;

/**
 * @deprecated Use ArrayStringFilterBuilder instead. This is kept only for migration purposes.
 */
export const StringFilterBuilder: GenericFilterBuilder<
  string,
  StringFilterOperator,
  StringFilterData
> = {
  type: AdvancedFilterType.STRING,
  valueSchema: StringFilterValueSchema,
  operatorSchema: StringFilterOperatorSchema,
  schema: StringFilterDataSchema,
  buildAtlasSearchQuery: ({
    filter,
    atlasPath,
    searchCompound,
    useObjectId = false,
  }) => {
    if (!filter.value) {
      return searchCompound;
    }

    if (filter.operator === StringFilterOperator.EQUALS) {
      searchCompound.filter.push({
        equals: {
          path: atlasPath,
          value: useObjectId ? new ObjectId(filter.value) : filter.value,
        },
      });
    } else if (filter.operator === StringFilterOperator.NOT_EQUALS) {
      searchCompound.mustNot.push({
        equals: {
          path: atlasPath,
          value: useObjectId ? new ObjectId(filter.value) : filter.value,
        },
      });
    } else if (filter.operator === StringFilterOperator.CONTAINS) {
      searchCompound.filter.push({
        text: { path: atlasPath, query: filter.value },
      });
    }
    return searchCompound;
  },
  readFromString: (name, operatorValueEncoded, defaultFilter) => {
    try {
      const decoded = decodeURIComponent(operatorValueEncoded);
      const operatorValue = decoded.split("::");

      const operator = operatorValue?.[0];
      const value = operatorValue?.[1];

      if (!operator) {
        return defaultFilter;
      }

      const parsedOperator = StringFilterOperatorSchema.safeParse(operator);
      if (!parsedOperator.success) {
        console.warn("Invalid operator type");
        return undefined;
      }

      if (!value) {
        return {
          type: AdvancedFilterType.STRING,
          name,
          value: null,
          operator: parsedOperator.data,
        };
      }

      const valueParsed = StringFilterValueSchema.safeParse(value);

      if (!valueParsed.success) {
        console.warn("Invalid value type for StringFilter");
        return undefined;
      }

      return {
        type: AdvancedFilterType.STRING,
        name,
        value: valueParsed.data,
        operator: parsedOperator.data,
      };
    } catch (error) {
      console.error("Error parsing StringFilter", error);
      return undefined;
    }
  },
  writeToString: (filter: StringFilterData | undefined) => {
    try {
      if (!filter) {
        return "";
      }
      const { operator, value } = filter;
      const parsedValue = StringFilterValueSchema.safeParse(value);

      if (parsedValue.success) {
        return `${operator}::${parsedValue.data}`;
      } else {
        return `${operator}::`;
      }
    } catch (error) {
      console.error("Error writing StringFilter", error);
      return "";
    }
  },
  isPartial: (filter) => {
    return !filter.operator || filter.value == null;
  },
};
