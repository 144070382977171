import { z } from "zod";

export const updateConciergeSkillsSettings = {
  input: z.object({
    settings: z.object({
      backInStockNotifications: z.object({ enabled: z.boolean() }),
      orderTracking: z.object({ enabled: z.boolean() }),
      cancelOrder: z.object({ enabled: z.boolean() }),
      editAddress: z.object({ enabled: z.boolean() }),
    }),
  }),
  output: z.null(),
};
