import { PrinterSchema } from "@redotech/redo-model/printnode/printer";
import { z } from "zod";

export const getPrintNodeInfo = {
  input: z.object({ refresh: z.boolean().default(false) }),
  output: z
    .object({
      email: z.string(),
      createdAt: z.date(),
      printers: z.array(PrinterSchema),
    })
    .nullable(),
};
