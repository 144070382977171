import {
  useLazyContext,
  useRequiredContext,
} from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { LoadState, useLoad } from "@redotech/react-util/load";
import { useUniqueTimeout } from "@redotech/react-util/timeout";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { MerchantAppTopic } from "@redotech/redo-merchant-app-common/events/merchant-app-event-server";
import { MerchantAppEventServerContext } from "@redotech/redo-merchant-app-common/events/merchant-app-event-server-provider";
import { getUserAvatarUrl } from "@redotech/redo-merchant-app-common/get-avatar-url";
import { RedoMerchantRpcClientContext } from "@redotech/redo-merchant-app-common/rpc-client";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { UserContext } from "@redotech/redo-merchant-app-common/user";
import { Customer } from "@redotech/redo-model/customer";
import {
  IncomingCallNotification,
  Notification,
  NotificationType,
} from "@redotech/redo-model/notification";
import {
  isVoiceEnabledPhoneNumber,
  VoiceEnabledPhoneNumber,
} from "@redotech/redo-model/phone/phone-number";
import {
  CallFlow,
  supportAgentCallingCustomerFormat,
  supportAgentResponseCallFormat,
} from "@redotech/redo-model/support/voice/voice-client-state";
import {
  Call,
  CallDirection,
  CallState,
  CustomerCallData,
  VoiceAgent,
  WebRTCCallState,
} from "@redotech/redo-model/support/voice/voice-types";
import {
  FrontendTeamNoticeEvent,
  TeamNoticeEventType,
} from "@redotech/redo-model/team-notifications/team-notifications";
import { Flex } from "@redotech/redo-web/flex";
import { PhoneWidget } from "@redotech/redo-web/voice/phone-widget";
import {
  VoiceContext,
  VoiceState,
} from "@redotech/redo-web/voice/voice-context";
import { convertArbitraryPhoneNumberToE164 } from "@redotech/util/phone-number";
import { assertNever, sinkValue } from "@redotech/util/type";
import { Audio, TelnyxRTCContext, useNotification } from "@telnyx/react-client";
import { TelnyxRTC } from "@telnyx/webrtc";
import { Call as TelnyxCall } from "@telnyx/webrtc/lib/src/Modules/Verto/webrtc/Call";
import { memo, useContext, useEffect, useState } from "react";
import { getCustomer } from "../../client/customer";
import { PhoneNumberContext } from "../../services/support/phone-number-service";
import { UserCallAvailabilityContext } from "../../services/support/user-call-availability";
import * as voiceWidgetCss from "./voice-widget.module.css";

export const VoiceWidget = memo(function VoiceWidget() {
  const telnyxRtcClient: TelnyxRTC = useContext(TelnyxRTCContext);
  const [phoneNumber] = useLazyContext(PhoneNumberContext);
  if (
    !telnyxRtcClient ||
    !phoneNumber.value ||
    !isVoiceEnabledPhoneNumber(phoneNumber.value)
  ) {
    return null;
  }

  return <PhoneInternal phoneNumber={phoneNumber.value} />;
});

const PhoneInternal = memo(function PhoneInternal({
  phoneNumber,
}: {
  phoneNumber: VoiceEnabledPhoneNumber;
}) {
  const userCallAvailability = useRequiredContext(UserCallAvailabilityContext);
  const telnyxRtcClient: TelnyxRTC = useRequiredContext(TelnyxRTCContext);
  const eventServer = useRequiredContext(MerchantAppEventServerContext);
  const merchantClient = useRequiredContext(RedoMerchantClientContext);
  const merchantRpcClient = useRequiredContext(RedoMerchantRpcClientContext);
  const team = useRequiredContext(TeamContext);
  const user = useRequiredContext(UserContext);
  const [callIdsWeHaveHungUp, setCallIdsWeHaveHungUp] = useState<string[]>([]);

  const agent: VoiceAgent = {
    name: user.name,
    imageUrl: getUserAvatarUrl({ email: user.email, userId: user._id }),
  };

  const notification = useNotification();

  const [callWithoutName, setCallWithoutName] = useState<Call | undefined>(
    undefined,
  );

  const [customerCallData, setCustomerCallData] = useState<
    CustomerCallData | undefined
  >(undefined);

  const telnyxCall: TelnyxCall | null = notification?.call;
  const telnyxCallState = telnyxCall?.state as WebRTCCallState | undefined;

  telnyxCallState && console.log(`Call state: ${telnyxCallState}`);

  const customer: LoadState<Customer | undefined> = useLoad(async () => {
    if (!customerCallData) {
      return;
    }
    return await getCustomer(merchantClient, {
      id: customerCallData.customerId,
    });
  }, [customerCallData?.customerId]);
  const call: Call | undefined = callWithoutName
    ? { ...callWithoutName, remoteCallerName: customer.value?.name }
    : undefined;

  const handleIncomingCall = useHandler((message: IncomingCallNotification) => {
    if (call) {
      return;
    }
    setCustomerCallData({
      customerCallControlId: message.call_control_id,
      customerId: message.customerId,
      customerNumber: message.from_number,
    });
    setCallWithoutName({
      state: CallState.RINGING,
      direction: CallDirection.INBOUND,
      remoteCallerNumber: message.from_number,
      agents: [agent],
    });
  });

  useEffect(() => {
    if (
      telnyxCallState === WebRTCCallState.ACTIVE &&
      (callWithoutName?.state === CallState.CONNECTING ||
        callWithoutName?.direction === CallDirection.OUTBOUND)
    ) {
      setCallConnectedState(callWithoutName.direction);
    }
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [telnyxCallState]);

  const setCallConnectedState = useHandler((direction: CallDirection) => {
    if (!telnyxCall || !callWithoutName) {
      return;
    }
    setCallWithoutName({
      state: CallState.ACTIVE,
      direction,
      startTime: new Date(),
      remoteCallerNumber: callWithoutName.remoteCallerNumber,
      agents: [agent],
    });
  });

  const handleTeamEvent = useHandler((message: FrontendTeamNoticeEvent) => {
    if (message.type === TeamNoticeEventType.CALL_CONNECTED) {
      if (message.direction === CallDirection.OUTBOUND) {
        setCustomerCallData({
          customerCallControlId: message.customerCallControlId,
          customerId: message.customerId,
          customerNumber: message.customerNumber,
        });
      } else if (message.direction === CallDirection.INBOUND) {
        if (
          !customerCallData ||
          !telnyxCall ||
          callWithoutName?.state !== CallState.CONNECTING
        ) {
          return;
        }
        const weAreWaitingForDataAboutThisCall =
          message.customerCallControlId ===
          customerCallData.customerCallControlId;
        if (!weAreWaitingForDataAboutThisCall) {
          return;
        }
        const callConnectedToUs =
          message.supportAgentCallSessionId ===
          telnyxCall.telnyxIDs.telnyxSessionId;
        if (callConnectedToUs) {
          setCallConnectedState(CallDirection.INBOUND);
        } else {
          endCall();
        }
      } else {
        assertNever(message.direction);
      }
    } else if (message.type === TeamNoticeEventType.END_RINGING) {
      const alreadyInACall = call?.state === CallState.ACTIVE;
      const waitingForCallConnection = call?.state === CallState.CONNECTING;
      if (alreadyInACall || waitingForCallConnection) {
        return;
      }
      endCall();
      rejectCall();
    }
  });

  const handleUserEvent = useHandler((message: Notification) => {
    if (message.type === NotificationType.INCOMING_CALL) {
      handleIncomingCall(message);
    }
  });

  useEffect(() => {
    const teamUnsubscribe = eventServer.subscribe({
      topic: MerchantAppTopic.TEAM,
      callback: handleTeamEvent,
    });
    const unsubscribe = eventServer.subscribe({
      topic: MerchantAppTopic.USER_NOTIFICATIONS,
      callback: handleUserEvent,
    });
    return () => {
      teamUnsubscribe();
      unsubscribe();
    };
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventServer]);

  const performCall = useHandler(async (number: string) => {
    const e164Number = convertArbitraryPhoneNumberToE164(number);
    if (!e164Number) {
      return;
    }

    const fetchedCustomer =
      await merchantRpcClient.getOrCreateCustomerFromPhoneNumber({
        number: e164Number,
      });

    const outboundState = supportAgentCallingCustomerFormat.write({
      type: CallFlow.SUPPORT_AGENT_CALLING_CUSTOMER,
      teamId: team._id,
      supportAgentUserId: user._id,
      billingGroupId: phoneNumber.voiceMetadata.billingGroupId,
      isCustomerLeg: false,
      callRecordId: "", // Gets filled in when created
      customerId: fetchedCustomer._id,
      customerNumber: e164Number,
    });
    telnyxRtcClient.newCall({
      destinationNumber: e164Number,
      callerNumber: phoneNumber.number,
      callerName: team.name,
      audio: true,
      video: false,
      clientState: outboundState,
    });
    setCallWithoutName({
      state: CallState.RINGING,
      direction: CallDirection.OUTBOUND,
      remoteCallerNumber: e164Number,
      agents: [agent],
    });
  });

  const endCall = useHandler(() => {
    if (!telnyxCall || callIdsWeHaveHungUp.includes(telnyxCall.id)) {
      return;
    } else {
      telnyxCall?.hangup();
      setCallIdsWeHaveHungUp((prev) => [...prev, telnyxCall.id]);
    }
  });

  const rejectCall = useHandler(() => {
    setCustomerCallData(undefined);
    setCallWithoutName(undefined);
  });

  const uniqueTimeout = useUniqueTimeout();

  const cleanupCallForHangup = useHandler((call: Call) => {
    setCustomerCallData(undefined);
    setCallWithoutName({
      state: CallState.HANGUP,
      direction: call.direction,
      remoteCallerNumber: call.remoteCallerNumber,
      agents: [agent],
    });

    uniqueTimeout(() => {
      setCallWithoutName(undefined);
    }, 2000);
  });

  useEffect(() => {
    if (
      call &&
      (telnyxCallState === WebRTCCallState.DESTROY ||
        telnyxCallState === WebRTCCallState.HANGUP)
    ) {
      cleanupCallForHangup(call);
    }
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [telnyxCallState]);

  const answerCall = useHandler(async () => {
    if (!customerCallData) {
      return;
    }
    telnyxRtcClient.newCall({
      destinationNumber: customerCallData.customerNumber,
      callerNumber: phoneNumber.number,
      callerName: team.name,
      audio: true,
      video: false,
      clientState: supportAgentResponseCallFormat.write({
        type: CallFlow.CUSTOMER_CALLING_SUPPORT_AGENT,
        customerCallId: customerCallData.customerCallControlId,
        supportAgentUserId: user._id,
        teamId: team._id,
        billingGroupId: phoneNumber.voiceMetadata.billingGroupId,
        callRecordId: "", // Gets filled in when created
        customerId: customerCallData.customerId,
        customerNumber: customerCallData.customerNumber,
      }),
    });
    setCallWithoutName({
      state: CallState.CONNECTING,
      direction: CallDirection.INBOUND,
      remoteCallerNumber: customerCallData.customerNumber,
      agents: [agent],
    });
  });

  const [muted, setMuted] = useState(false);
  const toggleMute = useHandler(() => {
    telnyxCall?.toggleAudioMute();
    setMuted((prev) => !prev);
  });

  const [deafened, setDeafened] = useState(false);
  const toggleDeafen = useHandler(async () => {
    telnyxCall?.toggleDeaf();
    setDeafened((prev) => !prev);
  });

  const [onHold, setOnHold] = useState(false);
  const toggleHold = useHandler(async () => {
    const id = customerCallData?.customerCallControlId || "";
    await merchantRpcClient.toggleCallOnHold({
      customerCallId: id,
      onHold: !onHold,
    });
    await telnyxCall?.toggleHold();
    setOnHold((prev) => !prev);
  });

  const [recording, setRecording] = useState(false);
  const toggleRecord = useHandler(async () => {
    const id = customerCallData?.customerCallControlId || "";
    sinkValue(id);
    try {
      await merchantRpcClient.toggleCallBeingRecorded({
        customerCallId: id,
        beingRecorded: !recording,
      });
      setRecording((prev) => !prev);
    } catch (error) {
      // A common error here is if they try to toggle the recording before the call is connected, we want to keep the UI in sync with the server state
      console.error("Error toggling call recording", error);
    }
  });

  if (!userCallAvailability) {
    return null;
  }

  const state: VoiceState = {
    call,
    status: userCallAvailability.availabilityStatus,
    setStatus: userCallAvailability.setAvailabilityStatus,
    performCall,
    rejectCall,
    endCall,
    answerCall,
    muted,
    toggleMute,
    deafened,
    toggleDeafen,
    onHold,
    toggleHold,
    recording,
    toggleRecord,
  };

  return (
    <Flex className={voiceWidgetCss.phone}>
      <VoiceContext.Provider value={state}>
        <PhoneWidget
          merchantPhoneNumber={phoneNumber.number}
          status={userCallAvailability.availabilityStatus}
        />
      </VoiceContext.Provider>
      <Audio stream={telnyxCall?.remoteStream} />
    </Flex>
  );
});
