import { genericMemo } from "@redotech/react-util/component";
import { useHandler } from "@redotech/react-util/hook";
import { RedoFilterDropdownAnchor } from "@redotech/redo-web/arbiter-components/filter-group/redo-filter-group";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { useEffect, useMemo, useState } from "react";
import { RedoListItem } from "../arbiter-components/list/redo-list";
import { RedoMultiselectDropdown } from "../arbiter-components/list/redo-multiselect-dropdown";

function optionToItem<T>(
  option: T,
  optionToFriendlyName: (option: T) => string,
): RedoListItem<T> {
  return {
    id: `${option}`,
    value: option,
    type: "text",
    text: optionToFriendlyName(option),
  };
}

export const SimpleMultiSelectFilterDropdown = genericMemo(
  function SimpleMultiSelectFilterDropdown<T extends string>({
    values,
    setValues,
    options,
    optionToFriendlyName,
    selectedOptionsToDisplay,
    openOnRender,
    filterStyle = "value",
  }: {
    values: T[] | undefined;
    setValues(items: T[]): void;
    options: T[];
    optionToFriendlyName: (option: T) => string;
    selectedOptionsToDisplay: (selectedOptions: T[] | undefined) => string;
    openOnRender?: boolean;
    filterStyle?: "query" | "value";
  }) {
    const optionItems: RedoListItem<T>[] = useMemo(() => {
      return options.map((option) =>
        optionToItem(option, optionToFriendlyName),
      );
    }, [options, optionToFriendlyName]);

    const selectedOptions = useMemo(() => {
      return (values || []).map((value) =>
        optionToItem(value, optionToFriendlyName),
      );
    }, [optionToFriendlyName, values]);

    const [dropdownButtonRef, setDropdownButtonRef] =
      useState<HTMLButtonElement | null>(null);

    const [dropdownOpen, setDropdownOpen] = useState(false);

    useEffect(() => {
      if (openOnRender && dropdownButtonRef) {
        dropdownButtonRef.click();
      }
    }, [openOnRender, dropdownButtonRef]);

    const selectOptions = useHandler((items: RedoListItem<T>[]) =>
      setValues(items.map((i) => i.value)),
    );

    const anyItemsSelected = values && values.length > 0;

    return (
      <>
        <RedoFilterDropdownAnchor
          color={filterStyle === "query" ? "secondary" : "primary"}
          onClick={() => setDropdownOpen(!dropdownOpen)}
          ref={setDropdownButtonRef}
          text={selectedOptionsToDisplay(values)}
          tooltip={
            anyItemsSelected && (
              <Flex dir="column">
                {values.map((value) => (
                  <Text key={value}>{optionToFriendlyName(value)}</Text>
                ))}
              </Flex>
            )
          }
          weight={filterStyle === "query" ? "regular" : "medium"}
        />
        <RedoMultiselectDropdown<T>
          dropdownAnchor={dropdownButtonRef}
          dropdownOpen={dropdownOpen}
          fitToAnchor={false}
          items={optionItems}
          selectedItems={selectedOptions}
          setDropdownOpen={setDropdownOpen}
          setSelectedItems={selectOptions}
          size="xs"
        />
      </>
    );
  },
);
