import { z } from "zod";
import { MoneySchema } from "../outbound-labels/util";
import { RedoFulfillmentLocationSchema } from "./fulfillment-location";
import { FulfillmentOrderAddressSchema } from "./fulfillment-order-address";
import { FulfillmentOrderLineItemSchema } from "./fulfillment-order-line-item";

export const CreateFulfillmentOrderSchema = z.object({
  location: RedoFulfillmentLocationSchema.nullish(),
  address: FulfillmentOrderAddressSchema,
  orderInfo: z.object({
    orderNumber: z.string(),
    orderDate: z.date(),
    paidDate: z.date(),
    shippingPaid: MoneySchema,
    taxPaid: MoneySchema,
    totalPaid: MoneySchema,
    items: z.array(FulfillmentOrderLineItemSchema),
    internalNote: z.string(),
  }),
});

export type CreateFulfillmentOrder = z.infer<
  typeof CreateFulfillmentOrderSchema
>;
