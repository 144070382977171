import { Currency } from "@redotech/money/currencies";
import { z } from "zod";

export const getWidgetsSummaryMetrics = {
  input: z.null(),
  output: z.object({
    salesGeneratedByWidgets: z.object({
      shopCurrency: z.nativeEnum(Currency),
      value: z.number().optional(),
    }),
    checkoutConversionRateOverLast30Days: z.number().optional(),
    revenueLiftPerOrder: z.object({
      shopCurrency: z.nativeEnum(Currency),
      value: z.number().optional(),
    }),
  }),
};
