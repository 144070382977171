import { z } from "zod";

export const getMetaAdsAudiences = {
  input: z.object({ accountId: z.string() }),
  output: z.object({
    audiences: z.array(
      z.object({
        id: z.string(),
        name: z.string(),
        description: z.string(),
        createdTime: z.number(),
        updatedTime: z.number(),
      }),
    ),
  }),
};
