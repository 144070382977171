export enum AnalyticsReportType {
  PRODUCT = "product",
  VARIANT = "variant",
}

export enum AnalyticsFilterType {
  ORDER_CREATED_DATE = "orderCreatedDate",
  NUM_SOLD = "numSold",
  RETURN_TYPE = "returnType",
}

export const PRODUCT_ANALYTICS_FILTER_TYPES = [
  AnalyticsFilterType.ORDER_CREATED_DATE,
  AnalyticsFilterType.NUM_SOLD,
  AnalyticsFilterType.RETURN_TYPE,
] as const;

export function isValidProductAnalyticsFilterType(
  type: AnalyticsFilterType,
): type is (typeof PRODUCT_ANALYTICS_FILTER_TYPES)[number] {
  return (PRODUCT_ANALYTICS_FILTER_TYPES as readonly string[]).includes(type);
}

export const VARIANT_ANALYTICS_FILTER_TYPES = [
  AnalyticsFilterType.ORDER_CREATED_DATE,
  AnalyticsFilterType.NUM_SOLD,
  AnalyticsFilterType.RETURN_TYPE,
] as const;

export function isValidVariantAnalyticsFilterType(
  type: AnalyticsFilterType,
): type is (typeof VARIANT_ANALYTICS_FILTER_TYPES)[number] {
  return (VARIANT_ANALYTICS_FILTER_TYPES as readonly string[]).includes(type);
}

export function getValidFilterTypes(
  dataType: AnalyticsReportType,
): AnalyticsFilterType[] {
  switch (dataType) {
    case AnalyticsReportType.PRODUCT:
      return [...PRODUCT_ANALYTICS_FILTER_TYPES];
    case AnalyticsReportType.VARIANT:
      return [...VARIANT_ANALYTICS_FILTER_TYPES];
    default:
      return [];
  }
}
