import { z } from "zod";

export const saveMetaLeadAdsFormMappings = {
  input: z.object({
    pageId: z.string(),
    mappings: z.array(
      z.object({
        formId: z.string(),
        formName: z.string(),
        segmentId: z.string(),
        segmentName: z.string(),
      }),
    ),
  }),
  output: z.object({ success: z.boolean() }),
};
