import {
  FulfillmentGroupSchema,
  type IFulfillmentGroup,
} from "@redotech/redo-model/fulfillments/fulfillment-group";
import { z } from "zod";

export const getLatestOpenFulfillmentGroup = {
  input: z.object({ fulfillmentGroupId: z.string() }),
  output: z.object({
    fulfillmentGroup: FulfillmentGroupSchema as IFulfillmentGroup,
  }),
};
