import {
  Call,
  CallState,
} from "@redotech/redo-model/support/voice/voice-types";
import PauseCircle from "@redotech/redo-web/arbiter-icon/pause-circle_filled.svg";
import * as classNames from "classnames";
import { memo, useContext, useEffect, useState } from "react";
import { DotsLoading } from "../animations/dots-loading";
import { RedoButton } from "../arbiter-components/buttons/redo-button";
import { RedoTextInput } from "../arbiter-components/input/redo-text-input";
import { Flex } from "../flex";
import { Text } from "../text";
import * as darkModeCss from "../theme/component-colors-dark.module.css";
import { UserImage, UserImageSize } from "../user-image";
import { ActiveCallControls } from "./active-call-controls";
import * as activeCallViewCss from "./active-call-view.module.css";
import { CallProgressView } from "./call-progress";
import { PhoneWidgetNumpad } from "./phone-numpad";
import { VoiceContext } from "./voice-context";

export function callDurationString(duration: number): string {
  const durationMinutes = Math.floor(duration / 60);
  const durationSeconds = duration % 60;
  return `${durationMinutes}:${durationSeconds.toString().padStart(2, "0")}`;
}

export const ActiveCallView = memo(function ActiveCall({
  call,
}: {
  call: Call;
}) {
  const [showNumpad, setShowNumpad] = useState(false);

  return (
    <>
      <ActiveCallDetails call={call} numpadOpen={showNumpad} />
      <ActiveCallControls
        numpadShowing={showNumpad}
        toggleNumpadShowing={() => setShowNumpad((old) => !old)}
      />
    </>
  );
});

const ActiveCallDetails = memo(function ActiveCallView({
  call,
  numpadOpen,
}: {
  call: Call;
  numpadOpen: boolean;
}) {
  return (
    <Flex align="center" dir="column" p="xl">
      <CallProgressView call={call} />
      {call.state === CallState.ACTIVE && !numpadOpen && (
        <>
          <DotsLoading />
          <CallParticipantsView call={call} />
          <CallRecordingIndicator />
        </>
      )}
      {numpadOpen && <ActiveCallNumpadView />}
    </Flex>
  );
});

const ActiveCallNumpadView = memo(function ActiveCallNumpadView() {
  const [number, setNumber] = useState<string>("");

  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);

  useEffect(() => {
    if (inputRef) {
      inputRef.disabled = true;
    }
  }, [inputRef]);

  return (
    <Flex align="center" dir="column" p="xl">
      <RedoTextInput ref={setInputRef} setValue={() => {}} value={number} />
      <PhoneWidgetNumpad
        itemPressed={(item) => setNumber((old) => old + item)}
      />
    </Flex>
  );
});

const CallParticipantsView = memo(function CallParticipantsView({
  call,
}: {
  call: Call;
}) {
  const firstAgent = call.agents[0];
  return (
    <Flex align="center" dir="column">
      <UserImage
        alt="You"
        imageUrl={firstAgent.imageUrl}
        size={UserImageSize.MEDIUM_SMALL}
      />
      <Text fontSize="xxs" textColor="secondary">
        {firstAgent.name}
      </Text>
    </Flex>
  );
});

const CallRecordingIndicator = memo(function CallRecordingIndicator() {
  const { recording, toggleRecord } = useContext(VoiceContext);

  const text = recording ? "Recording..." : "Paused";

  return (
    <Flex
      align="center"
      bgColor="primary"
      className={classNames(
        activeCallViewCss.recordingIndicator,
        darkModeCss.darkMode,
      )}
      justify="space-between"
      pl="md"
      pr="xs"
      py="sm"
    >
      <Flex align="center">
        <div
          className={classNames(
            activeCallViewCss.recordingDot,
            recording && activeCallViewCss.active,
          )}
        />
        <Text fontSize="xxs" textColor="secondary">
          {text}
        </Text>
      </Flex>
      <RedoButton
        hierarchy="tertiary"
        IconLeading={PauseCircle}
        onClick={toggleRecord}
        pressed={!recording}
        size="xs"
      />
    </Flex>
  );
});
