// extracted by mini-css-extract-plugin
export var addDetails = "_2i-l";
export var addEditButton = "_2i-b";
export var addressFields = "_2i-2";
export var animationContainer = "_2i-0";
export var closeButton = "_2i-p";
export var customerDetails = "_2i-x";
export var customerInformationWrapper = "_2i-6";
export var dropdowns = "_2i-4";
export var error = "_2i-k";
export var fields = "_2i-n";
export var flex = "_2i-m";
export var grow = "_2i-3";
export var icon = "_2i-d";
export var imageButton = "_2i-q";
export var imagesWrapper = "_2i-s";
export var itemDetails = "_2i-i";
export var itemSelectionHeader = "_2i-9";
export var label = "_2i-w";
export var lineItemImage = "_2i-j";
export var lineItemReturnedWrapper = "_2i-h";
export var lineItemWrapper = "_2i-g";
export var modalContent = "_2i-f";
export var modalFooter = "_2i-1";
export var multipleChoiceAnswer = "_2i-u";
export var multipleChoiceAnswersWrapper = "_2i-t";
export var orderButton = "_2i-z";
export var orderLabel = "_2i-10";
export var orderSelection = "_2i-y";
export var question = "_2i-v";
export var search = "_2i-e";
export var selectCheckbox = "_2i-a";
export var selectionWrapper = "_2i-8";
export var spinner = "_2i-c";
export var subheader = "_2i-5";
export var title = "_2i-7";
export var uploadedImage = "_2i-o";
export var uploadedImageWrapper = "_2i-r";