import * as classNames from "classnames";
import { JSXElementConstructor, memo, useMemo, useState } from "react";
import { Flex } from "../../flex";
import { Text } from "../../text";
import * as lightModeCss from "../../theme/component-colors-light.module.css";
import { TextSizeValue, TextWeightValue } from "../../theme/typography";
import * as avatarCss from "./redo-avatar.module.css";

export const redoAvatarSize = [
  "xxs",
  "xs",
  "sm",
  "md",
  "lg",
  "xl",
  "2xl",
] as const;
export type RedoAvatarSize = (typeof redoAvatarSize)[number];

interface IconAvatarContent {
  type: "icon";
  Icon: JSXElementConstructor<any>;
}

interface TextAvatarContent {
  type: "text";
  text: string;
}

interface ImageAvatarContent {
  type: "image";
  imageUrl: string | null | undefined;
  altText: string;
  backup: IconAvatarContent | TextAvatarContent;
}

export type AvatarContent =
  | IconAvatarContent
  | TextAvatarContent
  | ImageAvatarContent;

const statusDotColor = ["green", "yellow", "red", "gray"] as const;
export type StatusDotColor = (typeof statusDotColor)[number];

interface AvatarPresenceStatus {
  type: "presence";
  color: StatusDotColor;
}

interface AvatarTypingStatus {
  type: "typing";
}

export type AvatarStatus = AvatarPresenceStatus | AvatarTypingStatus;

export interface RedoAvatarProps {
  content: AvatarContent;
  size?: RedoAvatarSize;
  status?: AvatarStatus;
}

export const RedoAvatar = memo(function RedoAvatar({
  content,
  size = "xs",
  status,
}: RedoAvatarProps) {
  const text = useMemo(() => {
    if (content.type === "text") {
      return content.text;
    }
    if (content.type === "image" && content.backup.type === "text") {
      return content.backup.text;
    }
    return "";
  }, [content]);

  const bubbleColor: { backgroundColor: string } | undefined = useMemo(() => {
    if (!text) {
      return undefined;
    }
    return { backgroundColor: getBubbleColor(text) };
  }, [text]);

  return (
    <Flex
      align="center"
      className={classNames(
        !!bubbleColor && lightModeCss.lightMode,
        avatarCss.avatarContainer,
        sizeToAvatarCssSize[size],
      )}
      gap="none"
      justify="center"
      style={bubbleColor}
    >
      <RedoAvatarContent content={content} size={size} />
      {status && <RedoAvatarStatus size={size} status={status} />}
    </Flex>
  );
});

const colors = [
  "#A894ED",
  "#3E91DD",
  "#E4A3DD",
  "#61C0C6",
  "#4ECC82",
  "#E86B4B",
  "#BFAC82",
  "#C0E861",
  "#ED9494",
  "#EDCF94",
  "#94E8ED",
  "#FF9447",
];

const getBubbleColor = (name: string) => {
  const numericValue = name
    .split("")
    .reduce((accumulator: number, letter: string) => {
      return accumulator + letter.charCodeAt(0);
    }, 0);
  const colorIndex = numericValue % colors.length;
  return colors[colorIndex];
};

const RedoAvatarStatus = memo(function RedoAvatarStatus({
  status,
  size,
}: {
  status: AvatarStatus;
  size: RedoAvatarSize;
}) {
  const content = useMemo(() => {
    switch (status.type) {
      case "presence":
        return <RedoAvatarPresenceStatus size={size} status={status} />;
      case "typing":
        return <RedoAvatarTypingStatus status={status} />;
    }
  }, [status, size]);

  return (
    <Flex className={classNames(avatarCss.status, sizeToAvatarCssSize[size])}>
      {content}
    </Flex>
  );
});

const RedoAvatarTypingStatus = memo(function RedoAvatarTypingStatus({
  status,
}: {
  status: AvatarTypingStatus;
}) {
  return (
    <span
      className={classNames(
        avatarCss.statusDot,
        avatarCss.typing,
        avatarCss.presence,
      )}
    />
  );
});

const RedoAvatarPresenceStatus = memo(function RedoAvatarPresenceStatus({
  status,
  size,
}: {
  status: AvatarPresenceStatus;
  size: RedoAvatarSize;
}) {
  return (
    <span
      className={classNames(
        avatarCss.presenceDot,
        statusDotColorToClass[status.color],
        sizeToAvatarCssSize[size],
      )}
    />
  );
});

const statusDotColorToClass: Record<StatusDotColor, string> = {
  green: avatarCss.green,
  yellow: avatarCss.yellow,
  red: avatarCss.red,
  gray: avatarCss.gray,
};

const RedoAvatarContent = memo(function RedoAvatarContent({
  content,
  size,
}: {
  content: AvatarContent;
  size: RedoAvatarSize;
}) {
  switch (content.type) {
    case "icon": {
      const Icon = content.Icon;
      return (
        <Flex className={classNames(avatarCss.icon, sizeToAvatarCssSize[size])}>
          <Icon className={sizeToAvatarCssSize[size]} />
        </Flex>
      );
    }
    case "text": {
      return <RedoAvatarText size={size} text={content.text} />;
    }
    case "image": {
      return <RedoAvatarImage {...content} size={size} />;
    }
  }
});

const RedoAvatarText = memo(function RedoAvatarText({
  text,
  size,
}: {
  text: string;
  size: RedoAvatarSize;
}) {
  const twoLettersToShow: string = useMemo(() => {
    if (text.length <= 2) {
      return text.toUpperCase();
    }
    // This can probably be refined
    const allInitials = text
      ?.split(" ")
      ?.filter((textPart) => /^[A-Za-z]/.test(textPart)) // Ensure the part starts with a letter
      ?.map((textPart) => textPart[0]);
    const initials =
      allInitials && allInitials.length > 0
        ? allInitials.length > 1
          ? allInitials[0] + allInitials[allInitials.length - 1]
          : allInitials[0]
        : undefined;

    return initials?.toUpperCase() || text.slice(0, 2).toUpperCase();
  }, [text]);

  const fontWeight: TextWeightValue = size === "xxs" ? "medium" : "semibold";

  return (
    <Text
      fontSize={avatarSizeToTextSize[size]}
      fontWeight={fontWeight}
      textColor="primary"
    >
      {twoLettersToShow}
    </Text>
  );
});

const avatarSizeToTextSize: Record<RedoAvatarSize, TextSizeValue> = {
  xxs: "xxs",
  xs: "xs",
  sm: "sm",
  md: "md",
  lg: "lg",
  xl: "xl",
  "2xl": "2xl",
};

const RedoAvatarImage = memo(function RedoAvatarImage({
  imageUrl,
  altText,
  backup,
  size,
}: {
  imageUrl: string | null | undefined;
  altText: string;
  backup: IconAvatarContent | TextAvatarContent;
  size: RedoAvatarSize;
}) {
  const [imageLoadFailed, setImageLoadFailed] = useState(false);

  const [loaded, setLoaded] = useState(false);

  const useBackup = imageLoadFailed || !loaded || !imageUrl;

  const imageSize = useBackup ? { height: 0, width: 0 } : undefined;

  return (
    <Flex gap="none" overflow="hidden" radius="full">
      {useBackup && <RedoAvatarContent content={backup} size={size} />}
      <img
        alt={altText}
        className={classNames(avatarCss.image)}
        onError={() => setImageLoadFailed(true)}
        onLoad={() => setLoaded(true)}
        src={imageUrl || ""}
        style={imageSize}
      />
    </Flex>
  );
});

const sizeToAvatarCssSize: Record<RedoAvatarSize, string> = {
  xxs: avatarCss.xxs,
  xs: avatarCss.xs,
  sm: avatarCss.sm,
  md: avatarCss.md,
  lg: avatarCss.lg,
  xl: avatarCss.xl,
  "2xl": avatarCss.xxl,
};
