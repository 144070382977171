import { useInput } from "@redotech/react-util/form";
import { createContext } from "react";
import {
  SettlementForm,
  settlementForm,
} from "../order/return-flow/review/settlement";

export const SettlementContext = createContext<{ input: SettlementForm }>({
  // @ts-expect-error: strict-null-checks
  input: undefined,
});

export const SettlementContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const settlementInput = useInput(settlementForm, {
    accept: false,
    refund: 0,
  });

  return (
    <SettlementContext.Provider value={{ input: settlementInput }}>
      {children}
    </SettlementContext.Provider>
  );
};
