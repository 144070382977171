import {
  AnnualTimeframeCondition,
  FullDateTimeframeCondition,
} from "./segment-timeframe";
import { CustomerCharacteristicType } from "./segment-types";

export type WhereCondition<DIM extends string> =
  | TokenCondition<DIM>
  | NumericCondition<DIM>
  | BooleanCondition<DIM>
  | FullDateCondition<DIM>
  | AnnualDateCondition<DIM>
  | TokenListCondition<DIM>
  | TokenHierarchyCondition<DIM>
  | CityProximityCondition<DIM>;
// | PostalProximityCondition<DIM>;

// We might want to create dedicated currency and percentage data types
export enum WhereConditionDataType {
  TOKEN = "token",
  NUMERIC = "numeric",
  BOOLEAN = "boolean",
  DATE_FULL = "date",
  DATE_ANNUAL = "date-annual",
  TOKEN_LIST = "token_list",
  TOKEN_HIERARCHY = "token-hierarchy",
  CITY_PROXIMITY = "city-proximity",
  // POSTAL_PROXIMITY = "postal-proximity",
}

export enum ProximityUnit {
  MILES = "miles",
  KILOMETERS = "kilometers",
}

export enum ComparisonType {
  BOOLEAN = "boolean",
  LIST = "list",
  NUMERIC = "numeric",
  TOKEN = "token",
  PROXIMITY = "proximity",
}

export interface Condition<DIM extends string, C> {
  type: WhereConditionDataType;
  dimension: DIM;
  comparison: C;
}

export enum TokenCompareOperators {
  // EQ = "EQ",
  // NEQ = "NEQ",
  NONE = "NONE",
  ANY = "ANY",
}

export enum ProximityCompareOperators {
  WITHIN = "WITHIN",
  OUTSIDE = "OUTSIDE",
}

// interface SingleTokenCompare {
//   type: ComparisonType.TOKEN;
//   operator: TokenCompareOperators.EQ | TokenCompareOperators.NEQ;
//   value: string;
// }

interface ListTokenCompare {
  type: ComparisonType.TOKEN;
  operator: TokenCompareOperators.NONE | TokenCompareOperators.ANY;
  values: string[];
}

/**
 * The number of prerequisite values that must be true before comparing against the leaf node values
 */
export const tokenHierarchyPrerequisiteCounts: Partial<
  Record<CustomerCharacteristicType, number>
> = {
  [CustomerCharacteristicType.STATE_PROVINCE]: 1, // country
  [CustomerCharacteristicType.CITY]: 2, // country, state
  [CustomerCharacteristicType.PROXIMITY_TO_CITY]: 2, // country, state
};

export interface TokenHierarchyCompare {
  type: ComparisonType.TOKEN;
  operator: TokenCompareOperators.NONE | TokenCompareOperators.ANY;
  /**
   * Prerequisite values that must be true before comparing against the leaf node values
   * e.g., ["US"] for TokenHierarchyType.STATE_REGION
   */
  prerequisiteValues: string[];
  /**
   * The leaf node values to compare against, e.g., ["provo", "orem"] but populated dynamically based on prerequisites
   */
  values: string[];
}

// type TokenCompare = SingleTokenCompare | ListTokenCompare;

export type TokenCompare = ListTokenCompare;

export interface TokenCondition<DIM extends string>
  extends Condition<DIM, TokenCompare> {
  type: WhereConditionDataType.TOKEN;
}

export enum NumericCompareOperator {
  EQ = "eq",
  GT = "gt",
  LT = "lt",
  GTE = "gte",
  LTE = "lte",
  NEQ = "neq",
}

export interface NumericCompare {
  type: ComparisonType.NUMERIC;
  operator: NumericCompareOperator;
  value: number;
}

export interface NumericCondition<DIM extends string>
  extends Condition<DIM, NumericCompare> {
  type: WhereConditionDataType.NUMERIC;
}

export interface BooleanCompare {
  type: ComparisonType.BOOLEAN;
  value: boolean;
}

export interface BooleanCondition<DIM extends string>
  extends Condition<DIM, BooleanCompare> {
  type: WhereConditionDataType.BOOLEAN;
}

export interface FullDateCondition<DIM extends string>
  extends Condition<DIM, FullDateTimeframeCondition> {
  type: WhereConditionDataType.DATE_FULL;
}

export interface AnnualDateCondition<DIM extends string>
  extends Condition<DIM, AnnualTimeframeCondition> {
  type: WhereConditionDataType.DATE_ANNUAL;
}

export enum ListCompareOperators {
  ANY = "any",
  NONE = "none",
  ALL = "all",
}

export interface ListCompare<T> {
  type: ComparisonType.LIST;
  operator: ListCompareOperators;
  values: T[];
}

export interface TokenListCondition<DIM extends string>
  extends Condition<DIM, ListCompare<string>> {
  type: WhereConditionDataType.TOKEN_LIST;
}

export interface TokenHierarchyCondition<DIM extends string>
  extends Condition<DIM, TokenHierarchyCompare> {
  type: WhereConditionDataType.TOKEN_HIERARCHY;
}

export interface CityProximityCompare {
  type: ComparisonType.PROXIMITY;
  operator: ProximityCompareOperators;
  prerequisiteValues: string[]; // [country, state]
  value: string; // city
  options: { radius: number; units: ProximityUnit };
}

export interface CityProximityCondition<DIM extends string>
  extends Condition<DIM, CityProximityCompare> {
  type: WhereConditionDataType.CITY_PROXIMITY;
}

export interface PostalProximityCompare {
  type: ComparisonType.PROXIMITY;
  operator: ProximityCompareOperators;
  value: string; // postal code
  options: { radius: number; units: ProximityUnit };
}

// export interface PostalProximityCondition<DIM extends string>
//   extends Condition<DIM, PostalProximityCompare> {
//   type: WhereConditionDataType.POSTAL_PROXIMITY;
// }
