import HelpIcon from "@redotech/redo-web/arbiter-icon/help-circle.svg";
import LayoutLeft from "@redotech/redo-web/arbiter-icon/layout-left.svg";
import LayoutLeftFilled from "@redotech/redo-web/arbiter-icon/layout-left_filled.svg";
import GearIcon from "@redotech/redo-web/arbiter-icon/settings-02.svg";
import GearFilledIcon from "@redotech/redo-web/arbiter-icon/settings-02_filled.svg";
import * as classNames from "classnames";
import { JSXElementConstructor, memo } from "react";
import { Flex } from "../../../flex";
import * as darkModeCss from "../../../theme/component-colors-dark.module.css";
import { RedoNavigationTeamItem } from "../common/redo-nav-team-item";
import { UserProfile } from "../common/redo-nav-user-profile";
import * as navigationCss from "../redo-navigation.module.css";
import { PrimaryNavigationTab } from "./redo-primary-nav-tab";

interface BasePrimaryTab {
  id: string;
  hasNotification: boolean;
  UnfilledIcon: JSXElementConstructor<any>;
  FilledIcon: JSXElementConstructor<any>;
  friendlyName: string;
}

export interface LinkPrimaryTab extends BasePrimaryTab {
  type: "link";
  href: string;
  pathRoot: string;
}

interface ButtonPrimaryTab extends BasePrimaryTab {
  type: "button";
  onClick: () => void;
}

export type PrimaryTab = LinkPrimaryTab | ButtonPrimaryTab;

interface RedoPrimaryNavProps {
  toggleCollapseMode: () => void;
  isCollapseMode: boolean;
  toggleHelp: () => void;
  enterSettingsUrl: string | null;
  settingsRoot: string;
  pageTabs: LinkPrimaryTab[];
}

export const RedoPrimaryNav = memo(function RedoPrimaryNav({
  toggleCollapseMode,
  isCollapseMode,
  toggleHelp,
  enterSettingsUrl,
  settingsRoot,
  pageTabs,
}: RedoPrimaryNavProps) {
  return (
    <Flex
      align="center"
      bgColor="tertiary"
      className={classNames(darkModeCss.darkMode, navigationCss.primaryNav)}
      dir="column"
      grow={1}
      justify="space-between"
      px="xl"
      py="3xl"
    >
      <Flex align="center" dir="column" gap="lg">
        <RedoNavigationTeamItem />
        <Flex dir="column" overflowY="auto">
          <Flex
            dir="column"
            gap="lg"
            overflowY="auto"
            style={{ scrollbarWidth: "none" }}
          >
            {pageTabs.map((tab) => (
              <PrimaryNavigationTab key={tab.id} tab={tab} />
            ))}
          </Flex>
        </Flex>
      </Flex>
      <Flex align="center" dir="column" gap="lg">
        <PrimaryNavigationTab
          manuallySelected={isCollapseMode}
          tab={{
            id: "collapse",
            UnfilledIcon: LayoutLeft,
            FilledIcon: LayoutLeftFilled,
            type: "button",
            friendlyName: "Collapse",
            hasNotification: false,
            onClick: toggleCollapseMode,
          }}
        />
        <PrimaryNavigationTab
          tab={{
            id: "help",
            UnfilledIcon: HelpIcon,
            FilledIcon: HelpIcon,
            type: "button",
            friendlyName: "Help",
            hasNotification: false,
            onClick: toggleHelp,
          }}
        />
        {enterSettingsUrl && (
          <PrimaryNavigationTab
            tab={{
              id: "settings",
              hasNotification: false,
              UnfilledIcon: GearIcon,
              FilledIcon: GearFilledIcon,
              type: "link",
              href: enterSettingsUrl,
              friendlyName: "Settings",
              pathRoot: settingsRoot,
            }}
          />
        )}
        <UserProfile collapsed />
      </Flex>
    </Flex>
  );
});
