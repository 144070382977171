import { ndjsonParse } from "@redotech/json/ndjson";
import { streamAsyncIterator } from "@redotech/streams-util/async-iterator";
import { sleep } from "@redotech/util/schedule";
import { REDO_MERCHANT_SERVER_URL } from "../config";

export const getTeamNotificationsStream = ({
  authorization,
  signal,
}: {
  authorization: { Authorization: string };
  signal: AbortSignal;
}) => {
  // This query param is unused on the backend. When Firefox sees two identical requests in short succession
  // (even in different tabs), it only makes one. Appending a random number stops that from happening.
  const url = `${REDO_MERCHANT_SERVER_URL}/team/conversations/live?rand=${Math.random()}`;
  return fetch(url, { signal, headers: authorization });
};

export const getConnectedTeamsCountsStream = ({
  authorization,
  signal,
}: {
  authorization: { Authorization: string };
  signal: AbortSignal;
}) => {
  const url = `${REDO_MERCHANT_SERVER_URL}/team/conversations/connectedTeamsCountsLive`;
  return fetch(url, { signal, headers: authorization });
};

export const getUserNotificationsStream = ({
  authorization,
  signal,
}: {
  authorization: { Authorization: string };
  signal: AbortSignal;
}) => {
  // We're not using the Math.random() trick here because we're only listening to this stream in one place.
  // If we later listen in two places, we might need to use the trick.
  const url = `${REDO_MERCHANT_SERVER_URL}/user/notifications/live`;
  return fetch(url, { signal, headers: authorization });
};

export async function* listen({
  query,
  loopCondition,
  setErrorMessage,
  setShowErrorMessage,
}: {
  query(): Promise<Response>;
  loopCondition?: boolean;
  setErrorMessage?(message: string): void;
  setShowErrorMessage?(show: boolean): void;
}) {
  while (loopCondition) {
    const response = await query();

    if (response) {
      // If we get a 400 error, show an error
      if (
        response.status.toString()[0] === "4" &&
        setErrorMessage &&
        setShowErrorMessage
      ) {
        setErrorMessage(
          "Failed to get message updates. Please reload the page. If the issue persists, please contact customer support.",
        );
        setShowErrorMessage(true);
      }
      const data: ReadableStream<any> | null = response.body;
      if (data) {
        const reader = data!.pipeThrough(new TextDecoderStream());
        for await (const result of ndjsonParse(streamAsyncIterator(reader))) {
          if (result) {
            yield result;
          }
        }
      }
    }
    await sleep(Temporal.Duration.from({ seconds: 5 }));
  }
}
