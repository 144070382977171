import type { UniqueIdentifier } from "@dnd-kit/core";

export enum TreeItemType {
  View = "view",
  Folder = "folder",
}

export interface AbstractTreeItem {
  id: UniqueIdentifier;
  name: string;
  friendlyName: string;
  type: TreeItemType;
}

export interface TreeViewItem extends AbstractTreeItem {
  type: TreeItemType.View;
  actionHref: string;
}

export interface TreeFolderItem extends AbstractTreeItem {
  type: TreeItemType.Folder;
  children: TreeItem[];
}

export type TreeItem = TreeViewItem | TreeFolderItem;

export type FlattenedItem = TreeItem & {
  parentId: UniqueIdentifier | null;
  depth: number;
  index: number;
};
