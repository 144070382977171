import { z } from "zod";

export const ProductivityDashboardItemSchema = z.object({
  teamMemberId: z.string(),
  ordersFulfilled: z.number(),
  productsFulfilled: z.number(),
  ouncesFulfilled: z.number(),
  dollarsSpent: z.number(),
  averageDollarsPerOunce: z.number(),
});

export type ProductivityDashboardItem = z.infer<
  typeof ProductivityDashboardItemSchema
>;
