import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { CreateOmsViewFolderModal } from "@redotech/redo-merchant-app-fulfillment/common/create-oms-view-folder-modal";
import { EditOmsViewFolderModal } from "@redotech/redo-merchant-app-fulfillment/common/edit-oms-view-folder-modal";
import { RedoOutboundLabelsRpcClientContext } from "@redotech/redo-merchant-app-fulfillment/outbound-labels-rpc-client";
import {
  OutboundViewsContext,
  ReloadOutboundViewsContext,
} from "@redotech/redo-merchant-app-fulfillment/outbound-views";
import { folderTypes } from "@redotech/redo-model/views/folders";
import { FulfillmentGroupView } from "@redotech/redo-model/views/views";
import {
  createContext,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

export interface ViewOrderingData {
  openCreateFolderModal: () => void;
  openEditFolderModal: (folderId: string) => void;
  handleDeleteFolder: (folderId: string) => void;
  updateOrdering: (
    updatedViews: FulfillmentGroupView[],
    updatedFolders: folderTypes["fulfillment-groups"][],
  ) => void;
  saveOrdering: () => void;
  views: FulfillmentGroupView[];
  folders: folderTypes["fulfillment-groups"][];
}

export const OmsViewOrderingContext = createContext<
  ViewOrderingData | undefined
>(undefined);

export const OmsViewOrderingService = memo(function OmsViewOrderingService({
  children,
}: {
  children: React.ReactNode;
}) {
  const rpcClient = useRequiredContext(RedoOutboundLabelsRpcClientContext);
  const omsNavData = useContext(OutboundViewsContext);
  const reloadOmsNavData = useRequiredContext(ReloadOutboundViewsContext);

  const [views, setViews] = useState<FulfillmentGroupView[]>([]);
  const [folders, setFolders] = useState<folderTypes["fulfillment-groups"][]>(
    [],
  );

  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
  const [showEditFolderModal, setShowEditFolderModal] = useState(false);

  const [selectedFolder, setSelectedFolder] = useState<
    folderTypes["fulfillment-groups"] | null
  >(null);

  const handleCreateFolder = async (name: string) => {
    await rpcClient.createViewFolder({ name });
    void reloadOmsNavData();
  };

  const handleEditFolder = async (folderId: string, folderName: string) => {
    await rpcClient.updateViewFolder({ folderId, name: folderName });
    void reloadOmsNavData();
  };

  const handleDeleteFolder = async (folderId: string) => {
    await rpcClient.deleteViewFolder({ folderId });
    void reloadOmsNavData();
  };

  const updateOrdering = async (
    updatedViews: FulfillmentGroupView[],
    updatedFolders: folderTypes["fulfillment-groups"][],
  ) => {
    // Optimistic update
    setViews(updatedViews);
    setFolders(updatedFolders);

    await rpcClient.saveViewsAndFolders({
      views: updatedViews,
      folders: updatedFolders,
    });
  };

  const saveOrdering = useHandler(async () => {
    try {
      await rpcClient.saveViewsAndFolders({ views, folders });
    } catch (error) {
      void reloadOmsNavData();
    }
  });

  useEffect(() => {
    if (omsNavData && omsNavData.teamViews && omsNavData.folders) {
      setViews(omsNavData.teamViews);
      setFolders(omsNavData.folders);
    }
  }, [omsNavData]);

  const openCreateFolderModal = useHandler(() => {
    setShowCreateFolderModal(true);
  });

  const openEditFolderModal = useHandler((folderId: string) => {
    const folder = omsNavData?.folders?.find(
      (folder) => folder._id === folderId,
    );
    if (folder) {
      setShowEditFolderModal(true);
      setSelectedFolder(folder);
    }
  });

  const reservedFolderNames = useMemo(() => {
    return new Set(folders.map((folder) => folder.name));
  }, [folders]);

  return (
    <OmsViewOrderingContext.Provider
      value={{
        openCreateFolderModal,
        openEditFolderModal,
        handleDeleteFolder,
        updateOrdering,
        saveOrdering,
        views,
        folders,
      }}
    >
      {children}
      <CreateOmsViewFolderModal
        isOpen={showCreateFolderModal}
        onClose={() => setShowCreateFolderModal(false)}
        onSubmit={handleCreateFolder}
        reservedFolderNames={reservedFolderNames}
      />
      {selectedFolder && (
        <EditOmsViewFolderModal
          deleteFolder={handleDeleteFolder}
          isOpen={showEditFolderModal}
          onClose={() => setShowEditFolderModal(false)}
          onSubmit={handleEditFolder}
          reservedFolderNames={reservedFolderNames}
          selectedFolder={selectedFolder}
        />
      )}
    </OmsViewOrderingContext.Provider>
  );
});
