import { useRequiredContext } from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { useHandler } from "@redotech/react-util/hook";
import { useTriggerLoad } from "@redotech/react-util/load";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import type { Return } from "@redotech/redo-model/return";
import { alertOnFailure } from "@redotech/redo-web/alert";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { Modal, ModalSize } from "@redotech/redo-web/modal";
import { InputLines, TextInput } from "@redotech/redo-web/text-input";
import { groupInput, input } from "@redotech/ui/form";
import { memo, useEffect, useState } from "react";
import { updateNewOrderAddress } from "../../client/return";
import * as returnCss from "../return.module.css";

export const ChangeNewOrderAddressModal = memo(
  function ChangeNewOrderAddressModal({
    return: return_,
    open,
    onClose,
    reload,
  }: {
    open: boolean;
    onClose(): void;
    return: Return;
    reload(): void;
  }) {
    const client = useRequiredContext(RedoMerchantClientContext);
    const previousAddress = return_.newOrderAddress
      ? return_.newOrderAddress
      : return_.shipping_address
        ? return_.shipping_address
        : return_.products[0].order.shopify.shipping_address;

    const address = useInput(
      groupInput({
        firstName: input<string>(),
        lastName: input<string>(),
        address1: input<string>(),
        address2: input<string>(),
        city: input<string>(),
        province_code: input<string>(),
        zip: input<string>(),
        country_code: input<string>(),
      }),
      {
        firstName: previousAddress.first_name,
        lastName: previousAddress.last_name,
        address1: previousAddress.address1,
        address2: previousAddress.address2,
        city: previousAddress.city,
        province_code: previousAddress.province_code,
        zip: previousAddress.zip,
        country_code: previousAddress.country_code,
      },
    );
    const [isValid, setIsValid] = useState<boolean>(false);
    useEffect(() => {
      if (
        address.inputs.firstName.value &&
        address.inputs.lastName.value &&
        address.inputs.address1.value &&
        address.inputs.city.value &&
        address.inputs.province_code.value &&
        address.inputs.zip.value &&
        address.inputs.country_code.value
      ) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }, [
      address.inputs.firstName,
      address.inputs.lastName,
      address.inputs.address1,
      address.inputs.city,
      address.inputs.province_code,
      address.inputs.zip,
      address.inputs.country_code,
    ]);

    const [resendLoad, doResend] = useTriggerLoad((signal) =>
      alertOnFailure("Changing new order address failed")(async () => {
        await updateNewOrderAddress(client, {
          returnId: return_.id,
          first_name: address.inputs.firstName.value,
          last_name: address.inputs.lastName.value,
          address1: address.inputs.address1.value,
          address2: address.inputs.address2.value,
          city: address.inputs.city.value,
          province_code: address.inputs.province_code.value,
          zip: address.inputs.zip.value,
          country_code: address.inputs.country_code.value,
          signal,
        });
        reload();
        return true;
      }),
    );
    const handleSend = useHandler(() => doResend());

    const footer = resendLoad.value ? (
      <Button
        className={returnCss.modalButton}
        onClick={onClose}
        theme={ButtonTheme.OUTLINED}
      >
        Ok
      </Button>
    ) : (
      <>
        <Button
          className={returnCss.modalButton}
          onClick={onClose}
          theme={ButtonTheme.OUTLINED}
        >
          Cancel
        </Button>
        <Button
          className={returnCss.modalButton}
          disabled={!isValid && !resendLoad.value}
          onClick={handleSend}
          pending={resendLoad.pending}
          theme={ButtonTheme.PRIMARY}
        >
          Save
        </Button>
      </>
    );
    return (
      <Modal
        footer={footer}
        onClose={onClose}
        open={open}
        size={ModalSize.SMALL}
        title="Change new order address"
      >
        <div className={returnCss.modalContent}>
          {resendLoad.value ? (
            <p>The new order address has been changed.</p>
          ) : (
            <>
              <LabeledInput label="First Name">
                <TextInput
                  lines={InputLines.SINGLE}
                  maxLength={50}
                  onChange={address.inputs.firstName.setValue}
                  placeholder="John"
                  value={address.inputs.firstName.value}
                />
              </LabeledInput>
              <LabeledInput label="Last Name">
                <TextInput
                  lines={InputLines.SINGLE}
                  maxLength={50}
                  onChange={address.inputs.lastName.setValue}
                  placeholder="Doe"
                  value={address.inputs.lastName.value}
                />
              </LabeledInput>
              <LabeledInput label="Address 1">
                <TextInput
                  lines={InputLines.SINGLE}
                  maxLength={50}
                  onChange={address.inputs.address1.setValue}
                  placeholder="1234 Main St"
                  value={address.inputs.address1.value}
                />
              </LabeledInput>
              <LabeledInput label="Address 2">
                <TextInput
                  lines={InputLines.SINGLE}
                  maxLength={50}
                  onChange={address.inputs.address2.setValue}
                  placeholder="Apartment, studio, or floor"
                  value={address.inputs.address2.value}
                />
              </LabeledInput>
              <LabeledInput label="City">
                <TextInput
                  lines={InputLines.SINGLE}
                  maxLength={50}
                  onChange={address.inputs.city.setValue}
                  placeholder="City"
                  value={address.inputs.city.value}
                />
              </LabeledInput>
              <LabeledInput label="Province/State Code">
                <TextInput
                  lines={InputLines.SINGLE}
                  maxLength={50}
                  onChange={address.inputs.province_code.setValue}
                  placeholder="UT, NY, etc."
                  value={address.inputs.province_code.value}
                />
              </LabeledInput>
              <LabeledInput label="Zip">
                <TextInput
                  lines={InputLines.SINGLE}
                  maxLength={50}
                  onChange={address.inputs.zip.setValue}
                  placeholder="Zip"
                  value={address.inputs.zip.value}
                />
              </LabeledInput>
              <LabeledInput label="Country Code">
                <TextInput
                  lines={InputLines.SINGLE}
                  maxLength={50}
                  onChange={address.inputs.country_code.setValue}
                  placeholder="US, GB, etc."
                  value={address.inputs.country_code.value}
                />
              </LabeledInput>
            </>
          )}
        </div>
      </Modal>
    );
  },
);
