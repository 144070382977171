import {
  createContext,
  Fragment,
  memo,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { useEventListener } from "./event";

/**
 * Available devices
 */
export const HidContext = createContext<readonly HIDDevice[]>([]);
/**
 * Adds to available devices and returns the new devices
 */
export const HidRequestContext = createContext<
  (filters: HIDDeviceFilter[]) => Promise<HIDDevice[]>
>((filters) => navigator.hid.requestDevice({ filters }));

export const HidProvider = memo(
  !navigator.hid
    ? Fragment
    : function HidProvider({
        children,
      }: {
        children: ReactNode | ReactNode[];
      }) {
        const [devices, setDevices] = useState<HIDDevice[]>([]);
        useEffect(() => {
          void (async () => {
            setDevices(await navigator.hid.getDevices());
          })();
        }, []);
        useEventListener(navigator.hid, "connect", async () => {
          setDevices(await navigator.hid.getDevices());
        });
        useEventListener(navigator.hid, "disconnect", async () => {
          setDevices(await navigator.hid.getDevices());
        });
        const requestDevice = async (filters: HIDDeviceFilter[]) => {
          const devices = await navigator.hid.requestDevice({ filters });
          setDevices(await navigator.hid.getDevices());
          return devices;
        };
        return (
          <HidContext.Provider value={devices}>
            <HidRequestContext.Provider value={requestDevice}>
              {children}
            </HidRequestContext.Provider>
          </HidContext.Provider>
        );
      },
);
