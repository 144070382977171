import { z } from "zod";
import { zExt } from "../common/zod-util";
import { segmentSchema } from "../marketing/segments/segment-zod-schema";

export enum CustomerGroupType {
  STATIC = "static",
  DYNAMIC = "dynamic",
}

export const baseCustomerGroupSchema = z.object({
  _id: zExt.objectId(),
  teamId: zExt.objectId(),
  name: z.string(),
  type: z.nativeEnum(CustomerGroupType),
  count: z.number().optional(),
  smsSubscriberCount: z.number().optional(),
  emailSubscriberCount: z.number().optional(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

/** A cache is dirty if its group's conditions have
 * changed since the last time the cache was updated.
 */
export enum CustomerGroupMembershipCacheStatus {
  CLEAN = "clean",
  DIRTY = "dirty",
  CLEANING = "cleaning",
}

export const dynamicGroupSchema = baseCustomerGroupSchema.extend({
  type: z.literal(CustomerGroupType.DYNAMIC),
  conditions: segmentSchema,
  countsRefreshedAt: z.date().optional(),
  membershipCacheStatus: z.nativeEnum(CustomerGroupMembershipCacheStatus),
  membershipCacheRefreshedAt: z.date().optional(),
});

export const staticGroupSchema = baseCustomerGroupSchema.extend({
  type: z.literal(CustomerGroupType.STATIC),
  isFullSubscriberList: z.boolean().optional(),
});

export const customerGroupSchema = z.discriminatedUnion("type", [
  staticGroupSchema,
  dynamicGroupSchema,
]);

export type DynamicCustomerGroup = z.infer<typeof dynamicGroupSchema>;
export type StaticCustomerGroup = z.infer<typeof staticGroupSchema>;

export type CustomerGroup = z.infer<typeof customerGroupSchema>;
