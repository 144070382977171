import { useRequiredContext } from "@redotech/react-util/context";
import { useLoad } from "@redotech/react-util/load";
import { RedoClient } from "@redotech/redo-api-client";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { REDO_MERCHANT_SERVER_URL } from "@redotech/redo-merchant-app-common/config";
import { listen } from "@redotech/redo-merchant-app-common/events/utils";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import {
  ExpandedConversation,
  ExpandedConversationMessage,
} from "@redotech/redo-model/conversation";
import { RedoClientContext } from "@redotech/redo-web/client";
import { Text } from "@redotech/redo-web/text";
import { formatDate } from "@redotech/redo-web/time";
import * as classNames from "classnames";
import { Fragment, memo, useEffect, useRef, useState } from "react";
import { getProfilePictures } from "../client/conversations";
import * as conversationMessagesCss from "./conversation-messages.module.css";
import { Message } from "./message";
import { PrivateReplyModal } from "./private-reply-modal";
import { VoiceTranscriptMessage } from "./voice-transcript-message";

const SCROLL_OFFSET = 36;

const getConversationStream = (
  client: RedoClient,
  { conversationId, signal }: { conversationId: string; signal: AbortSignal },
) => {
  const url = `${REDO_MERCHANT_SERVER_URL}/conversations/${conversationId}/live`;
  return fetch(url, { signal, headers: client.authorization() });
};

export const ConversationMessages = memo(function ConversationMessages({
  conversation,
  typing,
  setErrorMessage,
  setShowErrorMessage,
  setActiveConversation,
  showFullCommentThread,
}: {
  conversation: ExpandedConversation;
  typing: Record<string, Date>;
  setErrorMessage: (message: string) => void;
  setShowErrorMessage: (show: boolean) => void;
  setActiveConversation: (conversation: ExpandedConversation) => void;
  showFullCommentThread: boolean;
}) {
  const messagesCardRef = useRef<HTMLDivElement>(null);
  const lastMessageRef = useRef<HTMLDivElement>(null);

  const [retryCount, setRetryCount] = useState(0);
  const [privateReplyModalOpen, setPrivateReplyModalOpen] =
    useState<boolean>(false);
  const [privateReplyMessage, setPrivateReplyMessage] =
    useState<ExpandedConversationMessage>();
  const client = useRequiredContext(RedoClientContext);
  const merchantClient = useRequiredContext(RedoMerchantClientContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [transcriptionMessage, setTranscriptionMessage] =
    useState<ExpandedConversationMessage>();

  const handleDrawerState = (message: ExpandedConversationMessage) => {
    if (transcriptionMessage && transcriptionMessage._id === message._id) {
      setTranscriptionMessage(undefined);
      setDrawerOpen(false);
    } else {
      setTranscriptionMessage(message);
      setDrawerOpen(true);
    }
  };

  const profilePicturesLoad = useLoad(async (signal) => {
    const profilePictures = await getProfilePictures(merchantClient, {
      conversationId: conversation._id,
      signal,
    });

    return profilePictures;
  }, []);
  const messages: ExpandedConversationMessage[] = conversation.messages;

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      try {
        for await (const updatedConversation of listen({
          query: async () => {
            return await getConversationStream(client, {
              conversationId: conversation._id,
              signal: abortController.signal,
            });
          },
          loopCondition: !!conversation?._id,
          setErrorMessage,
          setShowErrorMessage,
        })) {
          setActiveConversation(
            updatedConversation as unknown as ExpandedConversation,
          );
        }
      } catch (e) {
        // Wait 5 seconds before trying again
        setTimeout(() => {
          // Continue polling for changes
          setRetryCount(retryCount + 1);
        }, 5000);
        if (abortController.signal.aborted) {
          return;
        }
        throw e;
      }
    })();
    return () =>
      abortController.abort("Conversation changed or component unmounted");
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation._id, retryCount]);

  let messagesToShow: ExpandedConversationMessage[] = messages || [];
  if (
    (!!conversation.instagramCommentThread ||
      !!conversation.facebookCommentThread) &&
    !showFullCommentThread
  ) {
    messagesToShow = messagesToShow.filter(
      (message) =>
        message.type === "merchant" ||
        message.type === "system" ||
        message.customer?._id === conversation.customer.customer,
    );
  }

  // Dont show drafts
  messagesToShow = messagesToShow.filter(
    (message) => !message.draftInfo?.isDraft,
  );

  // We don't want to have to care about the order of messages in the array as it's store in the backend. Just sort them by sentAt
  messagesToShow.sort((a, b) => {
    return new Date(a.sentAt).getTime() - new Date(b.sentAt).getTime();
  });

  const team = useRequiredContext(TeamContext);

  useEffect(() => {
    if (lastMessageRef.current && messagesCardRef.current) {
      lastMessageRef.current.scrollIntoView({
        behavior: "instant",
        block: "start",
      });

      setTimeout(() => {
        if (messagesCardRef.current) {
          messagesCardRef.current.scrollTop += SCROLL_OFFSET;
        }
      }, 100);
    }
  }, [messagesToShow.length]);

  return (
    <>
      <div className={conversationMessagesCss.messagesCard}>
        <div
          className={classNames(
            conversationMessagesCss.messagesContainerWrapper,
            {
              [conversationMessagesCss.withPadding]:
                team.settings?.support?.ai?.enabled,
            },
          )}
          ref={messagesCardRef}
        >
          <div className={conversationMessagesCss.messagesContainer}>
            {messagesToShow.map((message, index) => (
              <Fragment key={message._id}>
                {message.type === "call" ? (
                  <VoiceTranscriptMessage
                    conversation={conversation}
                    handleDrawerState={handleDrawerState}
                    message={message}
                    open={drawerOpen}
                    transcriptionMessage={transcriptionMessage}
                  />
                ) : (
                  <>
                    {index === 0 && (
                      <div className={conversationMessagesCss.headerDivider}>
                        <div className={conversationMessagesCss.divider} />
                        <div className={conversationMessagesCss.createdDate}>
                          <Text
                            fontSize="sm"
                            fontWeight="medium"
                            textColor="tertiary"
                          >
                            {formatDate(
                              Temporal.Instant.from(conversation.createdAt),
                            )}
                          </Text>
                        </div>
                        <div className={conversationMessagesCss.divider} />
                      </div>
                    )}
                    <Message
                      conversation={conversation}
                      index={index}
                      message={message}
                      profilePictures={profilePicturesLoad.value}
                      setActiveConversation={setActiveConversation}
                      setPrivateReplyMessage={setPrivateReplyMessage}
                      setPrivateReplyModalOpen={setPrivateReplyModalOpen}
                    />
                    {index === messagesToShow.length - 1 && (
                      <div id="lastElement" ref={lastMessageRef} />
                    )}
                  </>
                )}
              </Fragment>
            ))}
          </div>
        </div>
      </div>
      {privateReplyMessage && (
        <PrivateReplyModal
          conversation={conversation}
          message={privateReplyMessage}
          onClose={() => setPrivateReplyModalOpen(false)}
          open={privateReplyModalOpen}
          setActiveConversation={setActiveConversation}
        />
      )}
    </>
  );
});
