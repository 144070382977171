// extracted by mini-css-extract-plugin
export var actionButtonsContainer = "_d1-0";
export var footer = "_d1-2";
export var infoSection = "_d1-3";
export var infoSectionContainer = "_d1-4";
export var infoSectionTitleRow = "_d1-5";
export var itemDetails = "_d1-6";
export var line = "_d1-7";
export var lineItemImage = "_d1-1";
export var lineItems = "_d1-8";
export var modalContent = "_d1-9";
export var multiLineInfo = "_d1-a";
export var orderName = "_d1-b";
export var strong = "_d1-c";