import { PrintableDocument } from "@redotech/redo-model/fulfillments/fullfilment-print-status";
import { z } from "zod";

export const markAsPrinted = {
  input: z.object({
    document: z.nativeEnum(PrintableDocument),
    groups: z.union([
      z.object({ fulfillmentGroupIds: z.array(z.string()) }),
      z.object({ batchId: z.string() }),
    ]),
  }),
  output: z.object({}),
};
