import { useHandler } from "@redotech/react-util/hook";
import { memo, useEffect, useState } from "react";
import { Flex } from "../../../../flex";
import { Text } from "../../../../text";
import { RedoButton } from "../../../buttons/redo-button";
import { RedoDateInput } from "../../../input/redo-date-input";
import { DatePickerVariant } from "../date-picker-menu";
import { DateVariant, getDatesFromVariant } from "../utils/date-picker-utils";
import { CalendarGrid } from "./calendar-grid";
import { CalendarHeader } from "./calendar-header";
import * as calendarCss from "./calendar.module.css";
import { DateOptionsPanel } from "./date-options-panel";
import { DayNamesHeader } from "./day-names-header";

interface BaseCalendarViewProps {
  variant?: DatePickerVariant;
  minDate?: Temporal.PlainDate;
  maxDate?: Temporal.PlainDate;
  onCancel?: () => void;
  onApply?: () => void;
  allowNoDateOption?: boolean;
}

interface SingleDateCalendarViewProps extends BaseCalendarViewProps {
  variant: "single";
  selectedDate: Temporal.PlainDate | null;
  onDateSelect: (date: Temporal.PlainDate | null) => void;
  startDate?: undefined;
  endDate?: undefined;
  onRangeChange?: undefined;
}

interface RangeDateCalendarViewProps extends BaseCalendarViewProps {
  variant: "range";
  startDate: Temporal.PlainDate | null;
  endDate: Temporal.PlainDate | null;
  onRangeChange: (
    startDate: Temporal.PlainDate | null,
    endDate: Temporal.PlainDate | null,
  ) => void;
  selectedDate?: undefined;
  onDateSelect?: undefined;
}

export type CalendarViewProps =
  | SingleDateCalendarViewProps
  | RangeDateCalendarViewProps;

export const CalendarView = memo(function CalendarView(
  props: CalendarViewProps,
) {
  const [rangeStartMonth, setRangeStartMonth] =
    useState<Temporal.PlainYearMonth>(() => {
      const thisMonth = Temporal.Now.plainDateISO().toPlainYearMonth();
      if (props.variant === "range") {
        return props.startDate?.toPlainYearMonth() || thisMonth;
      } else if (props.selectedDate) {
        return props.selectedDate.toPlainYearMonth();
      }
      return thisMonth;
    });

  const [rangeEndMonth, setRangeEndMonth] = useState<Temporal.PlainYearMonth>(
    () => {
      const thisMonth = Temporal.Now.plainDateISO().toPlainYearMonth();
      if (props.variant === "range") {
        return (
          props.endDate?.toPlainYearMonth() || thisMonth.add({ months: 1 })
        );
      }
      return thisMonth;
    },
  );

  const getEndRangeMonthToSet = useHandler(
    ({
      startDate,
      endDate,
    }: {
      startDate: Temporal.PlainDate;
      endDate: Temporal.PlainDate;
    }) => {
      // If both dates are in the same month, set range end to one month ahead
      const startMonth = startDate.toPlainYearMonth();
      const endMonth = endDate.toPlainYearMonth();
      if (startMonth.equals(endMonth)) {
        return startMonth.add({ months: 1 });
      }

      return endDate.toPlainYearMonth();
    },
  );

  useEffect(() => {
    if (props.variant !== "range") {
      return;
    }

    if (props.startDate && props.endDate) {
      const monthToUse = getEndRangeMonthToSet({
        startDate: props.startDate,
        endDate: props.endDate,
      });
      setRangeEndMonth(monthToUse);
    }
  }, [props.startDate, props.endDate, props.variant, getEndRangeMonthToSet]);

  const bindDateToRange = useHandler((date: Temporal.PlainDate) => {
    if (props.minDate && Temporal.PlainDate.compare(date, props.minDate) < 0) {
      return props.minDate;
    }
    if (props.maxDate && Temporal.PlainDate.compare(date, props.maxDate) > 0) {
      return props.maxDate;
    }
    return date;
  });

  const handleSingleDateSelect = useHandler(
    (date: Temporal.PlainDate | null) => {
      if (props.variant === "single") {
        const boundDate = date ? bindDateToRange(date) : null;

        boundDate && setRangeStartMonth(boundDate.toPlainYearMonth());
        props.onDateSelect(boundDate);
      }
    },
  );

  const handleUpdateRange = useHandler(
    (
      startDate: Temporal.PlainDate | null | undefined,
      endDate: Temporal.PlainDate | null | undefined,
    ) => {
      if (props.variant !== "range") {
        return;
      }
      if (startDate && endDate) {
        const [earlierDate, laterDate] = [startDate, endDate].sort((a, b) =>
          Temporal.PlainDate.compare(a, b),
        ) as [Temporal.PlainDate, Temporal.PlainDate];

        const boundStartDate = bindDateToRange(earlierDate);
        const boundEndDate = bindDateToRange(laterDate);

        props.onRangeChange(boundStartDate, boundEndDate);

        startDate && setRangeStartMonth(boundStartDate.toPlainYearMonth());
        endDate &&
          getEndRangeMonthToSet({
            startDate: boundStartDate,
            endDate: boundEndDate,
          });
      } else {
        const date = startDate || endDate;
        const boundDate = date ? bindDateToRange(date) : null;
        boundDate && setRangeStartMonth(boundDate.toPlainYearMonth());
        props.onRangeChange(boundDate, null);
      }
    },
  );

  const handleDatePickerSelect = useHandler((date: Temporal.PlainDate) => {
    if (props.variant === "range") {
      if (props.startDate && props.endDate) {
        handleUpdateRange(date, null);
      } else if (!props.startDate) {
        handleUpdateRange(date, null);
      } else {
        handleUpdateRange(props.startDate, date);
      }
    } else {
      handleSingleDateSelect(date);
    }
  });

  const handleDateInputSelect = useHandler(
    (date: Temporal.PlainDate | null, position: "end" | "start") => {
      if (!date) {
        return;
      }
      if (props.variant === "range") {
        if (position === "start") {
          handleUpdateRange(date, props.endDate);
        } else {
          handleUpdateRange(props.startDate, date);
        }
      } else {
        handleSingleDateSelect(date);
      }
    },
  );

  const handleRangeVariantSelect = useHandler((variant: DateVariant) => {
    if (variant === "no-date") {
      handleUpdateRange(null, null);
      return;
    }
    const { startDate, endDate } = getDatesFromVariant(variant);

    if (props.variant === "range") {
      handleUpdateRange(startDate, endDate);
    } else {
      handleSingleDateSelect(startDate);
    }
  });

  const goToPreviousMonth = useHandler((range: "start" | "end") => {
    if (range === "start") {
      setRangeStartMonth((prevMonth) => prevMonth.subtract({ months: 1 }));
    } else {
      setRangeEndMonth((prevMonth) => prevMonth.subtract({ months: 1 }));
    }
  });

  const goToNextMonth = useHandler((range: "start" | "end") => {
    if (range === "start") {
      setRangeStartMonth((prevMonth) => prevMonth.add({ months: 1 }));
    } else {
      setRangeEndMonth((prevMonth) => prevMonth.add({ months: 1 }));
    }
  });

  const [hoveredDate, setHoveredDate] = useState<Temporal.PlainDate | null>(
    null,
  );

  const handleDateHovered = useHandler((date: Temporal.PlainDate | null) => {
    if (date) {
      setHoveredDate(date);
    } else {
      setHoveredDate((prev) => {
        if (prev) {
          return prev;
        }
        return null;
      });
    }
  });

  return (
    <Flex
      bgColor="primary"
      borderColor="secondary"
      borderStyle="solid"
      borderWidth="1px"
      radius="md"
      shadow="lg"
    >
      {props.variant === "range" && (
        <DateOptionsPanel
          allowNoDateOption={props.allowNoDateOption}
          onVariantSelect={handleRangeVariantSelect}
        />
      )}
      <Flex dir="column">
        <Flex className={calendarCss.calendarView} dir="row" gap="none">
          <MonthCalendarView
            goToNextMonth={() => goToNextMonth("start")}
            goToPreviousMonth={() => goToPreviousMonth("start")}
            hoveredDate={hoveredDate}
            maxDate={props.maxDate}
            minDate={props.minDate}
            month={rangeStartMonth}
            onDateSelect={handleDatePickerSelect}
            rangeEndDate={props.endDate}
            rangeStartDate={props.startDate}
            selectedDate={props.selectedDate}
            setHoveredDate={handleDateHovered}
            variant={props.variant}
          />
          {props.variant === "range" && (
            <MonthCalendarView
              goToNextMonth={() => goToNextMonth("end")}
              goToPreviousMonth={() => goToPreviousMonth("end")}
              hoveredDate={hoveredDate}
              maxDate={props.maxDate}
              minDate={props.minDate}
              month={rangeEndMonth}
              onDateSelect={handleDatePickerSelect}
              rangeEndDate={props.endDate}
              rangeStartDate={props.startDate}
              selectedDate={props.selectedDate}
              setHoveredDate={handleDateHovered}
              variant={props.variant}
            />
          )}
        </Flex>

        {props.variant === "range" && (
          <Flex
            align="center"
            borderColor="secondary"
            borderStyle="solid"
            borderTopWidth="1px"
            justify="space-between"
            p="xl"
          >
            <Flex align="center">
              <Flex style={{ width: "136px" }}>
                <RedoDateInput
                  setValue={(date) => handleDateInputSelect(date, "start")}
                  size="md"
                  value={props.startDate}
                />
              </Flex>
              <Text>-</Text>
              <Flex style={{ width: "136px" }}>
                <RedoDateInput
                  setValue={(date) => handleDateInputSelect(date, "end")}
                  size="md"
                  value={props.endDate}
                />
              </Flex>
            </Flex>
            <Flex>
              {props.onCancel && (
                <RedoButton
                  hierarchy="secondary"
                  onClick={props.onCancel}
                  size="md"
                  text="Cancel"
                />
              )}
              {props.onApply && (
                <Flex className={calendarCss.applyButton} ml="md">
                  <RedoButton
                    disabled={
                      props.allowNoDateOption
                        ? !props.startDate !== !props.endDate // Only one date is selected
                        : !props.startDate || !props.endDate
                    }
                    hierarchy="primary"
                    onClick={props.onApply}
                    size="md"
                    text="Apply"
                  />
                </Flex>
              )}
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
});

const MonthCalendarView = memo(function MonthCalendarView({
  month,
  goToNextMonth,
  goToPreviousMonth,
  variant,
  selectedDate,
  rangeStartDate,
  rangeEndDate,
  onDateSelect,
  hoveredDate,
  setHoveredDate,
  minDate,
  maxDate,
}: {
  month: Temporal.PlainYearMonth;
  goToNextMonth: () => void;
  goToPreviousMonth: () => void;
  variant: DatePickerVariant;
  selectedDate?: Temporal.PlainDate | null;
  rangeStartDate?: Temporal.PlainDate | null;
  rangeEndDate?: Temporal.PlainDate | null;
  onDateSelect: (date: Temporal.PlainDate) => void;
  hoveredDate: Temporal.PlainDate | null;
  setHoveredDate: (date: Temporal.PlainDate | null) => void;
  minDate?: Temporal.PlainDate;
  maxDate?: Temporal.PlainDate;
}) {
  return (
    <Flex dir="column" gap="md" p="xl">
      <CalendarHeader
        currentMonth={month}
        onNextMonth={goToNextMonth}
        onPrevMonth={goToPreviousMonth}
      />

      <Flex dir="column" gap="sm">
        <DayNamesHeader />

        <CalendarGrid
          currentMonth={month}
          hoveredDate={hoveredDate}
          isRangeMode={variant === "range"}
          isRangeSelecting={!!rangeStartDate && !rangeEndDate}
          maxDate={maxDate}
          minDate={minDate}
          onDateSelect={onDateSelect}
          rangeEndDate={rangeEndDate}
          rangeStartDate={rangeStartDate}
          selectedDate={selectedDate}
          setHoveredDate={setHoveredDate}
        />
      </Flex>
    </Flex>
  );
});
