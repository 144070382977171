import { Navigate, RouteObject } from "react-router-dom";

import { reviewsSettingsRoutes } from "@redotech/redo-merchant-app-review/setting/route";
import {
  aiSettingsRoutes,
  inAiKnowledgeView,
} from "../../../setting/ai-agent/route";
import { checkoutOptimizationRoutes } from "../../../setting/checkout-optimization/route";
import {
  faqRoutes,
  inFaqBuilder,
  inFaqHomeView,
} from "../../../setting/faq/route";
import { upsellPageSettingsRoutes } from "../../../setting/order-tracking-settings/route";

const fullscreen = (url: string) => {
  return url.includes("ad-blocks/") && !url.endsWith("ad-blocks/");
};

const inForge = (url: string) => {
  return (
    (url.includes("reviews-displays-widgets/") &&
      !url.endsWith("reviews-displays-widgets/")) ||
    (url.includes("upsell-page/") && !url.endsWith("upsell-page/")) ||
    inFaqBuilder(url) ||
    (url.includes("oms/automations") && !url.endsWith("oms/automations"))
  );
};

const inPhoneSetup = (url: string) => {
  return url.includes("phone-setup");
};

const inAutomationsList = (url: string) => {
  return url.endsWith("oms/automations");
};

function hidePadding(url: string) {
  return (
    inAiKnowledgeView(url) ||
    inFaqHomeView(url) ||
    inAutomationsList(url) ||
    fullscreen(url) ||
    inForge(url) ||
    inPhoneSetup(url)
  );
}

export const settingRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Settings", breadcrumbNavigate: false },
    lazy: async () => {
      const { Page } = await import("../../page");
      return {
        element: (
          <Page
            hideHeader={(url) =>
              fullscreen(url) || inForge(url) || inPhoneSetup(url)
            }
            hideNavbar={(url) =>
              fullscreen(url) || inForge(url) || inPhoneSetup(url)
            }
            hidePadding={hidePadding}
          />
        ),
      };
    },
    children: [
      aiSettingsRoutes,
      checkoutOptimizationRoutes,
      {
        handle: { breadcrumb: "Automations" },
        path: "automations",
        lazy: async () => {
          const { AutomationsPage } = await import(
            "../../../setting/automation"
          );
          return { Component: AutomationsPage };
        },
      },
      {
        handle: { breadcrumb: "Bundles" },
        path: "bundles",
        lazy: async () => {
          const { BundlesPage } = await import("../../../setting/bundles");
          return { Component: BundlesPage };
        },
      },
      {
        handle: { breadcrumb: "Billing" },
        path: "billing",
        lazy: async () => {
          const { BillingPage } = await import(
            "../../../setting/billing/billing"
          );
          return { Component: BillingPage };
        },
      },
      {
        handle: { breadcrumb: "Customer widget" },
        path: "customer-widget",
        lazy: async () => {
          const { CustomerWidgetSettingsPage } = await import(
            "../../../setting/customer-widget/customer-widget-settings"
          );
          return { Component: CustomerWidgetSettingsPage };
        },
      },
      faqRoutes,
      {
        handle: { breadcrumb: "Channels" },
        path: "channels",
        children: [
          {
            path: "",
            lazy: async () => {
              const { ChannelsPage } = await import(
                "../../../setting/channels"
              );
              return { Component: ChannelsPage };
            },
          },
          {
            handle: { breadcrumb: "Support Email" },
            path: "supportEmail",
            lazy: async () => {
              const { SupportEmailInfo } = await import(
                "../../../setting/support-email-info"
              );
              return { Component: SupportEmailInfo };
            },
          },
          {
            handle: { breadcrumb: "Meta" },
            path: "meta-connect",
            async lazy() {
              const { Connect } = await import("../../../support/meta/connect");
              return { element: <Connect /> };
            },
          },
          {
            handle: { breadcrumb: "Meta" },
            path: "meta-settings",
            async lazy() {
              const { MetaSettings } = await import(
                "../../../setting/channel/meta-settings"
              );
              return { element: <MetaSettings /> };
            },
          },
          {
            handle: { breadcrumb: "Voice" },
            path: "voice-settings",
            async lazy() {
              const { VoiceSettings } = await import(
                "../../../setting/channel/voice-settings"
              );
              return { element: <VoiceSettings /> };
            },
          },
          {
            handle: { breadcrumb: "Phone" },
            path: "phone-setup",
            async lazy() {
              const { PhoneSetup } = await import(
                "../../../support/phone/setup"
              );
              return { element: <PhoneSetup /> };
            },
          },
          {
            handle: { breadcrumb: "Email" },
            path: "email-connect",
            async lazy() {
              const { EmailSetup } = await import(
                "../../../setting/email-setup"
              );
              return { element: <EmailSetup /> };
            },
          },
          {
            path: "meta-callback",
            async lazy() {
              const { Callback } = await import(
                "../../../support/meta-callback"
              );
              return { element: <Callback /> };
            },
          },
          {
            handle: { breadcrumb: "Attentive" },
            path: "attentive-connect",
            async lazy() {
              const { Connect } = await import(
                "../../../support/attentive/connect"
              );
              return { element: <Connect /> };
            },
          },
          {
            handle: { breadcrumb: "Postscript" },
            path: "postscript-connect",
            async lazy() {
              const { Connect } = await import(
                "../../../support/postscript/connect"
              );
              return { element: <Connect /> };
            },
          },
          {
            path: "attentive-callback",
            async lazy() {
              const { Callback } = await import(
                "../../../support/attentive-callback"
              );
              return { element: <Callback /> };
            },
          },
          {
            path: "gmail-callback",
            async lazy() {
              const { Callback } = await import(
                "../../../support/gmail-callback"
              );
              return { element: <Callback /> };
            },
          },
          {
            path: "outlook-callback",
            async lazy() {
              const { Callback } = await import(
                "../../../support/outlook-callback"
              );
              return { element: <Callback /> };
            },
          },
        ],
      },
      {
        handle: { breadcrumb: "Communication" },
        path: "communication",
        lazy: async () => {
          const { CommunicationPage } = await import(
            "../../../setting/communication"
          );
          return { Component: CommunicationPage };
        },
      },
      {
        handle: { breadcrumb: "Coverage" },
        path: "coverage",
        lazy: async () => {
          const { CoveragePage: CoverageSettings } = await import(
            "../../../setting/coverage/coverage"
          );
          return { Component: CoverageSettings };
        },
      },
      {
        handle: { breadcrumb: "Developer" },
        path: "developer",
        lazy: async () => {
          const { DeveloperPage } = await import("../../../setting/developer");
          return { Component: DeveloperPage };
        },
      },
      {
        handle: { breadcrumb: "Exchanges" },
        path: "exchanges",
        lazy: async () => {
          const { ExchangesPage: Exchanges } = await import(
            "../../../setting/exchanges"
          );
          return { Component: Exchanges };
        },
      },
      {
        handle: { breadcrumb: "Invoices" },
        path: "invoices",
        lazy: async () => {
          const { Payouts } = await import("../../../billing/payouts");
          return { Component: Payouts };
        },
      },
      {
        handle: { breadcrumb: "Notifications" },
        path: "notifications",
        lazy: async () => {
          const { NotificationsPage } = await import(
            "../../../setting/notifications"
          );
          return { Component: NotificationsPage };
        },
      },

      reviewsSettingsRoutes,
      {
        handle: { breadcrumb: "Email Domain" },
        path: "email-domain",
        lazy: async () => {
          const { EmailDomainPage } = await import(
            "../../../setting/email-domain"
          );
          return { Component: EmailDomainPage };
        },
      },
      {
        handle: { breadcrumb: "SMS Marketing" },
        path: "sms-marketing",
        lazy: async () => {
          const { SmsSettingsPage } = await import(
            "../../../setting/marketing/sms-settings"
          );
          return { Component: SmsSettingsPage };
        },
      },
      {
        handle: { breadcrumb: "Integrations" },
        path: "marketing-integrations",
        lazy: async () => {
          const { IntegrationsPage } = await import(
            "../../../setting/marketing/marketing-integrations"
          );
          return { Component: IntegrationsPage };
        },
      },
      {
        handle: { breadcrumb: "Carriers" },
        path: "carriers",
        lazy: async () => {
          const { CarriersPage: Carriers } = await import(
            "@redotech/redo-merchant-app-fulfillment/setting/carrier-accounts/carriers"
          );
          return { Component: Carriers };
        },
      },
      {
        handle: { breadcrumb: "End shippers" },
        path: "end-shippers",
        lazy: async () => {
          const { EndShippersPage: EndShippers } = await import(
            "@redotech/redo-merchant-app-fulfillment/setting/end-shippers/end-shippers-page"
          );
          return { Component: EndShippers };
        },
      },
      {
        handle: { breadcrumb: "OMS", breadcrumbNavigate: false },
        path: "oms",
        children: [
          {
            handle: { breadcrumb: "Automations" },
            path: "automations",
            children: [
              {
                index: true,
                lazy: async () => {
                  const { AutomationsPage: Automations } = await import(
                    "@redotech/redo-merchant-app-fulfillment/setting/automations/automations"
                  );
                  return { Component: Automations };
                },
              },
              {
                handle: { breadcrumb: "Automation Builder" },
                path: ":automationId",

                async lazy() {
                  const { OmsAutomationBuilder } = await import(
                    "../../../setting/oms/automations/automation-builder"
                  );
                  return { Component: OmsAutomationBuilder };
                },
              },
            ],
          },
          {
            handle: { breadcrumb: "General" },
            path: "general",
            lazy: async () => {
              const { GeneralOmsSettingsPage } = await import(
                "@redotech/redo-merchant-app-fulfillment/setting/general"
              );
              return { Component: GeneralOmsSettingsPage };
            },
          },
          {
            handle: { breadcrumb: "International" },
            path: "international",
            lazy: async () => {
              const { LoadInternationalSettingsPage } = await import(
                "@redotech/redo-merchant-app-fulfillment/setting/international"
              );
              return { Component: LoadInternationalSettingsPage };
            },
          },
          {
            handle: { breadcrumb: "Auto-merge shipments" },
            path: "automerge-shipments",
            lazy: async () => {
              const { AutoMergeSettingsPage } = await import(
                "@redotech/redo-merchant-app-fulfillment/setting/auto-merge-shipments"
              );
              return { Component: AutoMergeSettingsPage };
            },
          },
          {
            handle: { breadcrumb: "Packages" },
            path: "packages",
            lazy: async () => {
              const { ParcelsPage: Parcels } = await import(
                "@redotech/redo-merchant-app-fulfillment/setting/parcels"
              );
              return { Component: Parcels };
            },
          },
          {
            handle: { breadcrumb: "Balance" },
            path: "balance",
            lazy: async () => {
              const { OutboundLabelBalancePage } = await import(
                "../../../setting/outbound-label-balance"
              );
              return { Component: OutboundLabelBalancePage };
            },
          },
          {
            handle: { breadcrumb: "Presets" },
            path: "presets",
            lazy: async () => {
              const { PresetsPage } = await import(
                "@redotech/redo-merchant-app-fulfillment/setting/presets/presets"
              );
              return { Component: PresetsPage };
            },
          },
          // {
          //   handle: { breadcrumb: "Scan" },
          //   path: "scan",
          //   lazy: async () => {
          //     const { ScanPage } = await import(
          //       "@redotech/redo-merchant-app-fulfillment/setting/scan"
          //     );
          //     return { Component: ScanPage };
          //   },
          // },
        ],
      },
      { path: "parcels", element: <Navigate replace to="../oms/parcels" /> },
      { path: "balance", element: <Navigate replace to="../oms/balance" /> },
      {
        handle: { breadcrumb: "Shipping" },
        path: "shipping",
        lazy: async () => {
          const { ShippingPage } = await import("../../../setting/shipping");
          return { Component: ShippingPage };
        },
      },
      {
        handle: { breadcrumb: "Integrations" },
        path: "integrations",
        children: [
          {
            path: "",
            lazy: async () => {
              const { IntegrationsPage } = await import(
                "../../../setting/integrations"
              );
              return { Component: IntegrationsPage };
            },
          },
          {
            handle: { breadcrumb: "Comment Sold" },
            path: "comment-sold",
            lazy: async () => {
              const { CommentSoldPage } = await import(
                "../../../setting/comment-sold"
              );
              return { Component: CommentSoldPage };
            },
          },
          {
            handle: { breadcrumb: "ShipBob" },
            path: "shipbob",
            lazy: async () => {
              const { Connect } = await import(
                "../../../setting/channel/shipbob-connect"
              );
              return { Component: Connect };
            },
          },
        ],
      },
      // We moved /locations -> /shipping, so we'll redirect users just in case.
      { path: "locations", element: <Navigate replace to="../shipping" /> },
      {
        handle: { breadcrumb: "Return Flow" },
        path: "return-flow",
        children: [
          {
            path: "",
            lazy: async () => {
              const { ReturnFlow } = await import(
                "../../../setting/return-flow/return-flow"
              );
              return { element: <ReturnFlow /> };
            },
          },
          {
            handle: { breadcrumb: "AB Test Analytics" },
            path: "ab-test-analytics",
            lazy: async () => {
              const { ReturnFlowABTestAnalytics } = await import(
                "../../../setting/return-flow/ab-test-analytics"
              );
              return { element: <ReturnFlowABTestAnalytics /> };
            },
          },
        ],
      },
      {
        handle: { breadcrumb: "Claim Flow" },
        path: "claim-flow",
        lazy: async () => {
          const { ClaimFlow } = await import("../../../setting/claim-flow");
          return { element: <ClaimFlow /> };
        },
      },
      {
        handle: { breadcrumb: "Warranty Flow" },
        path: "warranty-flow",
        lazy: async () => {
          const { WarrantyFlow } = await import(
            "../../../setting/warranty-flow"
          );
          return { element: <WarrantyFlow /> };
        },
      },
      {
        handle: { breadcrumb: "Registration Rewards" },
        path: "registration-rewards",
        lazy: async () => {
          const { RegistrationRewards } = await import(
            "../../../setting/registration-rewards"
          );
          return { element: <RegistrationRewards /> };
        },
      },
      {
        handle: { breadcrumb: "Chat Logic Flow" },
        path: "chat-flow",
        lazy: async () => {
          const { ChatFlow } = await import("../../../setting/chat-flow");
          return { element: <ChatFlow /> };
        },
      },
      {
        handle: { breadcrumb: "CSAT" },
        path: "customer-satisfaction",
        lazy: async () => {
          const { CustomerSatisfaction } = await import(
            "../../../setting/customer-satisfaction"
          );
          return { Component: CustomerSatisfaction };
        },
      },
      {
        handle: { breadcrumb: "General" },
        path: "general",
        children: [
          {
            index: true,
            lazy: async () => {
              const { SupportGeneral } = await import(
                "../../../setting/support-general"
              );
              return { element: <SupportGeneral /> };
            },
          },
          {
            handle: { breadcrumb: "Contact Form" },
            path: "contact-form",
            lazy: async () => {
              const { ContactFormSettings } = await import(
                "../../../setting/support-general/contact-form-settings"
              );
              return { Component: ContactFormSettings };
            },
          },
        ],
      },
      {
        handle: { breadcrumb: "Upsells" },
        path: "upsells",
        lazy: async () => {
          const { UpsellSettings } = await import(
            "../../../setting/order-tracking-settings/upsells/upsell-settings"
          );
          return { element: <UpsellSettings /> };
        },
      },
      upsellPageSettingsRoutes,
      {
        handle: { breadcrumb: "Customer Portal" },
        path: "customer-portal",
        lazy: async () => {
          const { CustomerPortalPage: CustomerPortal } = await import(
            "../../../setting/customer-portal"
          );
          return { Component: CustomerPortal };
        },
      },
      // We moved /return-portal -> /customer-portal, so we'll redirect users just in case.
      {
        path: "return-portal",
        element: <Navigate replace to="../customer-portal" />,
      },
      {
        handle: { breadcrumb: "Returns UI" },
        path: "returns-ui",
        lazy: async () => {
          const { ReturnsUiPage } = await import(
            "../../../setting/returns-ui/returns-ui"
          );
          return { element: <ReturnsUiPage /> };
        },
      },
      {
        handle: { breadcrumb: "Info Modal" },
        path: "info-modal",
        lazy: async () => {
          const { InfoModalSettingsPage } = await import(
            "../../../setting/info-modal/info-modal-settings"
          );
          return { element: <InfoModalSettingsPage /> };
        },
      },
      {
        handle: { breadcrumb: "Rules" },
        path: "rules",
        children: [
          {
            path: "",
            lazy: async () => {
              const { RuleList } = await import("../../../setting/rules/list");
              return { element: <RuleList /> };
            },
          },
          {
            handle: { breadcrumb: "New rule" },
            path: "new",
            lazy: async () => {
              const { RuleFlow } = await import(
                "../../../setting/rules/rule-flow"
              );
              return { element: <RuleFlow isNew /> };
            },
          },
          {
            handle: { breadcrumb: "Edit rule" },
            path: "edit/:ruleId",
            lazy: async () => {
              const { RuleFlow } = await import(
                "../../../setting/rules/rule-flow"
              );
              return { element: <RuleFlow isNew={false} /> };
            },
          },
        ],
      },
      {
        handle: { breadcrumb: "Users" },
        path: "users",
        children: [
          {
            path: "",
            lazy: async () => {
              const { UsersPage } = await import("../../../setting/user/user");
              return { Component: UsersPage };
            },
          },
          {
            handle: { breadcrumb: "Profile settings" },
            path: ":userId",
            lazy: async () => {
              const { ProfilePage } = await import(
                "../../../setting/user/profile-page"
              );
              return { Component: ProfilePage };
            },
          },
        ],
      },
      {
        handle: { breadcrumb: "Theme" },
        path: "theme",
        lazy: async () => {
          const { ThemePage } = await import("../../../setting/theme");
          return { Component: ThemePage };
        },
      },
      {
        handle: { breadcrumb: "Brand kit" },
        path: "brand-kit",
        lazy: async () => {
          const { BrandKitPage } = await import("../../../setting/brand-kit");
          return { Component: BrandKitPage };
        },
      },
      {
        handle: { breadcrumb: "Integrations" },
        path: "customer-accounts-integrations",
        lazy: async () => {
          const { IntegrationsPage } = await import(
            "../../../setting/customer-accounts/integrations/integrations"
          );
          return { Component: IntegrationsPage };
        },
      },
      {
        handle: { breadcrumb: "Ad blocks" },
        path: "customer-accounts-ad-blocks",
        children: [
          {
            index: true,
            lazy: async () => {
              const { AdBlocksPage } = await import(
                "../../../setting/customer-accounts/ad-blocks/ad-blocks-drawer"
              );
              return { Component: AdBlocksPage };
            },
          },
          {
            path: ":adBlockId",
            handle: { breadcrumb: "Edit Ad Block" },
            lazy: async () => {
              const { AdBlockBuilder } = await import(
                "../../../setting/customer-accounts/ad-blocks/ad-blocks-builder"
              );
              return { element: <AdBlockBuilder /> };
            },
          },
        ],
      },
      {
        handle: { breadcrumb: "Storefront display" },
        path: "customer-accounts-storefront-display",
        lazy: async () => {
          const { StorefrontDisplaysPage } = await import(
            "../../../setting/customer-accounts/storefront-displays"
          );
          return { Component: StorefrontDisplaysPage };
        },
      },
    ],
  },
];
