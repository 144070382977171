import { BooleanFilterOperator } from "@redotech/redo-model/views/advanced-filters/boolean-filter";
import { memo, useEffect, useMemo, useState } from "react";
import {
  RedoFilterDropdownAnchor,
  RedoFilterGroup,
} from "../../arbiter-components/filter-group/redo-filter-group";
import { RedoListItem } from "../../arbiter-components/list/redo-list";
import { RedoListDropdown } from "../../arbiter-components/list/redo-list-dropdown";
import { SimpleFilterDropdown } from "../../filters/simple-filter-dropdown";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { BooleanTableFilter } from "../advanced-filter";

export const BooleanFilterGroup = memo(function BooleanFilterGroup({
  filter,
  setFilter,
  removeFilter,
  openOnRender,
}: {
  filter: BooleanTableFilter;
  setFilter(filter: BooleanTableFilter): void;
  removeFilter(): void;
  openOnRender: boolean;
}) {
  const [valueRef, setValueRef] = useState<HTMLButtonElement | null>(null);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const { value, operator } = filter.data;
  const boolDisplay = filter.boolDisplay;

  const booleanOptions: RedoListItem<boolean>[] = useMemo(() => {
    if (boolDisplay) {
      return [
        {
          id: "true",
          type: "text",
          value: true,
          text: boolDisplay.trueDisplay,
        },
        {
          id: "false",
          type: "text",
          value: false,
          text: boolDisplay.falseDisplay,
        },
      ];
    } else {
      return [
        { id: "true", type: "text", value: true, text: "true" },
        { id: "false", type: "text", value: false, text: "false" },
      ];
    }
  }, [boolDisplay]);

  function getDisplayString(value: boolean | undefined): string {
    if (!boolDisplay) {
      return value ? "true" : "false";
    }
    if (value === true) {
      return boolDisplay.trueDisplay;
    } else if (value === false) {
      return boolDisplay.falseDisplay;
    }
    return "...";
  }

  useEffect(() => {
    if (openOnRender && valueRef) {
      valueRef.click();
    }
  }, [openOnRender, valueRef]);

  const valueText = value == null ? "..." : getDisplayString(value);

  const valueDropdownAnchor = (
    <RedoFilterDropdownAnchor
      color="primary"
      onClick={() => setDropdownOpen(!dropdownOpen)}
      ref={setValueRef}
      text={valueText}
      tooltip={
        value != null ? (
          <Flex dir="column">
            <Text>{valueText}</Text>
          </Flex>
        ) : undefined
      }
      weight="medium"
    />
  );

  const valueDropdown = (
    <RedoListDropdown
      dropdownAnchor={valueRef}
      dropdownOpen={dropdownOpen}
      items={booleanOptions}
      itemSelected={({ item }) => {
        setFilter({ ...filter, data: { ...filter.data, value: item.value } });
      }}
      selectedItems={booleanOptions.find((o) => o.value === value)?.id}
      setDropdownOpen={setDropdownOpen}
      size="xs"
    />
  );

  return (
    <>
      {valueDropdown}
      <RedoFilterGroup
        Icon={filter.Icon}
        propertyName={filter?.label ? filter.label : filter.displayName}
        query={
          <SimpleFilterDropdown
            filterStyle="query"
            options={filter.operators || Object.values(BooleanFilterOperator)}
            optionToFriendlyName={(value) => booleanTypeToText[value]}
            setValue={(value) => {
              setFilter({
                ...filter,
                data: { ...filter.data, operator: value },
              });
            }}
            value={operator}
          />
        }
        removeFilter={removeFilter}
        value={valueDropdownAnchor}
      />
    </>
  );
});

const booleanTypeToText: Record<BooleanFilterOperator, string> = {
  [BooleanFilterOperator.IS]: "is",
  [BooleanFilterOperator.IS_NOT]: "is not",
};
