import { ForwardedRef, forwardRef, JSXElementConstructor, memo } from "react";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { BaseRedoInputContainer } from "./base-redo-input-container";
import { BaseRedoInput, RedoInputSize } from "./base-redo-text-input";
import * as redoLinkInputCss from "./redo-link-input.module.css";
export interface RedoLinkInputProps {
  value: string;
  onClick: () => void;
  size?: RedoInputSize;
  label?: string;
  icon: JSXElementConstructor<any>;
  placeholder?: string;
}

export const RedoLinkInput = memo(
  forwardRef(function RedoLinkInput(
    {
      value,
      size = "sm",
      label,
      onClick,
      icon: Icon,
      placeholder,
    }: RedoLinkInputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) {
    return (
      <BaseRedoInputContainer
        className={redoLinkInputCss.container}
        label={label}
        size={size}
      >
        <BaseRedoInput
          onClick={onClick}
          placeholder={placeholder}
          ref={ref}
          setValue={() => {}}
          size={size}
          state="link"
          value={value}
        />
        <Text textColor="quinary">
          <Flex
            align="center"
            className={redoLinkInputCss.icon}
            justify="center"
          >
            <Icon />
          </Flex>
        </Text>
      </BaseRedoInputContainer>
    );
  }),
);
