import {
  NumberType,
  PhoneType,
} from "@redotech/redo-model/support/phone/phone-types";
import { object, string, z } from "zod";

export const setupPhoneNumber = {
  input: object({
    phoneNumber: string(),
    phoneType: z.nativeEnum(PhoneType),
    numberType: z.nativeEnum(NumberType),
  }),
  output: object({}),
};

export type SetupPhoneNumberInput = z.infer<typeof setupPhoneNumber.input>;
export type SetupPhoneNumberOutput = z.infer<typeof setupPhoneNumber.output>;
