import { RedoMerchantClient } from "@redotech/redo-merchant-app-common/client";
import {
  CancelShopifySubscriptionBody,
  ShopifySubscriptionType,
} from "@redotech/redo-model/shopify-billing/shopify-subscription-types";

export const cancelShopifySubscription = async (
  client: RedoMerchantClient,
  {
    subscriptionType,
    signal,
  }: { subscriptionType: ShopifySubscriptionType; signal?: AbortSignal },
) => {
  const body: CancelShopifySubscriptionBody = { subscriptionType };

  const response = await client.client.post(`shopify/billing/cancel`, body, {
    headers: client.authorization(),
    signal,
  });
  return response.data;
};

export const createShopifySubscription = async (
  client: RedoMerchantClient,
  { signal }: { signal?: AbortSignal },
) => {
  const response = await client.client.post(
    `shopify/subscription/create`,
    {},
    { headers: client.authorization(), signal },
  );
  return response.data;
};

export const confirmShopifySubscription = async (
  client: RedoMerchantClient,
  {
    chargeId,
    productsEnabling,
    signal,
  }: {
    chargeId: string;
    productsEnabling: ShopifySubscriptionType[];
    signal?: AbortSignal;
  },
) => {
  const response = await client.client.post(
    `shopify/subscription/confirm`,
    { chargeId, productsEnabling },
    { headers: client.authorization(), signal },
  );
  return response.data?.appSubscriptions as any[];
};
