import { z } from "zod";
import { makeReportDateFilterSchema } from "./base-report-date-filter";
import { ReportFilterType } from "./report-filter-type";

export const ReturnDeliveryDateFilterSchema = makeReportDateFilterSchema(
  ReportFilterType.RETURN_DELIVERY_DATE,
);

export type ReturnDeliveryDateFilter = z.infer<
  typeof ReturnDeliveryDateFilterSchema
>;
