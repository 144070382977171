import { PrinterOptionsSchema } from "@redotech/redo-model/printnode/printer";
import { z } from "zod";

export const printLabels = {
  input: z.intersection(
    z.object({
      templateId: z.string().optional(),
      printerId: z.number().optional(),
      printerOptions: PrinterOptionsSchema.optional(),
    }),
    z.union([
      z.object({ fulfillmentGroupIds: z.array(z.string()) }),
      z.object({ batchId: z.string() }),
    ]),
  ),
  output: z.object({ url: z.string(), successIds: z.array(z.string()) }),
};

export type PrintLabelsInput = z.infer<typeof printLabels.input>;
