export enum PhoneType {
  SMS = "sms",
  VOICE = "voice",
  SMS_AND_VOICE = "sms-voice",
}

export enum NumberType {
  LOCAL = "local",
  TOLL_FREE = "toll_free",
}
