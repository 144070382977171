import Drawer from "@mui/material/Drawer";
import {
  CustomerActivityCategory,
  CustomerActivityFilterOptions,
} from "@redotech/redo-model/customer-activity/customer-activity-filters";
import XCloseIcon from "@redotech/redo-web/arbiter-icon/x-close.svg";
import { IconButton } from "@redotech/redo-web/button";
import { Checkbox } from "@redotech/redo-web/checkbox";
import { DateRangeInput, MaybeDate } from "@redotech/redo-web/date-range";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo, useState } from "react";
import * as customerActivityCss from "../../customers/customer-detail/customer-activity/customer-activity.module.css";
import {
  CATEGORY_LABELS,
  customerActivityCategoriesToEnabledMap,
  CustomerActivityCategoryEnabled,
  DEFAULT_CUSTOMER_ACTIVITY_CATEGORIES_ENABLED,
  updateCustomerActivityCategoriesEnabled,
} from "./activity-panel-utils";

const ActivityFilterDrawerHeader = memo(function ActivityFilterDrawerHeader({
  onClose,
}: {
  onClose: () => void;
}) {
  return (
    <Flex dir="column" gap="none">
      <Flex align="flex-start" dir="column" gap="xs" p="3xl">
        <Text fontSize="xl" fontWeight="semibold">
          Filters
        </Text>
        <Text fontSize="sm" textColor="tertiary">
          Apply filters to this activity feed.
        </Text>
      </Flex>
      <IconButton
        className={customerActivityCss.drawerCloseButtonContainer}
        onClick={onClose}
      >
        <XCloseIcon />
      </IconButton>
    </Flex>
  );
});

const ActivityFilterDrawerTypeFilterCheckRow = memo(
  function ActivityFilterDrawerTypeFilterCheckRow({
    label,
    customerActivityCategory,
    customerActivityCategoriesEnabled,
    setCustomerActivityCategoriesEnabled,
  }: {
    label: string;
    customerActivityCategory: CustomerActivityCategory;
    customerActivityCategoriesEnabled: CustomerActivityCategoryEnabled;
    setCustomerActivityCategoriesEnabled: (
      value: CustomerActivityCategoryEnabled,
    ) => void;
  }) {
    return (
      <Flex pl="md">
        <Checkbox
          onChange={(value) =>
            setCustomerActivityCategoriesEnabled(
              updateCustomerActivityCategoriesEnabled(
                customerActivityCategoriesEnabled,
                customerActivityCategory,
                value,
              ),
            )
          }
          value={customerActivityCategoriesEnabled[customerActivityCategory]}
        >
          {label}
        </Checkbox>
      </Flex>
    );
  },
);

const ActivityFilterDrawerTypeFilter = memo(
  function ActivityFilterDrawerTypeFilter({
    customerActivityCategoriesEnabled,
    setCustomerActivityCategoriesEnabled,
  }: {
    customerActivityCategoriesEnabled: CustomerActivityCategoryEnabled;
    setCustomerActivityCategoriesEnabled: (
      value: CustomerActivityCategoryEnabled,
    ) => void;
  }) {
    const categories = [
      CustomerActivityCategory.CLAIMS,
      CustomerActivityCategory.ORDERS,
      CustomerActivityCategory.RETURNS,
      CustomerActivityCategory.SHOPPING,
      CustomerActivityCategory.SUPPORT,
    ];

    return (
      <Flex dir="column" gap="none">
        <Text fontSize="sm" pb="lg" textColor="secondary">
          Activity type
        </Text>
        <Flex dir="column" gap="md">
          {categories.map((category) => (
            <ActivityFilterDrawerTypeFilterCheckRow
              customerActivityCategoriesEnabled={
                customerActivityCategoriesEnabled
              }
              customerActivityCategory={category}
              key={category}
              label={CATEGORY_LABELS[category]}
              setCustomerActivityCategoriesEnabled={
                setCustomerActivityCategoriesEnabled
              }
            />
          ))}
        </Flex>
      </Flex>
    );
  },
);

const ActivitySourceFilter = memo(function ActivitySourceFilter({
  showCustomerEvents,
  setShowCustomerEvents,
  showConversationEvents,
  setShowConversationEvents,
}: {
  showCustomerEvents: boolean;
  setShowCustomerEvents: (show: boolean) => void;
  showConversationEvents: boolean;
  setShowConversationEvents: (show: boolean) => void;
}) {
  return (
    <Flex dir="column" gap="none">
      <Text fontSize="sm" pb="lg" textColor="secondary">
        Activity source
      </Text>
      <Flex dir="column" gap="md">
        <Flex pl="md">
          <Checkbox
            onChange={setShowConversationEvents}
            value={showConversationEvents}
          >
            Conversation events
          </Checkbox>
        </Flex>
        <Flex pl="md">
          <Checkbox onChange={setShowCustomerEvents} value={showCustomerEvents}>
            Customer events
          </Checkbox>
        </Flex>
      </Flex>
    </Flex>
  );
});

const TODAY = new Date();
const ActivityFilterDrawerTimestampFilter = memo(
  function ActivityFilterDrawerTimestampFilter({
    timestampStart,
    setTimestampStart,
    timestampEnd,
    setTimestampEnd,
  }: {
    timestampStart: MaybeDate;
    setTimestampStart: (value: MaybeDate) => void;
    timestampEnd: MaybeDate;
    setTimestampEnd: (value: MaybeDate) => void;
  }) {
    const multiValueChange = (
      onStartChange: (value: MaybeDate) => void,
      onEndChange: (value: MaybeDate) => void,
    ) => {
      // Cannot use useHandler inside a function that is not a React component
      function handler(v: [MaybeDate, MaybeDate] | null): void {
        if (v && Array.isArray(v)) {
          const [newStart, newEnd] = v;

          onStartChange(newStart);
          onEndChange(newEnd);
        } else {
          onStartChange(null);
          onEndChange(null);
        }
      }
      return handler;
    };

    return (
      <Flex dir="column" gap="none">
        <Text fontSize="sm" pb="lg" textColor="secondary">
          Date range
        </Text>
        <DateRangeInput
          maxDate={TODAY}
          value={[timestampStart ?? null, timestampEnd ?? null]}
          valueChange={multiValueChange(setTimestampStart, setTimestampEnd)}
        />
      </Flex>
    );
  },
);

const ActivityFilterDrawerContent = memo(function ActivityFilterDrawerContent({
  customerActivityCategoriesEnabled,
  setCustomerActivityCategoriesEnabled,
  timestampStart,
  setTimestampStart,
  timestampEnd,
  setTimestampEnd,
  showCustomerEvents,
  setShowCustomerEvents,
  showConversationEvents,
  setShowConversationEvents,
}: {
  customerActivityCategoriesEnabled: CustomerActivityCategoryEnabled;
  setCustomerActivityCategoriesEnabled: (
    value: CustomerActivityCategoryEnabled,
  ) => void;
  timestampStart: MaybeDate;
  setTimestampStart: (value: MaybeDate) => void;
  timestampEnd: MaybeDate;
  setTimestampEnd: (value: MaybeDate) => void;
  showCustomerEvents: boolean;
  setShowCustomerEvents: (show: boolean) => void;
  showConversationEvents: boolean;
  setShowConversationEvents: (show: boolean) => void;
}) {
  return (
    <Flex dir="column" gap="none" pl="3xl" pr="3xl">
      <Flex dir="column" gap="3xl">
        <ActivitySourceFilter
          setShowConversationEvents={setShowConversationEvents}
          setShowCustomerEvents={setShowCustomerEvents}
          showConversationEvents={showConversationEvents}
          showCustomerEvents={showCustomerEvents}
        />
        {showCustomerEvents && (
          <>
            <ActivityFilterDrawerTypeFilter
              customerActivityCategoriesEnabled={
                customerActivityCategoriesEnabled
              }
              setCustomerActivityCategoriesEnabled={
                setCustomerActivityCategoriesEnabled
              }
            />
            <ActivityFilterDrawerTimestampFilter
              setTimestampEnd={setTimestampEnd}
              setTimestampStart={setTimestampStart}
              timestampEnd={timestampEnd}
              timestampStart={timestampStart}
            />
          </>
        )}
      </Flex>
    </Flex>
  );
});

export const ActivityFilterDrawer = memo(function ActivityFilterDrawer({
  open,
  onClose,
  initialFilterOptions,
  initialShowCustomerEvents,
  initialShowConversationEvents,
}: {
  open: boolean;
  onClose: (
    customerActivityFilterOptions: CustomerActivityFilterOptions,
    showCustomerEvents: boolean,
    showConversationEvents: boolean,
  ) => void;
  initialFilterOptions: CustomerActivityFilterOptions | undefined;
  initialShowCustomerEvents: boolean;
  initialShowConversationEvents: boolean;
}) {
  const [
    customerActivityCategoriesEnabled,
    setCustomerActivityCategoriesEnabled,
  ] = useState<CustomerActivityCategoryEnabled>(
    customerActivityCategoriesToEnabledMap(
      initialFilterOptions?.activityCategories,
    ),
  );
  const [timestampStart, setTimestampStart] = useState<MaybeDate>(
    initialFilterOptions?.timestampStart ?? null,
  );
  const [timestampEnd, setTimestampEnd] = useState<MaybeDate>(
    initialFilterOptions?.timestampEnd ?? null,
  );
  const [showCustomerEvents, setShowCustomerEvents] = useState(
    initialShowCustomerEvents,
  );
  const [showConversationEvents, setShowConversationEvents] = useState(
    initialShowConversationEvents,
  );

  const getCustomerActivityFilterOptions =
    (): CustomerActivityFilterOptions => {
      const activityCategories = Object.entries(
        customerActivityCategoriesEnabled,
      ).reduce<CustomerActivityCategory[]>((acc, [key, value]) => {
        if (value) {
          acc.push(key as CustomerActivityCategory);
        }
        return acc;
      }, []);

      return {
        activityCategories:
          activityCategories.length > 0 ? activityCategories : undefined,
        timestampStart: timestampStart ?? undefined,
        timestampEnd: timestampEnd ?? undefined,
      };
    };

  const handleClose = () => {
    const customerEventFilterOptions = getCustomerActivityFilterOptions();
    if (customerEventFilterOptions.activityCategories === undefined) {
      // reset checkboxes to all be selected if none are selected when closing the drawer
      setCustomerActivityCategoriesEnabled(
        DEFAULT_CUSTOMER_ACTIVITY_CATEGORIES_ENABLED,
      );
    }
    onClose(
      customerEventFilterOptions,
      showCustomerEvents,
      showConversationEvents,
    );
  };

  return (
    <Drawer
      anchor="right"
      onClose={handleClose}
      open={open}
      PaperProps={{
        className: customerActivityCss.customerActivityFilterDrawer,
      }}
    >
      <ActivityFilterDrawerHeader onClose={handleClose} />
      <ActivityFilterDrawerContent
        customerActivityCategoriesEnabled={customerActivityCategoriesEnabled}
        setCustomerActivityCategoriesEnabled={
          setCustomerActivityCategoriesEnabled
        }
        setShowConversationEvents={setShowConversationEvents}
        setShowCustomerEvents={setShowCustomerEvents}
        setTimestampEnd={setTimestampEnd}
        setTimestampStart={setTimestampStart}
        showConversationEvents={showConversationEvents}
        showCustomerEvents={showCustomerEvents}
        timestampEnd={timestampEnd}
        timestampStart={timestampStart}
      />
    </Drawer>
  );
});
