import { assertNever } from "@redotech/util/type";
import { z } from "zod";
import { zExt } from "../common/zod-util";
import { Provider } from "../order";
import { RateSchema } from "../outbound-labels/outbound-labels";
import { ParcelSchema } from "../outbound-labels/parcel";
import { Carriers } from "./fulfillment-carriers-and-services";
import { RedoShopifyFulfillmentDisplayStatus } from "./fulfillment-display-status";
import { FulfillmentGroup } from "./fulfillment-group";
import {
  FulfillmentLineItemSchema,
  IFulfillmentLineItem,
} from "./fulfillment-line-item";
import { FulfillmentOrderAddressSchema } from "./fulfillment-order-address";
import { RedoShopifyFulfillmentStatus } from "./fulfillment-status";
import { BatchState, ScanFormStatus } from "./scan-form";

export const RedoFulfillmentSchema = z.object({
  provider: z.nativeEnum(Provider),
  externalId: z.string(),
  name: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  estimatedDeliveryAt: z.date().nullish(),
  originAddress: FulfillmentOrderAddressSchema,
  destinationAddress: FulfillmentOrderAddressSchema,
  inTransitAt: z.date().nullish(),
  deliveredAt: z.date().nullish(),
  displayStatus: z.nativeEnum(RedoShopifyFulfillmentDisplayStatus).nullish(),
  status: z.nativeEnum(RedoShopifyFulfillmentStatus),
  requiresShipping: z.boolean(),
  totalQuantity: z.number(),
  lineItems: z.array(FulfillmentLineItemSchema as IFulfillmentLineItem),
  trackingInfo: z.array(
    z.object({
      company: z.string().nullish(),
      url: z.string().nullish(),
      number: z.string().nullish(),
    }),
  ),
  order: z
    .object({
      id: z.string(),
      name: z.string(),
      tags: z.array(z.string()).nullish(),
      note: z.string(),
      createdAt: z.date(),
    })
    .nullish(),
  customer: z
    .object({
      id: z.string(),
      name: z.string(),
      tags: z.array(z.string()).nullish(),
      numOrders: z.number(),
      email: z.string().nullish(),
      phone: z.string().nullish(),
      note: z.string().nullish(),
    })
    .nullish(),
  raw: z.any(),
});
export type RedoFulfillment = z.infer<typeof RedoFulfillmentSchema>;
export interface IRedoFulfillment extends z.ZodType<RedoFulfillment> {}

export const OutboundShipmentLabelSchema = z.object({
  url: z.string(),
  printed: z.date().optional(),
  commercialInvoiceUrl: z.string().optional().nullish(),
  comInvoiceSubmittedElectronically: z.boolean().nullish(),
  labelDate: z.date().nullish(),
});
export type OutboundShipmentLabel = z.infer<typeof OutboundShipmentLabelSchema>;

export const OutboundShipmentLineItemSchema = z.object({
  id: z.string(),
  quantity: z.number(),
  sku: z.string().nullish(),
});
export type OutboundShipmentLineItem = z.infer<
  typeof OutboundShipmentLineItemSchema
>;

export const LineItemsByFulfillmentOrderSchema = z.array(
  z.object({
    fulfillmentOrderId: z.string(),
    fulfillmentOrderLineItems: z.array(OutboundShipmentLineItemSchema),
  }),
);
export type LineItemsByFulfillmentOrder = z.infer<
  typeof LineItemsByFulfillmentOrderSchema
>;

// https://support.easypost.com/hc/en-us/articles/360044353091-Tracking-Frequently-Asked-Questions#h_9656edcc-0b76-4851-8b09-a826d9add238
export enum ShipmentTrackerStatus {
  UNKNOWN = "unknown",
  PRE_TRANSIT = "pre_transit",
  IN_TRANSIT = "in_transit",
  OUT_FOR_DELIVERY = "out_for_delivery",
  AVAILABLE_FOR_PICKUP = "available_for_pickup",
  DELIVERED = "delivered",
  RETURN_TO_SENDER = "return_to_sender",
  FAILURE = "failure",
  CANCELLED = "cancelled",
  ERROR = "error",
}

export const ShipmentTrackerSchema = z.object({
  id: z.string(),
  status: z.nativeEnum(ShipmentTrackerStatus),
  trackingCode: z.string(),
  carrier: z.string(),
  publicUrl: z.string(),
  raw: z.any(),
});

export const ShipmentPackageSchema = z.object({
  label: OutboundShipmentLabelSchema.nullish(),
  boughtRate: RateSchema.nullish(),
  parcel: ParcelSchema.nullish(),
  lineItemsByFulfillmentOrder: LineItemsByFulfillmentOrderSchema,
  slip: z.object({ printed: z.date().nullish() }).nullish(),
  labelSlipUrl: z.string().nullish(),
  scanFormId: z.string().nullish(),
  scanFormStatus: z.nativeEnum(ScanFormStatus).nullish(),
  scanFormBatch: z
    .object({ id: z.string(), state: z.nativeEnum(BatchState) })
    .nullish(),
  voided: z.boolean().nullish(),
  tracker: ShipmentTrackerSchema.nullish(),
  insured: z.boolean().nullish(),
  insuredValue: z.number().nullish(),
});
export type ShipmentPackage = z.infer<typeof ShipmentPackageSchema>;
export interface IShipmentPackage extends z.ZodType<ShipmentPackage> {}

export const OutboundShipmentSchema = z.object({
  _id: zExt.objectId(),
  team: zExt.objectId(),
  createdAt: z.date(),
  updatedAt: z.date(),
  fulfillments: z.array(RedoFulfillmentSchema),
  shipmentPackages: z.array(ShipmentPackageSchema),
});

export type OutboundShipment = z.infer<typeof OutboundShipmentSchema>;

export function getSuccessFulfillmentsByTrackingCode(
  fulfillmentGroup: FulfillmentGroup,
): RedoFulfillment[] {
  const trackingCode = fulfillmentGroup.shipment?.tracker?.trackingCode;
  if (!trackingCode) {
    throw new Error("No tracking code on shipment");
  }
  const fulfillments =
    fulfillmentGroup.fulfillments?.filter(
      (f) =>
        f.trackingInfo?.find((t) => t.number === trackingCode) &&
        f.status === RedoShopifyFulfillmentStatus.Success,
    ) ?? [];

  return fulfillments;
}

// https://shopify.dev/docs/api/admin-graphql/latest/objects/FulfillmentTrackingInfo#supported-tracking-companies
export function getShopifyFriendlyCarrierName(
  carrier: Carriers | undefined,
): string {
  if (!carrier) {
    return "";
  }
  switch (carrier) {
    case Carriers.FEDEX:
    case Carriers.FEDEX_SMART_POST:
      return "FedEx";
    case Carriers.UPS:
    case Carriers.UPS_SUREPOST:
      return "UPS";
    case Carriers.USPS:
    case Carriers.USPS_REDO:
    case Carriers.USPS_RETURNS:
      return "USPS";
    case Carriers.AMAZON:
      return "Amazon";
    default:
      assertNever(carrier);
  }
}
