import { FulfillmentOrderAddressSchema } from "@redotech/redo-model/fulfillments/fulfillment-order-address";
import { z } from "zod";

export const updateShippingAddress = {
  input: z.object({
    fulfillmentGroupId: z.string(),
    address: FulfillmentOrderAddressSchema,
  }),
  output: z.object({ success: z.boolean() }),
};
