import { z } from "zod";
import { zExt } from "../common/zod-util";

export const BaseFolderSchema = z.object({
  _id: zExt.objectId(),
  table: z.string(),
  name: z.string(),
  position: z.number(),
});

export const folderSchemas = { "fulfillment-groups": BaseFolderSchema };

export enum FolderTypesNames {
  FULFILLMENT_GROUPS = "fulfillment-groups",
}

export type folderTypes = {
  [key in keyof typeof folderSchemas]: z.infer<(typeof folderSchemas)[key]>;
};
