import { Card } from "@redotech/redo-web/card";
import { Flex } from "@redotech/redo-web/flex";
import { SkeletonBlock, SkeletonText } from "@redotech/redo-web/skeleton";
import { Text } from "@redotech/redo-web/text";
import { memo } from "react";
import * as returnCss from "./details-card.module.css";

export const OrderTitleSkeleton = memo(function OrderTitleSkeleton() {
  return (
    <Flex dir="column" gap="sm">
      <Flex dir="row" gap="sm">
        <Text fontSize="lg" fontWeight="bold">
          Order
        </Text>
        <SkeletonText length={100} />
      </Flex>
      <SkeletonText length={100} />
      <Flex dir="row" gap="sm">
        <SkeletonBlock />
        <SkeletonBlock />
      </Flex>
    </Flex>
  );
});

export const ReturnItemSkeleton = memo(function ReturnItemSkeleton() {
  return (
    <div className={returnCss.item}>
      <div className={returnCss.itemMain}>
        <SkeletonBlock />
        <Flex dir="column" gap="md" style={{ flex: 1 }}>
          <Flex dir="row" justify="space-between">
            <SkeletonText length={30} />
            <SkeletonText length={10} />
          </Flex>
          <Flex dir="row" gap="sm">
            <SkeletonText length={8} />
            <SkeletonText length={12} />
            <SkeletonText length={10} />
          </Flex>
        </Flex>
      </div>
    </div>
  );
});

export const ActivitySkeleton = memo(function ActivitySkeleton() {
  return (
    <Flex dir="column" gap="xl">
      <Text fontSize="md" fontWeight="semibold">
        Activity
      </Text>
      <Flex dir="column" gap="lg">
        {[1, 2, 3].map((i) => (
          <Flex dir="row" gap="md" key={i}>
            <SkeletonBlock />
            <Flex dir="column" gap="sm" style={{ flex: 1 }}>
              <SkeletonText length={20} />
              <SkeletonText length={40} />
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
});

export const ReturnItemsSkeleton = memo(function ReturnItemsSkeleton() {
  return (
    <div className={returnCss.items}>
      <Text fontSize="md" fontWeight="semibold">
        Return items
      </Text>
      <Card radius="md">
        <ReturnItemSkeleton />
        <ReturnItemSkeleton />
      </Card>
    </div>
  );
});

export const NewItemsSkeleton = memo(function NewItemsSkeleton() {
  return (
    <div className={returnCss.items}>
      <Text fontSize="md" fontWeight="semibold">
        New items
      </Text>
      <Card radius="md">
        <ReturnItemSkeleton />
      </Card>
    </div>
  );
});
