import { OutboundSystemView } from "@redotech/redo-model/views/default-outbound-views";
import { viewTypes } from "@redotech/redo-model/views/views";
import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoTextInput } from "@redotech/redo-web/arbiter-components/input/redo-text-input";
import { Flex } from "@redotech/redo-web/flex";
import { DisabledTooltip } from "@redotech/redo-web/tooltip/disabled-tooltip";
import { sinkPromise } from "@redotech/util/promise";
import { memo, useEffect, useState } from "react";

export const TableFilterEditorHeader = memo(function TableFilterEditorHeader({
  reservedViewNames,
  activeView,
  cancel,
  save,
  savePending,
}: {
  reservedViewNames: Set<string>;
  activeView: viewTypes[keyof viewTypes];
  cancel: () => void;
  save: (body: { name: string }) => Promise<void>;
  savePending: boolean;
}) {
  const [viewName, setViewName] = useState<string>("");

  useEffect(() => {
    if (activeView && activeView.name !== OutboundSystemView.CREATE) {
      setViewName(activeView.name);
    }
  }, [activeView]);

  const submitDisabledBecauseOfEmptyName =
    viewName.length === 0 ? "You must name this view" : false;

  const submitDisabledBecauseOfDuplicateName = reservedViewNames.has(
    viewName.toLowerCase(),
  )
    ? "A view with this name already exists"
    : false;

  const submitDisabled =
    submitDisabledBecauseOfEmptyName || submitDisabledBecauseOfDuplicateName;

  return (
    <Flex align="center" justify="space-between" px="3xl" py="3xl">
      <Flex align="center" gap="xl">
        <RedoTextInput
          placeholder="Enter a name"
          setValue={setViewName}
          value={viewName}
        />
      </Flex>
      <Flex align="center">
        <RedoButton hierarchy="secondary" onClick={cancel} text="Cancel" />
        <DisabledTooltip disabledMessage={submitDisabled}>
          <RedoButton
            disabled={!!submitDisabled}
            hierarchy="primary"
            onClick={() => {
              sinkPromise(save({ name: viewName }));
            }}
            pending={savePending}
            text="Save view"
          />
        </DisabledTooltip>
      </Flex>
    </Flex>
  );
});
