import { ReturnTypeEnum } from "@redotech/redo-model/return";
import { z } from "zod";

export const getReturnAnalyticsStatistics = {
  input: z.object({
    startDate: z.string(),
    endDate: z.string(),
    types: z.array(z.nativeEnum(ReturnTypeEnum)),
  }),
  output: z.object({
    keyMetrics: z.object({
      retainedRevenue: z.number(),
      refundedRevenue: z.number(),
      retainedRevenueRatio: z.number(),
      refundedRevenueRatio: z.number(),
      upsellRevenue: z.number(),
    }),
    byOrderMetrics: z.object({
      totalOrders: z.number(),
      returnedOrders: z.number(),
      returnedOrdersRate: z.number(),
    }),
    byItemMetrics: z.object({
      totalItems: z.number(),
      returnedItems: z.number(),
      returnedItemsRate: z.number(),
    }),
    byRevenueMetrics: z.object({
      totalRevenue: z.number(),
      returnedRevenue: z.number(),
      returnedRevenueRate: z.number(),
    }),
    returnsMetrics: z.object({
      submittedReturns: z.number(),
      processedReturns: z.number(),
      processedReturnsRate: z.number(),
    }),
  }),
};

export type ReturnAnalyticsStatistics = z.infer<
  typeof getReturnAnalyticsStatistics.output
>;
