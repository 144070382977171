import { Gid } from "@redotech/util/shopify-id";

// TODO: add more types as needed
export enum GidType {
  ORDER = "Order",
  LINE_ITEM = "LineItem",
  RETURN = "Return",
  FULFILLMENT = "Fulfillment",
  PRODUCT_VARIANT = "ProductVariant",
  DRAFT_ORDER = "DraftOrder",
  LOCATION = "Location",
  CUSTOMER = "Customer",
  PRODUCT = "Product",
  CART = "Cart",
  GIFT_CARD = "GiftCard",
}

export const isGid = (gid: string | number) => {
  if (typeof gid === "number") {
    return false;
  }

  return gid.startsWith("gid://");
};

export const parseGid = (gid: string | number): string => {
  if (typeof gid === "number") {
    return gid.toString();
  }

  if (!isGid(gid)) {
    return gid;
  }

  return gid.split("/").pop()!;
};

export const toGid = (type: GidType, id: string | number) => {
  if (isGid(id.toString())) {
    return id.toString();
  }

  return `gid://shopify/${type}/${id}`;
};

export type MaybeGid = Gid | string | number | null | undefined;
export const compareMaybeGids = (id1: MaybeGid, id2: MaybeGid) => {
  if (!id1 || !id2) {
    // undefined technically equals undefined, but generally ids are not expected
    // to be undefined, so returning true here could cause unexpected results
    return false;
  }
  return parseGid(id1) === parseGid(id2);
};
