// extracted by mini-css-extract-plugin
export var actions = "_20-0";
export var actionsContainer = "_20-4";
export var border = "_20-1";
export var childFullHeight = "_20-b";
export var collapse = "_20-2";
export var content = "_20-6";
export var contentChild = "_20-a";
export var fullscreen = "_20-8";
export var hidePadding = "_20-7";
export var noScrollX = "_20-c";
export var noScrollY = "_20-d";
export var optOutTopPadding = "_20-9";
export var pagePadding = "_20-5";
export var profile = "_20-e";
export var profileContainer = "_20-f";
export var profileImage = "_20-g";
export var profileMain = "_20-h";
export var profileSubtitle = "_20-j";
export var profileTitle = "_20-i";
export var tabsWrapper = "_20-3";
export var title = "_20-k";