import {
  createLazyContext,
  useLazyContextProvider,
  useRequiredContext,
} from "@redotech/react-util/context";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { Macro } from "@redotech/redo-model/macro";
import { memo, ReactNode } from "react";
import { getMacros } from "../../client/macro";

export const MacrosContext = createLazyContext<Macro[]>();
export const MacrosService = memo(function MacrosService({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const client = useRequiredContext(RedoMerchantClientContext);

  const loadState = useLazyContextProvider(async (signal) => {
    const { data } = await getMacros(client, { signal });
    return data;
  });

  return (
    <MacrosContext.internalContext.Provider value={loadState}>
      {children}
    </MacrosContext.internalContext.Provider>
  );
});
