import { useSearch } from "@redotech/react-util/search";
import RedoIconArrowLeft from "@redotech/redo-web/arbiter-icon/arrow-left.svg";
import SearchSm from "@redotech/redo-web/arbiter-icon/search-sm.svg";
import * as classNames from "classnames";
import Fuse from "fuse.js";
import { memo, useMemo, useState } from "react";
import { Flex } from "../../flex";
import { Text } from "../../text";
import * as darkModeCss from "../../theme/component-colors-dark.module.css";
import { RedoIcon } from "../icon/redo-icon";
import { RedoTextInput } from "../input/redo-text-input";
import { UserProfile } from "./common/redo-nav-user-profile";
import * as navigationCss from "./redo-navigation.module.css";
import {
  RedoSecondaryNavItem,
  SecondaryNavItemProps,
} from "./secondary/redo-secondary-nav-item";

export interface SettingItem {
  href: string;
  friendlyName: string;
  id: string;
}

export interface SettingsSection {
  name: string;
  items: SettingItem[];
}

export const RedoSettingsNav = memo(function RedoSettingsNav({
  sections,
  exitSettingsUrl,
}: {
  sections: SettingsSection[];
  exitSettingsUrl: string;
}) {
  const [search, setSearch] = useState("");

  const flattenedItems = useMemo(
    () => sections.flatMap((section) => section.items),
    [sections],
  );

  const itemSearcher = useMemo(() => {
    const searcher = new Fuse<SettingItem>([], {
      keys: ["friendlyName"],
      threshold: 0.4,
    });
    return searcher;
  }, []);

  const searchedItems = useSearch(itemSearcher, flattenedItems, search);

  const modifiedSections = useMemo(() => {
    const searchItemsInResults = new Set(searchedItems.map((item) => item.id));

    const modifiedSections = sections.map((section) => {
      const items = section.items.filter((item) =>
        searchItemsInResults.has(item.id),
      );
      return { name: section.name, items: items };
    });

    const nonEmptySections = modifiedSections.filter(
      (section) => section.items.length > 0,
    );

    return nonEmptySections;
  }, [searchedItems, sections]);

  return (
    <Flex
      bgColor="primary"
      className={classNames(navigationCss.settingsNav, darkModeCss.darkMode)}
      dir="column"
      grow={1}
      justify="space-between"
    >
      <Flex dir="column" gap="xl" overflow="hidden" py="3xl">
        <Flex px="sm">
          <Flex
            align="center"
            as="a"
            className={navigationCss.backButton}
            grow={1}
            linkProps={{ to: exitSettingsUrl }}
            px="3xl"
            py="sm"
            radius="sm"
          >
            <RedoIcon color="tertiary" Icon={RedoIconArrowLeft} size={16} />{" "}
            <Text fontSize="xs" fontWeight="regular" textColor="tertiary">
              Back
            </Text>
          </Flex>
        </Flex>
        <Flex px="3xl">
          <RedoTextInput
            IconLeading={SearchSm}
            placeholder="Search"
            setValue={setSearch}
            value={search}
          />
        </Flex>
        <Flex
          className={navigationCss.settingsNavItems}
          dir="column"
          gap="lg"
          px="xl"
        >
          {modifiedSections.map((section) => (
            <SettingsSection
              items={section.items}
              key={section.name}
              name={section.name}
            />
          ))}
        </Flex>
      </Flex>
      <UserProfile collapsed={false} />
    </Flex>
  );
});

function settingItemToProps(item: SettingItem): SecondaryNavItemProps {
  return {
    action: { type: "link", href: item.href },
    id: item.id,
    friendlyName: item.friendlyName,
  };
}

const SettingsSection = memo(function SettingsSection({
  name,
  items,
}: SettingsSection) {
  return (
    <Flex dir="column" gap="xs">
      <Text fontSize="xs" fontWeight="regular" textColor="tertiary">
        {name}
      </Text>
      <Flex dir="column" gap="xs">
        {items.map((item) => (
          <RedoSecondaryNavItem {...settingItemToProps(item)} key={item.id} />
        ))}
      </Flex>
    </Flex>
  );
});
