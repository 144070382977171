import {
  Call,
  CallDirection,
  CallState,
  NonActiveCall,
} from "@redotech/redo-model/support/voice/voice-types";
import { assertNever } from "@redotech/util/type";
import { memo } from "react";
import { Flex } from "../flex";
import { Text } from "../text";
import { UserImage, UserImageSize } from "../user-image";
import { TimeElapsed } from "./active-call-elapsed-time";

export const CallProgressView = memo(function CallProgressView({
  call,
}: {
  call: Call;
}) {
  const text = call.remoteCallerName
    ? `${call.remoteCallerName} - ${call.remoteCallerNumber}`
    : call.remoteCallerNumber;

  return (
    <Flex align="center" dir="column">
      <UserImage
        alt={call.remoteCallerName || call.remoteCallerNumber}
        imageUrl={null}
        size={UserImageSize.MEDIUM_SMALL}
      />
      <Text fontSize="xxs" textColor="secondary">
        {text}
      </Text>
      {call.state === CallState.ACTIVE ? (
        <TimeElapsed startTime={call.startTime} />
      ) : (
        <CallText call={call} />
      )}
    </Flex>
  );
});

export function getCallText(call: NonActiveCall): string {
  if (call.direction === CallDirection.OUTBOUND) {
    switch (call.state) {
      case CallState.CONNECTING:
        return "Connecting...";
      case CallState.RINGING:
        return "Calling...";
      case CallState.HANGUP:
        return "Call ended";
      default:
        assertNever(call);
    }
  } else {
    switch (call.state) {
      case CallState.RINGING:
        return "Incoming call...";
      case CallState.CONNECTING:
        return "Connecting...";
      case CallState.HANGUP:
        return "Call ended";
      default:
        assertNever(call);
    }
  }
}

const CallText = memo(function CallText({ call }: { call: NonActiveCall }) {
  return (
    <Text fontSize="xxs" textColor="primary">
      {getCallText(call)}
    </Text>
  );
});
