import { ReturnTypeEnum } from "@redotech/redo-model/return";
import { z } from "zod";

export const getOperationsNewTotals = {
  input: z.object({
    startDate: z.string(),
    endDate: z.string(),
    types: z.array(z.nativeEnum(ReturnTypeEnum)),
  }),
  output: z.object({
    data: z.array(
      z.object({
        email: z.string().nullable(),
        userName: z.string().nullable(),
        numCreated: z.number(),
        numApproved: z.number(),
        numProcessedComplete: z.number(),
        numProcessedIncomplete: z.number(),
      }),
    ),
  }),
};

export type OperationsNewTotals = z.infer<typeof getOperationsNewTotals.output>;
