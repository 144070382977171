import { useHandler } from "@redotech/react-util/hook";
import { CheckoutAbTestMetricToOptimize } from "@redotech/redo-model/checkouts/checkout-ab-test-builder";
import { createContext, useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";

export const CreateAbTestInitializationParamsContext = createContext<{
  initialName: string;
  setInitialName: (name: string) => void;
  initialHypothesis: string;
  setInitialHypothesis: (hypothesis: string) => void;
  initialMetricToOptimize: CheckoutAbTestMetricToOptimize;
  setInitialMetricToOptimize: (
    metricToOptimize: CheckoutAbTestMetricToOptimize,
  ) => void;
  resetToDefault: () => void;
}>({
  initialName: "",
  setInitialName: () => {},
  initialHypothesis: "",
  setInitialHypothesis: () => {},
  initialMetricToOptimize:
    CheckoutAbTestMetricToOptimize.CHECKOUT_CONVERSION_RATE,
  setInitialMetricToOptimize: () => {},
  resetToDefault: () => {},
});

export const CreateAbTestInitializationParamsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [initialName, setInitialName] = useState("");
  const [initialHypothesis, setInitialHypothesis] = useState("");
  const [initialMetricToOptimize, setInitialMetricToOptimize] = useState(
    CheckoutAbTestMetricToOptimize.CHECKOUT_CONVERSION_RATE,
  );

  const resetToDefault = useHandler(() => {
    setInitialName("");
    setInitialHypothesis("");
    setInitialMetricToOptimize(
      CheckoutAbTestMetricToOptimize.CHECKOUT_CONVERSION_RATE,
    );
  });

  // Preserve resetting to defaults
  const location = useLocation();
  useEffect(() => {
    if (!matchPath("/stores/:storeId/conversion/*", location.pathname)) {
      resetToDefault();
    }
  }, [location.pathname, resetToDefault]);

  return (
    <CreateAbTestInitializationParamsContext.Provider
      value={{
        initialName,
        setInitialName,
        initialHypothesis,
        setInitialHypothesis,
        initialMetricToOptimize,
        setInitialMetricToOptimize,
        resetToDefault,
      }}
    >
      {children}
    </CreateAbTestInitializationParamsContext.Provider>
  );
};
