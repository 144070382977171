import { useRequiredContext } from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { useHandler } from "@redotech/react-util/hook";
import { useTriggerLoad } from "@redotech/react-util/load";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import type { Return } from "@redotech/redo-model/return";
import { alertOnFailure } from "@redotech/redo-web/alert";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import FlagIcon from "@redotech/redo-web/icon-old/flag.svg";
import { Modal, ModalSize } from "@redotech/redo-web/modal";
import { FormSwitch } from "@redotech/redo-web/switch";
import { InputLines, TextInput } from "@redotech/redo-web/text-input";
import { groupInput, input } from "@redotech/ui/form";
import { memo, useState } from "react";
import { returnCommentCreate } from "../../client/comment";
import { flagReturn, unflagReturn } from "../../client/return";
import * as returnCss from "../return.module.css";
import { returnTypeName } from "../util";

type UploadedImage = { base64: string; name: string; bytesTotal: number };

import { RedoFileUpload } from "@redotech/redo-web/arbiter-components/file-upload/redo-file-upload";
export const FlagModal = memo(function FlagModal({
  return: return_,
  open,
  onClose,
  reload,
  flag,
}: {
  flag: boolean;
  open: boolean;
  onClose(): void;
  return: Return;
  reload(): void;
}) {
  const actionString = flag ? "Flag" : "Unflag";
  const client = useRequiredContext(RedoMerchantClientContext);
  const [uploadedImages, setUploadedImages] = useState<UploadedImage[]>([]);

  const handleFilesAdded = useHandler((newFiles: FileList | null) => {
    if (newFiles && newFiles.length > 0) {
      Array.from(newFiles).forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          setUploadedImages((prev) => [
            ...prev,
            {
              base64: e.target?.result as string,
              name: file.name,
              bytesTotal: file.size,
            },
          ]);
        };
        reader.readAsDataURL(file);
      });
    }
  });

  const removeImage = (index: number) => {
    setUploadedImages((prev) => prev.filter((_, i) => i !== index));
  };

  const [resendLoad, doResend] = useTriggerLoad((signal) =>
    alertOnFailure(`Failed to ${actionString.toLowerCase()} return`)(
      async () => {
        if (flag) {
          await flagReturn(client, { returnId: return_.id, signal });
        } else {
          await unflagReturn(client, { returnId: return_.id, signal });
        }
        const note = input_.inputs.comment.value;
        if (
          input_.inputs.addComment.value &&
          (note || uploadedImages.length > 0)
        ) {
          await returnCommentCreate(client, {
            message: note || "",
            returnId: return_!.id,
            signal,
            images: uploadedImages,
          });
        }
        reload();
        return true;
      },
    ),
  );
  const handleSend = useHandler(() => doResend());

  const footer = resendLoad.value ? (
    <Button
      className={returnCss.modalButton}
      onClick={onClose}
      theme={ButtonTheme.OUTLINED}
    >
      Ok
    </Button>
  ) : (
    <>
      <Button
        className={returnCss.modalButton}
        onClick={onClose}
        theme={ButtonTheme.OUTLINED}
      >
        No, go back
      </Button>
      <Button
        className={returnCss.modalButton}
        onClick={handleSend}
        theme={ButtonTheme.PRIMARY}
      >
        Yes, proceed
      </Button>
    </>
  );

  const input_ = useInput(
    groupInput({ addComment: input<boolean>(), comment: input<string>() }),
    { addComment: true, comment: "" },
  );
  const { addComment, comment } = input_.inputs;

  const cardTitle = `${actionString} ${returnTypeName(return_.type)}`;
  const commentPrompt = flag
    ? `Describe the reason for flagging the ${returnTypeName(return_.type)}`
    : `Describe the reason for unflagging the ${returnTypeName(return_.type)}`;
  return (
    <Modal
      footer={footer}
      onClose={onClose}
      open={open}
      size={ModalSize.SMALL}
      title={cardTitle}
      TitleIcon={FlagIcon}
    >
      <div className={returnCss.modalContent}>
        {resendLoad.value ? (
          <p>
            The {returnTypeName(return_.type)} has been{" "}
            {actionString.toLowerCase()}ged.
          </p>
        ) : (
          <>
            {flag ? (
              <p>This will prevent automatic processing until unflagged.</p>
            ) : (
              <p>This will allow automatic processing.</p>
            )}
            <FormSwitch input={addComment} label="Add comment">
              Add a comment to the {returnTypeName(return_.type)}.
            </FormSwitch>
            {addComment.value && (
              <>
                <TextInput
                  lines={InputLines.MULTI}
                  onChange={comment.setValue}
                  placeholder={commentPrompt}
                  value={comment.value}
                />
                <RedoFileUpload
                  accept="image/*"
                  acceptMessage="Accepts images"
                  className={returnCss.imageUploadContainer}
                  files={uploadedImages}
                  handleFileDeleted={removeImage}
                  handleFilesAdded={handleFilesAdded}
                  nFilesToAccept={5}
                />
              </>
            )}
          </>
        )}
      </div>
    </Modal>
  );
});
