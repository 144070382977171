import { Redirect } from "@redotech/react-router-util/redirect";
import { breadcrumbSlot } from "@redotech/redo-web/breadcrumb";
import { Navigate, RouteObject } from "react-router-dom";

const campaignDetailsBreadcrumb = breadcrumbSlot();

export const backwardsCompatibleEmailSmsRoutes: RouteObject[] = [
  {
    path: "automations",
    children: [
      {
        children: [
          {
            index: true,
            element: (
              <Redirect
                match={/automations/}
                replaceValue="email-sms/automations"
              />
            ),
          },
          {
            path: "*",
            element: (
              <Redirect
                match={/automations/}
                replaceValue="email-sms/automations"
              />
            ),
          },
        ],
      },
    ],
  },
  {
    path: "campaigns",
    children: [
      {
        children: [
          {
            index: true,
            element: (
              <Redirect
                match={/campaigns/}
                replaceValue="email-sms/campaigns"
              />
            ),
          },
          {
            path: "*",
            element: (
              <Redirect
                match={/campaigns/}
                replaceValue="email-sms/campaigns"
              />
            ),
          },
        ],
      },
    ],
  },
  {
    path: "templates",
    children: [
      {
        children: [
          {
            index: true,
            element: (
              <Redirect
                match={/templates/}
                replaceValue="email-sms/templates"
              />
            ),
          },
          {
            path: "*",
            element: (
              <Redirect
                match={/templates/}
                replaceValue="email-sms/templates"
              />
            ),
          },
        ],
      },
    ],
  },
  {
    path: "audience",
    children: [
      {
        children: [
          {
            index: true,
            element: (
              <Redirect match={/audience/} replaceValue="email-sms/audience" />
            ),
          },
          {
            path: "*",

            element: (
              <Redirect match={/audience/} replaceValue="email-sms/audience" />
            ),
          },
        ],
      },
    ],
  },
  {
    path: "segments",
    children: [
      {
        children: [
          {
            index: true,

            element: (
              <Redirect match={/segments/} replaceValue="email-sms/segments" />
            ),
          },
          {
            path: "*",

            element: (
              <Redirect match={/segments/} replaceValue="email-sms/segments" />
            ),
          },
        ],
      },
    ],
  },
];

export const emailSmsRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Marketing", breadcrumbNavigate: false },
    children: [
      { index: true, element: <Navigate to="audience" /> },
      {
        path: "signups",
        handle: { breadcrumb: "Sign-ups" },
        children: [
          {
            index: true,
            async lazy() {
              const { MarketingSignups } = await import(
                "./signups/signups-page"
              );
              return { Component: MarketingSignups };
            },
          },
          {
            path: "builder/:signupFormId",
            handle: { breadcrumb: "Sign-up Builder" },
            async lazy() {
              const { MarketingSignupBuilder } = await import(
                "./signups/signup-form-builder"
              );
              return { Component: MarketingSignupBuilder };
            },
          },
        ],
      },
      {
        path: "campaigns",
        handle: { breadcrumb: "Campaigns" },
        children: [
          {
            index: true,
            async lazy() {
              const { MarketingCampaigns } = await import(
                "./campaigns/campaigns-page"
              );
              return { Component: MarketingCampaigns };
            },
          },
          {
            path: ":campaignId/details",
            handle: { breadcrumb: campaignDetailsBreadcrumb },
            async lazy() {
              const { MarketingCampaignDetails } = await import(
                "./campaigns/campaign-details-page"
              );
              return {
                element: (
                  <MarketingCampaignDetails
                    breadcrumb={campaignDetailsBreadcrumb}
                  />
                ),
              };
            },
          },
          {
            path: ":campaignId/edit/groups",
            lazy: async () => {
              const { EditCampaignGroups } = await import(
                "./campaigns/edit/groups"
              );
              return { Component: EditCampaignGroups };
            },
          },
          {
            path: ":campaignId/edit/template",
            lazy: async () => {
              const { EditCampaignTemplate } = await import(
                "./campaigns/edit/template"
              );
              return { Component: EditCampaignTemplate };
            },
          },
          {
            path: ":campaignId/edit/preview", // called "review" in the mocks but I want to disambiguate from the "reviews" product
            lazy: async () => {
              const { PreviewCampaign } = await import(
                "./campaigns/edit/preview"
              );
              return { Component: PreviewCampaign };
            },
          },
        ],
      },
      {
        path: "templates",
        handle: { breadcrumb: "Templates" },
        children: [
          {
            path: "email",
            handle: { breadcrumb: "Email" },
            children: [
              {
                index: true,
                async lazy() {
                  const { EmailTemplatePage } = await import(
                    "./email-builder/email-template-page"
                  );
                  return { Component: EmailTemplatePage };
                },
              },
              {
                path: ":emailTemplateId",
                handle: { breadcrumb: "Edit" },
                lazy: async () => {
                  const { EmailBuilderForTemplateCollection } = await import(
                    "./email-builder/email-builder"
                  );
                  return { Component: EmailBuilderForTemplateCollection };
                },
              },
            ],
          },
          {
            path: "sms",
            handle: { breadcrumb: "SMS" },
            children: [
              {
                index: true,
                async lazy() {
                  const { SmsBuilderTemplates } = await import(
                    "./sms-builder/sms-builder-templates"
                  );
                  return { Component: SmsBuilderTemplates };
                },
              },
              {
                path: ":smsTemplateId",
                async lazy() {
                  const { SmsBuilderLoad } = await import(
                    "./sms-builder/sms-builder"
                  );
                  return { Component: SmsBuilderLoad };
                },
              },
            ],
          },
        ],
      },
      {
        path: "audience",
        handle: { breadcrumb: "Audience" },
        children: [
          {
            index: true,
            async lazy() {
              const { AudiencePage } = await import("./audience/audience-page");
              return { Component: AudiencePage };
            },
          },
        ],
      },
      {
        path: "segments",
        handle: { breadcrumb: "Segments" },
        children: [
          {
            index: true,
            async lazy() {
              const { SegmentsPage } = await import("./segments/segments-page");
              return { Component: SegmentsPage };
            },
          },
          {
            path: ":segmentId",
            async lazy() {
              const { SegmentBuilderPage } = await import(
                "./segments/segment-builder-page"
              );
              return { Component: SegmentBuilderPage };
            },
          },
        ],
      },
    ],
  },
];

export const automationRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Automations" },
    children: [
      {
        index: true,
        async lazy() {
          const { AutomationsPage: MarketingAutomations } = await import(
            "./automations/automations-page"
          );
          return { Component: MarketingAutomations };
        },
      },
      {
        path: ":automationId",
        handle: { breadcrumb: "Automation Builder" },
        async lazy() {
          const { MarketingAutomationBuilder } = await import(
            "./automations/marketing-automation-builder"
          );
          return { Component: MarketingAutomationBuilder };
        },
      },
    ],
  },
];
