import * as amplitude from "@amplitude/analytics-browser";
import { OverlapTransitionStateContext } from "@redotech/react-animation/outlet-transition";
import { OverlapTransitionState } from "@redotech/react-animation/transition";
import { useRequiredContext } from "@redotech/react-util/context";
import { useScrolled } from "@redotech/react-util/scroll";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { UserContext } from "@redotech/redo-merchant-app-common/user";
import {
  ConversationPlatform,
  ConversationStatus,
  ExpandedConversation,
} from "@redotech/redo-model/conversation";
import {
  AdvancedFilter,
  ConversationTagFilterType,
  FilterGroupFilterOption,
} from "@redotech/redo-model/conversation-filters/conversation-filters";
import { SystemView } from "@redotech/redo-model/conversation-filters/system-view";
import { Permission, permitted } from "@redotech/redo-model/user";
import AnnotationCheck from "@redotech/redo-web/arbiter-icon/annotation-check.svg";
import CheckIcon from "@redotech/redo-web/arbiter-icon/check.svg";
import Edit04Icon from "@redotech/redo-web/arbiter-icon/edit-04.svg";
import StatusSvg from "@redotech/redo-web/arbiter-icon/status.svg";
import TrashIcon from "@redotech/redo-web/arbiter-icon/trash-01.svg";
import User01 from "@redotech/redo-web/arbiter-icon/user-01.svg";
import { Button, ButtonSize, ButtonTheme } from "@redotech/redo-web/button";
import EnvelopeOpen from "@redotech/redo-web/icon-old/envelope-open.svg";
import EnvelopeIcon from "@redotech/redo-web/icon-old/mail.svg";
import MergeIcon from "@redotech/redo-web/icon-old/merge.svg";
import PlusIcon from "@redotech/redo-web/icon-old/plus.svg";
import SnoozeClockIcon from "@redotech/redo-web/icon-old/snooze-clock.svg";
import SpamIcon from "@redotech/redo-web/icon-old/spam.svg";
import TagIcon from "@redotech/redo-web/icon-old/tag.svg";
import UndoArrow from "@redotech/redo-web/icon-old/undo-arrow.svg";
import UserPlusIcon from "@redotech/redo-web/icon-old/user-plus.svg";
import { ActionPortalContext } from "@redotech/redo-web/page";
import { memo, useContext, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useNavigate } from "react-router-dom";
import { updateConversation } from "../../client/conversations";
import { UpdateConversationStateContext } from "../context/update-conversations-context";
import { ArchiveTicketModal } from "../conversation-actions/archive-ticket-modal";
import { AssignTicketModal } from "../conversation-actions/assign-ticket-modal";
import { ChangeSubjectModal } from "../conversation-actions/change-subject-modal";
import {
  CloseTicketModal,
  handleCloseTickets,
} from "../conversation-actions/close-ticket-modal";
import { MarkSpamModal } from "../conversation-actions/mark-spam-modal";
import { MergeModal } from "../conversation-actions/merge-modal";
import { ReopenTicketModal } from "../conversation-actions/reopen-ticket-modal";
import { SnoozeModal } from "../conversation-actions/snooze-modal";
import { TagsModal } from "../conversation-actions/tags-modal";
import { UnmarkSpamModal } from "../conversation-actions/unmark-spam-modal";
import { ConversationFetcher } from "../conversation-fetcher";
import { ActiveViewContext } from "../conversations-table-filters/active-view-context";
import {
  FinalizedFiltersContext,
  SetUniqueFiltersContext,
} from "../conversations-table-filters/filters-context";
import {
  markConversationAsRead,
  markConversationAsUnread,
} from "../conversations/mark-conversation-read-manager";
import { NewMessageModal } from "../new-message-modal";
import {
  ConversationsTable,
  ConversationTableDropdownAction,
} from "./conversations-table";
import { ConversationsTableHeader } from "./conversations-table-header";
import { ConversationsTableSelectionContext } from "./conversations-table-selection";
import * as conversationsTableCss from "./conversations-table.module.css";

const CONVERSATIONS_TABLE_ID = "conversations-table";

export const FullConversationsTable = memo(function FullConversationsTable({
  setActiveConversation,
  fetcher,
  blockRefresh,
  setBlockRefresh,
  pageLoaded,
}: {
  setActiveConversation: (
    conversation: ExpandedConversation | undefined,
  ) => void;
  fetcher: ConversationFetcher;
  blockRefresh: boolean;
  setBlockRefresh: (block: boolean) => void;
  pageLoaded: boolean;
}) {
  const client = useRequiredContext(RedoMerchantClientContext);
  const filters = useContext(FinalizedFiltersContext);
  const setFilters = useContext(SetUniqueFiltersContext);
  const view = useContext(ActiveViewContext);
  const actionsPortal = useContext(ActionPortalContext);
  const team = useContext(TeamContext);
  const user = useRequiredContext(UserContext);
  const { removeConversation, refreshIgnoreResult, tableRef } =
    useRequiredContext(UpdateConversationStateContext);
  const tableSelection = useRequiredContext(ConversationsTableSelectionContext);
  const [closeModalOpen, setCloseModalOpen] = useState(false);
  const [reopenModalOpen, setReopenModalOpen] = useState(false);
  const [mergeModalOpen, setMergeModalOpen] = useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [markSpamModalOpen, setMarkSpamModalOpen] = useState(false);
  const [unmarkSpamModalOpen, setUnmarkSpamModalOpen] = useState(false);
  const [changeSubjectModalOpen, setChangeSubjectModalOpen] = useState(false);
  const [shouldAssignToMe, setShouldAssignToMe] = useState(false);

  const inSpamView = filters.advancedFilters.find((filter: AdvancedFilter) => {
    return (
      filter.type === FilterGroupFilterOption.CONVERSATION_TAGS &&
      filter.value.includes("spam") &&
      filter.query !== ConversationTagFilterType.NONE_OF
    );
  });

  const [createConversationModalOpen, setCreateConversationModalOpen] =
    useState(false);

  const [snoozeModalOpen, setSnoozeModalOpen] = useState(false);
  // Stores conversation that needs an action (e.g. close, snooze, etc.) from the main view page
  const [conversationNeedingAction, setConversationNeedingAction] =
    useState<ExpandedConversation>();

  const navigate = useNavigate();
  const scrollableElement = document.getElementById(CONVERSATIONS_TABLE_ID);
  const scrolled = useScrolled(scrollableElement);
  const scrollAreaRef = useRef(null);

  const canReply =
    !!user && permitted(user.permissions, Permission.CREATE_REPLY);
  const canCloseTicket =
    !!user && permitted(user.permissions, Permission.CLOSE_CONVERSATION);
  const canAssignTag =
    !!user && permitted(user.permissions, Permission.ASSIGN_TAG);
  const canArchiveTicket =
    !!user && permitted(user.permissions, Permission.ARCHIVE_CONVERSATION);
  const canEditAssignee =
    !!user && permitted(user.permissions, Permission.EDIT_ASSIGNEE);

  const transitionState = useContext(OverlapTransitionStateContext);

  const dropdownActions = (
    conversation: ExpandedConversation,
    setDropdownOpen: (open: boolean) => void,
  ) => {
    const items: ConversationTableDropdownAction[] = [
      {
        show:
          canCloseTicket &&
          conversation.status !== ConversationStatus.CLOSED.toLowerCase(),
        commandMenuItem: {
          Icon: CheckIcon,
          text: "Close",
          onClick: async (e) => {
            e.stopPropagation();
            setDropdownOpen(false);
            setConversationNeedingAction(conversation);
            if (
              !!team &&
              (localStorage.getItem("redo.doNotShowCloseTicketModal") ===
                "true" ||
                conversation.platform !== ConversationPlatform.REDO_CHAT)
            ) {
              await handleCloseTickets({
                client: client,
                conversations: [conversation],
                setActiveConversation,
                team,
                inDetailView: false,
                cleanupFn: singleCleanupFn,
                removeConversation,
              });
            } else {
              amplitude.logEvent("view-closeConversationModal", {
                mode: "single",
                conversationIds: [conversation._id],
                channels: [conversation.platform],
              });
              setCloseModalOpen(true);
            }
          },
        },
      },
      {
        show:
          canCloseTicket &&
          (conversation.status === ConversationStatus.CLOSED.toLowerCase() ||
            conversation.status ===
              ConversationStatus.IN_PROGRESS.toLowerCase()),
        commandMenuItem: {
          Icon: UndoArrow,
          text:
            conversation.status === ConversationStatus.CLOSED.toLowerCase()
              ? "Reopen ticket"
              : "Mark open",
          onClick: (e) => {
            e.stopPropagation();
            amplitude.logEvent("view-reopenConversationModal", {
              mode: "single",
              conversationIds: [conversation._id],
              channels: [conversation.platform],
            });
            setDropdownOpen(false);

            setConversationNeedingAction(conversation);
            setReopenModalOpen(true);
          },
        },
      },
      {
        show: conversation.read ?? false,
        commandMenuItem: {
          Icon: EnvelopeIcon,
          text: "Mark unread",
          onClick: async (e) => {
            e.stopPropagation();
            setDropdownOpen(false);
            await handleMarkedUnread(conversation);
            refreshIgnoreResult();
          },
        },
      },
      {
        show: !conversation.read,
        commandMenuItem: {
          Icon: EnvelopeOpen,
          text: "Mark read",
          onClick: async (e) => {
            e.stopPropagation();
            setDropdownOpen(false);
            await onConversationsViewed(conversation);
            refreshIgnoreResult();
          },
        },
      },
      {
        show: canReply,
        commandMenuItem: {
          Icon: SnoozeClockIcon,
          text: "Snooze",
          onClick: (e) => {
            e.stopPropagation();
            amplitude.logEvent("view-snoozeConversationModal", {
              mode: "single",
              conversationIds: [conversation._id],
              channels: [conversation.platform],
            });
            setDropdownOpen(false);

            setConversationNeedingAction(conversation);
            setSnoozeModalOpen?.(true);
          },
        },
      },
      {
        show: !!(
          canCloseTicket &&
          conversation.status !==
            ConversationStatus.IN_PROGRESS.toLowerCase() &&
          team?.settings.support?.useInProgressStatus
        ),
        commandMenuItem: {
          Icon: StatusSvg,
          text: "Mark in progress",
          onClick: async (e) => {
            e.stopPropagation();
            setDropdownOpen(false);
            amplitude.logEvent("single-markInProgress", {
              mode: "single",
              conversationIds: [conversation._id],
              channels: [conversation.platform],
            });
            await updateConversation(client, conversation, {
              status: "in_progress",
            });
            refreshIgnoreResult();
          },
        },
      },
      {
        show: canCloseTicket,
        commandMenuItem: {
          Icon: MergeIcon,
          text: "Merge",
          onClick: (e) => {
            e.stopPropagation();
            amplitude.logEvent("view-mergeConversationModal", {
              mode: "single",
              conversationIds: [conversation._id],
              channels: [conversation.platform],
            });
            setDropdownOpen(false);
            setConversationNeedingAction(conversation);
            setMergeModalOpen(true);
          },
        },
      },
      {
        show: canArchiveTicket,
        commandMenuItem: {
          Icon: TrashIcon,
          text: "Delete",
          onClick: (e) => {
            e.stopPropagation();
            amplitude.logEvent("view-archiveConversationModal", {
              mode: "single",
              conversationIds: [conversation._id],
              channels: [conversation.platform],
            });
            setDropdownOpen(false);

            setConversationNeedingAction(conversation);
            setArchiveModalOpen(true);
          },
        },
      },
      {
        show: canEditAssignee,
        commandMenuItem: {
          Icon: UserPlusIcon,
          text: "Assign",
          onClick: (e) => {
            e.stopPropagation();
            amplitude.logEvent("view-assignConversationModal", {
              mode: "single",
              conversationIds: [conversation._id],
              channels: [conversation.platform],
            });
            setDropdownOpen(false);

            setConversationNeedingAction(conversation);
            setAssignModalOpen(true);
          },
        },
      },
      {
        show: canEditAssignee,
        commandMenuItem: {
          Icon: User01,
          text: "Assign to me",
          onClick: (e) => {
            e.stopPropagation();
            amplitude.logEvent("view-assignConversationModal", {
              mode: "single",
              conversationIds: [conversation._id],
              channels: [conversation.platform],
            });
            setDropdownOpen(false);
            setConversationNeedingAction(conversation);
            setShouldAssignToMe(true);
            setAssignModalOpen(true);
          },
        },
      },
      {
        show: canAssignTag,
        commandMenuItem: {
          Icon: TagIcon,
          text: "Edit tags",
          onClick: (e) => {
            e.stopPropagation();
            amplitude.logEvent(`view-tagDropdown`, {
              mode: "single",
              conversationIds: [conversation._id],
              channels: [conversation.platform],
            });
            setDropdownOpen(false);

            setConversationNeedingAction(conversation);
            setTagModalOpen(true);
          },
        },
      },
      {
        show:
          !inSpamView &&
          canCloseTicket &&
          conversation.platform === ConversationPlatform.EMAIL &&
          (conversation.tagIds?.some((tag) => tag.name === "spam") || true),
        commandMenuItem: {
          Icon: SpamIcon,
          text: "Mark spam",
          onClick: (e) => {
            e.stopPropagation();
            amplitude.logEvent("view-markSpamModal", {
              mode: "single",
              conversationIds: [conversation._id],
            });
            setDropdownOpen(false);
            setConversationNeedingAction(conversation);
            setMarkSpamModalOpen(true);
          },
        },
      },
      {
        show:
          !!inSpamView &&
          canCloseTicket &&
          conversation.platform === ConversationPlatform.EMAIL &&
          (conversation.tagIds?.some((tag) => tag.name === "spam") || false),
        commandMenuItem: {
          Icon: AnnotationCheck,
          text: "Unmark spam",
          onClick: (e) => {
            e.stopPropagation();
            amplitude.logEvent("view-unmarkSpamModal", {
              mode: "single",
              conversationIds: [conversation._id],
            });
            setDropdownOpen(false);
            setConversationNeedingAction(conversation);
            setUnmarkSpamModalOpen(true);
          },
        },
      },
      {
        show: canReply && conversation.platform === ConversationPlatform.EMAIL,
        commandMenuItem: {
          Icon: Edit04Icon,
          text: "Change subject",
          onClick: (e) => {
            e.stopPropagation();
            amplitude.logEvent("view-changeSubjectModal", {
              mode: "single",
              conversationIds: [conversation._id],
            });
            setDropdownOpen(false);
            handleChangeSubject(conversation);
          },
        },
      },
    ];
    return items;
  };

  const handleMarkedUnread = async (
    conversationToMark: ExpandedConversation | undefined,
  ) => {
    if (conversationToMark && team) {
      await markConversationAsUnread(
        client,
        conversationToMark,
        team,
        user._id,
      );
      setActiveConversation(undefined);
    }
  };

  const onConversationsViewed = async (conversation: ExpandedConversation) => {
    if (!team) {
      return;
    }
    return await markConversationAsRead(client, conversation, team, user._id);
  };

  const handleNavigateAfterCreate = async ({
    conversationId,
  }: {
    conversationId: string;
  }) => {
    amplitude.logEvent("create-conversation");
    refreshIgnoreResult();
    navigate(
      `/stores/${team?._id}/support/table/all?activeConversationId=${conversationId}`,
    );
  };

  const handleChangeSubject = (conversation: ExpandedConversation) => {
    setConversationNeedingAction(conversation);
    setChangeSubjectModalOpen(true);
  };

  const singleCleanupFn = (clearSelected?: boolean) => {
    refreshIgnoreResult();
    setConversationNeedingAction((prev) => {
      if (prev && clearSelected) {
        tableSelection.removeFromSelection([prev._id]);
      }
      return undefined;
    });
  };

  useEffect(() => {
    amplitude.logEvent("view-supportTable", { view });
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={conversationsTableCss.contentContainer}>
        <ConversationsTableHeader
          blockRefresh={blockRefresh}
          key={view.name}
          pageLoaded={pageLoaded}
          scrolled={scrolled}
          setActiveConversation={setActiveConversation}
          setBlockRefresh={setBlockRefresh}
        />
        <div
          className={conversationsTableCss.tableContainer}
          id={CONVERSATIONS_TABLE_ID}
          ref={scrollAreaRef}
        >
          <ConversationsTable
            dropdownActions={dropdownActions}
            externalSetSort={(newSort) => {
              /** If it's a system default view, save the sort direction in local storage */
              if (
                view.name &&
                (Object.values(SystemView) as string[]).includes(view.name)
              ) {
                localStorage.setItem(
                  `redo.support.lastResponse-sort-direction-view-${view.name}`,
                  newSort.direction,
                );
              }

              setFilters({ ...filters, sort: newSort });
            }}
            externalSort={filters.sort || undefined}
            fetcher={fetcher}
            filters={filters}
            handleCardClick={setActiveConversation}
            onConversationsViewed={onConversationsViewed}
            scrollAreaRef={scrollAreaRef}
            tableRef={tableRef}
            view={view}
          />
        </div>
      </div>
      <NewMessageModal
        handleNavigate={handleNavigateAfterCreate}
        open={createConversationModalOpen}
        setOpen={setCreateConversationModalOpen}
      />

      {closeModalOpen && conversationNeedingAction && (
        <CloseTicketModal
          cleanupFn={singleCleanupFn}
          conversations={[conversationNeedingAction]}
          inDetailView={false}
          open={closeModalOpen}
          setActiveConversation={setActiveConversation}
          setOpen={setCloseModalOpen}
        />
      )}
      {reopenModalOpen && conversationNeedingAction && (
        <ReopenTicketModal
          cleanupFn={singleCleanupFn}
          conversations={[conversationNeedingAction]}
          inDetailView={false}
          open={reopenModalOpen}
          setActiveConversation={setActiveConversation}
          setOpen={setReopenModalOpen}
        />
      )}
      {snoozeModalOpen && conversationNeedingAction && (
        <SnoozeModal
          cleanupFn={singleCleanupFn}
          conversations={[conversationNeedingAction]}
          inDetailView={false}
          open={snoozeModalOpen}
          setActiveConversation={setActiveConversation}
          setOpen={setSnoozeModalOpen}
        />
      )}
      {archiveModalOpen && conversationNeedingAction && (
        <ArchiveTicketModal
          cleanupFn={singleCleanupFn}
          conversations={[conversationNeedingAction]}
          inDetailView={false}
          open={archiveModalOpen}
          setActiveConversation={setActiveConversation}
          setOpen={setArchiveModalOpen}
        />
      )}
      {mergeModalOpen && conversationNeedingAction && (
        <MergeModal
          conversations={[conversationNeedingAction]}
          open={mergeModalOpen}
          setActiveConversation={setActiveConversation}
          setOpen={setMergeModalOpen}
        />
      )}
      {assignModalOpen && conversationNeedingAction && (
        <AssignTicketModal
          cleanupFn={singleCleanupFn}
          conversations={[conversationNeedingAction]}
          open={assignModalOpen}
          setOpen={setAssignModalOpen}
          setShouldAssignToMeImmediately={setShouldAssignToMe}
          shouldAssignToMeImmediately={shouldAssignToMe}
        />
      )}
      {tagModalOpen && conversationNeedingAction && (
        <TagsModal
          cleanupFn={singleCleanupFn}
          conversations={[conversationNeedingAction]}
          mode="add"
          open={tagModalOpen}
          setOpen={setTagModalOpen}
        />
      )}
      {markSpamModalOpen && conversationNeedingAction && (
        <MarkSpamModal
          cleanupFn={singleCleanupFn}
          conversations={[conversationNeedingAction]}
          inDetailView={false}
          open={markSpamModalOpen}
          setActiveConversation={setActiveConversation}
          setOpen={setMarkSpamModalOpen}
        />
      )}
      {unmarkSpamModalOpen && conversationNeedingAction && (
        <UnmarkSpamModal
          cleanupFn={singleCleanupFn}
          conversations={[conversationNeedingAction]}
          inDetailView={false}
          open={unmarkSpamModalOpen}
          setActiveConversation={setActiveConversation}
          setOpen={setUnmarkSpamModalOpen}
        />
      )}
      {changeSubjectModalOpen && (
        <ChangeSubjectModal
          conversation={conversationNeedingAction!}
          resolve={() => setChangeSubjectModalOpen(false)}
        />
      )}

      {actionsPortal &&
        canReply &&
        transitionState !== OverlapTransitionState.EXIT &&
        createPortal(
          <Button
            onClick={() => {
              amplitude.logEvent("view-createConversationModal");
              setCreateConversationModalOpen(true);
            }}
            size={ButtonSize.SMALL}
            theme={ButtonTheme.GHOST}
          >
            <div className={conversationsTableCss.actionButton}>
              <PlusIcon className={conversationsTableCss.icon} />
              Create new ticket
            </div>
          </Button>,
          actionsPortal,
        )}
    </>
  );
});
