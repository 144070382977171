import { RouteObject } from "react-router-dom";

export const trackingRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Tracking", breadcrumbNavigate: false },
    children: [
      {
        path: "about",
        async lazy() {
          const { AboutTracking } = await import("./about-tracking");
          return { element: <AboutTracking /> };
        },
      },
      {
        handle: { breadcrumb: "Discount flow" },
        path: "discount-flow",
        lazy: async () => {
          const { DiscountFlow } = await import(
            "../discount-flow/discount-flow"
          );
          return { element: <DiscountFlow /> };
        },
      },
      {
        handle: { breadcrumb: "Tracking Page" },
        path: "tracking-page",
        lazy: async () => {
          const { TrackingPage } = await import(
            "../order-tracking-settings/tracking-page/tracking-page"
          );
          return { Component: TrackingPage };
        },
      },
    ],
  },
];
