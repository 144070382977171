import { Schema } from "../type-system/schema";

export const numberComparisonOperators = [
  "lessThan",
  "greaterThan",
  "equalTo",
  "notEqualTo",
] as const;

export type NumberComparisonOperator =
  (typeof numberComparisonOperators)[number];

export const numberComparisonToLabel: Record<NumberComparisonOperator, string> =
  {
    ["lessThan"]: "less than",
    ["greaterThan"]: "greater than",
    ["equalTo"]: "equal to",
    ["notEqualTo"]: "not equal to",
  };

export interface NumberComparisonExpression {
  type: "number_comparison";
  field: keyof Schema["fields"];
  operator: NumberComparisonOperator;
  comparisonValue: number;
}
