import { RedoMerchantClient } from "@redotech/redo-merchant-app-common/client";

/**
 * GET /bigcommerce/collections
 */
export async function getBigcommerceCollections(
  client: RedoMerchantClient,
  {
    searchString,
    signal,
  }: { searchString: string | undefined; signal?: AbortSignal },
): Promise<{ id: number; name: string }[]> {
  const params = new URLSearchParams();
  if (searchString) {
    params.set("search", searchString);
  }
  const response = await client.client.get(`/bigcommerce/collections`, {
    params,
    signal,
    headers: client.authorization(),
  });
  return response.data;
}
