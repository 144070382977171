import { BillingPeriod } from "@redotech/redo-model/billing/service-invoice";
import { z } from "zod";

export type ServiceInvoiceStatus = z.infer<typeof serviceInvoiceStatus>;
export const serviceInvoiceStatus = z.object({
  period: z.nativeEnum(BillingPeriod),
  creditsForMonth: z.number(),
  creditsSpent: z.number(),
  billingDueDate: z.date(),
  estimatedInvoiceAmount: z.number(),
});

export const getCurrentMarketingEmailInvoice = {
  input: z.null(),
  output: serviceInvoiceStatus.nullable(),
};
