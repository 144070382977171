// extracted by mini-css-extract-plugin
export var disabled = "_4-6";
export var hierarchyLinkGray = "_4-a";
export var hierarchyLinkUnderline = "_4-9";
export var hierarchyPrimary = "_4-0";
export var hierarchySecondary = "_4-7";
export var hierarchyTertiary = "_4-8";
export var pressed = "_4-2";
export var themeAction = "_4-1";
export var themeDestructive = "_4-4";
export var themeNormal = "_4-3";
export var themeSuccess = "_4-5";