import { Redirect } from "@redotech/react-router-util/redirect";
import { RouteObject, matchPath } from "react-router-dom";
import { conciergeRoutes } from "../../../concierge/route";
import { customerRoutes } from "../../../customers/route";
import { ACTIVE_CONVERSATION_QUERY_PARAMETER } from "../../../support/query-parameters";
import { Support } from "../../../support/support";
import { Page } from "../../page";

const HIDE_PADDING_ROUTES: string[] = [
  "/table/*",
  "/customers/:customerId/*",
  "/concierge/conversations",
];

function hidePadding(url: string): boolean {
  return HIDE_PADDING_ROUTES.some(
    (route) => !!matchPath(`/stores/:storeId/support${route}`, url),
  );
}

const FULLSCREEN_ROUTES: string[] = ["/concierge/onboard"];

const HIDE_HEADER_ROUTES: string[] = ["/about", ...FULLSCREEN_ROUTES];
function hideHeader(url: string, { search }: { search: string }): boolean {
  if (search.includes(ACTIVE_CONVERSATION_QUERY_PARAMETER)) {
    return true;
  }

  return HIDE_HEADER_ROUTES.some(
    (route) => !!matchPath(`/stores/:storeId/support${route}`, url),
  );
}

const HIDE_NAV_ROUTES: string[] = [...FULLSCREEN_ROUTES];

function hideNav(url: string): boolean {
  return HIDE_NAV_ROUTES.some(
    (route) => !!matchPath(`/stores/:storeId/support${route}`, url),
  );
}

export const SUPPORT_VIEW_NAME = "Support View";

export const supportRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Support", breadcrumbNavigate: false },
    async lazy() {
      const { Page } = await import("../../page");
      return {
        element: (
          <Page
            hideHeader={hideHeader}
            hideNavbar={hideNav}
            hidePadding={hidePadding}
          />
        ),
      };
    },
    children: [
      {
        path: "about",
        async lazy() {
          const { AboutSupport } = await import(
            "../../../support/about-support"
          );
          return { element: <AboutSupport /> };
        },
      },
      {
        path: "setup",
        async lazy() {
          const { StartSupportTrial } = await import(
            "../../../support/support-setup/start-support-trial"
          );
          return { element: <StartSupportTrial /> };
        },
      },
      { path: "customers", children: customerRoutes },
      { path: "concierge", children: conciergeRoutes },
      {
        handle: { breadcrumb: SUPPORT_VIEW_NAME },
        path: "*",
        element: <Support />,
      },
    ],
  },
];

export const backwardsCompatibleSupportRoutes: RouteObject[] = [
  {
    path: "customers",
    children: [
      {
        element: <Page />,
        children: [
          {
            index: true,
            element: (
              <Redirect match={/customers/} replaceValue="support/customers" />
            ),
          },
          {
            path: "*",
            element: (
              <Redirect match={/customers/} replaceValue="support/customers" />
            ),
          },
        ],
      },
    ],
  },
  {
    path: "concierge",
    children: [
      {
        element: <Page />,
        children: [
          {
            index: true,
            element: (
              <Redirect match={/concierge/} replaceValue="support/concierge" />
            ),
          },
          {
            path: "*",
            element: (
              <Redirect match={/concierge/} replaceValue="support/concierge" />
            ),
          },
        ],
      },
    ],
  },
];
