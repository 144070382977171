import { assertNever } from "@redotech/util/type";
import { BaseStep, StepType } from "../common";
import { NumberComparisonOperator } from "../expressions/number-expression";
import { StringMatchOperator } from "../expressions/text-expression";

export enum OmsConditionType {
  ORDER_TAG = "ORDER_TAG",
  SELECTED_SHIPPING = "SELECTED_SHIPPING",
  SKU = "SKU",
  PRODUCT_NAME = "PRODUCT_NAME",
  ITEM_QUANTITY = "ITEM_QUANTITY",
  PACKAGE_WEIGHT_OZ = "PACKAGE_WEIGHT",
  TOTAL_VALUE = "TOTAL_VALUE",
  DESTINATION_COUNTRY = "DESTINATION_COUNTRY",
  DESTINATION_PROVINCE = "DESTINATION_PROVINCE",
  DELIVERY_METHOD = "DELIVERY_METHOD",
  STREET_ADDRESS = "STREET_ADDRESS",
  SALES_CHANNEL = "SALES_CHANNEL",
  PRODUCT_TAG = "PRODUCT_TAG",
}

export const getConditionTypeLabel = (conditionType: OmsConditionType) => {
  switch (conditionType) {
    case OmsConditionType.ORDER_TAG:
      return "Order tags";
    case OmsConditionType.SELECTED_SHIPPING:
      return "Requested shipping service";
    case OmsConditionType.SKU:
      return "Product SKUs";
    case OmsConditionType.PRODUCT_NAME:
      return "Product names";
    case OmsConditionType.ITEM_QUANTITY:
      return "Item quantity";
    case OmsConditionType.PACKAGE_WEIGHT_OZ:
      return "Parcel weight";
    case OmsConditionType.TOTAL_VALUE:
      return "Total value";
    case OmsConditionType.DESTINATION_COUNTRY:
      return "Destination country";
    case OmsConditionType.DESTINATION_PROVINCE:
      return "Destination state/province";
    case OmsConditionType.DELIVERY_METHOD:
      return "Delivery method";
    case OmsConditionType.STREET_ADDRESS:
      return "Street address";
    case OmsConditionType.SALES_CHANNEL:
      return "Sales channel";
    case OmsConditionType.PRODUCT_TAG:
      return "Product tags";
    default:
      assertNever(conditionType);
  }
};

export enum ListToListMatchType {
  INCLUDES_ANY_OF = "INCLUDES_ANY_OF",
  INCLUDES_ALL_OF = "INCLUDES_ALL_OF",
  HAS_NONE_OF = "HAS_NONE_OF",
}

export enum StringToListMatchType {
  IS_ANY_OF = "IS_ANY_OF",
  IS_NONE_OF = "IS_NONE_OF",
}

export const stringToListMatchTypeToLabel: Record<
  StringToListMatchType,
  string
> = {
  [StringToListMatchType.IS_ANY_OF]: "is any of",
  [StringToListMatchType.IS_NONE_OF]: "is none of",
};

/**
 * Ex: Order tags include any of
 */
export const listToListMatchTypeToLabel: Record<ListToListMatchType, string> = {
  [ListToListMatchType.INCLUDES_ANY_OF]: "include any of",
  [ListToListMatchType.INCLUDES_ALL_OF]: "include all of",
  [ListToListMatchType.HAS_NONE_OF]: "have none of",
};

/**
 * Ex: Order tag list includes any of
 */
export const listToListMatchTypeToPluralLabel: Record<
  ListToListMatchType,
  string
> = {
  [ListToListMatchType.INCLUDES_ANY_OF]: "includes any of",
  [ListToListMatchType.INCLUDES_ALL_OF]: "includes all of",
  [ListToListMatchType.HAS_NONE_OF]: "has none of",
};

export const getMatchTypeLabel = (
  matchType: StringToListMatchType | ListToListMatchType,
  isPlural: boolean,
) => {
  if (matchType in StringToListMatchType) {
    return stringToListMatchTypeToLabel[matchType as StringToListMatchType];
  }
  if (isPlural) {
    return listToListMatchTypeToPluralLabel[matchType as ListToListMatchType];
  }
  return listToListMatchTypeToLabel[matchType as ListToListMatchType];
};

export const stringMatchOperatorToLabel: Record<StringMatchOperator, string> = {
  startsWith: "starts with",
  endsWith: "ends with",
  includes: "includes",
  equals: "equals",
};

// search-tag:ADDING_AN_OMS_CONDITION
export interface BaseOmsConditionStep extends BaseStep {
  type: StepType.OMS_CONDITION;
  conditionType: OmsConditionType;
  nextTrueId: string;
  nextFalseId: string;
}

export interface OmsOrderTagConditionStep extends BaseOmsConditionStep {
  conditionType: OmsConditionType.ORDER_TAG;
  matchType?: ListToListMatchType;
  matchValues: string[];
  requireExactMatch?: boolean;
}

export interface OmsSelectedShippingConditionStep extends BaseOmsConditionStep {
  conditionType: OmsConditionType.SELECTED_SHIPPING;
  matchType?: ListToListMatchType;
  matchValues: string[];
  requireExactMatch?: boolean;
}

export interface OmsSkuConditionStep extends BaseOmsConditionStep {
  conditionType: OmsConditionType.SKU;
  matchType?: ListToListMatchType;
  matchValues: string[];
  requireExactMatch?: boolean;
}

export interface OmsProductNameConditionStep extends BaseOmsConditionStep {
  conditionType: OmsConditionType.PRODUCT_NAME;
  matchType?: ListToListMatchType;
  matchValues: string[];
  requireExactMatch?: boolean;
}

export interface OmsItemQuantityConditionStep extends BaseOmsConditionStep {
  conditionType: OmsConditionType.ITEM_QUANTITY;
  matchType?: NumberComparisonOperator;
  matchValue: number;
}

export interface OmsPackageWeightOzConditionStep extends BaseOmsConditionStep {
  conditionType: OmsConditionType.PACKAGE_WEIGHT_OZ;
  matchType?: NumberComparisonOperator;
  matchValue: number;
}

export interface OmsTotalValueConditionStep extends BaseOmsConditionStep {
  conditionType: OmsConditionType.TOTAL_VALUE;
  matchType?: NumberComparisonOperator;
  matchValue: number;
}

export interface OmsDestinationCountryConditionStep
  extends BaseOmsConditionStep {
  conditionType: OmsConditionType.DESTINATION_COUNTRY;
  matchType?: StringToListMatchType;
  matchValues: string[];
}

export interface OmsDestinationProvinceConditionStep
  extends BaseOmsConditionStep {
  conditionType: OmsConditionType.DESTINATION_PROVINCE;
  matchType?: StringToListMatchType;
  matchValues: string[];
}

export interface OmsDeliveryMethodConditionStep extends BaseOmsConditionStep {
  conditionType: OmsConditionType.DELIVERY_METHOD;
  matchType?: StringToListMatchType;
  matchValues: string[];
}

export interface OmsStreetAddressConditionStep extends BaseOmsConditionStep {
  conditionType: OmsConditionType.STREET_ADDRESS;
  matchType?: StringMatchOperator;
  matchValue: string;
}

export interface OmsSalesChannelConditionStep extends BaseOmsConditionStep {
  conditionType: OmsConditionType.SALES_CHANNEL;
  matchType?: StringToListMatchType;
  matchValues: string[];
}

export interface OmsProductTagConditionStep extends BaseOmsConditionStep {
  conditionType: OmsConditionType.PRODUCT_TAG;
  matchType?: ListToListMatchType;
  matchValues: string[];
  requireExactMatch?: boolean;
}
