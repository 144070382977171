// extracted by mini-css-extract-plugin
export var colorSquare = "_da-0";
export var danger = "_da-5";
export var lightPink = "_da-8";
export var neutralBlue = "_da-7";
export var neutralYellow = "_da-6";
export var normal = "_da-1";
export var primary = "_da-2";
export var primaryLight = "_da-3";
export var solidBlack = "_da-g";
export var solidGreen = "_da-b";
export var solidLightBlue = "_da-d";
export var solidNavy = "_da-c";
export var solidOrange = "_da-f";
export var solidPink = "_da-e";
export var solidRed = "_da-9";
export var solidYellow = "_da-a";
export var success = "_da-4";