import { useExpandHeight } from "@redotech/react-animation/transition";
import { useHover } from "@redotech/react-util/hover";
import TriangleDown from "@redotech/redo-web/arbiter-icon/triangle-down_filled.svg";
import { AllOrNone } from "@redotech/util/type";
import * as classNames from "classnames";
import {
  JSXElementConstructor,
  memo,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { useMatch } from "react-router-dom";
import DotsReorderIcon from "../../../arbiter-icon/dots-reorder.svg";
import LockIcon from "../../../arbiter-icon/lock-01.svg";
import { Flex } from "../../../flex";
import { Text } from "../../../text";
import { TextWeightValue } from "../../../theme/typography";
import { OverflowTooltip } from "../../../tooltip/overflow-tooltip";
import { RedoBadge } from "../../badge/redo-badge";
import { RedoIcon } from "../../icon/redo-icon";
import { NavIconSwap } from "../common/redo-nav-icon-swap";
import * as navigationCss from "../redo-navigation.module.css";

export type SecondaryNavItemProps = {
  friendlyName: string;
  id: string;
  notificationCount?: number;
  beta?: boolean;
  isNew?: boolean;
  isPrivate?: boolean;
  action:
    | { type: "link"; href: string }
    | { type: "button"; onClick?: () => void };
  dragHandleProps?: object;
  isFolder?: boolean;
} & AllOrNone<{
  UnfilledIcon: JSXElementConstructor<any>;
  FilledIcon: JSXElementConstructor<any>;
}> &
  AllOrNone<{ anyChildSelected: boolean; children?: ReactNode[] | ReactNode }>;

export const RedoSecondaryNavItem = memo(function RedoSecondaryNavItem({
  friendlyName,
  action,
  notificationCount,
  beta,
  isNew,
  id,
  UnfilledIcon,
  FilledIcon,
  isPrivate,
  children,
  anyChildSelected,
  dragHandleProps,
  isFolder = false,
}: SecondaryNavItemProps) {
  const [expanded, setExpanded] = useState<boolean>(false);

  const hasChildren =
    !!children && (!Array.isArray(children) || children.length > 0);

  useEffect(() => {
    if (anyChildSelected) {
      setExpanded(true);
    }
  }, [anyChildSelected]);

  const path = action.type === "link" ? action.href : "";
  const selfMatch = useMatch({
    path: hasChildren ? path : `${path}/*`,
    caseSensitive: true,
  });

  const active = !!path && !!selfMatch && !hasChildren;

  const color: "primary" | "secondary" = active ? "primary" : "secondary";
  const textWeight: TextWeightValue = active ? "semibold" : "regular";

  const isDropdown = hasChildren;

  const as = action.type === "link" ? "a" : "button";
  const linkProps = action.type === "link" ? { to: action.href } : undefined;

  const [itemRef, setItemRef] = useState<HTMLDivElement | null>(null);

  const hovered = useHover(itemRef);

  const [overflowRef, setOverflowRef] = useState<HTMLDivElement | null>(null);

  return (
    <Flex data-testid={id} dir="column" gap="none">
      <Flex
        as={as}
        className={classNames(
          navigationCss.navItem,
          !!active && navigationCss.active,
        )}
        justify="space-between"
        linkProps={linkProps}
        onClick={() => {
          if (action.type === "button") {
            setExpanded(!expanded);
            action.onClick && action.onClick();
          }
        }}
        px="md"
        py="sm"
        radius="sm"
        ref={setItemRef}
      >
        <OverflowTooltip
          overflowRef={overflowRef}
          tooltipProps={{ title: friendlyName, darkMode: true }}
          wrapperStyle={{
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
          }}
        >
          <>
            {UnfilledIcon && (
              <NavIconSwap
                FilledIcon={FilledIcon}
                isSelected={active || (isFolder && expanded)}
                size={16}
                UnfilledIcon={UnfilledIcon}
              />
            )}
            <Text
              fontSize="xs"
              fontWeight={textWeight}
              overflow="hidden"
              ref={setOverflowRef}
              textColor={color}
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {friendlyName}
            </Text>
            {isPrivate && (
              <RedoIcon color="tertiary" Icon={LockIcon} size={12} />
            )}
          </>
        </OverflowTooltip>
        {dragHandleProps && (
          <Flex
            align="center"
            className={classNames(
              hovered && navigationCss.show,
              navigationCss.dragHandle,
            )}
            {...dragHandleProps}
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <RedoIcon color="tertiary" Icon={DotsReorderIcon} size={16} />
          </Flex>
        )}
        <Flex align="center" gap="xs">
          {notificationCount !== undefined && (
            <RedoBadge
              color={notificationCount > 0 ? "error" : "black"}
              size="xs"
              text={notificationCount.toString()}
              type="alternate"
            />
          )}
          {beta && <RedoBadge color="purple" size="xs" text="Beta" />}
          {isNew && <RedoBadge color="white" size="xs" text="New" />}
          {isDropdown && (
            <Flex
              onClick={(e) => {
                e.preventDefault();
                setExpanded(!expanded);
              }}
            >
              <RedoIcon
                className={classNames(
                  navigationCss.dropdownIcon,
                  expanded && navigationCss.active,
                )}
                color="tertiary"
                Icon={TriangleDown}
                size={16}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
      <RedoNavItemChildren expanded={expanded}>{children}</RedoNavItemChildren>
    </Flex>
  );
});

const RedoNavItemChildren = memo(function RedoNavItemChildren({
  children,
  expanded,
}: {
  children: ReactNode[] | ReactNode | undefined;
  expanded: boolean;
}) {
  const [optionsContainer, setOptionsContainer] =
    useState<HTMLDivElement | null>(null);
  const [animateStyles, animating] = useExpandHeight(
    expanded,
    optionsContainer,
  );

  const hasChildren =
    !!children && (!Array.isArray(children) || children.length > 0);

  if (!hasChildren) {
    return null;
  }

  return (
    <Flex
      className={navigationCss.optionsContainer}
      dir="column"
      gap="xs"
      pl="3xl"
      pt={expanded ? "xs" : undefined}
      ref={setOptionsContainer}
      style={animateStyles}
    >
      {hasChildren && (animating || expanded) && children}
    </Flex>
  );
});
