import { z } from "zod";
import {
  AdvancedFilterType,
  createAdvancedFilterDataSchema,
  GenericFilterBuilder,
} from "./generic-advanced-filter-data";

export enum ExistsFilterOperator {
  EXISTS = "exists",
  NOT_EXISTS = "not_exists",
}
const ExistsFilterOperatorSchema = z.nativeEnum(ExistsFilterOperator);

const ExistsFilterValueSchema = z.boolean();

export const ExistsFilterDataSchema = createAdvancedFilterDataSchema(
  AdvancedFilterType.EXISTS,
  ExistsFilterValueSchema,
  ExistsFilterOperatorSchema,
);
export type ExistsFilterData = z.infer<typeof ExistsFilterDataSchema>;

export const ExistsFilterBuilder: GenericFilterBuilder<
  boolean,
  ExistsFilterOperator,
  ExistsFilterData
> = {
  type: AdvancedFilterType.EXISTS,
  valueSchema: ExistsFilterValueSchema,
  operatorSchema: ExistsFilterOperatorSchema,
  schema: ExistsFilterDataSchema,
  buildAtlasSearchQuery: ({
    filter,
    atlasPath,
    searchCompound,
    useObjectId = false,
  }) => {
    const { value } = filter;
    if (value === null || value === undefined) {
      return searchCompound;
    }

    if (filter.operator === ExistsFilterOperator.EXISTS) {
      if (value) {
        searchCompound.filter.push({ exists: { path: atlasPath } });
      } else if (!value) {
        searchCompound.mustNot.push({ exists: { path: atlasPath } });
      }
    }
    if (filter.operator === ExistsFilterOperator.NOT_EXISTS) {
      if (value) {
        searchCompound.mustNot.push({ exists: { path: atlasPath } });
      } else if (!value) {
        searchCompound.filter.push({ exists: { path: atlasPath } });
      }
    }
    return searchCompound;
  },
  readFromString: (name, operatorValueEncoded, defaultFilter) => {
    try {
      const decoded = decodeURIComponent(operatorValueEncoded);
      const operatorValue = decoded.split("::");

      const operator = operatorValue?.[0];
      const value = operatorValue?.[1];

      if (!operator) {
        return defaultFilter;
      }

      const parsedOperator = ExistsFilterOperatorSchema.safeParse(operator);
      if (!parsedOperator.success) {
        console.warn("Invalid operator type");
        return undefined;
      }

      if (value === undefined || value === null) {
        return {
          type: AdvancedFilterType.EXISTS,
          name,
          value: null,
          operator: parsedOperator.data,
        };
      }

      const valueParsed = ExistsFilterValueSchema.safeParse(
        value.trim() === "true",
      );

      if (!valueParsed.success) {
        console.warn("Invalid value type for ExistsFilter");
        return undefined;
      }

      return {
        type: AdvancedFilterType.EXISTS,
        name,
        value: valueParsed.data,
        operator: parsedOperator.data,
      };
    } catch (error) {
      console.error("Error parsing ExistsFilter", error);
      return undefined;
    }
  },
  writeToString: (filter: ExistsFilterData | undefined) => {
    try {
      if (!filter) {
        return "";
      }
      const { operator, value } = filter;
      const parsedValue = ExistsFilterValueSchema.safeParse(value);

      if (parsedValue.success) {
        return `${operator}::${parsedValue.data}`;
      } else {
        return `${operator}::`;
      }
    } catch (error) {
      console.error("Error writing ExistsFilter", error);
      return "";
    }
  },
  isPartial: (filter) => {
    return !filter.operator || filter.value == null;
  },
};
