import { RedoMerchantClient } from "@redotech/redo-merchant-app-common/client";
import { CreateCustomerTagBody } from "@redotech/redo-model/customer-tags/create-customer-tag-body";
import { CustomerTagRecord } from "@redotech/redo-model/customer-tags/customer-tag-record";
import { PatchCustomerTagBody } from "@redotech/redo-model/customer-tags/patch-customer-tag-body";

export async function getCustomerTagsOfTeam(
  client: RedoMerchantClient,
  { teamId }: { teamId: string },
  signal?: AbortSignal,
): Promise<CustomerTagRecord[]> {
  const response = await client.client.get(
    `customer-tags/teams/${teamId}/customer-tags`,
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * @param reloadCallback forces the caller to think about what needs reloading after updating a tag
 */
export async function patchCustomerTag(
  client: RedoMerchantClient,
  tagId: string,
  body: PatchCustomerTagBody,
  reloadCallback: () => void | Promise<void>,
  signal?: AbortSignal,
): Promise<CustomerTagRecord> {
  const response = await client.client.patch(`customer-tags/${tagId}`, body, {
    headers: client.authorization(),
    signal,
  });
  await reloadCallback();
  return response.data;
}

/**
 * @param reloadCallback forces the caller to think about what needs reloading after making a tag
 */
export async function postCustomerTag(
  client: RedoMerchantClient,
  body: CreateCustomerTagBody,
  reloadCallback: () => void | Promise<void>,
  signal?: AbortSignal,
): Promise<CustomerTagRecord> {
  const response = await client.client.post(`customer-tags`, body, {
    headers: client.authorization(),
    signal,
  });
  await reloadCallback();
  return response.data;
}
