// extracted by mini-css-extract-plugin
export var brand = "_51-7";
export var compact = "_51-5";
export var extraSmall = "_51-a";
export var icon = "_51-0";
export var large = "_51-3";
export var light = "_51-1";
export var medium = "_51-4";
export var outline = "_51-9";
export var primaryDark = "_51-8";
export var primaryLight = "_51-6";
export var primaryModern = "_51-b";
export var success = "_51-2";