import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";
import { customerGroupSchema } from "../customers/customer-group-definition";
import { EmailTemplate, emailTemplateSchema } from "../email-template";
import { MarketingChannel } from "../marketing";
import { smsTemplateSchema } from "../sms-template";

export const followUpEngagementPeriodSchema = z.enum(["30", "60", "90"]);
export type FollowUpEngagementPeriod = z.infer<
  typeof followUpEngagementPeriodSchema
>;

export enum CampaignErrorCode {
  INTERNAL_ERROR = "internal_error",
  DAILY_USAGE_LIMIT_EXCEEDED = "daily_usage_limit_exceeded",
}

export const followUpConfigSchema = z.object({
  enabled: z.boolean(),
  subjectLine: z.string(),
  engagementPeriod: followUpEngagementPeriodSchema,
  delayMs: z.number().int().min(0),
  originalCampaignId: zExt.objectId().optional(),
});
export type FollowUpConfig = z.infer<typeof followUpConfigSchema>;

export const campaignSchema = z.object({
  _id: zExt.objectId(),
  teamId: zExt.objectId(),
  name: z.string(),
  channel: z.nativeEnum(MarketingChannel),
  // this cast prevents error TS7056: The inferred type of this node exceeds the maximum length the compiler will serialize. An explicit type annotation is needed.
  emailTemplate: (emailTemplateSchema as z.ZodType<EmailTemplate>).optional(),
  smsTemplate: smsTemplateSchema.optional(),
  customerGroupIds: z.array(zExt.objectId()),
  scheduledAt: z.date().optional().nullable(),
  finishedAt: z.date().optional().nullable(),
  failedAt: z.date().optional().nullable(),
  errorCode: z.nativeEnum(CampaignErrorCode).optional(),
  followUpConfig: followUpConfigSchema.optional(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export const outreachAnalyticsSchema = z.object({
  sends: z.number(),
  deliveries: z.number(),
  opens: z.number(),
  uniqueOpens: z.number(),
  clicks: z.number(),
  uniqueClicks: z.number(),
  upsellCount: z.number(),
  upsellRevenue: z.number(),
});

export const campaignWithOutreachAnalyticsSchema = campaignSchema.extend({
  stats: outreachAnalyticsSchema.optional(),
});

export const campaignWithCustomerGroupsSchema = campaignSchema.extend({
  customerGroups: z.array(customerGroupSchema).optional(),
});

export type Campaign = z.infer<typeof campaignSchema>;
export type CampaignWithCustomerGroups = z.infer<
  typeof campaignWithCustomerGroupsSchema
>;
export type OutreachAnalytics = z.infer<typeof outreachAnalyticsSchema>;
export type CampaignWithOutreachAnalytics = z.infer<
  typeof campaignWithOutreachAnalyticsSchema
>;

const emailCampaignCreationParametersSchema = z.object({
  channel: z.literal(MarketingChannel.EMAIL),
  emailTemplate: z.object({
    subjectLine: z.string(),
    emailPreview: z.string(),
  }),
  name: z.string(),
});

const smsCampaignCreationParametersSchema = z.object({
  channel: z.literal(MarketingChannel.SMS),
  name: z.string(),
});

export const campaignCreationParametersSchema = z.discriminatedUnion(
  "channel",
  [emailCampaignCreationParametersSchema, smsCampaignCreationParametersSchema],
);

export type CampaignCreationParameters = z.infer<
  typeof campaignCreationParametersSchema
>;

export const campaignUpdateSchema = z.object({
  id: z.string(),
  fields: campaignSchema
    .omit({ _id: true, createdAt: true, updatedAt: true })
    .partial(),
});
export type CampaignUpdate = z.infer<typeof campaignUpdateSchema>;

export enum CampaignFilter {
  Status = "Status",
  Channel = "Channel",
}

export enum CampaignStatus {
  DRAFT = "draft",
  SCHEDULED = "scheduled",
  PENDING = "pending",
  FINISHED = "finished",
  FAILED = "failed",
}

export enum CampaignChannel {
  Email = "email",
  SMS = "sms",
}

export enum CampaignSortKey {
  Name = "name",
  ScheduledAt = "scheduledAt",
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
}

export function getCampaignStatus(
  campaign: CampaignWithOutreachAnalytics,
): CampaignStatus {
  const isFailed = campaign.failedAt && campaign.failedAt < new Date();
  // if it's failed, check if it's within the last 24 hours for internal errors
  // if it's that soon, keep in pending state so we can reprocess manually
  if (isFailed) {
    const isInternalError =
      !campaign.errorCode ||
      campaign.errorCode === CampaignErrorCode.INTERNAL_ERROR;
    if (isInternalError) {
      const failedTime = campaign.failedAt?.getTime() ?? 0;
      const twentyFourHoursAgo = Date.now() - 24 * 60 * 60 * 1000;

      if (failedTime > twentyFourHoursAgo) {
        return CampaignStatus.PENDING;
      }
    }
    return CampaignStatus.FAILED;
  }
  const isFinished = campaign.finishedAt && campaign.finishedAt < new Date();
  if (isFinished) {
    return CampaignStatus.FINISHED;
  }
  const isPending =
    campaign.scheduledAt &&
    campaign.scheduledAt < new Date() &&
    !isFinished &&
    !isFailed;
  if (isPending) {
    return CampaignStatus.PENDING;
  }
  const isScheduled = campaign.scheduledAt && campaign.scheduledAt > new Date();
  if (isScheduled) {
    return CampaignStatus.SCHEDULED;
  }
  if (!isFinished && !isPending && !isScheduled && !isFailed) {
    return CampaignStatus.DRAFT;
  }
  return CampaignStatus.DRAFT;
}
