import { RouteObject } from "react-router-dom";

export const customerRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Customers" },
    children: [
      {
        index: true,
        async lazy() {
          const { Customers } = await import("./customers");
          return { element: <Customers /> };
        },
      },
      {
        path: "about",
        async lazy() {
          const { AboutCustomerAccounts } = await import(
            "./about-customer-accounts"
          );
          return { element: <AboutCustomerAccounts /> };
        },
      },
      {
        handle: { breadcrumb: "Detail" },
        path: ":customerId",
        children: [
          {
            index: true,
            async lazy() {
              const { CustomerDetail } = await import(
                "./customer-detail/customer-detail"
              );
              return { element: <CustomerDetail /> };
            },
          },
        ],
      },
    ],
  },
];
