import { z } from "zod";
import { makeReportDateFilterSchema } from "./base-report-date-filter";
import { ReportFilterType } from "./report-filter-type";

export const OrderCreatedDateFilterSchema = makeReportDateFilterSchema(
  ReportFilterType.ORDER_CREATED_DATE,
);

export type OrderCreatedDateFilter = z.infer<
  typeof OrderCreatedDateFilterSchema
>;
