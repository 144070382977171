import { z } from "zod";
import { zExt } from "../../common/zod-util";
import { MarketingChannel } from "../../marketing";

export enum MarketingSuppressionScope {
  TEAM = "team",
  GLOBAL = "global",
}
export enum MarketingSuppressionReason {
  PERMANENT_ERROR = "permanent_error",
  TEMPORARY_ERROR = "temporary_error",
  INVALID_CHANNEL_ID = "invalid_channel_id",
  MERCHANT = "merchant",
}
export const GLOBAL_SUPPRESSION_REASONS = [
  MarketingSuppressionReason.PERMANENT_ERROR,
  MarketingSuppressionReason.INVALID_CHANNEL_ID,
] as const;

const marketingSuppression = z.object({
  teamId: zExt.objectId(),
  channel: z.nativeEnum(MarketingChannel),
  channelId: z.string(),
  reason: z.nativeEnum(MarketingSuppressionReason),
  count: z.number(),
});

export type MarketingSuppression = z.infer<typeof marketingSuppression>;
