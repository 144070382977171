import { useHandler } from "@redotech/react-util/hook";
import { FlowType, Step as ModelStep } from "@redotech/redo-model/return-flow";

import { UserContext } from "@redotech/redo-merchant-app-common/user";
import {
  ActionType,
  SetStatusActionStatus,
} from "@redotech/redo-model/return-flow/action";
import { Permission, permitted } from "@redotech/redo-model/user";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  IconButton,
} from "@redotech/redo-web/button";
import { Divider } from "@redotech/redo-web/divider";
import { BlockLayout } from "@redotech/redo-web/flowchart";
import StarIcon from "@redotech/redo-web/icon-old/star.svg";
import TrashIcon from "@redotech/redo-web/icon-old/trash.svg";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { SelectDropdown } from "@redotech/redo-web/select-dropdown";
import { produce } from "immer";
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  memo,
  useContext,
} from "react";
import {
  StepDownstream,
  StepId,
  StepType,
  StepTypeDetailsProps,
} from "../return-flow/step";
import { ADD_TAG } from "./add-tag";
import { ASSIGN } from "./assign";
import { AUTO_REPLY } from "./auto-reply";
import * as multipleStepsCss from "./multiple-steps.module.css";
import { SET_STATUS } from "./set-status";

export interface ActionTypeSetup<T, M> {
  readonly empty: T;
  name: string;
  Details(props: {
    state: T;
    setState: Dispatch<SetStateAction<T>>;
    disabled?: boolean;
    flowType: FlowType;
  }): ReactElement | null;
  description(state: T): string;
  valid(state: T): boolean;
  fromModel(model: M): T;
  toModel(state: T): M;
}

interface State {
  customTitle?: string;
  actions: ActionState[];
  next: StepId | undefined;
}

interface ActionState {
  actionTypeSetup: ActionTypeSetup<any, any> | undefined;
  actionState?: any;
  customTitle?: string;
}

const Details = memo(function Details({
  stepSelect,
  state,
  setState,
  flowType,
}: StepTypeDetailsProps<State>) {
  const handleAddAction = () => {
    setState((oldState) => {
      return {
        ...oldState,
        actions: [
          ...oldState.actions,
          {
            actionTypeSetup: SET_STATUS,
            actionState: SET_STATUS.fromModel({
              type: ActionType.SetStatus,
              status: SetStatusActionStatus.CLOSED,
            }),
          },
        ],
      };
    });
  };
  const handleDeleteAction = (index: number) => {
    setState((oldState) => {
      return {
        ...oldState,
        actions: oldState.actions.filter((_, ind) => ind !== index),
      };
    });
  };
  return (
    <>
      {state.actions.map((action, index) => (
        <>
          <Divider />
          <ActionDetails
            flowType={flowType}
            onDeleteChild={() => handleDeleteAction(index)}
            setState={(updateSingleAction: SetStateAction<ActionState>) => {
              let updatedAction: ActionState;
              if (typeof updateSingleAction === "function") {
                const updateActionState = updateSingleAction(action);
                if (typeof updateActionState.actionState === "function") {
                  const updatedActionState = updateActionState.actionState(
                    action.actionState,
                  );
                  updatedAction = {
                    ...updateActionState,
                    actionState: updatedActionState,
                  };
                } else {
                  updatedAction = updateActionState;
                }
              } else {
                updatedAction = updateSingleAction;
              }
              setState((oldState) => {
                return {
                  ...oldState,
                  actions: oldState.actions.map((act, ind) => {
                    if (index === ind) {
                      return updatedAction;
                    } else {
                      return act;
                    }
                  }),
                };
              });
            }}
            state={action}
            stepSelect={stepSelect}
          />
        </>
      ))}
      <LabeledInput
        errors={
          state.actions.length === 0 ? ["At least one action is required"] : []
        }
        label=""
      >
        <Button
          onClick={handleAddAction}
          size={ButtonSize.MEDIUM}
          theme={ButtonTheme.PRIMARY}
        >
          + Add action
        </Button>
      </LabeledInput>
      <LabeledInput errors={[]} label="Next step">
        {stepSelect({
          value: state.next,
          valueChange: (next) => setState((state) => ({ ...state, next })),
        })}
      </LabeledInput>
    </>
  );
});

const options: ActionTypeSetup<any, any>[] = [
  SET_STATUS,
  ADD_TAG,
  AUTO_REPLY,
  ASSIGN,
];

const ActionDetails = memo(function ActionDetails({
  stepSelect,
  state,
  setState,
  flowType,
  onDeleteChild,
}: StepTypeDetailsProps<ActionState>) {
  const user = useContext(UserContext);
  const canEditSettings =
    !!user && permitted(user.permissions, Permission.EDIT_SETTINGS);
  const setActionState = useHandler((actionState: any) => {
    setState((state) => ({ ...state, actionState }));
  });
  const handleType = useHandler(
    async (actionType: ActionTypeSetup<any, any>) => {
      setState((state) => ({
        ...state,
        actionTypeSetup: actionType,
        actionState: actionType!.empty,
      }));
    },
  );
  return (
    <>
      <LabeledInput
        label={
          <div className={multipleStepsCss.labelWrapper}>
            <p>Action</p>{" "}
            <IconButton onClick={onDeleteChild} size={ButtonSize.SMALL}>
              <TrashIcon />
            </IconButton>
          </div>
        }
      >
        <SelectDropdown
          disabled={!canEditSettings}
          options={options}
          value={state.actionTypeSetup}
          valueChange={handleType}
        >
          {(action) => action.name}
        </SelectDropdown>
      </LabeledInput>
      {state.actionTypeSetup && (
        <state.actionTypeSetup.Details
          disabled={!canEditSettings}
          flowType={flowType}
          setState={setActionState}
          state={state.actionState}
        />
      )}
    </>
  );
});

export const MULTIPLE_ACTIONS: StepType<
  State,
  { actions: ModelStep.Action[]; next?: number }
> = {
  Details,
  customTitle(state) {
    return state.customTitle;
  },
  Icon: StarIcon,
  description(state) {
    return state.actions
      .map((action) => {
        return action.actionTypeSetup?.name || "One or more actions";
      })
      .join(", ");
  },
  downstream(state) {
    const result: StepDownstream[] = [];
    if (state.next !== undefined) {
      result.push({ id: state.next, label: "Next" });
    }
    return result;
  },
  empty: { actions: [], next: undefined },
  fromModel(model, id) {
    return {
      actions: model.actions.map<ActionState>((act) => {
        if (act.action.type === ActionType.SetStatus) {
          return {
            customTitle: act.customTitle,
            actionTypeSetup: SET_STATUS,
            actionState: SET_STATUS.fromModel(act.action),
          };
        } else if (act.action.type === ActionType.AutoReply) {
          return {
            customTitle: act.customTitle,
            actionTypeSetup: AUTO_REPLY,
            actionState: AUTO_REPLY.fromModel(act.action),
          };
        } else if (act.action.type === ActionType.Assign) {
          return {
            customTitle: act.customTitle,
            actionTypeSetup: ASSIGN,
            actionState: ASSIGN.fromModel(act.action),
          };
        } else {
          return {
            customTitle: act.customTitle,
            actionTypeSetup: ADD_TAG,
            actionState: ADD_TAG.fromModel(act.action),
          };
        }
      }),
      next: model.next?.toString(),
    };
  },
  layout() {
    return BlockLayout.FULL;
  },
  stepDeleted(state, stepId) {
    return produce(state, (state) => {
      if (state.next === stepId) {
        state.next = undefined;
      }
    });
  },
  title: "Actions",
  toModel(state, id) {
    return {
      type: ModelStep.MULTIPLE_ACTIONS,
      actions: state.actions.map((act) => {
        return {
          type: ModelStep.ACTION,
          action: act.actionTypeSetup!.toModel(act.actionState),
        };
      }),
      next: state.next ? id(state.next) : undefined,
    };
  },
  valid(state) {
    return state.actions.length > 0;
  },
};
