import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const setCheckoutExperienceActive = {
  input: z.object({
    checkoutExperienceId: zExt.objectId(),
    active: z.boolean(),
  }),
  output: z.null(),
};
