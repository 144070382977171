import {
  FulfillmentGroupDataSchema,
  type IFulfillmentGroupData,
} from "@redotech/redo-model/fulfillments/fulfillment-group";
import { z } from "zod";

export const mergeFulfillmentGroups = {
  input: z.object({
    groupIds: z.array(z.string()),
    primaryGroupId: z.string(),
  }),
  output: z.object({
    group: FulfillmentGroupDataSchema as IFulfillmentGroupData,
  }),
};
