import { RedoMerchantClient } from "@redotech/redo-merchant-app-common/client";
import { supportViewFolderSchema } from "@redotech/redo-model/support-folder-parser";
import { FolderData } from "@redotech/redo-model/support/folder-types";

/**
 * POST user/:userId/support-create-folder
 *
 * request: {
 *  userId: string,
 *  name: string,
 *  viewIds: string[],
 * }
 *
 */
export async function createUserSupportFolder(
  client: RedoMerchantClient,
  {
    userId,
    name,
    viewIds,
    defaultViews,
    signal,
  }: {
    userId: string;
    name: string;
    viewIds: string[];
    defaultViews: string[];
    signal?: AbortSignal;
  },
): Promise<FolderData | null> {
  try {
    const response = await client.client.post(
      `/user/${userId}/support-create-folder`,
      { userId, name, viewIds, defaultViews },
      { headers: client.authorization(), signal },
    );

    const parsedFolder = supportViewFolderSchema.safeParse(response.data);
    if (parsedFolder.success) {
      return {
        _id: parsedFolder.data._id,
        name: parsedFolder.data.name,
        viewIds: parsedFolder.data.viewIds,
        defaultViews: parsedFolder.data.defaultViews,
      };
    } else {
      console.error("Error parsing folder:", parsedFolder.error);
      return null;
    }
  } catch (error) {
    console.error("Error creating support folder:", error);
    return null;
  }
}

export async function editUserSupportFolder(
  client: RedoMerchantClient,
  {
    userId,
    folderId,
    newFolder,
    signal,
  }: {
    userId: string;
    folderId: string;
    newFolder: FolderData;
    signal?: AbortSignal;
  },
): Promise<void> {
  await client.client.post(
    "/user/" + userId + "/support-update-folder",
    { userId, folderId, newFolder },
    { headers: client.authorization(), signal },
  );
}

/**
 * delete user/:userId/support-delete-user
 *
 * request: {
 *   folderId: string
 * }
 */

export async function deleteUserSupportFolder(
  client: RedoMerchantClient,
  {
    userId,
    folderId,
    signal,
  }: { userId: string; folderId: string; signal?: AbortSignal },
): Promise<void> {
  await client.client.delete(
    `user/${userId}/support-delete-folder/${folderId}`,
    { headers: client.authorization(), signal },
  );
}
