import { useHandler } from "@redotech/react-util/hook";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import {
  getLocalStorageJson,
  setLocalStorageJson,
} from "@redotech/redo-web/utils/local-storage-wrapper";
import { useContext, useEffect, useMemo, useState } from "react";
import { useDebounce } from "usehooks-ts";
import { z } from "zod";
import {
  FeatureOnboardingDefinition,
  featureOnboardingDefinitions,
  FeatureOnboardingType,
} from "./onboarding-types";

const FEATURE_ONBOARDING_CONFIG_KEY = "redo.feature-onboarding-config";
const SESSION_ONBOARDING_SHOWN_KEY = "redo.session-onboarding-shown";

const LEGACY_CROSS_SELL_POPOUT_CONFIG_KEY = "redo.cross-sell-popout-config";

const FeatureOnboardingConfigSchema = z.record(
  z.nativeEnum(FeatureOnboardingType),
  z.object({
    dismissals: z.number(),
    lastDismissal: z.string().datetime().nullish(),
    completed: z.boolean().optional(),
  }),
);

type FeatureOnboardingConfig = z.infer<typeof FeatureOnboardingConfigSchema>;

const WEEK_IN_HOURS = Temporal.Duration.from({ days: 7 }).total({
  unit: "hours",
});
const LEGACY_MAX_DISMISSALS = 3;
const DEBOUNCE_MS = 1500;

function getFeatureOnboardingConfig(): FeatureOnboardingConfig {
  const config = getLocalStorageJson(FEATURE_ONBOARDING_CONFIG_KEY);
  const parsed = FeatureOnboardingConfigSchema.safeParse(config);
  return parsed.success ? parsed.data : {};
}

function setFeatureOnboardingConfig(config: FeatureOnboardingConfig) {
  setLocalStorageJson(FEATURE_ONBOARDING_CONFIG_KEY, config);
}

function hasShownOnboardingThisSession(): boolean {
  return sessionStorage.getItem(SESSION_ONBOARDING_SHOWN_KEY) === "true";
}

function markOnboardingShownInSession() {
  sessionStorage.setItem(SESSION_ONBOARDING_SHOWN_KEY, "true");
}

/**
 * Legacy cross-sell popout config schema for backward compatibility
 */
const LegacyCrossSellPopoutConfigSchema = z.object({
  dismissals: z.number(),
  lastDismissal: z.string().datetime().nullish(),
});

/**
 * Check if we should suppress cross-sell related onboarding
 * due to legacy cross-sell popout config
 */
function shouldSuppressCrossSellOnboarding(): boolean {
  const legacyConfig = getLocalStorageJson(LEGACY_CROSS_SELL_POPOUT_CONFIG_KEY);
  const parsed = LegacyCrossSellPopoutConfigSchema.safeParse(legacyConfig);

  if (!parsed.success) {
    return false;
  }

  const config = parsed.data;

  // If dismissed too many times in legacy system, suppress
  if (config.dismissals >= LEGACY_MAX_DISMISSALS) {
    return true;
  }

  // If dismissed recently in legacy system, suppress
  if (
    config.lastDismissal &&
    Temporal.Instant.compare(
      Temporal.Instant.from(config.lastDismissal),
      Temporal.Now.instant().subtract({ hours: WEEK_IN_HOURS }),
    ) > 0
  ) {
    return true;
  }

  return false;
}

function shouldShowFeatureOnboarding(
  featureDefinition: FeatureOnboardingDefinition,
  config: FeatureOnboardingConfig,
): boolean {
  if (hasShownOnboardingThisSession()) {
    return false;
  }

  // Check legacy cross-sell popout config for cross-sell related features
  if (
    (featureDefinition.type === FeatureOnboardingType.PACKAGE_PROTECTION_PLUS ||
      featureDefinition.type === FeatureOnboardingType.EXPANDED_WARRANTY) &&
    shouldSuppressCrossSellOnboarding()
  ) {
    return false;
  }

  const featureConfig = config[featureDefinition.type];

  // If no config, this is the first time we're considering showing this onboarding
  if (!featureConfig) {
    return true;
  }

  if (featureConfig.completed) {
    return false;
  }

  const retries = featureDefinition.retries || 0;

  if (featureConfig.dismissals > retries) {
    return false;
  }

  if (
    featureConfig.lastDismissal &&
    Temporal.Instant.compare(
      Temporal.Instant.from(featureConfig.lastDismissal),
      Temporal.Now.instant().subtract({ hours: WEEK_IN_HOURS }),
    ) > 0
  ) {
    return false;
  }

  return true;
}

function getOrCreateFeatureConfig(
  featureType: FeatureOnboardingType,
  config: FeatureOnboardingConfig,
): {
  dismissals: number;
  lastDismissal?: string | null | undefined;
  completed?: boolean;
} {
  const existing = config[featureType];

  if (existing) {
    return existing;
  }
  return { dismissals: 0, lastDismissal: undefined, completed: false };
}

export function useFeatureOnboardingManager() {
  const team = useContext(TeamContext);
  const [showOnboarding, setShowOnboarding] = useState(false);

  const onboardingToShow = useMemo(() => {
    if (!team) {
      return undefined;
    }

    if (!team.settings.crossSellPopoutEnabled) {
      return FeatureOnboardingType.DISABLED;
    }

    const config = getFeatureOnboardingConfig();
    const sortedDefinitions = Object.values(featureOnboardingDefinitions).sort(
      (a, b) => a.priority - b.priority,
    );

    for (const definition of sortedDefinitions) {
      const isEligible = definition.isEligible(team);
      const shouldShow = shouldShowFeatureOnboarding(definition, config);

      if (isEligible && shouldShow) {
        return definition.type;
      }
    }

    return FeatureOnboardingType.DISABLED;
  }, [team]);

  const onboardingType = useDebounce(onboardingToShow, DEBOUNCE_MS);

  const handleDismiss = useHandler(() => {
    if (!onboardingType || onboardingType === FeatureOnboardingType.DISABLED) {
      return;
    }

    setShowOnboarding(false);

    const config = getFeatureOnboardingConfig();
    const featureConfig = getOrCreateFeatureConfig(onboardingType, config);

    setFeatureOnboardingConfig({
      ...config,
      [onboardingType]: {
        ...featureConfig,
        dismissals: featureConfig.dismissals + 1,
        lastDismissal: Temporal.Now.instant().toString(),
      },
    });
  });

  const handleComplete = useHandler(async () => {
    if (!onboardingType || onboardingType === FeatureOnboardingType.DISABLED) {
      return;
    }

    setShowOnboarding(false);

    const config = getFeatureOnboardingConfig();
    const featureConfig = getOrCreateFeatureConfig(onboardingType, config);

    setFeatureOnboardingConfig({
      ...config,
      [onboardingType]: { ...featureConfig, completed: true },
    });
  });

  useEffect(() => {
    if (onboardingType && onboardingType !== FeatureOnboardingType.DISABLED) {
      setShowOnboarding(true);
      markOnboardingShownInSession();
    }
  }, [onboardingType]);

  return { onboardingType, showOnboarding, handleDismiss, handleComplete };
}
