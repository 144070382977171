import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import { resendLabel, WidgetClient } from "@redotech/redo-api-client/widget";
import { getReturner, Return } from "@redotech/redo-model/return";
import { alertOnFailure, toast } from "@redotech/redo-web/alert";
import { RedoTextInput } from "@redotech/redo-web/arbiter-components/input/redo-text-input";
import { RedoModal } from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import { RedoClientContext } from "@redotech/redo-web/client";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo, useCallback, useState } from "react";

function useResendLabel(return_: Return, widgetSlug?: string) {
  const redoClient = useRequiredContext(RedoClientContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const resendLabelToEmail = useCallback(
    async (emailOverride: string, onSuccess?: () => void) => {
      setIsLoading(true);

      try {
        amplitude.logEvent("resend-label-support", {
          team: return_.team._id,
          returnId: return_._id,
        });

        const widgetClient = new WidgetClient(
          redoClient,
          widgetSlug || return_.team.widget_slug,
        );

        await resendLabel(widgetClient, {
          returnId: return_.id,
          emailOverride,
        });

        if (onSuccess) onSuccess();
        return true;
      } catch (err: any) {
        if (err.response?.data?.description?.includes("No HS codes")) {
          setError(err.response.data.description);
        } else {
          // setError(err.message);
          setError(
            "Shipping label failed to create. Please contact our support team to resolve this issue.",
          );
        }
        return false;
      } finally {
        setIsLoading(false);
      }
    },
    [redoClient, return_, widgetSlug],
  );

  return { resendLabelToEmail, isLoading, error };
}

interface ResendModalProps {
  return: Return;
  open: boolean;
  onClose: () => void;
  reload?: () => void;
  widgetSlug?: string;
}

export const ResendModal = memo(function ResendModal({
  return: return_,
  open,
  onClose,
  reload,
  widgetSlug,
}: ResendModalProps) {
  const initialEmail = getReturner(return_).email || "";
  const [email, setEmail] = useState(initialEmail);

  const { resendLabelToEmail, isLoading, error } = useResendLabel(
    return_,
    widgetSlug,
  );

  const handleSend = useCallback(() => {
    void alertOnFailure("Resending label failed")(async () => {
      const success = await resendLabelToEmail(email, reload);
      if (success) {
        toast("Label sent successfully!", { variant: "success" });
        onClose();
      }
      return success;
    });
  }, [email, resendLabelToEmail, reload, onClose]);

  return (
    <RedoModal
      modalSize="sm"
      onModalCloseRequested={onClose}
      primaryButton={{ text: "Send", onClick: handleSend, disabled: isLoading }}
      secondaryButton={{
        text: "Cancel",
        onClick: onClose,
        disabled: isLoading,
      }}
      subtitle="This will send an email with the shipping label(s) to the customer."
      title="Resend shipping label"
    >
      <Flex dir="column" gap="2xl">
        <RedoTextInput
          label="Email address"
          placeholder="Enter email address"
          setValue={setEmail}
          value={email}
        />

        {error && (
          <Text fontSize="sm" fontWeight="thin" textColor="error">
            {error}
          </Text>
        )}
      </Flex>
    </RedoModal>
  );
});
