import { friendlyViewName } from "@redotech/redo-model/views/default-outbound-views";
import { folderTypes } from "@redotech/redo-model/views/folders";
import { FulfillmentGroupView } from "@redotech/redo-model/views/views";
import {
  FlattenedItem,
  TreeFolderItem,
  TreeItem,
  TreeItemType,
  TreeViewItem,
} from "@redotech/redo-web/arbiter-components/navigation/folder-tree/types";
import { deepCopyObject } from "@redotech/util/object";
import { assertNever } from "@redotech/util/type";

export type FolderWithView = {
  folder: folderTypes["fulfillment-groups"];
  views: FulfillmentGroupView[];
};

export function viewsAndFoldersToTree(
  viewsWithoutFolders: FulfillmentGroupView[],
  foldersWithViews: FolderWithView[],
  urlPrefix: string,
) {
  const allItemsWithPositions = [
    ...viewsWithoutFolders.map((view) => ({
      ...view,
      position: view.position ?? 0,
      _id: view._id,
    })),
    ...foldersWithViews.map((folder) => ({
      ...folder,
      position: folder.folder.position ?? 0,
      _id: folder.folder._id,
    })),
  ];
  const sortedItems = allItemsWithPositions.sort((a, b) => {
    return a.position - b.position;
  });

  const treeItems: TreeItem[] = sortedItems.map((item) => {
    if ("folder" in item) {
      return folderToTreeItem(item, urlPrefix);
    } else {
      return viewToTreeItem(item, urlPrefix);
    }
  });

  return treeItems;
}

function viewToTreeItem(
  view: FulfillmentGroupView,
  urlPrefix: string,
): TreeViewItem {
  return {
    id: view._id,
    name: view.name,
    actionHref: `${urlPrefix}/${encodeURIComponent(view.name)}`,
    friendlyName: friendlyViewName(view),
    type: TreeItemType.View,
  };
}

function folderToTreeItem(
  folder: FolderWithView,
  urlPrefix: string,
): TreeFolderItem {
  return {
    id: folder.folder._id,
    name: folder.folder.name,
    friendlyName: folder.folder.name,
    type: TreeItemType.Folder,
    children: folder.views.map((view) => viewToTreeItem(view, urlPrefix)),
  };
}

export function treeToViewsAndFolders(
  flattenedItems: FlattenedItem[],
  allViews: FulfillmentGroupView[],
  allFolders: folderTypes["fulfillment-groups"][],
) {
  const viewsCopy = allViews.map((view) => deepCopyObject(view));
  const foldersCopy = allFolders.map((folder) => deepCopyObject(folder));

  const newFolders: folderTypes["fulfillment-groups"][] = [];
  const newViews: FulfillmentGroupView[] = [];

  for (const item of flattenedItems) {
    switch (item.type) {
      case TreeItemType.View: {
        const matchingView = viewsCopy.find((view) => view._id === item.id);
        if (matchingView) {
          if (item.parentId) {
            matchingView.folderId = item.parentId.toString();
          } else {
            matchingView.folderId = null;
          }
          matchingView.position = item.index;
          newViews.push(matchingView);
        }
        break;
      }
      case TreeItemType.Folder: {
        const matchingFolder = foldersCopy.find(
          (folder) => folder._id === item.id,
        );

        if (matchingFolder) {
          matchingFolder.position = item.index;
          newFolders.push(matchingFolder);
        }
        break;
      }
      default:
        assertNever(item);
    }
  }

  return { views: newViews, folders: newFolders };
}
