import { Currency } from "@redotech/money/currencies";
import { z } from "zod";

const timeSeriesMetric = z.array(
  z.object({ plainDate: z.string(), value: z.number().optional() }),
);

export const getWidgetsTimeSeriesMetrics = {
  input: z.null(),
  output: z.object({
    incrementalRevenueOverTimeSeries: timeSeriesMetric,
    currency: z.nativeEnum(Currency),
  }),
};
