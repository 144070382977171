import { useRequiredContext } from "@redotech/react-util/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { RedoSecondaryNavItem } from "@redotech/redo-web/arbiter-components/navigation/secondary/redo-secondary-nav-item";
import SmileIcon from "@redotech/redo-web/arbiter-icon/face-content.svg";
import SmileFilledIcon from "@redotech/redo-web/arbiter-icon/face-content_filled.svg";
import { allowCustomerAccounts } from "@redotech/redo-web/utils/customer-account-utils";
import { memo } from "react";

export const CustomerItems = memo(function CustomerItems({
  supportRoot,
}: {
  supportRoot: string;
}) {
  const team = useRequiredContext(TeamContext);
  const urlPrefix = `${supportRoot}/customers`;

  const customerAccountsEnabled = allowCustomerAccounts(
    team.settings.customerAccounts,
  );

  const viewedLandingPage = localStorage.getItem(
    "viewed_customer_accounts_page",
  );

  return (
    <RedoSecondaryNavItem
      action={{
        type: "link",
        href: customerAccountsEnabled ? `${urlPrefix}` : `${urlPrefix}/about`,
      }}
      FilledIcon={SmileFilledIcon}
      friendlyName="Customers"
      id="customers"
      isNew={!viewedLandingPage && !customerAccountsEnabled}
      UnfilledIcon={SmileIcon}
    />
  );
});
