import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const getCheckoutSplits = {
  input: z.null(),
  output: z.array(
    z.object({
      _id: zExt.objectId(),
      name: z.string(),
      active: z.boolean(),
      createdAt: z.date(),
      startDate: z.date().optional(),
    }),
  ),
};
export type GetCheckoutSplitsOutput = z.infer<typeof getCheckoutSplits.output>;
