import {
  createLazyContext,
  useLazyContextProvider,
  useRequiredContext,
} from "@redotech/react-util/context";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { ConversationTagWithId } from "@redotech/redo-model/conversation";
import { memo, ReactNode } from "react";
import { getConversationTagsOfTeam } from "../../client/conversation-tags";

export const ConversationTagsContext =
  createLazyContext<ConversationTagWithId[]>();

export const ConversationTagsService = memo(function ConversationTagsService({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const client = useRequiredContext(RedoMerchantClientContext);

  const team = useRequiredContext(TeamContext);

  const loadState = useLazyContextProvider(async (signal) => {
    const teamTags = await getConversationTagsOfTeam(
      client,
      { teamId: team._id },
      signal,
    );
    const tagIds = teamTags.map((tag) => {
      return {
        tagId: tag._id,
        name: tag.name,
        pillTheme: tag.pillTheme,
        source: tag.source,
      };
    });
    return tagIds.sort((a, b) => a.name.localeCompare(b.name));
  });

  return (
    <ConversationTagsContext.internalContext.Provider value={loadState}>
      {children}
    </ConversationTagsContext.internalContext.Provider>
  );
});
