import { useRequiredContext } from "@redotech/react-util/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { RedoSecondaryNavItem } from "@redotech/redo-web/arbiter-components/navigation/secondary/redo-secondary-nav-item";
import FlipBackwardIcon from "@redotech/redo-web/icon-old/flip-backward.svg";
import { memo, useMemo } from "react";
import { useMatch } from "react-router-dom";
import { OrdersReviewCountContext } from "../../../../services/orders/orders-review-count-service";

export const ReturnsItem = memo(function ReturnsItem({
  ordersRoot,
}: {
  ordersRoot: string;
}) {
  const team = useRequiredContext(TeamContext);
  const urlPrefix = `${ordersRoot}/returns`;

  const returnsEnabled = team?.settings.returns?.enabled;
  const returnsInstantExchangeEnabled = team.settings.exchanges.allowInstant;

  const { returnsNeedingReview } = useRequiredContext(OrdersReviewCountContext);

  const children = useMemo(() => {
    if (!returnsInstantExchangeEnabled) {
      return null;
    }
    return (
      <RedoSecondaryNavItem
        action={{ type: "link", href: `${urlPrefix}/instant-exchange` }}
        friendlyName="Instant exchange"
        id="returns-instant-exchange"
      />
    );
  }, [returnsInstantExchangeEnabled, urlPrefix]);

  const anyChildSelected = useMatch(`${urlPrefix}/*`);

  if (!returnsEnabled) {
    return null;
  }

  return (
    <RedoSecondaryNavItem
      action={{ type: "link", href: urlPrefix }}
      anyChildSelected={!!anyChildSelected}
      FilledIcon={FlipBackwardIcon}
      friendlyName="Returns"
      id="returns"
      notificationCount={returnsNeedingReview}
      UnfilledIcon={FlipBackwardIcon}
    >
      {children}
    </RedoSecondaryNavItem>
  );
});
