import { z } from "zod";

export enum ClaimsTabDateType {
  CLAIM_SUBMITTED_DATE = "claim_submitted_date",
  ORDER_DATE = "order_date",
  CLAIM_COMPLETED_DATE = "claim_completed_date",
}

const orderSummarySchema = z.object({
  TOTAL_CLAIMS: z.number(),
  PROTECTED_ORDERS: z.number(),
  TOTAL_ORDERS: z.number(),
  ADOPTION_RATE: z.number(),
  TOTAL_PP_REVENUE: z.number(),
  PROTECTED_CLAIM_REVENUE: z.number(),
  UNPROTECTED_CLAIM_REVENUE: z.number(),
  REFUNDS: z.number(),
  RETAINED: z.number(),
});

const claimByCarrierSchema = z.object({
  CARRIER: z.string(),
  TOTAL_CLAIMS: z.number(),
  PROTECTED_CLAIM_REVENUE: z.number(),
  UNPROTECTED_CLAIM_REVENUE: z.number(),
});

export const getClaimsAnalyticsStatistics = {
  input: z.object({
    startDate: z.string(),
    endDate: z.string(),
    type: z.nativeEnum(ClaimsTabDateType),
  }),
  output: z.object({
    orderSummary: orderSummarySchema,
    claimsByCarrier: z.array(claimByCarrierSchema),
  }),
};

export type ClaimAnalyticsStatistics = z.infer<
  typeof getClaimsAnalyticsStatistics.output
>;

export type OrderSummary = z.infer<typeof orderSummarySchema>;

export type ClaimByCarrier = z.infer<typeof claimByCarrierSchema>;
