import {
  createLazyContext,
  useLazyContextProvider,
  useRequiredContext,
} from "@redotech/react-util/context";
import { CustomerGroup } from "@redotech/redo-model/customers/customer-group-definition";
import { memo, ReactNode } from "react";
import { RedoMarketingRpcClientContext } from "../../app/redo-marketing-rpc-client-provider";

export const SegmentsContext = createLazyContext<CustomerGroup[]>();
export const SegmentsService = memo(function SegmentsService({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const client = useRequiredContext(RedoMarketingRpcClientContext);

  const loadState = useLazyContextProvider(async (signal) => {
    const segments: CustomerGroup[] = await client.fetchTeamSegments(
      { segmentType: undefined },
      { signal },
    );
    return segments;
  });

  return (
    <SegmentsContext.internalContext.Provider value={loadState}>
      {children}
    </SegmentsContext.internalContext.Provider>
  );
});
