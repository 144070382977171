import { audienceMemberSchema } from "@redotech/redo-model/marketing/audience";
import { SortDirection } from "@redotech/redo-model/tables/table";
import { z } from "zod";

export const getAudience = {
  input: z.object({
    search: z.string().optional(),
    sort: z
      .object({ key: z.string(), direction: z.nativeEnum(SortDirection) })
      .optional(),
    pageContinue: z.string().optional(),
    pageSize: z.number(),
  }),
  output: z.object({
    data: z.array(audienceMemberSchema),
    pageNext: z.string().nullable(),
  }),
};
