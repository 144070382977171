import { RedoListItem } from "@redotech/redo-web/arbiter-components/list/redo-list";
import { RedoSingleSelectDropdownInput } from "@redotech/redo-web/arbiter-components/select-dropdown/redo-single-select-dropdown-input";
import { Flex } from "@redotech/redo-web/flex";
import { InputSize } from "@redotech/redo-web/input";
import { LabeledInput, LabelSize } from "@redotech/redo-web/labeled-input";
import { InputLines, TextInput } from "@redotech/redo-web/text-input";
import { memo, useMemo } from "react";
import * as editItemDispositionCss from "./edit-item-disposition.module.css";

export const EditItemDisposition = memo(function EditItemDisposition({
  grade,
  outcome,
  notes,
}: {
  grade: {
    options: string[];
    selectedOption: string | undefined;
    setSelectedOption: (value: string) => void;
  };
  outcome: {
    options: string[];
    selectedOption: string | undefined;
    setSelectedOption: (value: string) => void;
  };
  notes: { value: string; setValue: (value: string) => void };
}) {
  const gradeOptions: RedoListItem<string>[] = useMemo(() => {
    return grade.options.map((grade) => ({
      id: grade,
      type: "text",
      text: grade,
      value: grade,
    }));
  }, [grade.options]);

  const outcomeOptions: RedoListItem<string>[] = useMemo(() => {
    return outcome.options.map((outcome) => ({
      id: outcome,
      type: "text",
      text: outcome,
      value: outcome,
    }));
  }, [outcome.options]);

  return (
    <Flex dir="column">
      <Flex className={editItemDispositionCss.itemDetailsGradeOutcomeContainer}>
        <LabeledInput
          className={editItemDispositionCss.itemDetailsLabeledInput}
          label="Grade"
          size={LabelSize.EXTRA_SMALL}
        >
          <RedoSingleSelectDropdownInput
            options={gradeOptions}
            optionSelected={({ item }) => grade.setSelectedOption(item.value)}
            placeholder="Select"
            selectedItem={gradeOptions.find(
              (o) => o.id === grade.selectedOption,
            )}
            size="xs"
          />
        </LabeledInput>
        <LabeledInput
          className={editItemDispositionCss.itemDetailsLabeledInput}
          label="Outcome"
          size={LabelSize.EXTRA_SMALL}
        >
          <RedoSingleSelectDropdownInput
            options={outcomeOptions}
            optionSelected={({ item }) => outcome.setSelectedOption(item.value)}
            placeholder="Select"
            selectedItem={outcomeOptions.find(
              (o) => o.id === outcome.selectedOption,
            )}
            size="xs"
          />
        </LabeledInput>
      </Flex>
      <LabeledInput
        className={editItemDispositionCss.itemDetailsLabeledInput}
        label="Notes"
        size={LabelSize.EXTRA_SMALL}
      >
        <TextInput
          lines={InputLines.MULTI}
          onChange={notes.setValue}
          size={InputSize.EXTRA_SMALL}
          value={notes.value}
        />
      </LabeledInput>
    </Flex>
  );
});
