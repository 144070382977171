import { z } from "zod";

export const createOrderComment = {
  input: z.object({
    orderId: z.string(),
    teamId: z.string(),
    message: z.string(),
    userId: z.string(),
  }),
  output: z.object({ success: z.boolean() }),
};
