import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export type LookupParams = z.infer<typeof lookupOrder.input>;

export const lookupOrder = {
  input: z.object({
    orderName: z.string().nullish(),
    orderId: z.string().nullish(),
  }),
  output: z
    .object({
      _id: zExt.objectId(),
      fulfillmentGroupId: zExt.objectId().nullish(),
    })
    .nullable(),
};
