// extracted by mini-css-extract-plugin
export var actionButton = "_a4-1";
export var actions = "_a4-0";
export var copyableField = "_a4-2";
export var detailColumn = "_a4-3";
export var detailRow = "_a4-4";
export var dropdownTitle = "_a4-5";
export var fulfillmentDetails = "_a4-7";
export var fulfillments = "_a4-6";
export var icon = "_a4-8";
export var imgContainer = "_a4-9";
export var lineItems = "_a4-a";
export var link = "_a4-p";
export var orderCard = "_a4-k";
export var orderCollapseButton = "_a4-l";
export var orderCollapseButtonIcon = "_a4-m";
export var orderDetails = "_a4-b";
export var orderPillsContainer = "_a4-c";
export var pillWrapper = "_a4-d";
export var product = "_a4-e";
export var productDetails = "_a4-g";
export var productTopDetails = "_a4-h";
export var products = "_a4-f";
export var strong = "_a4-i";
export var tightText = "_a4-o";
export var tooltipHidden = "_a4-q";
export var variantQuantityRow = "_a4-j";
export var wrappingText = "_a4-n";