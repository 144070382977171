import { zExt } from "@redotech/redo-model/common/zod-util";
import {
  printDocumentScanShortcutSchema,
  weightScanShortcutSchema,
} from "@redotech/redo-model/fulfillments/scan-form";
import { z } from "zod";

export const printShortcutBarcodes = {
  input: z.object({
    presets: z.array(z.object({ id: zExt.objectId() })),
    packages: z.array(z.object({ name: z.string() })),
    print: printDocumentScanShortcutSchema,
    weightScan: weightScanShortcutSchema,
  }),
  output: z.object({ url: z.string() }),
};
