import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import { useTriggerLoad } from "@redotech/react-util/load";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import {
  ConversationPlatform,
  ExpandedConversation,
  getConversationStatus,
} from "@redotech/redo-model/conversation";
import { ConversationFiltersV3 } from "@redotech/redo-model/conversation-filters/conversation-filters";
import {
  getCustomerDisplayName,
  getPrimaryCustomerEmail,
  getPrimaryCustomerFacebookName,
  getPrimaryCustomerInstagramUsername,
  getPrimaryCustomerPhoneNumber,
} from "@redotech/redo-model/customer";
import { GetUser as User } from "@redotech/redo-model/user";
import { Autocomplete } from "@redotech/redo-web/autocomplete";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { getDateString } from "@redotech/redo-web/date-utils";
import { Flex } from "@redotech/redo-web/flex";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { LoadingRedoAnimation } from "@redotech/redo-web/loading-redo-animation";
import { Modal, ModalSize } from "@redotech/redo-web/modal";
import { SelectDropdown } from "@redotech/redo-web/select-dropdown";
import { RedoSupportChannelBadge } from "@redotech/redo-web/support/redo-support-channel-badge";
import {
  Dispatch,
  memo,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDebounce } from "usehooks-ts";
import {
  getConversations,
  mergeConversations,
} from "../../client/conversations";
import { ConversationStatusPill } from "../../common/conversation-status-pill";
import { MergeSuggestionCarousel } from "../merge-suggestion/merge-suggestion-carousel";
import * as mergeSuggestionSummaryModalCss from "../merge-suggestion/merge-suggestion-summary-modal.module.css";
import { channelDisplayName } from "../utils";
import * as mergeModalCss from "./merge-modal.module.css";

export const MergeModal = memo(function MergeModal({
  open,
  setOpen,
  conversations,
  setActiveConversation,
  setConversationsInProximity,
  selectAllInfo,
  onBack,
  cleanupFn,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  conversations: ExpandedConversation[];
  setActiveConversation: (val: ExpandedConversation) => void;
  setConversationsInProximity?: Dispatch<
    SetStateAction<ExpandedConversation[]>
  >;
  selectAllInfo?: {
    count: number;
    deselectedConversations: ExpandedConversation[];
    filters: ConversationFiltersV3;
    setBlockRefresh: (val: boolean) => void;
  };
  /** Called when merge modal would return from details step to tickets step.
   * If callback returns false, merge modal will not change step. See MergeSuggestionFlow
   * for an example usage. */
  onBack?: () => void;
  cleanupFn?: () => void;
}) {
  const client = useRequiredContext(RedoMerchantClientContext);
  const [step, setStep] = useState<"tickets" | "details">("tickets");
  const [searchString, setSearchString] = useState<string>("");
  const [pending, setPending] = useState<boolean>(false);
  const debouncedSearchString = useDebounce(searchString, 500);
  const [conversationsLoading, setConversationsLoading] =
    useState<boolean>(false);
  const [searchedConversations, setSearchedConversations] = useState<
    ExpandedConversation[]
  >([]);
  const [selectedConversations, setSelectedConversations] =
    useState<ExpandedConversation[]>(conversations);
  const isInitialLoad = useRef(true);

  const doSelectAllLoad = async () => {
    if (!selectAllInfo) {
      return;
    }

    if (isInitialLoad.current) {
      setPending(true);
      isInitialLoad.current = false;
    }

    try {
      const response = await getConversations(client, {
        pageContinue: undefined,
        pageSize: 100,
        filters: selectAllInfo.filters,
      });
      const conversations = response.data;
      const selectedConversations = conversations.filter(
        (conversation) =>
          !selectAllInfo.deselectedConversations.find(
            (deselectedConversation) =>
              deselectedConversation._id === conversation._id,
          ) &&
          ![
            ConversationPlatform.INSTAGRAM_COMMENTS,
            ConversationPlatform.FACEBOOK_COMMENTS,
          ].includes(conversation.platform),
      );
      setSelectedConversations(selectedConversations);
      return;
    } catch (error) {
      console.error("Error loading conversations:", error);
    } finally {
      setPending(false);
    }
    return;
  };

  const handleValueChange = (conversationIds: string[]) => {
    const newSelectedConversations: ExpandedConversation[] = conversations;
    for (const conversationId of conversationIds) {
      const alreadySelectedConversation = selectedConversations.find(
        (conversation) => conversation._id === conversationId,
      );
      if (alreadySelectedConversation) {
        newSelectedConversations.push(alreadySelectedConversation);
      } else {
        const conversation = searchedConversations.find(
          (conversation) => conversation._id === conversationId,
        );
        if (conversation) {
          newSelectedConversations.push(conversation);
        }
      }
    }
    setSelectedConversations(newSelectedConversations);
  };

  const onInputChange = (event: any, value: string) => {
    setSearchString(value);
  };

  const [conversationsLoad, doConversationsLoad] = useTriggerLoad(
    async (signal) => {
      const { data } = await getConversations(client, {
        pageContinue: undefined,
        pageSize: 20,
        filters: {
          advancedFilters: [],
          search: debouncedSearchString || undefined,
        },
      });
      setConversationsLoading(false);
      const searchResults = data.filter((conversation) => {
        return conversations.every(
          (conversation_) => conversation_._id !== conversation._id,
        );
      });
      setSearchedConversations(searchResults);
      return data;
    },
  );
  useEffect(() => {
    if (searchString !== undefined) {
      setConversationsLoading(true);
      doConversationsLoad();
    }
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchString]);

  useEffect(() => {
    if (conversations.length > 1 || selectAllInfo) {
      setStep("details");
    }
  }, [conversations, selectAllInfo]);

  useEffect(() => {
    if (!selectAllInfo || !open || pending) {
      return;
    }
    void doSelectAllLoad();
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAllInfo, open]);

  useEffect(() => {
    const validSelectedConversations = selectedConversations.filter(
      (conversation) =>
        ![
          ConversationPlatform.INSTAGRAM_COMMENTS,
          ConversationPlatform.FACEBOOK_COMMENTS,
        ].includes(conversation.platform),
    );
    if (validSelectedConversations.length !== selectedConversations.length) {
      setSelectedConversations(validSelectedConversations);
    }
  }, [selectedConversations]);

  const ticketStepActions = (
    <div className={mergeModalCss.modalFooter}>
      <Button onClick={() => setOpen(false)} theme={ButtonTheme.OUTLINED}>
        Cancel
      </Button>
      <Button
        // todo disabled
        // disabled={isDisabled()}
        onClick={() => setStep("details")}
        theme={ButtonTheme.PRIMARY}
      >
        Continue
      </Button>
    </div>
  );

  const getTicketCount = () => {
    return (
      (selectAllInfo?.count ?? 0) -
        (selectAllInfo?.deselectedConversations?.length ?? 0) ||
      selectedConversations.length
    );
  };

  const getConversationDisplayName = (conversation: ExpandedConversation) => {
    const mostRecentMessage =
      conversation.messages[conversation.messages.length - 1];
    return (
      <div className={mergeModalCss.optionContainer}>
        <div className={mergeModalCss.conversationContainer}>
          <div className={mergeModalCss.name}>
            {getCustomerDisplayName(conversation.customer)}
          </div>
          <div className={mergeModalCss.message}>
            {mostRecentMessage.content}
          </div>
        </div>
        <div className={mergeModalCss.pillContainer}>
          <ConversationStatusPill
            conversationStatus={getConversationStatus(conversation)}
          />
          <RedoSupportChannelBadge platform={conversation.platform} />
        </div>
        <div>
          {getDateString(new Date(conversation.lastCustomerResponseAt))}
        </div>
      </div>
    );
  };
  const ticketStep = (
    <div>
      <div className={mergeModalCss.modalHeader}>Tickets being merged</div>
      <Autocomplete
        filterOptions={(x) => x}
        getLabel={(conversationId): string => {
          const conversation = selectedConversations.find(
            (conversation) => conversation._id === conversationId,
          );
          return getCustomerDisplayName(conversation?.customer);
        }}
        keyFn={(conversationId): string => {
          return conversationId;
        }}
        multiple
        noOptionsText={
          conversationsLoad.pending
            ? "Loading..."
            : "Unable to find tickets matching that search."
        }
        onInputChange={onInputChange}
        options={
          conversationsLoading
            ? []
            : searchedConversations
                ?.filter(
                  (conversation) =>
                    // Due to the special structure of Instagram and Facebook comments conversations, we don't allow merging them
                    ![
                      ConversationPlatform.INSTAGRAM_COMMENTS,
                      ConversationPlatform.FACEBOOK_COMMENTS,
                    ].includes(conversation.platform),
                )
                .map((conversation) => conversation._id) || []
        }
        valueChange={handleValueChange}
      >
        {(searchedConversation) =>
          getConversationDisplayName(
            searchedConversations.find(
              (conversation) => conversation._id === searchedConversation,
            ) as ExpandedConversation,
          )
        }
      </Autocomplete>
    </div>
  );

  return (
    <Modal
      footer={step === "tickets" && !pending ? ticketStepActions : undefined}
      onClose={() => setOpen(false)}
      open={open}
      size={ModalSize.MEDIUM}
      title={
        step === "tickets"
          ? "Select ticket(s) to merge with"
          : `Merge ${getTicketCount()} tickets`
      }
    >
      <div className={mergeModalCss.modalContent}>
        {pending ? (
          <div className={mergeModalCss.animationContainer}>
            <LoadingRedoAnimation />
          </div>
        ) : (
          step === "tickets" && ticketStep
        )}
      </div>
      {step === "details" && !pending && (
        <DetailsStep
          cleanupFn={cleanupFn}
          onBack={onBack}
          selectedConversations={selectedConversations}
          setActiveConversation={setActiveConversation}
          setConversationsInProximity={setConversationsInProximity}
          setOpen={setOpen}
          setPending={setPending}
          setStep={setStep}
        />
      )}
    </Modal>
  );
});

interface CustomerOption {
  id: string;
  value: string;
}
export const DetailsStep = memo(function DetailsStep({
  selectedConversations,
  setOpen,
  setStep,
  setActiveConversation,
  setConversationsInProximity,
  setPending,
  onBack,
  cleanupFn,
}: {
  selectedConversations: ExpandedConversation[];
  setOpen: (val: boolean) => void;
  setStep: (val: "tickets" | "details") => void;
  setActiveConversation: (val: ExpandedConversation) => void;
  setConversationsInProximity?: Dispatch<
    SetStateAction<ExpandedConversation[]>
  >;
  setPending: (val: boolean) => void;
  onBack?: () => void;
  cleanupFn?: () => void;
}) {
  const team = useRequiredContext(TeamContext);
  const client = useRequiredContext(RedoMerchantClientContext);
  const users = team.users;
  const [possibleCustomers, setPossibleCustomers] = useState<CustomerOption[]>(
    [],
  );
  const [selectedCustomer, setSelectedCustomer] = useState<
    CustomerOption | undefined
  >(undefined);
  type PossibleCommChannels = Exclude<
    ConversationPlatform,
    | ConversationPlatform.INSTAGRAM_COMMENTS
    | ConversationPlatform.FACEBOOK_COMMENTS
  >;
  const [possibleCommChannels, setPossibleCommChannels] = useState<
    PossibleCommChannels[]
  >([]);
  const [selectedCommChannel, setSelectedCommChannel] = useState<
    PossibleCommChannels | undefined
  >(undefined);
  const [possibleSubjects, setPossibleSubjects] = useState<string[]>([]);
  const [selectedSubject, setSelectedSubject] = useState<string | undefined>(
    undefined,
  );
  const [assignee, setAssignee] = useState<User | null | undefined>(
    selectedConversations[0]?.assignee || null,
  );

  const handleMergeTickets = async () => {
    if (!selectedCustomer || !selectedCommChannel || assignee === undefined) {
      return;
    }
    setPending(true);
    const selectedConversationIds = selectedConversations.map(
      (conversation) => conversation._id,
    );
    // all but primary conversation remove from proximity
    setConversationsInProximity &&
      setConversationsInProximity((prev: ExpandedConversation[]) => [
        ...prev.filter(
          (conv) => !selectedConversationIds.slice(1).includes(conv._id),
        ),
      ]);
    const mergedConversation = await mergeConversations(client, {
      selectedConversationIds,
      customer: selectedCustomer?.id,
      platform: selectedCommChannel,
      subject: selectedSubject || "",
      assignee: assignee?._id || null,
    });
    const channels = [
      ...new Set(
        selectedConversations.map((conversation) => conversation.platform),
      ),
    ];
    amplitude.logEvent("merge-conversation", {
      conversationIds: selectedConversationIds,
      channels,
    });
    setActiveConversation(mergedConversation);
    setPending(false);
    setOpen(false);
    cleanupFn && cleanupFn();
  };

  useEffect(() => {
    const customers: CustomerOption[] = [];
    for (const conversation of selectedConversations) {
      const primaryCustomerEmail = getPrimaryCustomerEmail(
        conversation.customer,
      );
      const primaryCustomerPhoneNumber = getPrimaryCustomerPhoneNumber(
        conversation.customer,
      );
      const primaryCustomerFacebookName = getPrimaryCustomerFacebookName(
        conversation.customer,
      );
      const primaryCustomerInstagramName = getPrimaryCustomerInstagramUsername(
        conversation.customer,
      );
      if (
        conversation.platform === ConversationPlatform.EMAIL &&
        primaryCustomerEmail
      ) {
        customers.push({
          id: conversation.customer?.customer,
          value: `${
            conversation.customer?.name || "Unknown Customer"
          } (${primaryCustomerEmail})`,
        });
      } else if (conversation.platform === ConversationPlatform.FACEBOOK) {
        customers.push({
          id: conversation.customer?.customer,
          value: `${
            conversation.customer?.name || "Unknown Customer"
          } (${primaryCustomerFacebookName} on FB)`,
        });
      } else if (conversation.platform === ConversationPlatform.INSTAGRAM) {
        customers.push({
          id: conversation.customer?.customer,
          value: `${
            conversation.customer?.name || "Unknown Customer"
          } (${primaryCustomerInstagramName} on IG)`,
        });
      } else if (conversation.platform === ConversationPlatform.SMS) {
        customers.push({
          id: conversation.customer?.customer,
          value: `${
            conversation.customer?.name || "Unknown Customer"
          } (${primaryCustomerPhoneNumber})`,
        });
      } else {
        customers.push({
          id: conversation.customer?.customer,
          value: `${conversation.customer?.name || "Unknown Customer"}`,
        });
      }
    }
    setPossibleCustomers(customers);
    setSelectedCustomer(customers[0]);
    const commChannels: PossibleCommChannels[] = [];
    for (const conversation of selectedConversations) {
      if (
        conversation.platform &&
        conversation.platform !== ConversationPlatform.INSTAGRAM_COMMENTS &&
        conversation.platform !== ConversationPlatform.FACEBOOK_COMMENTS &&
        !commChannels.includes(conversation.platform)
      ) {
        commChannels.push(conversation.platform);
      }
    }
    setPossibleCommChannels(commChannels);
    setSelectedCommChannel(commChannels[0]);
    const subjects: string[] = [];
    for (const conversation of selectedConversations) {
      if (conversation.platform === ConversationPlatform.EMAIL) {
        subjects.push(conversation.subject);
      }
    }
    setPossibleSubjects(subjects);
    setSelectedSubject(subjects[0]);
  }, [selectedConversations]);

  const showSelectedConversation = (conversation: ExpandedConversation) => {
    return (
      <Flex
        align="flex-start"
        className={
          mergeSuggestionSummaryModalCss.mergeModalConversationContainer
        }
        dir="column"
        gap="lg"
        key={conversation._id}
        p="3xl"
        radius="xl"
      >
        <MergeSuggestionCarousel conversation={conversation} showSubject />
      </Flex>
    );
  };

  return (
    <>
      <div className={mergeModalCss.detailsContent}>
        <div className={mergeModalCss.modalHeader}>Tickets being merged</div>
        <div className={mergeModalCss.selectedConversationsContainer}>
          {selectedConversations.map((conversation) => (
            <div key={conversation._id}>
              {showSelectedConversation(conversation)}
            </div>
          ))}
        </div>
        <div>
          <LabeledInput label="Customer">
            <SelectDropdown
              options={possibleCustomers}
              placeholder="Select customer"
              value={selectedCustomer}
              valueChange={(e: CustomerOption) => {
                setSelectedCustomer(e);
              }}
            >
              {(option) => option.value}
            </SelectDropdown>
          </LabeledInput>
        </div>
        <div>
          <LabeledInput label="Communication channel">
            <SelectDropdown
              options={possibleCommChannels}
              placeholder="Select communication channel"
              value={selectedCommChannel}
              valueChange={(e: PossibleCommChannels) => {
                setSelectedCommChannel(e);
              }}
            >
              {(option) => channelDisplayName(option)}
            </SelectDropdown>
          </LabeledInput>
        </div>
        {selectedCommChannel === ConversationPlatform.EMAIL && (
          <div>
            <LabeledInput label="Subject">
              <SelectDropdown
                options={possibleSubjects}
                placeholder="Select subject"
                value={selectedSubject}
                valueChange={(e: string) => {
                  setSelectedSubject(e);
                }}
              >
                {(option) => option}
              </SelectDropdown>
            </LabeledInput>
          </div>
        )}
        <div>
          <LabeledInput label="Assignee">
            <SelectDropdown
              allowNull
              options={[null, ...users.map((user) => user.user as User)]}
              placeholder="Assignee"
              value={assignee}
              valueChange={setAssignee}
            >
              {(option) => {
                if (option) {
                  return `${(option as User)?.name}`;
                } else {
                  return "Unassigned";
                }
              }}
            </SelectDropdown>
          </LabeledInput>
        </div>
      </div>
      <div className={mergeModalCss.modalFooter}>
        <Button
          onClick={() => {
            const shouldChangeStep = onBack?.() ?? true;
            shouldChangeStep && setStep("tickets");
          }}
          theme={ButtonTheme.OUTLINED}
        >
          Back
        </Button>
        <Button
          disabled={
            !selectedCustomer?.id ||
            !selectedCommChannel ||
            assignee === undefined
          }
          onClick={async () => {
            await handleMergeTickets();
          }}
          theme={ButtonTheme.PRIMARY}
        >
          Merge tickets
        </Button>
      </div>
    </>
  );
});
