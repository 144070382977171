import { UserContext } from "@redotech/redo-merchant-app-common/user";
import { Step as ModelStep } from "@redotech/redo-model/return-flow";
import { Permission, permitted } from "@redotech/redo-model/user";
import { BlockLayout } from "@redotech/redo-web/flowchart";
import CancelIcon from "@redotech/redo-web/icon-old/octagon.svg";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import {
  InputLines,
  InputTheme,
  TextInput,
} from "@redotech/redo-web/text-input";
import { memo, useContext } from "react";
import { StepType, StepTypeDetailsProps } from "./step";

export interface State {
  message: string;
  customTitle?: string;
}

const Details = memo(function Details({
  state,
  setState,
}: StepTypeDetailsProps<State>) {
  const user = useContext(UserContext);
  const canEditSettings =
    !!user && permitted(user.permissions, Permission.EDIT_SETTINGS);
  return (
    <LabeledInput
      description="Message to show to the customer. Can include HTML."
      label="Message"
    >
      <TextInput
        lines={InputLines.MULTI}
        onChange={(message) => setState((state) => ({ ...state, message }))}
        readonly={!canEditSettings}
        theme={InputTheme.FORM}
        value={state.message}
      />
    </LabeledInput>
  );
});

export const BLOCK: StepType<State, ModelStep.Block> = {
  Details,
  customTitle(state) {
    return state.customTitle;
  },
  description(state: State) {
    return state.message;
  },
  downstream() {
    return [];
  },
  empty: { message: "" },
  layout() {
    return BlockLayout.FULL;
  },
  stepDeleted(state) {
    return state;
  },
  title: "Block",
  valid(state) {
    return !!state.message;
  },
  fromModel(model: ModelStep.Block) {
    return { customTitle: model.customTitle, message: model.message };
  },
  toModel(state: State) {
    return {
      customTitle: state.customTitle,
      type: ModelStep.BLOCK,
      message: state.message,
    };
  },
  Icon: CancelIcon,
};
