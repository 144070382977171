import { NonIndexRouteObject } from "react-router-dom";

export function inAiKnowledgeView(url: string) {
  return url.includes("/ai/knowledge");
}

export const aiSettingsRoutes: NonIndexRouteObject = {
  handle: { breadcrumb: "AI", breadcrumbNavigate: false },
  path: "ai",
  children: [
    {
      handle: { breadcrumb: "Product meta fields" },
      path: "product-meta-fields",
      lazy: async () => {
        const { ContextSettingsPage } = await import("./context-settings");
        return { Component: ContextSettingsPage };
      },
    },
    {
      path: "general",
      handle: { breadcrumb: "General" },
      lazy: async () => {
        const { GeneralSettingsPage } = await import("./general");
        return { Component: GeneralSettingsPage };
      },
    },
    {
      path: "support",
      handle: { breadcrumb: "Support AI" },
      lazy: async () => {
        const { ManageSupportAiPage } = await import("./manage-support-ai");
        return { Component: ManageSupportAiPage };
      },
    },
    {
      path: "skills",
      handle: { breadcrumb: "Skills" },
      lazy: async () => {
        const { SkillsSettingsPage } = await import("./skills");
        return { Component: SkillsSettingsPage };
      },
    },
    {
      path: "knowledge",
      handle: { breadcrumb: "Knowledge" },
      lazy: async () => {
        const { ConciergeKnowledgePage } = await import("./knowledge/page");
        return { Component: ConciergeKnowledgePage };
      },
    },
  ],
};
