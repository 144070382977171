import { useRequiredContext } from "@redotech/react-util/context";
import { useLoad } from "@redotech/react-util/load";
import { RedoMerchantRpcClientContext } from "@redotech/redo-merchant-app-common/rpc-client";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { TelnyxRTCProvider } from "@telnyx/react-client";
import jwtDecode from "jwt-decode";

const LOCAL_STORAGE_KEY = "telnyx-voice-token";

/**
 * This is provided globally on purpose.
 */
export function SupportVoiceProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const client = useRequiredContext(RedoMerchantRpcClientContext);
  const team = useRequiredContext(TeamContext);

  const isVoiceEnabled = !!team.settings.support?.voice?.enabled;

  const tokenLoad = useLoad(async (signal) => {
    if (!isVoiceEnabled) {
      return null;
    }

    const existingToken = localStorage.getItem(LOCAL_STORAGE_KEY);

    if (existingToken) {
      const decodedToken = jwtDecode(existingToken) as { exp: number };

      const tokenHasExpired = decodedToken.exp * 1000 < Date.now();

      if (!tokenHasExpired) {
        return existingToken;
      }
    }

    const { token }: { token: string } = await client.getTelnyxTelephonyToken(
      {},
      { signal },
    );

    localStorage.setItem(LOCAL_STORAGE_KEY, token);

    return token;
  }, []);

  if (tokenLoad.value) {
    return (
      <TelnyxRTCProvider
        credential={{ login_token: tokenLoad.value }}
        // Adds debugging information to the console
        // options={{ debug: true }}
      >
        {children}
      </TelnyxRTCProvider>
    );
  } else {
    return children;
  }
}
