import { z } from "zod";
import { zExt } from "../common/zod-util";

export enum ScanFormStatus {
  Creating = "creating",
  Created = "created",
  Error = "failed",
}

export enum BatchState {
  Creating = "creating",
  CreationFailed = "creation_failed",
  Created = "created",
  Purchasing = "purchasing",
  PurchasedFailed = "purchased_failed",
  Purchased = "purchased",
  LabelGenerating = "label_generating",
  LabelGenerated = "label_generated",
}

export function isBatchEventActionState(state: BatchState): boolean {
  const actionStates = [
    BatchState.LabelGenerating,
    BatchState.LabelGenerated,
    BatchState.Created,
    BatchState.Purchased,
  ];
  return actionStates.includes(state);
}

export const batchEventSchema = z.object({
  id: z.string(),
  state: z.nativeEnum(BatchState),
});

export type BatchEvent = z.infer<typeof batchEventSchema>;

export const scanFormStatusInfoSchema = z.object({
  id: z.string(),
  status: z.nativeEnum(ScanFormStatus),
});

export type ScanFormStatusInfo = z.infer<typeof scanFormStatusInfoSchema>;

export const easypostScanForm = z.object({
  id: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
  tracking_codes: z.array(z.string()).nullish(),
  address: z
    .object({
      id: z.string(),
      name: z.string().nullish(),
      street1: z.string().nullish(),
      street2: z.string().nullish(),
      city: z.string().nullish(),
      state: z.string().nullish(),
      zip: z.string().nullish(),
      country: z.string().nullish(),
      phone: z.string().nullish(),
      email: z.string().nullish(),
      carrier_facility: z.string().nullish(),
      residential: z.boolean().nullish(),
      federal_tax_id: z.string().nullish(),
      state_tax_id: z.string().nullish(),
    })
    .nullish(),
  status: z.nativeEnum(ScanFormStatus),
  message: z.string().nullish(),
  form_url: z.string().nullish(),
  form_file_type: z.string().nullish(),
  batch_id: z.string().nullish(),
});

export type EasypostScanForm = z.infer<typeof easypostScanForm>;

export const RedoScanFormSchema = z.object({
  id: z.string(),
  externalCreatedAt: z.date(),
  externalUpdatedAt: z.date(),
  trackingCodes: z.array(z.string()),
  address: z
    .object({
      id: z.string(),
      name: z.string().nullish(),
      street1: z.string().nullish(),
      street2: z.string().nullish(),
      city: z.string().nullish(),
      state: z.string().nullish(),
      zip: z.string().nullish(),
      country: z.string().nullish(),
      phone: z.string().nullish(),
      email: z.string().nullish(),
      carrierFacility: z.string().nullish(),
      residential: z.boolean().nullish(),
      federalTaxId: z.string().nullish(),
      stateTaxId: z.string().nullish(),
    })
    .nullish(),
  status: z.nativeEnum(ScanFormStatus),
  message: z.string().nullish(),
  formUrl: z.string(),
  formUrl4x6: z.string().nullish(),
  formFileType: z.string().nullish(),
  batchId: z.string().nullish(),
  confirmation: z.string().nullish(),
});

export type RedoScanForm = z.infer<typeof RedoScanFormSchema>;

export const EndOfDayManifestSchema = z.object({
  _id: zExt.objectId(),
  team: zExt.objectId(),
  carrier: z.string(),
  carrierAccountId: z.string().nullish(),
  fulfillmentGroupIds: z.array(zExt.objectId()),
  location: z.string().nullish(),
  batch: z
    .object({ id: z.string(), state: z.nativeEnum(BatchState) })
    .nullish(),
  scanForm: RedoScanFormSchema.nullish(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type EndOfDayManifest = z.infer<typeof EndOfDayManifestSchema>;

export const CreateEndOfDayManifestSchema = EndOfDayManifestSchema.omit({
  _id: true,
  createdAt: true,
  updatedAt: true,
});

export type CreateEndOfDayManifest = z.infer<
  typeof CreateEndOfDayManifestSchema
>;

export const printDocumentScanShortcutSchema = z.object({
  printLabel: z.boolean(),
  printPackingSlip: z.boolean(),
  printPickList: z.boolean(),
});
export type PrintDocumentScanShortcut = z.infer<
  typeof printDocumentScanShortcutSchema
>;

export const printDocumentScanShortcutDisplayNames: Record<
  keyof PrintDocumentScanShortcut,
  string
> = {
  printLabel: "Print label",
  printPackingSlip: "Print packing slip",
  printPickList: "Print pick list",
};

export const weightScanShortcutSchema = z.object({
  focusInput: z.boolean(),
  readFromScale: z.boolean(),
});
export type WeightScanShortcut = z.infer<typeof weightScanShortcutSchema>;
export const weightScanShortcutDisplayNames: Record<
  keyof WeightScanShortcut,
  string
> = { focusInput: "Focus input", readFromScale: "Read from scale" };

export interface BarcodeProps {
  hotkey: string;
  title: string;
}

export const printHotkeyBinds: Record<keyof PrintDocumentScanShortcut, string> =
  { printLabel: "p", printPackingSlip: "s", printPickList: "l" };

export const weightScanHotkeyBinds: Record<keyof WeightScanShortcut, string> = {
  focusInput: "i",
  readFromScale: "r",
};

function getHotkey(key: string) {
  return `⌘${key.toUpperCase()}`;
}

export const printHotkeys: Record<
  keyof PrintDocumentScanShortcut,
  BarcodeProps
> = {
  printLabel: {
    title: printDocumentScanShortcutDisplayNames.printLabel,
    hotkey: getHotkey(printHotkeyBinds.printLabel),
  },
  printPackingSlip: {
    title: printDocumentScanShortcutDisplayNames.printPackingSlip,
    hotkey: getHotkey(printHotkeyBinds.printPackingSlip),
  },
  printPickList: {
    title: printDocumentScanShortcutDisplayNames.printPickList,
    hotkey: getHotkey(printHotkeyBinds.printPickList),
  },
};

export const weightScanHotkeys: Record<keyof WeightScanShortcut, BarcodeProps> =
  {
    focusInput: {
      title: weightScanShortcutDisplayNames.focusInput,
      hotkey: getHotkey(weightScanHotkeyBinds.focusInput),
    },
    readFromScale: {
      title: weightScanShortcutDisplayNames.readFromScale,
      hotkey: getHotkey(weightScanHotkeyBinds.readFromScale),
    },
  };
