import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const deleteShippingMethod = {
  input: z.object({
    checkoutExperienceId: zExt.objectId(),
    shippingMethodId: zExt.objectId(),
  }),
  output: z.null(),
};
