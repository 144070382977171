import { Order } from "@redotech/redo-model/order";

import { MerchantAppReturn } from "@redotech/redo-model/return";
import { getDateString } from "@redotech/redo-web/date-utils";
import { ExternalLink } from "@redotech/redo-web/link";
import { memo } from "react";
import { DaysSinceDelivered } from "../return-detail-cards/days-since-delivered";
import * as returnCss from "./details-card.module.css";

export const OrderSubtitle = memo(function OrderSubtitle({
  return_,
  orders,
  discountCodes,
  returnConfirmationHandler,
}: {
  return_: MerchantAppReturn;
  orders: Order[];
  discountCodes: string[];
  returnConfirmationHandler: (
    event: React.MouseEvent<HTMLAnchorElement>,
  ) => void;
}) {
  return (
    <div className={returnCss.subtitleText}>
      {orders.map((order) => (
        <div className={returnCss.headerSubtitleSmall} key={order.shopify.name}>
          {orders.length > 1 ? `${order.shopify.name} ordered: ` : "Ordered: "}
          {getDateString(new Date(order.shopifyCreatedAt))}
        </div>
      ))}
      <div className={returnCss.headerSubtitleSmall}>
        {"Created: " + getDateString(new Date(return_.createdAt))}
      </div>
      <div className={returnCss.headerSubtitleSmall}>
        <ExternalLink
          className={returnCss.linkColor}
          onClick={returnConfirmationHandler}
        >
          Return confirmation
        </ExternalLink>
      </div>
      {/* Custom component for Buttons Bebe, see explanation in the component */}
      {return_.team._id === "64c84223d5e3c700074d73c5" && (
        <div className={returnCss.headerSubtitleSmall}>
          <DaysSinceDelivered return_={return_} />
        </div>
      )}
    </div>
  );
});
