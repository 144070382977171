import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import { useTriggerLoad } from "@redotech/react-util/load";
import { RedoMerchantClient } from "@redotech/redo-merchant-app-common/client";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { updateEmailExclusions } from "@redotech/redo-merchant-app-common/client/team";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { getPrimaryCustomerEmail } from "@redotech/redo-model/customer";
import { CloseActionMethod, Team } from "@redotech/redo-model/team";
import { alertOnFailure } from "@redotech/redo-web/alert";
import { RedoProgressBar } from "@redotech/redo-web/arbiter-components/progress-bar/redo-progress-bar";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { Modal, ModalSize } from "@redotech/redo-web/modal";
import { filterTruthy } from "@redotech/util/array";
import { Dispatch, memo, SetStateAction, useMemo, useState } from "react";
import {
  bulkRemoveTags,
  bulkUpdateConversationsStatus,
  updateConversation,
} from "../../client/conversations";
import { UpdateConversationStateContext } from "../context/update-conversations-context";
import * as markSpamModalCss from "./spam-modals.module.css";

async function handleUnmarkTicketsSpam({
  client,
  team,
  conversations,
  setActiveConversation,
  setConversationsInProximity,
  inDetailView,
  cleanupFn,
  removeConversation,
  nextConversationInList,
  prevConversationInList,
}: {
  client: RedoMerchantClient;
  team: Team;
  conversations: ExpandedConversation[];
  setActiveConversation: (val: ExpandedConversation | undefined) => void;
  setConversationsInProximity?: Dispatch<
    SetStateAction<ExpandedConversation[]>
  >;
  inDetailView: boolean;
  cleanupFn?: (clearSelected?: boolean) => void;
  removeConversation: (conversation: ExpandedConversation) => void;
  nextConversationInList?: ExpandedConversation;
  prevConversationInList?: ExpandedConversation;
}) {
  if (conversations.length === 1) {
    setConversationsInProximity &&
      setConversationsInProximity((prev: ExpandedConversation[]) => [
        ...prev.filter((conv) => conversations[0]._id !== conv._id),
      ]);
    removeConversation(conversations[0]);
    const customerEmailAddress = getPrimaryCustomerEmail(
      conversations[0]?.customer,
    );

    const tagsWithoutSpam = conversations[0].tagIds?.filter(
      (tag) => tag.name !== "spam",
    );

    void alertOnFailure("Failed to update conversation")((async) =>
      updateConversation(client, conversations[0], {
        status: "open",
        tags: tagsWithoutSpam,
      }),
    );

    if (customerEmailAddress && team.settings.support?.enabled) {
      void alertOnFailure("Failed to add sender to exclude filter")(() =>
        updateEmailExclusions(client, {
          exclusionList: [
            ...(team.settings.support?.excludedEmails || []).filter(
              (email) => email !== customerEmailAddress,
            ),
          ],
        }),
      );
    }

    if (inDetailView) {
      if (team.settings.support?.closeAction === CloseActionMethod.NEXT) {
        setActiveConversation(nextConversationInList || prevConversationInList);
      } else if (
        team.settings.support?.closeAction === CloseActionMethod.STAY
      ) {
        setActiveConversation({ ...conversations[0], status: "open" });
      } else if (
        team.settings.support?.closeAction === CloseActionMethod.TABLE
      ) {
        setActiveConversation(undefined);
      }
    }

    amplitude.logEvent("unmarkSpam-conversation", {
      mode: "single",
      conversationIds: [conversations[0]._id],
    });
  } else if (conversations.length > 1) {
    const updatePromises = [];

    const conversationIdsToOpen = conversations
      .filter((conversation) => conversation.status !== "open")
      .map((conversation) => conversation._id);
    if (conversationIdsToOpen.length > 0) {
      updatePromises.push(
        bulkUpdateConversationsStatus(client, {
          conversationIds: conversationIdsToOpen,
          status: "open",
        }),
      );
    }

    const conversationIdsToRemoveSpamTag = conversations
      .filter((conversation) =>
        (conversation.tagIds || []).some((tag) => tag.name === "spam"),
      )
      .map((conversation) => conversation._id);
    updatePromises.push(
      alertOnFailure("Failed to add spam tag")(async () =>
        bulkRemoveTags(client, {
          conversationIds: conversationIdsToRemoveSpamTag,
          tags: [{ name: "spam" }],
        }),
      ),
    );

    const emailsToRemoveFromExclusionList = filterTruthy(
      conversations.map((conversation) =>
        getPrimaryCustomerEmail(conversation.customer),
      ),
    );

    if (team.settings.support?.enabled) {
      const newEmailExclusions =
        team.settings.support.excludedEmails?.filter(
          (email) => !emailsToRemoveFromExclusionList.includes(email),
        ) || [];
      void alertOnFailure("Failed to add sender to exclude filter")(() =>
        updateEmailExclusions(client, { exclusionList: newEmailExclusions }),
      );
      amplitude.logEvent("unmarkSpam-conversation", {
        mode: "multiple",
        conversationIds: conversations.map((conversation) => conversation._id),
      });
    }
    await Promise.all(updatePromises);
  }
  cleanupFn && cleanupFn(true);
}

export const UnmarkSpamModal = memo(function UnmarkSpamModal({
  open,
  setOpen,
  conversations,
  setActiveConversation,
  setConversationsInProximity,
  inDetailView,
  cleanupFn,
  nextConversationInList,
  prevConversationInList,
  selectAllInfo,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  conversations: ExpandedConversation[];
  setActiveConversation: (val: ExpandedConversation | undefined) => void;
  setConversationsInProximity?: Dispatch<
    SetStateAction<ExpandedConversation[]>
  >;
  inDetailView: boolean;
  cleanupFn?: (clearSelected?: boolean) => void;
  nextConversationInList?: ExpandedConversation;
  prevConversationInList?: ExpandedConversation;
  selectAllInfo?: {
    count: number;
    deselectedConversations: ExpandedConversation[];
    setBlockRefresh: (block: boolean) => void;
    bulkActionIterator: AsyncIterator<{
      conversations: ExpandedConversation[];
    }>;
  };
}) {
  const client = useRequiredContext(RedoMerchantClientContext);
  const team = useRequiredContext(TeamContext);
  const { removeConversation } = useRequiredContext(
    UpdateConversationStateContext,
  );
  const conversationsCurrentlyMarkedAsSpam = useMemo(
    () =>
      conversations.filter((conversation) =>
        (conversation.tagIds || []).some((tag) => tag.name === "spam"),
      ),
    [conversations],
  );
  const [updatingSelectAll, setUpdatingSelectAll] = useState(false);
  const [numDone, setNumDone] = useState(0);

  const handleSelectAllUnmarkSpam = async () => {
    if (!selectAllInfo || !team) return;

    try {
      setUpdatingSelectAll(true);
      selectAllInfo.setBlockRefresh(true);
      let done = false;

      while (!done) {
        const nextBatch = await selectAllInfo.bulkActionIterator.next();
        done = nextBatch.done || false;

        if (!nextBatch.value) continue;

        const batchConversations: ExpandedConversation[] =
          nextBatch.value.conversations.filter(
            (conversation: ExpandedConversation) =>
              !selectAllInfo.deselectedConversations.some(
                (deselected) => deselected._id === conversation._id,
              ),
          );

        if (batchConversations.length > 0) {
          await handleUnmarkTicketsSpam({
            client,
            team,
            conversations: batchConversations,
            setActiveConversation,
            inDetailView,
            removeConversation,
          });
        }

        setNumDone((prev) => prev + batchConversations.length);
      }

      setUpdatingSelectAll(false);
      selectAllInfo.setBlockRefresh(false);
      cleanupFn && cleanupFn(true);

      amplitude.logEvent("unmarkSpam-conversation", {
        mode: "all",
        filterCount: selectAllInfo.count,
      });

      setOpen(false);
    } catch (error) {
      console.error("Error in bulk unmark spam:", error);
      setUpdatingSelectAll(false);
      selectAllInfo.setBlockRefresh(false);
    }
  };

  const [unmarkSpamLoad, doUnmarkSpam] = useTriggerLoad(async (signal) => {
    if (selectAllInfo) {
      await handleSelectAllUnmarkSpam();
    } else {
      await handleUnmarkTicketsSpam({
        client,
        team,
        conversations: conversationsCurrentlyMarkedAsSpam,
        setActiveConversation,
        setConversationsInProximity,
        inDetailView,
        removeConversation,
        nextConversationInList,
        prevConversationInList,
      });
    }
    setOpen(false);
  });

  const count = selectAllInfo
    ? selectAllInfo.count - (selectAllInfo.deselectedConversations?.length || 0)
    : conversationsCurrentlyMarkedAsSpam.length;

  const plural = count > 1;

  const footer = updatingSelectAll ? null : (
    <div className={markSpamModalCss.actionButtonsContainer}>
      <Button onClick={() => setOpen(false)} theme={ButtonTheme.OUTLINED}>
        Cancel
      </Button>
      <Button
        onClick={() => doUnmarkSpam()}
        pending={unmarkSpamLoad.pending}
        theme={ButtonTheme.PRIMARY}
      >
        Yes, unmark {selectAllInfo ? "all " : ""}ticket{plural ? "s" : ""} as
        spam
      </Button>
    </div>
  );

  return (
    <Modal
      footer={footer}
      onClose={() => {
        cleanupFn && cleanupFn();
        setOpen(false);
      }}
      open={open}
      showHeaderBorder={false}
      size={ModalSize.SMALL}
      title={
        updatingSelectAll
          ? "Unmarking tickets as spam"
          : selectAllInfo
            ? `Unmark ${count} tickets as spam?`
            : plural
              ? `Unmark ${conversationsCurrentlyMarkedAsSpam.length} tickets as spam?`
              : `Unmark ticket as spam?`
      }
    >
      <div className={markSpamModalCss.actionModalContentContainer}>
        {updatingSelectAll ? (
          <RedoProgressBar
            percentage={(numDone / (selectAllInfo?.count || numDone)) * 100}
          />
        ) : (
          <div>
            {selectAllInfo
              ? `All ${count} selected tickets that have a spam tag will be reopened, have the "spam" tag removed, and the senders' email addresses will be removed from your team's exclusion list.`
              : `${plural ? "These tickets" : "This ticket"} will be reopened, have the "spam" tag removed and the${plural ? " senders' email addresses " : " sender's email address "}will be removed from your team's exclusion list.`}
          </div>
        )}
      </div>
    </Modal>
  );
});
