import { CheckoutOptimizationBillingPeriod } from "@redotech/redo-model/billing/service-invoice";
import { z } from "zod";

export const checkoutOptimizationServiceInvoiceStatus = z.object({
  period: z.nativeEnum(CheckoutOptimizationBillingPeriod),
  billingDueDate: z.date(),
  estimatedInvoiceAmount: z.number(),
  billableOrdersThisPeriod: z.number(),
  pricePerOrderInCents: z.number(),
});
export type CheckoutOptimizationServiceInvoiceStatus = z.infer<
  typeof checkoutOptimizationServiceInvoiceStatus
>;

export const getCurrentCheckoutOptimizationInvoice = {
  input: z.null(),
  output: checkoutOptimizationServiceInvoiceStatus.nullable(),
};
