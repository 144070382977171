import { z, ZodNumber, ZodString } from "zod";
import {
  annualDateTimeframeSchema,
  fullDateTimeframeSchema,
} from "./segment-timeframe-zod-schema";
import {
  ComparisonType,
  ListCompareOperators,
  NumericCompareOperator,
  ProximityCompareOperators,
  ProximityUnit,
  TokenCompareOperators,
  WhereConditionDataType,
} from "./segment-where-condition";

const listCompareSchema = (zType: ZodNumber | ZodString) =>
  z.object({
    type: z.literal(ComparisonType.LIST),
    operator: z.nativeEnum(ListCompareOperators),
    values: z.array(zType),
  });

const tokenCompareSchema = z.object({
  type: z.literal(ComparisonType.TOKEN),
  operator: z.nativeEnum(TokenCompareOperators),
  values: z.array(z.string()),
});

export type TokenCondition = z.infer<typeof tokenConditionSchema>;
const tokenConditionSchema = z.object({
  type: z.literal(WhereConditionDataType.TOKEN),
  dimension: z.string(),
  comparison: tokenCompareSchema,
});

const numericCompareSchema = z.object({
  type: z.literal(ComparisonType.NUMERIC),
  operator: z.nativeEnum(NumericCompareOperator),
  value: z.number(),
});

export type NumericCondition = z.infer<typeof numericConditionSchema>;
const numericConditionSchema = z.object({
  type: z.literal(WhereConditionDataType.NUMERIC),
  dimension: z.string(),
  comparison: numericCompareSchema,
});

const booleanCompareSchema = z.object({
  type: z.literal(ComparisonType.BOOLEAN),
  value: z.boolean(),
});

export type BooleanCondition = z.infer<typeof booleanConditionSchema>;
const booleanConditionSchema = z.object({
  type: z.literal(WhereConditionDataType.BOOLEAN),
  dimension: z.string(),
  comparison: booleanCompareSchema,
});

export type FullDateCondition = z.infer<typeof fullDateConditionSchema>;
const fullDateConditionSchema = z.object({
  type: z.literal(WhereConditionDataType.DATE_FULL),
  dimension: z.string(),
  comparison: fullDateTimeframeSchema,
});

export type AnnualDateCondition = z.infer<typeof annualDateConditionSchema>;
const annualDateConditionSchema = z.object({
  type: z.literal(WhereConditionDataType.DATE_ANNUAL),
  dimension: z.string(),
  comparison: annualDateTimeframeSchema,
});

export type TokenListCondition = z.infer<typeof tokenListConditionSchema>;
const tokenListConditionSchema = z.object({
  type: z.literal(WhereConditionDataType.TOKEN_LIST),
  dimension: z.string(),
  comparison: listCompareSchema(z.string()),
});

const tokenHierarchyCompareSchema = z.object({
  type: z.literal(ComparisonType.TOKEN),
  operator: z.nativeEnum(TokenCompareOperators),
  prerequisiteValues: z.array(z.string()),
  values: z.array(z.string()),
});
export type TokenHierarchyCondition = z.infer<
  typeof tokenHierarchyConditionSchema
>;
export const tokenHierarchyConditionSchema = z.object({
  type: z.literal(WhereConditionDataType.TOKEN_HIERARCHY),
  dimension: z.string(),
  comparison: tokenHierarchyCompareSchema,
});

const cityProximityCompareSchema = z.object({
  type: z.literal(ComparisonType.PROXIMITY),
  operator: z.nativeEnum(ProximityCompareOperators),
  prerequisiteValues: z.array(z.string()), // [country, state]
  value: z.string(), // city
  options: z.object({ radius: z.number(), units: z.nativeEnum(ProximityUnit) }),
});

export type CityProximityCondition = z.infer<
  typeof cityProximityConditionSchema
>;
const cityProximityConditionSchema = z.object({
  type: z.literal(WhereConditionDataType.CITY_PROXIMITY),
  dimension: z.string(),
  comparison: cityProximityCompareSchema,
});

// const postalProximityCompareSchema = z.object({
//   type: z.literal(ComparisonType.PROXIMITY),
//   operator: z.nativeEnum(ProximityCompareOperators),
//   value: z.string(), // postal code
//   options: z.object({ radius: z.number(), units: z.nativeEnum(ProximityUnit) }),
// });

// export type PostalProximityCondition = z.infer<
//   typeof postalProximityConditionSchema
// >;
// const postalProximityConditionSchema = z.object({
//   type: z.literal(WhereConditionDataType.POSTAL_PROXIMITY),
//   dimension: z.string(),
//   comparison: postalProximityCompareSchema,
// });

export type WhereCondition = z.infer<typeof whereConditionSchema>;
export const whereConditionSchema = z.discriminatedUnion("type", [
  tokenConditionSchema,
  numericConditionSchema,
  booleanConditionSchema,
  fullDateConditionSchema,
  annualDateConditionSchema,
  tokenListConditionSchema,
  tokenHierarchyConditionSchema,
  cityProximityConditionSchema,
  // postalProximityConditionSchema,
]);
