import { assertNever } from "@redotech/util/type";
import { z } from "zod";
import { ReportFilterType } from "./report-filter-type";

export enum DateFilterQuery {
  BEFORE = "before",
  AFTER = "after",
  WITHIN = "within",
}

export enum DateFilterValue {
  TODAY = "today",
  THIS_WEEK = "this week",
  THIS_MONTH = "this month",
  LAST_3_MONTHS = "last 3 months",
  THIS_YEAR = "this year",
}

export function makeReportDateFilterSchema<Type extends ReportFilterType>(
  type: Type,
) {
  return z.union([
    z.object({
      type: z.literal(type),
      query: z.union([
        z.literal(DateFilterQuery.BEFORE),
        z.literal(DateFilterQuery.AFTER),
      ]),
      value: z
        .union([
          z.nativeEnum(DateFilterValue),
          // Only allow Date when operator is not WITHIN
          // Coerce to allow filters to be stringified in search params
          z.coerce.date(),
        ])
        .optional(),
    }),
    z.object({
      type: z.literal(type),
      query: z.literal(DateFilterQuery.WITHIN),
      value: z
        .union([
          z.nativeEnum(DateFilterValue),
          // Only allow [Date, Date] when operator is WITHIN
          // Coerce to allow filters to be stringified in search params
          z.tuple([z.coerce.date(), z.coerce.date()]).readonly(),
        ])
        .optional(),
    }),
  ]);
}

export type BaseReportDateFilter<Type extends ReportFilterType> = z.infer<
  ReturnType<typeof makeReportDateFilterSchema<Type>>
>;

function startOf(
  value: DateFilterValue,
  merchantTimezone: string,
): Temporal.PlainDate {
  const startOfToday =
    Temporal.Now.zonedDateTimeISO(merchantTimezone).toPlainDate();
  return {
    [DateFilterValue.TODAY]: startOfToday,
    [DateFilterValue.THIS_WEEK]: startOfToday.subtract({
      days: startOfToday.dayOfWeek - 1,
    }),
    [DateFilterValue.THIS_MONTH]: startOfToday.subtract({
      days: startOfToday.day - 1,
    }),
    [DateFilterValue.LAST_3_MONTHS]: startOfToday
      .subtract({ days: startOfToday.day - 1 })
      .subtract({ months: 2 }),
    [DateFilterValue.THIS_YEAR]: startOfToday.subtract({
      days: startOfToday.dayOfYear - 1,
    }),
  }[value];
}

function endOf(
  value: DateFilterValue,
  merchantTimezone: string,
): Temporal.PlainDate {
  return {
    [DateFilterValue.TODAY]: startOf(value, merchantTimezone).add({ days: 1 }),
    [DateFilterValue.THIS_WEEK]: startOf(value, merchantTimezone).add({
      days: 7,
    }),
    [DateFilterValue.THIS_MONTH]: startOf(value, merchantTimezone).add({
      months: 1,
    }),
    [DateFilterValue.LAST_3_MONTHS]: startOf(
      DateFilterValue.TODAY,
      merchantTimezone,
    ), // Is "after the last 3 months" just "after today"?
    [DateFilterValue.THIS_YEAR]: startOf(value, merchantTimezone).add({
      years: 1,
    }),
  }[value];
}

export function dateFilterToPlainDateRange(
  filter: BaseReportDateFilter<ReportFilterType>,
  merchantTimezone: string,
): {
  start: Temporal.PlainDate | null;
  end: Temporal.PlainDate | null;
  inclusive: boolean;
} {
  if (!filter.value) {
    return { start: null, end: null, inclusive: false };
  }
  if (filter.query === DateFilterQuery.BEFORE) {
    return {
      start: null,
      end:
        filter.value instanceof Date
          ? dateInUTCToPlainDate(filter.value)
          : startOf(filter.value, merchantTimezone),
      inclusive: false,
    };
  } else if (filter.query === DateFilterQuery.AFTER) {
    return {
      start:
        filter.value instanceof Date
          ? dateInUTCToPlainDate(filter.value)
          : endOf(filter.value, merchantTimezone),
      end: null,
      inclusive: false,
    };
  } else if (filter.query === DateFilterQuery.WITHIN) {
    if (filter.value instanceof Array) {
      // https://github.com/microsoft/TypeScript/issues/17002
      const [start, end] = filter.value;
      return {
        start: dateInUTCToPlainDate(start),
        end: dateInUTCToPlainDate(end),
        inclusive: true,
      };
    }
    return {
      start: startOf(filter.value, merchantTimezone),
      end: endOf(filter.value, merchantTimezone),
      inclusive: true,
    };
  } else {
    assertNever(filter.query);
  }
}

function dateInUTCToPlainDate(date: Date): Temporal.PlainDate {
  return Temporal.Instant.from(date.toISOString())
    .toZonedDateTime({ timeZone: "UTC", calendar: "iso8601" })
    .toPlainDate();
}
