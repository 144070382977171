import { baseProductFilterSchema } from "@redotech/redo-model/product-filters/product-filters-db-parser";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const updateProductFilter = {
  input: z.object({
    _id: zExt.objectId(),
    updatedFilter: baseProductFilterSchema,
  }),
  output: z.intersection(
    z.object({ _id: zExt.objectId() }),
    baseProductFilterSchema,
  ),
};
