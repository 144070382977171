import {
  FilterGroupFilterOption,
  ReadStatusFilter as ModelReadStatusFilter,
  PendingFilter,
} from "@redotech/redo-model/conversation-filters/conversation-filters";
import {
  RedoFilterGroup,
  RedoFilterSection,
} from "@redotech/redo-web/arbiter-components/filter-group/redo-filter-group";
import {
  ConversationFilterOptionToIcon,
  ConversationFilterOptionToName,
} from "@redotech/redo-web/conversation-filters/conversation-filter-icons";
import { SimpleFilterDropdown } from "@redotech/redo-web/filters/simple-filter-dropdown";
import { memo } from "react";

export const ReadStatusFilter = memo(function ConversationTagFilter({
  readStatus,
  setReadStatus,
  removeFilter,
  openOnRender,
}: {
  readStatus: ModelReadStatusFilter | PendingFilter<ModelReadStatusFilter>;
  setReadStatus: (
    status: ModelReadStatusFilter | PendingFilter<ModelReadStatusFilter>,
  ) => void;
  removeFilter(): void;
  openOnRender: boolean;
}) {
  const query = <RedoFilterSection color="secondary" text="is" />;

  return (
    <RedoFilterGroup
      Icon={ConversationFilterOptionToIcon[FilterGroupFilterOption.READ]}
      propertyName={
        ConversationFilterOptionToName[FilterGroupFilterOption.READ]
      }
      query={query}
      removeFilter={removeFilter}
      value={
        <SimpleFilterDropdown<ReadStatus>
          filterStyle="value"
          openOnRender={openOnRender}
          options={["true", "false"]}
          optionToFriendlyName={friendlyValue}
          setValue={(value) =>
            setReadStatus({
              ...readStatus,
              value: readStatusToTypedData(value),
            })
          }
          value={typedDataToReadStatus(readStatus.value)}
        />
      }
    />
  );
});

function typedDataToReadStatus(read: boolean | null): ReadStatus {
  if (read === null) {
    return "null";
  } else if (read) {
    return "true";
  } else {
    return "false";
  }
}

function readStatusToTypedData(read: ReadStatus): boolean | null {
  if (read === "null") {
    return null;
  } else if (read === "true") {
    return true;
  } else {
    return false;
  }
}

const friendlyValue = (read: ReadStatus) => {
  if (read === "null") {
    return "...";
  } else if (read === "true") {
    return "Read";
  } else {
    return "Unread";
  }
};

const readStatus = ["true", "false", "null"] as const;
type ReadStatus = (typeof readStatus)[number];
