import { genericMemo } from "@redotech/react-util/component";
import { useScrolled } from "@redotech/react-util/scroll";
import * as classNames from "classnames";
import * as React from "react";
import { CSSProperties, memo, useEffect, useRef } from "react";
import { Flex } from "../../flex";
import { Spinner } from "../../spinner";
import { RowClickHandler } from "../../table";
import { Text } from "../../text";
import { RedoTableCellProps } from "./redo-table-cells";
import * as redoTableCellsCss from "./redo-table-cells.module.css";
import { RedoTableRow } from "./redo-table-row";
import * as redoTableCss from "./redo-table.module.css";

export const redoTableSize = ["sm", "md"] as const;
export type RedoTableSize = (typeof redoTableSize)[number];

export const redoTableColumnWidthType = ["fixed", "hug", "fill"] as const;
export type RedoTableColumnWidthType =
  (typeof redoTableColumnWidthType)[number];

export type RedoTableColumnWidthMode =
  | { type: "fixed"; width: CSSProperties["width"] }
  | { type: "hug" }
  | {
      type: "fill";
      /** Will map 1 -> "1fr" https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_grid_layout/Basic_concepts_of_grid_layout#the_fr_unit*/
      relativeLength?: number;
      minLength?: CSSProperties["width"];
    };

export const redoTableColumnAlignment = ["left", "right", "center"] as const;
export type RedoTableColumnAlignment =
  (typeof redoTableColumnAlignment)[number];

export type RedoTableColumn<DATA> = {
  key?: string;
  widthMode?: RedoTableColumnWidthMode;
  renderHeaderCell: () => React.ReactNode;
  renderNormalCell: (props: RedoTableCellProps<DATA>) => React.ReactNode;
  alignment?: RedoTableColumnAlignment;
};

export type OnVisibleHandlers = {
  setTopVisible: (topIsVisible: boolean) => void;
  setBottomVisible: (bottomIsVisible: boolean) => void;
};

export const redoTableHeaderColor = ["white", "grey"] as const;
export type RedoTableHeaderColor = (typeof redoTableHeaderColor)[number];

export interface RedoTableLayout<DATA> {
  columns: RedoTableColumn<DATA>[];
  headerColor?: RedoTableHeaderColor;
}

export interface RedoTableProps<DATA> {
  size?: RedoTableSize;
  layout: RedoTableLayout<DATA>;
  rowsOfData: DATA[];
  rowClassName?: (row: DATA, rowIndex: number) => string | undefined;
  onRowClick?: (
    row: DATA,
    rowIndex: number,
    sourceEvent: React.MouseEvent,
  ) => void;
  onContextMenu?: RowClickHandler<DATA>;
  onRowHovered?(record?: DATA): void;
  rowsSelected?: boolean[];
  onVisibleHandlers?: OnVisibleHandlers;
  emptyStateContent?: React.ReactNode;
  loadingStateContent?: React.ReactNode;
  additionalClasses?: string;
  hideHeaderWhenEmpty?: boolean;
}

export const tableDimensionToGridTemplateStyles = (
  dim: RedoTableColumnWidthMode,
) => {
  return dim.type === "fixed"
    ? typeof dim.width === "number"
      ? `minmax(${dim.width}px, 0fr)`
      : `minmax(${dim.width}, 0fr)`
    : dim.type === "hug"
      ? `min-content`
      : typeof dim.minLength === "number"
        ? `minmax(${dim.minLength || 0}px, ${dim.relativeLength || 1}fr)`
        : `minmax(${dim.minLength || 0}, ${dim.relativeLength || 1}fr)`;
};

export const RedoTableHeaderRow = genericMemo(function RedoTableHeaderRow<
  DATA,
>({
  headerColor = "grey",
  columns,
  size = "sm",
}: {
  headerColor?: RedoTableHeaderColor;
  columns: RedoTableColumn<DATA>[];
  size?: RedoTableSize;
}) {
  const styleForHeaderRow = sizeToTemplateHeaderRowHeight[size];
  return (
    <>
      {columns.map((column, columnIndex) => (
        <div
          className={classNames(
            redoTableCellsCss.cell,
            redoTableCss.headerCell,
            headerColorStyle[headerColor],
          )}
          key={`header-${column.key ?? columnIndex}`}
          role="columnheader"
          style={{ height: styleForHeaderRow }}
        >
          <column.renderHeaderCell />
        </div>
      ))}
    </>
  );
});

/**
 * Arbiter table https://www.figma.com/design/iZHj2I36zd9i8nRbWKw4ZK/%E2%9D%96-Arbiter?node-id=214-0&node-type=canvas&t=TZXhtoU5Ez2uwaXW-0
 *
 * Req. reading: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_grid_layout/Basic_concepts_of_grid_layout
 *
 * To be as presentational as possible, the table knows as little as possible.
 * Wrap with your own logical layer to manage backing row DATA, request/fetch new data upon table interactions, implement sorts, etc...
 * For extra features e.g. paginated footer, use kits that wrap this.
 */
export const RedoTable = genericMemo(function RedoTable<DATA>({
  size = "sm",
  layout: { columns, headerColor = "grey" },
  rowsOfData,
  onRowClick,
  onContextMenu,
  onRowHovered,
  rowClassName,
  rowsSelected,
  onVisibleHandlers,
  emptyStateContent = null,
  loadingStateContent = null,
  additionalClasses,
  hideHeaderWhenEmpty,
}: RedoTableProps<DATA>) {
  /** req. reading: https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-columns */
  const styleForGridColTemplate = columns
    .map((column) =>
      tableDimensionToGridTemplateStyles(column.widthMode || { type: "fill" }),
    )
    .join(" ");

  const styleForGridRow = sizeToTemplateRowHeight[size];
  const styleForHeaderRow = sizeToTemplateHeaderRowHeight[size];

  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const scrolled = useScrolled(scrollAreaRef.current);
  useEffect(() => {
    if (!scrollAreaRef.current) {
      return;
    }
    onVisibleHandlers?.setTopVisible(scrolled.top);
    onVisibleHandlers?.setBottomVisible(scrolled.bottom);
  }, [scrolled, onVisibleHandlers]);

  return (
    <>
      <div
        className={classNames(redoTableCss.table, additionalClasses)}
        ref={scrollAreaRef}
        role="table"
        style={{
          gridTemplateColumns: styleForGridColTemplate,
          gridTemplateRows: styleForHeaderRow,
          gridAutoRows: styleForGridRow,
          height:
            rowsOfData.length > 0
              ? "100%"
              : hideHeaderWhenEmpty
                ? 0
                : undefined,
        }}
      >
        {hideHeaderWhenEmpty && rowsOfData.length === 0 ? null : (
          <div
            className={classNames(redoTableCss.rowWrapper)}
            key="header"
            role="row"
          >
            <RedoTableHeaderRow
              columns={columns}
              headerColor={headerColor}
              size={size}
            />
          </div>
        )}
        {rowsOfData.length > 0 && (
          <div className={redoTableCss.rowWrapper} role="rowgroup">
            {rowsOfData.map((row, rowIndex) => (
              <RedoTableRow<DATA>
                className={rowClassName?.(row, rowIndex)}
                columns={columns}
                key={`row-${rowIndex}`}
                onContextMenu={onContextMenu}
                onRowClick={onRowClick}
                onRowHovered={onRowHovered}
                row={row}
                rowIndex={rowIndex}
                rowSelected={rowsSelected?.[rowIndex]}
              />
            ))}
          </div>
        )}
      </div>
      {rowsOfData.length === 0 && (
        <>
          {hideHeaderWhenEmpty && (
            <Flex
              borderColor="primary"
              borderStyle="solid"
              borderTopWidth="1px"
              w="full"
            />
          )}
          {emptyStateContent}
        </>
      )}
      {loadingStateContent}
    </>
  );
});

export const sizeToTemplateRowHeight: Record<
  RedoTableSize,
  CSSProperties["height"]
> = { ["sm"]: "48px", ["md"]: "72px" };

export const sizeToTemplateHeaderRowHeight: Record<
  RedoTableSize,
  CSSProperties["height"]
> = { ["sm"]: "36px", ["md"]: "44px" };

export const headerColorStyle: Record<
  RedoTableHeaderColor,
  string | undefined
> = { ["white"]: redoTableCss.white, ["grey"]: undefined };

export const RedoTableLoadingStateContent = memo(
  function RedoTableLoadingStateContent() {
    return (
      <Flex align="center" grow={1} justify="center" py="3xl">
        <Flex className={redoTableCss.loadingSpinnerContainer}>
          <Spinner />
        </Flex>
      </Flex>
    );
  },
);

export const RedoTableEmptyStateContent = memo(
  function RedoTableEmptyStateContent({ text }: { text: string }) {
    return (
      <Flex align="center" grow={1} justify="center" py="3xl">
        <Text fontSize="sm" fontWeight="regular" textColor="primary">
          {text}
        </Text>
      </Flex>
    );
  },
);
