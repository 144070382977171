import { useRequiredContext } from "@redotech/react-util/context";
import { useLoad, useTriggerLoad } from "@redotech/react-util/load";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { RedoMerchantRpcClientContext } from "@redotech/redo-merchant-app-common/rpc-client";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { Order } from "@redotech/redo-model/order";
import {
  ReturnedProductStatus,
  shouldBeAManagedClaim,
  type Product,
  type Return,
  type ReturnType,
} from "@redotech/redo-model/return";
import {
  getReturnTotalsFrontendLogFn,
  ReturnTotals,
  ReturnTotalsCalculator,
} from "@redotech/redo-model/return-totals-calculator";
import { returnToTrackable } from "@redotech/redo-model/trackable";
import { BreadcrumbSlot, useBreadcrumb } from "@redotech/redo-web/breadcrumb";
import { memo, useContext, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getReturn } from "../client/return";
import { ShippingCard } from "./return-page-right-panel/shipping-card";

import { Divider } from "@redotech/redo-web/divider";
import { Flex } from "@redotech/redo-web/flex";
import { SkeletonText } from "@redotech/redo-web/skeleton";
import { Text } from "@redotech/redo-web/text";
import { ActivityCard } from "./return-page-left-panel/activity-card";
import { DetailCardNew } from "./return-page-left-panel/order-details";
import { ActivitySkeleton } from "./return-page-left-panel/skeletons";
import { ClaimShipmentsCard } from "./return-page-right-panel/claim-shipments-card";
import { CommentsoldOrderNotesCard } from "./return-page-right-panel/commentsold-order-notes-card";
import { CustomerTabsCard } from "./return-page-right-panel/customer-tabs-card";
import { RepairInvoice } from "./return-page-right-panel/repair-invoice";
import * as panelCss from "./return-page-right-panel/return-page-right-panel.module.css";
import { SummaryCard } from "./return-page-right-panel/summary-card";
import { WarrantiesInsuranceCard } from "./return-page-right-panel/warranties-insurance-card";
import { ReturnRouteType } from "./return-route-type";
import * as returnNewCss from "./returnnew.module.css";

const returnTypeToRoute = {
  return: ReturnRouteType.RETURNS,
  claim: ReturnRouteType.CLAIMS,
  warranty: ReturnRouteType.WARRANTIES,
  managed_claim: ReturnRouteType.CLAIMS,
} as const;

export function shopifyAddressString(address: any) {
  if (!address) {
    return "";
  }
  let result = address.address1;
  if (address.address2) {
    result += " ";
    result += address.address2;
  }
  result += ", ";
  result += address.city;
  result += " ";
  result += address.province_code || address.province;
  if (address.zip) {
    result += " ";
    result += address.zip;
  }
  if (address.country_code) {
    result += " ";
    result += address.country_code;
  }
  if (address.phone) {
    result += " ";
    result += address.phone;
  }
  return result;
}

export const ReturnPage = memo(function ReturnPage({
  breadcrumb,
  returnType,
}: {
  breadcrumb: BreadcrumbSlot;
  returnType: ReturnType;
}) {
  const params = useParams();
  const client = useRequiredContext(RedoMerchantClientContext);
  const redoMerchantRpcClient = useContext(RedoMerchantRpcClientContext);
  const team = useContext(TeamContext);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const onSummaryCardChange = (unsavedChanges: boolean) => {
    setUnsavedChanges(unsavedChanges);
  };

  const [returnTotals, setReturnTotals] = useState<ReturnTotals>();

  const [returnLoad, doReturn] = useTriggerLoad(async (signal) => {
    if (!team || !redoMerchantRpcClient) {
      return null;
    }

    const return_ = await getReturn(client, { id: params.returnId!, signal });
    const res = await redoMerchantRpcClient.getOrders({
      teamId: team._id,
      orderIds: return_.orders.map((order) => order.order),
    });

    const orders = res.orders as Order[];

    const nonZeroValueExchange =
      team.settings.exchanges?.nonZeroValueExchange &&
      return_.type === "claim" &&
      return_.advancedExchangeItems.length > 0;

    try {
      const returnTotalsCalculator = new ReturnTotalsCalculator({
        return_,
        order: orders[0],
        orders,
        team,
        originOrders: return_.originOrders,
        logFn: getReturnTotalsFrontendLogFn(),
      });

      const totals = returnTotalsCalculator.getTotalsForProducts(
        return_.products.filter(
          (product) => product.status !== ReturnedProductStatus.REJECTED,
        ),
        false,
        nonZeroValueExchange,
      );

      setReturnTotals(totals);
    } catch (error) {
      console.error(error);
    }
    setUnsavedChanges(false);
    return { return_, orders, nonZeroValueExchange };
  });

  const emailTemplateMapLoad = useLoad(
    async (signal) => {
      if (!team || !redoMerchantRpcClient) {
        return undefined;
      }
      const [emailTemplateIdToNameMap] = await Promise.allSettled([
        redoMerchantRpcClient.getEmailTemplateNames(
          { teamId: team._id },
          { signal },
        ),
      ]);
      return emailTemplateIdToNameMap.status === "fulfilled"
        ? emailTemplateIdToNameMap.value?.emailTemplateIdToNameMap
        : undefined;
    },
    [team, redoMerchantRpcClient],
  );

  useLayoutEffect(() => {
    doReturn();
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.returnId!]);
  useBreadcrumb(
    breadcrumb,
    (returnLoad.value?.return_?.orders || [])
      .map((order) => order.name)
      .join(" & "),
  );

  useEffect(() => {
    doReturn();
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team, redoMerchantRpcClient, client]);

  const hasNonGreenReturn = (return_?: Return) => {
    return return_?.products?.some(
      (product: Product) => product.green_return == false,
    );
  };

  const hasRepairProduct = (return_?: Return) => {
    return return_?.products?.some(
      (product: Product) => product.strategy === "repair",
    );
  };

  const showManagedClaimCard = shouldBeAManagedClaim({
    returnType,
    packageProtectionPlusEnabled:
      !!team?.settings?.packageProtection?.packageProtectionPlusEnabled,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (
      returnLoad.value?.return_ &&
      returnLoad.value.return_.type !== returnType
    ) {
      // Redirect to the correct URL based on the actual return type
      const correctPath = `/stores/${team?._id}/${returnTypeToRoute[returnLoad.value.return_.type]}/${params.returnId}`;
      navigate(correctPath);
    }
  }, [returnLoad.value?.return_, returnType, params.returnId, navigate, team]);

  return (
    <div className={returnNewCss.container}>
      <div className={returnNewCss.leftPanel}>
        <DetailCardNew
          orders={returnLoad.value?.orders}
          pending={returnLoad.pending}
          reload={doReturn}
          return={returnLoad.value?.return_}
          returnTotals={returnTotals}
          unsavedChanges={unsavedChanges}
        />
        {returnLoad.value?.return_ && !returnLoad.pending ? (
          <ActivityCard
            emailTemplateIdToNameMap={emailTemplateMapLoad.value}
            initialReturn={returnLoad.value?.return_}
            trackable={returnToTrackable(
              returnLoad.value?.return_,
              returnLoad.value?.orders[0],
            )}
          />
        ) : (
          <ActivitySkeleton />
        )}
      </div>

      <div className={returnNewCss.rightPanel}>
        {returnLoad.value?.return_ ? (
          <SummaryCard
            nonZeroValueExchange={returnLoad.value?.nonZeroValueExchange}
            onChange={onSummaryCardChange}
            orders={returnLoad.value?.orders || []}
            reload={doReturn}
            return={returnLoad.value?.return_}
            returnTotals={returnTotals}
          />
        ) : (
          <Flex className={panelCss.cardPadding} dir="column" gap="xl">
            <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
              Summary
            </Text>
            <SkeletonText animated length={25} />
          </Flex>
        )}
        <Divider />
        <CustomerTabsCard return={returnLoad.value?.return_} />
        <Divider />
        <CommentsoldOrderNotesCard return={returnLoad.value?.return_} />
        {showManagedClaimCard && (
          <>
            <Divider />
            <WarrantiesInsuranceCard return={returnLoad.value?.return_} />
          </>
        )}
        {returnLoad.value?.return_?.type === "claim" && (
          <>
            <Divider />
            <ClaimShipmentsCard
              orders={returnLoad.value?.orders}
              returnLineItems={returnLoad.value?.return_?.products.map(
                (product) => product.line_item_id,
              )}
            />
          </>
        )}
        {hasRepairProduct(returnLoad.value?.return_) && (
          <>
            <Divider />
            <RepairInvoice return={returnLoad.value?.return_} />
            <Divider />
            <ShippingCard
              isRepairCard
              order={returnLoad.value?.orders?.[0]}
              reload={doReturn}
              return={returnLoad.value?.return_}
            />
          </>
        )}
        {!returnLoad.value?.return_.inStoreReturn &&
          hasNonGreenReturn(returnLoad.value?.return_) && (
            <>
              <Divider />
              <ShippingCard
                order={returnLoad.value?.orders?.[0]}
                reload={doReturn}
                return={returnLoad.value?.return_}
              />
            </>
          )}
      </div>
    </div>
  );
});
