import { Address } from "@redotech/redo-model/team";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo } from "react";

export const AddressDisplay = memo(function AddressDisplay({
  address,
  hideName = false,
}: {
  address?: Address;
  hideName?: boolean;
}) {
  if (!address) {
    return null;
  }

  return (
    <Flex dir="column" gap="none">
      {!hideName && (
        <Text fontSize="xs" fontWeight="regular" textColor="tertiary">
          {address.name}
        </Text>
      )}
      <Text fontSize="xs" fontWeight="regular" textColor="tertiary">
        {address.street1}
      </Text>
      {address.street2 && (
        <Text fontSize="xs" fontWeight="regular" textColor="tertiary">
          {address.street2}
        </Text>
      )}
      <Text fontSize="xs" fontWeight="regular" textColor="tertiary">
        {address.city}, {address.state} {address.zip}
      </Text>
      <Text fontSize="xs" fontWeight="regular" textColor="tertiary">
        {address.country}
      </Text>
    </Flex>
  );
});
