import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { useLoad } from "@redotech/react-util/load";
import { AuthContext } from "@redotech/redo-merchant-app-common/auth";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { View } from "@redotech/redo-model/view";
import {
  ReactNode,
  createContext,
  memo,
  useContext,
  useMemo,
  useState,
} from "react";
import { getTeamViews, getUserViews } from "../client/view";

export type UserDefinedView = View & { private: boolean };

export enum DefaultViews {
  ALL = "all",
  ASSIGNED = "assigned",
  MENTIONS = "mentions",
  DRAFTS = "drafts",
}

export interface ViewInfo {
  identifier: {
    _id?: string; // Used for custom views
    name?: string; // Used for built-in views
  };
  title: string;
  isPrivate: boolean;
}

export const ViewsContext = createContext<UserDefinedView[] | undefined>(
  undefined,
);

export const ReloadViewsContext = createContext<(() => void) | undefined>(
  undefined,
);

export const ViewsProvider = memo(function ViewsProvider({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const client = useRequiredContext(RedoMerchantClientContext);
  const auth = useContext(AuthContext);

  const [viewsTrigger, setViewsTrigger] = useState(Symbol());

  const teamViewsLoad = useLoad(
    (signal) =>
      auth ? getTeamViews(client, { signal }) : Promise.resolve(undefined),
    [auth, client, viewsTrigger],
  );

  const privateViewsLoad = useLoad(
    (signal) =>
      auth ? getUserViews(client, { signal }) : Promise.resolve(undefined),
    [auth, client, viewsTrigger],
  );

  const reloadViews = useHandler(() => setViewsTrigger(Symbol()));

  const views = useMemo(() => {
    if (!teamViewsLoad.value || !privateViewsLoad.value) {
      return [];
    }

    return [
      ...privateViewsLoad.value.map((view) => ({ ...view, private: true })),
      ...teamViewsLoad.value.map((view) => ({ ...view, private: false })),
    ];
  }, [teamViewsLoad.value, privateViewsLoad.value]);

  return (
    <ViewsContext.Provider value={views}>
      <ReloadViewsContext.Provider value={reloadViews}>
        {children}
      </ReloadViewsContext.Provider>
    </ViewsContext.Provider>
  );
});
