import { Carriers } from "@redotech/redo-model/fulfillments/fulfillment-carriers-and-services";
import { z } from "zod";

export const createEodManifestForIds = {
  input: z.object({
    fulfillmentGroupIds: z.array(z.string()),
    carrier: z.nativeEnum(Carriers),
  }),
  output: z.object({ success: z.boolean() }),
};
