import { Redirect } from "@redotech/react-router-util/redirect";
import { Navigate, RouteObject, matchPath } from "react-router-dom";
import {
  backwardsCompatibleOrderRoutes,
  outboundLabelRoutes,
} from "../../../order/route";
import {
  claimRoutes,
  returnRoutes,
  warrantyRoutes,
} from "../../../return/route";
import { Page } from "../../page";

const HIDE_PADDING_ROUTES: string[] = [
  "/orders",
  "/returns/*",
  "/claims/*",
  "/warranties/*",
  "/batch-history",
  "/eod-manifest",
  "/outbound-labels/*",
  "/orders/:orderId",
];

function hidePadding(url: string): boolean {
  return HIDE_PADDING_ROUTES.some(
    (route) => !!matchPath(`/stores/:storeId/order-management${route}`, url),
  );
}

const FULLSCREEN_ROUTES: string[] = ["/packing-slips/:packingSlipId"];

const HIDE_HEADER_ROUTES: string[] = [...FULLSCREEN_ROUTES];
function hideHeader(url: string): boolean {
  return HIDE_HEADER_ROUTES.some(
    (route) => !!matchPath(`/stores/:storeId/order-management${route}`, url),
  );
}

const HIDE_NAV_ROUTES: string[] = [...FULLSCREEN_ROUTES];

function hideNav(url: string): boolean {
  return HIDE_NAV_ROUTES.some(
    (route) => !!matchPath(`/stores/:storeId/order-management${route}`, url),
  );
}

export const orderManagementRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Order Management" },
    async lazy() {
      const { Page } = await import("../../page");
      return {
        element: (
          <Page
            hideHeader={hideHeader}
            hideNavbar={hideNav}
            hidePadding={hidePadding}
          />
        ),
      };
    },
    children: [
      { index: true, element: <Navigate to="outbound-labels" /> },
      { path: "warranties", children: warrantyRoutes },
      { path: "returns", children: returnRoutes },
      { path: "claims", children: claimRoutes },
      { path: "outbound-labels", children: outboundLabelRoutes },
    ],
  },
];

export const backwardsCompatibleOrderManagementRoutes: RouteObject[] = [
  {
    path: "approvals",
    children: [
      {
        element: <Page />,
        children: [
          {
            index: true,
            element: (
              <Redirect
                match={/approvals/}
                replaceValue="order-management/approvals"
              />
            ),
          },
          {
            path: "*",
            element: (
              <Redirect
                match={/approvals/}
                replaceValue="order-management/approvals"
              />
            ),
          },
        ],
      },
    ],
  },
  {
    path: "warranties",
    children: [
      {
        element: <Page />,
        children: [
          {
            index: true,
            element: (
              <Redirect
                match={/warranties/}
                replaceValue="order-management/warranties"
              />
            ),
          },
          {
            path: "*",
            element: (
              <Redirect
                match={/warranties/}
                replaceValue="order-management/warranties"
              />
            ),
          },
        ],
      },
    ],
  },
  {
    path: "returns",
    children: [
      {
        element: <Page />,
        children: [
          {
            index: true,
            element: (
              <Redirect
                match={/returns/}
                replaceValue="order-management/returns"
              />
            ),
          },
          {
            path: "*",
            element: (
              <Redirect
                match={/returns/}
                replaceValue="order-management/returns"
              />
            ),
          },
        ],
      },
    ],
  },
  {
    path: "about-oms",
    children: [
      {
        element: <Page />,
        children: [
          {
            index: true,
            element: (
              <Redirect
                match={/about-oms/}
                replaceValue="order-management/outbound-labels/about-oms"
              />
            ),
          },
          {
            path: "*",
            element: (
              <Redirect
                match={/about-oms/}
                replaceValue="order-management/outbound-labels/about-oms"
              />
            ),
          },
        ],
      },
    ],
  },
  {
    path: "claims",
    children: [
      {
        element: <Page />,
        children: [
          {
            index: true,
            element: (
              <Redirect
                match={/claims/}
                replaceValue="order-management/claims"
              />
            ),
          },
          {
            path: "*",

            element: (
              <Redirect
                match={/claims/}
                replaceValue="order-management/claims"
              />
            ),
          },
        ],
      },
    ],
  },
  {
    path: "orders/*",
    element: <Page />,
    children: [{ children: backwardsCompatibleOrderRoutes }],
  },
];
