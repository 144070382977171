import { assertNever } from "@redotech/util/type";

export type DateVariant =
  | "no-date"
  | "today"
  | "yesterday"
  | "this-week"
  | "last-week"
  | "this-month"
  | "last-month"
  | "this-year"
  | "last-year";

function getMondayStartOfWeek(date: Temporal.PlainDate): Temporal.PlainDate {
  const dayOfWeek = date.dayOfWeek;
  const daysToSubtract = dayOfWeek === 1 ? 0 : dayOfWeek - 1;
  return date.subtract({ days: daysToSubtract });
}

function getSundayEndOfWeek(date: Temporal.PlainDate): Temporal.PlainDate {
  const mondayStart = getMondayStartOfWeek(date);
  // Sunday is 6 days after Monday
  return mondayStart.add({ days: 6 });
}

function getStartOfMonth(date: Temporal.PlainDate): Temporal.PlainDate {
  return date.with({ day: 1 });
}

function getEndOfMonth(date: Temporal.PlainDate): Temporal.PlainDate {
  return date.with({ day: date.daysInMonth });
}

/**
 * Calculate date or date range from a variant
 */
export function getDatesFromVariant(variant: DateVariant): {
  startDate: Temporal.PlainDate | null;
  endDate: Temporal.PlainDate | null;
} {
  const now = Temporal.Now.plainDateISO();

  switch (variant) {
    case "no-date": {
      return { startDate: null, endDate: null };
    }
    case "today": {
      return { startDate: now, endDate: now };
    }

    case "yesterday": {
      const yesterday = now.subtract({ days: 1 });
      return { startDate: yesterday, endDate: yesterday };
    }

    case "this-week": {
      return {
        startDate: getMondayStartOfWeek(now),
        endDate: getSundayEndOfWeek(now),
      };
    }

    case "last-week": {
      const lastWeek = now.subtract({ days: 7 });
      return {
        startDate: getMondayStartOfWeek(lastWeek),
        endDate: getSundayEndOfWeek(lastWeek),
      };
    }

    case "this-month": {
      return { startDate: getStartOfMonth(now), endDate: getEndOfMonth(now) };
    }

    case "last-month": {
      const lastMonth = now.subtract({ months: 1 });
      return {
        startDate: getStartOfMonth(lastMonth),
        endDate: getEndOfMonth(lastMonth),
      };
    }
    case "this-year": {
      return {
        startDate: now.with({ month: 1, day: 1 }),
        endDate: now.with({ month: 12, day: now.daysInMonth }),
      };
    }
    case "last-year": {
      const lastYear = now.subtract({ years: 1 });
      return {
        startDate: lastYear.with({ month: 1, day: 1 }),
        endDate: lastYear.with({ month: 12, day: lastYear.daysInMonth }),
      };
    }

    default:
      assertNever(variant);
  }
}

export interface CalendarDay {
  date: Temporal.PlainDate;
  isCurrentMonth: boolean;
}

/**
 * Generate calendar days for month view
 * Always returns exactly 6 weeks (42 days)
 */
export function generateCalendarDays(
  currentMonth: Temporal.PlainYearMonth,
): CalendarDay[] {
  const days = [];
  const firstDay = currentMonth.toPlainDate({ day: 1 });
  const lastDay = currentMonth.toPlainDate({ day: currentMonth.daysInMonth });

  const firstDayOfWeek = firstDay.dayOfWeek;
  // When starting with Monday, we need to show previous days if firstDayOfWeek > 1
  const startPadding = firstDayOfWeek === 1 ? 0 : firstDayOfWeek - 1;

  // Add days from previous month
  for (let i = startPadding; i > 0; i--) {
    const day = firstDay.subtract({ days: i });
    days.push({ date: day, isCurrentMonth: false });
  }

  // Add days from current month
  for (let i = 1; i <= lastDay.day; i++) {
    const day = firstDay.with({ day: i });
    days.push({ date: day, isCurrentMonth: true });
  }

  // Always ensure we have exactly 6 weeks (42 days)
  const remainingDays = 42 - days.length;

  // Add days from next month
  for (let i = 1; i <= remainingDays; i++) {
    const day = lastDay.add({ days: i });
    days.push({ date: day, isCurrentMonth: false });
  }

  return days;
}

/**
 * Compares two dates
 */
export function datesEqual(
  d1: Temporal.PlainDate | null | undefined,
  d2: Temporal.PlainDate | null | undefined,
): boolean {
  if (!d1 || !d2) return false;
  return Temporal.PlainDate.compare(d1, d2) === 0;
}

export function isStartOfWeek(
  date: Temporal.PlainDate,
  { startOfWeek = 1 }: { startOfWeek?: number } = {},
): boolean {
  return date.dayOfWeek === startOfWeek;
}

export function isEndOfWeek(
  date: Temporal.PlainDate,
  { startOfWeek = 1 }: { startOfWeek?: number } = {},
): boolean {
  return date.dayOfWeek === (startOfWeek + 6) % 7 || date.dayOfWeek === 7;
}

export function isSelectedDate(
  date: Temporal.PlainDate,
  selectedDate: Temporal.PlainDate | null | undefined,
): boolean {
  if (!selectedDate) return false;
  return datesEqual(date, selectedDate);
}

export function isToday(date: Temporal.PlainDate): boolean {
  const today = Temporal.Now.plainDateISO();
  return datesEqual(date, today);
}

export function isDateInRange(
  date: Temporal.PlainDate,
  startDate: Temporal.PlainDate | null | undefined,
  endDate: Temporal.PlainDate | null | undefined,
): boolean {
  if (!startDate || !endDate) return false;

  return (
    Temporal.PlainDate.compare(date, startDate) >= 0 &&
    Temporal.PlainDate.compare(date, endDate) <= 0
  );
}

export function isDateInHoverRange(
  date: Temporal.PlainDate,
  startDate: Temporal.PlainDate | null | undefined,
  hoveredDate: Temporal.PlainDate | null | undefined,
): boolean {
  if (!startDate || !hoveredDate) return false;

  // Sort the dates to ensure we handle hover in both directions
  const [earlierDate, laterDate] = [startDate, hoveredDate].sort((a, b) =>
    Temporal.PlainDate.compare(a, b),
  );

  return (
    Temporal.PlainDate.compare(date, earlierDate) > 0 &&
    Temporal.PlainDate.compare(date, laterDate) < 0
  );
}

export const DAY_NAMES = ["Mo", "Tu", "We", "Th", "Fr", "Sat", "Su"];
