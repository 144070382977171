import { genericMemo } from "@redotech/react-util/component";
import * as React from "react";
import DraggableList from "react-draggable-list";
import {
  RedoSecondaryNavItem,
  SecondaryNavItemProps,
} from "./redo-secondary-nav-item";

export type RedoNavDraggableListItem<T> = SecondaryNavItemProps & {
  itemValue: T;
};

export const RedoNavDraggableList = genericMemo(function RedoNavDraggableList<
  T,
>({
  draggableItems,
  setDraggableItems,
}: {
  draggableItems: RedoNavDraggableListItem<T>[];
  setDraggableItems(items: RedoNavDraggableListItem<T>[]): void;
}) {
  return (
    <span style={{ marginBottom: "-4px" }}>
      <DraggableList<RedoNavDraggableListItem<T>, {}, any>
        constrainDrag
        container={() => document.body}
        itemKey={(item) => item.id}
        list={draggableItems}
        onMoveEnd={(a) => setDraggableItems([...a])}
        padding={4}
        // The draggable list type expects a class component,
        // but it supports functional components as well.
        template={DraggableNavItem as any}
      />
    </span>
  );
});

class DraggableNavItem<T> extends React.Component<{
  dragHandleProps: object;
  item: RedoNavDraggableListItem<T>;
}> {
  override render() {
    const { dragHandleProps, item } = this.props;
    return <RedoSecondaryNavItem {...item} dragHandleProps={dragHandleProps} />;
  }
}
