import { UserCallAvailability } from "@redotech/redo-model/support/voice/voice-types";
import GearIcon from "@redotech/redo-web/arbiter-icon/settings-02.svg";
import { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RedoButton } from "../arbiter-components/buttons/redo-button";
import { RedoPhoneNumberInput } from "../arbiter-components/input/redo-phone-number-input";
import { RedoListItem } from "../arbiter-components/list/redo-list";
import { RedoSingleSelectDropdownInput } from "../arbiter-components/select-dropdown/redo-single-select-dropdown-input";
import { Flex } from "../flex";
import { PhoneWidgetNumpad } from "./phone-numpad";
import { statusToListItem } from "./phone-status";

export const PhoneIdle = memo(function NoCallBody({
  status,
  setStatus,
  performCall,
}: {
  status: UserCallAvailability;
  setStatus: (status: UserCallAvailability) => void;
  performCall: (number: string) => void;
}) {
  return (
    <>
      <PhoneWidgetSettings setStatus={setStatus} status={status} />
      <PhoneWidgetBody performCall={performCall} />
    </>
  );
});

const PhoneWidgetBody = memo(function PhoneWidgetBody({
  performCall,
}: {
  performCall(number: string): void;
}) {
  const [number, setNumber] = useState<string>("");

  return (
    <Flex dir="column" p="xl">
      <RedoPhoneNumberInput setValue={setNumber} value={number} />
      <PhoneWidgetNumpad
        itemPressed={(item) => setNumber((number) => number + item)}
      />
      <RedoButton
        hierarchy="primary"
        onClick={() => performCall(number)}
        text="Call"
        theme="success"
      />
    </Flex>
  );
});

const availabilityOptions: RedoListItem<UserCallAvailability>[] = [
  statusToListItem(UserCallAvailability.AVAILABLE),
  statusToListItem(UserCallAvailability.UNAVAILABLE),
];

const PhoneWidgetSettings = memo(function PhoneWidgetSettings({
  status,
  setStatus,
}: {
  status: UserCallAvailability;
  setStatus: (status: UserCallAvailability) => void;
}) {
  const navigate = useNavigate();
  return (
    <Flex p="xl">
      <RedoSingleSelectDropdownInput
        options={availabilityOptions}
        optionSelected={({ item }) => setStatus(item.value)}
        selectedItem={statusToListItem(status)}
        size="xs"
      />
      <RedoButton
        hierarchy="secondary"
        IconLeading={GearIcon}
        onClick={() => navigate("/settings/channels/voice-settings")}
      />
    </Flex>
  );
});
