import { useInput } from "@redotech/react-util/form";
import { createContext } from "react";
import { PickupForm, pickupForm } from "../order/return-flow/review/pickup";

export const PickupContext = createContext<{ input: PickupForm }>({
  // @ts-expect-error: strict-null-checks
  input: undefined,
});

export const PickupContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const pickupInput = useInput(pickupForm, {
    finalized: false,
    pickup: undefined,
  });

  return (
    <PickupContext.Provider value={{ input: pickupInput }}>
      {children}
    </PickupContext.Provider>
  );
};
