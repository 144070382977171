import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import {
  RedoCommandMenu,
  RedoCommandMenuItem,
} from "@redotech/redo-web/arbiter-components/command-menu/redo-command-menu";
import ChevronDownIcon from "@redotech/redo-web/arbiter-icon/chevron-down_filled.svg";
import { memo, useState } from "react";

export const ActionsButton = memo(function ActionsButton({
  moreActionsDropdownOptions,
  disabled,
  canProcess,
  canApprove,
}: {
  moreActionsDropdownOptions: RedoCommandMenuItem[];
  disabled: boolean;
  canProcess: boolean;
  canApprove: boolean;
}) {
  const numActions = moreActionsDropdownOptions.filter(Boolean).length;

  const [actionMenuOpen, setActionMenuOpen] = useState(false);
  const [actionMenuRef, setActionMenuRef] = useState<HTMLButtonElement | null>(
    null,
  );

  return (
    numActions > 0 && (
      <>
        <RedoButton
          disabled={disabled}
          hierarchy="secondary"
          IconTrailing={ChevronDownIcon}
          onClick={() => setActionMenuOpen(!actionMenuOpen)}
          ref={setActionMenuRef}
          size="sm"
          text={canProcess || canApprove ? "More actions" : "Actions"}
        />
        <RedoCommandMenu
          anchor={actionMenuRef}
          items={moreActionsDropdownOptions}
          open={actionMenuOpen}
          setOpen={setActionMenuOpen}
        />
      </>
    )
  );
});
