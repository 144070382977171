// extracted by mini-css-extract-plugin
export var active = "_5n-7";
export var backButton = "_5n-6";
export var collapseBar = "_5n-f";
export var collapseMode = "_5n-h";
export var dragHandle = "_5n-a";
export var dropdownIcon = "_5n-d";
export var hidden = "_5n-n";
export var iconContainer = "_5n-k";
export var iconSwapper = "_5n-m";
export var navItem = "_5n-4";
export var navItemIcon = "_5n-c";
export var navProfile = "_5n-5";
export var notificationDot = "_5n-l";
export var optionsContainer = "_5n-e";
export var primaryNav = "_5n-0";
export var primaryTab = "_5n-i";
export var secondaryNav = "_5n-1";
export var selected = "_5n-j";
export var settingsNav = "_5n-2";
export var settingsNavItems = "_5n-3";
export var show = "_5n-b";
export var sideNav = "_5n-g";
export var teamItem = "_5n-8";
export var userProfileItem = "_5n-9";