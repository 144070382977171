// extracted by mini-css-extract-plugin
export var attachment = "_cf-a";
export var emailContainer = "_cf-4";
export var emailContentPaddedContainer = "_cf-b";
export var emailHeader = "_cf-6";
export var emailInfoIcon = "_cf-9";
export var emailShortenedSummary = "_cf-e";
export var infoDropdown = "_cf-0";
export var infoRowBoundedContent = "_cf-d";
export var internal = "_cf-5";
export var merchant = "_cf-8";
export var messageInputContainer = "_cf-h";
export var normal = "_cf-7";
export var replyCard = "_cf-f";
export var shadowDomWrapper = "_cf-3";
export var subtleButton = "_cf-1";
export var subtleButtonIconContainer = "_cf-2";
export var toggleViewRepliesButton = "_cf-c";
export var wrappingText = "_cf-g";