import { RedoMerchantClient } from "@redotech/redo-merchant-app-common/client";
import { Customer, CustomerTagWithId } from "@redotech/redo-model/customer";
import { ICustomerActivity } from "@redotech/redo-model/customer-activity/customer-activity-definition";
import {
  CustomerActivityFilterOptions,
  customerEventFilterOptionsToQueryParams,
} from "@redotech/redo-model/customer-activity/customer-activity-filters";
import { CustomerEventSchema } from "@redotech/redo-model/customer-event/customer-event-zod-schema";
import { AxiosHeaders } from "axios";
import { CustomerMetrics } from "../customers/customer-detail/customer-detail";

/**
 * GET /customers
 */
export async function getCustomers(
  client: RedoMerchantClient,
  {
    pageContinue,
    pageSize = 20,
    search,
    sort,
    signal,
    email,
  }: {
    pageContinue?: string;
    pageSize?: number;
    search?: string;
    sort?: { key: string; direction: string };
    signal?: AbortSignal;
    email?: string;
  },
): Promise<{ data: Customer[]; pageNext: string | undefined }> {
  const response = await client.client.get("customers", {
    headers: {
      ...client.authorization(),
      "X-Page-Continue": pageContinue,
      "X-Page-Size": pageSize,
    },
    params: { search, sort: sort && `${sort.key}:${sort.direction}` },
    signal,
  });
  return {
    data: response.data,
    pageNext:
      <string>(<AxiosHeaders>response.headers).get("X-Page-Next") || undefined,
  };
}

/**
 * GET /customers/by-email/:email
 */
export async function getCustomerByEmail(
  client: RedoMerchantClient,
  { email, signal }: { email: string; signal?: AbortSignal },
): Promise<Customer> {
  const response = await client.client.get(
    `customers/by-email/${encodeURIComponent(email)}`,
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * GET /customers/:id
 */
export async function getCustomer(
  client: RedoMerchantClient,
  { id, signal }: { id: string; signal?: AbortSignal },
): Promise<Customer> {
  const response = await client.client.get(
    `customers/${encodeURIComponent(id)}`,
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * GET /customers/:id/metrics
 */
export async function getCustomerMetrics(
  client: RedoMerchantClient,
  { id, signal }: { id: string; signal?: AbortSignal },
): Promise<CustomerMetrics> {
  const response = await client.client.get(
    `customers/${encodeURIComponent(id)}/metrics`,
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * GET /customers/:id/events
 */
export async function getCustomerEvents(
  client: RedoMerchantClient,
  {
    customerId,
    filterOptions,
    signal,
  }: {
    customerId: string;
    filterOptions?: CustomerActivityFilterOptions;
    signal?: AbortSignal;
  },
): Promise<ICustomerActivity> {
  const maybeQueryParamsString =
    customerEventFilterOptionsToQueryParams(filterOptions);
  const queryParamsUri = maybeQueryParamsString
    ? `?${maybeQueryParamsString}`
    : "";

  const response = await client.client.get(
    `customers/${encodeURIComponent(customerId)}/events${queryParamsUri}`,
    { headers: client.authorization(), signal },
  );

  try {
    const events = response.data.customerEvents;
    const validEvents = [];
    for (const event of events) {
      const parsedEvent = CustomerEventSchema.safeParse(event);
      if (parsedEvent.success) {
        validEvents.push(parsedEvent.data);
      } else {
        console.error("Invalid event found for team", event);
      }
    }
    return { customerEvents: validEvents };
  } catch (error) {
    console.error(error);
    return { customerEvents: [] };
  }
}

/**
 * PUT /customers/:id/tags
 */
export async function setCustomerTags(
  client: RedoMerchantClient,
  {
    customerId,
    tags,
    signal,
  }: { customerId: string; tags: CustomerTagWithId[]; signal?: AbortSignal },
): Promise<void> {
  await client.client.put(
    `customers/${encodeURIComponent(customerId)}/tags`,
    { tags },
    { headers: client.authorization(), signal },
  );
}
