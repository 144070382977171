import {
  AdvancedFilter,
  FilterGroupFilterOption,
  PendingAdvancedFilter,
} from "@redotech/redo-model/conversation-filters/conversation-filters";
import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { ConversationTableDateFilterGroup } from "@redotech/redo-web/conversation-filters/conversation-date-filter-group";
import { Flex } from "@redotech/redo-web/flex";
import { memo, useContext, useEffect, useState } from "react";
import * as activeConversationsTableFiltersCss from "./active-conversations-table-filters.css";
import { ActiveViewContext, EditingViewContext } from "./active-view-context";
import { AssigneesFilter } from "./assignees-filter";
import { ChannelsFilter } from "./channels-filter";
import { ConversationMentionsUsersFilter } from "./conversation-mentions-users-filter";
import { ConversationMentionsWordsFilter } from "./conversation-mentions-words-filter";
import { ConversationTagFilter } from "./conversation-tag-filter";
import { CustomerTagsFilter } from "./customer-tags-filter";
import {
  AdvancedFiltersContext,
  SetAdvancedFiltersContext,
  SetUniqueFiltersContext,
} from "./filters-context";
import { ReadStatusFilter } from "./read-status-filter";

function setFilter({
  advancedFilters,
  oldFilter,
  newFilter,
}: {
  advancedFilters: (AdvancedFilter | PendingAdvancedFilter)[];
  oldFilter: AdvancedFilter | PendingAdvancedFilter;
  newFilter: AdvancedFilter | PendingAdvancedFilter;
}) {
  return advancedFilters.map((f) => (f === oldFilter ? newFilter : f));
}

function removeFilter(
  filters: (AdvancedFilter | PendingAdvancedFilter)[],
  filter: AdvancedFilter | PendingAdvancedFilter,
) {
  return filters.filter((f) => f !== filter);
}

function shouldOpenOnRender(
  advancedFilters: (AdvancedFilter | PendingAdvancedFilter)[],
  filter: AdvancedFilter | PendingAdvancedFilter,
  justAddedFilter: boolean,
) {
  return (
    justAddedFilter &&
    advancedFilters[advancedFilters.length - 1] === filter &&
    filter.value === null
  );
}

export const ActiveConversationsTableFilters = memo(
  function ActiveConversationsTableFilters({
    viewMeaningfullyDiffersFromBase,
  }: {
    viewMeaningfullyDiffersFromBase: boolean;
  }) {
    const advancedFilters = useContext(AdvancedFiltersContext);
    const setAdvancedFilters = useContext(SetAdvancedFiltersContext);
    const setUniqueFiltersContext = useContext(SetUniqueFiltersContext);
    const view = useContext(ActiveViewContext);
    const viewBeingEdited = useContext(EditingViewContext);

    const [numAdvancedFilters, setNumAdvancedFilters] = useState(
      advancedFilters.length,
    );
    const [filterJustAdded, setFilterJustAdded] = useState(false);

    useEffect(() => {
      const justAddedFilter = numAdvancedFilters < advancedFilters.length;
      setFilterJustAdded(justAddedFilter);
      setNumAdvancedFilters(advancedFilters.length);
    }, [numAdvancedFilters, advancedFilters]);

    return (
      <Flex
        className={activeConversationsTableFiltersCss.filtersContainer}
        wrap="wrap"
      >
        {advancedFilters.map((filter, index) => {
          switch (filter.type) {
            case FilterGroupFilterOption.CONVERSATION_TAGS:
              return (
                <ConversationTagFilter
                  filter={filter}
                  key={index}
                  openOnRender={shouldOpenOnRender(
                    advancedFilters,
                    filter,
                    filterJustAdded,
                  )}
                  removeFilter={() => {
                    setAdvancedFilters(removeFilter(advancedFilters, filter));
                  }}
                  setFilter={(newFilter) => {
                    setAdvancedFilters(
                      setFilter({
                        advancedFilters,
                        oldFilter: filter,
                        newFilter,
                      }),
                    );
                  }}
                />
              );
            case FilterGroupFilterOption.CHANNELS:
              return (
                <ChannelsFilter
                  filter={filter}
                  key={index}
                  openOnRender={shouldOpenOnRender(
                    advancedFilters,
                    filter,
                    filterJustAdded,
                  )}
                  removeFilter={() => {
                    setAdvancedFilters(removeFilter(advancedFilters, filter));
                  }}
                  setFilter={(newFilter) => {
                    setAdvancedFilters(
                      setFilter({
                        advancedFilters,
                        oldFilter: filter,
                        newFilter,
                      }),
                    );
                  }}
                />
              );
            case FilterGroupFilterOption.CUSTOMER_TAGS:
              return (
                <CustomerTagsFilter
                  filter={filter}
                  key={index}
                  openOnRender={shouldOpenOnRender(
                    advancedFilters,
                    filter,
                    filterJustAdded,
                  )}
                  removeFilter={() => {
                    setAdvancedFilters(removeFilter(advancedFilters, filter));
                  }}
                  setFilter={(newFilter) => {
                    setAdvancedFilters(
                      setFilter({
                        advancedFilters,
                        oldFilter: filter,
                        newFilter,
                      }),
                    );
                  }}
                />
              );
            case FilterGroupFilterOption.ASSIGNEES:
              return (
                <AssigneesFilter
                  filter={filter}
                  key={index}
                  openOnRender={shouldOpenOnRender(
                    advancedFilters,
                    filter,
                    filterJustAdded,
                  )}
                  removeFilter={() => {
                    setAdvancedFilters(removeFilter(advancedFilters, filter));
                  }}
                  setFilter={(newFilter) => {
                    setAdvancedFilters(
                      setFilter({
                        advancedFilters,
                        oldFilter: filter,
                        newFilter,
                      }),
                    );
                  }}
                />
              );
            case FilterGroupFilterOption.READ:
              return (
                <ReadStatusFilter
                  openOnRender={shouldOpenOnRender(
                    advancedFilters,
                    filter,
                    filterJustAdded,
                  )}
                  readStatus={filter}
                  removeFilter={() => {
                    setAdvancedFilters(removeFilter(advancedFilters, filter));
                  }}
                  setReadStatus={(newFilter) => {
                    setAdvancedFilters(
                      setFilter({
                        advancedFilters,
                        oldFilter: filter,
                        newFilter,
                      }),
                    );
                  }}
                />
              );
            case FilterGroupFilterOption.CREATED_DATE:
              return (
                <ConversationTableDateFilterGroup
                  dateFilter={filter}
                  filterOption={FilterGroupFilterOption.CREATED_DATE}
                  key={index}
                  openOnRender={shouldOpenOnRender(
                    advancedFilters,
                    filter,
                    filterJustAdded,
                  )}
                  removeFilter={() => {
                    setAdvancedFilters(removeFilter(advancedFilters, filter));
                  }}
                  setDateFilter={(newFilter) => {
                    setAdvancedFilters(
                      setFilter({
                        advancedFilters,
                        oldFilter: filter,
                        newFilter,
                      }),
                    );
                  }}
                />
              );
            case FilterGroupFilterOption.CLOSED_DATE:
              return (
                <ConversationTableDateFilterGroup
                  dateFilter={filter}
                  filterOption={FilterGroupFilterOption.CLOSED_DATE}
                  key={index}
                  openOnRender={shouldOpenOnRender(
                    advancedFilters,
                    filter,
                    filterJustAdded,
                  )}
                  removeFilter={() => {
                    setAdvancedFilters(removeFilter(advancedFilters, filter));
                  }}
                  setDateFilter={(newFilter) => {
                    setAdvancedFilters(
                      setFilter({
                        advancedFilters,
                        oldFilter: filter,
                        newFilter,
                      }),
                    );
                  }}
                />
              );
            case FilterGroupFilterOption.LAST_RESPONSE_AT:
              return (
                <ConversationTableDateFilterGroup
                  dateFilter={filter}
                  filterOption={FilterGroupFilterOption.LAST_RESPONSE_AT}
                  key={index}
                  openOnRender={shouldOpenOnRender(
                    advancedFilters,
                    filter,
                    filterJustAdded,
                  )}
                  removeFilter={() => {
                    setAdvancedFilters(removeFilter(advancedFilters, filter));
                  }}
                  setDateFilter={(newFilter) => {
                    setAdvancedFilters(
                      setFilter({
                        advancedFilters,
                        oldFilter: filter,
                        newFilter,
                      }),
                    );
                  }}
                />
              );
            case FilterGroupFilterOption.MENTIONS:
              return (
                <ConversationMentionsUsersFilter
                  filter={filter}
                  key={index}
                  openOnRender={shouldOpenOnRender(
                    advancedFilters,
                    filter,
                    filterJustAdded,
                  )}
                  removeFilter={() => {
                    setAdvancedFilters(removeFilter(advancedFilters, filter));
                  }}
                  setFilter={(newFilter) => {
                    setAdvancedFilters(
                      setFilter({
                        advancedFilters,
                        oldFilter: filter,
                        newFilter,
                      }),
                    );
                  }}
                />
              );
            case FilterGroupFilterOption.WORDS:
              return (
                <ConversationMentionsWordsFilter
                  openOnRender={shouldOpenOnRender(
                    advancedFilters,
                    filter,
                    filterJustAdded,
                  )}
                  removeFilter={() => {
                    setAdvancedFilters(removeFilter(advancedFilters, filter));
                  }}
                  setWords={(newFilter) => {
                    setAdvancedFilters(
                      setFilter({
                        advancedFilters,
                        oldFilter: filter,
                        newFilter,
                      }),
                    );
                  }}
                  words={filter}
                />
              );
          }
        })}
        {viewMeaningfullyDiffersFromBase && !viewBeingEdited && (
          <RedoButton
            hierarchy="tertiary"
            onClick={() => {
              setAdvancedFilters(view.filters.advancedFilters);
              setUniqueFiltersContext(view.filters);
            }}
            text="Reset filters"
          />
        )}
      </Flex>
    );
  },
);
