const INPUT_DEVICE_LOCAL_STORAGE_KEY = "inputDeviceId";
const OUTPUT_DEVICE_LOCAL_STORAGE_KEY = "outputDeviceId";

export const retrieveIODevices = async ({
  setAudioStream,
}: {
  setAudioStream?: (stream: MediaStream) => void;
}): Promise<{
  audioInputDevices: MediaDeviceInfo[];
  audioOutputDevices: MediaDeviceInfo[];
  currentInputDevice: string | null;
  currentOutputDevice: string | null;
}> => {
  try {
    const audioElement = document.createElement("audio");
    // Required to request the available devices (grants permissions through web browser)
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });
    const devices = await navigator.mediaDevices.enumerateDevices();
    const audioInputDevices = devices.filter(
      (device) => device.kind === "audioinput",
    );
    const audioOutputDevices = devices.filter(
      (device) => device.kind === "audiooutput",
    );

    const inputDeviceId = localStorage.getItem(INPUT_DEVICE_LOCAL_STORAGE_KEY);
    let newInputDeviceId = null;
    if (inputDeviceId) {
      newInputDeviceId = inputDeviceId;
    } else {
      // Get default input device
      const audioTrack = stream.getAudioTracks()[0];
      if (audioTrack) {
        const currentInputDeviceId = audioTrack.getSettings().deviceId;
        newInputDeviceId = currentInputDeviceId || null;
      }
    }
    if (setAudioStream && newInputDeviceId) {
      const newStream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: { exact: newInputDeviceId } },
        video: false,
      });
      setAudioStream(newStream);
    }

    const outputDeviceId = localStorage.getItem(
      OUTPUT_DEVICE_LOCAL_STORAGE_KEY,
    );
    if (!outputDeviceId) {
      // Get default output device
      if (audioElement && audioOutputDevices.length > 0) {
        const defaultOutputDeviceId = audioOutputDevices[0].deviceId;
        await audioElement.setSinkId(defaultOutputDeviceId);
      }
    }
    return {
      audioInputDevices,
      audioOutputDevices,
      currentInputDevice: newInputDeviceId,
      currentOutputDevice: outputDeviceId || audioOutputDevices[0].deviceId,
    };
  } catch (err) {
    console.error("Error fetching input devices:", err);
    return {
      audioInputDevices: [],
      audioOutputDevices: [],
      currentInputDevice: null,
      currentOutputDevice: null,
    };
  }
};

export const handleInputDeviceChange = async ({
  deviceId,
  setCurrentInputDevice,
  setAudioStream,
}: {
  deviceId: string;
  setCurrentInputDevice: (deviceId: string) => void;
  setAudioStream: (stream: MediaStream) => void;
}) => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: { deviceId: { exact: deviceId } },
      video: false,
    });
    // Do something with the stream, e.g., attach it to an audio element
    const audioElement = document.querySelector("audio");
    if (audioElement) {
      audioElement.srcObject = stream;
    }
    setCurrentInputDevice(deviceId);
    localStorage.setItem(INPUT_DEVICE_LOCAL_STORAGE_KEY, deviceId);
    setAudioStream(stream);
  } catch (err) {
    console.error("Error setting input device:", err);
  }
};

export const handleOutputDeviceChange = async ({
  deviceId,
  setCurrentOutputDevice,
}: {
  deviceId: string;
  setCurrentOutputDevice: (deviceId: string) => void;
}) => {
  try {
    const audioElement = document.querySelector("audio");
    if (audioElement && "setSinkId" in audioElement) {
      await audioElement.setSinkId(deviceId);
      setCurrentOutputDevice(deviceId);
      localStorage.setItem(OUTPUT_DEVICE_LOCAL_STORAGE_KEY, deviceId);
    } else {
      console.error("Browser does not support output device selection.");
    }
  } catch (err) {
    console.error("Error setting output device:", err);
  }
};
