import {
  CreationTaxIdentifierSchema,
  RedactedTaxIdentifierSchema,
} from "@redotech/redo-model/outbound-labels/customs";
import { z } from "zod";

export const createTaxIdentifier = {
  input: z.object({ identifier: CreationTaxIdentifierSchema }),
  output: z.object({ identifiers: z.array(RedactedTaxIdentifierSchema) }),
};
