import { useRequiredContext } from "@redotech/react-util/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import {
  FilterGroupFilterOption,
  MentionsUsersFilter,
  PendingFilter,
} from "@redotech/redo-model/conversation-filters/conversation-filters";
import { GetUser } from "@redotech/redo-model/user";
import {
  RedoFilterDropdownAnchor,
  RedoFilterGroup,
  RedoFilterSection,
} from "@redotech/redo-web/arbiter-components/filter-group/redo-filter-group";
import { RedoListItem } from "@redotech/redo-web/arbiter-components/list/redo-list";
import {
  ConversationFilterOptionToIcon,
  ConversationFilterOptionToName,
} from "@redotech/redo-web/conversation-filters/conversation-filter-icons";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo, useEffect, useMemo, useState } from "react";
import { RedoMultiselectDropdown } from "../../../../web/src/arbiter-components/list/redo-multiselect-dropdown";

type MentionableUser = { id: string; name: string };

export const ConversationMentionsUsersFilter = memo(
  function ConversationMentionsUsersFilter({
    filter,
    setFilter,
    removeFilter,
    openOnRender,
  }: {
    filter: MentionsUsersFilter | PendingFilter<MentionsUsersFilter>;
    setFilter(
      filter: MentionsUsersFilter | PendingFilter<MentionsUsersFilter>,
    ): void;
    removeFilter(): void;
    openOnRender: boolean;
  }) {
    const [valueRef, setValueRef] = useState<HTMLButtonElement | null>(null);

    const team = useRequiredContext(TeamContext);

    useEffect(() => {
      if (openOnRender && valueRef) {
        valueRef.click();
      }
    }, [openOnRender, valueRef]);

    const { value: userIds } = filter;

    const teamUsers = team.users.map((user) => user.user as GetUser);

    const assigneesOptions: MentionableUser[] = useMemo(
      () => [
        ...teamUsers
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((user) => ({ id: user._id, name: user.name })),
      ],
      // FIXME
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [team],
    );

    const [valueDropdownOpen, setValueDropdownOpen] = useState(false);

    const query = <RedoFilterSection color="secondary" text="include" />;

    const valueText = userIds
      ? userIds.length === 1
        ? "1 User"
        : ` ${userIds.length} Users`
      : "...";

    const value = (
      <RedoFilterDropdownAnchor
        color="primary"
        onClick={() => setValueDropdownOpen(!valueDropdownOpen)}
        ref={setValueRef}
        text={valueText}
        tooltip={
          userIds && (
            <Flex dir="column">
              {userIds.map((userId) => (
                <Text key={userId}>
                  {userToFriendlyUser(userId, teamUsers)}
                </Text>
              ))}
            </Flex>
          )
        }
        weight="medium"
      />
    );

    const userListOptions: RedoListItem<MentionableUser>[] =
      assigneesOptions.map((assignee) => {
        return {
          id: assignee.id,
          type: "text",
          text: assignee.name,
          value: assignee,
        };
      });

    const selectedUsers: RedoListItem<MentionableUser>[] =
      userListOptions.filter((userItem) =>
        userIds?.includes(userItem.value.id),
      );

    const valueDropdown = (
      <RedoMultiselectDropdown
        dropdownAnchor={valueRef}
        dropdownOpen={valueDropdownOpen}
        items={userListOptions}
        selectedItems={selectedUsers}
        setDropdownOpen={setValueDropdownOpen}
        setSelectedItems={(users) => {
          if (users.length === 0) {
            setFilter({ ...filter, value: null });
          } else {
            const ids = users.map((user) => user.value.id);
            setFilter({ ...filter, value: ids });
          }
        }}
        size="xs"
      />
    );

    return (
      <>
        {valueDropdown}
        <RedoFilterGroup
          Icon={
            ConversationFilterOptionToIcon[FilterGroupFilterOption.MENTIONS]
          }
          propertyName={
            ConversationFilterOptionToName[FilterGroupFilterOption.MENTIONS]
          }
          query={query}
          removeFilter={removeFilter}
          value={value}
        />
      </>
    );
  },
);

function userToFriendlyUser(userId: string, teamUsers: GetUser[]): string {
  return teamUsers.find((user) => user._id === userId)?.name || "Unknown user";
}
