import { z, ZodType } from "zod";
import { SearchCompound } from "../../search-compound";

export enum AdvancedFilterType {
  DATE = "date",
  ARRAY_TO_STRING = "array_to_string",
  NUMBER = "number",
  /**
   * @deprecated Use ARRAY_TO_STRING instead. String filter type is kept only for migration purposes.
   */
  STRING = "string",
  EXISTS = "exists",
  BOOLEAN = "boolean",
}

export const createAdvancedFilterDataSchema = <VALUE, OPERATOR extends string>(
  type: AdvancedFilterType,
  valueSchema: ZodType<VALUE>,
  operatorSchema: ZodType<OPERATOR>,
) =>
  z.object({
    type: z.literal(type),
    name: z.string(),
    value: valueSchema.nullish(),
    operator: operatorSchema,
  });

type CreateAdvancedFilterDataSchema<
  VALUE,
  OPERATOR extends string,
> = ReturnType<typeof createAdvancedFilterDataSchema<VALUE, OPERATOR>>;

export type GenericAdvancedFilterData<VALUE, OPERATOR extends string> = z.infer<
  CreateAdvancedFilterDataSchema<VALUE, OPERATOR>
>;

export type AtlasPathMultiAnalyzer = { value: string; multi: string };

// https://www.mongodb.com/docs/atlas/atlas-search/path-construction/
export type AtlasPath =
  | string
  | string[]
  | AtlasPathMultiAnalyzer
  | (string | AtlasPathMultiAnalyzer)[];

export interface GenericFilterBuilder<
  VALUE,
  OPERATOR extends string,
  DATA extends GenericAdvancedFilterData<VALUE, OPERATOR>,
> {
  type: AdvancedFilterType;
  valueSchema: ZodType<VALUE>;
  operatorSchema: ZodType<OPERATOR>;
  schema: CreateAdvancedFilterDataSchema<VALUE, OPERATOR>;
  buildAtlasSearchQuery: ({
    filter,
    atlasPath,
    atlasMultiPath,
    searchCompound,
    useObjectId,
    timeZoneId,
  }: {
    filter: DATA;
    atlasPath: string;
    atlasMultiPath?: AtlasPath;
    searchCompound: SearchCompound;
    useObjectId?: boolean;
    timeZoneId?: string;
  }) => SearchCompound;
  readFromString: (
    name: string,
    operatorValue: string,
    defaultFilter: DATA,
  ) => DATA | undefined;
  writeToString: (filter: DATA | undefined) => string;
  isPartial: (filter: DATA) => boolean;
}
