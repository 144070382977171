import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { PopperPlacementType } from "@mui/base/Popper";
import { IterableMap } from "@redotech/react-util/component";
import {
  useLazyContext,
  useRequiredContext,
} from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { useSearch } from "@redotech/react-util/search";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { UserContext } from "@redotech/redo-merchant-app-common/user";
import { ConversationTagWithId } from "@redotech/redo-model/conversation";
import { PillTheme } from "@redotech/redo-model/pill-theme";
import { Permission, permitted } from "@redotech/redo-model/user";
import { RedoBadge } from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import {
  RedoList,
  RedoListItem,
} from "@redotech/redo-web/arbiter-components/list/redo-list";
import PlusIcon from "@redotech/redo-web/arbiter-icon/plus.svg";
import { Divider } from "@redotech/redo-web/divider";
import { Dropdown } from "@redotech/redo-web/dropdown";
import { Flex } from "@redotech/redo-web/flex";
import * as classNames from "classnames";
import Fuse from "fuse.js";
import {
  Dispatch,
  memo,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  deleteConversationTag,
  patchConversationTag,
  postConversationTag,
} from "../../client/conversation-tags";
import { getConversation } from "../../client/conversations";
import { ConversationTagsContext } from "../../services/support/conversation-tags-service";
import { ActiveConversationContext } from "../context/active-conversation-context";
import * as conversationTagInputCss from "./conversation-tag-input.module.css";
import {
  BadgePillThemeToColorMapping,
  ConversationTagPill,
} from "./conversation-tag-pill";
import { CreateTagModal } from "./create-tag-modal";
import { EditTagModal } from "./edit-tag-modal";

const searcher = new Fuse<ConversationTagWithId>([], {
  keys: ["name"],
  threshold: 0.3,
});

export const ConversationTagInput = memo(function ConversationTagInput({
  currentTags,
  setCurrentTags,
  disabled = false,
  onlyDropdownMode = undefined,
  showAddButtonToEnterInput: showAddButtonToEnterInput = false,
  dropdownPlacement = "bottom",
}: {
  currentTags: ConversationTagWithId[];
  setCurrentTags(tags: ConversationTagWithId[]): void;
  disabled?: boolean;
  showBorder?: boolean;
  onlyDropdownMode?: {
    anchor: HTMLElement | null;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
  };
  showAddButtonToEnterInput?: boolean;
  dropdownPlacement?: PopperPlacementType;
}) {
  const [availableTags, setAvailableTags] = useState<ConversationTagWithId[]>(
    [],
  );
  const user = useContext(UserContext);
  const team = useRequiredContext(TeamContext);
  const client = useRequiredContext(RedoMerchantClientContext);
  const canCreateTags =
    !!user && permitted(user.permissions, Permission.MANAGE_TAG);
  const [conversationTagsLoad, reloadConversationTags] = useLazyContext(
    ConversationTagsContext,
  );
  const { setActiveConversation, activeConversation } = useContext(
    ActiveConversationContext,
  );

  const [conversationTags] = useLazyContext(ConversationTagsContext);

  useEffect(() => {
    setAvailableTags(conversationTags.value || []);
  }, [conversationTags]);

  const [dropdownAnchor, setDropdownAnchor] = useState<HTMLElement | null>(
    null,
  );

  const selectedTags: string[] = useMemo(() => {
    return currentTags.map((tag) => tag.tagId);
  }, [currentTags]);

  const [searchText, setSearchText] = useState<string>("");

  const [selectDropdownOpen_, setSelectDropdownOpen_] = useState(false);

  const [selectDropdownOpen, setSelectDropdownOpen] = onlyDropdownMode
    ? [onlyDropdownMode.open, onlyDropdownMode.setOpen]
    : [selectDropdownOpen_, setSelectDropdownOpen_];

  const [tagBeingEdited, setTagBeingEdited] = useState<
    ConversationTagWithId | undefined
  >(undefined);

  const [createTagModalOpen, setCreateTagModalOpen] = useState(false);

  const filteredAvailableTags = useSearch(searcher, availableTags, searchText);

  function addTagToConversation(tag: ConversationTagWithId) {
    if (currentTags.find((usedTag) => usedTag.name === tag.name)) {
      return;
    }
    // Eagerly update
    const newTags = [...currentTags, tag];
    setCurrentTags(newTags);
    setSearchText("");
  }

  function removeTagFromConversation(tag: ConversationTagWithId) {
    // Eagerly update
    const updatedTags = currentTags.filter(
      (current) => current.name !== tag.name,
    );
    setCurrentTags(updatedTags);
  }

  const addNewTagButton = (
    <Flex
      className={conversationTagInputCss.pointer}
      onClick={() => setSelectDropdownOpen(true)}
    >
      <RedoBadge
        color="gray"
        segmentLeading={{ type: "icon", Icon: PlusIcon }}
        size="xs"
        text={currentTags.length === 0 ? "Add tag" : undefined}
      />
    </Flex>
  );

  const existingTags = currentTags.length > 0 && (
    <Flex className={conversationTagInputCss.tagsFlex} wrap="wrap">
      <IterableMap items={currentTags} keyFn={(value) => value.name}>
        {(tag) => (
          <ConversationTagPill
            badgeSize="xs"
            tag={tag}
            xClicked={
              disabled
                ? undefined
                : () => {
                    removeTagFromConversation(tag);
                  }
            }
          />
        )}
      </IterableMap>
      {showAddButtonToEnterInput && currentTags.length === 0 && addNewTagButton}
    </Flex>
  );

  useEffect(() => {
    if (selectDropdownOpen) {
      inputRef?.focus();
    }
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectDropdownOpen]);

  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);

  const tagInput = (
    <input
      autoFocus
      className={classNames(
        conversationTagInputCss.input,
        !selectDropdownOpen &&
          showAddButtonToEnterInput &&
          conversationTagInputCss.hide,
      )}
      disabled={disabled}
      onChange={(event) => setSearchText(event.target.value)}
      onClick={(event) => {
        event.stopPropagation();
        setSelectDropdownOpen(true);
      }}
      onFocus={(event) => {
        event.stopPropagation();
        setSelectDropdownOpen(true);
      }}
      placeholder="Search tags..."
      ref={setInputRef}
      value={searchText}
    />
  );

  const wrappedInput = (
    <Flex className={conversationTagInputCss.inputFlex} ref={setDropdownAnchor}>
      <div className={conversationTagInputCss.inputWrapper}>
        {existingTags}
        {showAddButtonToEnterInput && addNewTagButton}
      </div>
    </Flex>
  );

  const tagItems: RedoListItem<ConversationTagWithId | "create">[] =
    useMemo(() => {
      const tagItems: RedoListItem<ConversationTagWithId | "create">[] =
        filteredAvailableTags.map<
          RedoListItem<ConversationTagWithId | "create">
        >((tag) => ({
          id: tag.tagId,
          value: tag,
          type: "badge",
          badge: {
            text: tag.name,
            color: BadgePillThemeToColorMapping[tag.pillTheme],
          },
          menu: {
            onClick: () => {
              setTagBeingEdited(tag);
              setSelectDropdownOpen(false);
            },
          },
        }));

      if (canCreateTags) {
        const createTagItem: RedoListItem<"create"> = {
          id: "create",
          value: "create",
          type: "text",
          text: "New tag",
          leadingItem: { type: "icon", Icon: PlusIcon },
        };

        tagItems.push(createTagItem);
      }
      return tagItems;
    }, [filteredAvailableTags, canCreateTags, setSelectDropdownOpen]);

  const [focusedIndex, setFocusedIndex] = useState<number | undefined>();

  const handleItemSelected = useHandler(
    ({
      item,
      selected,
    }: {
      item: RedoListItem<ConversationTagWithId | "create">;
      selected: boolean;
    }) => {
      if (item.value === "create") {
        setCreateTagModalOpen(true);
        setSelectDropdownOpen(false);
      } else {
        const tag = item.value;
        if (selected) {
          addTagToConversation(tag);
        } else {
          removeTagFromConversation(tag);
        }
      }
    },
  );

  const dropdownItems = (
    <Dropdown
      anchor={onlyDropdownMode?.anchor || dropdownAnchor}
      fitToAnchor={false}
      open={selectDropdownOpen}
      placement={dropdownPlacement}
    >
      <Flex
        className={conversationTagInputCss.dropdownContainer}
        dir="column"
        gap="sm"
      >
        {tagInput}
        <Divider />
        <RedoList
          focusedIndex={focusedIndex}
          items={tagItems}
          itemSelected={handleItemSelected}
          itemsLoading={conversationTags.pending}
          refToListenTo={inputRef}
          selectedItems={selectedTags}
          setFocusedIndex={setFocusedIndex}
          size="sm"
        />
      </Flex>
    </Dropdown>
  );

  const dropdown = selectDropdownOpen && (
    <ClickAwayListener onClickAway={() => setSelectDropdownOpen(false)}>
      {dropdownItems}
    </ClickAwayListener>
  );

  async function handleDelete({ tagId }: { tagId: string }) {
    await deleteConversationTag(client, tagId, async () => {
      reloadConversationTags();
      if (activeConversation) {
        const updatedConversation = await getConversation(client, {
          conversationId: activeConversation._id,
        });
        setActiveConversation(updatedConversation);
      }
    });
    setTagBeingEdited(undefined);
    setSelectDropdownOpen(true);
  }

  async function handleEdit({
    tagId,
    name,
    pillTheme,
  }: {
    tagId: string;
    name: string;
    pillTheme: PillTheme;
  }) {
    await patchConversationTag(client, tagId, { name, pillTheme }, async () => {
      reloadConversationTags();
      if (activeConversation) {
        const updatedConversation = await getConversation(client, {
          conversationId: activeConversation._id,
        });
        setActiveConversation(updatedConversation);
      }
    });
  }

  const editModal = tagBeingEdited && (
    <EditTagModal
      handleDelete={handleDelete}
      handleEdit={handleEdit}
      resolved={() => {
        setTagBeingEdited(undefined);
        setSelectDropdownOpen(true);
      }}
      tag={tagBeingEdited}
      tags={conversationTags}
    />
  );

  async function handleSubmit({
    name,
    pillTheme,
  }: {
    name: string;
    pillTheme: PillTheme;
  }) {
    const newTag = await postConversationTag(
      client,
      { name: name, pillTheme: pillTheme, teamId: team._id },
      reloadConversationTags,
    );
    const newTagTyped: ConversationTagWithId = {
      name: newTag.name,
      pillTheme: newTag.pillTheme,
      tagId: newTag._id,
      source: newTag.source,
    };
    setSelectDropdownOpen(true);
    setCreateTagModalOpen(false);
    setAvailableTags([...availableTags, newTagTyped]);
  }

  const createModal = createTagModalOpen && (
    <CreateTagModal
      cancelClicked={() => {
        setSelectDropdownOpen(true);
        setCreateTagModalOpen(false);
      }}
      handleCreateTag={({
        name,
        pillTheme,
      }: {
        name: string;
        pillTheme: PillTheme;
      }) => handleSubmit({ name, pillTheme })}
      tagsLoad={conversationTagsLoad}
    />
  );

  return (
    <>
      {!onlyDropdownMode ? (
        <div className={conversationTagInputCss.fullWidth}>
          {wrappedInput}
          {dropdown}
          {editModal}
          {createModal}
        </div>
      ) : (
        <>
          {dropdown}
          {editModal}
          {createModal}
        </>
      )}
    </>
  );
});
