import { JsonFormat, JsonFormatError } from "@redotech/json/format";
import { Json } from "@redotech/json/json";
import { isDef } from "@redotech/util/checks";
import { z } from "zod";

const iso8601Regex =
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})$/;
const iso8601DateString = z
  .string()
  .refine((value) => iso8601Regex.test(value), {
    message: "Invalid ISO 8601 date format",
  });

const urlSchema = z.string().url();

const MoneySetSchema = z.strictObject({
  amount: z.string(),
  currency_code: z.string(),
});

const OptionalMoneySetSchema = z
  .strictObject({
    shop_money: MoneySetSchema,
    presentment_money: MoneySetSchema,
  })
  .nullish();

const CustomerAddressSchema = z.strictObject({
  address1: z.string().nullish(),
  address2: z.string().nullish(),
  city: z.string().nullish(),
  company: z.string().nullish(),
  country: z.string().nullish(),
  first_name: z.string().nullish(),
  last_name: z.string().nullish(),
  phone: z.string().nullish(),
  province: z.string().nullish(),
  zip: z.string().nullish(),
  name: z.string().nullish(),
  province_code: z.string().nullish(),
  country_code: z.string().nullish(),
  latitude: z.number().nullish(),
  longitude: z.number().nullish(),
});
export type CustomerAddress = z.infer<typeof CustomerAddressSchema>;

const ClientDetailsSchema = z.strictObject({
  accept_language: z.string().nullish(),
  browser_height: z.number().nullish(),
  browser_ip: z.string().nullish(),
  browser_width: z.number().nullish(),
  session_hash: z.string().nullish(),
  user_agent: z.string().nullish(),
});

const DiscountApplicationSchemaAllocationMethodEnum = z.enum([
  "across",
  "each",
  "one",
]);
const DiscountApplicationSchemaTargetSelectionEnum = z.enum([
  "all",
  "entitled",
  "explicit",
]);
const DiscountApplicationSchemaTargetTypeEnum = z.enum([
  "line_item",
  "shipping_line",
]);
const DiscountApplicationSchemaTypeEnum = z.enum([
  "automatic",
  "manual",
  "script",
  "discount_code",
]);
const DiscountApplicationValueTypeEnum = z.enum(["fixed_amount", "percentage"]);
const DiscountApplicationSchema = z.object({
  allocation_method: DiscountApplicationSchemaAllocationMethodEnum,
  code: z.string().nullish(),
  description: z.string().nullish(),
  target_selection: DiscountApplicationSchemaTargetSelectionEnum,
  target_type: DiscountApplicationSchemaTargetTypeEnum,
  title: z.string().nullish(),
  type: DiscountApplicationSchemaTypeEnum,
  value_type: DiscountApplicationValueTypeEnum,
  value: z.string(),
});
export type DiscountApplication = z.infer<typeof DiscountApplicationSchema>;

const DiscountCodeValueTypeEnum = z.enum([
  "fixed_amount",
  "percentage",
  "shipping",
]);
const DiscountCodeSchema = z.strictObject({
  amount: z.string(),
  code: z.string(),
  type: DiscountCodeValueTypeEnum,
});

const TaxLineSchema = z.strictObject({
  title: z.string(),
  price: z.string(),
  rate: z.number(),
  price_set: z
    .strictObject({
      shop_money: MoneySetSchema,
      presentment_money: MoneySetSchema,
    })
    .nullish(),
  channel_liable: z.boolean().nullish(),
});

const CompleteOrderFulfillmentStatusZodSchema = z.object({
  _value: z.string(),
  _dbValue: z.string().nullable(),
  _displayValue: z.string(),
});

/**
 * In OrderFulfillmentStatusEnum, unfulfilled is represented as null. This
 * CompleteOrderFulfillmentStatus enum is for convenience.
 */
export class CompleteOrderFulfillmentStatus {
  public static readonly FULFILLED = new CompleteOrderFulfillmentStatus(
    "fulfilled",
    "fulfilled",
    "Fulfilled",
  );
  public static readonly RESTOCKED = new CompleteOrderFulfillmentStatus(
    "restocked",
    "restocked",
    "Restocked",
  );
  public static readonly PARTIAL = new CompleteOrderFulfillmentStatus(
    "partial",
    "partial",
    "Partial",
  );
  public static readonly NOT_ELIGIBLE = new CompleteOrderFulfillmentStatus(
    "not_eligible",
    "not_eligible",
    "Not eligible",
  );
  public static readonly UNFULFILLED = new CompleteOrderFulfillmentStatus(
    "unfulfilled",
    null,
    "Unfulfilled",
  );
  public static readonly CANCELED = new CompleteOrderFulfillmentStatus(
    "canceled",
    "canceled",
    "Canceled",
  );

  public static readonly allValues = [
    CompleteOrderFulfillmentStatus.FULFILLED.value,
    CompleteOrderFulfillmentStatus.RESTOCKED.value,
    CompleteOrderFulfillmentStatus.PARTIAL.value,
    CompleteOrderFulfillmentStatus.NOT_ELIGIBLE.value,
    CompleteOrderFulfillmentStatus.UNFULFILLED.value,
    CompleteOrderFulfillmentStatus.CANCELED.value,
  ];

  public static readonly allDbValues = [
    CompleteOrderFulfillmentStatus.FULFILLED.dbValue,
    CompleteOrderFulfillmentStatus.RESTOCKED.dbValue,
    CompleteOrderFulfillmentStatus.PARTIAL.dbValue,
    CompleteOrderFulfillmentStatus.NOT_ELIGIBLE.dbValue,
    CompleteOrderFulfillmentStatus.UNFULFILLED.dbValue,
    CompleteOrderFulfillmentStatus.CANCELED.dbValue,
  ];

  public static fromValue(value: string): CompleteOrderFulfillmentStatus {
    switch (value) {
      case CompleteOrderFulfillmentStatus.FULFILLED.value:
        return CompleteOrderFulfillmentStatus.FULFILLED;
      case CompleteOrderFulfillmentStatus.RESTOCKED.value:
        return CompleteOrderFulfillmentStatus.RESTOCKED;
      case CompleteOrderFulfillmentStatus.PARTIAL.value:
        return CompleteOrderFulfillmentStatus.PARTIAL;
      case CompleteOrderFulfillmentStatus.NOT_ELIGIBLE.value:
        return CompleteOrderFulfillmentStatus.NOT_ELIGIBLE;
      case CompleteOrderFulfillmentStatus.UNFULFILLED.value:
        return CompleteOrderFulfillmentStatus.UNFULFILLED;
      case CompleteOrderFulfillmentStatus.CANCELED.value:
        return CompleteOrderFulfillmentStatus.CANCELED;
      default:
        throw new Error(
          `Invalid CompleteOrderFulfillmentStatus value ${value}`,
        );
    }
  }

  public static fromDbValue(
    dbValue: string | null | undefined,
  ): CompleteOrderFulfillmentStatus | undefined {
    switch (dbValue) {
      case CompleteOrderFulfillmentStatus.FULFILLED.dbValue:
        return CompleteOrderFulfillmentStatus.FULFILLED;
      case CompleteOrderFulfillmentStatus.RESTOCKED.dbValue:
        return CompleteOrderFulfillmentStatus.RESTOCKED;
      case CompleteOrderFulfillmentStatus.PARTIAL.dbValue:
        return CompleteOrderFulfillmentStatus.PARTIAL;
      case CompleteOrderFulfillmentStatus.NOT_ELIGIBLE.dbValue:
        return CompleteOrderFulfillmentStatus.NOT_ELIGIBLE;
      case CompleteOrderFulfillmentStatus.UNFULFILLED.dbValue:
        return CompleteOrderFulfillmentStatus.UNFULFILLED;
      case CompleteOrderFulfillmentStatus.CANCELED.dbValue:
        return CompleteOrderFulfillmentStatus.CANCELED;
      default:
        return undefined;
    }
  }

  public static jsonFormat: JsonFormat<CompleteOrderFulfillmentStatus> = {
    read(json: Json): CompleteOrderFulfillmentStatus {
      if (
        typeof json === "string" &&
        CompleteOrderFulfillmentStatus.allValues.includes(json)
      ) {
        return CompleteOrderFulfillmentStatus.fromValue(json)!;
      } else {
        throw new JsonFormatError(
          `Invalid CompleteOrderFulfillmentStatus ${json}`,
        );
      }
    },
    write(orderFulfillmentStatus: CompleteOrderFulfillmentStatus): Json {
      return orderFulfillmentStatus.value;
    },
  };

  /**
   * Attempts to parse as an object first and then as a nullable string so the
   * RPC layer doesn't have to have a different schema than the db layer.
   */
  public static zodParser = CompleteOrderFulfillmentStatusZodSchema.transform(
    (obj) => {
      return CompleteOrderFulfillmentStatus.fromValue(obj._value);
    },
  ).or(
    z
      .string()
      .nullable()
      .transform(
        (dbValue: string | null) =>
          CompleteOrderFulfillmentStatus.fromDbValue(dbValue)!,
      )
      .refine((value) => isDef(value), {
        message: "Unable to parse CompleteOrderFulfillmentStatus from string",
      }),
  );

  public static fromShopifyOrder(
    shopifyOrder: Pick<ShopifyOrder, "cancelled_at" | "fulfillment_status">,
  ): CompleteOrderFulfillmentStatus | undefined {
    if (shopifyOrder.cancelled_at) {
      return CompleteOrderFulfillmentStatus.CANCELED;
    }

    switch (shopifyOrder.fulfillment_status) {
      case null:
        return CompleteOrderFulfillmentStatus.UNFULFILLED;
      case "fulfilled":
        return CompleteOrderFulfillmentStatus.FULFILLED;
      case "restocked":
        return CompleteOrderFulfillmentStatus.RESTOCKED;
      case "partial":
        return CompleteOrderFulfillmentStatus.PARTIAL;
      case "not_eligible":
        return CompleteOrderFulfillmentStatus.NOT_ELIGIBLE;
      default:
        return undefined;
    }
  }

  private constructor(
    private readonly _value: string,
    private readonly _dbValue: string | null,
    private readonly _displayValue: string,
  ) {}

  public get value(): string {
    return this._value;
  }

  public get dbValue(): string | null {
    return this._dbValue;
  }

  public get displayValue(): string {
    return this._displayValue;
  }
}

export const OrderFulfillmentStatusEnum = z.enum([
  "fulfilled",
  "restocked",
  "partial",
  "not_eligible",
]);

const PropertySchema = z.strictObject({
  name: z.string().nullish(),
  value: z.any().nullish(),
});

const LineItemSchema = z.strictObject({
  id: z.union([z.number(), z.string()]),
  current_quantity: z.number().nullish(),
  tip_payment_gateway: z.string().nullish(),
  tip_payment_method: z.string().nullish(),
  image: z
    .strictObject({
      id: z.number(),
      product_id: z.union([z.number(), z.string()]),
      position: z.number(),
      created_at: iso8601DateString,
      updated_at: iso8601DateString,
      alt: z.string().nullish(),
      width: z.number(),
      height: z.number(),
      src: z.string(),
      variant_ids: z.array(z.number()),
      admin_graphql_api_id: z.string(),
    })
    .nullish(),
  protected: z.union([z.boolean(), z.array(z.string())]).nullish(), // sometimes this is a boolean, others it is an array like ["re:do"] idk why
  finalSaleProtected: z.boolean().nullish(),
  tags: z.array(z.string()).nullish(),
  tip: z
    .strictObject({
      payment_method: z.string().nullish(),
      payment_gateway: z.string().nullish(),
    })
    .nullish(),
  attributed_staffs: z
    .array(z.strictObject({ id: z.string(), quantity: z.number() }))
    .nullish(),
  redoEligible: z.boolean().nullish(),
  admin_graphql_api_id: z.string(),
  variant_id: z.union([z.number(), z.string()]).nullish(),
  title: z.string(),
  quantity: z.number(),
  price: z.string(),
  price_set: OptionalMoneySetSchema,
  pre_tax_price: z.string().nullish(),
  pre_tax_price_set: OptionalMoneySetSchema,
  grams: z.number(),
  sku: z.string().nullish(),
  vendor: z.string().nullish(),
  variant_title: z.string().nullish(),
  fulfillment_service: z.string(),
  product_id: z.union([z.number(), z.string()]).nullish(),
  requires_shipping: z.boolean(),
  taxable: z.boolean(),
  gift_card: z.boolean(),
  name: z.string(),
  variant_inventory_management: z.string().nullish(),
  properties: z.array(PropertySchema),
  product_exists: z.boolean(),
  fulfillable_quantity: z.number(),
  total_discount: z.string(),
  fulfillment_status: OrderFulfillmentStatusEnum.nullish(),
  total_discount_set: z.strictObject({
    shop_money: MoneySetSchema,
    presentment_money: MoneySetSchema,
  }),
  destination_location: z
    .strictObject({
      id: z.number(),
      country_code: z.string().nullish(),
      province_code: z.string().nullish(),
      name: z.string().nullish(),
      address1: z.string().nullish(),
      address2: z.string().nullish(),
      city: z.string().nullish(),
      zip: z.string().nullish(),
    })
    .nullish(),
  origin_location: z
    .strictObject({
      id: z.number(),
      country_code: z.string(),
      province_code: z.string(),
      name: z.string(),
      address1: z.string(),
      address2: z.string().nullish(),
      city: z.string(),
      zip: z.string(),
    })
    .nullish(),
  discount_allocations: z.array(
    z.strictObject({
      amount: z.string(),
      discount_application_index: z.number(),
      amount_set: z.strictObject({
        shop_money: MoneySetSchema,
        presentment_money: MoneySetSchema,
      }),
    }),
  ),
  fulfillment_line_item_id: z.string().nullish(),
  tax_code: z.string().nullish(),
  tax_lines: z.array(TaxLineSchema),
  duties: z.array(
    z.strictObject({
      id: z.number(),
      harmonized_system_code: z.string().nullish(),
      country_code_of_origin: z.string().nullish(),
      price_set: OptionalMoneySetSchema,
      tax_lines: z.array(TaxLineSchema),
      admin_graphql_api_id: z.string(),
    }),
  ),
  discount_price: z.string().nullish().optional(),
  reason: z.string().nullish().optional(),
  loyaltyAmount: z.number().nullish(),
});
export type LineItem = z.infer<typeof LineItemSchema>;

const ShipmentStatusEnum = z.enum([
  "label_printed",
  "label_purchased",
  "attempted_delivery",
  "ready_for_pickup",
  "confirmed",
  "in_transit",
  "out_for_delivery",
  "delivered",
  "failure",
  "picked_up",
  "stalled_in_transit",
  "delayed",
]);
const FulfillmentStatusEnum = z.enum([
  "pending",
  "open",
  "success",
  "cancelled",
  "error",
  "failure",
]);
export const OriginAddressSchema = z.strictObject({
  address1: z.string().nullish(),
  address2: z.string().nullish(),
  city: z.string().nullish(),
  country_code: z.string().nullish(),
  province_code: z.string().nullish(),
  zip: z.string().nullish(),
});
export type OriginAddress = z.infer<typeof OriginAddressSchema>;

const FulfillmentSchema = z.strictObject({
  admin_graphql_api_id: z.string().nullish(),
  created_at: iso8601DateString,
  id: z.union([z.number(), z.string()]),
  line_items: z.array(LineItemSchema),
  location_id: z.number(),
  location: z.strictObject({ name: z.string().nullish() }).nullish(),
  name: z.string(),
  order_id: z.number(),
  origin_address: OriginAddressSchema.nullish(),
  receipt: z.any(),
  status: FulfillmentStatusEnum,
  service: z.string(),
  shipment_status: ShipmentStatusEnum.nullish(),
  tracking_company: z.string().nullish(),
  tracking_number: z.string().nullish(),
  tracking_numbers: z.array(z.string()),
  tracking_url: z.string().nullish(),
  tracking_urls: z.array(z.string()),
  updated_at: iso8601DateString,
  variant_inventory_management: z.string().nullish(),
});
export type Fulfillment = z.infer<typeof FulfillmentSchema>;

const MarketingConsentSchema = z.strictObject({
  state: z.string(),
  opt_in_level: z.string().nullish(),
  consent_updated_at: iso8601DateString.nullish(),
  consent_collected_from: z.string().nullish(),
});

const CustomerStateSchema = z.enum([
  "disabled",
  "invited",
  "enabled",
  "declined",
]);

const CustomerSchema = z.strictObject({
  accepts_marketing: z.boolean().nullish(),
  accepts_marketing_updated_at: iso8601DateString.nullish(),
  addresses: z.array(CustomerAddressSchema).nullish(),
  admin_graphql_api_id: z.string().nullish(),
  created_at: iso8601DateString,
  currency: z.string(),
  default_address: CustomerAddressSchema.merge(
    z.strictObject({
      default: z.boolean(),
      id: z.number(),
      country_name: z.string().nullish(),
      customer_id: z.number(),
    }),
  ).nullish(),
  email: z.string().nullish(),
  email_marketing_consent: MarketingConsentSchema.nullish(),
  id: z.union([z.number(), z.string()]),
  first_name: z.string().nullish(),
  last_name: z.string().nullish(),
  last_order_id: z.number().nullish(),
  last_order_name: z.string().nullish(),
  metafield: z
    .strictObject({
      key: z.string(),
      value: z.string(),
      value_type: z.string(),
      namespace: z.string(),
    })
    .nullish(),
  marketing_opt_in_level: z.string().nullish(), // deprecated, use email_marketing_consent
  multipass_identifier: z.string().nullish(),
  note: z.string().nullish(),
  orders_count: z.number().nullish(),
  password: z.string().nullish(),
  password_confirmation: z.string().nullish(),
  phone: z.string().nullish(),
  sms_marketing_consent: MarketingConsentSchema.nullish(),
  state: CustomerStateSchema,
  tags: z.string(),
  tax_exempt: z.boolean(),
  tax_exemptions: z.array(z.string()),
  total_spent: z.string().nullish(),
  updated_at: iso8601DateString,
  verified_email: z.boolean(),
});

const PaymentTermsSchema = z.strictObject({
  id: z.number(),
  amount: z.number().nullish(),
  currency: z.string().nullish(),
  payment_terms_name: z.string(),
  payment_terms_type: z.string(),
  created_at: iso8601DateString,
  updated_at: iso8601DateString,
  due_in_days: z.number().nullish(),
  payment_schedules: z.array(
    z.strictObject({
      id: z.number(),
      amount: z.string(),
      currency: z.string(),
      issued_at: iso8601DateString.nullish(),
      due_at: iso8601DateString.nullish(),
      completed_at: iso8601DateString.nullish(),
      expected_payment_method: z.string().nullish(),
      created_at: iso8601DateString,
      updated_at: iso8601DateString,
    }),
  ),
});

const ShippingLineSchema = z.strictObject({
  id: z.number(),
  delivery_category: z.string().nullish(),
  phone: z.string().nullish(),
  discount_allocations: z.array(
    z.strictObject({
      amount: z.string(),
      discount_application_index: z.number(),
      amount_set: z.strictObject({
        shop_money: MoneySetSchema,
        presentment_money: MoneySetSchema,
      }),
    }),
  ),
  code: z.string(),
  discounted_price: z.string(),
  discounted_price_set: z.strictObject({
    shop_money: MoneySetSchema,
    presentment_money: MoneySetSchema,
  }),
  price: z.string(),
  price_set: z.strictObject({
    shop_money: MoneySetSchema,
    presentment_money: MoneySetSchema,
  }),
  source: z.string().nullish(),
  title: z.string(),
  tax_lines: z.array(TaxLineSchema),
  carrier_identifier: z.string().nullish(),
  requested_fulfillment_service_id: z.string().nullish(),
  is_removed: z.boolean().nullish(),
});

const RefundOrderAdjustmentKindEnum = z.enum([
  "shipping_refund",
  "refund_discrepancy",
]);
const RefundDutyTypeEnum = z.enum(["FULL", "PROPORTIONAL"]);
const RefundLineItemRestockTypeEnum = z.enum([
  "no_restock",
  "cancel",
  "return",
  "legacy_restock",
]);
const TransactionErrorCodeEnum = z.enum([
  "incorrect_number",
  "invalid_number",
  "invalid_expiry_date",
  "invalid_cvc",
  "expired_card",
  "incorrect_cvc",
  "incorrect_zip",
  "incorrect_address",
  "card_declined",
  "processing_error",
  "call_issuer",
  "pick_up_card",
  "generic_error",
]);
const TransactionKindEnum = z.enum([
  "authorization",
  "capture",
  "sale",
  "void",
  "refund",
]);
const TransactionStatusEnum = z.enum([
  "pending",
  "error",
  "success",
  "failure",
  "deferred",
]);

const PaymentDetailsSchema = z.strictObject({
  credit_card_bin: z.string().nullish(),
  avs_result_code: z.string().nullish(),
  cvv_result_code: z.string().nullish(),
  credit_card_number: z.string(),
  credit_card_company: z.string(),
  credit_card_name: z.string().nullish(),
  credit_card_wallet: z.string().nullish(),
  credit_card_expiration_month: z.number().nullish(),
  credit_card_expiration_year: z.number().nullish(),
  buyer_action_info: z.strictObject({}).nullish(),
  payment_method_name: z.string().nullish(),
});

const TransactionSchema = z.strictObject({
  admin_graphql_api_id: z.string(),
  amount: z.string(),
  authorization: z.string().nullish(),
  authorization_expires_at: iso8601DateString.nullish(),
  created_at: iso8601DateString,
  currency: z.string().nullish(),
  device_id: z.number().nullish(),
  error_code: TransactionErrorCodeEnum.nullish(),
  extended_authorization_attributes: z
    .strictObject({
      standard_authorization_expires_at: iso8601DateString,
      extended_authorization_expires_at: iso8601DateString.nullish(),
    })
    .nullish(),
  gateway: z.string().nullish(),
  id: z.number(),
  kind: TransactionKindEnum,
  location_id: z.number().nullish(),
  message: z.string().nullish(),
  order_id: z.number(),
  payment_details: PaymentDetailsSchema.nullish(),
  parent_id: z.number().nullish(),
  payment_id: z.string().nullish(),
  payments_refund_attributes: z
    .strictObject({
      status: TransactionStatusEnum,
      acquirer_reference_number: z.string().nullish(),
    })
    .nullish(),
  processed_at: iso8601DateString,
  receipt: z.any(),
  source_name: z.string(),
  status: TransactionStatusEnum,
  total_unsettled_set: OptionalMoneySetSchema,
  test: z.boolean(),
  user_id: z.number().nullish(),
  currenct_exchange_adjustment: z
    .strictObject({
      id: z.number(),
      adjustment: z.string(),
      original_amount: z.string(),
      final_amount: z.string(),
      currency: z.string(),
    })
    .nullish(),
});

const RefundSchema = z.strictObject({
  admin_graphql_api_id: z.string(),
  order_id: z.number(),
  total_duties_set: OptionalMoneySetSchema,
  created_at: iso8601DateString,
  duties: z.array(
    z.strictObject({
      duty_id: z.number(),
      amount_set: z.strictObject({
        shop_money: MoneySetSchema,
        presentment_money: MoneySetSchema,
      }),
    }),
  ),
  id: z.number(),
  note: z.string().nullish(),
  order_adjustments: z.array(
    z.strictObject({
      id: z.number(),
      order_id: z.number(),
      refund_id: z.number(),
      amount: z.string(),
      tax_amount: z.string(),
      reason: z.string(),
      kind: RefundOrderAdjustmentKindEnum,
      amount_set: z.strictObject({
        shop_money: MoneySetSchema,
        presentment_money: MoneySetSchema,
      }),
      tax_amount_set: z.strictObject({
        shop_money: MoneySetSchema,
        presentment_money: MoneySetSchema,
      }),
    }),
  ),
  processed_at: iso8601DateString,
  refund_duties: z
    .array(
      z.strictObject({ duty_id: z.number(), refund_type: RefundDutyTypeEnum }),
    )
    .nullish(),
  refund_line_items: z.array(
    z.strictObject({
      id: z.number(),
      line_item: LineItemSchema,
      line_item_id: z.number(),
      quantity: z.number(),
      restock_type: RefundLineItemRestockTypeEnum.nullish(),
      location_id: z.number().nullish(),
      subtotal: z.number(),
      total_tax: z.number(),
      subtotal_set: z.strictObject({
        shop_money: MoneySetSchema,
        presentment_money: MoneySetSchema,
      }),
      total_tax_set: z.strictObject({
        shop_money: MoneySetSchema,
        presentment_money: MoneySetSchema,
      }),
    }),
  ),
  restock: z.boolean(),
  transactions: z.array(TransactionSchema),
  user_id: z.number().nullish(),
});

const CancelledReasonEnum = z.enum([
  "customer",
  "fraud",
  "inventory",
  "declined",
  "other",
  "staff",
]);
const FinancialStatusEnum = z.enum([
  "authorized",
  "pending",
  "paid",
  "partially_paid",
  "partially_refunded",
  "refunded",
  "voided",
]);

export const ShopifyOrderSchema = z.strictObject({
  admin_graphql_api_id: z.string().nullish(),
  app_id: z.number().nullish(),
  billing_address: CustomerAddressSchema.nullish(),
  browser_ip: z.string().nullish(),
  buyer_accepts_marketing: z.boolean(),
  cancel_reason: CancelledReasonEnum.nullish(),
  cancelled_at: iso8601DateString.nullish(),
  cart_token: z.string().nullish(),
  checkout_id: z.number().nullish(),
  checkout_token: z.string().nullish(),
  client_details: ClientDetailsSchema.nullish(),
  closed_at: iso8601DateString.nullish(),
  company: z
    .strictObject({ id: z.number(), location_id: z.number().nullish() })
    .nullish(),
  confirmation_number: z.string().nullish(),
  confirmed: z.boolean(),
  contact_email: z.string().nullish(),
  created_at: iso8601DateString,
  currency: z.string(),
  current_total_additional_fees_set: OptionalMoneySetSchema,
  current_total_discounts: z.string(),
  current_total_discounts_set: OptionalMoneySetSchema,
  current_total_duties_set: OptionalMoneySetSchema,
  current_total_price: z.string(),
  current_total_price_set: OptionalMoneySetSchema,
  current_subtotal_price: z.string(),
  current_subtotal_price_set: OptionalMoneySetSchema,
  current_total_tax: z.string(),
  current_total_tax_set: OptionalMoneySetSchema,
  customer: CustomerSchema.nullish(),
  customer_locale: z.string().nullish(),
  device_id: z.number().nullish(),
  discount_applications: z.array(DiscountApplicationSchema),
  discount_codes: z.array(DiscountCodeSchema),
  email: z.string().nullish(),
  estimated_taxes: z.boolean().nullish(),
  financial_status: FinancialStatusEnum.nullish(),
  fulfillments: z.array(FulfillmentSchema),
  fulfillment_status: OrderFulfillmentStatusEnum.nullish(),
  gateway: z.string().nullish(),
  id: z.union([z.number(), z.string()]),
  landing_site: z.string().nullish(),
  landing_site_ref: z.string().nullish(),
  line_items: z.array(LineItemSchema),
  location_id: z.number().nullish(),
  merchant_of_record_app_id: z.number().nullish(),
  name: z.string(),
  note: z.string().nullish(),
  note_attributes: z.array(PropertySchema),
  number: z.union([z.number(), z.string()]),
  order_number: z.union([z.number(), z.string()]).nullish(),
  original_total_additional_fees_set: OptionalMoneySetSchema,
  original_total_duties_set: OptionalMoneySetSchema,
  payment_details: PaymentDetailsSchema.nullish(),
  payment_terms: PaymentTermsSchema.nullish(),
  payment_gateway_names: z.array(z.string()),
  phone: z.string().nullish(),
  po_number: z.string().nullish(),
  presentment_currency: z.string(),
  processing_method: z.string().nullish(),
  processed_at: iso8601DateString,
  reference: z.string().nullish(),
  referring_site: z.string().nullish(),
  refunds: z.array(RefundSchema),
  shipping_address: CustomerAddressSchema.nullish(),
  shipping_lines: z.array(ShippingLineSchema),
  source_identifier: z.string().nullish(),
  source_name: z.string(),
  source_url: z.string().nullish(),
  subtotal_price: z.string(),
  subtotal_price_set: z.strictObject({
    shop_money: MoneySetSchema,
    presentment_money: MoneySetSchema,
  }),
  tags: z.string().nullish(),
  tax_exempt: z.boolean().nullish(),
  tax_lines: z.array(TaxLineSchema),
  taxes_included: z.boolean(),
  test: z.boolean(),
  token: z.string(),
  total_discounts: z.string(),
  total_discounts_set: z.strictObject({
    shop_money: MoneySetSchema,
    presentment_money: MoneySetSchema,
  }),
  total_line_items_price: z.string(),
  total_line_items_price_set: z.strictObject({
    shop_money: MoneySetSchema,
    presentment_money: MoneySetSchema,
  }),
  total_outstanding: z.string(),
  total_price: z.string(),
  total_price_usd: z.string().nullish(),
  total_price_set: z.strictObject({
    shop_money: MoneySetSchema,
    presentment_money: MoneySetSchema,
  }),
  total_shipping_price_set: z.strictObject({
    shop_money: MoneySetSchema,
    presentment_money: MoneySetSchema,
  }),
  total_tax: z.string(),
  total_tax_set: z.strictObject({
    shop_money: MoneySetSchema,
    presentment_money: MoneySetSchema,
  }),
  total_tip_received: z.string(),
  total_weight: z.number().nullish(),
  updated_at: iso8601DateString,
  user_id: z.number().nullish(),
  order_status_url: urlSchema.nullish(),
  original_order: z.any().nullish(),
});

export type ShopifyOrder = z.infer<typeof ShopifyOrderSchema>;
