import {
  createLazyContext,
  useLazyContextProvider,
  useRequiredContext,
} from "@redotech/react-util/context";
import { RedoMerchantRpcClientContext } from "@redotech/redo-merchant-app-common/rpc-client";
import { PhoneNumber } from "@redotech/redo-model/phone/phone-number";
import { memo, ReactNode } from "react";

export const PhoneNumberContext = createLazyContext<PhoneNumber | null>();
export const PhoneNumberService = memo(function MacrosService({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const client = useRequiredContext(RedoMerchantRpcClientContext);

  const loadState = useLazyContextProvider(async (signal) => {
    const number: PhoneNumber | null = await client.getPhoneNumberForTeam(
      {},
      { signal },
    );
    return number;
  });

  return (
    <PhoneNumberContext.internalContext.Provider value={loadState}>
      {children}
    </PhoneNumberContext.internalContext.Provider>
  );
});
