import { advancedFlowSchema } from "@redotech/redo-model/advanced-flow/advanced-flow-db-parser";
import { z } from "zod";

export const createAdvancedFlow = {
  input: z.object({
    newFlow: advancedFlowSchema.omit({
      _id: true,
      createdAt: true,
      updatedAt: true,
      versionGroupId: true,
    }),
    setIndex: z.boolean(),
  }),
  output: z.object({ id: z.string() }),
};
