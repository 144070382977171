import { folderTypes } from "@redotech/redo-model/views/folders";
import { RedoTextInput } from "@redotech/redo-web/arbiter-components/input/redo-text-input";
import {
  RedoModal,
  RedoModalButtonProps,
} from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import TrashIcon from "@redotech/redo-web/arbiter-icon/trash-01.svg";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo, useState } from "react";
import { ConfirmDeleteOmsFolderModal } from "./confirm-delete-oms-folder-modal";

export const EditOmsViewFolderModal = memo(function EditOmsViewFolderModal({
  isOpen,
  onClose,
  onSubmit,
  selectedFolder,
  deleteFolder,
  reservedFolderNames,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (folderId: string, folderName: string) => Promise<void>;
  selectedFolder: folderTypes["fulfillment-groups"];
  deleteFolder: (folderId: string) => Promise<void>;
  reservedFolderNames: Set<string>;
}) {
  const [folderName, setFolderName] = useState(selectedFolder.name);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const submitDisabled =
    !folderName.trim().length || reservedFolderNames.has(folderName.trim());

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      await onSubmit(selectedFolder._id, folderName);
      onClose();
    } finally {
      setIsSubmitting(false);
      setFolderName("");
    }
  };

  const primaryButton: RedoModalButtonProps = {
    text: "Save",
    onClick: handleSubmit,
    disabled: !!submitDisabled,
    pending: false,
  };

  const secondaryButton: RedoModalButtonProps = {
    text: "Delete",
    onClick: () => setShowConfirmDelete(true),
    disabled: false,
    theme: "destructive",
    IconLeading: TrashIcon,
  };

  return (
    <>
      <RedoModal
        buttonPlacement="full"
        isOpen={isOpen}
        onModalCloseRequested={isSubmitting ? () => {} : onClose}
        primaryButton={primaryButton}
        secondaryButton={secondaryButton}
        title="Edit folder"
      >
        <Flex dir="column" gap="xl">
          <Flex dir="column">
            <Text>Folder name</Text>
            <div data-devinid="folder-name-input">
              <RedoTextInput
                setValue={setFolderName}
                size="md"
                state={isSubmitting ? "readonly" : "default"}
                value={folderName}
              />
            </div>
          </Flex>
        </Flex>
      </RedoModal>
      <ConfirmDeleteOmsFolderModal
        folderName={selectedFolder.name}
        isOpen={showConfirmDelete && !!selectedFolder}
        onClose={() => setShowConfirmDelete(false)}
        onConfirm={async () => {
          if (selectedFolder) {
            await deleteFolder(selectedFolder._id);
            onClose();
          }
        }}
      />
    </>
  );
});
