// https://stackoverflow.com/a/201378
export const emailRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const isValidEmail = (email: string): boolean =>
  emailRegex.test(email.toLowerCase());

export const maskEmail = (email: string): string => {
  const [username, fullDomain] = email.split("@");
  const [domain, tld] = fullDomain.split(".");

  const beginningUsername = username.slice(0, 2);
  const endUsername = username.slice(-1);

  const beginningDomain = domain.slice(0, 1);
  return `${beginningUsername}**${endUsername}@${beginningDomain}***.${tld}`;
};
