import { Order } from "@redotech/redo-model/order";

import { Return } from "@redotech/redo-model/return";
import { Team } from "@redotech/redo-model/team";
import ChevronDownIcon from "@redotech/redo-web/arbiter-icon/chevron-down_filled.svg";
import ExternalLinkIcon from "@redotech/redo-web/arbiter-icon/link-external-02.svg";
import { Text } from "@redotech/redo-web/text";
import * as classNames from "classnames";

import { RedoExternalLink } from "@redotech/redo-web/arbiter-components/links/redo-external-link";

import { Dropdown } from "@redotech/redo-web/dropdown";
import { Flex } from "@redotech/redo-web/flex";
import { memo, useState } from "react";

import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { THIRD_PARTY_SOURCE } from "@redotech/redo-model/third-party";
import { ExternalLink } from "@redotech/redo-web/link";
import { orderExternalUrl } from "../util";
import * as orderNameCss from "./order-name.module.css";
export const OrderName = memo(function OrderName({
  return_,
  order,
}: {
  return_: Return;
  order: Order;
}) {
  return (
    <Flex align="center" key={order.shopify.name}>
      {order.shopify.source_name !== THIRD_PARTY_SOURCE ? (
        <Flex className={orderNameCss.orderLink}>
          <RedoExternalLink
            fontSize="lg"
            fontWeight="semibold"
            showIcon={false}
            text={"Order " + order.shopify.name}
            textClassName={orderNameCss.orderLink}
            url={orderExternalUrl(return_.team, order)}
          />
        </Flex>
      ) : (
        <Text fontSize="lg" fontWeight="semibold">
          Third party order
        </Text>
      )}
    </Flex>
  );
});

export const MultipleOrdersName = memo(function MultipleOrdersName({
  orders,
  team,
}: {
  orders: Order[];
  team: Team;
}) {
  const [open, setOpen] = useState(false);
  const [anchorRef, setAnchorRef] = useState<HTMLDivElement | null>(null);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)} ref={setAnchorRef}>
      <div ref={setAnchorRef}>
        <ExternalLink
          className={orderNameCss.multipleOrdersTitle}
          onClick={() => setOpen(!open)}
          showIcon={false}
        >
          <Flex align="center" dir="row" gap="xs">
            <Text fontSize="lg" fontWeight="semibold" textColor="primary">
              Multiple orders
            </Text>
            <ChevronDownIcon
              className={classNames(
                orderNameCss.chevron,
                open
                  ? orderNameCss.dropdownIconOpen
                  : orderNameCss.dropdownIcon,
              )}
            />
          </Flex>
        </ExternalLink>
        <Dropdown anchor={anchorRef} fitToAnchor={false} open={open}>
          {orders.map((order) => (
            <Flex
              className={orderNameCss.multipleOrdersDropdownItem}
              justify="space-between"
              key={order.shopify.name}
            >
              <RedoExternalLink
                fontSize="xs"
                fontWeight="thin"
                showIcon={false}
                text={order.shopify.name}
                textClassName={orderNameCss.multipleOrdersLink}
                url={orderExternalUrl(team, order)}
              />
              <ExternalLinkIcon className={orderNameCss.externalLinkIcon} />
            </Flex>
          ))}
        </Dropdown>
      </div>
    </ClickAwayListener>
  );
});
