import { IterableMap } from "@redotech/react-util/component";
import { useHandler } from "@redotech/react-util/hook";
import { UserContext } from "@redotech/redo-merchant-app-common/user";
import { Step as ModelStep } from "@redotech/redo-model/return-flow";
import { Permission, permitted } from "@redotech/redo-model/user";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  IconButton,
} from "@redotech/redo-web/button";
import { Divider } from "@redotech/redo-web/divider";
import { BlockLayout } from "@redotech/redo-web/flowchart";
import LeftArrowIcon from "@redotech/redo-web/icon-old/left-arrow.svg";
import QuestionIcon from "@redotech/redo-web/icon-old/question.svg";
import TrashIcon from "@redotech/redo-web/icon-old/trash.svg";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { InputTheme, TextInput } from "@redotech/redo-web/text-input";
import { groupBy } from "@redotech/util/map";
import { produce } from "immer";
import Quill from "quill";
import { memo, useContext, useRef } from "react";
import * as multipleChoiceCss from "../return-flow/multiple-choice.module.css";
import { RequiredMessage } from "../return-flow/required-message";
import {
  StepDownstream,
  StepId,
  StepType,
  StepTypeDetailsProps,
} from "../return-flow/step";
import { containsImg, StepRichTextEditor } from "./step-rich-text";

export interface ChoiceState {
  name: string;
  next: StepId | null;
  nextName: string | null;
}

export interface State {
  choices: ChoiceState[];
  message: string;
  messageHtml?: string;
  attempted?: boolean;
  customTitle?: string;
}

const Details = memo(function Details({
  stepSelect,
  state,
  setState,
}: StepTypeDetailsProps<State>) {
  const user = useContext(UserContext);
  const canEditSettings =
    !!user && permitted(user.permissions, Permission.EDIT_SETTINGS);

  const messageQuillRef = useRef<Quill | undefined>(undefined);

  const handleAdd = useHandler(() =>
    setState((state) => ({
      ...state,
      choices: [...state.choices, { name: "", next: null, nextName: null }],
    })),
  );

  const handleMessageChange = useHandler(() => {
    if (!messageQuillRef.current) {
      return;
    }
    let message = messageQuillRef.current.getText();
    let messageHtml: string | undefined =
      messageQuillRef.current.getSemanticHTML();
    if (message === "\n") {
      message = "";
    }
    if (messageHtml === "<p></p>") {
      messageHtml = undefined;
    }

    if (messageHtml !== state.messageHtml || message !== state.message) {
      setState((state) => ({ ...state, message, messageHtml }));
    }
  });

  const handleFocus = useHandler((focused: boolean) => {
    if (!focused) {
      setState((state) => ({ ...state, attempted: true }));
    }
  });

  return (
    <>
      <LabeledInput
        errors={containsImg(state.messageHtml) ? ["Cannot contain images"] : []}
        label="Question"
      >
        <StepRichTextEditor
          htmlValue={state.messageHtml}
          onTextChange={handleMessageChange}
          quillRef={messageQuillRef}
          readOnly={!canEditSettings}
          textValue={state.message}
        />
      </LabeledInput>
      <IterableMap items={state.choices} keyFn={(_, index) => index}>
        {(choice, index) => (
          <StateChoiceComponent
            canEditSettings={canEditSettings}
            choice={choice}
            handleFocus={handleFocus}
            index={index}
            setState={setState}
            state={state}
            stepSelect={stepSelect}
          />
        )}
      </IterableMap>
      <Divider />
      <div>
        <Button
          disabled={!canEditSettings}
          onClick={handleAdd}
          size={ButtonSize.SMALL}
          theme={ButtonTheme.OUTLINED}
        >
          + Add option
        </Button>
      </div>
      {!state.choices.length && (
        <RequiredMessage>Must have at least one option</RequiredMessage>
      )}
    </>
  );
});

const StateChoiceComponent = memo(function StateChoiceComponent({
  state,
  setState,
  stepSelect,
  handleFocus,
  canEditSettings,
  choice,
  index,
}: {
  state: StepTypeDetailsProps<State>["state"];
  setState: StepTypeDetailsProps<State>["setState"];
  stepSelect: StepTypeDetailsProps<State>["stepSelect"];
  handleFocus(focused: boolean): void;
  canEditSettings: boolean;
  choice: ChoiceState;
  index: number;
}) {
  const setName = useHandler((name: string) =>
    setState((state) =>
      produce(state, (state) => {
        state.choices[index].name = name;
        state.choices[index].nextName = null;
      }),
    ),
  );
  const setStep = useHandler((next: StepId | undefined | "base") => {
    if (next === "base") {
      setState((state) =>
        produce(state, (state) => {
          state.choices[index].next = null;
          state.choices[index].nextName = "base";
        }),
      );
    } else {
      setState((state) =>
        produce(state, (state) => {
          state.choices[index].next = next || null;
          state.choices[index].nextName = null;
        }),
      );
    }
  });
  const handleUp = useHandler(() => {
    setState((state) =>
      produce(state, (state) => {
        const choices = state.choices.splice(index, 1);
        state.choices.splice(index - 1, 0, ...choices);
      }),
    );
  });
  const handleDown = useHandler(() => {
    setState((state) =>
      produce(state, (state) => {
        const choices = state.choices.splice(index, 1);
        state.choices.splice(index + 1, 0, ...choices);
      }),
    );
  });
  const handleDelete = useHandler(() => {
    setState((state) =>
      produce(state, (state) => {
        state.choices.splice(index, 1);
      }),
    );
  });
  return (
    <>
      <Divider />
      <LabeledInput
        label={
          <div className={multipleChoiceCss.label}>
            <div>Option #{index + 1}</div>
            {canEditSettings && (
              <>
                <IconButton
                  disabled={!index}
                  onClick={handleUp}
                  size={ButtonSize.SMALL}
                >
                  <LeftArrowIcon className={multipleChoiceCss.upArrow} />
                </IconButton>
                <IconButton
                  disabled={index === state.choices.length - 1}
                  onClick={handleDown}
                  size={ButtonSize.SMALL}
                >
                  <LeftArrowIcon className={multipleChoiceCss.downArrow} />
                </IconButton>
                <IconButton onClick={handleDelete} size={ButtonSize.SMALL}>
                  <TrashIcon />
                </IconButton>
              </>
            )}
          </div>
        }
      >
        <TextInput
          error={state.attempted && !choice.name}
          onChange={setName}
          onFocus={(focused: boolean) => {
            if (!choice.name) {
              handleFocus(focused);
            }
          }}
          readonly={!canEditSettings}
          theme={InputTheme.FORM}
          value={choice.name}
        />
      </LabeledInput>
      <LabeledInput
        errors={
          state.attempted && (!choice.next || !choice.nextName)
            ? ["Required"]
            : []
        }
        label={`Next step #${index + 1}`}
      >
        {stepSelect({
          value: choice.nextName ? choice.nextName : choice.next,
          valueChange: setStep,
          type: ModelStep.CHAT_MULTIPLE_CHOICE,
        })}
      </LabeledInput>
    </>
  );
});

export const CHAT_MULTIPLE_CHOICE: StepType<
  State,
  ModelStep.ChatMultipleChoice
> = {
  Details,
  customTitle(state) {
    return state.customTitle;
  },
  title: "Multiple choice",
  valid(state: State) {
    return (
      !!state.choices.length &&
      state.choices.every(
        (choice) => choice.name && (choice.next || choice.nextName),
      ) &&
      !containsImg(state.messageHtml)
    );
  },
  Icon: QuestionIcon,
  description(state) {
    return state.message;
  },
  downstream(state) {
    const choices = groupBy(
      state.choices.filter((choice) => choice.next !== undefined),
      (choice) => choice.next,
    );
    const result: StepDownstream[] = [];
    for (const [next, c] of choices.entries()) {
      result.push({
        id: next!,
        label: c.length === 1 ? c[0].name : `${c.length} choices`,
      });
    }
    return result;
  },
  empty: { choices: [], message: "", messageHtml: undefined },
  layout() {
    return BlockLayout.FULL;
  },
  stepDeleted(state, stepId) {
    return produce(state, (state) => {
      for (const choice of state.choices) {
        if (choice.next === stepId) {
          choice.next = null;
        }
      }
    });
  },
  toModel(state, id): ModelStep.ChatMultipleChoice {
    return {
      customTitle: state.customTitle,
      type: ModelStep.CHAT_MULTIPLE_CHOICE,
      message: state.message,
      messageHtml: state.messageHtml,
      options: state.choices.map((choice) => ({
        name: choice.name,
        next: choice.next ? id(choice.next) : null,
        nextName: choice.nextName,
      })),
    };
  },
  fromModel(model: ModelStep.ChatMultipleChoice, id: (id: number) => StepId) {
    return {
      customTitle: model.customTitle,
      choices: model.options.map((option) => ({
        name: option.name,
        next: option.next ? id(option.next) : null,
        nextName: option.nextName,
      })),
      message: model.message,
      messageHtml: model.messageHtml,
    };
  },
};
