import { z } from "zod";

const timeSeriesMetric = z.array(
  z.object({ plainDate: z.string(), value: z.number().optional() }),
);

export const getCheckoutOptimizationTimeSeriesMetrics = {
  input: z.null(),
  output: z.object({
    checkoutConversionRateOverTimeSeries: timeSeriesMetric,
    averageShippingRevenuePerOrderOverTimeSeries: timeSeriesMetric,
    currency: z.string(),
  }),
};
