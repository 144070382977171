import {
  Call,
  UserCallAvailability,
} from "@redotech/redo-model/support/voice/voice-types";
import { createContext } from "react";

export interface VoiceState {
  call?: Call;
  status: UserCallAvailability;
  setStatus: (status: UserCallAvailability) => void;
  performCall: (number: string) => void;
  endCall: () => void;
  rejectCall: () => void;
  answerCall: () => void;
  muted: boolean;
  toggleMute: () => void;
  deafened: boolean;
  toggleDeafen: () => void;
  onHold: boolean;
  toggleHold: () => void;
  recording: boolean;
  toggleRecord: () => void;
}

export const VoiceContext = createContext<VoiceState>({
  status: UserCallAvailability.AVAILABLE,
  setStatus: () => {},
  performCall: () => {},
  endCall: () => {},
  rejectCall: () => {},
  answerCall: () => {},
  muted: false,
  toggleMute: () => {},
  deafened: false,
  toggleDeafen: () => {},
  onHold: false,
  toggleHold: () => {},
  recording: false,
  toggleRecord: () => {},
});
