import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";
import {
  CheckoutOptimizationWidgetType,
  PurchaseCheckoutBlockRenderTarget,
} from "./widget";

const baseWidgetSchema = z.object({
  /**
   * Defined `id` means the widget is existing and needs to be updated.
   * Undefined `id` means the widget is new and needs to be created
   */
  id: zExt.objectId().optional(),
  target: z.nativeEnum(PurchaseCheckoutBlockRenderTarget).optional(),
  enabled: z.boolean(),
});

export const checkoutWidgetsSchema = z.object({
  [CheckoutOptimizationWidgetType.THRESHOLD_UPSELL]: baseWidgetSchema.extend({
    type: z.literal(CheckoutOptimizationWidgetType.THRESHOLD_UPSELL),
    thresholdAmount: z.number(),
    gteThresholdText: z.string(),
    ltThresholdText: z.string(),
    addToCartButtonColor: z.string(),
    addToCartButtonTextColor: z.string(),
    progressBarColor: z.string(),
    excludedProductTags: z.array(z.string()),
  }),
  [CheckoutOptimizationWidgetType.COUNTDOWN_TIMER]: baseWidgetSchema.extend({
    type: z.literal(CheckoutOptimizationWidgetType.COUNTDOWN_TIMER),
  }),
} satisfies Record<CheckoutOptimizationWidgetType, any>);

/**
 * The global state store for the checkout widgets builder.
 */
const checkoutWidgetsBuilderStateSchema = z.object({
  selectedWidget: z.nativeEnum(CheckoutOptimizationWidgetType),
  checkoutWidgets: checkoutWidgetsSchema,
});
export type CheckoutWidgetsBuilderState = z.infer<
  typeof checkoutWidgetsBuilderStateSchema
>;
