import MinusIcon from "@redotech/redo-web/arbiter-icon/minus.svg";
import PhoneIcon from "@redotech/redo-web/arbiter-icon/phone.svg";
import XIcon from "@redotech/redo-web/arbiter-icon/x-close.svg";
import { memo } from "react";
import { RedoButton } from "../arbiter-components/buttons/redo-button";
import { Flex } from "../flex";
import { Text } from "../text";
import { ExpandedState } from "./phone-widget";
import * as phoneWidgetCss from "./phone-widget.module.css";

export const PhoneWidgetHeader = memo(function ExpandedWidgetHeader({
  setExpandedState,
  merchantPhoneNumber,
}: {
  setExpandedState: (val: ExpandedState) => void;
  merchantPhoneNumber: string;
}) {
  return (
    <Flex align="center" justify="space-between" p="xl">
      <Flex align="center">
        <Flex align="center" className={phoneWidgetCss.phoneIcon}>
          <PhoneIcon />
        </Flex>
      </Flex>
      <Text fontSize="sm" textColor="secondary">
        {merchantPhoneNumber}
      </Text>
      <Flex gap="none">
        <RedoButton
          IconLeading={MinusIcon}
          onClick={() => setExpandedState("collapsed")}
          size="xs"
        />
        <RedoButton
          IconLeading={XIcon}
          onClick={() => setExpandedState("iconOnly")}
          size="xs"
        />
      </Flex>
    </Flex>
  );
});
