import { z } from "zod";

const OperatorZodSchema = z.object({
  type: z.literal("operator"),
  operator: z.enum(["+", "-", "*", "/", "^"]),
});

const LeftParenthesisZodSchema = z.object({
  type: z.literal("left-parenthesis"),
});

const RightParenthesisZodSchema = z.object({
  type: z.literal("right-parenthesis"),
});

const TermZodSchema = z.object({ type: z.literal("term"), value: z.string() });

export const MetricDefinitionPartZodSchema = z.discriminatedUnion("type", [
  OperatorZodSchema,
  LeftParenthesisZodSchema,
  RightParenthesisZodSchema,
  TermZodSchema,
]);
export type MetricDefinitionPart = z.infer<
  typeof MetricDefinitionPartZodSchema
>;
