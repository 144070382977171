import { assertNever } from "@redotech/util/type";

// This is a copy of the FulfillmentOrderStatus enum from @redotech/shopify-client/admin.graphql, but that can also be used in redo/model and therefore the frontend
export enum RedoShopifyFulfillmentOrderStatus {
  /** The fulfillment order has been cancelled by the merchant. */
  Cancelled = "CANCELLED",
  /** The fulfillment order has been completed and closed. */
  Closed = "CLOSED",
  /** The fulfillment order cannot be completed as requested. */
  Incomplete = "INCOMPLETE",
  /** The fulfillment order is being processed. */
  InProgress = "IN_PROGRESS",
  /** The fulfillment order is on hold. The fulfillment process can't be initiated until the hold on the fulfillment order is released. */
  OnHold = "ON_HOLD",
  /** The fulfillment order is ready for fulfillment. */
  Open = "OPEN",
  /** The fulfillment order is deferred and will be ready for fulfillment after the date and time specified in `fulfill_at`. */
  Scheduled = "SCHEDULED",
}

export function fulfillmentOrderStatusIsDone(
  status: RedoShopifyFulfillmentOrderStatus,
) {
  switch (status) {
    case RedoShopifyFulfillmentOrderStatus.Closed:
    case RedoShopifyFulfillmentOrderStatus.Cancelled:
    case RedoShopifyFulfillmentOrderStatus.Incomplete:
      return true;
    case RedoShopifyFulfillmentOrderStatus.InProgress:
    case RedoShopifyFulfillmentOrderStatus.OnHold:
    case RedoShopifyFulfillmentOrderStatus.Scheduled:
    case RedoShopifyFulfillmentOrderStatus.Open:
      return false;
    default:
      assertNever(status);
  }
}
