import { useHandler } from "@redotech/react-util/hook";
import { UserContext } from "@redotech/redo-merchant-app-common/user";
import { Step as ModelStep } from "@redotech/redo-model/return-flow";
import { Permission, permitted } from "@redotech/redo-model/user";
import { BlockLayout } from "@redotech/redo-web/flowchart";
import TagIcon from "@redotech/redo-web/icon-old/tag.svg"; // TODO(devin): Update icon
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { InputTheme, TextInput } from "@redotech/redo-web/text-input";
import { produce } from "immer";
import { memo, useContext } from "react";
import { StepDownstream, StepId, StepType, StepTypeDetailsProps } from "./step";

export interface State {
  prefix: string;
  suffix: string;
  next: StepId | undefined;
  customTitle?: string;
}

function valid(state: State) {
  return !!state.prefix || !!state.suffix;
}

const Details = memo(function Details({
  stepSelect,
  state,
  setState,
}: StepTypeDetailsProps<State>) {
  const user = useContext(UserContext);
  const canEditSettings =
    !!user && permitted(user.permissions, Permission.EDIT_SETTINGS);
  const nextStepChange = useHandler((next: StepId | undefined) => {
    setState((state) => ({ ...state, next }));
  });
  const handlePrefixChange = useHandler((prefix: string) => {
    setState((state) => ({ ...state, prefix: prefix }));
  });
  const handleSuffixChange = useHandler((suffix: string) => {
    setState((state) => ({ ...state, suffix: suffix }));
  });

  return (
    <>
      <LabeledInput
        description="Prefix that will be prepended to any created discount codes"
        label="Discount code prefix"
      >
        <TextInput
          onChange={handlePrefixChange}
          readonly={!canEditSettings}
          theme={InputTheme.FORM}
          value={state.prefix}
        />
      </LabeledInput>
      <LabeledInput
        description="Suffix that will be appended to any created discount codes"
        label="Discount code suffix"
      >
        <TextInput
          onChange={handleSuffixChange}
          readonly={!canEditSettings}
          theme={InputTheme.FORM}
          value={state.suffix}
        />
      </LabeledInput>
      <LabeledInput errors={[]} label="Next step">
        {stepSelect({ value: state.next, valueChange: nextStepChange })}
      </LabeledInput>
    </>
  );
});

export const DISCCOUNT_CODE_NAME: StepType<State, ModelStep.DiscountCodeName> =
  {
    Details,
    customTitle(state) {
      return state.customTitle;
    },
    title: "Discount code name",
    valid,
    Icon: TagIcon,
    description(state) {
      return `${state.prefix}...${state.suffix}`;
    },
    downstream(state) {
      const result: StepDownstream[] = [];
      if (state.next !== undefined) {
        result.push({ id: state.next });
      }
      return result;
    },
    empty: { next: undefined, prefix: "", suffix: "" },
    stepDeleted(state, stepId) {
      return produce(state, (state) => {
        if (state.next === stepId) {
          state.next = undefined;
        }
      });
    },
    layout() {
      return BlockLayout.FULL;
    },
    toModel(state, id): ModelStep.DiscountCodeName {
      return {
        customTitle: state.customTitle,
        type: ModelStep.DISCOUNT_CODE_NAME,
        next: state.next ? id(state.next) : undefined,
        prefix: state.prefix,
        suffix: state.suffix,
      };
    },
    fromModel(model, id) {
      return {
        customTitle: model.customTitle,
        next: model.next ? id(model.next) : undefined,
        prefix: model.prefix || "",
        suffix: model.suffix || "",
      };
    },
  };
