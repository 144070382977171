import { useRequiredContext } from "@redotech/react-util/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { RedoSecondaryNavItem } from "@redotech/redo-web/arbiter-components/navigation/secondary/redo-secondary-nav-item";
import StarIcon from "@redotech/redo-web/arbiter-icon/star-01.svg";
import StarFilledIcon from "@redotech/redo-web/arbiter-icon/star-01_filled.svg";
import { memo, useContext } from "react";
import { useMatch } from "react-router-dom";
import { ReviewsToReviewContext } from "../../../../services/marketing/reviews-to-review-service";

export const ReviewsItems = memo(function ReviewsItems({
  marketingRoot,
}: {
  marketingRoot: string;
}) {
  const team = useRequiredContext(TeamContext);
  const baseUrl = `${marketingRoot}/reviews`;

  const reviewCount = useContext(ReviewsToReviewContext);

  const reviewsEnabled = team?.settings.reviews?.enabled;

  const anyChildSelected = useMatch(`${baseUrl}/*`);

  if (!reviewsEnabled) {
    return null;
  }

  return (
    <RedoSecondaryNavItem
      action={{ type: "link", href: `${baseUrl}/dashboard` }}
      anyChildSelected={!!anyChildSelected}
      FilledIcon={StarFilledIcon}
      friendlyName="Reviews"
      id="reviews"
      notificationCount={reviewCount}
      UnfilledIcon={StarIcon}
    >
      <RedoSecondaryNavItem
        action={{ type: "link", href: `${baseUrl}/dashboard` }}
        friendlyName="Dashboard"
        id="reviews-dashboard"
      />
      <RedoSecondaryNavItem
        action={{ type: "link", href: `${baseUrl}/all-reviews` }}
        friendlyName="All reviews"
        id="reviews-all-reviews"
      />
      <RedoSecondaryNavItem
        action={{ type: "link", href: `${baseUrl}/products` }}
        friendlyName="Products"
        id="reviews-products"
      />
    </RedoSecondaryNavItem>
  );
});
