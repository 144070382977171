// extracted by mini-css-extract-plugin
export var baseCustomerActivityCardArrowRight = "_bl-f";
export var baseCustomerActivityCardArrowRightContainer = "_bl-e";
export var baseCustomerActivityCardContainer = "_bl-c";
export var baseCustomerActivityCardContainerHovered = "_bl-d";
export var conversationActivityDataConversationIconContainer = "_bl-5";
export var customerActivityDataClaimIcon = "_bl-8";
export var customerActivityDataClaimIconContainer = "_bl-7";
export var customerActivityDataConversationIcon = "_bl-6";
export var customerActivityDataConversationIconContainer = "_bl-4";
export var customerActivityDataOrderIcon = "_bl-1";
export var customerActivityDataOrderIconContainer = "_bl-0";
export var customerActivityDataReturnIcon = "_bl-3";
export var customerActivityDataReturnIconContainer = "_bl-2";
export var customerActivityShoppingIconContainer = "_bl-9";
export var messageBubble = "_bl-a";
export var messageBubbleText = "_bl-b";