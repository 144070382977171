import * as classNames from "classnames";
import { memo } from "react";
import { useMatch } from "react-router-dom";
import { Flex } from "../../../flex";
import { Tooltip } from "../../../tooltip/tooltip";
import { NavIconSwap } from "../common/redo-nav-icon-swap";
import * as navigationCss from "../redo-navigation.module.css";
import { PrimaryTab } from "./redo-primary-nav";

export interface PrimaryNavigationTabProps {
  tab: PrimaryTab;
  manuallySelected?: boolean;
}

export const PrimaryNavigationTab = memo(function PrimaryNavigationTab({
  tab,
  manuallySelected,
}: PrimaryNavigationTabProps) {
  const { UnfilledIcon, FilledIcon, hasNotification, type } = tab;

  const href = type === "link" ? tab.pathRoot : "";

  const match = useMatch({ path: `${href}/*`, caseSensitive: true });

  const isSelected = (!!href && !!match) || !!manuallySelected;

  const as = type === "link" ? "a" : "button";

  return (
    <Tooltip darkMode placement="right" title={tab.friendlyName}>
      <Flex
        align="center"
        as={as}
        className={classNames(
          navigationCss.primaryTab,
          isSelected && navigationCss.selected,
        )}
        justify="center"
        linkProps={tab.type === "link" ? { to: tab.href } : undefined}
        onClick={tab.type === "button" ? tab.onClick : undefined}
      >
        {hasNotification && <span className={navigationCss.notificationDot} />}
        <NavIconSwap
          FilledIcon={FilledIcon}
          isSelected={isSelected}
          size={20}
          UnfilledIcon={UnfilledIcon}
        />
      </Flex>
    </Tooltip>
  );
});
