import {
  shopifyAdminOrderUrl,
  shopifyStoreName,
} from "@redotech/redo-merchant-app-common/shopify";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { getNumNonRedoItemsInOrders, Order } from "@redotech/redo-model/order";
import { MerchantAppReturn, Return } from "@redotech/redo-model/return";
import { THIRD_PARTY_SOURCE } from "@redotech/redo-model/third-party";
import {
  RedoBadge,
  RedoBadgeColor,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import { RedoExternalLink } from "@redotech/redo-web/arbiter-components/links/redo-external-link";
import { CURRENCY_FORMAT } from "@redotech/redo-web/currency";
import { getDateString } from "@redotech/redo-web/date-utils";
import { Flex } from "@redotech/redo-web/flex";
import { Link } from "@redotech/redo-web/link";
import { SkeletonText } from "@redotech/redo-web/skeleton";
import { Text } from "@redotech/redo-web/text";
import * as sortBy from "lodash/sortBy";
import { memo, useContext } from "react";
import { returnStatusName, returnStatusToBadgeColor } from "../../util";
import { shippingStatusToBadgeColor } from "../shipping-status-badge";
import * as customerHistoryTabCss from "./customer-history-tab.module.css";
export const CustomerHistoryTab = memo(function CustomerHistoryTab({
  return: return_,
}: {
  return: MerchantAppReturn;
}) {
  return (
    <Flex className={customerHistoryTabCss.cardPadding} dir="column" gap="lg">
      {return_?.customerOrders ? (
        <>
          {sortBy(
            return_.customerReturns!.filter(
              (return_) => return_.type === "claim",
            ),
            (return_) => return_.createdAt,
          ).map((return_, index) => (
            <CustomerHistoryReturn key={`claim-${index}`} return={return_} />
          ))}
          {sortBy(
            return_.customerReturns!.filter(
              (return_) => return_.type === "return",
            ),
            (return_) => return_.createdAt,
          ).map((return_, index) => (
            <CustomerHistoryReturn key={`return-${index}`} return={return_} />
          ))}
          {sortBy(
            return_.customerOrders,
            (order) => order.shopifyCreatedAt,
          ).map((order, index) => {
            if (order.shopify.source_name === THIRD_PARTY_SOURCE) {
              return;
            }
            return <CustomerHistoryOrder key={index} order={order} />;
          })}
        </>
      ) : (
        <SkeletonText length={30} />
      )}
    </Flex>
  );
});

const CustomerHistoryOrder = memo(function CustomerHistoryOrder({
  order,
}: {
  order: Order;
}) {
  const team = useContext(TeamContext);
  if (!team) {
    return null;
  }
  return (
    <Flex className={customerHistoryTabCss.itemContainer} dir="column" gap="lg">
      <Flex dir="column" gap="xs">
        {team.platform === "shopify" ? (
          <RedoExternalLink
            fontSize="xs"
            text={`Order ${order.shopify.name}`}
            url={shopifyAdminOrderUrl(
              shopifyStoreName(team!.storeUrl),
              order.shopify_id,
            ).toString()}
          />
        ) : (
          <Text fontSize="xs" fontWeight="regular">
            Order #{order.shopify.name}
          </Text>
        )}
        <Flex dir="row" gap="xs">
          {order.shopify.fulfillment_status && (
            <RedoBadge
              color={orderFulfillmentStatusToBadgeColor(
                order.shopify.fulfillment_status,
              )}
              size="sm"
              text={order.shopify.fulfillment_status}
            />
          )}
          {order.trackers[0]?._tracker.status && (
            <RedoBadge
              color={shippingStatusToBadgeColor(
                order.trackers[0]?._tracker.status,
              )}
              size="sm"
              text={order.trackers[0]?._tracker.status}
            />
          )}
        </Flex>
      </Flex>
      <Flex dir="row" justify="space-between">
        <Flex dir="column" gap="none">
          <Text fontSize="xs" fontWeight="regular" textColor="tertiary">
            Created On
          </Text>
          <Text fontSize="xs" fontWeight="regular">
            {getDateString(new Date(order.shopifyCreatedAt))}
          </Text>
        </Flex>
        <Flex dir="column" gap="none">
          <Text fontSize="xs" fontWeight="regular" textColor="tertiary">
            Total
          </Text>
          <Text fontSize="xs" fontWeight="regular">
            {CURRENCY_FORMAT(order.shopify.currency).format(
              +order.shopify.total_price,
            )}
          </Text>
        </Flex>
      </Flex>
      <Flex dir="column" gap="none">
        <Text fontSize="xs" fontWeight="regular" textColor="tertiary">
          Total number of items
        </Text>
        <Text fontSize="xs" fontWeight="regular">
          {getNumNonRedoItemsInOrders([order])}
        </Text>
      </Flex>
    </Flex>
  );
});

function orderFulfillmentStatusToBadgeColor(status: string): RedoBadgeColor {
  switch (status) {
    case "fulfilled":
      return "success";
    case "restocked":
      return "blue";
    case "partial":
      return "whiteSuccess";
    case "not_eligible":
    default:
      return "error";
  }
}

const CustomerHistoryReturn = memo(function CustomerHistoryReturn({
  return: return_,
}: {
  return: Return;
}) {
  const team = useContext(TeamContext);
  if (!team) {
    return null;
  }
  return (
    <Flex className={customerHistoryTabCss.itemContainer} dir="column" gap="xl">
      <Flex dir="column" gap="md">
        <Link
          to={`/stores/${team._id}/order-management/returns/${return_._id}`}
        >
          <Text fontSize="xs" fontWeight="regular">
            {
              {
                claim: "Claim",
                return: "Return",
                warranty: "Warranty",
                managed_claim: "Claim",
              }[return_.type]
            }{" "}
            {return_.orders[0].name}
          </Text>
        </Link>
        <Flex dir="row" gap="xs">
          <RedoBadge
            color={returnStatusToBadgeColor[return_.status]}
            size="sm"
            text={returnStatusName(return_.status)}
          />
          {return_.trackers?.[0]?._tracker.status && (
            <RedoBadge
              color={shippingStatusToBadgeColor(
                return_.trackers?.[0]?._tracker.status,
              )}
              size="sm"
              text={return_.trackers?.[0]?._tracker.status}
            />
          )}
        </Flex>
      </Flex>
      <Flex dir="row" justify="space-between">
        <Flex dir="column" gap="none">
          <Text fontSize="xs" fontWeight="regular" textColor="tertiary">
            Created On
          </Text>
          <Text fontSize="xs" fontWeight="regular">
            {getDateString(new Date(return_.createdAt))}
          </Text>
        </Flex>
      </Flex>
      <Flex dir="column" gap="none">
        <Text fontSize="xs" fontWeight="regular" textColor="tertiary">
          Total number of items
        </Text>
        <Text fontSize="xs" fontWeight="regular">
          {return_.products.length}
        </Text>
      </Flex>
    </Flex>
  );
});
