import { memo } from "react";
import * as progressBarCss from "./progress-bar.module.css";

/**
 * @deprecated use RedoProgressBar instead
 */
export const ProgressBar = memo(function ProgressBar({
  value,
}: {
  value: number;
}) {
  return (
    <div className={progressBarCss.progressBar}>
      <div
        className={progressBarCss.progressBarInner}
        style={{ width: `${value}%` }}
      />
    </div>
  );
});
