import { Alert, Snackbar } from "@mui/material";
import { useRequiredContext } from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { useHandler } from "@redotech/react-util/hook";
import { createAuth, WidgetClient } from "@redotech/redo-api-client/widget";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { RedoMerchantRpcClientContext } from "@redotech/redo-merchant-app-common/rpc-client";
import { UserContext } from "@redotech/redo-merchant-app-common/user";
import { REDO_RETURNS_APP_URL_LOCAL } from "@redotech/redo-model/customer-portal";
import { Order, Provider } from "@redotech/redo-model/order";
import { VariantInfo } from "@redotech/redo-model/product";
import {
  claimIsBeingAutoprocessed,
  getReturner,
  InvoiceStatus,
  isGiftReturn,
  Product,
  RejectTypes,
  RETURN_TYPE_ROUTE,
  ReturnTypeEnum,
  type MerchantAppReturn,
  type Return,
} from "@redotech/redo-model/return";
import { ProvisionType } from "@redotech/redo-model/return-flow";
import {
  isClosedReturnStatus,
  ReturnStatus,
} from "@redotech/redo-model/return-status";
import {
  ProductTotals,
  ReturnTotals,
} from "@redotech/redo-model/return-totals-calculator";
import { canRefundInstantExchangeRecoveryCharge } from "@redotech/redo-model/returns/exchange-recovery";
import { Channel, SalesChannels } from "@redotech/redo-model/sales-channels";
import { Team } from "@redotech/redo-model/team";
import { Permission, permitted } from "@redotech/redo-model/user";
import { alertOnFailure } from "@redotech/redo-web/alert";
import { RedoBadge } from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoButtonTabGroup } from "@redotech/redo-web/arbiter-components/checkbox/redo-button-tab-group";
import { RedoCheckbox } from "@redotech/redo-web/arbiter-components/checkbox/redo-checkbox";
import { RedoCommandMenuItem } from "@redotech/redo-web/arbiter-components/command-menu/redo-command-menu";
import { RedoModal } from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import ChargeIcon from "@redotech/redo-web/arbiter-icon/bank-note-03.svg";
import ApproveIcon from "@redotech/redo-web/arbiter-icon/check-circle.svg";
import RefundIcon from "@redotech/redo-web/arbiter-icon/credit-card-upload.svg";
import RejectIcon from "@redotech/redo-web/arbiter-icon/file-x-01.svg";
import MailIcon from "@redotech/redo-web/arbiter-icon/mail-01.svg";
import PlusIcon from "@redotech/redo-web/arbiter-icon/plus.svg";
import RestockIcon from "@redotech/redo-web/arbiter-icon/reverse-left_filled.svg";
import SlashCircleIcon from "@redotech/redo-web/arbiter-icon/slash-circle-01.svg";
import ReOpenIcon from "@redotech/redo-web/arbiter-icon/user-01.svg";
import XCloseIcon from "@redotech/redo-web/arbiter-icon/x-close.svg";
import { Card } from "@redotech/redo-web/card";
import * as cardCss from "@redotech/redo-web/card.module.css";
import { RedoClientContext } from "@redotech/redo-web/client";
import { TeamContext } from "@redotech/redo-web/context/team-context";
import { Flex } from "@redotech/redo-web/flex";
import FlagIcon from "@redotech/redo-web/icon-old/flag.svg";
import RefreshCwIcon from "@redotech/redo-web/icon-old/refresh-cw-02.svg";
import { LabelTheme } from "@redotech/redo-web/labeled-input";
import { ExternalLink } from "@redotech/redo-web/link";
import { SkeletonText } from "@redotech/redo-web/skeleton";
import { Text } from "@redotech/redo-web/text";
import { FormTextInput } from "@redotech/redo-web/text-input";
import { formatDateTime } from "@redotech/redo-web/time";
import { groupInput, input } from "@redotech/ui/form";
import { newWindow } from "@redotech/web-util/window";
import * as classNames from "classnames";
import { memo, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  reopenReturn,
  resendInvoiceEmail,
  updateFiledWithCarrier,
} from "../../client/return";
import { ProductSelectModal } from "../../support/product-select-modal";
import { shopifyAddressString } from "../return";
import { AiReview } from "../return-detail-cards/ai-review";
import { ApproveModal } from "../return-modals/approve-modal";
import { ChangeNewOrderAddressModal } from "../return-modals/change-new-order-address-modal";
import { ChangeReturnTypeModal } from "../return-modals/change-return-type-modal";
import { ChargeRecoveryModal } from "../return-modals/charge-recovery-modal";
import { CloseModal } from "../return-modals/close-modal";
import {
  getDefaultItemsToRestock,
  getPriceDetailsForProduct,
  ItemRestockState,
} from "../return-modals/common/util";
import { DenyModal } from "../return-modals/deny-modal";
import { DropshipConfirmationModal } from "../return-modals/dropship-confirmation-modal";
import { EditItemModal } from "../return-modals/edit-item-modal";
import { EditSingleItemDispositionModal } from "../return-modals/edit-single-item-disposition-modal";
import { FlagModal } from "../return-modals/flag-modal";
import { ItemDispositionModal } from "../return-modals/item-disposition-modal";
import { ProcessModal } from "../return-modals/process-modal";
import { RefundRecoveryChargeModal } from "../return-modals/refund-recovery-charge-modal";
import { RejectModal } from "../return-modals/reject-modal";
import {
  DO_NOT_SHOW_REOPEN_PROCESSED_MODAL_KEY,
  ReopenModal,
} from "../return-modals/reopen-modal";
import { ResetModal } from "../return-modals/reset-modal";
import { RestockItemsModal } from "../return-modals/restock-items-modal";
import {
  getProductsByShippingGroupTitle,
  isExchangeOrderEditable,
  isVariantExchange,
  ItemDispositionModalOpen,
  returnKindNameReturn,
  returnStatusName,
  returnStatusToBadgeColor,
  returnTypeName,
} from "../util";
import { ActionsButton } from "./actions-button";
import * as returnCss from "./details-card.module.css";
import { MultipleOrdersName, OrderName } from "./order-name";
import { OrderSubtitle } from "./order-subtitle";
import { Item } from "./return-item";
import { NewReturnItem } from "./return-new-item";
import {
  NewItemsSkeleton,
  OrderTitleSkeleton,
  ReturnItemsSkeleton,
} from "./skeletons";

export const DetailCardNew = memo(function DetailCard({
  return: return_,
  orders,
  pending,
  returnTotals,
  reload,
  unsavedChanges,
}: {
  return?: MerchantAppReturn;
  orders?: Order[];
  pending: boolean;
  returnTotals?: ReturnTotals;
  reload(): void;
  unsavedChanges: boolean;
}) {
  const team = useContext(TeamContext);
  const user = useContext(UserContext);
  const client = useRequiredContext(RedoMerchantClientContext);
  const redoClient = useRequiredContext(RedoClientContext);
  const rpcClient = useRequiredContext(RedoMerchantRpcClientContext);

  const defaultItemExpanded = useMemo(() => {
    return team?.settings.returnsUI?.defaultExpanded ?? false;
  }, [team]);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [filedWithCarrierPending, setFiledWithCarrierPending] =
    useState<boolean>(false);
  const [isFiledWithCarrier, setIsFiledWithCarrier] = useState<boolean>(
    !!return_?.filedWithCarrier,
  );
  const claimIsManaged =
    !!return_ &&
    claimIsBeingAutoprocessed({
      returnType: return_.type,
      packageProtectionPlusEnabled:
        !!team?.settings?.packageProtection?.packageProtectionPlusEnabled,
      reportedClaims: return_.reportedClaims ?? [],
    });
  const hasEligibleShippingStatus =
    !!return_ &&
    !claimIsManaged &&
    [ReturnStatus.DELIVERED, ReturnStatus.IN_TRANSIT].includes(return_.status);
  const giftReturn = return_ && isGiftReturn(return_);
  const canManageReturn =
    !!user && permitted(user.permissions, Permission.MANAGE_RETURN);
  const canReject =
    !!return_ &&
    !isClosedReturnStatus(return_.status) &&
    (!claimIsManaged ||
      [ReturnStatus.OPEN, ReturnStatus.NEEDS_REVIEW].includes(return_.status));
  const canFlag =
    !!return_ &&
    (hasEligibleShippingStatus || return_.status === ReturnStatus.OPEN);
  const canUnflag = return_?.status === ReturnStatus.FLAGGED;
  const canProcess =
    !!return_ &&
    (hasEligibleShippingStatus ||
      [ReturnStatus.OPEN, ReturnStatus.FLAGGED].includes(return_.status));
  const canDisposition =
    team?.settings.returnItemDisposition.enabled &&
    !!return_ &&
    return_.status === ReturnStatus.COMPLETE &&
    !return_.allProductsDispositionCompleted;
  const canApprove = return_?.status === ReturnStatus.NEEDS_REVIEW;
  const canDeny = canApprove;

  /**
   * Although we support multiple orders in a return, we assume all orders are
   * from the same provider.
   */
  const orderProvider = useMemo(() => {
    return orders?.[0]?.provider;
  }, [orders]);

  const canRestockBeforeProcessing =
    team &&
    team.settings.inventory.allowRestockBeforeProcess &&
    !!return_ &&
    ![ReturnStatus.DELETED].includes(return_.status) &&
    orderProvider &&
    Object.values(getDefaultItemsToRestock(return_, team, orderProvider)).some(
      (item) => item.itemRestockState === ItemRestockState.ABLE_TO_RESTOCK,
    );

  const canReset =
    !!return_ &&
    ![ReturnStatus.COMPLETE, ReturnStatus.DELETED].includes(return_.status) &&
    (!claimIsManaged || return_.status === ReturnStatus.NEEDS_REVIEW);
  const canReapprove =
    return_ &&
    ![ReturnStatus.COMPLETE, ReturnStatus.DELETED].includes(return_.status) &&
    return_?.rejectType === RejectTypes.MANUAL_REVIEW;

  // Similar to Reset, this has the potential to allow merchants to resubmit a claim that's been processed by our insurance provider.
  // We want to prevent it in this case because some actions are irreversible.
  const canReopenRejected =
    return_ &&
    !claimIsManaged &&
    return_.status === ReturnStatus.REJECTED &&
    return_.rejectType !== RejectTypes.RETURN_PORTAL &&
    !canReapprove &&
    !canApprove;

  const canClose = return_ && !isClosedReturnStatus(return_.status);

  const canReopenProcessed =
    !!return_ &&
    return_.status === ReturnStatus.COMPLETE &&
    !!team?.settings.returns?.allowReopenAfterProcessing;

  const containsDropshippedItem = !!return_?.products?.some(
    (product) => !!product?.dropshipSupplierName,
  );
  const EmailInput = useInput(groupInput({ email: input<string>() }), {
    email: (return_ && getReturner(return_).email) || "",
  });

  const canChargeRecovery = useMemo(() => {
    if (!return_) {
      return false;
    }

    const recoveryCharge = return_.paymentIntents?.find(
      (paymentIntent) => paymentIntent.type === "recovery",
    );

    return return_.provision === ProvisionType.INSTANT && !recoveryCharge;
  }, [return_]);

  const canRefund = useMemo(() => {
    if (!return_) {
      return false;
    }

    return canRefundInstantExchangeRecoveryCharge({
      paymentIntents: return_.paymentIntents || [],
      provision: return_.provision,
    });
  }, [return_]);

  const shopifySourceName =
    return_?.products[0]?.order?.shopify?.source_name || null;
  const salesChannelName = SalesChannels.find((channel: Channel) => {
    let found = false;
    if (channel.id === "draftOrder") {
      // showing draft order as a sales channel is confusing, so we don't show it
      return false;
    }
    channel.sourceNames.forEach((source: string) => {
      // Using starts with because some shopify source names include an order # that we want to leave out.
      if (shopifySourceName?.startsWith(source)) {
        found = true;
      }
    });
    return found;
  })?.displayName;

  const unpaidInvoice =
    (return_?.sentInvoice || return_?.draftOrderURL) &&
    !return_.exchangeOrder.length &&
    return_.invoiceStatus !== InvoiceStatus.COMPLETE;

  const [cancelOpen, setCancelOpen] = useState(false);
  const handleCancel = useHandler(() => {
    setCancelOpen(true);
  });
  const handleCancelClose = useHandler((refresh: boolean = false) => {
    setCancelOpen(false);
    if (refresh) {
      window.location.reload();
    }
  });

  const [chargeRecoveryOpen, setRecoveryChargeOpen] = useState(false);
  const handleRecoveryCharge = useHandler(() => {
    setRecoveryChargeOpen(true);
  });
  const handleRecoveryChargeClose = useHandler((refresh: boolean = false) => {
    setRecoveryChargeOpen(false);
    if (refresh) {
      window.location.reload();
    }
  });

  const [restockItemsModalOpen, setRestockItemsModalOpen] = useState(false);
  const handleRestockBeforeProcessing = useHandler(() => {
    setRestockItemsModalOpen(true);
  });

  const [itemBeingEdited, setItemBeingEdited] = useState<any>(); // TODO type

  // This can be used for actions that aren't done from a modal, for example,
  // if "Don't show again" has been checked in that modal and the action is taken.
  // So far, only reopening a return uses this.
  // const [actionPending, setActionPending] = useState(false);

  const [processOpen, setProcessOpen] = useState(false);
  const handleProcess = useHandler(() => {
    setProcessOpen(true);
  });
  const handleProcessClose = useHandler((refresh: boolean = false) => {
    if (!isProcessing) {
      setProcessOpen(false);
      if (refresh) {
        window.location.reload();
      }
    }
    setItemBeingEdited(undefined);
  });
  const [editItemModalOpen, setEditItemModalOpen] = useState(false);
  const [
    editSingleItemDispositionModalOpen,
    setEditSingleItemDispositionModalOpen,
  ] = useState<ItemDispositionModalOpen>({ open: false });
  const [itemDispositionModalOpen, setItemDispositionModalOpen] =
    useState(false);
  const handleEditItemModalClose = useHandler((refresh: boolean = false) => {
    if (refresh) {
      window.location.reload();
    }
    setEditItemModalOpen(false);
  });

  const [resendInvoiceModalOpen, setResendInvoiceModalOpen] = useState(false);

  const [dropshipConfirmModalOpen, setDropshipConfirmModalOpen] =
    useState(false);
  const [approveOpen, setApproveOpen] = useState(false);

  const handleApprove = useHandler(() => {
    setDropshipConfirmModalOpen(containsDropshippedItem);
    setApproveOpen(true);
  });
  const handleApproveClose = useHandler(() => {
    setApproveOpen(false);
  });

  const handleDropshipApprove = () => {
    setDropshipConfirmModalOpen(false);
  };

  const handleDropshipClose = () => {
    setApproveOpen(false);
    setDropshipConfirmModalOpen(false);
  };

  const [reapproveOpen, setReapproveOpen] = useState(false);
  const handleReapprove = useHandler(() => {
    setReapproveOpen(true);
  });
  const handleReapproveClose = useHandler(() => {
    setReapproveOpen(false);
  });

  const navigate = useNavigate();

  const [resetOpen, setResetOpen] = useState(false);
  const handleReset = useHandler(() => {
    setResetOpen(true);
  });
  const onResetSubmitFinished = useHandler(() => {
    navigate(`/${RETURN_TYPE_ROUTE[return_?.type || ReturnTypeEnum.RETURN]}`);
  });
  const handleResetClose = useHandler(() => {
    setResetOpen(false);
  });

  const [reopenOpen, setReopenOpen] = useState(false);
  const handleReopen = useHandler(async () => {
    if (
      localStorage.getItem(DO_NOT_SHOW_REOPEN_PROCESSED_MODAL_KEY) === "true" &&
      return_
    ) {
      // setActionPending(true);
      await reopenReturn(client, { returnId: return_.id });
      reload();
      // setActionPending(false);
      location.reload();
      return;
    }
    setReopenOpen(true);
  });
  const handleReopenClose = useHandler(() => {
    setReopenOpen(false);
  });

  const [closeOpen, setCloseOpen] = useState(false);
  const handleClose = useHandler(() => {
    setCloseOpen(true);
  });
  const handleCloseModalClose = useHandler(() => {
    setCloseOpen(false);
  });

  const [refundOpen, setRefundOpen] = useState(false);
  const handleRefund = useHandler(() => {
    setRefundOpen(true);
  });
  const handleRefundClose = useHandler(() => {
    setRefundOpen(false);
  });

  const [denyOpen, setDenyOpen] = useState(false);
  const handleDeny = useHandler(() => {
    setDenyOpen(true);
  });
  const handleDenyClose = useHandler(() => {
    setDenyOpen(false);
  });

  const [productToUpdate, setProductToUpdate] =
    useState<Return["products"][0]>();
  const [newType, setNewType] = useState<string>("");
  const [returnTypeModalOpen, setReturnTypeModalOpen] = useState(false);
  const handleChangeReturnType = (
    newType: string,
    product: Return["products"][0],
  ) => {
    setProductToUpdate(product);
    setNewType(newType);
    setReturnTypeModalOpen(true);
  };
  const handleChangeReturnTypeClose = (refresh: boolean = false) => {
    setReturnTypeModalOpen(false);
    if (refresh) {
      window.location.reload();
    }
  };

  const [flagModalOpen, setFlagModalOpen] = useState(false);
  const handleFlagModalOpen = useHandler(() => {
    setFlagModalOpen(true);
  });
  const handleFlagModalClose = useHandler(() => {
    setFlagModalOpen(false);
  });

  const [unflagModalOpen, setUnflagModalOpen] = useState(false);
  const [editNewItemsModalOpen, setEditNewItemsModalOpen] = useState(false);

  const handleUnflagModalOpen = useHandler(() => {
    setUnflagModalOpen(true);
  });
  const handleUnflagModalClose = useHandler(() => {
    setUnflagModalOpen(false);
  });
  // const order = return_?.products[0]?.order || null;
  const discountCodes = orders
    ? orders.flatMap((order) =>
        order.shopify.discount_codes.map((code: { code: string }) => code.code),
      )
    : [];

  const [changeNewOrderAddressModalOpen, setChangeNewOrderAddressModalOpen] =
    useState(false);
  const handleChangeNewOrderAddressModalOpen = useHandler(() => {
    setChangeNewOrderAddressModalOpen(true);
  });
  const handleChangeNewOrderAddressModalClose = useHandler(() => {
    setChangeNewOrderAddressModalOpen(false);
  });

  const [resentInvoiceSuccessMessageOpen, setResentInvoiceSuccessMessageOpen] =
    useState(false);
  const handleResendDraftEmail = useHandler(async () => {
    if (return_) {
      await alertOnFailure(
        "Email failed to send. Check the email address and try again",
        true,
      )(async () => {
        await resendInvoiceEmail(client, {
          returnId: return_._id,
          emailOverride: EmailInput.value.email,
        });
        setResentInvoiceSuccessMessageOpen(true);
      });
    }
  });
  const handleResentInvoiceSuccessMessageClose = useHandler(() => {
    setResentInvoiceSuccessMessageOpen(false);
  });

  const [viewingOriginal, setViewingOriginal] = useState(false);

  const exchangeOrderModified =
    return_ &&
    (return_.products.some(
      (product) =>
        product.originalVariantOptions?.exchangeGroupItem ||
        product.originalVariantOptions?.exchange_for,
    ) ||
      return_.originalReturnOptions?.advancedExchangeItems);

  const newItemsFromVariantExchange = return_?.products
    .map((product) => {
      return {
        ...product,
        exchangeGroupItem: viewingOriginal
          ? product.originalVariantOptions?.exchangeGroupItem
          : product.exchangeGroupItem,
        exchange_for: viewingOriginal
          ? product.originalVariantOptions?.exchange_for
          : product.exchange_for,
        outOfStockDuringProcessing: product.outOfStockDuringProcessing,
      };
    })
    .filter((product) => isVariantExchange(product));

  const newItemsFromAdvancedExchange = viewingOriginal
    ? return_?.originalReturnOptions?.advancedExchangeItems
    : return_?.advancedExchangeItems;

  const numNewItems = () =>
    (newItemsFromVariantExchange?.length || 0) +
    (newItemsFromAdvancedExchange?.length || 0);

  const handleFiledWithCarrierChange = async (markFiled: boolean) => {
    if (!return_) {
      return;
    }
    setFiledWithCarrierPending(true);

    await updateFiledWithCarrier(client, {
      returnId: return_._id,
      filedWithCarrier: markFiled,
    });
    reload();

    setFiledWithCarrierPending(false);
    setIsFiledWithCarrier(markFiled);
  };

  const moreActionsDropdownOptions: RedoCommandMenuItem[] = [
    {
      show: canReject,
      Icon: SlashCircleIcon,
      text: "Reject",
      onClick: handleCancel,
    },
    {
      show: canFlag,
      Icon: FlagIcon,
      text: "Flag",
      onClick: handleFlagModalOpen,
    },
    {
      show: canUnflag,
      Icon: FlagIcon,
      text: "Unflag",
      onClick: handleUnflagModalOpen,
    },
    {
      show: canRestockBeforeProcessing || false,
      Icon: RestockIcon,
      text: "Restock",
      onClick: handleRestockBeforeProcessing,
    },
    { show: canDeny, Icon: RejectIcon, text: "Deny", onClick: handleDeny },
    {
      show: canReset,
      Icon: RefreshCwIcon,
      text: "Reset",
      onClick: handleReset,
    },
    {
      show: canReapprove || false,
      Icon: ApproveIcon,
      text: "Approve",
      onClick: handleReapprove,
    },
    {
      show: canReopenRejected || canReopenProcessed || false,
      Icon: ReOpenIcon,
      text: "Re-open",
      onClick: handleReopen,
    },
    {
      show: unpaidInvoice || false,
      Icon: MailIcon,
      text: "Resend invoice",
      onClick: () => {
        setResendInvoiceModalOpen(true);
        if (return_) {
          EmailInput.setValue({ email: getReturner(return_).email || "" });
        }
      },
    },
    {
      show: canClose || false,
      Icon: XCloseIcon,
      text: "Close",
      onClick: handleClose,
    },
    {
      show: canChargeRecovery,
      Icon: ChargeIcon,
      text: "Charge",
      onClick: handleRecoveryCharge,
    },
    {
      show: canRefund,
      Icon: RefundIcon,
      text: "Refund",
      onClick: handleRefund,
    },
  ];

  const hasMultipleShipments =
    return_?.shipmentGroups?.length && return_.shipmentGroups.length > 1;

  let productsByGroupName: { groupName: string; products: Product[] }[] = [];
  if (hasMultipleShipments && return_) {
    productsByGroupName = getProductsByShippingGroupTitle(return_);
  }

  const openCustomerPortal = useHandler((event: React.MouseEvent) => {
    if (!team || !return_ || !orders || !orders[0]) {
      return;
    }

    const widgetClient = new WidgetClient(redoClient, team.widget_slug);
    newWindow(async () => {
      event.stopPropagation();

      let auth:
        | {
            token: string;
            customer: {
              email: string;
              name: string;
              customerId: string | undefined;
            };
          }
        | undefined;
      if (team.settings.returns?.newLogin) {
        const { token, customerId } = await rpcClient.createCustomerAuthToken({
          email: orders[0].shopify.email,
        });
        auth = {
          token,
          customer: {
            email: orders[0].shopify.email,
            name: orders[0].shopify.name,
            customerId,
          },
        };
      } else {
        auth = await createAuth(widgetClient, {
          orderNumber: orders[0].shopify.name,
          email: orders[0].shopify.email,
          type: "order",
        });
      }

      const domain = team.portal.domain || team.storeUrl;
      const path = team.portal.pathPrefix || "/apps/redo";

      const url =
        process.env.NODE_ENV === "production"
          ? new URL(`https://${domain}${path}/returns-portal`)
          : new URL(
              `${REDO_RETURNS_APP_URL_LOCAL}/widget_id/${team.widget_slug}/returns-portal`,
            );
      const address = {
        street1: orders[0].shopify.shipping_address?.address1,
        street2: orders[0].shopify.shipping_address?.address2 || "",
        city: orders[0].shopify.shipping_address?.city,
        state: orders[0].shopify.shipping_address?.province,
        country: orders[0].shopify.shipping_address?.country_code,
        zip: orders[0].shopify.shipping_address?.zip,
      };
      url.searchParams.set(
        "customer",
        JSON.stringify({ ...auth.customer, address: JSON.stringify(address) }),
      );
      url.searchParams.set("token", auth.token);
      url.searchParams.set(
        "next",
        `/returns-portal/orders/return/${return_._id}`,
      );
      return String(url);
    });
  });

  const canRemoveExchangeItems =
    !!return_ &&
    canManageReturn &&
    !viewingOriginal &&
    isExchangeOrderEditable(return_, team, user);

  const handleRemoveExchangeItem = useHandler(async (variantId: string) => {
    if (!team || !return_) {
      return false;
    }
    const items = return_.advancedExchangeItems.map((item) => ({
      quantity: item.quantity || 1,
      variantId: item.variantId || "",
    }));
    // Remove first occurrence of item with matching variantId
    const itemIndex = items.findIndex((item) => item.variantId === variantId);
    if (itemIndex === -1) {
      return false;
    }
    items.splice(itemIndex, 1);
    await rpcClient.updateNewOrderItems({
      teamId: team._id,
      returnId: return_._id,
      items,
    });
    return true;
  });

  const handleAddExchangeItems = useHandler(async (newVariantIds: string[]) => {
    if (!team || !return_) {
      return;
    }
    const items = return_.advancedExchangeItems.map((item) => ({
      quantity: item.quantity || 1,
      variantId: item.variantId || "",
    }));
    newVariantIds.forEach((variantId) => {
      items.push({ quantity: 1, variantId });
    });
    await rpcClient.updateNewOrderItems({
      teamId: team._id,
      returnId: return_._id,
      items,
    });
    reload();
  });

  const returnProductBeingEdited = useMemo(() => {
    if (itemBeingEdited) {
      return return_?.products.find((item) => {
        return item._id === itemBeingEdited._id;
      });
    }
    return undefined;
  }, [return_, itemBeingEdited]);

  const returnItemsToPrice = useMemo(() => {
    if (return_ && returnTotals) {
      const returnItemsToPrice: Record<string, number> = {};
      for (const product of return_.products) {
        const { price } = getPriceDetailsForProduct(product, returnTotals);
        returnItemsToPrice[product._id] = price;
      }
      return returnItemsToPrice;
    }
    return undefined;
  }, [return_, returnTotals]);

  const hasActions = moreActionsDropdownOptions.some((option) => option.show);

  const willShowAddNewItemsButton =
    canManageReturn &&
    return_ &&
    team?.platform === "shopify" &&
    isExchangeOrderEditable(return_, team, user);

  const hasNewItemsToShow = numNewItems() > 0;

  const showNewItems = (return_: Return, team?: Team) => {
    return (
      return_.products.some(isVariantExchange) ||
      numNewItems() ||
      isExchangeOrderEditable(return_, team, user)
    );
  };

  const orderProductsSplit = useMemo(() => {
    const orderMap = new Map<
      string,
      { orderName: string; products: Product[] }
    >();

    orders?.forEach((order) => {
      orderMap.set(order._id.toString(), {
        orderName: order.shopify.name,
        products: [],
      });
    });

    return_?.products.forEach((product) => {
      const orderId = product.order._id;
      orderMap.get(orderId)?.products.push(product);
    });

    return Array.from(orderMap.values());
  }, [return_, orders]);

  const [viewTab, setViewTab] = useState("actual");
  return (
    <>
      {return_ && orders ? (
        <div className={returnCss.headerLayout}>
          {pending ? (
            <OrderTitleSkeleton />
          ) : (
            <>
              <div className={returnCss.topRow}>
                <div className={returnCss.orderAndTags}>
                  <div className={returnCss.orderNames}>
                    {orders.length > 1 ? (
                      <MultipleOrdersName orders={orders} team={return_.team} />
                    ) : (
                      <OrderName order={orders[0]} return_={return_} />
                    )}
                  </div>
                  <div className={returnCss.tags}>
                    <RedoBadge
                      color={returnStatusToBadgeColor[return_.status]}
                      size="sm"
                      text={returnStatusName(return_.status)}
                    />
                    {salesChannelName && (
                      <RedoBadge size="sm" text={salesChannelName} />
                    )}
                    <RedoBadge
                      size="sm"
                      text={returnKindNameReturn(return_, false)}
                    />
                    {((return_?.type === "claim" &&
                      orders.some((order) => order?.packageProtected)) ||
                      (return_?.type === "return" &&
                        orders.some((order) => order?.protected))) && (
                      <RedoBadge size="sm" text="Protected" />
                    )}
                    {return_.inStoreReturn && (
                      <RedoBadge size="sm" text="In-Store Return" />
                    )}
                    {giftReturn && (
                      <RedoBadge color="warning" size="sm" text="Gift" />
                    )}
                  </div>
                </div>
                {canManageReturn && (
                  <div className={returnCss.buttonBar}>
                    {hasActions && (
                      <ActionsButton
                        canApprove={canApprove}
                        canProcess={canProcess}
                        disabled={pending || unsavedChanges}
                        moreActionsDropdownOptions={moreActionsDropdownOptions}
                      />
                    )}
                    {canProcess && (
                      <RedoButton
                        className={returnCss.actionButton}
                        disabled={unsavedChanges}
                        hierarchy="primary"
                        onClick={handleProcess}
                        size="sm"
                        text="Process"
                        theme="normal"
                      />
                    )}
                    {canDisposition && (
                      <RedoButton
                        className={returnCss.actionButton}
                        hierarchy="primary"
                        onClick={() => setItemDispositionModalOpen(true)}
                        pending={pending}
                        size="sm"
                        text="Disposition"
                        theme="normal"
                      />
                    )}
                    {canApprove && (
                      <RedoButton
                        className={returnCss.actionButton}
                        hierarchy="primary"
                        onClick={handleApprove}
                        size="sm"
                        text="Approve"
                        theme="normal"
                      />
                    )}
                  </div>
                )}
              </div>
              <div className={returnCss.subtitle}>
                <OrderSubtitle
                  discountCodes={discountCodes}
                  orders={orders}
                  return_={return_}
                  returnConfirmationHandler={openCustomerPortal}
                />
              </div>
              {discountCodes.length > 0 && (
                <Flex align="center">
                  <Text className={returnCss.subtitleText}>
                    Discount code{discountCodes.length > 1 ? "s" : ""}:{" "}
                  </Text>
                  <div>
                    {discountCodes.map((code, i) => (
                      <Text
                        className={classNames(
                          returnCss.headerSubtitleSmall,
                          returnCss.subtitleText,
                        )}
                        key={code}
                      >
                        {code}
                      </Text>
                    ))}
                  </div>
                </Flex>
              )}
              {!return_ ||
              return_.type !== "claim" ? null : filedWithCarrierPending ||
                pending ? (
                <SkeletonText length={24} />
              ) : (
                <Text className={returnCss.subtitleText} fontWeight="thin">
                  <RedoCheckbox
                    label={`Filed with carrier${
                      isFiledWithCarrier && return_.filedWithCarrier
                        ? ` (${formatDateTime(
                            Temporal.Instant.from(
                              return_.filedWithCarrier?.toString() || "",
                            ),
                          )})`
                        : ""
                    }`}
                    setValue={async (value) => {
                      if (typeof value === "boolean") {
                        await handleFiledWithCarrierChange(value);
                      }
                    }}
                    size="sm"
                    value={isFiledWithCarrier}
                  />
                </Text>
              )}
            </>
          )}
        </div>
      ) : (
        <>
          <Text fontSize="md" fontWeight="semibold">
            Order
          </Text>
          <SkeletonText length={8} />
        </>
      )}
      {return_ ? <AiReview return_={return_} /> : null}

      <div className={returnCss.items}>
        {!pending && (
          <Text fontSize="md" fontWeight="semibold">
            {returnTypeName(return_?.type, true)}
            {" items"}
          </Text>
        )}

        {pending ? (
          <ReturnItemsSkeleton />
        ) : hasMultipleShipments ? (
          // Multiple shipments - render each group in its own card
          productsByGroupName.map((productGroup, index) => (
            <>
              <Text fontSize="xs" fontWeight="thin">
                {productGroup.groupName}
              </Text>
              <Card className={returnCss.card} gap="md" p="md" radius="md">
                {productGroup.products.map((item, itemIndex) => (
                  <Item
                    defaultItemExpanded={defaultItemExpanded}
                    handleChangeReturnType={handleChangeReturnType}
                    itemToDisplay={item}
                    key={itemIndex}
                    order={orders?.find(
                      (order) =>
                        order._id.toString() === item.order._id.toString(),
                    )}
                    productTotals={(returnTotals?.productTotals || []).find(
                      (productTotals: ProductTotals) =>
                        productTotals.product._id === item._id,
                    )}
                    return_={return_}
                    returnLoadPending={pending}
                    setEditItemModalOpen={setEditItemModalOpen}
                    setEditSingleItemDispositionModalOpen={
                      setEditSingleItemDispositionModalOpen
                    }
                    setItemBeingEdited={setItemBeingEdited}
                  />
                ))}
              </Card>
            </>
          ))
        ) : return_ && orders && orders.length > 1 ? (
          orderProductsSplit.map((orderProducts, index) => (
            <Flex dir="column" gap="xs" key={index}>
              <Text fontSize="xs" fontWeight="thin" textColor="tertiary">
                {orderProducts.orderName}
              </Text>
              <Card className={returnCss.card} gap="md" p="md">
                {orderProducts.products.map((item, itemIndex) => (
                  <Item
                    defaultItemExpanded={defaultItemExpanded}
                    handleChangeReturnType={handleChangeReturnType}
                    itemToDisplay={item}
                    key={itemIndex}
                    order={orders?.find(
                      (order) =>
                        order._id.toString() === item.order._id.toString(),
                    )}
                    productTotals={(returnTotals?.productTotals || []).find(
                      (productTotals: ProductTotals) =>
                        productTotals.product._id === item._id,
                    )}
                    return_={return_}
                    returnLoadPending={pending}
                    setEditItemModalOpen={setEditItemModalOpen}
                    setEditSingleItemDispositionModalOpen={
                      setEditSingleItemDispositionModalOpen
                    }
                    setItemBeingEdited={setItemBeingEdited}
                  />
                ))}
              </Card>
            </Flex>
          ))
        ) : (
          // Single shipment - render all items in one card
          <Card className={returnCss.card} gap="md" p="md" radius="md">
            {return_?.products.map((item, index) => (
              <Item
                defaultItemExpanded={defaultItemExpanded}
                handleChangeReturnType={handleChangeReturnType}
                itemToDisplay={item}
                key={index}
                order={orders?.find(
                  (order) => order._id.toString() === item.order._id.toString(),
                )}
                productTotals={(returnTotals?.productTotals || []).find(
                  (productTotals: ProductTotals) =>
                    productTotals.product._id === item._id,
                )}
                return_={return_}
                returnLoadPending={pending}
                setEditItemModalOpen={setEditItemModalOpen}
                setEditSingleItemDispositionModalOpen={
                  setEditSingleItemDispositionModalOpen
                }
                setItemBeingEdited={setItemBeingEdited}
              />
            ))}
          </Card>
        )}
      </div>
      <div className={returnCss.newItems}>
        {pending ? (
          <NewItemsSkeleton />
        ) : (
          <>
            {return_ &&
              (hasNewItemsToShow ||
                willShowAddNewItemsButton ||
                showNewItems(return_, team) ||
                viewingOriginal) && (
                <Flex className={returnCss.newItemHeader} dir="column">
                  <Text fontSize="md" fontWeight="semibold">
                    New items
                  </Text>
                  {!!exchangeOrderModified && (
                    <RedoButtonTabGroup
                      className={returnCss.newItemHeaderTabs}
                      onChange={(value) => {
                        setViewTab(value);
                        setViewingOriginal(value === "original");
                      }}
                      options={[
                        {
                          value: "actual",
                          el: <Text fontSize="xs">Actual</Text>,
                        },
                        {
                          value: "original",
                          el: <Text fontSize="xs">Original</Text>,
                        },
                      ]}
                      size="xs"
                      value={viewTab}
                    />
                  )}
                </Flex>
              )}
            {!!numNewItems() && (
              <Flex
                className={returnCss.newItemShipping}
                flexDirection="column"
              >
                {!return_ ? (
                  <div />
                ) : (
                  <Text
                    className={returnCss.shippingTextHeader}
                    fontWeight="thin"
                  >
                    Shipping to:
                  </Text>
                )}
                <div>
                  {!return_ ? (
                    <SkeletonText length={30} />
                  ) : (
                    <Text className={returnCss.shippingText} fontWeight="thin">
                      {return_.newOrderAddress?.name ||
                        getReturner(return_).name}
                    </Text>
                  )}
                  <address>
                    <Text className={returnCss.shippingText} fontWeight="thin">
                      {return_ ? (
                        shopifyAddressString(
                          return_.newOrderAddress
                            ? return_.newOrderAddress
                            : return_.shipping_address
                              ? return_.shipping_address
                              : return_.products[0].order.shopify
                                  .shipping_address,
                        )
                      ) : (
                        <SkeletonText length={28} />
                      )}
                    </Text>
                  </address>
                </div>
                {return_ &&
                  canManageReturn &&
                  isExchangeOrderEditable(return_, team, user) && (
                    <ExternalLink
                      className={classNames(
                        returnCss.changeAddress,
                        returnCss.shippingText,
                      )}
                      onClick={handleChangeNewOrderAddressModalOpen}
                      showIcon={false}
                    >
                      Change address
                    </ExternalLink>
                  )}
              </Flex>
            )}
            {hasNewItemsToShow && return_ && (
              <Card className={returnCss.card} gap="md" p="md" radius="md">
                <section
                  className={classNames(
                    cardCss.section,
                    returnCss.newItemsSection,
                  )}
                >
                  {/* Variant exchange items */}
                  {!!newItemsFromVariantExchange &&
                    newItemsFromVariantExchange.map((item, index) => (
                      <NewReturnItem
                        defaultItemExpanded={defaultItemExpanded}
                        deleteExchangeItem={handleRemoveExchangeItem}
                        editable={canRemoveExchangeItems}
                        item={{
                          isVariantExchange: true,
                          images: item.exchangeGroupItem
                            ? [item.exchangeGroupItem.imageSrc]
                            : item.exchange_for
                              ? item.exchange_for.images
                              : item.images,
                          option1: item.option1 || "",
                          option2: item.option2 || "",
                          option3: item.option3 || "",
                          price:
                            (returnTotals?.productTotals || [])
                              ?.find(
                                (productTotals: ProductTotals) =>
                                  productTotals.product._id === item._id,
                              )
                              ?.finalPrice.toString() || "",
                          title:
                            item.exchangeGroupItem?.title || item.product_title,
                          variantTitle:
                            item.exchangeGroupItem?.variantTitle ||
                            item.exchange_for?.variant_title ||
                            "",
                          variantId:
                            item.exchangeGroupItem?.variantId ||
                            item.exchange_for?.variant_id ||
                            "",
                          shopifyVariant: {
                            sku: item.exchange_for?.sku || "",
                            product_id: parseFloat(
                              item.exchangeGroupItem?.productId ||
                                item.product_id,
                            ),
                          },
                          quantity: item.quantity,
                          outOfStock: item.outOfStockDuringProcessing,
                        }}
                        key={index}
                        reload={reload}
                        return={return_}
                      />
                    ))}
                  {/* Advanced exchange items */}
                  {!!newItemsFromAdvancedExchange &&
                    newItemsFromAdvancedExchange.map((item, index) => (
                      <NewReturnItem
                        defaultItemExpanded={defaultItemExpanded}
                        deleteExchangeItem={handleRemoveExchangeItem}
                        editable={canRemoveExchangeItems}
                        item={{
                          ...item,
                          isVariantExchange: false,
                          outOfStock: item.outOfStockDuringProcessing,
                        }}
                        key={index}
                        reload={reload}
                        return={return_}
                      />
                    ))}
                </section>
              </Card>
            )}
            {willShowAddNewItemsButton && (
              <Flex
                align="center"
                className={returnCss.addNewItemButton}
                gap="sm"
                onClick={() => setEditNewItemsModalOpen(true)}
              >
                <PlusIcon className={returnCss.addNewItemIcon} />
                <Text
                  className={returnCss.addNewItemButtonText}
                  fontSize="xs"
                  fontWeight="thin"
                >
                  Add new item
                </Text>
              </Flex>
            )}
            <ProductSelectModal
              open={editNewItemsModalOpen}
              provider={orders?.[0]?.provider || Provider.SHOPIFY}
              selectedVariants={[]}
              setOpen={setEditNewItemsModalOpen}
              setSelectedVariants={(products: VariantInfo[]) => {
                void handleAddExchangeItems(
                  products.map((product) => product.id),
                );
              }}
            />
          </>
        )}
      </div>
      {return_ && returnTotals && (
        <>
          {productToUpdate && (
            <ChangeReturnTypeModal
              newType={newType}
              onClose={handleChangeReturnTypeClose}
              open={returnTypeModalOpen}
              product={productToUpdate}
              reload={reload}
              return={return_}
            />
          )}
          {resetOpen && (
            <ResetModal
              onClose={handleResetClose}
              onSubmitFinished={onResetSubmitFinished}
              open={resetOpen}
              return={return_}
            />
          )}
          {reopenOpen && (
            <ReopenModal
              onClose={handleReopenClose}
              open={reopenOpen}
              reload={reload}
              return={return_}
            />
          )}
          {reapproveOpen && (
            <ApproveModal
              onClose={handleReapproveClose}
              open={reapproveOpen}
              orders={orders}
              reapprove
              reload={reload}
              return={return_}
            />
          )}
          {closeOpen && (
            <CloseModal
              onClose={handleCloseModalClose}
              open={closeOpen}
              reload={reload}
              return={return_}
            />
          )}
          {chargeRecoveryOpen && (
            <ChargeRecoveryModal
              onClose={handleRecoveryChargeClose}
              open={chargeRecoveryOpen}
              reload={reload}
              return={return_}
            />
          )}
          {restockItemsModalOpen &&
            team &&
            returnItemsToPrice &&
            orderProvider && (
              <RestockItemsModal
                closeModal={(_reload: boolean = false) => {
                  setRestockItemsModalOpen(false);
                  _reload && reload();
                }}
                open={restockItemsModalOpen}
                orderProvider={orderProvider}
                return_={return_}
                returnItemsToPrice={returnItemsToPrice}
                team={team}
              />
            )}
          {approveOpen &&
            (dropshipConfirmModalOpen ? (
              <DropshipConfirmationModal
                onAccept={handleDropshipApprove}
                onClose={handleDropshipClose}
                open={approveOpen}
              />
            ) : (
              <ApproveModal
                onClose={handleApproveClose}
                open={approveOpen}
                orders={orders}
                reapprove={false}
                reload={reload}
                return={return_}
              />
            ))}

          {denyOpen && (
            <DenyModal
              onClose={handleDenyClose}
              open={denyOpen}
              reload={reload}
              return={return_}
            />
          )}
          {cancelOpen && (
            <RejectModal
              onClose={handleCancelClose}
              open={cancelOpen}
              reload={reload}
              return={return_}
            />
          )}
          {processOpen && (
            <ProcessModal
              isProcessing={isProcessing}
              onClose={handleProcessClose}
              open={processOpen}
              orders={orders || []}
              reload={reload}
              return={return_}
              returnTotals={returnTotals}
              setIsProcessing={setIsProcessing}
            />
          )}
          {editItemModalOpen && returnProductBeingEdited && (
            <EditItemModal
              item={returnProductBeingEdited}
              onClose={handleEditItemModalClose}
              open={editItemModalOpen}
              reload={reload}
              return_={return_}
              returnTotals={returnTotals}
            />
          )}
          {editSingleItemDispositionModalOpen.open && (
            <EditSingleItemDispositionModal
              closeModal={(_reload: boolean = false) => {
                setEditSingleItemDispositionModalOpen({ open: false });
                _reload && reload();
              }}
              item={editSingleItemDispositionModalOpen.item}
              open={editSingleItemDispositionModalOpen.open}
              return_={return_}
            />
          )}
          {itemDispositionModalOpen &&
            team &&
            returnItemsToPrice &&
            orderProvider && (
              <ItemDispositionModal
                closeModal={(_reload: boolean = false) => {
                  setItemDispositionModalOpen(false);
                  _reload && reload();
                }}
                open={itemDispositionModalOpen}
                orderProvider={orderProvider}
                return_={return_}
                returnItemsToPrice={returnItemsToPrice}
                team={team}
              />
            )}
          {flagModalOpen && (
            <FlagModal
              flag
              onClose={handleFlagModalClose}
              open={flagModalOpen}
              reload={reload}
              return={return_}
            />
          )}
          {unflagModalOpen && (
            <FlagModal
              flag={false}
              onClose={handleUnflagModalClose}
              open={unflagModalOpen}
              reload={reload}
              return={return_}
            />
          )}
          {changeNewOrderAddressModalOpen && (
            <ChangeNewOrderAddressModal
              onClose={handleChangeNewOrderAddressModalClose}
              open={changeNewOrderAddressModalOpen}
              reload={reload}
              return={return_}
            />
          )}
          {resentInvoiceSuccessMessageOpen && (
            <ResentInvoiceSuccessMessage
              onClose={handleResentInvoiceSuccessMessageClose}
              open={resentInvoiceSuccessMessageOpen}
            />
          )}
          {refundOpen && (
            <RefundRecoveryChargeModal
              onClose={handleRefundClose}
              open={refundOpen}
              reload={reload}
              returnItem={return_}
            />
          )}
        </>
      )}
      <RedoModal
        buttonPlacement="tight"
        isOpen={resendInvoiceModalOpen}
        modalSize="sm"
        onModalCloseRequested={() => {
          setResendInvoiceModalOpen(false);
        }}
        primaryButton={{
          text: "Send",
          onClick: async () => {
            await handleResendDraftEmail();
            setResendInvoiceModalOpen(false);
          },
          disabled: false,
        }}
        secondaryButton={{
          text: "Cancel",
          onClick: () => {
            setResendInvoiceModalOpen(false);
          },
          disabled: false,
        }}
        subtitle="This will send an email with the invoice to the customer"
        theme="success"
        title="Resend Invoice"
      >
        <FormTextInput
          input={EmailInput.inputs.email}
          label="Email Address"
          labelTheme={LabelTheme.THIN}
        />
      </RedoModal>
    </>
  );
});

const ResentInvoiceSuccessMessage = memo(function ResentInvoiceSuccessMessage({
  open,
  onClose,
}: {
  open: boolean;
  onClose(): void;
}) {
  return (
    <Snackbar autoHideDuration={6000} onClose={onClose} open={open}>
      <Alert onClose={onClose} severity="success">
        Invoice email successfully sent
      </Alert>
    </Snackbar>
  );
});
