import { z } from "zod";
import { zExt } from "../common/zod-util";
import { ParcelSchema } from "../outbound-labels/parcel";
export enum OutboundLabelsInvoiceStatus {
  IN_PROGRESS = "IN_PROGRESS",
  PAID = "PAID",
  UNPAID = "UNPAID",
}

export const OutboundLabelsInvoiceStatusTextMap = {
  [OutboundLabelsInvoiceStatus.PAID]: "Paid",
  [OutboundLabelsInvoiceStatus.IN_PROGRESS]: "In Progress",
  [OutboundLabelsInvoiceStatus.UNPAID]: "Unpaid",
};

export enum OutboundLabelsInvoiceTransactionType {
  VOID_LABEL = "VOID_LABEL",
  CHARGE_LABEL = "CHARGE_LABEL",
  ADD_FUNDS = "ADD_FUNDS",
  REFUND = "REFUND",
  MISCELLANEOUS = "MISCELLANEOUS",
}

export const OutboundLabelsInvoiceStatusEnum = z.nativeEnum(
  OutboundLabelsInvoiceStatus,
);

export const OutboundLabelsInvoiceLineItemSchema = z.object({
  date: z.date(),
  description: z.string(),
  orderNumbers: z.array(z.string()).nullish(),
  package: ParcelSchema.nullish(),
  zip: z.string().nullish(),
  carrier: z.string().nullish(),
  serviceLevel: z.string().nullish(),
  insurance: z.string().nullish(),
  customerLabelPaid: z.number().nullish(),
  amount: z.string(),
  type: z.nativeEnum(OutboundLabelsInvoiceTransactionType),
});

export type OutboundLabelsInvoiceLineItem = z.infer<
  typeof OutboundLabelsInvoiceLineItemSchema
>;

export const OutboundLabelsInvoiceSchema = z.object({
  _id: zExt.objectId(),
  team: zExt.objectId(),
  periodStart: z.date(),
  periodEnd: z.date().nullish(),
  transactions: z.array(OutboundLabelsInvoiceLineItemSchema),
  total: z.number(),
  status: OutboundLabelsInvoiceStatusEnum.nullish(),
  paymentDetails: z
    .object({ institution: z.string().nullish(), last4: z.string().nullish() })
    .nullish(),
});

export type OutboundLabelsInvoice = z.infer<typeof OutboundLabelsInvoiceSchema>;
