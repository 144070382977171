import { CheckoutAbTestMetric } from "@redotech/redo-model/checkouts/checkout-ab-test-utils";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

const checkoutMetricData = z.object({
  valuesByTreatment: z.array(
    z.object({ treatmentId: zExt.objectId(), value: z.number() }),
  ),
  // Making nullable until we have a way to calculate confidence
  // for all metrics.
  variantConfidencesByTreatment: z
    .array(z.object({ treatmentId: zExt.objectId(), confidence: z.number() }))
    .nullable(),
});

type CheckoutMetricsShape = {
  [key in CheckoutAbTestMetric]: typeof checkoutMetricData;
};

export const calculateCheckoutSplitResults = {
  input: z.object({ splitId: zExt.objectId() }),
  output: z.object<CheckoutMetricsShape>({
    [CheckoutAbTestMetric.CHECKOUT_CONVERSION_RATE]: checkoutMetricData,
    [CheckoutAbTestMetric.AVERAGE_SHIPPING_REVENUE]: checkoutMetricData,
    [CheckoutAbTestMetric.ORDERS_PLACED]: checkoutMetricData,
    [CheckoutAbTestMetric.AVERAGE_ORDER_VALUE]: checkoutMetricData,
  }),
};

export type CalculateCheckoutSplitResultsOutput = z.infer<
  typeof calculateCheckoutSplitResults.output
>;
