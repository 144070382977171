import { genericMemo } from "@redotech/react-util/component";
import { assertNever } from "@redotech/util/type";
import * as classNames from "classnames";
import { memo } from "react";
import { RowClickHandler } from "../../table";
import { RedoTableColumn, RedoTableColumnAlignment } from "./redo-table";
import * as redoTableCellsCss from "./redo-table-cells.module.css";
import * as redoTableRowCss from "./redo-table-row.module.css";

export interface RedoTableRowProps<DATA> {
  className?: string;
  row: DATA;
  rowIndex: number;
  columns: RedoTableColumn<DATA>[];
  onRowClick?: (
    row: DATA,
    rowIndex: number,
    sourceEvent: React.MouseEvent,
  ) => void;
  onContextMenu?: RowClickHandler<DATA>;
  onRowHovered?(record?: DATA): void;
  rowSelected?: boolean;
}

export const RedoTableRow = genericMemo(function RedoTableRow<DATA>({
  className,
  row,
  rowIndex,
  columns,
  onRowClick,
  rowSelected,
  onContextMenu,
  onRowHovered,
}: RedoTableRowProps<DATA>) {
  return (
    <div
      className={classNames(
        redoTableRowCss.row,
        rowSelected && redoTableRowCss.selectedRow,
        onRowClick && redoTableRowCss.clickableRow,
        className,
      )}
      onMouseEnter={() => onRowHovered && onRowHovered(row)}
      onMouseLeave={() => onRowHovered && onRowHovered(undefined)}
      role="row"
    >
      {columns.map((column, columnIndex) => {
        const cellId = `cell-${rowIndex}-${column.key ?? columnIndex}`;
        function onContextMenuHandler(
          event: React.MouseEvent<HTMLTableRowElement>,
        ) {
          onContextMenu?.(row, event, rowIndex);
        }
        return (
          <CellData
            cellId={cellId}
            column={column}
            key={`${cellId}-data`}
            onContextMenuHandler={onContextMenuHandler}
            onRowClick={onRowClick}
            row={row}
            rowIndex={rowIndex}
          />
        );
      })}
    </div>
  );
});

const getAlignmentClass = (alignment: RedoTableColumnAlignment) => {
  switch (alignment) {
    case "left":
      return redoTableRowCss.left;
    case "right":
      return redoTableRowCss.right;
    case "center":
      return redoTableRowCss.center;
    default:
      assertNever(alignment);
  }
};

const CellData = memo(function CellData({
  cellId,
  onContextMenuHandler,
  row,
  rowIndex,
  onRowClick,
  column,
}: {
  cellId: string;
  onContextMenuHandler: (event: React.MouseEvent<HTMLTableRowElement>) => void;
  row: any;
  rowIndex: number;
  onRowClick?: (
    row: any,
    rowIndex: number,
    sourceEvent: React.MouseEvent,
  ) => void;
  column: RedoTableColumn<any>;
}) {
  if (typeof column.renderNormalCell !== "function") {
    console.error(
      `renderNormalCell is not a function for column: ${column.key}`,
    );
    return null;
  }

  return (
    <div
      className={classNames(
        redoTableCellsCss.cell,
        getAlignmentClass(column.alignment || "left"),
      )}
      id={cellId}
      key={cellId}
      onClick={(e) => onRowClick?.(row, rowIndex, e)}
      onContextMenu={onContextMenuHandler}
      role="cell"
    >
      {column.renderNormalCell({ row, rowIndex })}
    </div>
  );
});
