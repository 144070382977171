import { ShopifyIntegration } from "@redotech/redo-model/shopify";
import { Team } from "@redotech/redo-model/team";
import { createContext } from "react";

export type TeamContextValue =
  | (Team & {
      platform: "shopify" | "commerce-cloud" | "commentsold";
      shopifyIntegration?: ShopifyIntegration;
      updatePermissionsLink?: string;
      ianaTimezone: string;
    })
  | undefined;
export const TeamContext = createContext<TeamContextValue>(undefined);

export const ReloadTeamContext = createContext<(() => void) | undefined>(
  undefined,
);
