import { useRequiredContext } from "@redotech/react-util/context";
import { RedoMerchantRpcClientContext } from "@redotech/redo-merchant-app-common/rpc-client";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import {
  UserCallAvailability,
  WebRTCCallState,
} from "@redotech/redo-model/support/voice/voice-types";
import { sinkPromise } from "@redotech/util/promise";
import { useNotification } from "@telnyx/react-client";
import { Call as TelnyxCall } from "@telnyx/webrtc/lib/src/Modules/Verto/webrtc/Call";
import { createContext, useContext, useEffect, useState } from "react";

const TEN_SECONDS_IN_MS = 10000;
export const UserCallAvailabilityContext = createContext<
  | {
      availabilityStatus: UserCallAvailability;
      setAvailabilityStatus: (available: UserCallAvailability) => void;
    }
  | undefined
>(undefined);

export function UserCallAvailabilityContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const team = useRequiredContext(TeamContext);
  const client = useContext(RedoMerchantRpcClientContext);

  const notification = useNotification();

  const [userDerivedStatus, setUserDerivedStatus] =
    useState<UserCallAvailability>(UserCallAvailability.UNAVAILABLE);
  const [currentTimeout, setCurrentTimeout] = useState<NodeJS.Timeout | null>(
    null,
  );

  const voiceEnabled = !!team.settings.support?.voice?.enabled;

  const telnyxCall: TelnyxCall | null = notification?.call;
  const telnyxCallState = telnyxCall?.state as WebRTCCallState | undefined;

  const telnyxDerivedStatus =
    telnyxCall &&
    telnyxCallState !== WebRTCCallState.DESTROY &&
    telnyxCallState !== WebRTCCallState.HANGUP
      ? UserCallAvailability.IN_CALL
      : undefined;

  const availabilityStatus = telnyxDerivedStatus || userDerivedStatus;

  const sendCurrentUserAvailability = async (
    availabilityStatus: UserCallAvailability,
  ) => {
    if (!voiceEnabled) {
      return;
    }
    if (currentTimeout) {
      // If we switch between available and unavailable, we want to clear the previous timeout so it doesn't get reset
      clearTimeout(currentTimeout);
    }
    if (!client) {
      return;
    }
    try {
      await client.createCallAvailability({ availabilityStatus });
    } catch (error) {
      console.error("Failed to update call availability", error);
    } finally {
      // We don't want to continuously send the availability status for unavailable
      if (availabilityStatus !== UserCallAvailability.UNAVAILABLE) {
        const timeout = setTimeout(
          () => sendCurrentUserAvailability(availabilityStatus),
          TEN_SECONDS_IN_MS,
        );
        setCurrentTimeout(timeout);
      }
    }
  };

  useEffect(() => {
    sinkPromise(sendCurrentUserAvailability(availabilityStatus));
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availabilityStatus]);

  useEffect(() => {
    const beforeUnloadHandler = async () => {
      await sendCurrentUserAvailability(UserCallAvailability.UNAVAILABLE);
    };

    window.addEventListener("beforeunload", beforeUnloadHandler);
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserCallAvailabilityContext.Provider
      value={{
        availabilityStatus: availabilityStatus,
        setAvailabilityStatus: setUserDerivedStatus,
      }}
    >
      {children}
    </UserCallAvailabilityContext.Provider>
  );
}
