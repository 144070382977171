import { genericMemo } from "@redotech/react-util/component";
import * as classNames from "classnames";
import { InputHTMLAttributes, memo } from "react";
import FileIconSvg from "../../arbiter-icon/file-type-attachment_solid.svg";
import TrashIconSvg from "../../arbiter-icon/trash-01.svg";
import UploadCloud02Svg from "../../arbiter-icon/upload-cloud-02.svg";
import { humanReadableFileSize } from "../../file-size-utils";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { RedoButton } from "../buttons/redo-button";
import { RedoFeaturedIcon } from "../featured-icon/redo-featured-icon";
import * as redoFileUploadCss from "./redo-file-upload.module.css";

export interface FileUploadState<T> {
  error?: any;
  value?: T;
  name?: string;
  /** false means we finished loading */
  pendingBytes?: number | false;
  bytesTotal?: number;
}

const FilePickerOrDropper = memo(function FilePickerOrDropper({
  disabled,
  nFilesToAccept,
  accept,
  acceptMessage,
  handleFilesAdded,
}: {
  disabled?: boolean;
  nFilesToAccept: number;
  accept?: InputHTMLAttributes<HTMLInputElement>["accept"];
  acceptMessage?: string;
  handleFilesAdded: (files: FileList | null) => void;
}) {
  return (
    <Flex
      align="center"
      as="label"
      className={classNames(
        redoFileUploadCss.container,
        disabled && redoFileUploadCss.disabled,
      )}
      dir="column"
      gap="xs"
      grow={1}
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onDrop={(ev) => {
        console.log("File(s) dropped");

        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();

        // Use DataTransfer interface to access the file(s)
        handleFilesAdded(ev.dataTransfer.files);
      }}
      px="xl"
      py="3xl"
    >
      <Flex align="center" dir="column" gap="lg">
        <Flex>
          <RedoFeaturedIcon
            color="black"
            Icon={UploadCloud02Svg}
            type="default"
          />
        </Flex>
        <Flex align="center" dir="column" gap="xs">
          <Flex gap="sm">
            <Text
              fontSize="sm"
              fontWeight="medium"
              textColor={disabled ? "disabled" : "primary"}
            >
              Click to upload
            </Text>
            <Text fontSize="sm" textColor="tertiary">
              or drag and drop
            </Text>
          </Flex>
          <Flex>
            <Text fontSize="xs" textColor="tertiary">
              {acceptMessage || "Accepts all files"}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <input
        accept={accept}
        className={classNames(redoFileUploadCss.hiddenInput)}
        disabled={disabled}
        multiple={nFilesToAccept > 1}
        onChange={(e) => handleFilesAdded(e.target.files)}
        type="file"
      />
    </Flex>
  );
});

const FileUploadItem = genericMemo(function FileUploadItem<FILE_TYPE>({
  file,
  handleFileDeleted,
}: {
  file: FileUploadState<FILE_TYPE>;
  handleFileDeleted: () => void;
}) {
  return (
    <Flex className={redoFileUploadCss.fileContainer} gap="xs" grow={1} p="xl">
      <Flex gap="xs">
        <Flex>
          <RedoFeaturedIcon Icon={FileIconSvg} type="default" />
        </Flex>
        <Flex dir="column" gap="none">
          <Text fontSize="sm" fontWeight="medium" textColor="secondary">
            {file.name}
          </Text>
          <Text fontSize="sm" textColor="tertiary">
            {humanReadableFileSize(file.bytesTotal || 0)}
            {(file.pendingBytes !== undefined && file.bytesTotal && (
              <>
                {" "}
                -{" "}
                {(
                  ((file.pendingBytes === false
                    ? file.bytesTotal
                    : file.pendingBytes) /
                    file.bytesTotal) *
                  100
                ).toFixed(0)}
                % uploaded
              </>
            )) ||
              ""}
          </Text>
        </Flex>
      </Flex>
      <Flex className={redoFileUploadCss.cornerButtonContainer} p="md">
        <RedoButton
          IconLeading={() => <TrashIconSvg />}
          onClick={() => handleFileDeleted()}
          size="sm"
        />
      </Flex>
    </Flex>
  );
});

/**
 * Arbiter file uploader https://www.figma.com/design/iZHj2I36zd9i8nRbWKw4ZK/%E2%9D%96-Arbiter?node-id=1157-90306&node-type=canvas&t=aFi7HFeRFV5M0nX0-0
 *
 * NOTE: you might just want to use RedoUrlBucketedFileUpload if your files are just bucketed files stored at URLs.
 *
 * @param files - the load state of files isn't used for _dragging and dropping_ - that's instant. The
 * progress is for reflecting status of _server-side_ uploads, processing, etc...
 * (Setting progress, uploading files, etc. is up to you via the `handleFilesAdded` callback and the `files` state.)
 *
 * @param handleFilesAdded - be sure to be defensive about the file types, since drop events could be any extension.
 *
 * @param accept - https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept#limiting_accepted_file_types
 */
export const RedoFileUpload = genericMemo(function RedoFileUpload<FILE_TYPE>({
  nFilesToAccept = 1,
  files,
  handleFilesAdded,
  handleFileDeleted,
  disabled,
  accept,
  acceptMessage,
  className,
}: {
  nFilesToAccept?: number;
  files: FileUploadState<FILE_TYPE>[];
  handleFilesAdded: (files: FileList | null) => void;
  handleFileDeleted: (index: number) => void;
  disabled?: boolean;

  accept?: InputHTMLAttributes<HTMLInputElement>["accept"];
  acceptMessage?: string;
  className?: string;
}) {
  const shouldShowFilePicker = nFilesToAccept > 1 || files.length === 0;

  return (
    <Flex
      align="stretch"
      className={classNames(redoFileUploadCss.widthContainer, className)}
      dir="column"
      justify="center"
    >
      {shouldShowFilePicker && (
        <FilePickerOrDropper
          accept={accept}
          acceptMessage={acceptMessage}
          disabled={disabled}
          handleFilesAdded={handleFilesAdded}
          nFilesToAccept={nFilesToAccept}
        />
      )}
      {files.map((file, index) => {
        return (
          <FileUploadItem
            file={file}
            handleFileDeleted={() => handleFileDeleted(index)}
            key={index}
          />
        );
      })}
    </Flex>
  );
});
