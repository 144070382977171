import { z } from "zod";

export const getAddressFromSuggestion = {
  input: z.object({ suggestionId: z.string() }),
  output: z.object({
    address: z
      .object({
        address1: z.string(),
        city: z.string(),
        province: z.string(),
        zip: z.string(),
        countryCode: z.string(),
      })
      .nullish(),
  }),
};
