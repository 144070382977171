import { RedoSideNavigation } from "@redotech/redo-web/arbiter-components/navigation/redo-side-navigation";
import { filterTruthy } from "@redotech/util/array";
import { memo, useMemo } from "react";
import { useIntercom } from "react-use-intercom";
import { useConversionNavigation } from "./conversion/conversion-navigation";
import { useHomeNavigation } from "./home/home-navigation";
import { useMarketingNavigation } from "./marketing/marketing-navigation";
import { useOrdersNavigation } from "./orders/orders-navigation";
import { useSettingsItems } from "./settings";
import { useSupportNavigation } from "./support/support-navigation";

export const MerchantAppSideNav = memo(function MerchantAppSideNav({
  enterSettingsUrl,
  exitSettingsUrl,
  settingsRoot,
  hideNav,
}: {
  enterSettingsUrl: string;
  exitSettingsUrl: string;
  settingsRoot: string;
  hideNav: boolean;
}) {
  const intercom = useIntercom();

  const homeEntry = useHomeNavigation();
  const conversionEntry = useConversionNavigation();
  const marketing = useMarketingNavigation();
  const orders = useOrdersNavigation();
  const support = useSupportNavigation();

  const allPageEntries = useMemo(() => {
    return filterTruthy([
      homeEntry,
      conversionEntry,
      marketing,
      orders,
      support,
    ]);
  }, [homeEntry, conversionEntry, marketing, orders, support]);

  const settingsItems = useSettingsItems();

  return (
    <RedoSideNavigation
      enterSettingsUrl={enterSettingsUrl}
      exitSettingsUrl={exitSettingsUrl}
      isHidden={hideNav}
      pageEntries={allPageEntries}
      settingSections={settingsItems}
      settingsRoot={settingsRoot}
      toggleHelp={() => {
        intercom.show();
      }}
    />
  );
});
