import {
  FulfillmentGroupDataSchema,
  type IFulfillmentGroupData,
} from "@redotech/redo-model/fulfillments/fulfillment-group";
import { z } from "zod";

export const GroupReplacementSchema = z.object({
  groupId: z.string(),
  replacementGroup: FulfillmentGroupDataSchema as IFulfillmentGroupData,
});

export type GroupReplacement = z.infer<typeof GroupReplacementSchema>;

export const getReplacementGroups = {
  input: z.object({ groupIds: z.array(z.string()) }),
  output: z.object({ replacements: z.array(GroupReplacementSchema) }),
};
