import { CustomerEventType } from "../customer-event/customer-event-definition";

export interface BaseStep {
  id: string;
  customTitle?: string;
}

/**
 * Keep in sync with the same enum in redo/temporal/workflows/run-automation/automation-activity-proxies
 * */
export enum StepType {
  TRIGGER = "trigger", // check redo/temporal/workflows/run-automation.ts if this ever changes, it hardcodes "trigger"
  CONDITION = "condition",
  WAIT = "wait",
  WAIT_FOR_EVENT = "wait_for_event",
  DO_NOTHING = "do_nothing",
  SEND_EMAIL = "send_email",
  SEND_SMS = "send_sms",

  UPSELL = "upsell",
  DOWNSELL = "downsell",
  UPSELL_CONDITION = "upsell_condition",
  OMS_ACTION = "oms_action",
  OMS_CONDITION = "oms_condition",
}

export const supportedWaitForEventTypes = [
  CustomerEventType.MARKETING_CONFIRMED,
] as const;

export type SupportedWaitForEventType =
  (typeof supportedWaitForEventTypes)[number];

export enum AutomationFilter {
  Status = "Status",
  Channel = "Channel",
  Category = "Category",
}

export enum AutomationStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export enum AutomationChannel {
  Email = "Email",
  SMS = "SMS",
}

export enum AutomationSortKey {
  Name = "name",
  UpdatedAt = "updatedAt",
}
