import { useHtmlEventListener } from "@redotech/react-util/event";
import { useHandler } from "@redotech/react-util/hook";
import { useHover } from "@redotech/react-util/hover";
import * as classNames from "classnames";
import { createContext, memo, useMemo, useState } from "react";
import { useLocation, useMatch } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import { useExpandWidth } from "../../../../../react/animation/src/transition";
import { Flex } from "../../flex";
import * as darkModeCss from "../../theme/component-colors-dark.module.css";
import { LinkPrimaryTab, RedoPrimaryNav } from "./primary/redo-primary-nav";
import * as navigationCss from "./redo-navigation.module.css";
import { RedoSettingsNav, SettingsSection } from "./redo-settings-nav";
import {
  RedoSecondaryNav,
  SecondaryNavigationData,
} from "./secondary/redo-secondary-nav";

/**
 * The id of the tab and the secondary navigation data must match.
 */
export interface PageNavigationEntry {
  primaryTab: LinkPrimaryTab;
  secondaryNavigationData: SecondaryNavigationData;
}

export interface RedoSideNavigationProps {
  isHidden: boolean;
  toggleHelp: () => void;
  enterSettingsUrl: string;
  exitSettingsUrl: string;
  settingsRoot: string;
  pageEntries: PageNavigationEntry[];
  settingSections: SettingsSection[] | null;
}

export const CollapseModeContext = createContext<{
  collapseMode: boolean;
  setCollapseMode: (collapseMode: boolean) => void;
}>({ collapseMode: false, setCollapseMode: (collapseMode: boolean) => {} });

export const RedoSideNavigation = memo(function RedoSideNavigation({
  isHidden,
  toggleHelp,
  enterSettingsUrl,
  exitSettingsUrl,
  settingsRoot,
  pageEntries,
  settingSections,
}: RedoSideNavigationProps) {
  const [navContainer, setNavContainer] = useState<HTMLDivElement | null>(null);

  const [collapseContainer, setCollapseContainer] =
    useState<HTMLSpanElement | null>(null);

  const [closingOnCollapseToggle, setClosingOnCollapseToggle] = useState(false);

  const [collapseMode, setCollapseMode] = useLocalStorage(
    "redo_side_navigation_collapse_mode",
    false,
  );

  const handleSetCollapseMode = useHandler((collapseMode: boolean) => {
    if (collapseMode) {
      setClosingOnCollapseToggle(true);
    }
    setCollapseMode(collapseMode);
  });

  const navHovered = useHover(navContainer);

  const collapseContainerHovered = useHover(collapseContainer);

  const isHovered = navHovered || collapseContainerHovered;

  const location = useLocation();

  const isInSettings = useMatch(`${settingsRoot}/*`);

  const [exitedToLeft, setExitedToLeft] = useState(false);

  const expanded =
    !isHidden &&
    !closingOnCollapseToggle &&
    (!collapseMode || isHovered || exitedToLeft);

  const [animateStyles, animating] = useExpandWidth(expanded, navContainer);

  useHtmlEventListener(navContainer, "transitionend", (e) => {
    if (e.propertyName === "width" && closingOnCollapseToggle) {
      setClosingOnCollapseToggle(false);
    }
  });

  useHtmlEventListener(collapseContainer, "touchstart", () => {
    if (collapseMode) {
      setCollapseMode(false);
    }
  });

  useHtmlEventListener(document.documentElement, "mouseleave", (e) => {
    if (e.clientX < 10) {
      setExitedToLeft(true);
    }
  });

  useHtmlEventListener(document.documentElement, "mouseenter", () => {
    requestAnimationFrame(() => setExitedToLeft(false));
  });

  const pagePrimaryTabs = useMemo(
    () => pageEntries.map((entry) => entry.primaryTab),
    [pageEntries],
  );

  const selectedPrimaryTab = pagePrimaryTabs.find((tab) =>
    location.pathname.includes(tab.pathRoot),
  );

  const secondaryNavigationData = pageEntries.find(
    ({ secondaryNavigationData }) =>
      secondaryNavigationData.id === selectedPrimaryTab?.id,
  )?.secondaryNavigationData;

  if (isInSettings) {
    return (
      <Flex grow={1} position="relative">
        <span
          className={navigationCss.collapseBar}
          ref={setCollapseContainer}
        />
        <Flex
          className={classNames(
            darkModeCss.darkMode,
            navigationCss.sideNav,
            collapseMode && navigationCss.collapseMode,
          )}
          dir="row"
          gap="none"
          grow={1}
          ref={setNavContainer}
          style={animateStyles}
        >
          {(animating || expanded) && settingSections && (
            <RedoSettingsNav
              exitSettingsUrl={exitSettingsUrl}
              sections={settingSections}
            />
          )}
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex grow={1} position="relative">
      <span className={navigationCss.collapseBar} ref={setCollapseContainer} />
      <Flex
        className={classNames(
          darkModeCss.darkMode,
          navigationCss.sideNav,
          collapseMode && navigationCss.collapseMode,
        )}
        dir="row"
        gap="none"
        grow={1}
        ref={setNavContainer}
        style={animateStyles}
      >
        {(animating || expanded) && (
          <>
            <RedoPrimaryNav
              enterSettingsUrl={settingSections ? enterSettingsUrl : null}
              isCollapseMode={collapseMode}
              pageTabs={pagePrimaryTabs}
              settingsRoot={settingsRoot}
              toggleCollapseMode={() => handleSetCollapseMode(!collapseMode)}
              toggleHelp={toggleHelp}
            />
            <RedoSecondaryNav
              data={secondaryNavigationData}
              pathRoot={selectedPrimaryTab?.pathRoot || ""}
            />
          </>
        )}
      </Flex>
    </Flex>
  );
});
