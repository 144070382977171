import { Order } from "@redotech/redo-model/order";
import {
  getBuyer,
  getReturner,
  isGiftReturn,
  Returner,
  type Return,
} from "@redotech/redo-model/return";
import { Address } from "@redotech/redo-model/team";
import { AddressDisplay } from "@redotech/redo-web/address/address-display";
import { RedoExternalLink } from "@redotech/redo-web/arbiter-components/links/redo-external-link";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { NullishPartial } from "@redotech/util/type";
import { memo } from "react";
import { returnTypeName } from "../../util";
import * as customerInformationTabCss from "./customer-information-tab.module.css";
import { YofiFraudDetails } from "./yofi-fraud-details";

export const CustomerInformationTab = memo(function CustomerInformationTab({
  return: return_,
  order,
}: {
  return: Return;
  order: Order | undefined | null;
}) {
  const buyer = getBuyer(return_);
  const returner = getReturner(return_);
  const isGift = isGiftReturn(return_);

  const buyerShippingAddress =
    return_?.products[0]?.order?.shopify?.shipping_address;

  const address: Address = {
    name: buyerShippingAddress?.name || "",
    street1: buyerShippingAddress?.address1 || "",
    street2: buyerShippingAddress?.address2 || "",
    city: buyerShippingAddress?.city || "",
    state: buyerShippingAddress?.province || buyerShippingAddress?.state || "",
    zip: buyerShippingAddress?.zip || "",
    country: buyerShippingAddress?.country || "",
  };

  const returnerShippingAddress = return_?.shipping_address;

  const returnerAddress: Address = {
    name: returnerShippingAddress?.name || "",
    street1: returnerShippingAddress?.address1 || "",
    street2: returnerShippingAddress?.address2 || "",
    city: returnerShippingAddress?.city || "",
    state: returnerShippingAddress?.province || "",
    zip: returnerShippingAddress?.zip || "",
    country: returnerShippingAddress?.country || "",
  };

  let customerInfoSection;
  if (!isGift) {
    customerInfoSection = (
      <SingleCustomerInfo address={address} customer={buyer} />
    );
  } else {
    customerInfoSection = (
      <Flex dir="column" gap="lg">
        <Text fontSize="xs" fontWeight="semibold">
          Original buyer
        </Text>
        <SingleCustomerInfo address={address} customer={buyer} />
        <Text fontSize="xs" fontWeight="semibold">
          Gift recipient
        </Text>
        <SingleCustomerInfo address={returnerAddress} customer={returner} />
      </Flex>
    );
  }

  return (
    <Flex dir="column" gap="xs" pt="lg">
      {customerInfoSection}
      <YofiFraudDetails
        order={order}
        returnTypeName={returnTypeName(return_?.type, true)}
      />
    </Flex>
  );
});

const SingleCustomerInfo = memo(function SingleCustomerInfo({
  customer,
  address,
}: {
  customer: NullishPartial<Returner>;
  address: Address;
}) {
  return (
    <Flex className={customerInformationTabCss.container} dir="column" gap="xs">
      <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
        {customer.name}
      </Text>
      {!!customer.email && (
        <RedoExternalLink
          text={customer.email}
          url={`mailto:${customer.email}`}
        />
      )}
      {customer.phone && (
        <Text fontSize="xs" fontWeight="regular" textColor="tertiary">
          {customer.phone}
        </Text>
      )}
      <AddressDisplay address={address} hideName />
    </Flex>
  );
});
