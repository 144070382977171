import { z } from "zod";

export const getMetaLeadAdsForms = {
  input: z.object({ pageId: z.string() }),
  output: z.object({
    forms: z.array(
      z.object({
        id: z.string(),
        name: z.string(),
        status: z.string(),
        locale: z.string(),
      }),
    ),
  }),
};
