import {
  CallDirection,
  CallState,
  UserCallAvailability,
} from "@redotech/redo-model/support/voice/voice-types";
import PhoneIcon from "@redotech/redo-web/arbiter-icon/phone.svg";
import { memo, useContext, useEffect, useState } from "react";
import { RedoButton } from "../arbiter-components/buttons/redo-button";
import { Flex } from "../flex";
import { REDO_RINGTONE } from "../sound/sounds";
import { ActiveCallView } from "./active-call-view";
import { CollapsedPhoneWidget } from "./collapsed-phone-widget";
import { InboundCall } from "./inbound-call";
import { PhoneIdle } from "./phone-idle";
import { PhoneWidgetHeader } from "./phone-widget-header";
import * as phoneWidgetCss from "./phone-widget.module.css";
import { VoiceContext } from "./voice-context";

export type ExpandedState = "full" | "collapsed" | "iconOnly";
/**
 * Requires `VoiceContext`
 */
export const PhoneWidget = memo(function PhoneWidget({
  status,
  merchantPhoneNumber,
}: {
  status: UserCallAvailability;
  merchantPhoneNumber: string;
}) {
  const { call } = useContext(VoiceContext);
  const [expandedState, setExpandedState] =
    useState<ExpandedState>("collapsed");

  useEffect(() => {
    if (call?.state === CallState.RINGING) {
      REDO_RINGTONE.play().catch((error) => {
        console.warn("Failed to play ringing sound:", error);
      });
    } else {
      REDO_RINGTONE.pause();
      REDO_RINGTONE.currentTime = 0;
    }
  }, [call?.state, call?.direction]);

  if (expandedState === "iconOnly") {
    return (
      <RedoButton
        hierarchy="secondary"
        IconLeading={PhoneIcon}
        onClick={() => setExpandedState("full")}
        size="lg"
      />
    );
  }

  return (
    <Flex className={phoneWidgetCss.widgetContainer} dir="column">
      {expandedState === "collapsed" && (
        <CollapsedPhoneWidget
          onClose={() => setExpandedState("iconOnly")}
          onExpand={() => setExpandedState("full")}
          status={status}
        />
      )}
      {expandedState === "full" && (
        <ExpandedPhoneWidget
          merchantPhoneNumber={merchantPhoneNumber}
          setExpandedState={setExpandedState}
        />
      )}
    </Flex>
  );
});

const ExpandedPhoneWidget = memo(function ExpandedPhoneWidget({
  setExpandedState,
  merchantPhoneNumber,
}: {
  setExpandedState: (val: ExpandedState) => void;
  merchantPhoneNumber: string;
}) {
  const { call, performCall, status, setStatus } = useContext(VoiceContext);

  const isInboundRinging =
    call &&
    (call.state === CallState.RINGING || call.state === CallState.CONNECTING) &&
    call.direction === CallDirection.INBOUND;

  return (
    <Flex className={phoneWidgetCss.bodySections} dir="column" gap="none">
      <PhoneWidgetHeader
        merchantPhoneNumber={merchantPhoneNumber}
        setExpandedState={setExpandedState}
      />
      {!call && (
        <PhoneIdle
          performCall={performCall}
          setStatus={setStatus}
          status={status}
        />
      )}
      {isInboundRinging && <InboundCall call={call} />}
      {call && !isInboundRinging && <ActiveCallView call={call} />}
    </Flex>
  );
});
