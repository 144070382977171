import { useHandler } from "@redotech/react-util/hook";
import {
  NumberFilterOperator,
  NumberValue,
} from "@redotech/redo-model/views/advanced-filters/number-filter";
import { RedoIncrementDecrement } from "@redotech/redo-web/arbiter-components/increment-decrement/redo-increment-decrement";
import * as classnames from "classnames";
import { memo } from "react";
import { RedoFilterGroup } from "../../arbiter-components/filter-group/redo-filter-group";
import { SimpleFilterDropdown } from "../../filters/simple-filter-dropdown";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { NumberTableFilter } from "../advanced-filter";
import * as styles from "./filter.module.css";

export const NumberFilterGroup = memo(function NumberFilterGroup({
  filter,
  setFilter,
  removeFilter,
}: {
  filter: NumberTableFilter;
  setFilter(filter: NumberTableFilter): void;
  removeFilter(): void;
}) {
  const { operator, value } = filter.data;
  const step = filter?.step ?? 1;
  const min = filter?.min ?? 0;
  const scalar = filter?.secondField?.scalar ?? 1;
  const modifier = filter.modifier ?? 1;

  function getFirstUnmodified(value: number) {
    if (step < 1) {
      return parseFloat((value / modifier / scalar).toFixed(2)); // For fields we want decimal precision
    }
    return Math.floor(parseFloat((value / modifier / scalar).toFixed(8))); // Fixes floating point precision issues
  }

  function getSecondUnmodified(value: number) {
    return Math.round((value / modifier) % scalar) % scalar;
  }

  const handleSetOperator = useHandler((operator: NumberFilterOperator) => {
    setFilter({
      ...filter,
      data: {
        ...filter.data,
        operator,
        value:
          operator === NumberFilterOperator.BETWEEN
            ? [value?.[0] ?? 0, value?.[1] ?? 1]
            : [value?.[0] ?? 0],
      },
    });
  });

  const valueAnchor = (
    <Flex>
      <RedoIncrementDecrement
        className={classnames(
          styles.filterIncDec,
          operator === NumberFilterOperator.BETWEEN
            ? styles.rightBorder
            : undefined,
        )}
        max={filter.max ?? undefined}
        min={filter.min ?? undefined}
        placeholder={
          min >= 1 || step >= 1
            ? min.toString()
            : min.toFixed(step.toString().split(".")[1]?.length || 0)
        }
        prefix={filter?.prefix}
        setValue={(newNumber) => {
          let newVal: NumberValue = [
            (getSecondUnmodified(value?.[0] ?? 0) + newNumber * scalar) *
              modifier,
          ];
          if (operator === NumberFilterOperator.BETWEEN) {
            newVal = [newVal[0], value?.[1] ?? 0];
          }
          setFilter({ ...filter, data: { ...filter.data, value: newVal } });
        }}
        size="small"
        step={filter?.step ?? undefined}
        suffix={filter?.suffix}
        value={getFirstUnmodified(value?.[0] ?? 0)}
      />
      {filter.secondField && (
        <RedoIncrementDecrement
          className={classnames(
            styles.filterIncDec,
            operator === NumberFilterOperator.BETWEEN
              ? styles.rightBorder
              : undefined,
          )}
          max={filter.secondField.max ?? undefined}
          min={filter.secondField.min ?? undefined}
          placeholder={
            min >= 1 || step >= 1
              ? min.toString()
              : min.toFixed(step.toString().split(".")[1]?.length || 0)
          }
          prefix={filter.secondField.prefix}
          setValue={(newNumber) => {
            let newVal: NumberValue = [
              (getFirstUnmodified(value?.[0] ?? 0) * scalar + newNumber) *
                modifier,
            ];
            if (operator === NumberFilterOperator.BETWEEN) {
              newVal = [newVal[0], getFirstUnmodified(value?.[1] ?? 0)];
            }
            setFilter({ ...filter, data: { ...filter.data, value: newVal } });
          }}
          size="small"
          step={filter.secondField.step ?? undefined}
          suffix={filter.secondField.suffix}
          value={getSecondUnmodified(value?.[0] ?? 0)}
        />
      )}
      {operator === NumberFilterOperator.BETWEEN && (
        <Flex>
          <Text fontSize="sm" textColor="secondary">
            and
          </Text>
          <RedoIncrementDecrement
            className={classnames(
              styles.filterIncDec,
              operator === NumberFilterOperator.BETWEEN
                ? styles.leftBorder
                : undefined,
            )}
            max={filter.max ?? undefined}
            min={filter.min ?? undefined}
            placeholder={
              min >= 1 || step >= 1
                ? min.toString()
                : min.toFixed(step.toString().split(".")[1]?.length || 0)
            }
            prefix={filter?.prefix}
            setValue={(newNumber) => {
              let newVal: NumberValue = [
                0,
                (getSecondUnmodified(value?.[1] ?? 0) + newNumber * scalar) *
                  modifier,
              ];
              if (value?.[0]) {
                newVal = [value[0], newVal[1]];
              }
              setFilter({ ...filter, data: { ...filter.data, value: newVal } });
            }}
            size="small"
            step={filter?.step ?? undefined}
            suffix={filter?.suffix}
            value={getFirstUnmodified(value?.[1] ?? 0)}
          />
          {filter.secondField && (
            <RedoIncrementDecrement
              className={classnames(
                styles.filterIncDec,
                operator === NumberFilterOperator.BETWEEN
                  ? styles.leftBorder
                  : undefined,
              )}
              max={filter.secondField.max ?? undefined}
              min={filter.secondField.min ?? undefined}
              placeholder={
                min >= 1 || step >= 1
                  ? min.toString()
                  : min.toFixed(step.toString().split(".")[1]?.length || 0)
              }
              prefix={filter.secondField.prefix}
              setValue={(newNumber) => {
                let newVal: NumberValue = [
                  0,
                  (getFirstUnmodified(value?.[1] ?? 0) * scalar + newNumber) *
                    modifier,
                ];
                if (value?.[0]) {
                  newVal = [value[0], newVal[1]];
                }
                setFilter({
                  ...filter,
                  data: { ...filter.data, value: newVal },
                });
              }}
              size="small"
              step={filter.secondField.step ?? undefined}
              suffix={filter.secondField.suffix}
              value={getSecondUnmodified(value?.[1] ?? 0)}
            />
          )}
        </Flex>
      )}
    </Flex>
  );

  return (
    <RedoFilterGroup
      Icon={filter.Icon}
      propertyName={filter.displayName}
      query={
        <SimpleFilterDropdown
          filterStyle="query"
          options={filter.operators || Object.values(NumberFilterOperator)}
          optionToFriendlyName={(value) =>
            numberFilterOperatorTypeToText[value]
          }
          setValue={handleSetOperator}
          value={operator}
        />
      }
      removeFilter={removeFilter}
      value={valueAnchor}
    />
  );
});

export const numberFilterOperatorTypeToText: Record<
  NumberFilterOperator,
  string
> = {
  [NumberFilterOperator.EQUAL]: "equals",
  [NumberFilterOperator.LESS_THAN]: "less than",
  [NumberFilterOperator.GREATER_THAN]: "greater than",
  [NumberFilterOperator.BETWEEN]: "between",
};
