import { CreateShippingRateTableInputSchema } from "@redotech/redo-model/integration/redo-in-shipping/shipping-rate-table";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const createShippingMethod = {
  input: z.object({
    checkoutExperienceId: zExt.objectId(),
    shippingRateTable: CreateShippingRateTableInputSchema,
  }),
  output: z.null(),
};
export type CreateShippingMethodInput = z.infer<
  typeof createShippingMethod.input
>;
