import { memo, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const Redirect = memo(function Redirect({
  match,
  replaceValue,
}: {
  match: RegExp;
  replaceValue: string;
}) {
  const location = useLocation();

  const navigate = useNavigate();

  const [hasRedirected, setHasRedirected] = useState(false);
  useEffect(() => {
    if (hasRedirected) {
      return;
    }
    const newLocation =
      location.pathname.replace(match, replaceValue) + location.search;
    navigate(newLocation, { replace: true });
    setHasRedirected(true);
  }, [location, navigate, match, replaceValue, hasRedirected]);

  return null;
});
