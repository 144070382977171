import { useHandler } from "@redotech/react-util/hook";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { UserContext } from "@redotech/redo-merchant-app-common/user";
import {
  Step as ModelStep,
  Payer,
  UncoveredPayer,
} from "@redotech/redo-model/return-flow";
import { Permission, permitted } from "@redotech/redo-model/user";
import ToolIcon from "@redotech/redo-web/arbiter-icon/tool-02.svg";
import { BlockLayout } from "@redotech/redo-web/flowchart";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { Switch } from "@redotech/redo-web/switch";
import { produce } from "immer";
import { memo, useContext } from "react";
import { StepType, StepTypeDetailsProps } from "./step";

type SubmitWarrantyOption = { labelPayer: UncoveredPayer; chargeFee: boolean };

export interface State {
  customTitle?: string;
  option: SubmitWarrantyOption;
}

const Details = memo(function Details({
  state,
  setState,
}: StepTypeDetailsProps<State>) {
  const user = useContext(UserContext);
  const team = useContext(TeamContext);
  const canEditSettings =
    !!user && permitted(user.permissions, Permission.EDIT_SETTINGS);
  const handleSafetyNet = useHandler((value: boolean) => {
    setState((state) =>
      produce(state, (state) => {
        state.option.labelPayer = value ? Payer.MERCHANT : Payer.CUSTOMER;
      }),
    );
  });

  const handleChargeFee = useHandler((value: boolean) => {
    setState((state) =>
      produce(state, (state) => {
        state.option.chargeFee = value;
      }),
    );
  });

  return (
    <>
      <LabeledInput
        description="Merchant pays for labels not covered by Redo."
        label="Safety net"
      >
        <Switch
          disabled={!canEditSettings}
          onChange={handleSafetyNet}
          value={state.option.labelPayer === Payer.MERCHANT}
        />
      </LabeledInput>
      {team?.settings?.warranties?.repairFeeEnabled && (
        <LabeledInput
          description="Repair fees will be displayed to the customer."
          label="Display repair fee"
        >
          <Switch
            disabled={!canEditSettings}
            onChange={handleChargeFee}
            value={state.option.chargeFee}
          />
        </LabeledInput>
      )}
    </>
  );
});

export const SUBMIT_REPAIR: StepType<State, ModelStep.SubmitRepair> = {
  Details,
  customTitle: (state) => state.customTitle,
  title: "Submit Repair",
  valid: () => true,
  Icon: ToolIcon,
  description(state: State) {
    return "Submit a repair request";
  },
  downstream() {
    return [];
  },
  empty: { option: { labelPayer: Payer.CUSTOMER, chargeFee: true } },
  layout() {
    return BlockLayout.FULL;
  },
  stepDeleted(state) {
    return state;
  },
  toModel(state: State): ModelStep.SubmitRepair {
    return {
      customTitle: state.customTitle,
      type: ModelStep.SUBMIT_REPAIR,
      option: {
        labelPayer: state.option.labelPayer,
        chargeFee: state.option.chargeFee,
      },
    };
  },
  fromModel(model: ModelStep.SubmitRepair): State {
    return {
      customTitle: model.customTitle,
      option: {
        labelPayer: model.option?.labelPayer ?? Payer.CUSTOMER,
        chargeFee: model.option?.chargeFee ?? true,
      },
    };
  },
};
