import { Team } from "@redotech/redo-model/team";

export enum FeatureOnboardingType {
  PACKAGE_PROTECTION_PLUS = "package_protection_plus",
  EXPANDED_WARRANTY = "extended_warranty",
  NEW_NAVIGATION = "new_navigation",
  // Special state
  DISABLED = "disabled",
}

export interface FeatureOnboardingDefinition {
  type: FeatureOnboardingType;
  isEligible: (team: Team) => boolean;
  priority: number;
  retries?: number;
}

export function isEligibleForPackageProtectionPlus(team: Team): boolean {
  return (
    team.settings.crossSellPopoutEnabled === true &&
    !team.settings.packageProtection?.packageProtectionPlusEnabled
  );
}

export function isEligibleForExpandedWarranty(team: Team): boolean {
  return (
    team.settings.warranties?.enabled === true &&
    !team.settings.extendedWarranties?.enabled
  );
}

export const featureOnboardingDefinitions: Record<
  Exclude<FeatureOnboardingType, FeatureOnboardingType.DISABLED>,
  FeatureOnboardingDefinition
> = {
  [FeatureOnboardingType.NEW_NAVIGATION]: {
    type: FeatureOnboardingType.NEW_NAVIGATION,
    isEligible: () => true,
    priority: 1,
  },
  [FeatureOnboardingType.PACKAGE_PROTECTION_PLUS]: {
    type: FeatureOnboardingType.PACKAGE_PROTECTION_PLUS,
    isEligible: isEligibleForPackageProtectionPlus,
    priority: 3,
    retries: 2,
  },
  [FeatureOnboardingType.EXPANDED_WARRANTY]: {
    type: FeatureOnboardingType.EXPANDED_WARRANTY,
    isEligible: isEligibleForExpandedWarranty,
    priority: 3,
    retries: 2,
  },
};
