import { genericMemo } from "@redotech/react-util/component";
import { LoadState, useTriggerLoad } from "@redotech/react-util/load";
import { useSearch } from "@redotech/react-util/search";
import {
  ArrayStringFilterOperator,
  valuelessOperators,
} from "@redotech/redo-model/views/advanced-filters/array-string-filter";
import Fuse from "fuse.js";
import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "usehooks-ts";
import {
  RedoFilterDropdownAnchor,
  RedoFilterGroup,
} from "../../arbiter-components/filter-group/redo-filter-group";
import { RedoTextInput } from "../../arbiter-components/input/redo-text-input";
import { RedoListItem } from "../../arbiter-components/list/redo-list";
import { RedoMultiselectDropdown } from "../../arbiter-components/list/redo-multiselect-dropdown";
import { SimpleFilterDropdown } from "../../filters/simple-filter-dropdown";
import { Flex } from "../../flex";
import { Text } from "../../text";
import {
  AdvancedTableFilter,
  ArrayStringTableFilter,
} from "../advanced-filter";
import * as styles from "./filter.module.css";

function useValueOptions({
  filter,
  searchString,
  filters,
}: {
  filter: ArrayStringTableFilter;
  searchString: string;
  filters: AdvancedTableFilter[];
}): LoadState<string[] | undefined> {
  const [fetchedValueOptions, fetchValueOptions] = useTriggerLoad(
    async (signal) => {
      if (filter.valueFetcher) {
        const body: {
          filters: AdvancedTableFilter[];
          filterSearch?: string;
          applyTableState?: boolean;
        } = {
          filters: filters.filter(
            (otherFilter) => filter.data.name !== otherFilter.data.name,
          ),
          applyTableState: filter.applyTableState,
        };

        if (filter.dbSearch) {
          body.filterSearch = searchString;
        }

        return await filter.valueFetcher(body, signal);
      } else {
        return filter.values ?? [];
      }
    },
  );

  const stableDefault = useMemo(() => {
    return { pending: false, error: false, value: filter.values ?? [] };
  }, [filter.values]);

  useEffect(() => {
    fetchValueOptions();
  }, [fetchValueOptions]);

  useEffect(() => {
    if (filter.dbSearch) {
      fetchValueOptions();
    }
  }, [filter.dbSearch, fetchValueOptions, searchString]);

  if (!filter.valueFetcher) {
    return stableDefault;
  }

  return fetchedValueOptions;
}

export const ArrayStringFilterGroup = genericMemo(
  function ArrayStringFilterGroup({
    filter,
    setFilter,
    removeFilter,
    openOnRender,
    filters,
  }: {
    filter: ArrayStringTableFilter;
    setFilter(filter: ArrayStringTableFilter): void;
    removeFilter(): void;
    openOnRender: boolean;
    filters: AdvancedTableFilter[];
  }) {
    const [valueRef, setValueRef] = useState<HTMLButtonElement | null>(null);

    const { value, operator } = filter.data;

    const [searchText, setSearchText] = useState<string>("");

    const searcher = useMemo(
      () =>
        new Fuse<RedoListItem<string>>([], { keys: ["value"], threshold: 0.3 }),
      [],
    );

    const debouncedFilterSearch = useDebounce(searchText, 300);

    const valueOptions = useValueOptions({
      filter,
      searchString: debouncedFilterSearch,
      filters,
    });

    useEffect(() => {
      if (openOnRender && valueRef) {
        valueRef.click();
      }
    }, [openOnRender, valueRef]);

    const pluralLabel = filter.itemLabelPlural ?? `${filter.itemLabel}s`;

    const valueText = value
      ? value.length === 1
        ? `1 ${filter.itemLabel}`
        : ` ${value.length} ${pluralLabel}`
      : "...";

    const valueInput = (
      <RedoTextInput
        className={styles.stringInput}
        fullWidth={false}
        placeholder={value?.[0] ? getDisplayString(value[0], filter) : "..."}
        setValue={(value) => {
          setFilter({
            ...filter,
            data: { ...filter.data, value: [getStringValue(value, filter)] },
          });
        }}
        state="default"
        value={value?.[0] != null ? getStringValue(value[0], filter) : ""}
      />
    );

    const valueDropdownAnchor = (
      <RedoFilterDropdownAnchor
        color="primary"
        onClick={() => {
          setValueDropdownOpen(!valueDropdownOpen);
        }}
        ref={setValueRef}
        text={valueText}
        tooltip={
          value &&
          value.length > 0 && (
            <Flex dir="column">
              {value.map((item) => (
                <Text key={item}>{getDisplayString(item, filter)}</Text>
              ))}
            </Flex>
          )
        }
        weight="medium"
      />
    );

    const selectedItems = useMemo(() => {
      if (!value) {
        return [];
      }
      return value.map((item) => getStringValue(item, filter));
    }, [value, filter]);

    const multiSelectOptions: RedoListItem<string>[] = useMemo(() => {
      if (!valueOptions.value) {
        return [];
      }

      if (filter.itemGenerator) {
        return valueOptions.value.map(filter.itemGenerator);
      }

      return valueOptions.value.map<RedoListItem<string>>((item) => ({
        id: getStringValue(item, filter),
        // Under normal circumstances, we would want this to be getStringValue,
        // but because of the custom render function we need a consistent reference to search by
        value: getDisplayString(item, filter),
        type: "text",
        text: getDisplayString(item, filter),
      }));
    }, [valueOptions, filter]);

    const selectedListItems: RedoListItem<string>[] = useMemo(() => {
      if (filter.itemGenerator) {
        return selectedItems.map(filter.itemGenerator);
      }

      return selectedItems.map<RedoListItem<string>>((item) => ({
        id: getStringValue(item, filter),
        // Under normal circumstances, we would want this to be getStringValue,
        // but because of the custom render function we need a consistent reference to search by
        value: getDisplayString(item, filter),
        type: "text",
        text: getDisplayString(item, filter),
      }));
    }, [selectedItems, filter]);

    const placeholder = filter.itemLabelPlural
      ? `Search ${filter.itemLabelPlural}...`
      : `Search ${filter.itemLabel}s...`;

    const searchedItems = useSearch(
      searcher,
      multiSelectOptions,
      debouncedFilterSearch,
    );

    const [valueDropdownOpen, setValueDropdownOpen] = useState(false);

    const valueDropdown = (
      <RedoMultiselectDropdown
        dropdownAnchor={valueRef}
        dropdownOpen={valueDropdownOpen}
        items={searchedItems}
        itemsLoading={valueOptions.pending}
        searchPlaceholder={placeholder}
        searchString={searchText}
        selectedItems={selectedListItems}
        setDropdownOpen={setValueDropdownOpen}
        setSearchString={setSearchText}
        setSelectedItems={(items) => {
          const selectedItems = items.map((item) => item.id) as string[];
          setFilter({
            ...filter,
            data: { ...filter.data, value: selectedItems },
          });
        }}
      />
    );

    return (
      <>
        {inputOperators.includes(operator) ? undefined : valueDropdown}
        <RedoFilterGroup
          Icon={filter.Icon}
          propertyName={filter.displayName}
          query={
            <SimpleFilterDropdown
              filterStyle="query"
              options={filter.operators || defaultOperators}
              optionToFriendlyName={(value) => arrayStringTypeToText[value]}
              setValue={(value) => {
                let shouldResetValue = false;
                if (hasOperatorTypeChanged(operator, value)) {
                  // we just switched operator input types, so we should reset the value
                  shouldResetValue = true;
                }

                setFilter({
                  ...filter,
                  data: {
                    ...filter.data,
                    operator: value,
                    value: shouldResetValue ? [] : filter.data.value,
                  },
                });
              }}
              value={operator}
            />
          }
          removeFilter={removeFilter}
          value={
            valuelessOperators.includes(operator)
              ? null
              : inputOperators.includes(operator)
                ? valueInput
                : valueDropdownAnchor
          }
        />
      </>
    );
  },
);

const arrayStringTypeToText: Record<ArrayStringFilterOperator, string> = {
  [ArrayStringFilterOperator.CONTAINS]: "contains",
  [ArrayStringFilterOperator.NOT_CONTAINS]: "does not contain",
  [ArrayStringFilterOperator.ANY_OF]: "include any of",
  [ArrayStringFilterOperator.NONE_OF]: "include none of",
  [ArrayStringFilterOperator.ALL_OF]: "have all of",
  [ArrayStringFilterOperator.IS_EMPTY]: "is empty",
  [ArrayStringFilterOperator.IS_NOT_EMPTY]: "is not empty",
};

const inputOperators = [
  ArrayStringFilterOperator.CONTAINS,
  ArrayStringFilterOperator.NOT_CONTAINS,
];

function getStringValue(item: any, filter: ArrayStringTableFilter): string {
  return filter.stringify && item != null
    ? filter.stringify(item)
    : item != null
      ? item.toString()
      : "";
}

function getDisplayString(item: any, filter: ArrayStringTableFilter): string {
  return filter.display && item
    ? filter.display(item)
    : item
      ? item.toString()
      : "";
}

const defaultOperators = [
  ArrayStringFilterOperator.ANY_OF,
  ArrayStringFilterOperator.NONE_OF,
  ArrayStringFilterOperator.ALL_OF,
  ArrayStringFilterOperator.CONTAINS,
  ArrayStringFilterOperator.NOT_CONTAINS,
];

function hasOperatorTypeChanged(
  before: ArrayStringFilterOperator,
  after: ArrayStringFilterOperator,
): boolean {
  const arrays = [inputOperators, valuelessOperators];

  const beforeIncluded = arrays.some((arr) => arr.includes(before));
  const afterIncluded = arrays.some((arr) => arr.includes(after));

  return beforeIncluded !== afterIncluded;
}
