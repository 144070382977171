import {
  ReturnStatus,
  returnStatusToBadgeColor,
  returnStatusToDisplayText,
} from "@redotech/redo-model/return-status";
import { RedoBadge } from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import { memo } from "react";
import { BadgePillThemeToColorMapping } from "../../../../support/conversation-tags/conversation-tag-pill";

/**
 * Returns and claims are have the same set of statuses. Feel free to split this out if that changes.
 */
export const ReturnClaimStatusBadge = memo(function ReturnClaimStatusBadge({
  returnClaimStatus,
}: {
  returnClaimStatus: ReturnStatus;
}) {
  const getBadgeText = (): string => {
    return returnStatusToDisplayText[returnClaimStatus];
  };

  return (
    <RedoBadge
      color={
        BadgePillThemeToColorMapping[
          returnStatusToBadgeColor[returnClaimStatus]
        ]
      }
      size="sm"
      text={getBadgeText()}
    />
  );
});
