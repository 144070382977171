import type { RedoConversionRpcClient } from "@redotech/merchant-sdk/conversion-rpc/client";
import { createRedoConversionRpcClient } from "@redotech/merchant-sdk/conversion-rpc/client";
import { Context, createContext, ReactNode, useMemo } from "react";

export const RedoConversionRpcClientContext: Context<
  RedoConversionRpcClient | undefined
> = createContext<RedoConversionRpcClient | undefined>(undefined);
RedoConversionRpcClientContext.displayName = "RedoConversionRpcClientContext";

export function RedoConversionRpcClientProvider({
  children,
  baseUrl,
  authToken,
}: {
  children: ReactNode | ReactNode[];
  baseUrl: string;
  authToken?: string;
}) {
  const redoConversionRpcClient = useMemo(() => {
    if (!authToken) {
      return undefined;
    }
    return createRedoConversionRpcClient({
      baseURL: new URL(`${baseUrl}/conversion-rpc`),
      headers: { Authorization: authToken },
    });
  }, [baseUrl, authToken]);
  return (
    <RedoConversionRpcClientContext.Provider value={redoConversionRpcClient}>
      {children}
    </RedoConversionRpcClientContext.Provider>
  );
}
