// extracted by mini-css-extract-plugin
export var border = "_9-5";
export var button = "_9-g";
export var center = "_9-7";
export var centered = "_9-3";
export var closeButton = "_9-9";
export var disabledWrapper = "_9-h";
export var error = "_9-k";
export var fill = "_9-d";
export var footer = "_9-c";
export var footerAction = "_9-e";
export var footerButtons = "_9-f";
export var header = "_9-4";
export var icon = "_9-j";
export var info = "_9-n";
export var inline = "_9-6";
export var large = "_9-2";
export var main = "_9-a";
export var modal = "_9-0";
export var neutral = "_9-o";
export var none = "_9-p";
export var scrollable = "_9-b";
export var small = "_9-1";
export var success = "_9-m";
export var tight = "_9-i";
export var titleIcon = "_9-8";
export var warn = "_9-l";