import * as classNames from "classnames";
import { JSXElementConstructor, memo } from "react";
import * as iconCss from "./redo-icon.module.css";

const redoIconColor = [
  "primary",
  "secondary",
  "tertiary",
  "quaternary",
  "quinary",
  "disabled",
  "error",
  "warning",
  "success",
  "inherit",
] as const;
export type RedoIconColor = (typeof redoIconColor)[number];

export interface RedoIconProps {
  color?: RedoIconColor;
  size?: number;
  Icon: JSXElementConstructor<any>;
  className?: string;
}

export const RedoIcon = memo(function RedoIcon({
  color = "inherit",
  size = 12,
  Icon,
  className,
}: RedoIconProps) {
  return (
    <Icon
      className={classNames(iconCss.icon, colorToCss[color], className)}
      style={{ height: `${size}px`, width: `${size}px` }}
    />
  );
});

const colorToCss: Record<RedoIconColor, string> = {
  primary: iconCss.primary,
  secondary: iconCss.secondary,
  tertiary: iconCss.tertiary,
  quaternary: iconCss.quaternary,
  quinary: iconCss.quinary,
  disabled: iconCss.disabled,
  error: iconCss.error,
  warning: iconCss.warning,
  success: iconCss.success,
  inherit: iconCss.inherit,
};
