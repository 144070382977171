import { RedoSecondaryNavItem } from "@redotech/redo-web/arbiter-components/navigation/secondary/redo-secondary-nav-item";
import DataFlow from "@redotech/redo-web/arbiter-icon/dataflow-03.svg";
import DataFlowFilled from "@redotech/redo-web/arbiter-icon/dataflow-03_filled.svg";
import { memo } from "react";

export const AutomationsItems = memo(function AutomationsItems({
  marketingRoot,
}: {
  marketingRoot: string;
}) {
  return (
    <RedoSecondaryNavItem
      action={{ type: "link", href: `${marketingRoot}/automations` }}
      FilledIcon={DataFlowFilled}
      friendlyName="Automations"
      id="automations"
      UnfilledIcon={DataFlow}
    />
  );
});
