import { MarketingChannel } from "@redotech/redo-model/marketing";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const setCustomerSuppressed = {
  input: z.object({
    customerId: zExt.objectId(),
    action: z.enum(["suppress", "unsuppress"]),
    channel: z.nativeEnum(MarketingChannel),
  }),
  output: z.object({}),
};
