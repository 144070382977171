import {
  Call,
  CallState,
} from "@redotech/redo-model/support/voice/voice-types";
import PhoneCallIcon from "@redotech/redo-web/arbiter-icon/phone-call.svg";
import PhoneXIcon from "@redotech/redo-web/arbiter-icon/phone-x.svg";
import { memo, useContext } from "react";
import { RedoButton } from "../arbiter-components/buttons/redo-button";
import { Flex } from "../flex";
import { REDO_RINGTONE } from "../sound/sounds";
import { CallProgressView } from "./call-progress";
import { VoiceContext } from "./voice-context";

export const InboundCall = memo(function CallRingingBody({
  call,
}: {
  call: Call;
}) {
  const { rejectCall, answerCall } = useContext(VoiceContext);

  const disable = call.state === CallState.CONNECTING;

  const handleRejectCall = () => {
    if (call.state === CallState.RINGING) {
      rejectCall();
      REDO_RINGTONE.pause();
      REDO_RINGTONE.currentTime = 0;
    }
  };

  return (
    <Flex dir="column" gap="xl" p="2xl">
      <CallProgressView call={call} />
      <Flex justify="center">
        <RedoButton
          disabled={disable}
          hierarchy="primary"
          IconTrailing={PhoneCallIcon}
          onClick={answerCall}
          text="Answer"
          theme="success"
        />
        <RedoButton
          disabled={disable}
          hierarchy="primary"
          IconTrailing={PhoneXIcon}
          onClick={handleRejectCall}
          text="Ignore"
          theme="destructive"
        />
      </Flex>
    </Flex>
  );
});
