import { ClickAwayListener, Popper } from "@mui/base";

import { useRequiredContext } from "@redotech/react-util/context";
import {
  applyDynamicVariableSyntax,
  DynamicVariable,
} from "@redotech/redo-model/advanced-flow/schemas/schemas";
import { SchemaField } from "@redotech/redo-model/advanced-flow/type-system/schema";
import { sanitizeUrl } from "@redotech/util/link";
import { assertNever, Value } from "@redotech/util/type";
import ResizeModule from "@ssumo/quill-resize-module";
import * as classNames from "classnames";
import Quill from "quill";
import MagicUrl from "quill-magic-url";
import { Delta, Parchment, Range } from "quill/core";
import Emitter from "quill/core/emitter";
import * as React from "react";
import {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { RedoColorPicker } from "../arbiter-components/color-picker/redo-color-picker";
import { DynamicVariableSelectionModal } from "../dynamic-variable-selection-modal";
import {
  BaseEmoji,
  CustomEmoji,
  EmojiSelectionModal,
  EmojiSelectionModalCloseCause,
} from "../emoji-selection-modal";
import { ThemeContext } from "../theme-provider";
import * as quillEditorCss from "./quill-editor.module.css";
import { QuillLink } from "./quill-link";
import { QuillLinkPopup } from "./quill-link-popup";

export const quillFakeCursorPadding = 2;

export type OnEditorChangeArgs =
  | [typeof Quill.events.TEXT_CHANGE, Delta, Delta, string]
  | [typeof Quill.events.SELECTION_CHANGE, Range, Range, string];

export type QuillEditorProps = {
  /** Either the toolbar element's id (without #) or an HTMLDivElement
   * referring to the toolbar directly. It's useful to pass as an element
   * to allow Quill to reinitialize the toolbar when the toolbar re-renders.
   */
  toolbar?: string | HTMLDivElement;
  readOnly?: boolean;
  defaultValue?: any;

  /** Called when the text changes. https://quilljs.com/docs/api#text-change */
  onTextChange?: (delta: Delta, oldContents: Delta, source: string) => void;

  /** Called when the selection changes. https://quilljs.com/docs/api#selection-change */
  onSelectionChange?: (
    range: { index: number; length: number } | null,
    oldRange: { index: number; length: number } | null,
    source: string,
  ) => void;

  /** Called when the selection or text changes, even if they changed silently,
   * as in the case of the selection-change event fired after a text-change.
   * https://quilljs.com/docs/api#editor-change */
  onEditorChange?: (args: OnEditorChangeArgs) => void;

  placeholder?: string;
  onQuillEditorEmptyChange?: (empty: boolean) => void;

  cursorIndexRef?: React.MutableRefObject<number | undefined>;
  setEmojiPickerOpenRef?: React.MutableRefObject<
    React.Dispatch<React.SetStateAction<boolean>> | undefined
  >;

  /** HTML element to anchor the emoji picker to. If not provided, the emoji picker will be anchored
   * to the cursor if opened via hotkey and to the emoji button if opened via click.
   */
  emojiPickerAnchorOverride?: HTMLElement | null;

  /**
   * To make font size portable, you may want the output of the editor to be inline styles instead of classes.
   * Everything except the settings -> email builder's text field works this way.
   */
  inlineFontSizeStyles?: boolean;

  /**
   * Class name to apply to the editor container.
   */
  editorClassName?: string;

  /**
   * Styles to apply to the editor container.
   */
  editorStyles?: CSSProperties;

  /**
   * Id to apply to the editor container. Useful for CSS targeting.
   */
  editorId?: string;

  /**
   * Allow pasting images and videos.
   */
  allowEmbeds?: boolean;

  /**
   * Whitelisted font families.
   */
  fontFamilies?: readonly string[];

  /**
   * Available dynamic variables.
   */
  dynamicVariables?: readonly SchemaField[];
};

// quill editor v2 internally displays all lists as <ol> elements, then converts them to <ul> when we callGetSemanticHTML()
// when we plug them back into quill after saving them to the db, we need to convert back to <ol> elements with data-list="bullet" attribute
// see https://github.com/slab/quill/issues/3957
export function convertToQuillHTML(html: string) {
  const container = document.createElement("div");
  container.innerHTML = html;
  const ulElements = container.querySelectorAll("ul");
  ulElements.forEach((ul) => {
    const ol = document.createElement("ol");
    const liElements = ul.querySelectorAll("li");
    liElements.forEach((li) => {
      const newLi = document.createElement("li");
      newLi.setAttribute("data-list", "bullet");
      newLi.innerHTML = li.innerHTML;
      ol.appendChild(newLi);
    });
    ul.replaceWith(ol);
  });
  return container.innerHTML;
}

const DEFAULT_QUILL_FONT_FAMILIES = [
  "monospace",
  "serif",
  "sans-serif",
  "arial",
  "courier-new",
  "georgia",
  "lucida-sans",
  "tahoma",
  "times-new-roman",
  "verdana",
] as const;

const QUILL_FORMATS_EXCEPT_EMBEDS = [
  "background",
  "bold",
  "color",
  "font",
  "code",
  "italic",
  "link",
  "size",
  "strike",
  "script",
  "underline",
  "blockquote",
  "header",
  "indent",
  "list",
  "align",
  "direction",
  "code-block",
];

export const QUILL_FONT_SIZES = [
  "8",
  "10",
  "11",
  "12",
  "14",
  "16",
  "20",
  "24",
  "36",
];

/**
 * The text area alongside a quill instance that fired text changed callbacks.
 * Customize your editor's behavior by grabbing the quill instance from the ref.
 */
export const QuillEditor = forwardRef<Quill, QuillEditorProps>(
  function QuillEditor(
    {
      toolbar,
      readOnly,
      defaultValue,
      onTextChange,
      onSelectionChange,
      onEditorChange,
      placeholder,
      onQuillEditorEmptyChange,

      cursorIndexRef,
      setEmojiPickerOpenRef,

      inlineFontSizeStyles: inlineFontStyles = true,
      editorClassName,
      editorStyles,
      editorId,
      emojiPickerAnchorOverride,
      dynamicVariables,
      allowEmbeds = true,
      fontFamilies = DEFAULT_QUILL_FONT_FAMILIES,
    }: QuillEditorProps,
    ref: ForwardedRef<Quill>,
  ) {
    const containerRef = useRef(null);
    const fakeCursorRef = useRef<HTMLDivElement>(null);
    const defaultValueRef = useRef(defaultValue);

    const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
    setEmojiPickerOpenRef &&
      (setEmojiPickerOpenRef.current = setEmojiPickerOpen);
    const emojiButtonRef = useRef<HTMLButtonElement | null>(null);
    const [emojiPickerOpenedWithHotkey, setEmojiPickerOpenedWithHotkey] =
      useState(false);

    const [linkPopupOpen, setLinkPopupOpen] = useState(false);
    const linkButtonRef = useRef<HTMLButtonElement | null>(null);

    const [colorPickerOpen, setColorPickerOpen] = useState(false);
    const colorButtonRef = useRef<HTMLButtonElement | null>(null);
    const [quillColor, setQuillColor] = useState<string | undefined>();

    const [dynamicVariablePickerOpen, setDynamicVariablePickerOpen] =
      useState(false);
    const dynamicVariableButtonRef = useRef<HTMLButtonElement | null>(null);

    const { theme } = useRequiredContext(ThemeContext);

    const quillRef = useRef<Quill | null>(null);
    const [cursorIndex, setCursorIndex] = useState(0);

    useEffect(() => {
      if (containerRef.current == null) {
        return;
      }
      const container = containerRef.current as HTMLElement;

      // Register fonts
      const Font: any = Quill.import("formats/font");
      Font.whitelist = fontFamilies;
      Quill.register(Font, true);

      // Use inline styling for font size instead of classes
      if (inlineFontStyles) {
        const SizeStyle: any = Quill.import("attributors/style/size");
        SizeStyle.whitelist = QUILL_FONT_SIZES.map((size) => `${size}px`);
        Quill.register(SizeStyle, true);
      }

      Quill.register("modules/resize", ResizeModule as any);
      Quill.register(QuillLink, true);
      Quill.register("modules/magicUrl", MagicUrl as any);

      const quill = new Quill(container, {
        placeholder: readOnly ? "" : placeholder || "",
        theme: "snow",
        readOnly: !!readOnly,
        modules: {
          toolbar: !toolbar
            ? false
            : {
                container:
                  typeof toolbar === "string" ? `#${toolbar}` : toolbar,
                handlers: {
                  "dynamic-variables": function (value: any) {
                    const range = quill.getSelection();
                    if (range) quill.insertText(range.index, value);
                  },
                },
              },

          resize: { locale: {} },
          clipboard: {
            matchers: [
              [
                "img",
                (node: HTMLImageElement, delta: Delta) => {
                  if (node.style.width) {
                    delta.ops[0].attributes = delta.ops[0].attributes || {};
                    delta.ops[0].attributes.width = node.style.width;
                  }
                  if (node.style.height) {
                    delta.ops[0].attributes = delta.ops[0].attributes || {};
                    delta.ops[0].attributes.height = node.style.height;
                  }
                  return delta;
                },
              ],
            ],
          },
          magicUrl: true,
        },
        // Explicitly allowing everything but embeds implicitly disallows embeds.
        formats: allowEmbeds ? null : QUILL_FORMATS_EXCEPT_EMBEDS,
      });
      quillRef.current = quill;
      cursorIndexRef && (cursorIndexRef.current = 0);

      // Pass Quill instance to parent via forwarded ref
      if (ref) {
        if (typeof ref === "function") {
          ref(quill);
        } else {
          ref.current = quill;
        }
      }

      // Attach listeners for quill events
      const quillListeners: [
        Value<(typeof Emitter)["events"]>,
        (...args: any) => void,
      ][] = [];

      function handleSelectionChange(range: Range) {
        if (!range) return;
        cursorIndexRef && (cursorIndexRef.current = range.index);
        setCursorIndex(range.index);
        setQuillColor(quill.getFormat(range.index).color as string | undefined);
      }
      quillListeners.push(["selection-change", handleSelectionChange]);
      quillListeners.push(["editor-change", handleEditorChange]);

      quillListeners.push([
        "editor-change",
        (...args) => {
          onQuillEditorEmptyChange?.(quill.getLength() <= 1);
        },
      ]);

      // Attach listeners to toolbar buttons and store refs to toolbar buttons
      const toolbarListeners: [
        HTMLElement,
        keyof HTMLElementEventMap,
        EventListenerOrEventListenerObject,
      ][] = [];
      const toolbarElement =
        typeof toolbar === "string"
          ? document.querySelector(`#${toolbar}`)
          : toolbar;

      const emojiButton = toolbarElement?.querySelector(`.rql-emoji`) as
        | HTMLButtonElement
        | null
        | undefined;
      if (emojiButton) {
        emojiButtonRef.current = emojiButton;
        toolbarListeners.push([emojiButton, "click", openEmojiPickerOnClick]);
      }

      const linkButton = toolbarElement?.querySelector(`.rql-link`) as
        | HTMLButtonElement
        | null
        | undefined;
      if (linkButton) {
        linkButtonRef.current = linkButton;
        toolbarListeners.push([linkButton, "click", openLinkPopupOnClick]);
      }

      const colorButton = toolbarElement?.querySelector(
        `.rql-color`,
      ) as HTMLButtonElement;
      if (colorButton) {
        colorButtonRef.current = colorButton;
        toolbarListeners.push([colorButton, "click", openColorPickerOnClick]);
      }

      const dynamicVariableButton = toolbarElement?.querySelector(
        `.rql-dynamic-variable`,
      ) as HTMLButtonElement;
      if (dynamicVariableButton) {
        dynamicVariableButtonRef.current = dynamicVariableButton;
        toolbarListeners.push([
          dynamicVariableButton,
          "click",
          openDynamicVariablePickerOnClick,
        ]);
      }

      if (defaultValueRef.current) {
        quill.setContents(defaultValueRef.current);
      }

      quill.root.addEventListener(
        "paste",
        function (e) {
          const clipboard = e.clipboardData;
          const pastedHTML = clipboard?.getData("text/html");
          const pastedText = clipboard?.getData("text/plain");

          if (pastedText) {
            // Magic URL doesn't work with pasted in urls for quill 2.0 so we will handle those ourselves here
            const urlRegex =
              /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/i;
            if (urlRegex.test(pastedText.trim())) {
              e.preventDefault();
              const currentSelection = quill.getSelection();
              if (currentSelection) {
                quill.deleteText(
                  currentSelection.index,
                  currentSelection.length,
                );
                quill.insertText(
                  currentSelection.index,
                  pastedText,
                  { link: pastedText },
                  Quill.sources.USER,
                );
                quill.setSelection(
                  currentSelection.index + pastedText.length,
                  0,
                );
              }
            }
          }

          const selection = quill.getSelection();
          if (!selection || !pastedHTML) return;

          const [blot] = quill.getLeaf(selection.index);
          if (!blot) return;

          const tempElement = document.createElement("div");
          tempElement.innerHTML = pastedHTML;

          let contentNode = null;

          // Iterate through the nodes to find the actual content (skip meta tags)
          for (let i = 0; i < tempElement.childNodes.length; i++) {
            const node = tempElement.childNodes[i];
            if (node.nodeName.toLowerCase() !== "meta") {
              contentNode = node;
              break;
            }
          }

          if (contentNode && shouldSeparateNode(contentNode, blot)) {
            const currentPos = selection.index;
            const currentLine = quill.getLine(currentPos);

            // Insert a new line before the pasted content if we're not at the beginning of a line
            if (currentPos > 0 && currentLine[1] > 0) {
              quill.insertText(currentPos, "\n");
            }
          }
        },
        true,
      );

      function shouldSeparateNode(node: Node, blot: Parchment.LeafBlot) {
        const nodeName = node.nodeName.toLowerCase();

        // Block level elements that should trigger separation
        const blockElements = [
          "p",
          "h1",
          "h2",
          "h3",
          "h4",
          "h5",
          "h6",
          "div",
          "blockquote",
          "pre",
          "ul",
          "ol",
          "li",
        ];

        if (!blockElements.includes(nodeName)) {
          return false;
        }

        let parentBlot = blot.parent;

        // Navigate up the blot hierarchy to find the block-level blot
        while (
          parentBlot &&
          !(
            parentBlot.statics &&
            (parentBlot.statics.blotName === "block" ||
              blockElements.includes(
                String(parentBlot.statics.tagName).toLowerCase(),
              ))
          )
        ) {
          parentBlot = parentBlot.parent;
        }

        if (!parentBlot || !parentBlot.domNode) {
          return true;
        }

        const currentTagName = parentBlot.domNode.nodeName.toLowerCase();

        if (currentTagName !== nodeName) {
          if (currentTagName.startsWith("h") && nodeName.startsWith("h")) {
            const currentLevel = parseInt(currentTagName.substring(1), 10);
            const newLevel = parseInt(nodeName.substring(1), 10);
            return currentLevel !== newLevel;
          }

          if (
            (currentTagName === "ul" && nodeName === "ol") ||
            (currentTagName === "ol" && nodeName === "ul")
          ) {
            return true;
          }
          return true;
        }
        return false;
      }

      for (const [event, callback] of quillListeners) {
        quill.on(event, callback);
      }
      for (const [element, event, callback] of toolbarListeners) {
        element.addEventListener(event, callback);
      }
      return () => {
        for (const [event, callback] of quillListeners) {
          quill.off(event, callback);
        }
        for (const [element, event, callback] of toolbarListeners) {
          element.removeEventListener(event, callback);
        }
        if (typeof ref === "function") {
          ref(null);
        } else if (ref) {
          ref.current = null;
        }
        container.innerHTML = "";
      };
      // FIXME
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, readOnly, toolbar]);

    // Attach onTextChange
    useEffect(() => {
      const quill = quillRef.current;
      if (!quill || !onTextChange) return;
      quill.on("text-change", onTextChange);
      return () => {
        quill.off("text-change", onTextChange);
      };
    }, [onTextChange]);

    // Attach onSelectionChange
    useEffect(() => {
      const quill = quillRef.current;
      if (!quill || !onSelectionChange) return;
      quill.on("selection-change", onSelectionChange);
      return () => {
        quill.off("selection-change", onSelectionChange);
      };
    }, [onSelectionChange]);

    // Attach onEditorChange
    useEffect(() => {
      const quill = quillRef.current;
      if (!quill || !onEditorChange) return;
      quill.on("editor-change", onEditorChange);
      return () => {
        quill.off("editor-change", onEditorChange);
      };
    }, [onEditorChange]);

    function openEmojiPickerOnClick(event: Event) {
      event.stopPropagation();
      setEmojiPickerOpen((emojiPickerOpen) => {
        if (!emojiPickerOpen) setEmojiPickerOpenedWithHotkey(false);
        return !emojiPickerOpen;
      });
    }
    function openLinkPopupOnClick() {
      setLinkPopupOpen((open) => {
        if (open) {
          quillRef.current?.focus();
        } else {
          if (!quillRef.current?.selection.savedRange?.length) {
            return false;
          }
        }
        return !open;
      });
    }
    function openColorPickerOnClick() {
      setColorPickerOpen((open) => {
        if (open) {
          quillRef.current?.focus();
        }
        return !open;
      });
    }
    function openDynamicVariablePickerOnClick() {
      setDynamicVariablePickerOpen((open) => {
        if (open) {
          quillRef.current?.focus();
        }
        return !open;
      });
    }

    function handleEditorChange(...args: OnEditorChangeArgs) {
      const [name, ...rest] = args;
      if (name === Quill.events.SELECTION_CHANGE) {
        const range = rest[0] as Range;
        if (range?.index !== undefined) {
          cursorIndexRef && (cursorIndexRef.current = range.index);
          setCursorIndex(range.index);
        }
      }
    }

    const insertTextAndUpdateCursorIndex = useCallback(
      ({ text, link }: { text: string; link?: string }) => {
        const quill = quillRef.current;
        if (!quill) return;
        const selection = quill.getSelection();

        if (selection && selection.length) {
          quill.deleteText(cursorIndex, selection.length);
        }
        quill.insertText(cursorIndex, text, "link", link, Quill.sources.USER);

        const newCursorIndex = cursorIndex + text.length;
        quill.setSelection(newCursorIndex, 0);
        cursorIndexRef && (cursorIndexRef.current = newCursorIndex);
        setCursorIndex(newCursorIndex);
      },
      [setCursorIndex, cursorIndex, cursorIndexRef],
    );

    function handleEmojiSelected(emoji: BaseEmoji | CustomEmoji) {
      if (!("native" in emoji)) return; // Rule out CustomEmoji
      insertTextAndUpdateCursorIndex({ text: emoji.native });
    }

    function handleHotkey(event: React.KeyboardEvent) {
      if (!event.ctrlKey && !event.metaKey) {
        return;
      }
      if (event.key === "3") {
        setEmojiPickerOpenedWithHotkey(true);
        setEmojiPickerOpen(true);
        event.preventDefault();
      }
    }

    function closeEmojiPicker(cause: EmojiSelectionModalCloseCause) {
      setEmojiPickerOpenedWithHotkey(false);
      setEmojiPickerOpen(false);
      if (cause !== "clickOutside") {
        quillRef.current?.focus();
      }
    }

    function closeColorPicker() {
      setColorPickerOpen(false);
      const quill = quillRef.current;
      if (!quill) {
        return;
      }

      // Insert and then delete a zero-width space wherever the selection is
      // (otherwise no text-change event gets fired)
      quill.insertText(quill.getSelection()?.index || 0, "\u200B");
      quill.deleteText(quill.getSelection()?.index || 0, 1);
    }

    const handleColorPicked = useCallback((color: string) => {
      setQuillColor(color);
      const quill = quillRef.current;
      if (!quill) return;
      formatQuillWithoutFocusingRisky(quill, "color", color);
    }, []);

    const handleColorPickerKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter" || event.key === "Escape") {
          closeColorPicker();
          quillRef.current?.focus();
          // Don't let the enter press go through to quill
          event.preventDefault();
        }
      },
      [],
    );

    const handleDynamicVariableSelected = useCallback(
      (variable: DynamicVariable) => {
        if (variable.type === "text") {
          insertTextAndUpdateCursorIndex({
            text: applyDynamicVariableSyntax(variable),
          });
        } else if (variable.type === "url") {
          insertTextAndUpdateCursorIndex({
            text: variable.text,
            link: applyDynamicVariableSyntax(variable),
          });
        } else {
          assertNever(variable);
        }
      },
      [insertTextAndUpdateCursorIndex],
    );

    return (
      <div className={quillEditorCss.container}>
        <div
          className={classNames(quillEditorCss.fakeCursor, {
            [quillEditorCss.active]: emojiPickerOpen,
          })}
          ref={fakeCursorRef}
          style={{
            padding: quillFakeCursorPadding + "px",
            left:
              (quillRef.current?.getBounds(cursorIndex)?.left || 0) -
              quillFakeCursorPadding,
            top:
              (quillRef.current?.getBounds(cursorIndex)?.top || 0) -
              quillFakeCursorPadding,
            width: 1 + quillFakeCursorPadding * 2 + "px",
            height:
              (quillRef.current?.getBounds(cursorIndex)?.height || 0) +
              quillFakeCursorPadding * 2 +
              "px",
          }}
        />
        <EmojiSelectionModal
          anchor={
            emojiPickerAnchorOverride ||
            (emojiPickerOpenedWithHotkey
              ? fakeCursorRef.current
              : emojiButtonRef.current)
          }
          handleEmojiSelected={handleEmojiSelected}
          onClose={closeEmojiPicker}
          open={emojiPickerOpen}
          theme={theme}
        />
        <ClickAwayListener
          mouseEvent="onMouseDown"
          onClickAway={closeColorPicker}
          touchEvent="onTouchStart"
        >
          <Popper
            anchorEl={colorButtonRef.current}
            onKeyDown={handleColorPickerKeyDown}
            open={colorPickerOpen}
          >
            <RedoColorPicker
              onChange={handleColorPicked}
              value={quillColor ?? "#000000"}
            />
          </Popper>
        </ClickAwayListener>
        {dynamicVariablePickerOpen && (
          <DynamicVariableSelectionModal
            dynamicVariables={dynamicVariables ?? []}
            handleDynamicVariableSelected={handleDynamicVariableSelected}
            onClose={() => setDynamicVariablePickerOpen(false)}
            open={dynamicVariablePickerOpen}
          />
        )}
        {linkPopupOpen && (
          <QuillLinkPopup
            anchorEl={linkButtonRef.current}
            onClose={() => setLinkPopupOpen(false)}
            onSubmit={(url) => {
              setLinkPopupOpen(false);
              quillRef.current?.format("link", sanitizeUrl(url));
            }}
          />
        )}
        <div
          className={editorClassName}
          id={editorId}
          onKeyDown={handleHotkey}
          ref={containerRef}
          style={editorStyles}
        />
      </div>
    );
  },
);

export function pasteHtmlWithoutFocusing(quill: Quill, html: string) {
  const delta = quill.clipboard.convert({ html });
  quill.setContents(delta);
}

/**
 * This function is copied from quill.format but with certain
 * things changed to avoid focusing the quill editor. In particular,
 * 1. This function bypasses quill's event system. No EDITOR_CHANGE or
 *  TEXT_CHANGE events will fire as a result of this function.
 * 2. Instead of calling quill.getSelection, this function
 *  uses a variable more internal to quill that doesn't seem to be
 *  intended for public use.
 *
 * If you aren't concerned about quill grabbing focus when you
 * format, just use quill.format.
 *
 * @param quill Quill instance.
 * @param name Name of format to change. e.g. `"color"`
 * @param value Value for format. e.g. `"#deadaf"`
 * @returns
 */
function formatQuillWithoutFocusingRisky(
  quill: Quill,
  name: string,
  value: string | undefined,
) {
  // When quill is unfocused, quill.getSelection() returns null. Instead,
  // we access the last non-null selection, stored in savedRange.
  const range = quill.selection.savedRange;
  if (range == null) return;
  if (quill.scroll.query(name, Parchment.Scope.BLOCK)) {
    quill.editor.formatLine(range.index, range.length, { [name]: value });
  } else if (range.length === 0) {
    quill.selection.format(name, value);
  } else {
    quill.editor.formatText(range.index, range.length, { [name]: value });
  }
}
