import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { getLocations } from "@redotech/redo-merchant-app-common/client/shopify";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { AxiosError } from "axios";
import { memo, useContext, useEffect, useState } from "react";
import { Notification } from "../notification";

export const LogInToRedo = memo(function LogInToRedo() {
  const team = useContext(TeamContext);
  const client = useContext(RedoMerchantClientContext);
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    if (!team?.shopifyIntegration || !team.storeUrl || !client) {
      return;
    }

    const checkShopifyConnection = async () => {
      try {
        await getLocations(client, { storeUrl: team.storeUrl });
        setShowBanner(false);
      } catch (error) {
        if (error instanceof AxiosError && error.response?.status === 401) {
          setShowBanner(true);
        }
      }
    };

    void checkShopifyConnection();
  }, [team, client]);

  if (!showBanner) {
    return null;
  }

  return (
    <Notification type="error">
      <p>
        Redo is not available to your customers. Please log into Shopify and
        open Redo from your Apps section.
      </p>
    </Notification>
  );
});
