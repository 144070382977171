import { ChangeEvent, memo, MouseEvent, useState } from "react";
import { RedoButton } from "../arbiter-components/buttons/redo-button";
import PaperclipIcon from "../icon-old/paperclip.svg";
import { Tooltip } from "../tooltip/tooltip";
import * as quillToolbarUploadFileCss from "./quill-toolbar-upload-file.module.css";

export const QuillToolbarUploadFile = memo(function QuillToolbarUploadFile({
  handleUpload,
  clearInput,
}: {
  handleUpload: ({
    event,
  }: {
    event: ChangeEvent<HTMLInputElement>;
  }) => Promise<void>;
  clearInput: (e: MouseEvent<HTMLInputElement>) => void;
}) {
  const [fileUploadRef, setFileUploadRef] = useState<HTMLInputElement | null>(
    null,
  );

  return (
    <Tooltip arrow title="File">
      <div>
        <label htmlFor="file-upload" id="file-upload-label">
          <RedoButton
            buttonId="attach-file"
            IconLeading={PaperclipIcon}
            onClick={() => {
              fileUploadRef?.click();
            }}
            size="sm"
          />
        </label>
        <input
          className={quillToolbarUploadFileCss.fileInput}
          id="file-upload"
          onChange={(e) => handleUpload({ event: e })}
          onClick={(e) => clearInput(e)}
          ref={setFileUploadRef}
          type="file"
        />
      </div>
    </Tooltip>
  );
});
