import { useRequiredContext } from "@redotech/react-util/context";
import { useWaitForPrevious } from "@redotech/react-util/wait-for-previous";
import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { TeamConversationActivity } from "@redotech/redo-model/conversation-activity";
import { RenderedTeam } from "@redotech/redo-model/team";
import { FrontendTeamNoticeEvent } from "@redotech/redo-model/team-notifications/team-notifications";
import { SupportBreadcrumbOverrider } from "./support-breadcrumb-overrider";

import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { MerchantAppTopic } from "@redotech/redo-merchant-app-common/events/merchant-app-event-server";
import { MerchantAppEventServerContext } from "@redotech/redo-merchant-app-common/events/merchant-app-event-server-provider";
import { listen } from "@redotech/redo-merchant-app-common/events/utils";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { UserContext } from "@redotech/redo-merchant-app-common/user";
import { CardListRef } from "@redotech/redo-web/card-list";
import { Flex } from "@redotech/redo-web/flex";
import { LoadingRedoAnimation } from "@redotech/redo-web/loading-redo-animation";
import { TableRef } from "@redotech/redo-web/table";
import { idEqual } from "@redotech/util/equal";
import { sinkPromise } from "@redotech/util/promise";
import * as clone from "lodash/clone";
import {
  createContext,
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { UserCacheContext } from "../app/user-cache";
import { ViewsContext } from "../app/views";
import { getConversation } from "../client/conversations";
import { CachedConversationFetcher } from "./cached-conversation-fetcher";
import { UpdateConversationStateContext } from "./context/update-conversations-context";
import { ConversationFetcherCache } from "./conversation-fetcher-cache";
import { ActiveViewContextProvider } from "./conversations-table-filters/active-view-context";
import { ActiveViewConversationCountsProvider } from "./conversations-table-filters/conversation-counts-context";
import {
  FiltersProvider,
  FinalizedFiltersContext,
} from "./conversations-table-filters/filters-context";
import { ConversationsTableSelection } from "./conversations-table/conversations-table-selection";
import { FullConversationsTable } from "./conversations-table/full-conversations-table";
import { maybeRunConversationsRefresh } from "./handle-notice-event";
import {
  ACTIVE_CONVERSATION_QUERY_PARAMETER,
  getQueryParameter,
} from "./query-parameters";
import { SingleConversationView } from "./single-conversation-view";
import {
  getConversationActivityStream as getTeamConversationActivityStream,
  sendConversationActivityUpdate,
} from "./utils";

export const Support = memo(function Support() {
  const team = useContext(TeamContext);
  const user = useContext(UserContext);
  const views = useContext(ViewsContext);
  if (team && user && views) {
    return (
      <ActiveViewContextProvider>
        <FiltersProvider>
          <SupportComponent />
        </FiltersProvider>
      </ActiveViewContextProvider>
    );
  } else {
    return null;
  }
});

export const TeamConversationActivityContext =
  createContext<TeamConversationActivity>({});

const SupportComponent = memo(function SupportComponent() {
  const navigate: NavigateFunction = useNavigate();
  const team = useRequiredContext(TeamContext) as RenderedTeam;
  const user = useRequiredContext(UserContext);
  const client = useRequiredContext(RedoMerchantClientContext);
  const userCache = useContext(UserCacheContext);
  const filters = useContext(FinalizedFiltersContext);

  // The conversation that is currently being viewed in the detail view
  const [activeConversation, setActiveConversationState] =
    useState<ExpandedConversation>();
  const [prevConversation, setPrevConversation] =
    useState<ExpandedConversation>();
  const activeConversationRef = useRef<ExpandedConversation>();
  const [pageLoaded, setPageLoaded] = useState(false);

  const [blockRefresh, setBlockRefresh] = useState(false);

  const [teamConversationActivity, setTeamConversationActivity] =
    useState<TeamConversationActivity>({});
  const teamConversationActivityRef = useRef<TeamConversationActivity>({});

  // Needed to prevent us from rendering the table before we get the active conversation from the network request
  const activeConversationLoading = useRef(true);

  // We shouldn't load anything until we've checked the URL for a potential active conversation
  const doneCheckingUrlForConversation = useRef(false);

  useEffect(() => {
    activeConversationRef.current = activeConversation;
  }, [activeConversation]);

  useEffect(() => {
    teamConversationActivityRef.current = teamConversationActivity;
  }, [teamConversationActivity]);

  useEffect(() => {
    const abortController = new AbortController();
    let retryCount = 0;
    const maxRetries = 2;
    const retryDelay = 2000;

    const setupStream = async () => {
      try {
        for await (const data of listen({
          query: async () => {
            return await getTeamConversationActivityStream({
              authorization: client.authorization() as {
                Authorization: string;
              },
              signal: abortController.signal,
            });
          },
          loopCondition: true,
        })) {
          const newTeamConversationActivity: TeamConversationActivity =
            data as any;
          setTeamConversationActivity(newTeamConversationActivity);
        }
      } catch (e) {
        if (abortController.signal.aborted) {
          return;
        }

        if (retryCount < maxRetries) {
          retryCount += 1;
          setTimeout(() => {
            void setupStream();
          }, retryDelay);
        }

        throw e;
      }
    };

    void setupStream();

    return () => {
      if (team.users.map((user) => user.user._id).includes(user._id))
        void sendConversationActivityUpdate({
          authorization: client.authorization() as { Authorization: string },
          prevConversationId: activeConversationRef.current?._id,
        });
      abortController.abort();
    };
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** see useImperativeHandle in table.tsx, which wires this up */
  const tableRef = useRef<TableRef<ExpandedConversation>>({
    refresh: async () => {},
    items: [],
    removeItem: () => {},
    externalRefresh: async () => {},
    externalSetItems: () => {},
  });

  /** see useImperativeHandle in card-list.tsx, which wires this up */
  const cardListRef = useRef<CardListRef<ExpandedConversation>>({
    refresh: async () => {},
    removeItem: () => {},
    getNext: () => undefined,
    getPrevious: () => undefined,
    externalRefresh: async () => {},
    externalSetItems: () => {},
  });

  const refreshConversations = async () => {
    lastRefreshStartedAt.current = new Date();
    fetcher.invalidateCache();
    await Promise.all([
      tableRef.current?.externalRefresh?.(setConversations),
      cardListRef.current?.externalRefresh?.(setConversations),
    ]);
  };

  const throttledRefresh = useWaitForPrevious(refreshConversations);

  const refreshIgnoreResult = () => {
    sinkPromise(throttledRefresh());
  };

  const waitForRefresh = async () => {
    await throttledRefresh();
  };

  // If our latest refresh was triggered after we updated a conversation, we can set the conversations
  // Otherwise we should ignore the refresh - it's got stale data
  const setConversations = (conversations: ExpandedConversation[]) => {
    if (!lastConversationUpdatedAt.current) {
      tableRef.current?.externalSetItems?.(conversations);
      cardListRef.current?.externalSetItems?.(conversations);
    } else if (
      lastRefreshStartedAt.current &&
      lastConversationUpdatedAt.current < lastRefreshStartedAt.current
    ) {
      tableRef.current?.externalSetItems?.(conversations);
      cardListRef.current?.externalSetItems?.(conversations);
    }
  };

  const lastRefreshStartedAt = useRef<Date | null>(null);
  const lastConversationUpdatedAt = useRef<Date | null>(null);

  const removeConversation = (conversation: ExpandedConversation) => {
    lastConversationUpdatedAt.current = new Date();
    cardListRef.current?.removeItem(conversation, idEqual);
    // In the future weprobably don't want to invalidate the cache here. We should perform an optimistic update, and if it's in the view, we should update the cache, and the table/card list
    fetcher.invalidateCache();
  };

  const optimisticUpdate = (conversation: ExpandedConversation) => {
    // TODO implement
    // Check if conversation matches the view/filters
    // If so, update the cache, and the table/card list
    // Make sure to not only add the conversation, but remove if it no longer matches th view, or just update the conversation in memory
    // Update all cached pages - we may need to invalidate cache that doesn't match existing filters
    // Also increment/decrement the counts
  };

  const eventServer = useRequiredContext(MerchantAppEventServerContext);

  // The main purpose of the cache is to avoid making the same request when we switch between cards and tables
  // To avoid handling the complexity of cache invalidation, we invalidate the cache anytime we change the filters
  useEffect(() => {
    fetcher.invalidateCache();
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters.sort?.direction,
    filters.sort?.key,
    filters.advancedFilters,
    filters.search,
    filters.status,
    filters.drafts,
    filters.customerEmail,
  ]);

  useEffect(() => {
    const unlistenCallback = eventServer.subscribe({
      topic: MerchantAppTopic.TEAM,
      callback: async (message: FrontendTeamNoticeEvent | null) => {
        if (blockRefresh) {
          return;
        }
        maybeRunConversationsRefresh(message, refreshIgnoreResult);
      },
    });
    return () => unlistenCallback();
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockRefresh]);

  useEffect(() => {
    if (team.users.map((user) => user.user._id).includes(user._id)) {
      if (!activeConversation) return;
      const abortController = new AbortController();
      const sendUpdate = async ({
        prevConversationId,
      }: { prevConversationId?: string } = {}) => {
        await sendConversationActivityUpdate({
          authorization: client.authorization() as { Authorization: string },
          activeConversationId: activeConversation._id,
          prevConversationId,
          signal: abortController.signal,
        });
      };

      // Optimistically update team conversation activity
      const newTeamConversationActivity = clone(
        teamConversationActivityRef.current,
      );
      // Remove from old conversation
      if (
        prevConversation &&
        newTeamConversationActivity[prevConversation._id]
      ) {
        newTeamConversationActivity[prevConversation._id].viewing =
          newTeamConversationActivity[prevConversation._id].viewing.filter(
            (userId) => userId !== user._id,
          );
        newTeamConversationActivity[prevConversation._id].typing =
          newTeamConversationActivity[prevConversation._id].typing.filter(
            (userId) => userId !== user._id,
          );
        if (
          newTeamConversationActivity[prevConversation._id].viewing.length ===
            0 &&
          newTeamConversationActivity[prevConversation._id].typing.length === 0
        ) {
          delete newTeamConversationActivity[prevConversation._id];
        }
      }
      // Add to new conversation
      if (!newTeamConversationActivity[activeConversation._id]) {
        newTeamConversationActivity[activeConversation._id] = {
          viewing: [],
          typing: [],
        };
      }
      const newViewing = [
        ...(newTeamConversationActivity[activeConversation._id].viewing || []),
        user._id,
      ];
      newTeamConversationActivity[activeConversation._id] = {
        viewing: [...newViewing],
        typing: newTeamConversationActivity[activeConversation._id].typing,
      };
      setTeamConversationActivity(newTeamConversationActivity);

      setPrevConversation(activeConversation);
      void sendUpdate({ prevConversationId: prevConversation?._id });
      const interval = setInterval(sendUpdate, 5 * 1000);
      return () => {
        clearInterval(interval);
        abortController.abort("activeConversation changed");
      };
    }
    return;
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeConversation?._id]);

  useEffect(() => {
    // Handle the case where we went back to the view without clicking the back button
    // (i.e. by clicking the view in the navigation sidebar)
    if (!location.search && activeConversation) {
      void setActiveConversation(undefined);
      activeConversationLoading.current = false;
    }

    const activeConversationId = getQueryParameter(
      location,
      ACTIVE_CONVERSATION_QUERY_PARAMETER,
    );
    if (
      activeConversationId &&
      activeConversation?._id !== activeConversationId
    ) {
      const updateActiveConversation = async () => {
        try {
          const conversation = await getConversation(client, {
            conversationId: activeConversationId,
          });
          await setActiveConversation(conversation);
        } finally {
          activeConversationLoading.current = false;
        }
      };
      void updateActiveConversation();
    } else {
      activeConversationLoading.current = false;
    }

    doneCheckingUrlForConversation.current = true;
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search]);

  const fetcher: CachedConversationFetcher = useMemo(() => {
    return new CachedConversationFetcher(
      new ConversationFetcherCache(),
      client,
      userCache,
      () => setPageLoaded(true),
    );
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  const setActiveConversation = async (
    incomingConversation: ExpandedConversation | undefined,
  ) => {
    setActiveConversationState((prevActiveConversation) => {
      let conversationToUse: ExpandedConversation | undefined;
      if (!incomingConversation || !prevActiveConversation) {
        conversationToUse = incomingConversation;
      } else if (incomingConversation?._id !== prevActiveConversation?._id) {
        conversationToUse = incomingConversation;
      } else if (
        new Date(incomingConversation.updatedAt) >=
        new Date(prevActiveConversation.updatedAt)
      ) {
        conversationToUse = incomingConversation;
      } else {
        conversationToUse = prevActiveConversation;
      }

      navigateToConversationIfNeeded(prevActiveConversation, conversationToUse);

      return conversationToUse;
    });
  };

  const navigateToConversationIfNeeded = (
    activeConversation: ExpandedConversation | undefined,
    newConversation: ExpandedConversation | undefined,
  ): void => {
    if (activeConversation?._id === newConversation?._id) {
      return;
    }
    const locationActiveConversationId = getQueryParameter(
      location,
      ACTIVE_CONVERSATION_QUERY_PARAMETER,
    );
    if (newConversation?._id === locationActiveConversationId) {
      return;
    }
    const searchParams = new URLSearchParams(location.search);

    if (newConversation?._id) {
      searchParams.set(
        ACTIVE_CONVERSATION_QUERY_PARAMETER,
        newConversation._id,
      );
      navigate(`${location.pathname}?${searchParams}`);
    } else {
      searchParams.delete(ACTIVE_CONVERSATION_QUERY_PARAMETER);
      navigate(`${location.pathname}?${searchParams}`);
    }
  };

  if (!team.settings.support?.enabled) {
    navigate(`/stores/${team._id}/summary`);
  }

  const updateConversationStateContext: UpdateConversationStateContext = {
    invalidateCache: () => fetcher.invalidateCache(),
    tableRef,
    cardListRef,
    waitForRefresh,
    refreshIgnoreResult,
    removeConversation,
    optimisticUpdate,
  };

  const showConversationsTable = useMemo(() => {
    return (
      doneCheckingUrlForConversation.current &&
      !activeConversation &&
      !activeConversationLoading.current
    );
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    doneCheckingUrlForConversation.current,
    activeConversation,
    activeConversationLoading.current,
  ]);

  const showLoadingAnimation = useMemo(() => {
    return (
      doneCheckingUrlForConversation.current &&
      activeConversationLoading.current
    );
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    doneCheckingUrlForConversation.current,
    activeConversationLoading.current,
  ]);

  return (
    <UpdateConversationStateContext.Provider
      value={updateConversationStateContext}
    >
      <ActiveViewConversationCountsProvider blockCountRefreshes={blockRefresh}>
        <TeamConversationActivityContext.Provider
          value={teamConversationActivity}
        >
          <SupportBreadcrumbOverrider />
          {activeConversation && (
            <SingleConversationView
              activeConversation={activeConversation}
              blockRefresh={blockRefresh}
              fetcher={fetcher}
              setActiveConversation={setActiveConversation}
            />
          )}
          {showConversationsTable && (
            <ConversationsTableSelection fetcher={fetcher}>
              <FullConversationsTable
                blockRefresh={blockRefresh}
                fetcher={fetcher}
                pageLoaded={pageLoaded}
                setActiveConversation={setActiveConversation}
                setBlockRefresh={setBlockRefresh}
              />
            </ConversationsTableSelection>
          )}
          {showLoadingAnimation && (
            <Flex align="center" justify="center" w="full">
              <LoadingRedoAnimation />
            </Flex>
          )}
        </TeamConversationActivityContext.Provider>
      </ActiveViewConversationCountsProvider>
    </UpdateConversationStateContext.Provider>
  );
});
