import { CompleteOrderFulfillmentStatus } from "@redotech/redo-model/shopify-order";
import { memo } from "react";
import {
  RedoBadge,
  RedoBadgeColor,
} from "./arbiter-components/badge/redo-badge";

export const OrderStatusPill = memo(function OrderStatusPill({
  orderStatus,
}: {
  orderStatus: CompleteOrderFulfillmentStatus;
}) {
  const getBadgeColor = (): RedoBadgeColor => {
    if (orderStatus === CompleteOrderFulfillmentStatus.CANCELED) {
      return "error";
    } else if (orderStatus === CompleteOrderFulfillmentStatus.FULFILLED) {
      return "success";
    } else if (orderStatus === CompleteOrderFulfillmentStatus.UNFULFILLED) {
      return "warning";
    } else if (orderStatus === CompleteOrderFulfillmentStatus.PARTIAL) {
      return "warning";
    } else {
      return "gray";
    }
  };

  const getBadgeText = (): string => {
    return orderStatus.displayValue;
  };

  return <RedoBadge color={getBadgeColor()} size="sm" text={getBadgeText()} />;
});
