export enum ShopifySubscriptionType {
  SUPPORT_BASE = "supportBase",
  SUPPORT_AI = "aiMessageSuggestion",
  ORDER_TRACKING = "orderTracking",
  TEXT_MESSAGING = "textMessaging",
  ONE_CLICK_UPSELL = "oneClickUpsell",
  MARKETING = "marketing",
  CHECKOUT_OPTIMIZATION = "checkoutOptimization",
}

export interface CancelShopifySubscriptionBody {
  subscriptionType: ShopifySubscriptionType;
}
