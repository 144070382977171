import {
  createLazyContext,
  useLazyContextProvider,
  useRequiredContext,
} from "@redotech/react-util/context";
import { Parcel } from "@redotech/redo-model/outbound-labels/parcel";
import { memo, ReactNode } from "react";
import { RedoOutboundLabelsRpcClientContext } from "../outbound-labels-rpc-client";

export const ParcelContext = createLazyContext<readonly Parcel[]>();
export const ParcelService = memo(function ParcelService({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const client = useRequiredContext(RedoOutboundLabelsRpcClientContext);

  const loadState = useLazyContextProvider(async (signal) => {
    const { parcels } = await client.getParcels(null, { signal });
    return parcels;
  });

  return (
    <ParcelContext.internalContext.Provider value={loadState}>
      {children}
    </ParcelContext.internalContext.Provider>
  );
});
