// extracted by mini-css-extract-plugin
export var aboveInput = "_al-0";
export var actionButton = "_al-5";
export var actionButtonContainer = "_al-1";
export var actionIcon = "_al-6";
export var aiInfoContainer = "_al-2";
export var attachmentWrapper = "_al-7";
export var bold = "_al-3";
export var checkIcon = "_al-8";
export var clickingRecipientBar = "_al-11";
export var commentThreadSwitch = "_al-a";
export var draftImage = "_al-b";
export var draftImageContainer = "_al-c";
export var draftImages = "_al-e";
export var editButton = "_al-g";
export var editor = "_al-f";
export var editorContainer = "_al-y";
export var editorErrorTooltipWrapper = "_al-9";
export var headerDivider = "_al-h";
export var icon = "_al-i";
export var info = "_al-j";
export var internal = "_al-n";
export var internalDivider = "_al-k";
export var internalMessage = "_al-l";
export var messageInputCard = "_al-m";
export var messageInputForm = "_al-o";
export var messageInputFormInternal = "_al-p";
export var options = "_al-q";
export var publicMessageCustomer = "_al-s";
export var publicMessageMerchant = "_al-r";
export var removeImageButton = "_al-d";
export var replyButtons = "_al-t";
export var showSignatureButton = "_al-u";
export var spinner = "_al-v";
export var verticalButtonDivider = "_al-4";
export var visibilitySelector = "_al-w";
export var warning = "_al-x";
export var wrappingText = "_al-z";
export var writingInternalNoteTopBorder = "_al-10";