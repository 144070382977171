/**
 * https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
 * @param {string} string string
 * @returns {number} 32-bit hash of string
 */
export function stringHash(string: string): number {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    const chr = string.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return hash;
}

export function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

/**
 * Attempts to coerce a name into 'Jane Doe'
 * jane doe, JANE   DOE -> Jane Doe
 */
export function formatName(name: string): string {
  return name
    .split(/\s+/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export function formatSentenceCase(str: string) {
  return str[0].toUpperCase() + str.slice(1).replace(/_/g, " ");
}

export function camelToSnakeCase(str: string) {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

export function snakeToCamelCase(str: string) {
  return str.replace(/(_\w)/g, (match) => match[1].toUpperCase());
}

export function truncateString(str: string, maxLength: number) {
  return str.length > maxLength ? str.slice(0, maxLength) + "…" : str;
}

export async function replaceAsync(
  str: string,
  regexp: RegExp,
  replacerFunction: (match: RegExpMatchArray) => Promise<string>,
) {
  const replacements = await Promise.all(
    Array.from(str.matchAll(regexp), replacerFunction),
  );
  let i = 0;
  return str.replace(regexp, () => replacements[i++]);
}
