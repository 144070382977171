import { ClickAwayListener } from "@mui/base";
import { ExpandedConversation } from "@redotech/redo-model/conversation";
import {
  EmailReplyType,
  stringifyEmailRecipientsInfo,
} from "@redotech/redo-model/support/conversations/email-info";
import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import ChevronDownSvg from "@redotech/redo-web/arbiter-icon/chevron-down_filled.svg";
import CornerUpLeftSvg from "@redotech/redo-web/arbiter-icon/corner-up-left_filled.svg";
import { Dropdown, DropdownOption } from "@redotech/redo-web/dropdown";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import * as classNames from "classnames";
import { memo, useState } from "react";
import { EditRecipientsModal } from "../action-panel/edit-recipients-modal";
import { EmailDraftState } from "../conversation-email-view/email-draft-state";
import { EmailDraftProps } from "./message-input";
import * as messageInputCss from "./message-input.module.css";

export const MessageInputEmailHeader = memo(function MessageInputEmailHeader({
  emailDraftProps,
  conversation,
  isFirstMessageAfterPlatformConversion,
}: {
  emailDraftProps: EmailDraftProps;
  conversation: ExpandedConversation;
  isFirstMessageAfterPlatformConversion: boolean;
}) {
  const [pickingReplyType, setPickingReplyType] = useState(false);

  const [pickReplyButtonRef, setPickReplyButtonRef] =
    useState<HTMLButtonElement | null>(null);

  /** If we open a fresh editor for email replies and it's in forward mode, start with picker for forwarding */
  const [editingCCBCC, setEditingCCBCC] = useState(
    emailDraftProps?.draftInfo?.mode === "Forward" &&
      emailDraftProps?.shouldPopupRecipientsModalBecauseForwardButtonClicked,
  );

  const handlePickReplyType = (mode: EmailReplyType) => {
    emailDraftProps?.handleSetReplyDraft(
      (di) => ({ ...di, mode }) as EmailDraftState,
    );
    if (mode === "Forward") {
      setEditingCCBCC(true);
    }
    setPickingReplyType(false);
  };

  return (
    <Flex
      align="center"
      className={classNames(messageInputCss.wrappingText)}
      pt="md"
      px="lg"
    >
      <ClickAwayListener onClickAway={() => setPickingReplyType(false)}>
        <div>
          <RedoButton
            hierarchy="tertiary"
            IconLeading={CornerUpLeftSvg}
            IconTrailing={ChevronDownSvg}
            onClick={(e) => setPickingReplyType((v) => !v)}
            ref={setPickReplyButtonRef}
            size="md"
            text={emailDraftProps.draftInfo.mode}
          />
          <Dropdown
            anchor={pickReplyButtonRef}
            fitToAnchor={false}
            open={pickingReplyType}
          >
            <DropdownOption
              action={() => handlePickReplyType(EmailReplyType.REPLY)}
            >
              Reply
            </DropdownOption>
            {!isFirstMessageAfterPlatformConversion && (
              <>
                <DropdownOption
                  action={() => handlePickReplyType(EmailReplyType.REPLY_ALL)}
                >
                  Reply all
                </DropdownOption>
                <DropdownOption
                  action={() => handlePickReplyType(EmailReplyType.FORWARD)}
                >
                  Forward
                </DropdownOption>
              </>
            )}
          </Dropdown>
        </div>
      </ClickAwayListener>
      <Flex
        align="center"
        className={messageInputCss.wrappingText}
        gap="2xl"
        justify="space-between"
      >
        <Flex
          align="center"
          className={classNames(
            messageInputCss.wrappingText,
            messageInputCss.clickingRecipientBar,
          )}
          onClick={() => setEditingCCBCC(true)}
        >
          <Text
            fontSize="md"
            overflow="hidden"
            textColor="primary"
            textOverflow="ellipsis"
          >
            {stringifyEmailRecipientsInfo(
              emailDraftProps?.draftInfo.draft.recipientsInfo,
            ) ?? ""}
          </Text>
        </Flex>
        <Flex align="center">
          <RedoButton
            hierarchy="tertiary"
            onClick={() => setEditingCCBCC(true)}
            size="xs"
            text="Edit recipients"
          />
          <EditRecipientsModal
            conversation={conversation}
            emailDraft={emailDraftProps.draftInfo}
            isOpen={editingCCBCC}
            onClose={() => setEditingCCBCC(false)}
            setEmailDraft={emailDraftProps.handleSetReplyDraft}
          />
        </Flex>
      </Flex>
    </Flex>
  );
});
