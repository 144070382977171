import { z } from "zod";

export const updateReturnWithShippingRefund = {
  input: z.object({
    id: z.string(),
    refundOriginalShipping: z.boolean(),
    refundOriginalShippingAmount: z.number().optional(),
  }),
  output: z.object({ success: z.boolean() }),
};
