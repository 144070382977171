import { useRequiredContext } from "@redotech/react-util/context";
import { useTriggerLoad } from "@redotech/react-util/load";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import {
  getTeamOrders,
  getTeamReturns,
} from "@redotech/redo-merchant-app-common/client/team";
import {
  StorefrontClientContext,
  TeamContext,
} from "@redotech/redo-merchant-app-common/team";
import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { getPrimaryCustomerEmail } from "@redotech/redo-model/customer";
import { Flex } from "@redotech/redo-web/flex";
import { Tabs, TabSize } from "@redotech/redo-web/tab";
import { getCart } from "@redotech/shopify-storefront";
import { memo, useContext, useEffect, useState } from "react";
import { ConversationDetailsPanel } from "../conversation-detail-panel/conversation-details-panel";
import { ActivityPanel } from "./activity-panel";

enum ContextTab {
  DETAILS = "details",
  ACTIVITY = "conversation-activity",
}

const contextTabLabel = {
  [ContextTab.DETAILS]: "Details",
  [ContextTab.ACTIVITY]: "Timeline",
};

export const ConversationContext = memo(function ConversationContext({
  conversation,
  setConversationAssignee,
  setActiveConversation,
}: {
  conversation: ExpandedConversation;
  setConversationAssignee: (assignee: any) => void;
  setActiveConversation: (conversation: ExpandedConversation) => void;
}) {
  const team = useRequiredContext(TeamContext);
  const storefrontClient = useContext(StorefrontClientContext);
  const client = useRequiredContext(RedoMerchantClientContext);

  const [tab, setTab] = useState<ContextTab>(ContextTab.DETAILS);

  const primaryEmail = getPrimaryCustomerEmail(conversation.customer);

  const [returnsLoad, doReturnsLoad] = useTriggerLoad(async (signal) => {
    if (primaryEmail) {
      return await getTeamReturns(client, { email: primaryEmail, signal });
    }
  });

  const [cartsLoad, doCartsLoad] = useTriggerLoad(async (signal) => {
    if (storefrontClient && conversation.carts?.length) {
      const cartPromises = conversation.carts.map(async (cartId: string) => {
        return await getCart(storefrontClient, `gid://shopify/Cart/${cartId}`);
      });
      return await Promise.all(cartPromises);
    }
    return [];
  });

  const [ordersLoad, doOrdersLoad] = useTriggerLoad(async (signal) => {
    if (primaryEmail) {
      return await getTeamOrders(client, { email: primaryEmail });
    }
  });

  useEffect(() => {
    doOrdersLoad();
    doReturnsLoad();
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation._id, conversation?.customer?.customer]);

  useEffect(() => {
    doCartsLoad();
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storefrontClient, conversation]);

  const availableTabs = team.settings.customerAccounts?.enabled
    ? Object.values(ContextTab)
    : [ContextTab.DETAILS, ContextTab.ACTIVITY];

  return (
    <Tabs<ContextTab>
      customContainerCss={{ height: "100%" }}
      customOutletCss={{ overflowY: "auto", paddingTop: "0" }}
      customTabCss={{ fontSize: "16px", height: "40px" }}
      keyFn={(option) => option}
      options={availableTabs}
      tab={(option) => contextTabLabel[option]}
      tabSize={TabSize.SMALL}
      value={tab}
      valueChange={setTab}
    >
      {tab === ContextTab.DETAILS && (
        <ConversationDetailsPanel
          cartsLoad={cartsLoad}
          conversation={conversation}
          doCartsLoad={doCartsLoad}
          doOrdersLoad={doOrdersLoad}
          doReturnsLoad={doReturnsLoad}
          inTab
          ordersLoad={ordersLoad}
          returnsLoad={returnsLoad}
          setActiveConversation={setActiveConversation}
          setConversationAssignee={setConversationAssignee}
        />
      )}
      {tab === ContextTab.ACTIVITY && (
        <Flex dir="column" w="full">
          <ActivityPanel
            conversationId={conversation._id}
            customerId={conversation?.customer?.customer}
          />
        </Flex>
      )}
    </Tabs>
  );
});
