import { PopperProps } from "@mui/base";
import { Popper } from "@mui/material";
import { memo, useState } from "react";
import { RedoButton } from "../arbiter-components/buttons/redo-button";
import { RedoTextInput } from "../arbiter-components/input/redo-text-input";
import { Flex } from "../flex";

export const QuillLinkPopup = memo(function QuillLinkPopup({
  anchorEl,
  onSubmit,
  onClose,
}: {
  anchorEl: PopperProps["anchorEl"];
  onSubmit(link: string): void;
  onClose(): void;
}) {
  const [url, setUrl] = useState<string>("");
  const keyUpHandlers: Map<string, () => void> = new Map([
    ["Enter", () => onSubmit(url)],
    ["Escape", onClose],
  ]);
  return (
    <Popper anchorEl={anchorEl} open placement="top">
      <Flex pb="md">
        <Flex
          bgColor="primary"
          borderColor="primary"
          borderStyle="solid"
          borderWidth="1px"
          px="md"
          py="xs"
          radius="md"
        >
          <Flex
            gap="xs"
            onKeyUp={(e) => {
              const handler = keyUpHandlers.get(e.key);
              if (handler) {
                e.stopPropagation();
                handler();
              }
            }}
          >
            <RedoTextInput
              placeholder="Paste or type a link"
              setValue={setUrl}
              value={url}
            />
          </Flex>
          <RedoButton
            hierarchy="tertiary"
            onClick={() => onSubmit(url)}
            size="sm"
            text="Save"
          />
        </Flex>
      </Flex>
    </Popper>
  );
});
