import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { useLoad, useTriggerLoad } from "@redotech/react-util/load";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { UserContext } from "@redotech/redo-merchant-app-common/user";
import {
  ConversationPlatform,
  ExpandedConversation,
  ExpandedConversationMessage,
  MessageVisibility,
  textForDeletedFacebookComment,
} from "@redotech/redo-model/conversation";
import { getCustomerDisplayName } from "@redotech/redo-model/customer";
import { replaceReplyThreadClassesWithSameDefaultClass } from "@redotech/redo-model/email-utils/reply-thread-utils";
import { Permission, permitted } from "@redotech/redo-model/user";
import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { AttachmentThumbnail } from "@redotech/redo-web/attachment-thumbnail";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { formatTimeAgo } from "@redotech/redo-web/date-utils";
import { Flex } from "@redotech/redo-web/flex";
import MeatballIcon from "@redotech/redo-web/icon-old/meatballs.svg";
import { ImageLightbox } from "@redotech/redo-web/image-lightbox";
import { ExternalLink } from "@redotech/redo-web/link";
import {
  pasteHtmlWithoutFocusing,
  QuillEditor,
} from "@redotech/redo-web/quill/quill-editor";
import * as quillEditorCss from "@redotech/redo-web/quill/quill-editor.module.css";
import { Text } from "@redotech/redo-web/text";
import { formatDateTime } from "@redotech/redo-web/time";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import * as classNames from "classnames";
import * as capitalize from "lodash/capitalize";
import Quill from "quill";
import { Delta } from "quill/core";
import "quill/dist/quill.snow.css";
import {
  Fragment,
  memo,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { resendMessage } from "../client/conversations";
import { convertPlainTextToHtml } from "./conversation-email-view/format-email-message-content";
import * as messageCss from "./message.module.css";
import { SystemMessage } from "./system-message";
import { parseHtml, removeHtmlComments, removeSimpleHTMLTags } from "./utils";
const urlRegex =
  /((?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$]))/gim;
const replaceLongLinks = (text: string) => {
  const pieces = text.split(urlRegex);
  const replacedPieces = pieces.map((piece, index) => {
    if (urlRegex.test(piece) && piece.length > 30) {
      // If the URL is part of an href, don't touch it
      if (index > 0 && pieces[index - 1].endsWith('href="')) {
        return piece;
        // If the URL is the child of a tag like an <a> tag, shorten it
      } else if (index > 0 && pieces[index - 1].endsWith('">')) {
        return `${piece.slice(0, 25)}...`;
        // If it's a bare URL, wrap it in an <a> tag
      } else {
        return `<a href="${piece}">${piece.slice(0, 25)}...</a>`;
      }
    } else {
      return piece;
    }
  });
  return replacedPieces.join("").replaceAll("\n", "<br/>");
};

const removeReplyThread = (
  htmlString: string,
  setShowViewReplyButton: {
    (value: SetStateAction<boolean>): void;
    (arg0: boolean): void;
  },
) => {
  htmlString = replaceReplyThreadClassesWithSameDefaultClass(htmlString);

  // Hide any reply threads in the email
  const htmlObject = document.createElement("div");
  htmlObject.innerHTML = htmlString;

  // Remove outlook reply threads
  const firstThreadOutlook = htmlObject.querySelector("[id$='divRplyFwdMsg']");
  if (firstThreadOutlook) {
    let currentElement: Element | null = firstThreadOutlook;
    let nextElement: Element | null = firstThreadOutlook.nextElementSibling;
    while (currentElement) {
      currentElement.remove();
      currentElement = nextElement;
      nextElement = nextElement?.nextElementSibling || null;
    }
    setShowViewReplyButton(true);
  }

  const elementsToRemove = htmlObject.querySelectorAll(
    "[class$='gmail_quote']",
  );

  if (elementsToRemove.length > 0) {
    setShowViewReplyButton(true);
  }
  for (let i = 0; i < elementsToRemove.length; i++) {
    elementsToRemove[i].remove();
  }
  return htmlObject;
};

export const Message = memo(function Message({
  message,
  conversation,
  index,
  profilePictures,
  setPrivateReplyModalOpen,
  setPrivateReplyMessage,
  setActiveConversation,
}: {
  message: ExpandedConversationMessage;
  conversation: ExpandedConversation;
  index: number;
  profilePictures?: Record<string, string>;
  setPrivateReplyModalOpen: (value: boolean) => void;
  setPrivateReplyMessage: (value: ExpandedConversationMessage) => void;
  setActiveConversation: (conversation: ExpandedConversation) => void;
}) {
  const user = useContext(UserContext);
  const client = useRequiredContext(RedoMerchantClientContext);

  const [selectedImage, setSelectedImage] = useState<string>("");
  const [selectedVideo, setSelectedVideo] = useState<string>("");
  const [isLightboxOpen, setIsLightboxOpen] = useState<boolean>(false);
  const [showPrivateReplyOption, setShowPrivateReplyOption] =
    useState<boolean>(false);
  const canReply =
    !!user && permitted(user.permissions, Permission.CREATE_REPLY);

  const typeClassname =
    message.visibility === MessageVisibility.INTERNAL
      ? messageCss.internal
      : message.type === "merchant"
        ? messageCss.merchant
        : messageCss.customer;
  const senderClassname =
    message.type === "merchant" ? messageCss.merchant : messageCss.customer;

  const senderName: string | undefined =
    message.type === "merchant"
      ? message.user?._id === process.env.ANONYMOUS_USER_ID
        ? `${capitalize(conversation.platform)} User`
        : message.user?.name
      : ![
            ConversationPlatform.INSTAGRAM_COMMENTS,
            ConversationPlatform.FACEBOOK_COMMENTS,
          ].includes(conversation.platform) ||
          message.customer?._id === conversation.customer.customer
        ? getCustomerDisplayName(message.customer)
        : undefined;

  const handleImageClick = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    setIsLightboxOpen(true);
  };

  const handleVideoClick = (videoUrl: string) => {
    setSelectedVideo(videoUrl);
    setIsLightboxOpen(true);
  };

  const handleResend = useHandler(async () => {
    const updatedConversation = await resendMessage(client, {
      conversationId: conversation._id,
      redoMessageId: message._id,
    });
    setActiveConversation(updatedConversation);
  });

  async function mediaIsVideo(url: string) {
    try {
      const response = await fetch(url, { method: "HEAD" });
      const contentType = response.headers.get("content-type");

      if (contentType && contentType.startsWith("video/")) {
        return true;
      }
    } catch (e) {
      // Ignore errors here and just say its not a video
    }
    return false;
  }

  const messageLoad = useLoad(async () => {
    const messageArray = removeSimpleHTMLTags(message.content).split(urlRegex);
    const images: string[] = [];
    const contentElements: string[] = [];
    const videoUrls: string[] = [];
    // Display an image instead of the url for image urls not in <img> tags
    messageArray.forEach(async (word: string, index: number) => {
      if (urlRegex.test(word)) {
        // Check if the url is preceeded by "src", indicating it's already displaying inline
        if (
          contentElements[contentElements.length - 1]
            .slice(contentElements[contentElements.length - 1].length - 5)
            .includes("src=")
        ) {
          return;
        }
        if (
          word.match(/(https?:\/\/\S+(\.png|\.jpg|\.gif|\.jpeg|\.webp))/g) ||
          word.includes("ig_messaging_cdn")
        ) {
          const isVideo = await mediaIsVideo(word);
          if (isVideo) {
            videoUrls.push(word);
          } else {
            images.push(word);
          }
        } else {
          contentElements.push(`<a href="${word}" target="_blank">${word}</a>`);
        }
      } else {
        contentElements.push(word);
      }
    });

    return { images, contentElements, videoUrls };
  }, [message.content]);

  const images = useMemo(
    () => messageLoad.value?.images ?? [],
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messageLoad.value?.images.length, conversation._id],
  );
  const contentElements = useMemo(
    () => messageLoad.value?.contentElements ?? [],
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messageLoad.value?.contentElements.length, conversation._id],
  );
  const videoUrls = useMemo(
    () => messageLoad.value?.videoUrls ?? [],
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messageLoad.value?.videoUrls.length, conversation._id],
  );

  return message.type !== "system" &&
    !messageLoad.pending &&
    messageLoad.value ? (
    <>
      {(contentElements.length > 0 ||
        images.length > 0 ||
        message.email?.htmlBody) && (
        <div className={classNames(messageCss.message, senderClassname)}>
          <div
            className={classNames(messageCss.bubbleContext, senderClassname)}
            onMouseEnter={() => {
              if (
                message.type !== "customer" ||
                ![
                  ConversationPlatform.INSTAGRAM_COMMENTS,
                  ConversationPlatform.FACEBOOK_COMMENTS,
                ].includes(conversation.platform) ||
                !canReply
              ) {
                return;
              }
              setShowPrivateReplyOption(true);
            }}
            onMouseLeave={() => setShowPrivateReplyOption(false)}
          >
            <MessageInformation
              conversation={conversation}
              message={message}
              senderName={senderName}
            />
            <MessageImages
              conversation={conversation}
              handleImageClick={handleImageClick}
              images={images}
              message={message}
              senderClassname={senderClassname}
              typeClassname={typeClassname}
            />
            <MessageVideos
              conversation={conversation}
              handleVideoClick={handleVideoClick}
              message={message}
              senderClassname={senderClassname}
              typeClassname={typeClassname}
              videoUrls={videoUrls}
            />
            {((contentElements.length > 0 &&
              !contentElements.every((elem) => !elem?.trim())) ||
              message.email?.htmlBody) && (
              <MessageBody
                contentElements={contentElements}
                conversation={conversation}
                index={index}
                message={message}
                typeClassname={typeClassname}
              />
            )}
            <MessageFailure handleResend={handleResend} message={message} />
            {showPrivateReplyOption && (
              <PrivateReplyOption
                message={message}
                setPrivateReplyMessage={setPrivateReplyMessage}
                setPrivateReplyModalOpen={setPrivateReplyModalOpen}
              />
            )}
          </div>
        </div>
      )}
      <ImageLightbox
        imageSrc={selectedImage}
        onClose={() => setIsLightboxOpen(false)}
        open={isLightboxOpen}
        videoSrc={selectedVideo}
      />
    </>
  ) : (
    <SystemMessage message={message.content} />
  );
});

const MessageFailure = memo(function MessageFailure({
  message,
  handleResend,
}: {
  message: ExpandedConversationMessage;
  handleResend(): Promise<void>;
}) {
  // These error messages are not guaranteed to be customer friendly, and are
  // the product of unexpected errors. Don't expose them to the customer.
  const messageHadError = message.error;

  const [load, triggerLoad] = useTriggerLoad(handleResend);

  if (messageHadError) {
    return (
      <RedoButton
        disabled={load.pending}
        hierarchy="secondary"
        onClick={() => triggerLoad()}
        pending={load.pending}
        size="xs"
        text="Message could not be sent. Try again or contact support."
        theme="destructive"
      />
    );
  } else {
    return null;
  }
});

const PrivateReplyOption = memo(function PrivateReplyOption({
  message,
  setPrivateReplyModalOpen,
  setPrivateReplyMessage,
}: {
  message: ExpandedConversationMessage;
  setPrivateReplyModalOpen: (value: boolean) => void;
  setPrivateReplyMessage: (value: ExpandedConversationMessage) => void;
}) {
  return (
    <div className={messageCss.senderDateCustomer}>
      {message.instagram?.comment?.hasBeenRepliedTo ||
      message.facebook?.comment?.hasBeenRepliedTo ? (
        <p>Already replied</p>
      ) : (
        <ExternalLink
          className={messageCss.username}
          onClick={() => {
            setPrivateReplyMessage(message);
            setPrivateReplyModalOpen(true);
          }}
          showIcon={false}
        >
          Reply with DM
        </ExternalLink>
      )}
    </div>
  );
});

const MessageInformation = memo(function MessageInformation({
  message,
  conversation,
  senderName,
}: {
  message: ExpandedConversationMessage;
  conversation: ExpandedConversation;
  senderName: string | undefined;
}) {
  const senderIdentifiers: ReactNode[] = [];
  if (senderName) {
    senderIdentifiers.push(<span>{senderName}</span>);
  }
  if (
    message.type === "customer" &&
    [
      ConversationPlatform.INSTAGRAM,
      ConversationPlatform.INSTAGRAM_COMMENTS,
    ].includes(conversation.platform) &&
    message.instagram?.comment?.username
  ) {
    const instagramUrl = `https://instagram.com/${message.instagram.comment.username}`;
    senderIdentifiers.push(
      <ExternalLink
        className={messageCss.username}
        showIcon={false}
        url={instagramUrl}
      >
        @{message.instagram.comment.username}
      </ExternalLink>,
    );
  }
  if (
    message.type === "customer" &&
    conversation.platform === ConversationPlatform.FACEBOOK_COMMENTS &&
    message.facebook?.comment?.facebookName &&
    !senderIdentifiers.length
  ) {
    senderIdentifiers.push(
      <span>{message.facebook.comment.facebookName}</span>,
    );
  }

  const [timeSinceMessage, setTimeSinceMessage] = useState<string>(
    formatTimeAgo(message.sentAt),
  );

  useEffect(() => {
    setTimeSinceMessage(formatTimeAgo(message.sentAt));
    const intervalId = setInterval(() => {
      setTimeSinceMessage(formatTimeAgo(message.sentAt));
    }, 60 * 1000);
    return () => clearInterval(intervalId);
  }, [message.sentAt]);

  return (
    <div className={messageCss.infoAbove}>
      <div
        className={
          message.type === "merchant"
            ? messageCss.senderDate
            : messageCss.senderDateCustomer
        }
      >
        <Flex align="baseline">
          {senderIdentifiers.length > 0 && (
            <>
              {senderIdentifiers.map((identifier, index) => (
                <>
                  {index > 0 && " - "}
                  <Fragment key={index}>
                    <Text fontSize="sm" fontWeight="medium">
                      {identifier}
                    </Text>
                  </Fragment>
                </>
              ))}
            </>
          )}
          <Tooltip
            arrow
            placement="top"
            title={formatDateTime(Temporal.Instant.from(message.sentAt))}
          >
            {/* This empty div needs to be here for the tooltip to work.
                Removing it breaks the tooltip and causes error logs when
                running locally that say "The children component of the
                Tooltip is not forwarding its props correctly." Not sure
                what that means, but something about our Text component
                doesn't play well with Tooltip. Feel free to look for a
                better way than this. -Zach */}
            <div>
              <Text fontSize="xs" fontWeight="regular">
                {timeSinceMessage}
              </Text>
            </div>
          </Tooltip>
        </Flex>
      </div>
      {!!message.instagram?.repliedStoryUrl && (
        <div>
          Replied to your{" "}
          <ExternalLink
            showIcon={false}
            url={message.instagram.repliedStoryUrl}
          >
            story
          </ExternalLink>
        </div>
      )}
      {!!message.instagram?.comment &&
        conversation.platform === ConversationPlatform.INSTAGRAM && (
          <div>Commented on your post</div>
        )}
      {(message.instagram?.privateRepliedComment ||
        message.facebook?.privateRepliedComment) && (
        <div>
          Private reply to their comment on your{" "}
          {message.instagram?.privateRepliedComment?.permalink ? (
            <ExternalLink
              showIcon={false}
              url={message.instagram.privateRepliedComment.permalink}
            >
              post
            </ExternalLink>
          ) : message.facebook?.privateRepliedComment?.permalink ? (
            <ExternalLink
              showIcon={false}
              url={message.facebook.privateRepliedComment.permalink}
            >
              post
            </ExternalLink>
          ) : (
            "post"
          )}
        </div>
      )}
    </div>
  );
});

const MessageVideos = memo(function MessageVideos({
  videoUrls,
  message,
  conversation,
  handleVideoClick,
  typeClassname,
  senderClassname,
}: {
  videoUrls: string[];
  message: ExpandedConversationMessage;
  conversation: ExpandedConversation;
  handleVideoClick: (videoUrl: string) => void;
  typeClassname: string;
  senderClassname: string;
}) {
  return (
    <>
      {videoUrls.map((videoUrl: string) => (
        <Fragment key={videoUrl}>
          {message.instagram?.mentionedStoryUrl && (
            <div>Mentioned you in their story</div>
          )}
          <div
            className={classNames(
              messageCss.bubble,
              typeClassname,
              conversation.platform,
            )}
          >
            <div
              className={classNames(messageCss.bubbleItems, senderClassname)}
              onClick={() => handleVideoClick(videoUrl)}
            >
              <video
                className={messageCss.uploadedImage}
                controls
                src={videoUrl}
              />
            </div>
          </div>
        </Fragment>
      ))}
    </>
  );
});

const MessageImages = memo(function MessageImages({
  images,
  handleImageClick,
  message,
  conversation,
  typeClassname,
  senderClassname,
}: {
  images: string[];
  handleImageClick: (imageUrl: string) => void;
  message: ExpandedConversationMessage;
  conversation: ExpandedConversation;
  typeClassname: string;
  senderClassname: string;
}) {
  return (
    <>
      {images.map((image: string) => (
        <Fragment key={image}>
          {message.instagram?.mentionedStoryUrl && (
            <div>Mentioned you in their story</div>
          )}
          <div
            className={classNames(
              messageCss.bubble,
              typeClassname,
              conversation.platform,
            )}
          >
            <div
              className={classNames(messageCss.bubbleItems, senderClassname)}
            >
              <img
                className={messageCss.uploadedImage}
                onClick={(e) => handleImageClick(e.currentTarget.src)}
                onError={(e) => {
                  e.currentTarget.style.display = "none";
                  e.currentTarget.parentElement?.appendChild(
                    document.createTextNode(
                      message.instagram?.mentionedStoryUrl
                        ? "Story expired"
                        : "Image unavailable",
                    ),
                  );
                }}
                src={image}
              />
            </div>
          </div>
        </Fragment>
      ))}
    </>
  );
});

const MessageBody = memo(function MessageBody({
  typeClassname,
  conversation,
  contentElements,
  message,
  index,
}: {
  typeClassname: string;
  conversation: ExpandedConversation;
  contentElements: string[];
  message: ExpandedConversationMessage;
  index: number;
}) {
  const [quill, setQuill] = useState<Quill | null>(null);
  const [hideReplyThreads, setHideReplyThreads] = useState<boolean>(true);
  const [showViewReplyButton, setShowViewReplyButton] =
    useState<boolean>(false);

  useEffect(() => {
    if (quill) {
      if (message.facebook?.comment?.deleted) {
        quill.setContents(
          new Delta({
            ops: [
              {
                insert: textForDeletedFacebookComment,
                attributes: { italic: true },
              },
            ],
          }),
        );
      } else if (message.email?.htmlBody) {
        // Tables don't get good formatting with quill, so we will extract the table,
        // parse it to get the text content, then put it back in the message with some basic formatting
        const tableRegex = /<table.*\/table>/g;
        let htmlString = removeHtmlComments(message.email.htmlBody).replace(
          /(\r\n|\n|\r)/gm,
          "",
        );
        const tableMatches = htmlString.match(tableRegex);
        if (tableMatches) {
          for (const match of tableMatches) {
            htmlString = htmlString.replace(
              match,
              replaceLongLinks(parseHtml(match).replace("\n", "<br />")),
            );
          }
        }
        if (hideReplyThreads) {
          const htmlObject = removeReplyThread(
            htmlString,
            setShowViewReplyButton,
          );
          htmlString = htmlObject.outerHTML;
        }
        pasteHtmlWithoutFocusing(quill, htmlString);
      } else if (message.chatFlow?.htmlContent) {
        pasteHtmlWithoutFocusing(quill, message.chatFlow.htmlContent);
      } else if (contentElements.length > 0) {
        const htmlString = convertPlainTextToHtml(contentElements.join(""));
        pasteHtmlWithoutFocusing(quill, htmlString);
      } else {
        // Message.content should just be plaintext, with our own <a> tags added in to preserve links
        pasteHtmlWithoutFocusing(
          quill,
          convertPlainTextToHtml(message.content),
        );
      }
    }
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quill, hideReplyThreads, contentElements.length, message]);

  return (
    <div
      className={classNames(
        messageCss.bubble,
        typeClassname,
        conversation.platform === "email" && messageCss.email,
      )}
    >
      {(contentElements.some((element) => element.length > 0) ||
        message.email?.htmlBody) && (
        <div className={classNames(quillEditorCss.quillContainerSmall)}>
          <QuillEditor
            editorClassName={classNames(
              quillEditorCss.quillEditorSmall,
              quillEditorCss.removePadding,
            )}
            readOnly
            ref={setQuill}
            toolbar={showViewReplyButton ? `messageToolbar${index}` : undefined}
          />
        </div>
      )}
      {!!message.files.length && (
        <div className={messageCss.fileList}>
          {message.files.map((file) => (
            <AttachmentThumbnail
              description={file.description}
              key={file._id}
              mimeType={file.mimeType}
              url={file.url}
            />
          ))}
        </div>
      )}
      {showViewReplyButton && (
        <div className={messageCss.quillToolbarContainer}>
          <div
            className={quillEditorCss.quillToolbar}
            id={`messageToolbar${index}`}
          >
            <Button
              onClick={() => {
                setHideReplyThreads(!hideReplyThreads);
              }}
              theme={ButtonTheme.GHOST}
            >
              <div
                className={
                  message.visibility === MessageVisibility.PUBLIC &&
                  message.type === "merchant"
                    ? messageCss.lightActionButton
                    : messageCss.actionButton
                }
              >
                <MeatballIcon />
              </div>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
});
