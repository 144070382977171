import { getMetaLeadAdsForms } from "./get-meta-lead-ads-forms";
import { getMetaLeadAdsIntegration } from "./get-meta-lead-ads-integration";
import { getMetaLeadAdsPages } from "./get-meta-lead-ads-pages";
import { getMetaLeadAdsWebhookStatus } from "./get-meta-lead-ads-webhook-status";
import { saveMetaLeadAdsFormMappings } from "./save-meta-lead-ads-form-mappings";
import { subscribeToMetaLeadAdsWebhooks } from "./subscribe-to-meta-lead-ads-webhooks";

export const metaLeadAds = {
  getMetaLeadAdsIntegration,
  getMetaLeadAdsPages,
  getMetaLeadAdsForms,
  getMetaLeadAdsWebhookStatus,
  saveMetaLeadAdsFormMappings,
  subscribeToMetaLeadAdsWebhooks,
};
