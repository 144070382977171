export enum ProductId {
  SUPPORT = "support",
  RETURNS = "returns",
}

export const SETTING_GROUP = {
  GENERAL: "general",
  CUSTOMER_SATISFACTION: "customer-satisfaction",
  CHANNELS: "channels",
} as const;

export type SettingGroup = (typeof SETTING_GROUP)[keyof typeof SETTING_GROUP];

type Setting = Readonly<{
  title: string;
  description: string;
  path: string;
  keywords: readonly string[];
  settingGroup: SettingGroup;
  productId: ProductId;
}>;

const supportGeneralSettings = {
  "auto-merge-enabled": {
    title: "Auto-merge open email tickets",
    description:
      "When a customer sends a new email, it will automatically merge into their existing open ticket if they have one from the last 24 hours.",
    path: "/general",
    keywords: ["merge", "email", "combine", "automatic", "tickets", "customer"],
    settingGroup: SETTING_GROUP.GENERAL,
    productId: ProductId.SUPPORT,
  },
  "default-email": {
    title: "Default email address",
    description:
      "The default email address that will be used when a customer creates a ticket or is making a rule.",
    path: "/general",
    keywords: ["email", "address", "default", "customer", "support"],
    settingGroup: SETTING_GROUP.GENERAL,
    productId: ProductId.SUPPORT,
  },
  "default-send-action": {
    title: "Default send behavior",
    description: "What happens when you hit 'Send' within a support ticket.",
    path: "/general",
    keywords: [
      "send",
      "message",
      "close",
      "in progress",
      "default",
      "action",
      "behavior",
    ],
    settingGroup: SETTING_GROUP.GENERAL,
    productId: ProductId.SUPPORT,
  },
  "use-in-progress-status": {
    title: "Use in progress ticket status",
    description:
      "Great for notifying your team that a ticket is actively being worked on by a team member.",
    path: "/general",
    keywords: ["status", "in progress", "active", "working", "notification"],
    settingGroup: SETTING_GROUP.GENERAL,
    productId: ProductId.SUPPORT,
  },
  "keep-assignee-on-reopen": {
    title: "Keep assignee when ticket is reopened",
    description:
      "If a ticket is reopened, keep the assignee as it was when the ticket was closed. Otherwise the assignee will be reset.",
    path: "/general",
    keywords: ["assignee", "reopen", "closed", "ticket", "reset", "keep"],
    settingGroup: SETTING_GROUP.GENERAL,
    productId: ProductId.SUPPORT,
  },
  "close-on-gmail-archive": {
    title: "Close tickets when archived in Gmail",
    description:
      "Automatically close tickets in Redo when they are archived in Gmail",
    path: "/general",
    keywords: ["gmail", "archive", "close", "automatic", "email"],
    settingGroup: SETTING_GROUP.GENERAL,
    productId: ProductId.SUPPORT,
  },
  "close-action": {
    title: "After closing ticket action",
    description:
      "What happens after closing a ticket in an individual ticket view",
    path: "/general",
    keywords: [
      "close",
      "action",
      "next",
      "table",
      "stay",
      "view",
      "after",
      "behavior",
    ],
    settingGroup: SETTING_GROUP.GENERAL,
    productId: ProductId.SUPPORT,
  },
  "undo-send": {
    title: "Allow 5 seconds to undo send for email",
    description: "Show the undo send option after sending an email",
    path: "/general",
    keywords: ["undo", "send", "timer", "behavior", "unsend"],
    settingGroup: SETTING_GROUP.GENERAL,
    productId: ProductId.SUPPORT,
  },
} satisfies Record<string, Setting>;

const supportCustomerSatisfactionSettings = {
  "csat-enabled": {
    title: "Enable CSAT",
    description:
      "Monitor and track how satisfied users are with your customer support service.",
    path: "/customer-satisfaction",
    keywords: [
      "csat",
      "satisfaction",
      "survey",
      "feedback",
      "monitor",
      "track",
    ],
    settingGroup: SETTING_GROUP.CUSTOMER_SATISFACTION,
    productId: ProductId.SUPPORT,
  },
  "survey-send-time": {
    title: "Send email survey after ticket closure",
    description:
      "Choose when to send the satisfaction survey after a ticket has been closed",
    path: "/customer-satisfaction",
    keywords: ["survey", "timing", "send", "after", "close", "delay", "email"],
    settingGroup: SETTING_GROUP.CUSTOMER_SATISFACTION,
    productId: ProductId.SUPPORT,
  },
  "survey-methods": {
    title: "Survey delivery methods",
    description: "Choose how you want to deliver your CSAT survey to customers",
    path: "/customer-satisfaction",
    keywords: ["survey", "method", "email", "chat", "send", "delivery"],
    settingGroup: SETTING_GROUP.CUSTOMER_SATISFACTION,
    productId: ProductId.SUPPORT,
  },
  "allow-remarks": {
    title: "Allow customer remarks",
    description:
      "Give customers a text input where they can record their thoughts and feelings about the service they received",
    path: "/customer-satisfaction",
    keywords: ["remarks", "comments", "feedback", "text", "input", "customer"],
    settingGroup: SETTING_GROUP.CUSTOMER_SATISFACTION,
    productId: ProductId.SUPPORT,
  },
  "header-image": {
    title: "Survey email header image",
    description:
      "Upload a banner image to display at the top of your survey emails (recommended size: 670x120, aspect ratio 16:9)",
    path: "/customer-satisfaction",
    keywords: ["header", "image", "banner", "email", "survey", "logo"],
    settingGroup: SETTING_GROUP.CUSTOMER_SATISFACTION,
    productId: ProductId.SUPPORT,
  },
  "email-body": {
    title: "Survey email content",
    description:
      "Customize the message content that appears in your survey emails",
    path: "/customer-satisfaction",
    keywords: [
      "email",
      "body",
      "content",
      "message",
      "survey",
      "customize",
      "text",
    ],
    settingGroup: SETTING_GROUP.CUSTOMER_SATISFACTION,
    productId: ProductId.SUPPORT,
  },
} satisfies Record<string, Setting>;

const supportSettings = {
  ...supportGeneralSettings,
  ...supportCustomerSatisfactionSettings,
} satisfies Record<string, Setting>;

const merchantAppSettings = { ...supportSettings } satisfies Record<
  string,
  Setting
>;

export type MerchantAppSetting = Setting & {
  id: keyof typeof merchantAppSettings;
};

export type MerchantAppSettingKey = keyof typeof merchantAppSettings;

export const getMerchantAppSetting = (
  key: MerchantAppSettingKey,
): MerchantAppSetting => {
  return { id: key, ...merchantAppSettings[key] };
};

export const allMerchantAppSettings: Array<MerchantAppSetting> = Object.keys(
  merchantAppSettings,
).map((key) => ({
  id: key as MerchantAppSettingKey,
  ...merchantAppSettings[key as MerchantAppSettingKey],
}));
