import { RedoMerchantClient } from "@redotech/redo-merchant-app-common/client";
import { KlaviyoEventType } from "@redotech/redo-model/klaviyo-integration";

/**
 * GET /integrations
 */
export async function getIntegrations(
  client: RedoMerchantClient,
  { signal }: { signal?: AbortSignal },
) {
  const response = await client.client.get("integrations", {
    headers: client.authorization(),
    signal,
  });
  return response.data;
}

/**
 * GET /integrations/klaviyo
 */
export async function getKlaviyoIntegrationEvents(
  client: RedoMerchantClient,
  { signal }: { signal?: AbortSignal },
): Promise<KlaviyoEventType[] | null> {
  const response = await client.client.get("integrations/klaviyo", {
    headers: client.authorization(),
    signal,
  });
  return response.data.events;
}

/**
 * PUT /integrations/klaviyo
 */
export async function updateKlaviyoIntegrationEvents(
  client: RedoMerchantClient,
  {
    customerAccountCreatedEnabled,
    productAddedToWishlistEnabled,
    signal,
  }: {
    customerAccountCreatedEnabled?: boolean;
    productAddedToWishlistEnabled?: boolean;
    signal?: AbortSignal;
  },
) {
  const response = await client.client.put(
    "integrations/klaviyo",
    { customerAccountCreatedEnabled, productAddedToWishlistEnabled },
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * POST /integrations/klaviyo
 */
export async function createKlaviyoIntegration(
  client: RedoMerchantClient,
  {
    apiKey,
    events,
    signal,
  }: { apiKey: string; events: KlaviyoEventType[]; signal?: AbortSignal },
): Promise<void> {
  await client.client.post(
    "integrations/klaviyo",
    { apiKey, events },
    { headers: client.authorization(), signal },
  );
}

export async function getGoogleAdsOAuthURL(
  client: RedoMerchantClient,
  { signal }: { signal?: AbortSignal } = {},
): Promise<any> {
  const response = await client.client.get(
    "integrations/google-ads/getOAuthURL",
    { headers: client.authorization(), signal },
  );
  return response.data;
}

export async function getGoogleAdsIntegration(
  client: RedoMerchantClient,
  { signal }: { signal?: AbortSignal } = {},
): Promise<any> {
  const response = await client.client.get("integrations/google-ads", {
    headers: client.authorization(),
    signal,
  });
  return response.data;
}

export async function getBrightpearlIntegration(
  client: RedoMerchantClient,
  { signal }: { signal?: AbortSignal } = {},
): Promise<any> {
  const response = await client.client.get("integrations/brightpearl", {
    headers: client.authorization(),
    signal,
  });
  return response.data;
}

export async function deleteBrightpearlIntegration(
  client: RedoMerchantClient,
  { signal }: { signal?: AbortSignal } = {},
): Promise<void> {
  await client.client.delete("integrations/brightpearl", {
    headers: client.authorization(),
    signal,
  });
}

export async function getBrightpearlOAuthURL(
  client: RedoMerchantClient,
  account: string,
  { signal }: { signal?: AbortSignal } = {},
): Promise<any> {
  const response = await client.client.get(
    `integrations/brightpearl/getOAuthURL/${account}`,
    { headers: client.authorization(), signal },
  );
  return response.data;
}

export async function getCommentSoldIntegration(
  client: RedoMerchantClient,
  { signal }: { signal?: AbortSignal } = {},
): Promise<any> {
  const response = await client.client.get("integrations/commentsold", {
    headers: client.authorization(),
    signal,
  });
  return response.data;
}

export async function deleteCommentSoldIntegration(
  client: RedoMerchantClient,
  { signal }: { signal?: AbortSignal } = {},
): Promise<void> {
  await client.client.delete("integrations/commentsold", {
    headers: client.authorization(),
    signal,
  });
}

export async function getCommentSoldOAuthURL(
  client: RedoMerchantClient,
  isStaging: boolean,
  { signal }: { signal?: AbortSignal } = {},
): Promise<any> {
  const response = await client.client.get(
    "integrations/commentsold/getOAuthURL",
    { params: { isStaging }, headers: client.authorization(), signal },
  );
  return response.data;
}

export async function createCommentSoldIntegration(
  client: RedoMerchantClient,
  { storeID, signal }: { storeID: string; signal?: AbortSignal },
): Promise<any> {
  return await client.client.post(
    "integrations/commentsold/create",
    { storeID },
    { headers: client.authorization(), signal },
  );
}
