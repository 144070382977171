import { memo } from "react";
import { Flex } from "../../../../flex";
import { Text } from "../../../../text";
import { DAY_NAMES } from "../utils/date-picker-utils";

export const DayNamesHeader = memo(function DayNamesHeader() {
  return (
    <Flex dir="row" gap="none" justify="space-between" style={{ padding: 0 }}>
      {DAY_NAMES.map((day) => (
        <Flex
          align="center"
          gap="none"
          justify="center"
          key={day}
          style={{ height: "40px", width: "40px" }}
        >
          <Text fontSize="sm" fontWeight="medium" textColor="secondary">
            {day}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
});
