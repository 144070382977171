// extracted by mini-css-extract-plugin
export var container = "_21-0";
export var error = "_21-9";
export var label = "_21-5";
export var light = "_21-6";
export var md = "_21-3";
export var sm = "_21-2";
export var thin = "_21-8";
export var thinBold = "_21-7";
export var vertical = "_21-4";
export var xs = "_21-1";