import { RedoTextInput } from "@redotech/redo-web/arbiter-components/input/redo-text-input";
import {
  RedoModal,
  RedoModalButtonProps,
} from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo, useState } from "react";

export const CreateOmsViewFolderModal = memo(function CreateOmsViewFolderModal({
  isOpen,
  onClose,
  onSubmit,
  reservedFolderNames,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (folderName: string) => Promise<void>;
  reservedFolderNames: Set<string>;
}) {
  const [folderName, setFolderName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      await onSubmit(folderName);
      onClose();
    } finally {
      setIsSubmitting(false);
      setFolderName("");
    }
  };

  const primaryButton: RedoModalButtonProps = {
    text: "Create",
    onClick: handleSubmit,
    disabled:
      !folderName.trim().length || reservedFolderNames.has(folderName.trim()),
  };

  const secondaryButton: RedoModalButtonProps = {
    text: "Cancel",
    onClick: onClose,
    disabled: isSubmitting,
    pending: false,
  };

  return (
    <RedoModal
      buttonPlacement="full"
      isOpen={isOpen}
      onModalCloseRequested={isSubmitting ? () => {} : onClose}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      title="Create folder"
    >
      <Flex dir="column" gap="xl">
        <Flex dir="column">
          <Text>Folder name</Text>
          <div data-devinid="folder-name-input">
            <RedoTextInput
              setValue={setFolderName}
              size="md"
              state={isSubmitting ? "readonly" : "default"}
              value={folderName}
            />
          </div>
        </Flex>
      </Flex>
    </RedoModal>
  );
});
