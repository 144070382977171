import { useRequiredContext } from "@redotech/react-util/context";
import {
  useSearch,
  useSearchBackend,
  UseSearchBackendResultType,
} from "@redotech/react-util/search";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { RedoTagInputField } from "@redotech/redo-web/arbiter-components/input/redo-tag-input-field";
import { isValidEmail } from "@redotech/util/email";
import { assertNever } from "@redotech/util/type";
import {
  Dispatch,
  memo,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";

import { getCustomerSearch } from "@redotech/redo-merchant-app-common/client/shopify";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { RedoListItem } from "@redotech/redo-web/arbiter-components/list/redo-list";
import { RedoListDropdown } from "@redotech/redo-web/arbiter-components/list/redo-list-dropdown";
import { unique } from "@redotech/util/array";
import Fuse from "fuse.js";

const validateNewInputs = (
  emailCandidates: readonly string[],
): readonly string[] => {
  return emailCandidates.filter(isValidEmail);
};

const userFuse = new Fuse([], { keys: ["email"], threshold: 0.5 });

export const EmailListChipInput = memo(function EmailListChipInput({
  title,
  infoText,
  emailsToShow,
  setEmails,
}: {
  title: string;
  infoText: string;
  emailsToShow: readonly string[];
  setEmails: Dispatch<SetStateAction<readonly string[]>>;
}) {
  const [searchMenuOpen, setSearchMenuOpen] = useState<boolean>(false);

  const team = useRequiredContext(TeamContext);
  const client = useRequiredContext(RedoMerchantClientContext);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setSearchMenuOpen(searchText.length > 0);
  }, [searchText]);

  const usersForSearch = useMemo(() => {
    return team.users.filter((user) => user.email);
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const memoizedCustomerSearch = useMemo(() => {
    return (searchText: string, abortSignal: AbortSignal) =>
      getCustomerSearch(client, {
        search: searchText,
        signal: abortSignal,
      }).then((res) => res.customers);
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customerSearchResults = useSearchBackend({
    memoizedDocFetcher: memoizedCustomerSearch,
    searchText,
  });

  const userSearchResults = useSearch(userFuse, usersForSearch, searchText);

  const [menuRef, setMenuRef] = useState<HTMLDivElement | null>(null);

  const itemsLoading =
    customerSearchResults.type === UseSearchBackendResultType.SEARCH_PENDING ||
    customerSearchResults.type === UseSearchBackendResultType.DEBOUNCING;

  const searchResultList: RedoListItem<string>[] = useMemo(() => {
    const resultsToShow: string[] = unique(
      [...userSearchResults, ...(customerSearchResults.results || [])]
        .filter(
          (result: any) => validateNewInputs([result.email || ""]).length > 0,
        )
        .filter((result: any) => !emailsToShow.includes(result.email))
        .map((result: any) => result.email),
    );

    switch (customerSearchResults.type) {
      case UseSearchBackendResultType.ERROR:
      case UseSearchBackendResultType.DEBOUNCING:
      case UseSearchBackendResultType.SEARCH_PENDING:
        return [];
      case UseSearchBackendResultType.RESOLVED:
        return resultsToShow.map<RedoListItem<any>>((result) => ({
          type: "text",
          id: result,
          text: result,
          value: result,
        }));
      default:
        assertNever(customerSearchResults.type);
    }
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSearchResults.trigger]);

  return (
    <div ref={setMenuRef}>
      <RedoTagInputField
        delimeter="both"
        infoTooltip={infoText}
        inputValue={searchText}
        label={title}
        placeholder="Type an email and press enter"
        setInputValue={setSearchText}
        setTags={(newTagSet) => {
          setEmails(() => [...new Set(validateNewInputs([...newTagSet]))]);
        }}
        size="sm"
        tags={emailsToShow.map((email) => ({ text: email, id: email }))}
      />
      <RedoListDropdown
        dropdownAnchor={menuRef}
        dropdownOpen={searchMenuOpen}
        fitToAnchor
        items={searchResultList}
        itemSelected={({ item }) => {
          setEmails((emailsToShow) => [
            ...new Set(validateNewInputs([...emailsToShow, item.value])),
          ]);
          setSearchText("");
        }}
        itemsLoading={itemsLoading}
        setDropdownOpen={setSearchMenuOpen}
        size="xs"
      />
    </div>
  );
});

export const recipientDescriptions = {
  toTitle: "To",
  toInfo: "The primary recipients of the email.",
  ccTitle: "CC",
  bccTitle: "BCC",
  ccInfo:
    "A CC (Carbon Copy) address in an email allows you to send a copy of the message to additional recipients, making their email addresses visible to all recipients.",
  bccInfo:
    "A BCC (Blind Carbon Copy) address enables you to send an email to multiple recipients without revealing their email addresses to each other, ensuring privacy and confidentiality.",
};
