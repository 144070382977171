import { baseProductFilterSchema } from "@redotech/redo-model/product-filters/product-filters-db-parser";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const getProductFilterById = {
  input: z.object({ id: z.string() }),
  output: z.intersection(
    z.object({ _id: zExt.objectId() }),
    baseProductFilterSchema,
  ),
};
