import { Return } from "@redotech/redo-model/return";
import { RedoLinkInput } from "@redotech/redo-web/arbiter-components/input/redo-link-input";
import {
  RedoTextareaInputField,
  RedoTextareaInputFieldSize,
} from "@redotech/redo-web/arbiter-components/input/redo-textarea-input-field";
import { RedoModal } from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import { RedoRadioGroup } from "@redotech/redo-web/arbiter-components/radio/redo-radio-group";
import CheckCircle from "@redotech/redo-web/arbiter-icon/check-circle.svg";
import LinkExternal2 from "@redotech/redo-web/arbiter-icon/link-external-02.svg";
import RefreshCcw from "@redotech/redo-web/arbiter-icon/refresh-ccw-01_filled.svg";
import { Flex } from "@redotech/redo-web/flex";
import { Flowchart } from "@redotech/redo-web/flowchart";
import { AnimatedSkeletonBlock } from "@redotech/redo-web/skeleton";
import { assertNever } from "@redotech/util/type";
import * as resetModalCss from "./reset-modal.module.css";
import { IconType, Page, useResetModal } from "./use-reset-modal";

export const ResetModal = ({
  open,
  onClose,
  onSubmitFinished,
  return: return_,
}: {
  open: boolean;
  onClose: () => void;
  onSubmitFinished: () => void;
  return: Return;
}) => {
  const {
    icon,
    texts,
    resetReason: rejectionReason,
    onResetReasonChange: onRejectionReasonChange,
    onGoToChangeResetPointPage,
    onContinue,
    onCancel,
    page,
    flowchartProps,
    continueButtonDisabled,
    cancelButtonDisabled,
    loading,
    setChangeDefaultStepSelection,
    changeDefaultStepSelection,
  } = useResetModal({ return_, onSubmitFinished });

  return (
    <RedoModal
      buttonPlacement="full"
      isOpen={open}
      onModalCloseRequested={onClose}
      primaryButton={{
        text: texts.continueButton,
        disabled: continueButtonDisabled,
        onClick: onContinue,
      }}
      secondaryButton={{
        text: texts.cancelButton,
        disabled: cancelButtonDisabled,
        onClick: onCancel,
      }}
      subtitle={texts.subtitle}
      theme={(() => {
        switch (page) {
          case Page.CONFIRM:
            return "success";
          case Page.CHANGE_RESET_POINT:
            return "neutral";
          default:
            assertNever(page);
        }
      })()}
      title={texts.title}
      TitleIcon={() => {
        switch (icon) {
          case IconType.CHECK_CIRCLE:
            return <CheckCircle />;
          case IconType.REFRESH_CCW:
            return <RefreshCcw />;
          default:
            assertNever(icon);
        }
      }}
    >
      {(() => {
        switch (page) {
          case Page.CONFIRM:
            return (
              <Flex dir="column" gap="xl">
                {loading ? (
                  <Flex dir="column" gap="sm">
                    <AnimatedSkeletonBlock height="20px" width="80px" />
                    <AnimatedSkeletonBlock height="40px" width="100%" />
                  </Flex>
                ) : (
                  <RedoLinkInput
                    icon={LinkExternal2}
                    label={texts.resetPointLabel}
                    onClick={onGoToChangeResetPointPage}
                    placeholder={texts.resetPointPlaceholder}
                    size="md"
                    value={texts.resetPointText}
                  />
                )}
                <RedoTextareaInputField
                  containerHeight="144px"
                  label={texts.resetReasonLabel}
                  placeholder={texts.resetReasonPlaceholder}
                  setValue={onRejectionReasonChange}
                  size={RedoTextareaInputFieldSize.REGULAR}
                  value={rejectionReason}
                />
              </Flex>
            );
          case Page.CHANGE_RESET_POINT:
            return (
              <Flex dir="column" gap="2xl">
                <div className={resetModalCss.flowchartContainer}>
                  <Flowchart {...flowchartProps} showBorder />
                </div>

                <RedoRadioGroup
                  label="Change default step"
                  options={[
                    {
                      label: "Change reset point for this return only",
                      id: false,
                    },
                    {
                      label:
                        "Change reset point for this and all future returns",
                      id: true,
                    },
                  ]}
                  setValue={(value: boolean) =>
                    setChangeDefaultStepSelection(value)
                  }
                  value={changeDefaultStepSelection}
                />
              </Flex>
            );

          default:
            assertNever(page);
        }
      })()}
    </RedoModal>
  );
};
