// extracted by mini-css-extract-plugin
export var alternate = "_47-h";
export var black = "_47-3";
export var blue = "_47-d";
export var blueLight = "_47-c";
export var brand = "_47-7";
export var error = "_47-9";
export var gray = "_47-6";
export var grayBlue = "_47-b";
export var iconWrapper = "_47-4";
export var indigo = "_47-e";
export var orange = "_47-g";
export var pink = "_47-f";
export var primary = "_47-0";
export var purple = "_47-8";
export var success = "_47-5";
export var warning = "_47-a";
export var white = "_47-1";
export var whiteSuccess = "_47-2";