import { z } from "zod";
import { zExt } from "../common/zod-util";
import { PermissionEntrySchema } from "../order-schema";

export const OMSUserSchema = z.object({
  roles: z.array(z.string()),
  _id: z.string(),
  email: z.string(),
  createdAt: z.coerce.date(),
  firstName: z.string(),
  lastName: z.string(),
  name: z.string(),
  updatedAt: z.coerce.date(),
  team: zExt.objectId(),
  permissions: z.array(PermissionEntrySchema).nullish(),
});
export type OMSUser = z.infer<typeof OMSUserSchema>;

export const unassignedUser: OMSUser = {
  roles: [],
  _id: "unassigned",
  email: "",
  createdAt: new Date(),
  firstName: "Unassigned",
  lastName: "",
  name: "Unassigned",
  updatedAt: new Date(),
  team: "",
};
