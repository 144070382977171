import { zExt } from "@redotech/redo-model/common/zod-util";
import {
  DiscountGroupType,
  DiscountProvider,
} from "@redotech/redo-model/discount";
import {
  discountSettingsSchema,
  expirationSchema,
} from "@redotech/redo-model/discount/discount-db-parser";
import { z } from "zod";

export type DiscountCodeGenerationStrategy = z.infer<
  typeof codeGenerationStrategy
>;
const codeGenerationStrategy = z.object({
  strategy: z.nativeEnum(DiscountGroupType),
  code: z.string(),
});

export type RedoDiscountConfiguration = z.infer<typeof discountConfiguration>;
/**
 * Shipping discounts are not implemented yet.
 * When that happens, this will need to be a discriminated union of basic + shipping shopify types
 */
export const discountConfiguration = z.object({
  name: z.string(),
  provider: z.literal(DiscountProvider.SHOPIFY),
  codeGenerationStrategy: codeGenerationStrategy,
  expiration: expirationSchema,
  discountSettings: discountSettingsSchema,
});

export type RedoDiscount = z.infer<typeof redoDiscountSchema>;
export const redoDiscountSchema = z.intersection(
  discountConfiguration,
  z.object({
    _id: zExt.objectId(),
    teamId: zExt.objectId(),
    providerIdentifier: z.string(),
  }),
);
