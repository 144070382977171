import { useRequiredContext } from "@redotech/react-util/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import {
  ConversationPlatform,
  ExpandedConversation,
} from "@redotech/redo-model/conversation";
import { getPrimaryCustomerEmail } from "@redotech/redo-model/customer";
import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo, useState } from "react";
import { NewMessageModal } from "./new-message-modal";
import * as offerVoiceConversionCss from "./offer-voice-conversion.module.css";

export const OfferVoiceConversion = memo(function OfferVoiceConversion({
  conversation,
}: {
  conversation: ExpandedConversation;
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [initialPlatform, setInitialPlatform] = useState<ConversationPlatform>(
    ConversationPlatform.EMAIL,
  );
  const team = useRequiredContext(TeamContext);

  const primaryEmail = getPrimaryCustomerEmail(conversation.customer);

  const openModalWithPlatform = (platform: ConversationPlatform) => {
    setInitialPlatform(platform);
    setModalOpen(true);
  };

  return (
    <Flex height="half-screen" justify="center" w="full">
      <Flex
        align="center"
        className={offerVoiceConversionCss.voiceConversionContent}
        dir="column"
      >
        <Text fontSize="md" fontWeight="semibold">
          Convert ticket
        </Text>
        <Text fontSize="sm" textAlign="center" textColor="tertiary">
          This ticket is currently a voice ticket only and does not support
          messaging. In order to reply to this customer via messaging:
        </Text>
        <Flex>
          {team.settings.support?.billing?.textMessaging?.enabled && (
            <RedoButton
              hierarchy="primary"
              onClick={() => openModalWithPlatform(ConversationPlatform.SMS)}
              size="sm"
              text="Reply via SMS"
            />
          )}
          <RedoButton
            hierarchy="primary"
            onClick={() => openModalWithPlatform(ConversationPlatform.EMAIL)}
            size="sm"
            text="Reply via email"
          />
        </Flex>
      </Flex>
      {modalOpen && (
        <NewMessageModal
          customerInfo={
            primaryEmail
              ? { email: primaryEmail, _id: conversation.customer.customer }
              : undefined
          }
          existingConversation={conversation}
          initialConversationPlatform={initialPlatform}
          open={modalOpen}
          setOpen={setModalOpen}
        />
      )}
    </Flex>
  );
});
