import {
  createLazyContext,
  useLazyContextProvider,
  useRequiredContext,
} from "@redotech/react-util/context";

import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { SenderEmail } from "@redotech/redo-model/team";
import { memo, ReactNode } from "react";
import { getGmailIntegrations } from "../../client/gmail";
import { getOutlookIntegrations } from "../../client/outlook";
import {
  GmailIntegration,
  OutlookIntegration,
} from "../../setting/support-email-info";

export type EmailIntegrations = {
  gmailIntegrations: GmailIntegration[];
  outlookIntegrations: OutlookIntegration[];
  customIntegrations: SenderEmail[];
};

export const EmailIntegrationsContext = createLazyContext<EmailIntegrations>();

export const EmailIntegrationsService = memo(function EmailIntegrationsService({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const client = useRequiredContext(RedoMerchantClientContext);
  const team = useRequiredContext(TeamContext);

  const loadState = useLazyContextProvider(async (signal) => {
    const customIntegrations = team.settings.support?.senderEmails || [];
    const gmailIntegrations = (await getGmailIntegrations(client, { signal }))
      ?.integrations;
    const outlookIntegrations = (
      await getOutlookIntegrations(client, { signal })
    )?.integrations;
    return { customIntegrations, gmailIntegrations, outlookIntegrations };
  });

  return (
    <EmailIntegrationsContext.internalContext.Provider value={loadState}>
      {children}
    </EmailIntegrationsContext.internalContext.Provider>
  );
});
