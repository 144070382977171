import { useRequiredContext } from "@redotech/react-util/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { RedoSecondaryNavItem } from "@redotech/redo-web/arbiter-components/navigation/secondary/redo-secondary-nav-item";
import ScalesIcon from "@redotech/redo-web/arbiter-icon/scales-01.svg";
import ScalesFilledIcon from "@redotech/redo-web/arbiter-icon/scales-01_filled.svg";
import { memo } from "react";

export const AbTestItems = memo(function AbTestItems({
  conversionRoot,
}: {
  conversionRoot: string;
}) {
  const team = useRequiredContext(TeamContext);

  const checkoutOptimizationEnabled =
    team?.settings.checkoutOptimization?.enabled;

  if (!checkoutOptimizationEnabled) {
    return null;
  }
  return (
    <RedoSecondaryNavItem
      action={{ type: "link", href: `${conversionRoot}/ab-tests` }}
      FilledIcon={ScalesFilledIcon}
      friendlyName="A/B tests"
      id="ab-tests"
      UnfilledIcon={ScalesIcon}
    />
  );
});
