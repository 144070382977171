import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { getReturner, MerchantAppReturn } from "@redotech/redo-model/return";
import { RedoHorizontalTabs } from "@redotech/redo-web/arbiter-components/tabs/redo-horizontal-tabs";
import { Divider } from "@redotech/redo-web/divider";
import { Flex } from "@redotech/redo-web/flex";
import { SkeletonText } from "@redotech/redo-web/skeleton";
import { Text } from "@redotech/redo-web/text";
import { memo, useContext, useMemo, useState } from "react";
import { CustomerHistoryTab } from "./customer-tabs/customer-history-tab";
import { CustomerInformationTab } from "./customer-tabs/customer-information-tab";
import { CustomerSupportTab } from "./customer-tabs/customer-support-tab";
import * as returnPageRightPanelCss from "./return-page-right-panel.module.css";

enum CustomerTab {
  Information = "Information",
  History = "History",
  Support = "Support",
}

export const CustomerTabsCard = memo(function CustomerTabsCard({
  return: return_,
}: {
  return?: MerchantAppReturn;
}) {
  const team = useContext(TeamContext);
  const [selectedTab, setSelectedTab] = useState<CustomerTab>(
    CustomerTab.Information,
  );

  const tabs = useMemo(() => {
    const availableTabs = [
      { key: CustomerTab.Information, label: "Information" },
      { key: CustomerTab.History, label: "History" },
    ];

    if (team?.settings.support?.enabled) {
      availableTabs.push({ key: CustomerTab.Support, label: "Support" });
    }

    return availableTabs;
  }, [team?.settings.support]);

  const child = useMemo(() => {
    if (!return_) {
      return null;
    }
    switch (selectedTab) {
      case CustomerTab.Information:
        return (
          <CustomerInformationTab
            order={return_.products[0].order}
            return={return_}
          />
        );
      case CustomerTab.History:
        return <CustomerHistoryTab return={return_} />;
      case CustomerTab.Support:
        return (
          <CustomerSupportTab
            customerEmail={getReturner(return_).email ?? undefined}
            returnType={return_.type}
          />
        );
    }
  }, [selectedTab, return_]);

  if (!return_) {
    return (
      <Flex className={returnPageRightPanelCss.cardPadding} dir="row" gap="xl">
        <Flex dir="column" gap="xs">
          <Text fontSize="xs" fontWeight="regular" textColor="tertiary">
            Information
          </Text>
          <SkeletonText animated length={8} />
        </Flex>
        <Divider />
        <Flex dir="column" gap="xs">
          <Text fontSize="xs" fontWeight="regular" textColor="tertiary">
            History
          </Text>
          <SkeletonText animated length={8} />
        </Flex>
        <Divider />
        <Flex dir="column" gap="xs">
          <Text fontSize="xs" fontWeight="regular" textColor="tertiary">
            Support
          </Text>
          <SkeletonText animated length={8} />
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex className={returnPageRightPanelCss.cardPadding} dir="column" gap="xl">
      <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
        Customer
      </Text>
      <Flex dir="column" grow={1}>
        <RedoHorizontalTabs
          pl="none"
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          size="xs"
          tabs={tabs}
        />
        {child}
      </Flex>
    </Flex>
  );
});
