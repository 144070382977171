import { useRequiredContext } from "@redotech/react-util/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { RedoSecondaryNavItem } from "@redotech/redo-web/arbiter-components/navigation/secondary/redo-secondary-nav-item";
import ReceiptCheckIcon from "@redotech/redo-web/arbiter-icon/receipt-check.svg";
import ReceiptCheckFilledIcon from "@redotech/redo-web/arbiter-icon/receipt-check_filled.svg";
import { memo } from "react";

export const CheckoutItems = memo(function CheckoutItems({
  conversionRoot,
}: {
  conversionRoot: string;
}) {
  const team = useRequiredContext(TeamContext);

  const checkoutOptimizationEnabled =
    team?.settings.checkoutOptimization?.enabled;

  if (!checkoutOptimizationEnabled) {
    return null;
  }
  return (
    <RedoSecondaryNavItem
      action={{ type: "link", href: `${conversionRoot}/checkouts` }}
      FilledIcon={ReceiptCheckFilledIcon}
      friendlyName="Checkouts"
      id="checkouts"
      UnfilledIcon={ReceiptCheckIcon}
    />
  );
});
