import { useRequiredContext } from "@redotech/react-util/context";
import { RedoCheckbox } from "@redotech/redo-web/arbiter-components/checkbox/redo-checkbox";
import { RedoTextInput } from "@redotech/redo-web/arbiter-components/input/redo-text-input";
import {
  RedoModal,
  RedoModalButtonProps,
} from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo, useCallback, useState } from "react";
import { DefaultViews, ViewsContext } from "../app/views";
import { viewUrlNameToDisplayName } from "./view-name-utils";

export const CreateFolderModal = memo(function CreateFolderModal({
  isOpen,
  onClose,
  onSubmit,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (
    folderName: string,
    selectedViewIds: string[],
    defaultViews: string[],
  ) => Promise<void>;
}) {
  const [folderName, setFolderName] = useState("");
  const [selectedViews, setSelectedViews] = useState<Record<string, boolean>>(
    {},
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleViewSelect = useCallback((viewId: string, checked: boolean) => {
    setSelectedViews((prev) => ({ ...prev, [viewId]: checked }));
  }, []);

  const viewsContext = useRequiredContext(ViewsContext);

  // Combine context arrays for a full list of user/team views
  const allViews = [
    ...Object.values(DefaultViews).map((view) => ({
      _id: view,
      name: viewUrlNameToDisplayName(view),
    })),
    ...viewsContext,
  ].map((view) => ({ _id: view._id || view.name, name: view.name }));

  const hasSelectedViews = Object.values(selectedViews).some(
    (isSelected) => isSelected,
  );
  const submitDisabled =
    !folderName.trim().length || !hasSelectedViews || isSubmitting;

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const selectedIds = Object.entries(selectedViews)
        .filter(([_, checked]) => checked)
        .map(([viewId]) => viewId);
      const defaultViews = selectedIds.filter((id) =>
        Object.values(DefaultViews).includes(id as DefaultViews),
      );
      const customViews = selectedIds.filter(
        (id) => !Object.values(DefaultViews).includes(id as DefaultViews),
      );
      await onSubmit(folderName, customViews, defaultViews);
      onClose();
    } finally {
      setIsSubmitting(false);
      setFolderName("");
      setSelectedViews({});
    }
  };

  const primaryButton: RedoModalButtonProps = {
    text: "Create",
    onClick: handleSubmit,
    disabled: submitDisabled,
  };

  const secondaryButton: RedoModalButtonProps = {
    text: "Cancel",
    onClick: onClose,
    disabled: isSubmitting,
  };

  return (
    <RedoModal
      buttonPlacement="full"
      isOpen={isOpen}
      onModalCloseRequested={isSubmitting ? () => {} : onClose}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      title="Create folder"
    >
      <Flex dir="column" gap="xl">
        <Flex dir="column">
          <Text>Folder name</Text>
          <div data-devinid="folder-name-input">
            <RedoTextInput
              setValue={setFolderName}
              size="md"
              state={isSubmitting ? "readonly" : "default"}
              value={folderName}
            />
          </div>
        </Flex>
        <Text>Select Views</Text>
        <Flex dir="column" gap="md" pl="sm">
          {allViews.map((view) => (
            <div key={view._id}>
              <Flex>
                <RedoCheckbox
                  label={view.name}
                  setValue={(val: boolean) => handleViewSelect(view._id, val)}
                  size="md"
                  value={Boolean(selectedViews[view._id])}
                />
              </Flex>
            </div>
          ))}
        </Flex>
      </Flex>
    </RedoModal>
  );
});
