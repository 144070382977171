import { memo } from "react";
import * as notificationCss from "./notification.module.css";
import { LogInToRedo } from "./notifications/log-in-to-redo";
import { NewPermissions } from "./notifications/new-permissions";
import { ShopifyBilling } from "./notifications/shopify-billing";

export const NotificationBanner = memo(function NotificationBanner() {
  return (
    <div className={notificationCss.updateBanner}>
      <NewPermissions />
      <ShopifyBilling />
      <LogInToRedo />
    </div>
  );
});
