// extracted by mini-css-extract-plugin
export var danger = "_4c-6";
export var lightPink = "_4c-a";
export var medium = "_4c-j";
export var nearSquare = "_4c-m";
export var neutralBlue = "_4c-8";
export var neutralYellow = "_4c-7";
export var noCapitalize = "_4c-1";
export var normal = "_4c-2";
export var pill = "_4c-0";
export var primary = "_4c-3";
export var primaryLight = "_4c-4";
export var shadow = "_4c-n";
export var small = "_4c-k";
export var solidBlack = "_4c-i";
export var solidGreen = "_4c-d";
export var solidLightBlue = "_4c-f";
export var solidNavy = "_4c-e";
export var solidOrange = "_4c-h";
export var solidPink = "_4c-g";
export var solidRed = "_4c-b";
export var solidWhite = "_4c-9";
export var solidYellow = "_4c-c";
export var success = "_4c-5";
export var xsmall = "_4c-l";