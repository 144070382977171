import { composeWrappers } from "@redotech/react-util/component";
import { filterArrayFormat } from "@redotech/redo-model/conversation-filters/conversation-filter-formats";
import {
  filtersToQueryString,
  queryStringToFilters,
} from "@redotech/redo-model/conversation-filters/conversation-filter-query";
import {
  AdvancedFilter,
  AssigneesFilter,
  AssigneesFilterType,
  ConversationFiltersV3,
  FilterGroupFilterOption,
  FiltersStatus,
  PendingAdvancedFilter,
  UniqueV3Filters,
} from "@redotech/redo-model/conversation-filters/conversation-filters";
import { conversationFiltersEquivalent } from "@redotech/redo-model/conversation-filters/conversation-filters-equal";
import {
  ReactElement,
  createContext,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { ActiveViewContext } from "./active-view-context";

export const UniqueFiltersContext = createContext<UniqueV3Filters>({});

export const SetUniqueFiltersContext = createContext<
  (filters: UniqueV3Filters) => void
>(() => {});

export const AdvancedFiltersContext = createContext<
  (AdvancedFilter | PendingAdvancedFilter)[]
>([]);

export const SetAdvancedFiltersContext = createContext<
  React.Dispatch<
    React.SetStateAction<(AdvancedFilter | PendingAdvancedFilter)[]>
  >
>(() => {});

export const FinalizedFiltersContext = createContext<ConversationFiltersV3>({
  advancedFilters: [],
});

/**
 * Certain races will happen on initial page load, including:
 * - this context inits filters to a default value
 * - this context check the URL for filters and applies them
 * - any components with setters to filters can run them on page load
 *
 * So, use this to know: "When has the filters context finished initializing, and it's safe to check filters / filter URL? "
 */
export const HaveFiltersResolvedOnInitialPageLoadContext =
  createContext<boolean>(false);

export const FiltersProvider = memo(function FiltersProvider({
  children,
}: {
  children: ReactElement;
}) {
  const activeView = useContext(ActiveViewContext);

  const [uniqueFilters, setUniqueFilters] = useState<UniqueV3Filters>(
    activeView.filters,
  );

  const [advancedFilters, setAdvancedFilters] = useState<
    (AdvancedFilter | PendingAdvancedFilter)[]
  >(activeView.filters.advancedFilters);

  const nonPendingAdvancedFilters = useMemo(
    () => advancedFilters.filter((filter) => filter.value !== null),
    [advancedFilters],
  );

  const nonPendingAdvancedFiltersString = useMemo(
    () => JSON.stringify(filterArrayFormat.write(nonPendingAdvancedFilters)),
    [nonPendingAdvancedFilters],
  );

  const [
    haveFiltersResolvedOnInitialPageLoad,
    setHaveFiltersResolvedOnInitialPageLoad,
  ] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setUniqueFilters(activeView.filters);
    setAdvancedFilters(activeView.filters.advancedFilters);
  }, [activeView]);

  useEffect(() => {
    const filters = queryParamsToFilters(location.search);
    if (filters) {
      setUniqueFilters(filters);
      setAdvancedFilters(filters.advancedFilters);
      _updateFilters(filters, activeView.filters, navigate);
    }

    /**
     * After updating filters from URL, and say we're resolved on the _next_ render.
     */

    setHaveFiltersResolvedOnInitialPageLoad(true);
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const finalizedFilters = useMemo(() => {
    return {
      ...activeView.filters,
      ...uniqueFilters,
      advancedFilters: nonPendingAdvancedFilters,
    };
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeView, uniqueFilters, nonPendingAdvancedFiltersString]);

  useEffect(() => {
    if (!haveFiltersResolvedOnInitialPageLoad) {
      return;
    }
    _updateFilters(finalizedFilters, activeView.filters, navigate);
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeView, finalizedFilters, haveFiltersResolvedOnInitialPageLoad]);

  useEffect(() => {
    const urlFilters = queryParamsToFilters(location.search);
    if (urlFilters && urlFilters.status === undefined) {
      setUniqueFilters((old) => {
        return { ...old, status: FiltersStatus.OPEN };
      });
    }
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeView]);

  return composeWrappers(
    (inner) => (
      <AdvancedFiltersContext.Provider value={advancedFilters}>
        {inner}
      </AdvancedFiltersContext.Provider>
    ),
    (inner) => (
      <SetAdvancedFiltersContext.Provider value={setAdvancedFilters}>
        {inner}
      </SetAdvancedFiltersContext.Provider>
    ),
    (inner) => (
      <UniqueFiltersContext.Provider value={uniqueFilters}>
        {inner}
      </UniqueFiltersContext.Provider>
    ),
    (inner) => (
      <SetUniqueFiltersContext.Provider value={setUniqueFilters}>
        {inner}
      </SetUniqueFiltersContext.Provider>
    ),
    (inner) => (
      <FinalizedFiltersContext.Provider value={finalizedFilters}>
        {inner}
      </FinalizedFiltersContext.Provider>
    ),
    (inner) => (
      <HaveFiltersResolvedOnInitialPageLoadContext.Provider
        value={haveFiltersResolvedOnInitialPageLoad}
      >
        {inner}
      </HaveFiltersResolvedOnInitialPageLoadContext.Provider>
    ),
  )(children);
});

function _updateFilters(
  newFilters: ConversationFiltersV3,
  baseFilters: ConversationFiltersV3,
  navigate: NavigateFunction,
) {
  const searchParams = new URLSearchParams(location.search);

  if (conversationFiltersEquivalent(newFilters, baseFilters)) {
    searchParams.delete("filters");
  } else {
    const filterParam = filtersToQueryString(newFilters);
    if (filterParam) {
      searchParams.set("filters", filterParam);
    } else {
      searchParams.delete("filters");
    }
  }

  const newSearch = searchParams.toString();
  if (newSearch === location.search) {
    return;
  }

  navigate({ search: searchParams.toString() });
}

const queryParamsToFilters = (
  rawSearchString: string,
): ConversationFiltersV3 | undefined => {
  const queryParams = new URLSearchParams(rawSearchString);
  const filters = queryParams.get("filters");
  if (!filters) {
    return undefined;
  }
  return queryStringToFilters(filters);
};

export const navigateToTicketsAssignedToUser = (
  teamId: string,
  userId: string,
) => {
  const assigneeFilter: AssigneesFilter = {
    type: FilterGroupFilterOption.ASSIGNEES,
    value: [userId],
    query: AssigneesFilterType.INCLUDES,
  };
  const filters: ConversationFiltersV3 = { advancedFilters: [assigneeFilter] };
  window.location.href = `/stores/${teamId}/support/table/all?filters=${filtersToQueryString(filters)}`;
};
