import { Redirect } from "@redotech/react-router-util/redirect";
import { breadcrumbSlot } from "@redotech/redo-web/breadcrumb";
import { Navigate, RouteObject } from "react-router-dom";

const orderBreadcrumb = breadcrumbSlot();

export const scanRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Scan" },
    children: [
      {
        index: true,
        async lazy() {
          const { ScanStartPage } = await import(
            "@redotech/redo-merchant-app-fulfillment/scan/start-page"
          );
          return { Component: ScanStartPage };
        },
      },
      {
        path: ":fulfillmentGroupId",
        async lazy() {
          const { ScanFulfillmentPage: ScanFulfillemntPage } = await import(
            "@redotech/redo-merchant-app-fulfillment/scan/fulfillment-page"
          );
          return { Component: ScanFulfillemntPage };
        },
      },
    ],
  },
];

export const outboundLabelRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Orders" },
    children: [
      { index: true, element: <Navigate to="orders" /> },
      {
        path: "dashboard",
        handle: { breadcrumb: "Dashboard" },
        async lazy() {
          const { DashboardPage } = await import(
            "@redotech/redo-merchant-app-fulfillment/dashboard/dashboard"
          );
          return { element: <DashboardPage /> };
        },
      },
      {
        path: "about",
        async lazy() {
          const { AboutOms } = await import("../setting/about-oms/about-oms");
          return { element: <AboutOms /> };
        },
      },
      {
        path: "lookup",
        async lazy() {
          const { OutboundOrderLookup } = await import(
            "@redotech/redo-merchant-app-fulfillment/outbound-order-lookup"
          );
          return { element: <OutboundOrderLookup /> };
        },
      },
      {
        path: "batch-history",
        handle: { breadcrumb: "Batch history" },
        async lazy() {
          const { BatchHistoryPage } = await import(
            "@redotech/redo-merchant-app-fulfillment/batch-history/batch-history"
          );
          return { element: <BatchHistoryPage /> };
        },
      },
      {
        path: "eod-manifest",
        handle: { breadcrumb: "EOD Manifest" },
        async lazy() {
          const { EodManifestPage } = await import(
            "@redotech/redo-merchant-app-fulfillment/end-of-day-manifest/eod-manifest-page"
          );
          return { element: <EodManifestPage /> };
        },
      },
      {
        path: "packing-slips",
        handle: { breadcrumb: "Packing slips" },
        children: [
          {
            index: true,
            async lazy() {
              const { PackingSlipsPage } = await import(
                "@redotech/redo-merchant-app-fulfillment/packing-slips/packing-slips-page"
              );
              return { element: <PackingSlipsPage /> };
            },
          },
          {
            path: ":packingSlipId",
            handle: { breadcrumb: "Packing slip builder" },
            async lazy() {
              const { PackingSlipBuilder } = await import(
                "@redotech/redo-merchant-app-fulfillment/packing-slips/packing-slip-builder"
              );
              return { Component: PackingSlipBuilder };
            },
          },
        ],
      },
      {
        path: "orders",
        children: [
          {
            handle: { breadcrumb: "All orders" },
            children: [
              {
                index: true,
                async lazy() {
                  const { Orders } = await import("./orders");
                  return {
                    element: (
                      <Orders
                        filterParams={(filters) => ({
                          dateRange: filters.dateRange,
                          shipmentStatus: filters.shipmentStatus,
                        })}
                      />
                    ),
                  };
                },
              },
              {
                path: ":orderId",
                handle: { breadcrumb: orderBreadcrumb },
                async lazy() {
                  const { OrderPage } = await import("./order-page");
                  return {
                    element: <OrderPage breadcrumb={orderBreadcrumb} />,
                  };
                },
              },
            ],
          },
        ],
      },
      { path: "scan", children: scanRoutes },
      { path: "", element: <Navigate replace to="awaiting-shipment" /> },
      {
        handle: { breadcrumb: "Order view" },
        path: ":viewName",
        async lazy() {
          const { FulfillmentGroupPage } = await import(
            "@redotech/redo-merchant-app-fulfillment/fulfillment-groups/fulfillment-group-page"
          );
          return { element: <FulfillmentGroupPage /> };
        },
      },
    ],
  },
];

export const backwardsCompatibleOrderRoutes: RouteObject[] = [
  {
    path: "outbound-labels",
    element: (
      <Redirect
        match={/orders\/outbound-labels/}
        replaceValue="order-management/outbound-labels"
      />
    ),
  },
  {
    path: "batch-history",
    element: (
      <Redirect
        match={/orders\/batch-history/}
        replaceValue="order-management/outbound-labels/batch-history"
      />
    ),
  },
  {
    path: "eod-manifest",
    element: (
      <Redirect
        match={/orders\/eod-manifest/}
        replaceValue="order-management/outbound-labels/eod-manifest"
      />
    ),
  },
  {
    path: "packing-slips",
    element: (
      <Redirect
        match={/orders\/packing-slips/}
        replaceValue="order-management/outbound-labels/packing-slips"
      />
    ),
  },
  {
    index: true,
    element: (
      <Redirect match={/orders(.*)/} replaceValue="order-management$1" />
    ),
  },
  {
    path: "outbound-labels/*",
    element: (
      <Redirect
        match={/orders\/outbound-labels(.*)/}
        replaceValue="order-management/outbound-labels$1"
      />
    ),
  },
  {
    path: "*",
    element: (
      <Redirect
        match={/orders(.*)/}
        replaceValue="order-management/outbound-labels/orders$1"
      />
    ),
  },
];
