import { memo } from "react";
import { Flex } from "../flex";
import { Text } from "../text";
import * as phoneNumpadCss from "./phone-numpad.module.css";

const numpadItems: string[] = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "*",
  "0",
  "#",
];

const dialCodes: Record<string, string[]> = {
  "1": [],
  "2": ["A", "B", "C"],
  "3": ["D", "E", "F"],
  "4": ["G", "H", "I"],
  "5": ["J", "K", "L"],
  "6": ["M", "N", "O"],
  "7": ["P", "Q", "R", "S"],
  "8": ["T", "U", "V"],
  "9": ["W", "X", "Y", "Z"],
  "*": [],
  "0": [],
  "#": [],
};

export const PhoneWidgetNumpad = memo(function PhoneWidgetNumpad({
  itemPressed,
}: {
  itemPressed: (item: string) => void;
}) {
  return (
    <Flex gap="3xl" justify="center" wrap="wrap">
      {numpadItems.map((item) => {
        return (
          <PhoneNumpadKey
            dialCodes={dialCodes[item]}
            key={item}
            number={item}
            onClick={() => itemPressed(item)}
          />
        );
      })}
    </Flex>
  );
});

const PhoneNumpadKey = memo(function PhoneNumpadKey({
  number,
  dialCodes,
  onClick,
}: {
  number: string;
  dialCodes: string[];
  onClick: () => void;
}) {
  return (
    <Flex
      align="center"
      as="button"
      className={phoneNumpadCss.phoneButton}
      dir="column"
      gap="none"
      justify="flex-start"
      onClick={onClick}
      p="xs"
    >
      <Text fontSize="md" textColor="primary">
        {number}
      </Text>
      <Flex gap="xxs">
        {dialCodes.map((dialCode) => (
          <Text fontSize="xxs" key={dialCode} textColor="placeholder">
            {dialCode}
          </Text>
        ))}
      </Flex>
    </Flex>
  );
});
