import { z } from "zod";
import { AnalyticsFilterType } from "./analytics-filter-type";

export function makeReportFilterSchema<
  Type extends AnalyticsFilterType,
  Query extends z.EnumLike,
  Value extends z.ZodType,
>(type: Type, query: Query, value: Value) {
  return z.object({
    type: z.literal(type),
    query: z.nativeEnum(query),
    value: value.optional(),
  });
}

export type BaseReportFilter<
  Type extends AnalyticsFilterType,
  Query extends z.EnumLike,
  Value extends z.ZodType,
> = z.infer<ReturnType<typeof makeReportFilterSchema<Type, Query, Value>>>;
